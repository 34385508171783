import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { FC } from 'react'
import { useMutation } from 'react-query'

import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { Person } from '@mui/icons-material'
import { AuthService } from '../../interactors/services/AuthService'
import { useForm } from 'react-hook-form'

export const RequestResetPasswordPrompt: FC = () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email: '' } })

  const watchedEmail = watch('email')

  const requestPasswordResetMutation = useMutation((email: string) => AuthService.requestPasswordReset(email), {
    onSuccess: () => {
      globalSnackbarStore.triggerSuccessMessage(
        `Un email a été à ${watchedEmail} envoyé pour réinitialiser votre mot de passe`
      )
      closeDialog()
    },
  })

  const onSubmit = (data: { email: string }) => {
    requestPasswordResetMutation.mutate(data.email)
  }

  return (
    <>
      <Button sx={{ textTransform: 'none', marginTop: 1 }} onClick={openDialog}>
        Mot de passe oublié ?
      </Button>

      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit(onSubmit as any)}>
          <DialogTitle>Réinitialiser le mot de passe</DialogTitle>
          <DialogContent>
            <TextField
              id="email"
              label="Email"
              sx={{ marginTop: 1 }}
              required
              type="email"
              fullWidth
              {...register('email', {
                required: 'required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "L'addresse n'est pas valide.",
                },
              })}
              InputProps={{
                startAdornment: (
                  <div style={{ marginRight: '8px' }}>
                    <Person />
                  </div>
                ),
              }}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Revenir
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              loading={requestPasswordResetMutation.isLoading}
            >
              Réinitialiser
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
