import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { AccountsClient } from '../../interactors/clients/AccountsClient'
import { MemberAdoptionPreferences } from './MemberAdoptionPreferences'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { AccountDto, AccountDtoRoles } from '../../interactors/gen/backendClient'
import { useFetchOrRefreshMembersOnMount } from '../../store/useFetchOrRefreshMembersOnMount'
import axios, { AxiosError } from 'axios'
import { readableAxiosError } from '../../utils/axios'
import { StatusCodes } from 'http-status-codes'
import { MemberAvatarAndName } from './MemberAvatarAndName'
import { MemberOwnAnimalsCard } from './MemberOwnAnimalsCard'
import { useMutation } from 'react-query'
import { MemberGeneralInfo } from './MemberGeneralInfo'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { MemberHomeCard } from './MemberHomeCard'
import { MemberAvailabilityTab } from './Availability/MemberAvailabilityTab'
import { MemberDocumentsTab } from './Documents/MemberDocumentsTab'
import { MemberCommentsCard } from './Comments/MemberCommentsCard'
import { MemberVisibilityTab } from './Visibility/MemberVisibilityTab'
import { SpecialitiesCard } from './Veterinary/SpecialitiesCard'
import InactivityPopup from '../utils/InactivityPopup'

type TabKey = 'profil' | 'availability' | 'documents' | 'visibility'

export const MemberDetailsScreen = () => {
  const [account, setAccount] = useState<AccountDto | null>(null)
  const { accountId } = useParams() as { accountId: string }
  const accountsStore = useAccountsStore()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const [isOwnAccount, setIsOwnAccount] = useState(false)

  const navigate = useNavigate()
  const [currentTabIndex, setCurrentTabIndex] = useState<TabKey>('profil')

  const handleChange = (_: React.SyntheticEvent, newTabKey: TabKey) => {
    setCurrentTabIndex(newTabKey)
    navigate(`/suivi-benevole/${accountId}?tab=${newTabKey}`)
  }

  useFetchOrRefreshMembersOnMount()

  //Récupération du compte
  useEffect(() => {
    const loadAccount = async () => {
      const selectedAccount = accountsStore.members.find((anAccount) => accountId === anAccount.id)
      console.log(selectedAccount)
      setAccount(selectedAccount || null)
    }

    loadAccount()
    console.log(account)
  }, [accountId, accountsStore.members])

  //On vérifie si le compte connecté est le même que celui affiché
  useEffect(() => {
    setIsOwnAccount(accountId === accountsStore.connectedAccount?.id)
  }, [accountId, accountsStore.connectedAccount])

  //Mutation pour modifier un compte
  const editAccountMutation = useMutation(
    async (data: AccountDto) => {
      const response = await AccountsClient.editAccount(data)
      return response
    },
    {
      onSuccess: (account) => {
        globalSnackBarStore.triggerSuccessMessage(`Le profil de ${account.firstName} a été modifié.`)
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            globalSnackBarStore.triggerErrorMessage('Un utilisateur existe déjà avec cette addresse mail.')
            return
          }
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  //Fonction pour envoyer les données des formulaires
  const onSubmit = async (data: Partial<AccountDto>) => {
    const dataWithPotentialMockPassword: AccountDto = {
      ...account!,
      ...data,
      phoneNumber: ['+33', ''].includes(data.phoneNumber?.trim() || '') ? undefined : data.phoneNumber,
    }

    setAccount(dataWithPotentialMockPassword)
    editAccountMutation.mutate(dataWithPotentialMockPassword)
  }

  function a11yProps(tabKey: TabKey) {
    return {
      id: `simple-tab-${tabKey}`,
      value: tabKey,
      'aria-controls': `simple-tabpanel-${tabKey}`,
      key: tabKey,
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tabParam = queryParams.get('tab')
    const newTabKey = tabParam as TabKey
    setCurrentTabIndex(newTabKey || 'profil')
  }, [location.search])

  if (account === null) {
    return <CircularProgressPanel />
  }

  return (
    <Box sx={{ padding: 2, backgroundColor: 'grey.100', minHeight: '100%' }}>
      <InactivityPopup />
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: '5rem' }}>
        <MemberAvatarAndName account={account!} isOwnAccount={isOwnAccount} onSubmit={onSubmit} />

        <MemberGeneralInfo
          account={account!}
          isOwnAccount={isOwnAccount}
          onSubmit={onSubmit}
          isLoading={editAccountMutation.isLoading}
        />

        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab label="Profil" {...a11yProps('profil')} />
            <Tab label="Disponibilité" {...a11yProps('availability')} />
            <Tab label="Documents" {...a11yProps('documents')} />
            <Tab label="Visibilité" {...a11yProps('visibility')} />
          </Tabs>
        </Box>

        <TabPanel value={currentTabIndex} index={'profil' as TabKey}>
          <Grid container spacing={{ xs: 2, md: 4 }} sx={{ pb: 2, boxSizing: 'border-box' }}>
            {account.roles.includes(AccountDtoRoles.Veterinary) && location.pathname.includes('suivi-veterinaire') && (
              <Grid item xs={12} sm={12}>
                <SpecialitiesCard account={account!} onSubmit={onSubmit} isOwnAccount />
              </Grid>
            )}

            {!(account.roles.length === 1 && account.roles[0] === AccountDtoRoles.Veterinary) && (
              <>
                <Grid item xs={12} sm={6}>
                  <MemberHomeCard
                    account={account!}
                    onSubmit={onSubmit}
                    isOwnAccount={isOwnAccount}
                    isLoading={editAccountMutation.isLoading}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MemberAdoptionPreferences
                    account={account!}
                    onSubmit={onSubmit}
                    isOwnAccount={isOwnAccount}
                    isLoading={editAccountMutation.isLoading}
                  />
                </Grid>
              </>
            )}

            {account.roles.includes(AccountDtoRoles.Veterinary) && location.pathname.includes('suivi-benevole') && (
              <Grid item xs={12} sm={12}>
                <SpecialitiesCard account={account!} onSubmit={onSubmit} isOwnAccount />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <MemberCommentsCard accountId={accountId} baseComments={account?.comments} isOwnAccount={isOwnAccount} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'availability' as TabKey}>
          <MemberAvailabilityTab
            initialAvailabilities={account?.availability || []}
            onSubmit={onSubmit}
            isOwnAccount={isOwnAccount}
          />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'documents' as TabKey}>
          <MemberDocumentsTab account={account} isOwnAccount={isOwnAccount} />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'visibility' as TabKey}>
          <MemberVisibilityTab account={account} isOwnAccount={isOwnAccount} onSubmit={onSubmit} />
        </TabPanel>

        <MemberOwnAnimalsCard accountId={accountId} capacity={account.home?.maxCapacity} />
      </Box>
    </Box>
  )
}

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
