import {
  AccountDocumentDtoType,
  AccountDto,
  AccountDtoAvailabilityStatus,
  AccountDtoHomeBalcony,
  AccountDtoHomeGarden,
  AccountDtoHomeHomeType,
  AccountDtoPermission,
  AccountDtoPreferenceSeniority,
  AccountDtoPreferencesNeedsBottleFeeding,
  AccountDtoPreferencesSeniority,
  AccountDtoPreferencesSex,
  AccountDtoPreferencesSterilizedOrNeutered,
  AccountDtoPreferencesVaccinated,
  AccountDtoPreferencesWillingToSocializeWildAnimals,
  AccountDtoRoles,
  AccountDtoStatus,
  AccountVeterinaryDtoType,
} from '../../interactors/gen/backendClient'
import { colors } from '../../views/theme'

export const fullName = (account: { firstName?: string; lastName?: string }) => {
  return [account.firstName, account.lastName].filter((field) => field).join(' ')
}

export const fullAddress = (addressObject: { address?: string; postalCode?: string; city?: string }): string => {
  return [addressObject.address, addressObject.postalCode, addressObject.city].filter((field) => field).join(', ')
}

type AccountPropertyWithLabel = Exclude<
  keyof AccountDto,
  'customerId' | 'isDeleted' | 'images' | 'status' | 'city' | 'preferences'
>
// type AccountPropertyWithLabel = keyof AccountDto

export const getReadableAccountFieldLabel = (field: AccountPropertyWithLabel): string => {
  const map: Record<AccountPropertyWithLabel, string> = {
    id: 'ID',
    firstName: 'Prénom',
    lastName: 'Nom',
    roles: 'Rôles',
    email: 'Email',
    permission: 'Permission',
    address: 'Addresse',
    phoneNumber: 'Numéro de téléphone',
    postalCode: 'Code postal',
    country: 'Pays',
    titles: 'Postes',
    home: 'Foyer et hébergement',
    availability: 'Disponibilité',
    documents: 'Documents',
    hasACACED: "Possède l'ACACED",
    comments: 'Commentaires',
    hasAcceptedTerms: 'A accepté les conditions',
    visibility: 'Visibilité',
    restrainedData: 'Données restreintes',
    coordinates: 'Coordonnées',
    veterinary: 'Vétérinaire',
    memberNumber: 'Numéro de membre',
    validated: 'Validé',
  }

  return map[field]
}

export const getReadableAccountRole = (field: AccountDtoRoles): string => {
  const map: Record<AccountDtoRoles, string> = {
    [AccountDtoRoles.Member]: "Membre de l'association",
    [AccountDtoRoles.HostFamily]: "Famille d'accueil",
    [AccountDtoRoles.Veterinary]: 'Vétérinaire',
  }

  return map[field]
}

export const getSmallReadableAccountRole = (field: AccountDtoRoles): string => {
  const map: Record<AccountDtoRoles, string> = {
    [AccountDtoRoles.Member]: 'Membre',
    [AccountDtoRoles.HostFamily]: 'FA',
    [AccountDtoRoles.Veterinary]: 'Véto',
  }

  return map[field]
}

export const getReadableAccountPermission = (field: AccountDtoPermission): string => {
  const map: Record<AccountDtoPermission, string> = {
    [AccountDtoPermission.Administrator]: 'Admin',
    [AccountDtoPermission.Editor]: 'Éditeur',
    [AccountDtoPermission.Read]: 'Lecteur',
    [AccountDtoPermission.Silent]: "Pas d'accès",
  }

  return map[field]
}

export const getReadableAccountPreferenceSeniority = (field: AccountDtoPreferenceSeniority): string => {
  const map: Record<AccountDtoPreferenceSeniority, string> = {
    [AccountDtoPreferenceSeniority.Junior]: 'Junior',
    [AccountDtoPreferenceSeniority.Senior]: 'Sénior',
    [AccountDtoPreferenceSeniority.Adult]: 'Adulte',
  }

  return map[field]
}

export const getReadableAccountStatus = (field: AccountDtoStatus): string => {
  const map: Record<AccountDtoStatus, string> = {
    [AccountDtoStatus.Active]: 'Disponible',
    [AccountDtoStatus.Inactive]: 'Indisponible',
  }

  return map[field]
}

export const getReadableAvailabilityStatus = (field: AccountDtoAvailabilityStatus): string => {
  const map: Record<AccountDtoAvailabilityStatus, string> = {
    [AccountDtoAvailabilityStatus.Active]: 'Disponible',
    [AccountDtoAvailabilityStatus.Inactive]: 'Indisponible',
    [AccountDtoAvailabilityStatus.EmergencyOnly]: 'Indisponible sauf urgence',
  }

  return map[field]
}

export const getColorForAvailabilityStatus = (field: AccountDtoAvailabilityStatus): string => {
  const map: Record<AccountDtoAvailabilityStatus, string> = {
    [AccountDtoAvailabilityStatus.Active]: colors.seaGreen,
    [AccountDtoAvailabilityStatus.Inactive]: colors.punch,
    [AccountDtoAvailabilityStatus.EmergencyOnly]: colors.amber,
  }

  return map[field]
}

export const getReadableAccountPreferencesSeniority = (field: AccountDtoPreferencesSeniority): string => {
  const map: Record<AccountDtoPreferencesSeniority, string> = {
    [AccountDtoPreferencesSeniority.LessThanOneMonth]: '< 1 mois',
    [AccountDtoPreferencesSeniority.OneToSixMonths]: '1 à 4 mois',
    [AccountDtoPreferencesSeniority.MoreThanSixMonths]: 'Adolescent',
    [AccountDtoPreferencesSeniority.Senior]: 'Sénior',
    [AccountDtoPreferencesSeniority.Adult]: 'Adulte',
    [AccountDtoPreferencesSeniority.PregnantFemale]: 'Femelle gestante',
    [AccountDtoPreferencesSeniority.MotherWithBabies]: 'Mère avec bébés',
    [AccountDtoPreferencesSeniority.NoPreference]: 'Pas de préférence',
  }

  return map[field]
}

export const getReadableAccountPreferencesSex = (field: AccountDtoPreferencesSex): string => {
  const map: Record<AccountDtoPreferencesSex, string> = {
    [AccountDtoPreferencesSex.Female]: 'Femelle',
    [AccountDtoPreferencesSex.Male]: 'Mâle',
    [AccountDtoPreferencesSex.NoPreference]: 'Pas de préférence',
  }

  return map[field]
}

export const getReadableAccountPreferencesSterilizedOrNeutered = (
  field: AccountDtoPreferencesSterilizedOrNeutered
): string => {
  const map: Record<AccountDtoPreferencesSterilizedOrNeutered, string> = {
    [AccountDtoPreferencesSterilizedOrNeutered.True]: 'Oui',
    [AccountDtoPreferencesSterilizedOrNeutered.False]: 'Non',
    [AccountDtoPreferencesSterilizedOrNeutered.NoPreference]: 'Pas de préférence',
  }

  return map[field]
}

export const getReadableAccountPreferencesVaccinated = (field: AccountDtoPreferencesVaccinated): string => {
  const map: Record<AccountDtoPreferencesVaccinated, string> = {
    [AccountDtoPreferencesVaccinated.True]: 'Oui',
    [AccountDtoPreferencesVaccinated.False]: 'Non',
    [AccountDtoPreferencesVaccinated.NoPreference]: 'Pas de préférence',
  }

  return map[field]
}

export const getReadableAccountPreferencesWillingToSocializeWildAnimals = (
  field: AccountDtoPreferencesWillingToSocializeWildAnimals
): string => {
  const map: Record<AccountDtoPreferencesWillingToSocializeWildAnimals, string> = {
    [AccountDtoPreferencesWillingToSocializeWildAnimals.Yes]: 'Oui',
    [AccountDtoPreferencesWillingToSocializeWildAnimals.No]: 'Non',
  }

  return map[field]
}

export const getReadableAccountPreferencesNeedsBottleFeeding = (
  field: AccountDtoPreferencesNeedsBottleFeeding
): string => {
  const map: Record<AccountDtoPreferencesNeedsBottleFeeding, string> = {
    [AccountDtoPreferencesNeedsBottleFeeding.True]: 'Oui',
    [AccountDtoPreferencesNeedsBottleFeeding.False]: 'Non',
    [AccountDtoPreferencesNeedsBottleFeeding.NoPreference]: 'Pas de préférence',
  }

  return map[field]
}

export const getReadableAccountHomeType = (field: AccountDtoHomeHomeType): string => {
  const map: Record<AccountDtoHomeHomeType, string> = {
    [AccountDtoHomeHomeType.Apartment]: 'Appartement',
    [AccountDtoHomeHomeType.House]: 'Maison',
  }

  return map[field]
}

export const getReadableAccountHomeBalcony = (field: AccountDtoHomeBalcony): string => {
  const map: Record<AccountDtoHomeBalcony, string> = {
    [AccountDtoHomeBalcony.No]: 'Non',
    [AccountDtoHomeBalcony.YesNotSecured]: 'Oui, non sécurisé',
    [AccountDtoHomeBalcony.YesSecured]: 'Oui, sécurisé',
  }

  return map[field]
}

export const getReadableAccountHomeGarden = (field: AccountDtoHomeGarden): string => {
  const map: Record<AccountDtoHomeGarden, string> = {
    [AccountDtoHomeGarden.No]: 'Non',
    [AccountDtoHomeGarden.YesNotSecured]: 'Oui, non sécurisé',
    [AccountDtoHomeGarden.YesSecured]: 'Oui, sécurisé',
  }

  return map[field]
}

export const getReadableAccountDocumentType = (field: AccountDocumentDtoType): string => {
  const map: Record<AccountDocumentDtoType, string> = {
    [AccountDocumentDtoType.Acaced]: 'ACACED',
    [AccountDocumentDtoType.Asv]: 'Diplôme d’ASV',
    [AccountDocumentDtoType.EquipmentLoanForm]: 'Fiche de prêt de matériel',
    [AccountDocumentDtoType.FosterFamilyContract]: "Contrat Famille d'accueil",
    [AccountDocumentDtoType.Others]: 'Autres',
    [AccountDocumentDtoType.PresidentIdentityDocument]: 'Document d’identité du président',
    [AccountDocumentDtoType.VolunteerContract]: 'Contrat bénévole',
  }

  return map[field]
}

export const getColorForAccountDocumentType = (field: AccountDocumentDtoType): string => {
  const map: Record<AccountDocumentDtoType, string> = {
    [AccountDocumentDtoType.Acaced]: colors.blueSky,
    [AccountDocumentDtoType.Asv]: colors.blueSky,
    [AccountDocumentDtoType.EquipmentLoanForm]: colors.blueSky,
    [AccountDocumentDtoType.FosterFamilyContract]: colors.blueSky,
    [AccountDocumentDtoType.Others]: colors.blueSky,
    [AccountDocumentDtoType.PresidentIdentityDocument]: colors.blueSky,
    [AccountDocumentDtoType.VolunteerContract]: colors.blueSky,
  }

  return map[field]
}

export const getReadableAccountVeterinaryType = (field: AccountVeterinaryDtoType): string => {
  const map: Record<AccountVeterinaryDtoType, string> = {
    [AccountVeterinaryDtoType.Independent]: 'Vétérinaire indépendant',
    [AccountVeterinaryDtoType.Clinic]: 'Clinique vétérinaire',
  }

  return map[field]
}
