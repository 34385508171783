import React, { useEffect, useRef, useState } from 'react'
import {
  AnimalDtoSpecies,
  CustomerCageWithAnimalsDetailsDto,
  CustomerDto,
} from '../../../interactors/gen/backendClient'
import {
  Box,
  Card,
  CardContent,
  Drawer,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useMutation } from 'react-query'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import { AssociationCageItem } from './AssociationCageItem'
import { AssociationAddCageModal } from './AssociationAddCageModal'
import { spacingItem } from '../../theme'
import { omitEmptyString } from '../../../utils/omitEmptyString'
import { useFetchOrRefreshAnimalsOnMount } from '../../../store/useFetchOrRefreshAnimalsOnMount'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { HorizontalSplit, Pets, Tune } from '@mui/icons-material'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { FilterListItem } from '../../common/FilterListItem'
import { useAccountsStore } from '../../../store/AccountsStore'
import { FilterList } from '../../common/FilterList'
import { searchCages } from '../../../domain/Customer/searchCages'

interface Props {
  association: CustomerDto
}

export const AssociationCagesTab: React.FC<Props> = ({ association }) => {
  const [dataLoaded, setDataLoaded] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [cagesList, setCagesList] = useState<CustomerCageWithAnimalsDetailsDto[]>([])
  const [highestCageNumber, setHighestCageNumber] = useState(0)
  const [selectedSpecies, setSelectedSpecies] = useState<AnimalDtoSpecies[]>([])
  const [selectedOccupations, setSelectedOccupations] = useState<string[]>([])
  const globalSnackbarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  useFetchOrRefreshAnimalsOnMount()

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const initialCagesRef = useRef(cagesList)

  const editCustomerMutation = useMutation(
    async (customer: CustomerDto) => await CustomersClient.editCustomer(customer)
  )

  useEffect(() => {
    const saveCages = async () => {
      if (!dataLoaded) {
        return // Do not save if the data is not yet loaded
      }
      // only save if cagesList have been modified
      if (JSON.stringify(cagesList) !== JSON.stringify(initialCagesRef.current)) {
        // Convert cagesList from CustomerCageWithAnimalsDetailsDto to CustomerCageDto
        const convertedCagesList = cagesList.map((cageWithAnimalsNames) => {
          const { animals, ...rest } = cageWithAnimalsNames

          // Only keep the 'id' from the 'animals' array
          const convertedAnimals = animals ? animals.map((animal) => animal.id) : undefined

          return {
            ...rest,
            animals: convertedAnimals,
          }
        })

        const update = omitEmptyString({
          ...association,
          cages: convertedCagesList,
        })

        await editCustomerMutation.mutate(update)
        globalSnackbarStore.triggerSuccessMessage('Les cages ont bien été mises à jour !')
      }
    }

    saveCages()
  }, [cagesList])

  const fetchCagesMutation = useMutation(async () => await CustomersClient.getCurrentCustomerCagesWithAnimalsNames(), {
    onSuccess: (data) => {
      setCagesList(data)
      setDataLoaded(true)
    },
  })

  useEffect(() => {
    if (accountsStore.isPremium()) {
      fetchCagesMutation.mutate()
    } else {
      setDataLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (dataLoaded) {
      initialCagesRef.current = cagesList
    }
  }, [dataLoaded, cagesList])

  useEffect(() => {
    if (cagesList.length > 0) {
      const newHighest = Number(
        cagesList.reduce((prev, current) => {
          const prevNumber = Number(prev.cageNumber)
          const currentNumber = Number(current.cageNumber)
          return prevNumber > currentNumber ? prev : current
        }).cageNumber
      )
      setHighestCageNumber(newHighest)
    } else {
      setHighestCageNumber(0)
    }
  }, [cagesList])

  const species = accountsStore.connectedCustomer?.preferences.species

  const FiltersBar = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom sx={spacingItem}>
          Box pour
        </Typography>

        {species && species.length > 1 && (
          <FilterList label="Espèces" icon={<Pets />}>
            {species?.map((specie) => {
              const isSelected = selectedSpecies.includes(specie)

              return (
                <FilterListItem
                  key={specie}
                  label={getReadableSpecies(specie)}
                  isSelected={isSelected}
                  toggleSelected={() => {
                    if (isSelected) {
                      // If the specie is already selected, remove it from the selectedSpecies array
                      setSelectedSpecies(selectedSpecies.filter((s) => s !== specie))
                    } else {
                      // If the specie is not selected, add it to the selectedSpecies array
                      setSelectedSpecies([...selectedSpecies, specie])
                    }
                  }}
                />
              )
            })}
          </FilterList>
        )}

        <Typography variant="h6" gutterBottom sx={spacingItem}>
          Disponibilité
        </Typography>

        <FilterList label="État" icon={<HorizontalSplit />}>
          {['Vacant', 'Occupé', 'Partiellement occupé'].map((cageOccupation) => {
            const isSelected = selectedOccupations.includes(cageOccupation)

            return (
              <FilterListItem
                key={cageOccupation}
                label={cageOccupation}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    setSelectedOccupations(selectedOccupations.filter((s) => s !== cageOccupation))
                  } else {
                    setSelectedOccupations([...selectedOccupations, cageOccupation])
                  }
                }}
              />
            )
          })}
        </FilterList>
      </>
    )
  }

  const searchResults = searchCages(cagesList, searchQuery)
    .filter((cage) => {
      if (selectedSpecies.length === 0) return true
      return cage.suitableForSpecies.some((specie) => selectedSpecies.includes(specie))
    })
    .filter((cage) => {
      if (selectedOccupations.length === 0) return true

      let match = false

      if (selectedOccupations.includes('Partiellement occupé')) {
        match = match || (cage.animals?.length ? cage.animals.length < cage.capacity : false)
      }

      if (selectedOccupations.includes('Vacant')) {
        match = match || !cage.animals?.length
      }

      if (selectedOccupations.includes('Occupé')) {
        match = match || (cage.animals?.length ? cage.animals.length >= cage.capacity : false)
      }

      return match
    })
    .sort((a, b) => Number(a.cageNumber) - Number(b.cageNumber))

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: 1,
          paddingBottom: 7.5,
          backgroundColor: 'grey.100',
          minHeight: `calc(100% - 20px)`,
          boxSizing: 'border-box',
          marginBottom: 2,
          maxHeight: `calc(100% - 20px)`,
          width: '100%',
        }}
      >
        {/* Filters for desktop version */}
        <Card
          sx={{
            width: 230,
            display: { xs: 'none', lg: 'block' },
            overflowY: 'auto',
          }}
        >
          <CardContent>
            <FiltersBar />
          </CardContent>
        </Card>
        {/* Filters for mobile version */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
          }}
        >
          <Box sx={{ padding: 2 }}>
            <FiltersBar />
          </Box>
        </Drawer>

        <Card
          sx={{
            ml: { xs: 0, lg: 2 },
            overflowX: 'auto',
            width: '100%',
          }}
        >
          <CardContent>
            <Box display="flex" flexDirection="row" flexWrap="nowrap">
              <TextField
                id="name-input"
                placeholder="Rechercher une cage"
                type="text"
                fullWidth
                value={searchQuery}
                onChange={handleSearch}
                sx={{
                  borderRadius: 0,
                  [`& fieldset`]: {
                    borderRadius: 0,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { lg: 'none' }, mx: 'auto' }}
              >
                <Tune />
              </IconButton>
            </Box>
            <Table stickyHeader={true} aria-label="simple table" sx={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>N°</TableCell>
                  <TableCell>Convient pour</TableCell>
                  <TableCell>Capacité d&#39;accueil</TableCell>
                  <TableCell>Actuellement occupée par</TableCell>
                  <TableCell>Taux d&#39;occupation</TableCell>
                  <TableCell>Membre en charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResults.map((cage) => (
                  <AssociationCageItem
                    key={cage.cageNumber}
                    cage={cage}
                    setCagesList={setCagesList}
                    cagesList={cagesList}
                  />
                ))}
              </TableBody>
            </Table>
            <Box
              sx={{
                ...spacingItem,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <AssociationAddCageModal
                key={highestCageNumber}
                setCagesList={setCagesList}
                cagesList={cagesList}
                highestCageNumber={highestCageNumber}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
