import { AdoptionAttemptDto } from '../interactors/gen/backendClient'

export const getAdoptionAttemptStatus = (adoptionAttempt: AdoptionAttemptDto): string => {
  if (adoptionAttempt.status === 'suspended-by-customer' || adoptionAttempt.status === 'suspended-by-user')
    return 'Adoption suspendue'
  if (adoptionAttempt.archived === true) return 'Adoption terminée'
  if (adoptionAttempt.done === true) return 'Adoption à finaliser'
  if (!adoptionAttempt.certificate || !adoptionAttempt.certificate.original) {
    return 'Envoyer le certificat'
  }
  if (!adoptionAttempt.contract || !adoptionAttempt.contract.original) {
    return 'Envoyer le contrat'
  }
  if (!adoptionAttempt.certificate.signed || !adoptionAttempt.contract.signed) {
    return "En attente de l'adoptant"
  }
  if (!adoptionAttempt.paymentSentByUser && !adoptionAttempt.paymentDone) {
    return 'Paiement en attente'
  }
  if (adoptionAttempt.paymentSentByUser && !adoptionAttempt.paymentDone) {
    return 'Valider la réception du paiement'
  }
  return 'Adoption à finaliser'
}
