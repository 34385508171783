import { AnimalHealthDtoPathologies, AnimalHealthOperationDtoType } from '../../interactors/gen/backendClient'

export const DogTestPathologies: Partial<AnimalHealthOperationDtoType[]> = [
  AnimalHealthOperationDtoType.CanineDistemper,
  AnimalHealthOperationDtoType.RubarthHepatitis,
  AnimalHealthOperationDtoType.Leptospirosis,
  AnimalHealthOperationDtoType.Parvovirus,
  AnimalHealthOperationDtoType.KennelCough,
  AnimalHealthOperationDtoType.Rabies,
  AnimalHealthOperationDtoType.LymeDisease,
  AnimalHealthOperationDtoType.Babesiosis,
  AnimalHealthOperationDtoType.Diabetic,
]

export const CatTestPathologies: Partial<AnimalHealthOperationDtoType[]> = [
  AnimalHealthOperationDtoType.Typhus,
  AnimalHealthOperationDtoType.Chlamydiosis,
  AnimalHealthOperationDtoType.Coryza,
  AnimalHealthOperationDtoType.FeLv,
  AnimalHealthOperationDtoType.Fiv,
  AnimalHealthOperationDtoType.Fip,
  AnimalHealthOperationDtoType.Rabies,
  AnimalHealthOperationDtoType.CatScratchDisease,
  AnimalHealthOperationDtoType.Diabetic,
]

export const FerretTestPathologies: Partial<AnimalHealthOperationDtoType[]> = [
  AnimalHealthOperationDtoType.Implant,
  AnimalHealthOperationDtoType.Diabetic,
]

export const GlobalTestPathologies: Partial<AnimalHealthOperationDtoType[]> = [
  AnimalHealthOperationDtoType.Diabetic,
  AnimalHealthOperationDtoType.Herpesvirus,
]

export const DogPathologies: Partial<AnimalHealthDtoPathologies[]> = [
  AnimalHealthDtoPathologies.CanineDistemper,
  AnimalHealthDtoPathologies.RubarthHepatitis,
  AnimalHealthDtoPathologies.Leptospirosis,
  AnimalHealthDtoPathologies.Parvovirus,
  AnimalHealthDtoPathologies.KennelCough,
  AnimalHealthDtoPathologies.Rabies,
  AnimalHealthDtoPathologies.LymeDisease,
  AnimalHealthDtoPathologies.Babesiosis,
  AnimalHealthDtoPathologies.Diabetic,
  AnimalHealthDtoPathologies.Giardiasis,
]

export const CatPathologies: Partial<AnimalHealthDtoPathologies[]> = [
  AnimalHealthDtoPathologies.Typhus,
  AnimalHealthDtoPathologies.Chlamydiosis,
  AnimalHealthDtoPathologies.Coryza,
  AnimalHealthDtoPathologies.FeLv,
  AnimalHealthDtoPathologies.Fiv,
  AnimalHealthDtoPathologies.Fip,
  AnimalHealthDtoPathologies.Rabies,
  AnimalHealthDtoPathologies.CatScratchDisease,
  AnimalHealthDtoPathologies.Diabetic,
  AnimalHealthDtoPathologies.Giardiasis,
  AnimalHealthDtoPathologies.Calicivirus,
]

export const FerretPathologies: Partial<AnimalHealthDtoPathologies[]> = [AnimalHealthDtoPathologies.Diabetic]

export const GlobalPathologies: Partial<AnimalHealthDtoPathologies[]> = [
  AnimalHealthDtoPathologies.Herpesvirus,
  AnimalHealthDtoPathologies.Deaf,
  AnimalHealthDtoPathologies.VisuallyImpaired,
  AnimalHealthDtoPathologies.Diabetic,
]
