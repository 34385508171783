import React from 'react'
import { Card, CardHeader, CardContent, Box, Typography } from '@mui/material'
import { AccountDto } from '../../interactors/gen/backendClient'
import { spacingItem } from '../theme'
import { useAccountsStore } from '../../store/AccountsStore'
import { EditMemberHomePrompt } from './EditMemberHomePrompt'
import {
  getReadableAccountHomeBalcony,
  getReadableAccountHomeGarden,
  getReadableAccountHomeType,
  getReadableAccountPreferencesWillingToSocializeWildAnimals,
} from '../../domain/Account/AccountDisplay'
import { MemberHomeAnimals } from './MemberHomeAnimals'

interface Props {
  account: AccountDto
  onSubmit: (data: Partial<AccountDto>) => Promise<void>
  isOwnAccount: boolean
  isLoading: boolean
}

export const MemberHomeCard: React.FC<Props> = ({ account, onSubmit, isOwnAccount, isLoading }) => {
  const accountsStore = useAccountsStore()
  return (
    <Card sx={{ ...spacingItem, height: 'calc(100% - 16px)', boxSizing: 'border-box' }}>
      <CardHeader title="Foyer et Habitation" />
      {account.restrainedData ? (
        <CardContent>
          <Typography>
            Ces informations ne sont pas visibles compte tenu des préférences de l&#39;utilisateur
          </Typography>
        </CardContent>
      ) : (
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2 }}>
                  Type d&#39;habitation:
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.homeType ? getReadableAccountHomeType(account.home?.homeType) : 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, pl: 2 }}>
                  -Avec Balcon(s):
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.balcony ? getReadableAccountHomeBalcony(account.home?.balcony) : 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, pl: 2 }}>
                  -Avec Jardin(s):
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.garden ? getReadableAccountHomeGarden(account.home?.garden) : 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2 }}>
                  Nombre de salle(s) de quarantaine:
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.quarantineRoomNumber || account.home?.quarantineRoomNumber === 0
                    ? account.home?.quarantineRoomNumber
                    : 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2 }}>
                  Possibilité d&#39;isolement:
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.hasIsolationRoom
                    ? getReadableAccountPreferencesWillingToSocializeWildAnimals(account.home?.hasIsolationRoom)
                    : 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2 }}>
                  Véhiculé:
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.hasCar
                    ? getReadableAccountPreferencesWillingToSocializeWildAnimals(account.home?.hasCar)
                    : 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2 }}>
                  Surface du foyer:
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.homeSurface ?? 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2 }}>
                  Nombre d&#39;enfant(s):
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.childrenNumber || account.home?.childrenNumber === 0
                    ? account.home?.childrenNumber
                    : 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2 }}>
                  Nombre d&#39;adulte(s):
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 2, fontWeight: 500 }}>
                  {account.home?.adultNumber || account.home?.adultNumber === 0
                    ? account.home?.adultNumber
                    : 'Non renseigné'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', gap: 1 }}>
                <Typography variant="h4" sx={{ fontSize: 18 }}>
                  Capacité d&#39;accueil:
                </Typography>
                <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                  {account.home?.maxCapacity
                    ? `${account.home.maxCapacity} ${account.home.maxCapacity > 1 ? 'animaux' : 'animal'}`
                    : 'Non renseigné'}
                </Typography>
              </Box>
            </Box>
            {accountsStore.canEdit() && (
              <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
                <EditMemberHomePrompt
                  account={account}
                  onSubmit={onSubmit}
                  isOwnAccount={isOwnAccount}
                  isLoading={isLoading}
                />
              </Box>
            )}
          </Box>
          <MemberHomeAnimals account={account} onSubmit={onSubmit} isOwnAccount={isOwnAccount} />
        </CardContent>
      )}
    </Card>
  )
}
