import { CreateDonorBodyDtoType } from '../../interactors/gen/backendClient'

export const getDonatorFullName = (donator: {
  human?: { firstName?: string; lastName?: string }
  organization?: { name: string }
}) => {
  if (donator.human) {
    return [donator.human.firstName, donator.human.lastName].filter((field) => field).join(' ')
  }
  if (donator.organization) {
    return donator.organization.name
  }
  return ''
}

export const getReadableDonatorType = (field: CreateDonorBodyDtoType): string => {
  const map: Record<CreateDonorBodyDtoType, string> = {
    [CreateDonorBodyDtoType.Associations]: 'Association',
    [CreateDonorBodyDtoType.Companies]: 'Entreprise',
    [CreateDonorBodyDtoType.Individuals]: 'Particulier',
    [CreateDonorBodyDtoType.PublicBodies]: 'Organisation publique',
  }

  return map[field]
}
