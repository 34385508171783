import { Box, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomerDto } from '../../interactors/gen/backendClient'
import { AssociationMyAssociationTab } from './MyAssociation/AssociationMyAssociationTab'
import { getBestCustomerTypeLabel } from '../../domain/Customer/CustomerDisplay'
import { AssociationDonationTrackingTab } from './DonationTracking/AssociationDonationTrackingTab'
import { FunctionalitiesTab } from './Functionalities/FunctionalitiesTab'
import { useNavigate } from 'react-router-dom'
import { AssociationDocumentsTab } from './Documents/AssociationDocumentsTab'
import { AssociationCagesTab } from './Cages/AssociationCagesTab'
import { AssociationDashboard } from './Dashboard/AssociationDashboard'
import { TemplatesList } from './Templates/TemplatesList'

interface AssociationProps {
  association: CustomerDto
  setAssociation: (association: CustomerDto) => void
}

type TabKey =
  | 'my-association'
  | 'dashboard'
  | 'donation-tracking'
  | 'functionalities'
  | 'documents'
  | 'cages'
  | 'templates'

export const AssociationTabs: React.FC<AssociationProps> = ({ association, setAssociation }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<TabKey>('my-association')
  const navigate = useNavigate()

  const handleChange = (_: React.SyntheticEvent, newTabKey: TabKey) => {
    setCurrentTabIndex(newTabKey)
    navigate(`/association?tab=${newTabKey}`)
  }

  function a11yProps(tabKey: TabKey) {
    return {
      id: `simple-tab-${tabKey}`,
      value: tabKey,
      'aria-controls': `simple-tabpanel-${tabKey}`,
      key: tabKey,
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tabParam = queryParams.get('tab')
    const newTabKey = tabParam as TabKey
    setCurrentTabIndex(newTabKey || 'my-association')
  }, [location.search])
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={getBestCustomerTypeLabel(association?.types, { my: true })} {...a11yProps('my-association')} />
          <Tab label="Documents" {...a11yProps('documents')} />
          <Tab label="Modèles et signature" {...a11yProps('templates')} />
          <Tab label="Tableau de bord" {...a11yProps('dashboard')} />
          <Tab label="Boxes" {...a11yProps('cages')} />
          <Tab label="Suivi des dons" {...a11yProps('donation-tracking')} />
          <Tab label="Mon abonnement" {...a11yProps('functionalities')} />
        </Tabs>
      </Box>

      <TabPanel value={currentTabIndex} index={'my-association' as TabKey}>
        <AssociationMyAssociationTab association={association!} setAssociation={setAssociation} />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'dashboard' as TabKey}>
        <AssociationDashboard />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'donation-tracking' as TabKey}>
        <AssociationDonationTrackingTab association={association!} />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'functionalities' as TabKey}>
        <FunctionalitiesTab />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'documents' as TabKey}>
        <AssociationDocumentsTab association={association!} />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'templates' as TabKey}>
        <TemplatesList association={association!} />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'cages' as TabKey}>
        <AssociationCagesTab association={association!} />
      </TabPanel>
    </>
  )
}

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
