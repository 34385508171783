import { Edit } from '@mui/icons-material'
import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  TextField,
  DialogActions,
  Tooltip,
  FormControl,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { spacingItem } from '../theme'
import {
  fullName,
  getReadableAccountPermission,
  getReadableAccountRole,
  getReadableAccountVeterinaryType,
} from '../../domain/Account/AccountDisplay'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { Controller } from 'react-hook-form'
import {
  AccountDto,
  AccountDtoPermission,
  AccountDtoRoles,
  AccountDtoStatus,
  AccountVeterinaryDtoType,
} from '../../interactors/gen/backendClient'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { useAccountsStore } from '../../store/AccountsStore'
import { ControlledMultipleAutoComplete } from '../common/ControlledMultipleAutoComplete'
import { ControlledMultipleFreeSoloAutoComplete } from '../common/ControlledMultipleFreeSoloAutoComplete'
import { MemberPromptSaveButton } from './MemberPromptSaveButton'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'

interface Props {
  account: AccountDto | null
  onSubmit: (data: Partial<AccountDto>) => Promise<void>
  isOwnAccount: boolean
  isLoading: boolean
}

export interface MemberFormParams {
  firstName: AccountDto['firstName']
  lastName: AccountDto['lastName']
  email: AccountDto['email']
  phoneNumber: AccountDto['phoneNumber']
  city: AccountDto['city']
  address: AccountDto['address']
  postalCode: AccountDto['postalCode']
  country: AccountDto['country']
  permission?: AccountDto['permission']
  roles?: Array<AccountDtoRoles>
  titles?: Array<string>
  status?: AccountDtoStatus
  memberNumber?: AccountDto['memberNumber']
  veterinaryType: AccountVeterinaryDtoType | ''
}

const normalizeTitle = (title: string): string => {
  return title
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

const isTitleRestricted = (title: string, existingTitles: string[] = []): boolean => {
  const normalizedTitle = normalizeTitle(title)
  const restrictedTitles = [
    'president(e)',
    'vice-president(e)',
    'tresorier(e)',
    'secretaire general(e)',
    'president',
    'presidente',
    'vice-president',
    'vice-presidente',
    'tresorier',
    'tresoriere',
    'secretaire general',
    'secretaire generale',
    'veterinaire sanitaire',
    'pdt', // Abbreviation for president
    'vp', // Abbreviation for vice-president
    'tres.', // Possible abbreviation for tresorier
    'sec.', // Possible abbreviation for secretaire
    'sec gen', // Shorthand for secretaire general
    'vet sanitaire', // Shorthand for veterinaire sanitaire
    'vet. sanitaire', // Another possible shorthand
  ]

  // If the title is restricted but already existed, return false
  if (
    restrictedTitles.some((restrictedTitle) => normalizedTitle.includes(restrictedTitle)) &&
    existingTitles.map(normalizeTitle).includes(normalizedTitle)
  ) {
    return false
  }

  return restrictedTitles.some((restrictedTitle) => normalizedTitle.includes(restrictedTitle))
}

export const EditMemberGeneralInfoPrompt: React.FC<Props> = ({ account, onSubmit, isOwnAccount, isLoading }) => {
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()
  const globalSnackBarStore = useGlobalSnackbarStore()

  const getDefaultValues = (account: AccountDto | null): MemberFormParams => ({
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    email: account?.email || '',
    phoneNumber: account?.phoneNumber || '',
    address: account?.address || '',
    postalCode: account?.postalCode || '',
    country: account?.country || '',
    city: account?.city || '',
    permission: account?.permission,
    roles: account?.roles || [],
    titles: account?.titles || [],
    status: account?.status,
    memberNumber: account?.memberNumber,
    veterinaryType: account?.veterinary?.type || '',
  })

  const processSubmit = async (data: MemberFormParams) => {
    if (data.titles?.some((title) => isTitleRestricted(title, account?.titles))) {
      // Display an error message to the user
      console.error('You cannot assign restricted titles to yourself!')
      globalSnackBarStore.triggerErrorMessage(
        'Vous ne pouvez pas vous assigner les titres suivants: Président(e), Vice-Président(e), Trésorier(e), Secrétaire Général(e). Ces titres sont réservés aux membres du bureau et modifiables uniquement sur la page mon asso'
      )
      setValue('titles', account?.titles || [])
      return
    }
    const dataToSend: Partial<AccountDto> = data
    if (data.veterinaryType) {
      dataToSend.veterinary = {
        ...account?.veterinary,
        type: data.veterinaryType,
      }
    }

    await onSubmit(dataToSend)
    dialogOpenedState.closeDialog()
  }

  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormExtended(account, getDefaultValues)

  const validateMemberNumber = (value: string | undefined) => {
    const isUnique = !accountsStore.members
      .filter(
        (member) => member.memberNumber != null && member.memberNumber !== undefined && member.memberNumber !== ''
      )
      .some((member) => member.memberNumber === value)
    return isUnique || 'Numéro du membre déjà utilisé par un autre membre' // Return an error message if not unique
  }

  const watchRoles = watch('roles') || []
  const watchVeterinaryType = watch('veterinaryType')

  const isClinic = watchVeterinaryType === AccountVeterinaryDtoType.Clinic

  useEffect(() => {
    if (watchRoles.includes(AccountDtoRoles.Veterinary) && watchRoles.length > 1) {
      setValue('veterinaryType', 'independent')
    }
  }, [watchRoles])

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
        <Tooltip title="Editer le profil">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Editer le profil de {account ? fullName(account) : '..'}</DialogTitle>

        <form
          onSubmit={handleSubmit(processSubmit)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <TextField
                id="first-name-input"
                label="Prénom"
                type="text"
                required
                fullWidth
                {...register('firstName', { required: 'Le prénom est requis' })}
                sx={spacingItem}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                InputLabelProps={{ shrink: true }}
              />

              {!isClinic && (
                <TextField
                  id="last-name-input"
                  label="Nom de Famille"
                  type="text"
                  fullWidth
                  {...register('lastName')}
                  sx={spacingItem}
                />
              )}

              <TextField
                id="email"
                label="Email"
                required
                type="email"
                fullWidth
                {...register('email', {
                  required: 'required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "L'addresse n'est pas valide.",
                  },
                })}
                sx={spacingItem}
                error={!!errors.email}
                helperText={errors.email?.message}
                // helperText="Vous ne pouvez pas encore modifier cette information"
                InputLabelProps={{ shrink: true }}
              />

              <FormControl fullWidth variant="outlined" sx={spacingItem}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: (value: string | undefined) => {
                      console.log(value)
                      return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                        ? true
                        : 'Tel is invalid'
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                      {...field}
                      label="Numero de Téléphone"
                      defaultCountry="FR"
                      helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </FormControl>

              <ControlledMultipleAutoComplete
                label="Rôle"
                control={control}
                error={errors.firstName}
                fieldName="roles"
                options={Object.values(AccountDtoRoles).map((role: AccountDtoRoles) => ({
                  label: getReadableAccountRole(role),
                  value: role,
                }))}
                requiredRule="Ce champs est requis"
                sx={spacingItem}
              />

              {watchRoles.includes(AccountDtoRoles.Veterinary) && (
                <ControlledSelectField
                  sx={spacingItem}
                  control={control}
                  error={errors.veterinaryType}
                  fieldName="veterinaryType"
                  label="Type"
                  options={Object.values(AccountVeterinaryDtoType).map((status: AccountVeterinaryDtoType) => ({
                    label: getReadableAccountVeterinaryType(status),
                    value: status,
                  }))}
                  requiredRule="Ce champ est requis"
                  disabled={watchRoles.includes(AccountDtoRoles.Veterinary) && watchRoles.length > 1}
                />
              )}

              <ControlledMultipleFreeSoloAutoComplete
                freeSolo={true}
                control={control}
                error={undefined}
                fieldName="titles"
                label="Poste"
                options={[]}
                requiredRule=""
                sx={spacingItem}
                helperText="Pour désigner un membre du bureau, rendez vous dans l'onglet mon asso"
              />

              <TextField
                id="memberNumber"
                label="Numéro du membre"
                type="text"
                fullWidth
                {...register('memberNumber', { validate: validateMemberNumber })}
                sx={spacingItem}
                InputLabelProps={{ shrink: true }}
                error={!!errors.memberNumber}
                helperText={errors.memberNumber?.message}
              />

              <TextField
                id="address"
                label="Adresse"
                type="text"
                fullWidth
                {...register('address')}
                sx={spacingItem}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                id="postalCode"
                label="Code Postal"
                type="text"
                fullWidth
                {...register('postalCode')}
                InputLabelProps={{ shrink: true }}
                sx={spacingItem}
              />

              <TextField
                id="city"
                label="Ville"
                type="text"
                fullWidth
                {...register('city')}
                sx={spacingItem}
                error={!!errors.city}
                InputLabelProps={{ shrink: true }}
                helperText={errors.city?.message}
              />

              <TextField
                id="country"
                label="Pays"
                type="text"
                fullWidth
                {...register('country')}
                sx={spacingItem}
                error={!!errors.country}
                InputLabelProps={{ shrink: true }}
                helperText={errors.country?.message}
              />

              {accountsStore.isAdmin() && (
                <ControlledSelectField
                  label="Permission"
                  control={control}
                  error={errors.permission}
                  fieldName="permission"
                  options={Object.values(AccountDtoPermission).map((permission: AccountDtoPermission) => ({
                    label: getReadableAccountPermission(permission),
                    value: permission,
                  }))}
                  requiredRule="Ce champs est requis"
                  disabled={!accountsStore.canEdit()}
                  sx={spacingItem}
                />
              )}
            </DialogContent>

            <DialogActions>
              <MemberPromptSaveButton
                isOwnAccount={isOwnAccount}
                isLoading={isLoading}
                dialogOpenedState={dialogOpenedState}
                noClose
              />
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
