import dayjs from 'dayjs'
import { AccountDto, AccountDtoAvailabilityStatus } from '../interactors/gen/backendClient'

export const isUserAvailableToday = (account: AccountDto): AccountDtoAvailabilityStatus => {
  // If there's no availability array or it's empty, return true (available by default)
  if (!account.availability || account.availability.length === 0) {
    return AccountDtoAvailabilityStatus.Active
  }

  const today = dayjs()

  for (const period of account.availability) {
    const startDay = dayjs(period.startDate)
    const endDay = dayjs(period.endDate)

    // Check if today is within the period
    const isInPeriod = today.isBetween(startDay, endDay, null, '[]') // '[]' means inclusive of start and end date

    if (isInPeriod) {
      // Return the status of the period if today is within it
      return period.status
    }
  }

  // If today is not within any specified periods, return Active
  return AccountDtoAvailabilityStatus.Active
}
