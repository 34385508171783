import { Box, SxProps, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Control, UseFormRegister, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { StepsFormParams } from '../AddAdopterDialog'

export interface StepsProps {
  control: Control<StepsFormParams>
  register: UseFormRegister<StepsFormParams>
  errors: FieldErrors<StepsFormParams>
  setValue: UseFormSetValue<StepsFormParams>
  watch: UseFormWatch<StepsFormParams>
  fieldSx?: SxProps
  secondaryPage?: boolean
}

export const StepOne: React.FC<StepsProps> = ({ control, errors, setValue, watch, secondaryPage = false }) => {
  const validateProfile = watch('validateProfile')
  const hasAnimal = watch('hasAnimal')
  const oldAdopter = watch('oldAdopter')
  const gavedContract = watch('gavedContract')
  const gotSignedCertificate = watch('gotSignedCertificate')

  useEffect(() => {
    if (validateProfile === 'false') {
      setValue('doneAnimalMeeting', 'false')
      setValue('gavedCertificate', false)
      setValue('gotSignedCertificate', false)
    }
    if (hasAnimal === false) {
      setValue('doneAnimalMeeting', 'false')
      setValue('gavedCertificate', false)
      setValue('gotSignedCertificate', false)
    }
    if (gotSignedCertificate === false) {
      setValue('gavedContract', false)
    }
    if (gavedContract === false) {
      setValue('gotSignedContract', false)
    }
  }, [validateProfile, hasAnimal, gavedContract, gotSignedCertificate])
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'left', justifyContent: 'center' }}>
      {secondaryPage && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
          <Typography>Est-ce un ancien adoptant (adoption déjà terminée) ?</Typography>
          <ControlledToggleButton<StepsFormParams>
            control={control}
            error={errors.oldAdopter}
            fieldName="oldAdopter"
            firstOptionLabel="Oui"
            secondOptionLabel="Non"
            firstOptionValue={true}
            secondOptionValue={false}
            requiredRule="Veuillez répondre à la question"
            fullWidth
          />
        </Box>
      )}
      {!oldAdopter && (
        <>
          {' '}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
            <Typography>Avez vous déjà envoyé le formulaire ?</Typography>
            <ControlledToggleButton<StepsFormParams>
              control={control}
              error={errors.formSent}
              fieldName="formSent"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              thirdOptionLabel="Pas nécessaire"
              firstOptionValue={'true'}
              secondOptionValue={'false'}
              thirdOptionValue={'not-necessary'}
              requiredRule="Veuillez répondre à la question"
              fullWidth
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
            <Typography>Avez vous pu valider le sérieux de l&#39;adoptant ?</Typography>
            <ControlledToggleButton<StepsFormParams>
              control={control}
              error={errors.validateProfile}
              fieldName="validateProfile"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              thirdOptionLabel="Pas nécessaire"
              firstOptionValue={'true'}
              secondOptionValue={'false'}
              thirdOptionValue={'not-necessary'}
              requiredRule="Veuillez répondre à la question"
              fullWidth
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
            <Typography>Avez vous un animal précis/souhait pour l&#39;adoptant ?</Typography>
            <ControlledToggleButton<StepsFormParams>
              control={control}
              error={errors.hasAnimal}
              fieldName="hasAnimal"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={true}
              secondOptionValue={false}
              requiredRule="Veuillez répondre à la question"
              fullWidth
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
            <Typography>Avez vous déjà fait la rencontre avec l&#39;adoptant/animal ?</Typography>
            <ControlledToggleButton<StepsFormParams>
              control={control}
              error={errors.doneAnimalMeeting}
              fieldName="doneAnimalMeeting"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              thirdOptionLabel="Pas nécessaire"
              firstOptionValue={'true'}
              secondOptionValue={'false'}
              thirdOptionValue={'not-necessary'}
              requiredRule="Veuillez répondre à la question"
              fullWidth
              disabled={validateProfile === 'false' || hasAnimal === false}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
            <Typography>Avez vous déjà remis en main propre le certificat ?</Typography>
            <ControlledToggleButton<StepsFormParams>
              control={control}
              error={errors.gavedCertificate}
              fieldName="gavedCertificate"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={true}
              secondOptionValue={false}
              requiredRule="Veuillez répondre à la question"
              fullWidth
              disabled={validateProfile === 'false' || hasAnimal === false}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
            <Typography>Avez vous déjà récupéré et validé le certificat signé ?</Typography>
            <ControlledToggleButton<StepsFormParams>
              control={control}
              error={errors.gotSignedCertificate}
              fieldName="gotSignedCertificate"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={true}
              secondOptionValue={false}
              requiredRule="Veuillez répondre à la question"
              fullWidth
              disabled={validateProfile === 'false' || hasAnimal === false}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
            <Typography>Avez vous déjà remis en main propre le contrat ?</Typography>
            <ControlledToggleButton<StepsFormParams>
              control={control}
              error={errors.gavedContract}
              fieldName="gavedContract"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={true}
              secondOptionValue={false}
              requiredRule="Veuillez répondre à la question"
              fullWidth
              disabled={gotSignedCertificate === false}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'left', justifyContent: 'center' }}>
            <Typography>Avez vous déjà récupéré et validé le contrat signé ?</Typography>
            <ControlledToggleButton<StepsFormParams>
              control={control}
              error={errors.gotSignedContract}
              fieldName="gotSignedContract"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={true}
              secondOptionValue={false}
              requiredRule="Veuillez répondre à la question"
              fullWidth
              disabled={gavedContract === false}
            />
          </Box>
        </>
      )}
    </Box>
  )
}
