import { Card, CardHeader, CardContent, Box, Typography, Chip } from '@mui/material'
import React from 'react'
import { blueSky, spacingItem } from '../../theme'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { getBestCustomerTypeLabel, getReadableCustomerType } from '../../../domain/Customer/CustomerDisplay'
import { useAccountsStore } from '../../../store/AccountsStore'
import { EditTheAssociationPrompt } from './EditTheAssociationPrompt'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { EditPaymentInformationPrompt } from './EditPaymentInformationPrompt'

interface Props {
  association: CustomerDto
  onSubmit: (data: Partial<CustomerDto>) => Promise<void>
}

export const AssociationTheAssociationCard: React.FC<Props> = ({ association, onSubmit }) => {
  const accountsStore = useAccountsStore()
  return (
    <Card sx={{ ...spacingItem, height: 'calc(100% - 16px)', boxSizing: 'border-box' }}>
      <CardHeader title={`${getBestCustomerTypeLabel(association?.types, { the: true })}`} />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Type d&#39;organisation:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.types
                  ? association.types.map((type) => getReadableCustomerType(type)).join(', ')
                  : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Nom de l&#39;association:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.name ? association.name : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Numéro de SIRET:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.SIRETNumber ? association.SIRETNumber : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Numéro de RNA:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.RNANumber ? association.RNANumber : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Adresse de domiciliation:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.address ? association.address : 'Non renseigné'}
                <br />
                {association?.postalCode ? association.postalCode : 'Non renseigné'}{' '}
                {association?.city ? association.city : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Email de contact:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.email ? association.email : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Téléphone de contact:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.phoneNumber ? association.phoneNumber : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Nombre d&#39;animaux pris en charge chaque année:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.howManyAnimalsPerYear ? association.howManyAnimalsPerYear : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Nombre d&#39;animaux adoptés chaque année:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.howManyAdoptionsPerYear ? association.howManyAdoptionsPerYear : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                width: '100%',
                gap: 1,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                {`${getBestCustomerTypeLabel(association?.types, { the: true })} prend en charge`}:
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {association?.preferences?.species.map((species) => (
                  <Chip
                    key={species}
                    sx={{ backgroundColor: blueSky, color: 'white', fontWeight: 500 }}
                    label={getReadableSpecies(species, { withEmoji: false })}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          {accountsStore.canEdit() && (
            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditTheAssociationPrompt association={association} onSubmit={onSubmit} />
            </Box>
          )}
        </Box>
      </CardContent>
      <CardHeader title={`Informations de paiement`} />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
            <Typography variant="h4" sx={{ fontSize: 18, mb: 3, ml: -1, color: 'black' }}>
              Virement bancaire
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                IBAN:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.paymentInfo?.IBANNumber ? association.paymentInfo.IBANNumber : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Prénom du titulaire du compte:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.paymentInfo?.IBANFirstName ? association.paymentInfo.IBANFirstName : 'Non renseigné'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Nom du titulaire du compte:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.paymentInfo?.IBANLastName ? association.paymentInfo.IBANLastName : 'Non renseigné'}
              </Typography>
            </Box>
            <Typography variant="h4" sx={{ fontSize: 18, mb: 3, mt: 1, ml: -1, color: 'black' }}>
              Chèque
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Ordre de l&#39;association:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.paymentInfo?.checkOrder ? association.paymentInfo.checkOrder : 'Non renseigné'}
              </Typography>
            </Box>
            <Typography variant="h4" sx={{ fontSize: 18, mb: 3, mt: 1, ml: -1, color: 'black' }}>
              Paiement en ligne
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                flexWrap: 'wrap',
                width: '100%',
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                Lien de paiement:
              </Typography>
              <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 500 }}>
                {association?.paymentInfo?.onlinePaymentURL
                  ? association.paymentInfo.onlinePaymentURL
                  : 'Non renseigné'}
              </Typography>
            </Box>
          </Box>
          {accountsStore.canEdit() && (
            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditPaymentInformationPrompt association={association} onSubmit={onSubmit} />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
