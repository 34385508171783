import { Box, Card, CardContent, CardHeader, Grid, SxProps, Theme } from '@mui/material'

import { FC } from 'react'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import { getReadableAnimalFieldLabel, getReadableTakeOverReason } from '../../../../domain/Animal/AnimalDisplay'
import { AnimalDto } from '../../../../interactors/gen/backendClient'

import { ReadOnlyItem } from '../../../common/ReadOnlyItem'
import { EditAnimalTakeOverPrompt } from './EditAnimalTakeOverPrompt'
import dayjs from 'dayjs'

interface Props {
  animal: AnimalDto
  sx?: SxProps<Theme>
}

export const AnimalTakeOverCard: FC<Props> = ({ animal, sx }) => {
  const defaultItemProps = { xs: 6, sm: 6, item: true, marginBottom: 2 }

  const fullAddress = [animal.takeOver.addressOfOrigin, animal.takeOver.cityOfOrigin]
    .filter((field) => field)
    .join(', ')

  return (
    <>
      <Card sx={sx}>
        <CardHeader title="🤗 Prise en charge" />
        <CardContent>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={getReadableAnimalFieldLabel('takeOverAt')}
                    value={animal.takeOver.takeOverAt ? dayjs(animal.takeOver.takeOverAt).format('DD/MM/YYYY') : '..'}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem label="Lieu de prise en charge" value={fullAddress || '..'} />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem label="Contact" value={fullName(animal.takeOver.takeOverBy) || '..'} />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label="Motif"
                    value={animal.takeOver.reason ? getReadableTakeOverReason(animal.takeOver.reason) : '..'}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditAnimalTakeOverPrompt animal={animal} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
