import { Add, Delete, Edit, ExpandLess, ExpandMore } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  // Fab,
  Grid,
  SxProps,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import { FC, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { UseMutationResult } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { getReadableEventType } from '../../../domain/Adopters/AccountDisplay'
import {
  AccountDtoRoles,
  AdoptionAttemptDto,
  AdoptionAttemptEventDto,
  AdoptionAttemptEventDtoType,
  AnimalDto,
} from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { PATHS } from '../../PATHS'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateField } from '../../common/ControlledDateField'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { DisplayChipLikeTextField } from '../../common/DisplayChipLikeTextField'
import { blue, colors, spacingItem, theme } from '../../theme'

interface Props {
  animal: AnimalDto
  attempt: AdoptionAttemptDto
  editAttemptMutation: UseMutationResult<
    AdoptionAttemptDto,
    Error | AxiosError<unknown, unknown>,
    AdoptionAttemptDto,
    unknown
  >
  sx?: SxProps<Theme>
}

export const AdoptionAttemptEvents: FC<Props> = ({ attempt, editAttemptMutation, sx, animal }) => {
  const [events, setEvents] = useState(attempt.events || [])
  const [expandedEventIndex, setExpandedEventIndex] = useState<number | null>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const initialEventsRef = useRef(attempt.events)

  // Sync events state with attempt.events when attempt.events changes
  useEffect(() => {
    setEvents(attempt.events || [])
    initialEventsRef.current = attempt.events
  }, [attempt.events])

  useEffect(() => {
    const saveOperations = async () => {
      if (JSON.stringify(events) !== JSON.stringify(initialEventsRef.current)) {
        const updatedAttempt = { ...attempt, events: events }

        let done = false
        let paymentDone = false

        events
          .sort((a, b) => {
            const dateA = dayjs(a.date)
            const dateB = dayjs(b.date)
            return dateA.isAfter(dateB) ? 1 : -1
          })
          .forEach((event: AdoptionAttemptEventDto) => {
            if (event.type === AdoptionAttemptEventDtoType.AdoptionDone) {
              done = true
            }
            if (event.type === AdoptionAttemptEventDtoType.PaymentCancelled) {
              paymentDone = false
            }
            if (
              event.type === AdoptionAttemptEventDtoType.PaymentReception &&
              dayjs(event.date).isBefore(dayjs().add(1, 'day'), 'day')
            ) {
              paymentDone = true
            }
          })

        // Update the values based on the presence of the events
        updatedAttempt.done = done
        updatedAttempt.paymentDone = paymentDone

        await editAttemptMutation.mutateAsync(updatedAttempt)
      }
    }
    saveOperations()
  }, [events])

  return (
    <Card
      sx={{
        ...sx,
        width: '100%',
      }}
    >
      <CardHeader
        title="Suivi d'adoption"
        titleTypographyProps={{
          fontSize: isMobile ? '18px' : '20px',
        }}
        sx={{
          pt: isMobile ? 1 : 'auto',
          pb: isMobile ? 0 : 0,
        }}
      />
      <CardContent
        sx={{
          p: isMobile ? 'auto' : '12px',
          '&:last-child': { pb: isMobile ? '16px' : '12px' },
        }}
      >
        <Box pb={0}>
          {events
            .sort((a, b) => {
              const dateA = dayjs(a.date)
              const dateB = dayjs(b.date)
              return dateA.isAfter(dateB) ? -1 : 1
            })
            .map((event, index) => (
              <AdoptionAttemptEventOperation
                key={index}
                animal={animal}
                event={event}
                index={index}
                events={events}
                setEvents={setEvents}
                eventIndex={index}
                expandedEventIndex={expandedEventIndex}
                setExpandedEventIndex={setExpandedEventIndex}
              />
            ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: isMobile ? 0 : 2,
          }}
        >
          <AdoptionAttemptNewEventModal
            animal={animal}
            events={events}
            setEvents={setEvents}
            setExpandedEventIndex={setExpandedEventIndex}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

interface PropsItem {
  animal: AnimalDto
  event: AdoptionAttemptEventDto
  index: number
  events: Array<AdoptionAttemptEventDto>
  setEvents: (events: Array<AdoptionAttemptEventDto>) => void
  eventIndex: number
  expandedEventIndex: number | null
  setExpandedEventIndex: React.Dispatch<React.SetStateAction<number | null>>
}

export const AdoptionAttemptEventOperation: FC<PropsItem> = ({
  event,
  events,
  setEvents,
  index,
  animal,
  eventIndex,
  expandedEventIndex,
  setExpandedEventIndex,
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const isUpSmWidth = useMediaQuery(theme.breakpoints.up('sm'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClickOnExpand = () => {
    setExpanded(!isExpanded)
    !isExpanded && setExpandedEventIndex(eventIndex)
  }
  const handleClickOnDelete = () => {
    setEvents(events.filter((_, anIndex) => index !== anIndex))
  }

  useEffect(() => {
    if (eventIndex !== expandedEventIndex) {
      setExpanded(false)
    }
  })

  const sxField = {
    ...spacingItem,
    cursor: 'disabled',
    input: { cursor: 'not-allowed' },
  }

  const accountsStore = useAccountsStore()

  const memberInCharge = accountsStore.members.find((account) => account.id === event.memberInChargeId)

  return (
    <Box>
      <Grid container spacing={{ md: 2, xs: 1 }}>
        <Grid item xs={12} sm={6} md={3} pt={0}>
          <DisplayChipLikeTextField label="Motif" value={getReadableEventType(event.type)} color={blue} />
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <TextField
            label="Date"
            type="text"
            fullWidth
            value={event.date ? dayjs(event.date).format('DD/MM/YYYY') : 'N/A'}
            sx={sxField}
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>
        {isUpSmWidth && (
          <Grid item sm={6} md={3}>
            <TextField
              label="Membre en charge"
              type="text"
              fullWidth
              value={memberInCharge ? fullName(memberInCharge) : ''}
              sx={sxField}
              size="small"
              inputProps={{ readOnly: true }}
            />
          </Grid>
        )}
        <Grid item xs={6} sm={6} md={3}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              paddingTop: 2,
            }}
          >
            <Button onClick={handleClickOnExpand}>{isExpanded ? <ExpandLess /> : <ExpandMore />}</Button>
            <AdoptionAttemptEditEventModal
              index={index}
              events={events}
              setEvents={setEvents}
              animal={animal}
              event={event}
            />
            <Button onClick={handleClickOnDelete} sx={{ color: 'error.main' }}>
              <Delete />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={isExpanded}>
        <Grid container spacing={{ md: 2, xs: 1 }}>
          {isMobile && (
            <Grid item xs={12}>
              <TextField
                label="Membre en charge"
                type="text"
                fullWidth
                value={memberInCharge ? fullName(memberInCharge) : ''}
                sx={sxField}
                size="small"
                inputProps={{ readOnly: true }}
              />
            </Grid>
          )}
          <Grid item sm={12} xs={12} md={9}>
            <TextField
              label="Details"
              type="text"
              fullWidth
              value={event.comment}
              sx={sxField}
              size="small"
              multiline
              inputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', my: 2 }}>
        <hr />
      </Box>
    </Box>
  )
}

type FormParams = {
  type: string
  date: string // DateString
  comment: string
  memberInChargeId: string
}

interface PropsNewOperation {
  animal: AnimalDto
  events: Array<AdoptionAttemptEventDto>
  setEvents: (events: Array<AdoptionAttemptEventDto>) => void
  setExpandedEventIndex: React.Dispatch<React.SetStateAction<number | null>>
}

export const AdoptionAttemptNewEventModal: FC<PropsNewOperation> = ({
  animal,
  events,
  setEvents,
  setExpandedEventIndex,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormParams>({
    defaultValues: {
      type: '',
      date: '',
      comment: '',
      memberInChargeId: animal.responsibilities?.memberInChargeAccountId,
    },
    mode: 'onChange',
  })

  const onSubmit = (data: FormParams) => {
    const newOperation = {
      id: nanoid(),
      ...data,
      date: dayjs(data.date).toISOString(),
    }
    setEvents([...events, newOperation as AdoptionAttemptEventDto])
    closeDialog()
    setValue('type', '')
    setValue('memberInChargeId', animal.responsibilities?.memberInChargeAccountId || '')
    setValue('date', '')
    setValue('comment', '')
    setExpandedEventIndex(null)
  }

  const memberInCharge = watch('memberInChargeId')
  useEffect(() => {
    if (memberInCharge === 'addAccount') {
      reset({ memberInChargeId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=sante&role=${AccountDtoRoles.Member}&card=animalHealthEventsCard`
      )
    }
  }, [memberInCharge])

  return (
    <>
      <Button
        color="primary"
        sx={{
          textTransform: 'none',
          fontSize: { xs: '14px', sm: '16px' },
          width: { xs: '100%', sm: 'auto' },
        }}
        variant="contained"
        onClick={openDialog}
      >
        {!isMobile && <Add sx={{ mr: 1 }} />}
        {/* <Add sx={{ mr: 1 }} /> */}
        Ajouter un suivi
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Ajouter un suivi
              </Typography>
              <ControlledSelectField
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={errors.type}
                fieldName="type"
                size="small"
                label="Motif"
                options={Object.values(AdoptionAttemptEventDtoType).map((status: AdoptionAttemptEventDtoType) => ({
                  label: getReadableEventType(status),
                  value: status,
                }))}
                requiredRule={'Le type est requis'}
              />

              <ControlledDateField
                control={control}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                error={errors.date}
                fieldName={'date'}
                label="Date"
                requiredRule={undefined}
                size="small"
                sx={spacingItem}
              />

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'memberInChargeId'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un bénévole' },
                  ...accountsStore.members.map((option) => ({
                    label: fullName(option),
                    value: option.id,
                  })),
                ]}
                label="Membre en charge"
                error={errors.memberInChargeId}
                requiredRule={undefined}
                defaultValue={animal.responsibilities?.memberInChargeAccountId || ''}
                sx={spacingItem}
                highlight
              />

              <TextField
                id="additional-info-input"
                label="Détails"
                type="text"
                fullWidth
                {...register('comment')}
                sx={spacingItem}
                size="small"
                multiline
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="cancel"
              variant="outlined"
              onClick={closeDialog}
              sx={{
                textTransform: 'none',
                fontSize: isMobile ? '14px' : '16px',
                width: isMobile ? '50%' : 'auto',
                px: isMobile ? 'auto' : '50px',
              }}
            >
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              sx={{
                textTransform: 'none',
                fontSize: isMobile ? '14px' : '16px',
                width: isMobile ? '50%' : 'auto',
                px: isMobile ? 'auto' : '50px',
              }}
            >
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

interface PropsEditOperation {
  animal: AnimalDto
  events: Array<AdoptionAttemptEventDto>
  event: AdoptionAttemptEventDto
  setEvents: (events: Array<AdoptionAttemptEventDto>) => void
  index: number
}

export const AdoptionAttemptEditEventModal: FC<PropsEditOperation> = ({ animal, event, events, setEvents, index }) => {
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const accountsStore = useAccountsStore()
  const isMobile = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    if (queryParams.get('card') === JSON.stringify(event)) {
      openDialog()
    }
  }, [])

  const getDefaultValues = (event: AdoptionAttemptEventDto): FormParams => ({
    type: event.type,
    date: event.date || '',
    comment: event.comment || '',
    memberInChargeId: queryParams.get('memberInCharge') || event.memberInChargeId || '',
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useFormExtended(event, getDefaultValues)

  const onSubmit = (data: FormParams) => {
    const newOperation = {
      id: nanoid(),
      ...data,
      date: dayjs(data.date).toISOString(),
    }

    // create a copy of events
    const updatedOperations = [...events]

    // replace the event at the given index
    updatedOperations[index] = newOperation as AdoptionAttemptEventDto

    // update the state
    setEvents(updatedOperations)

    closeDialog()
  }

  const memberInCharge = watch('memberInChargeId')
  useEffect(() => {
    if (memberInCharge === 'addAccount') {
      reset({ memberInChargeId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=sante&role=${
          AccountDtoRoles.Member
        }&card=${JSON.stringify(event)}`
      )
    }
  }, [memberInCharge])

  return (
    <>
      <Button onClick={openDialog} sx={{ color: colors.black }}>
        <Edit />
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Modifier l&#39;injection
              </Typography>
              <ControlledSelectField
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={errors.type}
                fieldName="type"
                size="small"
                label="Motif"
                options={Object.values(AdoptionAttemptEventDtoType).map((status: AdoptionAttemptEventDtoType) => ({
                  label: getReadableEventType(status),
                  value: status,
                }))}
                requiredRule={'Le motif est requis'}
              />

              <ControlledDateField
                control={control}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                error={errors.date}
                fieldName={'date'}
                label="Date"
                requiredRule={undefined}
                size="small"
                sx={spacingItem}
              />

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'memberInChargeId'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un bénévole' },
                  ...accountsStore.members.map((option) => ({
                    label: fullName(option),
                    value: option.id,
                  })),
                ]}
                label="Membre en charge"
                error={errors.memberInChargeId}
                requiredRule={undefined}
                defaultValue={animal.responsibilities?.memberInChargeAccountId || ''}
                sx={spacingItem}
                highlight
              />

              <TextField
                id="additional-info-input"
                label="Détails"
                type="text"
                fullWidth
                {...register('comment')}
                sx={spacingItem}
                size="small"
                multiline
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="cancel"
              variant="outlined"
              onClick={closeDialog}
              sx={{
                textTransform: 'none',
                fontSize: isMobile ? '14px' : '16px',
                width: isMobile ? '50%' : 'auto',
                px: isMobile ? 'auto' : '50px',
              }}
            >
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              sx={{
                textTransform: 'none',
                fontSize: isMobile ? '14px' : '16px',
                width: isMobile ? '50%' : 'auto',
                px: isMobile ? 'auto' : '50px',
              }}
            >
              Modifier
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
