import {
  CustomerBureauMemberDtoTitles,
  CustomerDocumentDtoType,
  CustomerDtoTypes,
  CustomerTemplateDtoType,
  OrganizationTypeDtoType,
} from '../../interactors/gen/backendClient'
import { colors } from '../../views/theme'

export const getReadableCustomerType = (field: CustomerDtoTypes): string => {
  const map: Record<CustomerDtoTypes, string> = {
    [CustomerDtoTypes.Pound]: 'Fourrière',
    [CustomerDtoTypes.Shelter]: 'Refuge',
    [CustomerDtoTypes.Charity]: 'Association',
  }

  return map[field]
}

interface LabelOptions {
  the?: boolean
  my?: boolean
}

export const getBestCustomerTypeLabel = (types?: CustomerDtoTypes[], options?: LabelOptions) => {
  if (types && types.length > 0) {
    if (options?.the) {
      if (types.includes('charity')) {
        return "L'association"
      } else if (types.includes('shelter')) {
        return 'Le refuge'
      } else if (types.includes('pound')) {
        return 'La fourrière'
      }
      return "L'association"
    }
    if (options?.my) {
      if (types.includes('charity')) {
        return 'Mon asso'
      } else if (types.includes('shelter')) {
        return 'Mon refuge'
      } else if (types.includes('pound')) {
        return 'Ma fourrière'
      }
      return 'Mon asso'
    }
    if (types.includes('charity')) {
      return "de l'association"
    } else if (types.includes('shelter')) {
      return 'du refuge'
    } else if (types.includes('pound')) {
      return 'de la fourrière'
    }
  } else if (options?.the) {
    return "L'association"
  } else if (options?.my) {
    return 'Mon asso'
  }
  return "de l'association"
}

export const getReadableCustomerTypeOfOrganization = (field: OrganizationTypeDtoType): string => {
  const map: Record<OrganizationTypeDtoType, string> = {
    [OrganizationTypeDtoType.UniversityOrPartnerFoundation]:
      "Fondation universitaire ou fondation partenariale mentionnées respectivement aux articles L. 719-12 et L. 719-13 du code de l'éducation",
    [OrganizationTypeDtoType.CorporateFoundation]: "Fondation d'entreprise",
    [OrganizationTypeDtoType.GeneralInterestOrganization]: "Oeuvre ou organisme d'intérêt général",
    [OrganizationTypeDtoType.NonProfitEducationalInstitution]:
      "Établissement d'enseignement supérieur ou d'enseignement artistique public ou privé, d'intérêt général, à but non lucratif",
    [OrganizationTypeDtoType.NonProfitResearchInstitution]:
      "Établissement de recherche public ou privé, d'intérêt général, à but non lucratif",
    [OrganizationTypeDtoType.InsertionOrTempWorkCompany]:
      "Entreprise d'insertion ou entreprise de travail temporaire d'insertion (articles L. 5132-5 et L. 5132-6 du code du travail).",
    [OrganizationTypeDtoType.IntermediaryAssociations]:
      'Associations intermédiaires (article L. 5132-7 du code du travail)',
    [OrganizationTypeDtoType.InsertionWorkshopsAndSites]:
      "Ateliers et chantiers d'insertion (article L. 5132-15 du code du travail)",
    [OrganizationTypeDtoType.AdaptedEnterprises]: 'Entreprises adaptées (article L. 5213-13 du code du travail)',
    [OrganizationTypeDtoType.OtherOrganizations]: 'Autre organisme',
  }

  return map[field]
}

export const getReadableCustomerDocumentType = (field: CustomerDocumentDtoType): string => {
  const map: Record<CustomerDocumentDtoType, string> = {
    [CustomerDocumentDtoType.AdoptionContractModel]: "Modèle - Contrat d'adoption",
    [CustomerDocumentDtoType.HostFamilyCharter]: "Charte de Famille d'accueil",
    [CustomerDocumentDtoType.KnowledgeCertificateModel]: 'Modèle - Certificat de connaissance',
    [CustomerDocumentDtoType.Others]: 'Autres',
    [CustomerDocumentDtoType.SanitaryRegulations]: 'Réglement sanitaire',
    [CustomerDocumentDtoType.VolunteerCharter]: 'Charte du bénévole',
    [CustomerDocumentDtoType.LegalStatus]: 'Statuts juridiques',
    [CustomerDocumentDtoType.SituationNotice]: 'Avis de situation',
    [CustomerDocumentDtoType.OfficialJournalPublication]: 'Parution au Journal Officiel',
    [CustomerDocumentDtoType.BureauMembersDeclaration]: 'Déclaration des membres du Bureau',
    [CustomerDocumentDtoType.ActivityDeclaration]: "Déclaration d'activité",
  }

  return map[field]
}

export const getColorForCustomerDocumentType = (field: CustomerDocumentDtoType): string => {
  const map: Record<CustomerDocumentDtoType, string> = {
    [CustomerDocumentDtoType.AdoptionContractModel]: colors.blueSky,
    [CustomerDocumentDtoType.HostFamilyCharter]: colors.blueSky,
    [CustomerDocumentDtoType.KnowledgeCertificateModel]: colors.blueSky,
    [CustomerDocumentDtoType.Others]: colors.blueSky,
    [CustomerDocumentDtoType.SanitaryRegulations]: colors.blueSky,
    [CustomerDocumentDtoType.VolunteerCharter]: colors.blueSky,
    [CustomerDocumentDtoType.LegalStatus]: colors.blueSky,
    [CustomerDocumentDtoType.SituationNotice]: colors.blueSky,
    [CustomerDocumentDtoType.OfficialJournalPublication]: colors.blueSky,
    [CustomerDocumentDtoType.BureauMembersDeclaration]: colors.blueSky,
    [CustomerDocumentDtoType.ActivityDeclaration]: colors.blueSky,
  }

  return map[field]
}

export const getReadableCustomerTemplateType = (field: CustomerTemplateDtoType): string => {
  const map: Record<CustomerTemplateDtoType, string> = {
    [CustomerTemplateDtoType.AdoptionContractModel]: "Contrat d'adoption",
    [CustomerTemplateDtoType.KnowledgeCertificateModel]: 'Certificat de connaissance',
  }

  return map[field]
}

export const getReadableBureauMemberTitle = (field: CustomerBureauMemberDtoTitles): string => {
  const map: Record<CustomerBureauMemberDtoTitles, string> = {
    [CustomerBureauMemberDtoTitles.President]: 'Président(e)',
    [CustomerBureauMemberDtoTitles.SecretaryGeneral]: 'Secrétaire général(e)',
    [CustomerBureauMemberDtoTitles.Treasurer]: 'Trésorier(e)',
    [CustomerBureauMemberDtoTitles.VicePresident]: 'Vice-président(e)',
  }

  return map[field]
}
