import { AddCircleOutline, Delete, Edit } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getReadableAccountPreferencesWillingToSocializeWildAnimals } from '../../domain/Account/AccountDisplay'
import { getReadableSex, getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import {
  AccountDto,
  AccountDtoHomePet,
  AccountDtoHomePetSterilizedOrNeutered,
  AccountDtoHomePetVaccinated,
  AnimalDtoSex,
  AnimalDtoSpecies,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { omitEmptyString } from '../../utils/omitEmptyString'
import { ControlledAutoCompleteWithCustomValue } from '../common/ControlledAutocompleteWithCustomValue'
import { ControlledSelectField } from '../common/ControlledSelectField'

interface Props {
  account: AccountDto
  onSubmit: (data: Partial<AccountDto>) => Promise<void>
  isOwnAccount: boolean
}

export const MemberHomeAnimals: React.FC<Props> = ({ account, onSubmit, isOwnAccount }) => {
  const [allHomeAnimals, setAllHomeAnimals] = useState<AccountDtoHomePet[]>(account.home?.pets || [])
  const initialOperationsRef = useRef(account?.home?.pets || [])

  useEffect(() => {
    const saveOtherPreferences = async () => {
      // only save if vaccinations have been modified
      if (JSON.stringify(allHomeAnimals) !== JSON.stringify(initialOperationsRef.current)) {
        const update = omitEmptyString({
          ...account,
          home: {
            ...account.home,
            pets: allHomeAnimals,
          },
        })

        await onSubmit(update)
      }
    }

    saveOtherPreferences()
  }, [allHomeAnimals])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
      <Typography variant="h4" sx={{ fontSize: 18, mt: 4, mb: 1 }}>
        Présence d&#39;animaux (hors association):
      </Typography>
      {allHomeAnimals?.map((homeAnimal, i) => (
        <HomeAnimal
          key={homeAnimal.name + i.toString()}
          homeAnimal={homeAnimal}
          allHomeAnimals={allHomeAnimals}
          setAllHomeAnimals={setAllHomeAnimals}
          isOwnAccount={isOwnAccount}
        />
      ))}
      <NewHomeAnimalPrompt
        allHomeAnimals={allHomeAnimals}
        setAllHomeAnimals={setAllHomeAnimals}
        isOwnAccount={isOwnAccount}
      />
    </Box>
  )
}

interface HomeAnimalProps {
  homeAnimal: AccountDtoHomePet
  allHomeAnimals: AccountDtoHomePet[]
  setAllHomeAnimals: (data: AccountDtoHomePet[]) => void
  isOwnAccount: boolean
}

const HomeAnimal: React.FC<HomeAnimalProps> = ({ homeAnimal, allHomeAnimals, setAllHomeAnimals, isOwnAccount }) => {
  const accountsStore = useAccountsStore()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        p: 2,
        boxShadow: '0px 2px 15px 1px #F0F0F0',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        <Typography fontSize={18} fontWeight={500}>
          {homeAnimal?.specie ? `${getReadableSpecies(homeAnimal?.specie)}:` : '..'}
        </Typography>
        <Typography fontSize={18}>{`${homeAnimal.sex ? `${getReadableSex(homeAnimal.sex)},` : ''} ${
          homeAnimal.sterilizedOrNeutered === AccountDtoHomePetSterilizedOrNeutered.Yes
            ? 'stérilisé,'
            : 'non stérilisé,'
        } ${homeAnimal.vaccinated === AccountDtoHomePetVaccinated.Yes ? 'vacciné' : 'non vacciné'}`}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 2, md: 0 } }}>
        <EditHomeAnimalPrompt
          homeAnimal={homeAnimal}
          allHomeAnimals={allHomeAnimals}
          setAllHomeAnimals={setAllHomeAnimals}
          isOwnAccount={isOwnAccount}
        />
        <IconButton
          sx={{ color: 'error.main' }}
          onClick={() => {
            setAllHomeAnimals(allHomeAnimals.filter((APreference) => APreference !== homeAnimal))
          }}
          disabled={!accountsStore.isAdmin() || !isOwnAccount}
        >
          <Delete />
        </IconButton>
      </Box>
    </Box>
  )
}

interface NewAnimalProps {
  allHomeAnimals: AccountDtoHomePet[]
  setAllHomeAnimals: (data: AccountDtoHomePet[]) => void
  isOwnAccount: boolean
}

interface AnimalFormParams {
  specie: AnimalDtoSpecies
  name?: string
  sex: AnimalDtoSex
  sterilizedOrNeutered: AccountDtoHomePetSterilizedOrNeutered
  vaccinated: AccountDtoHomePetVaccinated
}

export const NewHomeAnimalPrompt: React.FC<NewAnimalProps> = ({ allHomeAnimals, setAllHomeAnimals, isOwnAccount }) => {
  const dialogOpenedState = useDialogOpened()
  const accountsStore = useAccountsStore()

  const submitProcess = (data: AnimalFormParams) => {
    const newHomeAnimal = {
      ...data,
    }
    reset()
    setAllHomeAnimals([...allHomeAnimals, newHomeAnimal])
    return dialogOpenedState.closeDialog()
  }

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<AnimalFormParams>({
    defaultValues: {
      specie: undefined,
      name: undefined,
      sex: undefined,
      sterilizedOrNeutered: undefined,
      vaccinated: undefined,
    },
    mode: 'onChange',
  })
  return (
    <>
      <Box
        onClick={() => (accountsStore.isAdmin() || isOwnAccount ? dialogOpenedState.openDialog() : null)}
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '100%',
          p: 2,
          boxShadow: '0px 2px 15px 1px #F0F0F0',
          cursor: 'pointer',
          color: 'primary.main',
          gap: 2,
          transition: 'all 0.1s ease-in-out',
          ':hover': {
            textDecoration: accountsStore.isAdmin() || isOwnAccount ? 'underline' : 'none',
            cursor: accountsStore.isAdmin() || isOwnAccount ? 'pointer' : 'not-allowed',
          },
        }}
      >
        <AddCircleOutline sx={{ fontSize: 30 }} />
        <Typography fontSize={18} fontWeight={700}>
          Ajouter un animal (hors association)
        </Typography>
      </Box>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Ajouter un nouvel animal (hors association)</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField id="name-input" label="Nom" type="text" fullWidth {...register('name')} size="small" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    defaultValue=""
                    fieldName="specie"
                    label="Espèce"
                    chip
                    error={errors.specie}
                    options={Object.values(AnimalDtoSpecies).map((status: AnimalDtoSpecies) => ({
                      label: getReadableSpecies(status, { withEmoji: false }),
                      value: status,
                    }))}
                    requiredRule="L'espèce est requise"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sex"
                    label="Sexe"
                    error={errors.sex}
                    options={Object.values(AnimalDtoSex).map((status: AnimalDtoSex) => ({
                      label: getReadableSex(status),
                      value: status,
                    }))}
                    requiredRule="Le sexe est requis"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sterilizedOrNeutered"
                    label="Stérilisé/Castré"
                    error={errors.sterilizedOrNeutered}
                    options={Object.values(AccountDtoHomePetSterilizedOrNeutered).map(
                      (status: AccountDtoHomePetSterilizedOrNeutered) => ({
                        label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                        value: status,
                      })
                    )}
                    requiredRule="Le champs stérilisation est requis"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="vaccinated"
                    label="Vaccination"
                    error={errors.vaccinated}
                    options={Object.values(AccountDtoHomePetVaccinated).map((status: AccountDtoHomePetVaccinated) => ({
                      label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                      value: status,
                    }))}
                    requiredRule="Le champs vaccination est requis"
                    size="small"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={dialogOpenedState.closeDialog}>Annuler</Button>
              <Button type="submit" variant="contained" color="primary">
                Valider
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}

const EditHomeAnimalPrompt: React.FC<HomeAnimalProps> = ({
  homeAnimal,
  allHomeAnimals,
  setAllHomeAnimals,
  isOwnAccount,
}) => {
  const dialogOpenedState = useDialogOpened()
  const accountsStore = useAccountsStore()

  const submitProcess = (data: AnimalFormParams) => {
    const newHomeAnimal = {
      ...data,
    }
    setAllHomeAnimals(allHomeAnimals.map((AnAnimal) => (AnAnimal === homeAnimal ? newHomeAnimal : AnAnimal)))

    return dialogOpenedState.closeDialog()
  }

  const getDefaultValues = (homeAnimal: AccountDtoHomePet): AnimalFormParams => ({
    specie: homeAnimal?.specie,
    name: homeAnimal?.name || undefined,
    sex: homeAnimal?.sex,
    sterilizedOrNeutered: homeAnimal?.sterilizedOrNeutered,
    vaccinated: homeAnimal?.vaccinated,
  })

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useFormExtended(homeAnimal, getDefaultValues)
  return (
    <>
      <IconButton
        sx={{ color: 'primary.main' }}
        onClick={dialogOpenedState.openDialog}
        disabled={!accountsStore.isAdmin() || !isOwnAccount}
      >
        <Edit />
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Modifier un animal (hors association)</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField id="name-input" label="Nom" type="text" fullWidth {...register('name')} size="small" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    defaultValue=""
                    fieldName="specie"
                    label="Espèce"
                    chip
                    error={errors.specie}
                    options={Object.values(AnimalDtoSpecies).map((status: AnimalDtoSpecies) => ({
                      label: getReadableSpecies(status, { withEmoji: false }),
                      value: status,
                    }))}
                    requiredRule="L'espèce est requise"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sex"
                    label="Sexe"
                    error={errors.sex}
                    options={Object.values(AnimalDtoSex).map((status: AnimalDtoSex) => ({
                      label: getReadableSex(status),
                      value: status,
                    }))}
                    requiredRule="Le sexe est requis"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sterilizedOrNeutered"
                    label="Stérilisé/Castré"
                    error={errors.sterilizedOrNeutered}
                    options={Object.values(AccountDtoHomePetSterilizedOrNeutered).map(
                      (status: AccountDtoHomePetSterilizedOrNeutered) => ({
                        label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                        value: status,
                      })
                    )}
                    requiredRule="Le champs stérilisation est requis"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="vaccinated"
                    label="Vaccination"
                    error={errors.vaccinated}
                    options={Object.values(AccountDtoHomePetVaccinated).map((status: AccountDtoHomePetVaccinated) => ({
                      label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                      value: status,
                    }))}
                    requiredRule="Le champs vaccination est requis"
                    size="small"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={dialogOpenedState.closeDialog}>Annuler</Button>
              <Button type="submit" variant="contained" color="primary">
                Valider
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
