import { AccountDto, LoginBodyDto, LoginResponseDto } from '../gen/backendClient'
import { backendClient } from './client'
import { AES } from 'crypto-ts'
import { AxiosRequestConfig } from 'axios'

export class LocalStorageToken {
  private static TOKEN_KEY = 'token'

  public static getCurrentToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY)
  }

  public static setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token)
  }

  public static removeStaticToken(): void {
    localStorage.removeItem(this.TOKEN_KEY)
  }
}

export class AuthClient {
  public static async getAccessToken(email: string, password: string): Promise<string> {
    const encryptedPassword = AES.encrypt(password, 'ff276a4457e543b3db78f1c1462df89269b42c74eb7abe82').toString()

    const config: AxiosRequestConfig<LoginBodyDto> = {
      method: 'POST',
      url: '/login',
      data: {
        email,
        password: encryptedPassword,
      },
    }

    const response = await backendClient<LoginResponseDto>(config)

    const token = response.data.access_token
    if (!token) {
      throw new Error('No token in the response')
    }

    return token
  }

  public static async getAdminAccessToken(customerId: string, password: string): Promise<string> {
    const encryptedPassword = AES.encrypt(password, 'ff276a4457e543b3db78f1c1462df89269b42c74eb7abe82').toString()

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/admin-login',
      data: {
        customerId,
        password: encryptedPassword,
      },
    }

    const response = await backendClient<LoginResponseDto>(config)

    const token = response.data.access_token
    if (!token) {
      throw new Error('No token in the response')
    }

    return token
  }

  public static async getCurrentAccount(): Promise<AccountDto> {
    const response = await backendClient.get<AccountDto>('/accounts/profile')

    return response.data
  }

  public static requestPasswordReset(email: string): Promise<void> {
    return backendClient.post('/password-reset/request', { email })
  }

  public static resetPassword(resetToken: string, newPassword: string): Promise<void> {
    return backendClient.post('/password-reset', { resetToken, newPassword })
  }

  public static updatePassword(newPassword: string): Promise<void> {
    return backendClient.post('/update-password', { newPassword })
  }
}
