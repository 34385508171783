import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { AnimalsEnteredByDateDto } from '../../../interactors/gen/backendClient'

interface AssociationDashboardEvolutionProps {
  entryData: AnimalsEnteredByDateDto[]
  exitData: AnimalsEnteredByDateDto[]
}

export const AssociationDashboardEvolution: React.FC<AssociationDashboardEvolutionProps> = ({
  entryData,
  exitData,
}) => {
  const [graphData, setGraphData] = useState<AnimalsEnteredByDateDto[]>(entryData)
  const [buttonValue, setButtonValue] = useState<boolean>(true)

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
    event.stopPropagation()
    if (newValue === 'entry') {
      setButtonValue(true)
      setGraphData(entryData)
    } else {
      setButtonValue(false)
      setGraphData(exitData)
    }
  }
  return (
    <>
      <Box marginBottom={2} marginTop={5} display="flex" justifyContent="space-between">
        <Typography variant="h5">Evolution des entrées et des sorties</Typography>
        <ToggleButtonGroup
          exclusive
          value={buttonValue ? 'entry' : 'exit'}
          onChange={handleToggleChange}
          aria-label="text alignment"
        >
          <ToggleButton value="entry" aria-label="Entrée">
            Entrée
          </ToggleButton>
          <ToggleButton value="exit" aria-label="Sortie">
            Sortie
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Grid container spacing={0} height={400}>
        {/*  <Grid item xs={12} md={2}>
          <Typography variant="h5" marginBottom={1}>
            Animaux
          </Typography>
          <List dense={false}>
            {uniqueSpecies.map((specie, index) => (
              <ListItem key={index}>
                <ListItemText primary={getReadableSpecies(specie)} />
              </ListItem>
            ))}
          </List>
          <Typography variant="h5" marginBottom={1}>
            Motif abandon
          </Typography>
          <List dense={false}>
            {uniqueReasons.map((reason, index) => (
              <ListItem key={index}>
                <ListItemText primary={getReadableTakeOverReason(reason)} />
              </ListItem>
            ))}
          </List>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="animaux" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </>
  )
}
