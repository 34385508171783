export enum BooleanOrAll {
  True = 'true',
  False = 'false',
  All = 'all',
}

export enum BooleanOrUnknownOrAll {
  True = 'true',
  False = 'false',
  Unknown = 'unknown',
  All = 'all',
}

export enum BooleanOrUnknown {
  True = 'true',
  False = 'false',
  Unknown = 'unknown',
}
