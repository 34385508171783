import { Lock, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, TextField, Typography } from '@mui/material'
import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { omit } from 'lodash'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import {
  getReadableAccountPermission,
  getReadableAccountRole,
  getReadableAccountVeterinaryType,
} from '../../domain/Account/AccountDisplay'
import { AccountsClient } from '../../interactors/clients/AccountsClient'
import {
  AccountDtoPermission,
  AccountDtoRoles,
  AccountVeterinaryDtoType,
  CreateAccountBodyDto,
  CreateAccountBodyDtoPermission,
  CustomerSubscriptionDtoPlan,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useDashboardLoaderStore } from '../../store/DashboardLoaderStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../utils/axios'
import { PATHS } from '../PATHS'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { ControlledMultipleAutoComplete } from '../common/ControlledMultipleAutoComplete'

// We need to omit the select and add empty string in union type, if not it does not work
type CreateAccountFormParams = Omit<CreateAccountBodyDto, 'permission' | 'role'> & {
  permission: CreateAccountBodyDto['permission'] | '' // adding empty string as it starts with empty string
  veterinaryType?: AccountVeterinaryDtoType
}

const renderPermissionDescription = () => (
  <Box sx={{ mt: 2, mb: 2, border: 1, borderColor: 'divider', borderRadius: 1, p: 2, bgcolor: 'background.paper' }}>
    <Typography variant="subtitle1" gutterBottom>
      Permissions:
    </Typography>
    <Typography variant="body2" gutterBottom>
      <strong>Admin:</strong> L’admin a un droit de lecture et d’édition sur l’ensemble des éléments présents sur Petso.
    </Typography>
    <Typography variant="body2" gutterBottom>
      <strong>Éditeur:</strong> Un éditeur a un droit de lecture global, mais un droit d’édition uniquement aux fiches
      des animaux dont il est marqué comme responsable.
    </Typography>
    <Typography variant="body2" gutterBottom>
      <strong>Lecteur:</strong> Un lecteur a uniquement droit de lecture sur l’ensemble de la plateforme.
    </Typography>
    <Typography variant="body2">
      <strong>Pas d’accès:</strong> Ne confère pas d’accès à la personne. Cela permet néanmoins d’enregistrer ces
      coordonnées et de l’associer à des animaux pour faciliter votre suivi.
    </Typography>
  </Box>
)

export const AddMemberScreen: FC = () => {
  const queryParams = new URLSearchParams(location.search)
  const globalSnackBarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()
  const { setIsLoading } = useDashboardLoaderStore()
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<CreateAccountFormParams>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      permission: '',
      roles: queryParams.get('role') ? [queryParams.get('role') as AccountDtoRoles] : [],
      veterinaryType: undefined,
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      country: 'France',
    },
    mode: 'onChange',
  })

  const [serverError, setServerError] = useState('')
  const [isPasswordField, setIsPasswordField] = useState(true)

  const onSubmit = async (data: CreateAccountFormParams) => {
    setIsLoading(true)
    console.log('On submit', data)

    const dataWithPotentialMockPassword = {
      ...data,
      veterinary: {
        type: data.veterinaryType,
      },
      password: data.password || 'petso',
      roles: data.roles,
      phoneNumber: ['+33', ''].includes(data.phoneNumber?.trim() || '') ? undefined : data.phoneNumber,
    }

    await AccountsClient.createAccount(
      omit(dataWithPotentialMockPassword, 'role', 'veterinaryType') as CreateAccountBodyDto
    )
      .then((account) => {
        globalSnackBarStore.triggerSuccessMessage(`Le profil de ${account.firstName} a été créé.`)
        const from = queryParams.get('from')
        if (from && from.length > 0) {
          if (queryParams.get('role') === AccountDtoRoles.Member) {
            return navigate(
              `/${from}&memberInCharge=${account.id}&card=${queryParams.get('card')}&field=${queryParams.get('field')}`
            )
          }
          if (queryParams.get('role') === AccountDtoRoles.HostFamily) {
            return navigate(
              `/${from}&hostFamilyInCharge=${account.id}&card=${queryParams.get('card')}&field=${queryParams.get(
                'field'
              )}`
            )
          }
          if (queryParams.get('role') === AccountDtoRoles.Veterinary) {
            return navigate(
              `/${from}&veterinary=${account.id}&card=${queryParams.get('card')}&field=${queryParams.get('field')}`
            )
          }
        } else {
          return navigate(PATHS.suiviBenevole.absolute)
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            setIsLoading(false)
            setServerError('Un utilisateur existe déjà avec cette adresse mail.')
            return
          }
        }

        setServerError(readableAxiosError(error).join(' '))
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const watchedPermission = watch('permission')

  const spacingItem = { marginTop: 2 }

  const maxMemberReached =
    !(
      accountsStore.connectedCustomer?.subscription?.plan === CustomerSubscriptionDtoPlan.Essential ||
      accountsStore.connectedCustomer?.subscription?.plan === CustomerSubscriptionDtoPlan.Premium
    ) && accountsStore.members.filter((member) => member.permission !== AccountDtoPermission.Silent).length >= 10

  const watchRoles = watch('roles')
  const watchVeterinaryType = watch('veterinaryType')

  const isClinic = watchVeterinaryType === AccountVeterinaryDtoType.Clinic

  /* const isOnlyVeterinary = watchRoles?.length === 1 && watchRoles[0] === AccountDtoRoles.Veterinary */

  useEffect(() => {
    if (watchRoles.includes(AccountDtoRoles.Veterinary) && watchRoles.length > 1) {
      setValue('veterinaryType', 'independent')
    }
  }, [watchRoles])

  return (
    <Box sx={{ width: '900px', marginX: 'auto', marginY: '5rem' }}>
      <Typography variant="h4">👷🏻‍♀️ Ajouter un Membre</Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledMultipleAutoComplete
          label="Rôle(s)"
          control={control}
          error={errors.firstName}
          fieldName="roles"
          options={Object.values(AccountDtoRoles).map((role: AccountDtoRoles) => ({
            label: getReadableAccountRole(role),
            value: role,
          }))}
          requiredRule={'Ce champs est requis'}
          sx={spacingItem}
          disabled={queryParams.get('role') != null}
        />
        {queryParams.get('role') != null && (
          <Typography variant="body2">
            Vous êtes en train d’ajouter un {getReadableAccountRole(queryParams.get('role') as AccountDtoRoles)}.
          </Typography>
        )}

        {watchRoles.includes(AccountDtoRoles.Veterinary) && (
          <ControlledSelectField
            sx={spacingItem}
            control={control}
            error={errors.veterinaryType}
            fieldName="veterinaryType"
            label="Type"
            options={Object.values(AccountVeterinaryDtoType).map((status: AccountVeterinaryDtoType) => ({
              label: getReadableAccountVeterinaryType(status),
              value: status,
            }))}
            requiredRule="Ce champ est requis"
            disabled={watchRoles.includes(AccountDtoRoles.Veterinary) && watchRoles.length > 1}
          />
        )}

        <TextField
          id="first-name-input"
          label={isClinic ? 'Nom de la Clinique' : 'Prénom'}
          type="text"
          required
          fullWidth
          {...register('firstName', { required: `Le ${isClinic ? 'nom de la clinique' : 'prénom'} est requis` })}
          sx={spacingItem}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
        />

        {!isClinic && (
          <TextField
            id="last-name-input"
            label="Nom de Famille"
            type="text"
            fullWidth
            {...register('lastName')}
            sx={spacingItem}
          />
        )}

        <TextField
          id="email"
          label="Email"
          required
          type="email"
          fullWidth
          {...register('email', {
            required: 'required',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "L'addresse n'est pas valide.",
            },
          })}
          sx={spacingItem}
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        <FormControl fullWidth variant="outlined" sx={spacingItem}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              validate: (value: string | undefined) => {
                console.log(value)
                return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                  ? true
                  : 'Tel is invalid'
              },
            }}
            render={({ field, fieldState }) => (
              <MuiTelInput
                {...field}
                label="Numero de Téléphone"
                defaultCountry="FR"
                helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                error={!!fieldState.error}
              />
            )}
          />
        </FormControl>

        <TextField id="address" label="Adresse" type="text" fullWidth {...register('address')} sx={spacingItem} />

        <TextField
          id="postalCode"
          label="Code Postal"
          type="text"
          fullWidth
          {...register('postalCode')}
          sx={spacingItem}
        />

        <TextField
          id="city"
          label="Ville"
          type="text"
          fullWidth
          {...register('city')}
          sx={spacingItem}
          error={!!errors.city}
          helperText={errors.city?.message}
        />

        <TextField
          id="country"
          label="Pays"
          type="text"
          fullWidth
          {...register('country')}
          sx={spacingItem}
          error={!!errors.country}
          helperText={errors.country?.message}
        />

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.permission}
          fieldName="permission"
          label="Permission"
          options={Object.values(AccountDtoPermission).map((status: AccountDtoPermission) => ({
            label: getReadableAccountPermission(status),
            value: status,
          }))}
          requiredRule="Ce champ est requis"
        />

        {renderPermissionDescription()}

        {watchedPermission !== CreateAccountBodyDtoPermission.Silent && (
          <TextField
            fullWidth
            type={isPasswordField ? 'password' : 'text'}
            label="Mot de passe"
            {...register('password', { required: 'Le mot de passe est requis' })}
            sx={spacingItem}
            InputProps={{
              startAdornment: (
                <div style={{ marginRight: '8px' }}>
                  <Lock />
                </div>
              ),
              endAdornment: (
                <IconButton
                  color="default"
                  size="small"
                  edge="end"
                  onClick={() => setIsPasswordField(!isPasswordField)}
                >
                  {isPasswordField ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
        )}

        {serverError && (
          <Box sx={spacingItem}>
            <Typography color="error">{serverError}</Typography>
          </Box>
        )}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...spacingItem,
          }}
        >
          {maxMemberReached && (
            <Typography variant="body2" color="text.secondary">
              Vous avez atteint la limite de création de membre avec accès pour votre offre. Vous pouvez créer un
              nouveau membre sans accès ou alors{' '}
              <Link style={{ color: 'black' }} to={`${PATHS.association.absolute}?tab=functionalities`}>
                souscrire à l&#39;une de nos offres
              </Link>
              .
            </Typography>
          )}
          <Button
            variant="contained"
            sx={{ width: '100%' }}
            type="submit"
            disabled={
              (watchedPermission !== AccountDtoPermission.Silent && maxMemberReached) || !accountsStore.isAdmin()
            }
          >
            Ajouter
          </Button>
        </Box>
      </form>
    </Box>
  )
}
