import { Search as SearchIcon } from '@mui/icons-material'
import { Box, Card, TextField, InputAdornment, Pagination, CardHeader } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { AnimalsTable, SortColumn, SortDirection } from '../Animals/AnimalsTable'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { spacingItem } from '../theme'
import { useAnimalStore } from '../../store/AnimalStore'
import { useFetchOrRefreshAnimalsOnMount } from '../../store/useFetchOrRefreshAnimalsOnMount'
import { BooleanOrAll } from '../../domain/enums/Boolean.enum'
import { debounce } from 'lodash'
import { DesiredAnimalConfirmModal } from './DesiredAnimalConfirmModal'
import { fullName } from '../../domain/Adopters/AccountDisplay'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty } from '../Animals/AnimalsListScreen'
import { AnimalAdoptionDtoStatus } from '../../interactors/gen/backendClient'
import { useFetchOrRefreshAdoptersOnMount } from '../../store/useFetchOrRefreshAdoptersOnMount'
import { NewAdoptionAttemptConfirmModal } from './NewAdoptionAttemptConfirmModal'

export const AssignAnimalToAdopter: React.FC = () => {
  const queryParams = new URLSearchParams(location.search)
  const type = queryParams.get('type')

  useFetchOrRefreshAdoptersOnMount()

  const [animalIdToAssign, setAnimalIdToAssign] = React.useState<string | null>(null)
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('adoptionStatus')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(type !== 'pastAdoption' ? 'asc' : 'desc')
  const [page, setPage] = React.useState(1)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState('')
  const maxAnimalPerPage = 50

  const adoptionStatusFilter: AnimalAdoptionDtoStatus[] =
    type === 'pastAdoption'
      ? ['has-been-adopted', 'cannot-be-adopted', 'adoptable', 'in-the-process-of-being-adoptable', 'to-be-filled']
      : []

  const cannotBeAdoptedStatusFilter: AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty[] =
    type === 'pastAdoption'
      ? ['long-term-with-hosted-family', 'in-care', 'being-socialized', 'in-gestation', 'in-lactation', 'empty']
      : []

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchQuery(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query !== debouncedSearchQuery) {
        setDebouncedSearchQuery(query)
        setPage(1) // Reset page as well when searchQuery changes
      }
    }, 500),
    [debouncedSearchQuery]
  )

  useEffect(() => {
    debouncedSearch(searchQuery)

    // Clean up function
    return () => {
      debouncedSearch.cancel()
    }
  }, [searchQuery, debouncedSearch])

  useFetchOrRefreshAnimalsOnMount(
    page,
    maxAnimalPerPage,
    {
      myAnimalsOnly: false,
      hasIdentificationNumberFilter: BooleanOrAll.All,
      sterilizedOrNeuteredFilter: [],
      adoptionStatusFilter: adoptionStatusFilter,
      stepFilter: [],
      cannotBeAdoptedStatusFilter: cannotBeAdoptedStatusFilter,
      hasExitDateFilter: null,
      speciesFilter: [],
      sexFilter: null,
    },
    debouncedSearchQuery,
    sortColumn,
    sortDirection
  )

  const animalStore = useAnimalStore()
  const adoptersStore = useAdoptersStore()

  const adopter = adoptersStore.adopters.find((adopter) => adopter.id === queryParams.get('adopterId'))
  return (
    <Box
      sx={{
        display: 'flex',
        padding: 1,
        paddingBottom: 7.5,
        backgroundColor: 'grey.100',
        minHeight: `calc(100% - 20px)`,
        boxSizing: 'border-box',
        marginBottom: 2,
        maxHeight: `calc(100% - 20px)`,
      }}
    >
      {animalStore.isLoading ? (
        <CircularProgressPanel />
      ) : (
        <>
          {type === 'newAdoption' ? (
            <NewAdoptionAttemptConfirmModal animalId={animalIdToAssign} />
          ) : (
            <DesiredAnimalConfirmModal animalId={animalIdToAssign} type={type} />
          )}

          <Card
            sx={{
              ...spacingItem,
              marginLeft: { xs: 0, sm: 2 },
              width: '100%',
              maxHeight: '100%',
              boxSizing: 'border-box',
            }}
          >
            <CardHeader
              title={`Sélectionnez ${
                type === 'pastAdoption'
                  ? "l'animal déjà adopté par"
                  : type === 'newAdoption'
                  ? "l'animal que veut adopter"
                  : "le souhait d'adoption de"
              } ${adopter && fullName(adopter)}`}
            />
            <Box display="flex" flexDirection="row" flexWrap="nowrap">
              <TextField
                id="name-input"
                placeholder="Rechercher un animal (nom, nº d'immatriculation, membres...)"
                type="text"
                fullWidth
                value={searchQuery}
                onChange={onChangeSearch}
                sx={{
                  borderRadius: 0,
                  [`& fieldset`]: {
                    borderRadius: 0,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                maxHeight: '100%',
                boxSizing: 'border-box',
                overflow: 'auto',
                paddingBottom: 7, // HACK: To avoid the last row to be hidden
              }}
            >
              {animalStore.isLoading ? (
                <CircularProgressPanel />
              ) : (
                <AnimalsTable
                  animals={animalStore.animals}
                  setSortColumn={setSortColumn}
                  setSortDirection={setSortDirection}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  disableFrontSorting={true}
                  assignAnimalId={setAnimalIdToAssign}
                />
              )}
              <Pagination
                count={Math.ceil(animalStore.animalsCount / maxAnimalPerPage)}
                page={page}
                onChange={(_, page) => {
                  setPage(page)
                }}
                sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, paddingBottom: 10 }}
              />
            </Box>
          </Card>
        </>
      )}
    </Box>
  )
}
