import { BaseTextFieldProps, FormControl, SxProps, Theme } from '@mui/material'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { frFR } from '@mui/x-date-pickers/locales'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { toDayJSFromAll } from '../../utils/date/DateOnly'

type Props<T extends FieldValues> = {
  sx?: SxProps<Theme>
  control: Control<T>
  validate: (date: dayjs.Dayjs | null) => boolean
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  minDate?: dayjs.Dayjs
  maxDate?: dayjs.Dayjs
}

dayjs.locale('fr')

export function ControlledDateAndTimeField<T extends FieldValues>({
  sx,
  control,
  validate,
  error,
  fieldName,
  requiredRule,
  size,
  label = 'Date de naissance',
  disabled,
  minDate,
  maxDate,
}: PropsWithChildren<Props<T>>) {
  return (
    <FormControl fullWidth variant="outlined" sx={sx} size={size}>
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: requiredRule,
          validate: (dateTime: string | null | undefined) => {
            const formattedDateTime = toDayJSFromAll(dateTime || null)
            const isValid = validate(formattedDateTime)
            return isValid ? true : `La date et l'heure ne sont pas valides.`
          },
        }}
        render={({ field: { onChange, value } }) => {
          const formattedValue = toDayJSFromAll(value)

          return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <MobileDateTimePicker
                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                value={formattedValue || null}
                onChange={(newValue) => {
                  onChange(newValue)
                }}
                label={label}
                disabled={disabled}
                minDateTime={minDate}
                maxDateTime={maxDate}
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error?.message,
                    size,
                  },
                }}
                ampm={false} // Set to false if you want to use 24-hour clock
              />
            </LocalizationProvider>
          )
        }}
      />
    </FormControl>
  )
}
