import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material'
import React from 'react'
import { useAccountsStore } from '../../store/AccountsStore'
import { useMutation } from 'react-query'
import { AccountsClient } from '../../interactors/clients/AccountsClient'
import { AccountDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'

interface Props {
  isOpen: boolean
  close: () => void
}

export const CGUPopUp: React.FC<Props> = ({ isOpen, close }) => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()
  const account = accountsStore.connectedAccount

  const editAccountMutation = useMutation(
    async (account: AccountDto) => {
      return await AccountsClient.editAccount(account)
    },
    {
      onSuccess: (account) => {
        accountsStore.changeConnectedAccount(account)
        globalSnackBarStore.triggerSuccessMessage('Vous avez accepté les CGU')
        close()
      },
    }
  )

  const acceptCGU = () => {
    if (!account) return
    editAccountMutation.mutate({
      ...account,
      hasAcceptedTerms: true,
    })
  }
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: { width: 900, maxWidth: '90%' },
      }}
    >
      <DialogContent dividers={true} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ marginBottom: 0 }}>Conditions d’utilisation du Site Web et des Services</h1>
          <p style={{ marginTop: 0 }}>https://petso.fr/</p>
        </Box>
        <p>Date de dernière mise à jour: 09/01/2023</p>
        <p>
          Bienvenue sur notre site web <a href="https://petso.fr/">https://petso.fr/</a> (le « <b>Site Web</b> »).
        </p>
        <p>
          Le Site Web appartient à et est édité par <b>Petso</b>, société par actions simplifiée, dont le siège social
          est situé 5 rue de Bérite (75006) à Paris, immatriculée au registre du commerce et des sociétés de Paris sous
          le numéro 953 397 056 (également « <b>nous</b> », « <b>notre</b> » et « <b>nos</b> »).
        </p>
        <p>
          Les présentes conditions d’utilisation du Site Web (« <b>CdU</b> ») ont pour objet de définir les modalités et
          conditions selon lesquelles nous mettons à disposition le Site Web ainsi que les services disponibles sur
          ledit Site Web (les « <b>Services</b> »). L’utilisation du Site Mobile est soumis au respect des Conditions
          Générales d’Utilisation par les visiteurs du Site Web utilisateurs (le ou les « <b>Utilisateur(s)</b> »).
        </p>
        <p>
          Le Site Web et les Services qui y sont proposés sont hébergés par <b>Vercel, Inc.</b>, société de droit
          états-unien incorporée dans l’Etat du Delaware, dont le siège social est situé 440 N Barranca Ave #4133
          Covina, CA 91723, Californie, Etats-Unis.
        </p>
        <p>Les présentes CGU régissent votre accès au Site Web et l’utilisation que vous en faites.</p>
        <p>
          <b>
            Veuillez lire attentivement les présentes CdU avant d’utiliser le Site Web. En utilisant le Site Web, vous
            reconnaissez avoir lu et accepté d’être lié(e) par les présentes CdU. Si vous n’acceptez pas l’ensemble des
            présentes CdU, veuillez ne pas accéder au Site Web ou utiliser les Services.
          </b>
        </p>

        <h2 style={{ marginTop: '30px' }}>1. Accès au Site Web et aux Services</h2>
        <p>
          Le Site Web et les Services qui y sont disponibles sont accessibles depuis n’importe quel pays et à toute
          heure.
        </p>
        <p>
          Le Site Web est accessible depuis votre terminal via le réseau mis à votre disposition par votre opérateur
          téléphonique et ou Internet. L’Accès au Site Web est gratuit. Néanmoins, tous les frais afférents à
          l’utilisation du Site Web tels que les frais d’accès à Internet ou de communication engendrés par votre
          utilisation du Site Web restent à votre charge.
        </p>
        <p>
          Vous êtes à ce titre seul responsable du bon fonctionnement de votre terminal ainsi que de son accès au réseau
          Internet et de téléphonie mobile. Tout usage du Site Web ou des Services engage votre responsabilité.
        </p>

        <h2 style={{ marginTop: '30px' }}>2. Utilisation du Site Web et des Services</h2>
        <p>Vous assumez l’entière responsabilité de l’utilisation du Site Web et des Services.</p>
        <p>En utilisant le Site Web et les Services, vous vous engagez à ne pas:</p>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <li>
            Utiliser le Site Web de manière à l’interrompre ou l’endommager, ou qui est susceptible de l’endommager,
            d’en altérer la disponibilité ou l’accessibilité ou d’en entraver le bon fonctionnement ;
          </li>
          <li>
            Contourner ou tenter de contourner les mesures de sécurité du Site Web et altérer les technologies qui sont
            utilisées par le Site Web ;
          </li>
          <li>
            Obtenir ou tenter d’obtenir des informations, des matériaux ou des documents qui ne sont pas délibérément
            mis à disposition via le Site Web par quelque moyen que ce soit (tels que des données personnelles,
            financières ou autres informations confidentielles ou sensibles) ;
          </li>
          <li>Utiliser le Site Web ou le Contenu à des fins illégales ou interdites par les présentes CdU ;</li>
          <li>Utiliser le Site Web d’une manière qui porte atteinte aux droits des tiers.</li>
        </ul>
        <p>
          Vous vous interdisez de copier, reproduire, republier, redistribuer, télécharger, poster, afficher, traduire,
          transmettre d’aucune manière, tout ou partie du Site Web ou de son Contenu vers un autre ordinateur, serveur,
          site ou autre support à des fins de publication ou de distribution ou dans un but commercial, sans
          l’autorisation écrite préalable de Petso.
        </p>

        <h2 style={{ marginTop: '30px' }}>3. Droits de propriété intellectuelle</h2>
        <p>
          La présentation et le contenu du Site Web sont protégés par le droit d’auteur, le droit des marques, et autres
          droits de propriété intellectuelle, qu’ils soient enregistrés ou non, ainsi que par le droit de la concurrence
          déloyale.
        </p>
        <p>
          Conformément aux articles L. 111-1 et suivants du Code de la propriété intellectuelle, Petso est titulaire des
          droits de propriété intellectuelle sur le Site Web.
        </p>
        <p>
          Certains éléments du Site Web peuvent être protégés par des droits de propriété intellectuelle dont Petso soit
          des tiers tels que les refuges, les adoptants ou les donateurs sont titulaires. Dans ce dernier cas, Petso a
          préalablement obtenu des tiers les autorisations nécessaires à leur utilisation.
        </p>
        <p>
          Les reproductions par l’Utilisateur, sur un support quel qu’il soit, dudit Site Web et/ou des œuvres qui y
          sont reproduites sont autorisées sous réserve qu’elles soient strictement et exclusivement réservées à un
          usage personnel, excluant tout usage à des fins publicitaires et/ou commerciales et/ou d’information ; et
          qu’elles soient conformes aux dispositions de l’article L. 122-5 du Code de la propriété intellectuelle.
        </p>
        <p>
          Il est rappelé, en conformité avec les articles L. 335-2 et L. 521-4 du Code de la propriété intellectuelle,
          qu’à l’exception des dispositions ci-dessus, toute reproduction, représentation, utilisation ou modification,
          par quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie du Site Web, de tout ou
          partie des différentes œuvres qui le composent, sans avoir obtenu l’autorisation préalable de Petso, sont
          strictement interdites.
        </p>
        <p>
          Aucune disposition des présentes CdU ne saurait être interprétée comme vous conférant, de manière expresse ou
          implicite, une quelconque licence ou quelconque droit d’utiliser tout ou partie du Site Web appartenant à
          Petso, sans l’autorisation écrite préalable de Petso ou des tiers détenteurs des droits de propriété
          intellectuelle concernés.
        </p>

        <h2 style={{ marginTop: '30px' }}>4. Eléments mis en ligne par vous</h2>
        <p>
          Vous reconnaissez et acceptez que toute suggestion, proposition, idée, concept, photographie, texte ou tout
          autre contenu et matériel téléchargé via ou envoyé par l’intermédiaire du Site Web ou autrement sera considéré
          comme non confidentiel et non exclusif. Vous conservez tous vos droits de propriété sur les éléments mis en
          ligne mais en téléchargeant les éléments mis en ligne, vous nous accordez une licence mondiale, irrévocable,
          gratuite et non exclusive comprenant le droit d’utiliser, exploiter, stocker, copier, reproduire, modifier,
          publier, distribuer et mettre à la disposition des tiers tout ou partie des éléments mis en ligne, sous
          quelque forme et support que ce soit, connu ou inconnu à ce jour, et à toutes fins, y compris la publicité, la
          promotion ou le développement de produits et à d’autres fins commerciales et ce, pour la durée de protection
          des éléments mis en ligne.
        </p>
        <p>Vous déclarez et garantissez que les éléments mis en ligne:</p>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <li>
            ne sont pas illégaux, trompeurs, nuisibles, menaçants, abusifs, constitutifs de harcèlement, n’incitent pas
            à la haine, diffamatoires, offensants, violents, obscènes, pornographiques, calomnieux, offensant à l’égard
            d’une race, une ethnie ou une religion ou autrement inappropriés ;
          </li>
          <li>
            ne constituent pas une divulgation non autorisée de données à caractère personnel ou d’informations
            confidentielles ;
          </li>
          <li>n’enfreignent pas les droits de propriété intellectuelle de tiers ;</li>
          <li>ne contiennent pas de virus ou toute autre forme de programme malveillant.</li>
        </ul>
        <p>
          Vous êtes entièrement responsable du contenu (y compris la légalité, l’exactitude, la véracité et la
          pertinence) de tout élément mis en ligne ou de toute publication ou contribution effectuée par vos soins.
        </p>
        <p>
          Nous nous réservons le droit de rejeter ou supprimer tout élément mis en ligne ou de supprimer toute
          publication effectuée par vos soins sur le Site Web, pour quelque raison que ce soit ou sans raison, en ce
          compris si cet élément mis en ligne ou cette publication enfreint selon nous les présentes CdU.
        </p>

        <h2 style={{ marginTop: '30px' }}>5. Données personnelles et cookies</h2>
        <p>
          Dans le cadre de votre utilisation du Site Web et des Services, nous sommes susceptibles de collecter,
          d’utiliser et de stocker des données personnelles vous concernant, permettant de vous identifier directement
          ou indirectement sur le Site Web (les « <b>Données</b> »).
        </p>
        <p>
          Nous nous engageons à respecter la législation applicable en matière de protection des données et notamment du
          Règlement Général relatif à la Protection des Données Personnelles (également appelé RGPD) et de la loi dite
          “Informatique et Libertés” du 6 janvier 1978 modifiée.
        </p>
        <p>
          Pour tout traitement de données personnelles en lien avec votre utilisation du Site Web ou des Services, le
          responsable de traitement est Petso.
        </p>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <li>
            <b>Lors de votre inscription sur les Site Web :</b>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <li>
                <span style={{ textDecoration: 'underline' }}>Pour les Associations, refuges et fourrières:</span> Type
                d’organisation, descriptif de la structure, nom de l’organisation, numéro de Siret ou/et RNA, forme
                juridique, statuts, avis de situation, parution au Journal Officiel, adresse de domiciliation, code
                postal, ville pays, téléphone et mail de contact, type d’animaux pris en charge, estimation du nombre
                d’animaux pris en charge et adoptés par an, coordonnées du représentant légal, membres du bureaux et du
                vétérinaire sanitaire (si pertinent), Attestation de Connaissances pour les Animaux de compagnie
                d’espèces domestiques pour au moins l’un des membres du bureau, déclaration des membres du bureau, Cerfa
                15045*3 (si pertinent) ;
              </li>
              <li>
                <span style={{ textDecoration: 'underline' }}>Pour les familles d’accueil:</span> Prénom, Nom de
                famille, Email et téléphone personnel, Rôle dans l’organisation, Adresse, code postal, ville, pays ;
              </li>
              <li>
                Pour les vétérinaires: Prénom, Nom de famille, Email et téléphone personnel, Rôle dans l’organisation,
                Adresse, code postal, ville, pays, nom de la clinique vétérinaire (si pertinent), coordonnées de la
                clinique vétérinaire (si pertinent) ;
              </li>
              <li>
                <span style={{ textDecoration: 'underline' }}>Pour les adoptants:</span> Prénom, Nom de famille, Email
                et téléphone personnel, Adresse, code postal, ville, pays ;
              </li>
              <li>
                <span style={{ textDecoration: 'underline' }}>Pour les mairies et collectivités:</span> Type
                d’organisation, descriptif de la structure, nom de l’organisation, numéro de Siret, forme juridique,
                adresse de domiciliation, code postal, ville pays, téléphone et mail de contact, coordonnées du
                représentant légal.
              </li>
            </ul>
          </li>
          <li>
            <b>Lors de votre utilisation du Site Web et des Services :</b>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <li>
                <span style={{ textDecoration: 'underline' }}>Pour les adoptants:</span> Prénom, Nom, Mail et téléphone
                personnel de contact, adresse postale (adresse, code postal, ville, pays); nombres de nourrissons, de
                jeunes enfants, d’adolescents, d’adultes et présence d’autres animaux au sein du foyer et
                caractéristiques (race, sexe, âge, statut de vaccination, statut de stérilisation), type d’habitation et
                nombres d’accès à l’extérieur sécurisée ou non; mode de travail (domicile, hybride, bureau...),
                motivation à adopter un animal, présence de balcons ou jardins sécurisés ou non, nombre de salles de
                quarantaines au sein du foyer, capacité d’accueil, caractéristiques des préférences de prises en charges
                des animaux géré par l’organisation (race, sexe, âge, statut de vaccination, statut de stérilisation).
              </li>
            </ul>
          </li>
        </ul>
        <p>
          En outre, nous sommes susceptibles d’utiliser des cookies afin de nous permettre de réaliser des statistiques
          de visites et de vous proposer de la publicité ciblée.
        </p>
        <p>
          Vous pouvez choisir d’accepter le dépôt de cookies sur votre terminal et revenir sur cette décision à tout
          moment en modifiant les paramètres de votre navigateur internet. Selon le type de navigateur, vous disposerez
          des options suivantes : accepter ou rejeter les cookies de toute origine ou d’une provenance donnée ou encore
          programmer l’affichage d’un message vous demandant votre accord à chaque fois qu’un cookie est déposé sur
          votre terminal.
        </p>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <li>
            Chrome :
            <a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en" rel="noreferrer" target="_blank">
              supprimer, autoriser et gérer les cookies dans Chrome
            </a>
          </li>
          <li>
            Firefox :
            <a
              href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences?redirectlocale=fr&redirectslug=activer-desactiver-cookies"
              rel="noreferrer"
              target="_blank"
            >
              activer et désactiver les cookies dans Firefox
            </a>
          </li>
          <li>
            Internet explorer :
            <a
              href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
              rel="noreferrer"
              target="_blank"
            >
              supprimer et gérer les cookies dans Internet explorer
            </a>
          </li>
          <li>
            Opera :
            <a href="http://help.opera.com/Windows/10.20/fr/cookies.html" rel="noreferrer" target="_blank">
              gérer les cookies dans Opera
            </a>
          </li>
          <li>
            Safari :
            <a
              href="https://support.apple.com/kb/PH21411?viewlocale=fr_FR&locale=fr_FR"
              rel="noreferrer"
              target="_blank"
            >
              gérer les cookies et les données de sites web avec Safari
            </a>
          </li>
        </ul>

        <h2 style={{ marginTop: '30px' }}>6. Limitation de responsabilité</h2>
        <p>Votre utilisation du Site Web et des Services se fait sous votre propre responsabilité.</p>
        <p>
          Dans toute la mesure permise par la loi, et sous réserve des stipulations des présentes CdU, notre
          responsabilité envers vous résultant de ou en lien avec votre utilisation du Site Web ou des Services ou avec
          tout adoption faite par vous, qu’elle ait été fondée sur votre consultation du Site Web ou des Services ou
          non, est exclue, en ce compris tout dommage direct ou indirect. En particulier, nous ne sommes pas
          responsables de tout dommage, toute perte de profits, perte d’affaires, pertes d’exploitation, dommage à vos
          équipements et/ou logiciels informatiques, perte de données ou perte d’opportunités d’affaires qui pourraient
          résulter de votre utilisation du Site Web ou des Services. Vous reconnaissez et acceptez que la présente
          stipulation reflète l’équilibre et la répartition des risques voulus sous les présentes CdU.
        </p>
        <p>
          Dans toute la mesure permise par la loi, nous excluons toute garantie concernant l’ensemble du Site Web et des
          Services. Nous ne pouvons notamment garantir que le Site Web et les Services ne contiennent pas d’erreur,
          omission, approximation, défaut de mise à jour ou autre défaut, ni que le Site Web ne souffre d’aucun délai ou
          indisponibilité.
        </p>
        <p>
          Certaines offres et publication concernant des adoptions figurant sur le Site Web sont publiées par des tiers.
          Nous déclinons toute responsabilité pour de tels contenus.
        </p>
        <p>
          Nous excluons toute garantie concernant le fait que le Site Web et les Services n’enfreindront pas les droits
          de propriété intellectuelle de tiers, et déclinons toute responsabilité à cet égard.
        </p>

        <h2 style={{ marginTop: '30px' }}>7. Mises à jour des présentes Conditions d’utilisation</h2>
        <p>
          Nous pouvons modifier ou mettre à jour tout ou partie des présentes CdU, de temps à autre, sans préavis. La
          version la plus récente des présentes CdU sera toujours publiée sur le Site Web. Les CdU révisées entreront en
          vigueur et seront applicables dès leur publication.
        </p>

        <h2 style={{ marginTop: '30px' }}>8. Résiliation et suspension</h2>
        <p>
          Petso peut, à son entière discrétion et sans préavis, refuser, restreindre, suspendre ou résilier votre accès
          au Site Web et/ou aux Services ou leur utilisation, sans engager sa responsabilité, notamment si vous
          enfreignez les présentes CdU, ou en cas de violation des droits de Petso ou de tout tiers, sans préjudice de
          tout autre recours dont nous disposons en vertu de la loi applicable ou des présentes CdU.
        </p>
        <p>
          Petso peut également modifier le Site Web et/ou les Services ou cesser de les fournir, sans préavis et sans
          engager sa responsabilité.
        </p>
        <p>
          Vous acceptez que Petso ne soit pas responsable vis-à-vis de vous ou des tiers en conséquence de ladite
          résiliation, suspension, modification ou cessation.
        </p>
        <p>
          Les clauses intitulées « Limitation de responsabilité » et « Dispositions générales » survivront à la
          résiliation des présentes CdU.
        </p>

        <h2 style={{ marginTop: '30px' }}>9. Dispositions générales</h2>
        <p>
          Si une quelconque disposition, ou partie d’une disposition, des présentes CdU était jugée illégale, invalide
          ou inapplicable, elle sera considérée comme ne faisant pas partie des présentes CdU, et n’affectera pas la
          légalité, la validité ou le caractère exécutoire des dispositions restantes des présentes CdU.
        </p>
        <p>
          Les présentes CdU constituent l’intégralité de l’accord entre vous et Petso en rapport avec l’utilisation du
          Site Web et des Services, et annulent et remplacent toutes les discussions, communications, conversations et
          accords précédents, orales ou écrites, relatifs à ce sujet.
        </p>
        <p>
          Aucune renonciation de Petso à faire valoir une disposition des présentes CdU ne sera interprétée comme une
          renonciation de ladite disposition ou à toute autre disposition.
        </p>

        <h2 style={{ marginTop: '30px' }}>10. Loi applicable et attribution de compétence</h2>
        <p>
          Les présentes CdU ainsi que votre accès ou votre utilisation du Site Web seront régis par et interprétés
          conformément à la loi française, sans égard aux principes régissant les conflits de lois.
        </p>
        <p>
          Tout litige découlant de, ou en rapport avec, la validité, l’interprétation, la violation ou la résiliation
          des présentes CdU, et que les parties n’auront pu résoudre à l’amiable, sera soumis à la compétence exclusive
          des tribunaux de Paris.
        </p>

        <h2 style={{ marginTop: '30px' }}>11. Nous contacter</h2>
        <p>Pour toute question ou commentaire à propos des présentes CdU, veuillez écrire à : </p>
        <p>
          Petso <br />5 rue de Bérite, 75006 Paris
        </p>
        <p>
          ou à :{' '}
          <a href="mailto:contact@petso.fr" target="_blank" rel="noreferrer">
            contact@petso.fr
          </a>
          .
        </p>
        <p>
          Si vous souhaitez demander une copie des données personnelles que nous détenons sur vous, veuillez écrire à :{' '}
          <a href="mailto:contact@petso.fr" target="_blank" rel="noreferrer">
            contact@petso.fr
          </a>
          .
        </p>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={acceptCGU} loading={editAccountMutation.isLoading}>
          J&#39;accepte
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
