import { Box, Button, Collapse, Grid, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'

import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material'

import { spacingItem, theme } from '../../../theme'
import { getColorForContactFollowUpType, getReadableContactFollowUpType } from '../../../../domain/Animal/AnimalDisplay'
import { DisplayChipLikeTextField } from '../../../common/DisplayChipLikeTextField'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import { AnimalDto, ContactHostedFamilyFollowUpDto } from '../../../../interactors/gen/backendClient'
import { EditContactFollowUpModal } from './EditContactFollowUpModal'
import { animalButtonShouldBeDisabled } from '../../../../utils/animalButtonShouldBeDisabled'

interface PropsItem {
  followUp: ContactHostedFamilyFollowUpDto
  contactFollowUps: Array<ContactHostedFamilyFollowUpDto>
  setContactFollowUps: (contactFollowUps: Array<ContactHostedFamilyFollowUpDto>) => void
  animal: AnimalDto
  disabled?: boolean
}

export const ContactFollowUpItem: FC<PropsItem> = ({
  followUp,
  contactFollowUps,
  setContactFollowUps,
  animal,
  disabled = false,
}) => {
  const accountsStore = useAccountsStore()
  const [isExpanded, setExpanded] = useState(false)

  const handleClickOnExpand = () => {
    setExpanded(!isExpanded)
  }
  const handleClickOnDelete = () => {
    setContactFollowUps(contactFollowUps.filter((aFollowUp) => followUp !== aFollowUp))
  }

  const sxField = { ...spacingItem, cursor: 'disabled', input: { cursor: 'not-allowed' } }

  const getAccountLabelFromId = (id: string): string => {
    if (!id) {
      return ''
    }

    const account = accountsStore.withRemovedMembers.find((account) => account.id === id)
    if (!account) {
      return `${id} (Introuvable)`
    }
    let label = account ? fullName(account) : ''

    if (account.id === accountsStore.connectedAccount?.id) {
      label = `Moi (${label})`
    }

    if (account.isDeleted) {
      label = `Compte supprimé (${label})`
    }

    return label
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={3} xs={12}>
          <DisplayChipLikeTextField
            label="Type de suivi"
            value={getReadableContactFollowUpType(followUp.type)}
            color={getColorForContactFollowUpType(followUp.type)}
          />
        </Grid>

        <Grid item sm={1.5} xs={12}>
          <TextField
            label="Date"
            type="text"
            fullWidth
            value={followUp.date}
            sx={sxField}
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item sm={2.75} xs={12}>
          <TextField
            label="Famille d'accueil"
            type="text"
            fullWidth
            value={getAccountLabelFromId(followUp.hostFamilyAccountId)}
            sx={sxField}
            multiline
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item sm={2.75} xs={12}>
          <TextField
            label="Interlocuteur"
            type="text"
            fullWidth
            value={getAccountLabelFromId(followUp.interlocutorAccountId)}
            sx={sxField}
            multiline
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item sm={2} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%', pt: 2 }}>
            <Button onClick={handleClickOnExpand}>{isExpanded ? <ExpandLess /> : <ExpandMore />}</Button>
            <EditContactFollowUpModal
              followUp={followUp}
              contactFollowUps={contactFollowUps}
              setContactFollowUps={setContactFollowUps}
              animal={animal}
              disabled={disabled}
            />
            <Button
              sx={{ color: 'error.main' }}
              onClick={() => handleClickOnDelete()}
              disabled={animalButtonShouldBeDisabled(animal) || disabled}
            >
              <Delete />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={isExpanded}>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <TextField
              label="Details"
              type="text"
              fullWidth
              value={followUp.description}
              sx={sxField}
              multiline
              size="small"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'row', sm: 'column' }}
              justifyContent={{ xs: 'space-between', sm: 'center' }}
              alignItems="left"
              padding={1}
              paddingTop={1.8}
              gap={0.5}
              sx={{ color: theme.palette.grey[500] }}
            >
              {followUp.created && (
                <Typography variant="body2" fontSize={14} sx={{ p: 0, m: 0 }}>
                  Créé le {new Date(followUp.created.at).toLocaleDateString()} par{' '}
                  {getAccountLabelFromId(followUp.created.by)}
                </Typography>
              )}
              {followUp.modified && (
                <Typography variant="body2" fontSize={14} sx={{ p: 0, m: 0 }}>
                  Modifié le {new Date(followUp.created.at).toLocaleDateString()} par{' '}
                  {getAccountLabelFromId(followUp.created.by)}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', marginY: 2 }}>
        <hr />
      </Box>
    </>
  )
}
