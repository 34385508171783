import { PropsWithChildren } from 'react'
import { Autocomplete, BaseTextFieldProps, Chip, SxProps, TextField } from '@mui/material'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { blueSky } from '../theme'

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  color?: string
  sx?: SxProps
  helperText?: string
}

export function ControlledChipInput<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  size,
  disabled,
  color = blueSky,
  sx,
  helperText,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          sx={{ ...sx, width: '100%' }}
          multiple
          freeSolo
          open={false} // control the open state
          PaperComponent={() => null} // render no dropdown
          onChange={(_, data) => onChange(data)}
          id={fieldName}
          getOptionLabel={(option) => option}
          options={[]}
          value={value}
          size={size}
          disabled={disabled}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...restTagProps } = getTagProps({ index })
              return (
                <Chip
                  key={option}
                  label={option}
                  {...restTagProps}
                  style={{ backgroundColor: color, color: 'white' }}
                />
              )
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={label}
              error={!!error}
              helperText={helperText}
            />
          )}
        />
      )}
    />
  )
}
