import { ArrowDownward } from '@mui/icons-material'
import { Card, CardContent, Box, Typography, IconButton, Collapse, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { theme } from '../theme'

interface Props {
  defaultExpanded?: boolean
  children: React.ReactNode
  title: string
}

export const CollapsableCard: React.FC<Props> = ({ children, title, defaultExpanded = false }) => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleToggleExpand = () => {
    setExpanded(!expanded)
  }
  return (
    <Card
      sx={{
        m: 0,
        width: '100%',
      }}
    >
      <CardContent sx={{ m: 0, p: { sm: '12px !important' }, pt: { xs: 0 }, '&:last-child': { pb: { xs: 0 } } }}>
        <Box
          onClick={handleToggleExpand}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: isMobile ? 1 : 0,
            cursor: 'pointer',
          }}
        >
          <Typography variant="h4" component="p" sx={{ fontSize: { xs: 18, sm: 20 }, fontWeight: 700 }}>
            {title}
          </Typography>
          <IconButton>
            <ArrowDownward
              style={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            />
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto">
          {children}
        </Collapse>
      </CardContent>
    </Card>
  )
}
