import { AdopterDto } from '../../interactors/gen/backendClient'

const profileOrder = {
  waiting: 10,
  'form-accepted': 20,
  accepted: 30,
  'form-sent': 300,
  'form-refused': 400,
  'form-to-sent': 500,
  refused: 600,
  blacklisted: 700,
}

const attemptStatusOrder = {
  'awaiting-certificate': 1,
  'awaiting-contract': 2,
  'awaiting-certificate-validation': 3,
  'awaiting-contract-validation': 4,
  'awaiting-payment-validation': 5,
  'pending-user': 6,
  'pending-customer': 6,
  'awaiting-signed-certificate': 80,
  'awaiting-signed-contract': 90,
  'awaiting-payment': 100,
  done: 480,
  'suspended-by-user': 490,
  'suspended-by-customer': 490,
}

const getLeastSignificantAttemptScore = (adopter: AdopterDto) => {
  if (!adopter.adoptionAttempts || adopter.adoptionAttempts.length === 0) {
    if (adopter.profileHasBeenAccepted === 'accepted') {
      return Infinity
    }
    return 0 // Return a high value to indicate no attempts
  }
  return adopter.adoptionAttempts.reduce(
    (minScore, attempt) => Math.min(minScore, attemptStatusOrder[attempt.status] || Infinity),
    Infinity
  )
}

const calculateCompositeScore = (adopter: AdopterDto) => {
  const profileScore: number = adopter.profileHasBeenAccepted ? profileOrder[adopter.profileHasBeenAccepted] : 1000
  const attemptScore = getLeastSignificantAttemptScore(adopter)
  return profileScore + attemptScore
}

export const sortAdopters = (a: AdopterDto, b: AdopterDto) => {
  const scoreA = calculateCompositeScore(a)
  const scoreB = calculateCompositeScore(b)
  return scoreA - scoreB
}
