import create from 'zustand'
import { AnimalsClient } from '../interactors/clients/AnimalsClient'
import { AnimalDto, AnimalSearchItemDto } from '../interactors/gen/backendClient'
import { AnimalFilters } from '../domain/Animal/AnimalFiltersType'
import { SortColumn, SortDirection } from '../views/Animals/AnimalsTable'

interface AnimalStore {
  animals: AnimalSearchItemDto[]
  animalsCount: number
  fetchAnimals: (
    page?: number,
    limit?: number,
    filters?: AnimalFilters,
    searchQuery?: string,
    sortColumn?: SortColumn,
    sortDirection?: SortDirection
  ) => Promise<void>
  fetchOrRefreshAnimals: (
    page?: number,
    limit?: number,
    filters?: AnimalFilters,
    searchQuery?: string,
    sortColumn?: SortColumn,
    sortDirection?: SortDirection
  ) => Promise<void>
  initializeAnimalsIfNeeded: () => Promise<void>
  refreshAnimals: (
    page?: number,
    limit?: number,
    filters?: AnimalFilters,
    searchQuery?: string,
    sortColumn?: SortColumn,
    sortDirection?: SortDirection
  ) => Promise<void>
  changeSelectedAnimal: (animalId: string) => Promise<void>
  selectedAnimal: AnimalDto | null
  isLoading: boolean
  areAnimalsInitialized: boolean
  refreshSelectedAnimal: () => Promise<void>
  refreshSelectedAnimalInBackground: () => Promise<void>
}

export const useAnimalStore = create<AnimalStore>()((set, get) => ({
  isLoading: false,
  animals: [],
  animalsCount: 0,
  fetchAnimals: async (page, limit, filters, searchQuery, sortColumn, sortDirection) => {
    set({ isLoading: true })

    const animalsFetched = await AnimalsClient.getAllAnimal(
      page,
      limit,
      filters,
      searchQuery,
      sortColumn,
      sortDirection
    )
    const animalsCountFetched = await AnimalsClient.countAllAnimal(filters, searchQuery)
    set({ animalsCount: animalsCountFetched })
    set({ animals: animalsFetched })
    set({ isLoading: false })
    set({ areAnimalsInitialized: true })
  },
  refreshAnimals: async (page, limit, filters, searchQuery, sortColumn, sortDirection) => {
    const refreshedAnimals = await AnimalsClient.getAllAnimal(
      page,
      limit,
      filters,
      searchQuery,
      sortColumn,
      sortDirection
    )
    const refreshedAnimalsCount = await AnimalsClient.countAllAnimal(filters, searchQuery)
    set({ animalsCount: refreshedAnimalsCount })
    set({ animals: refreshedAnimals })
  },
  fetchOrRefreshAnimals: async (page, limit, filters, searchQuery, sortColumn, sortDirection) => {
    if (get().areAnimalsInitialized) {
      await get().refreshAnimals(page, limit, filters, searchQuery, sortColumn, sortDirection)
    } else {
      await get().fetchAnimals(page, limit, filters, searchQuery, sortColumn, sortDirection)
    }
  },
  initializeAnimalsIfNeeded: async () => {
    if (!get().areAnimalsInitialized) {
      console.log('Loading animals')
      await get().fetchAnimals()
      console.log('Animals loaded')
    }
  },
  areAnimalsInitialized: false,
  globalChangeSelectedAnimal: async (newSelectedAnimalId: string) => {
    set({ isLoading: true })
    const animalsFetched = await AnimalsClient.getAnimal(newSelectedAnimalId)
    set({ selectedAnimal: animalsFetched, isLoading: false })
  },
  selectedAnimal: null,
  changeSelectedAnimal: async (newSelectedAnimalId: string) => {
    set({ isLoading: true, selectedAnimal: null })
    const animalsFetched = await AnimalsClient.getAnimal(newSelectedAnimalId)
    set({ selectedAnimal: animalsFetched, isLoading: false })
  },
  selectedAnimalMinified: null,
  refreshSelectedAnimal: async () => {
    await get().changeSelectedAnimal(get().selectedAnimal!.id)
  },
  refreshSelectedAnimalInBackground: async () => {
    const animalsFetched = await AnimalsClient.getAnimal(get().selectedAnimal!.id)
    set({ selectedAnimal: animalsFetched })
  },
}))
