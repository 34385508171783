import { Autocomplete, BaseTextFieldProps, SxProps, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'

interface Option {
  title: string
  options: string[]
}

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Option[]
  requiredRule?: string
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  freeSolo?: boolean
  sx?: SxProps
}

export function ControlledAutoCompleteWithCategories<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  options,
  size,
  disabled,
  freeSolo,
  sx,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={'' as any} // Set default value as empty string or initial value
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          sx={sx}
          onChange={(_, data) => onChange(data)}
          freeSolo={freeSolo}
          id={fieldName}
          options={options.flatMap((option) => option.options)} // Flatten options
          getOptionLabel={(option) => option}
          value={value}
          size={size}
          disabled={disabled}
          groupBy={(option) => {
            // Find the category for this option
            const category = options.find((category) => category.options.includes(option))
            return category?.title || ''
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} placeholder={label} error={!!error} />
          )}
        />
      )}
    />
  )
}
