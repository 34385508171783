import { Opaque } from '../Opaque'
import { isString } from '../isString'
import dayjs from 'dayjs'

export type DateOnly = Opaque<string, 'DateOnly'>

export const DATE_ONLY_FORMAT = 'DD/MM/YYYY'

export const dayJSFromDateOnly = (dateOnly: DateOnly): dayjs.Dayjs => {
  return dayjs(dateOnly, DATE_ONLY_FORMAT)
}

function isDateOnly(dateString: string): dateString is DateOnly {
  // Create a regex pattern for the format DD/MM/YYYY
  const pattern = /^(\d{2})\/(\d{2})\/(\d{4})$/

  // Check if the string matches the pattern
  if (!pattern.test(dateString)) {
    return false
  }

  // Extract the day, month, and year from the string
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [, day, month, year] = pattern.exec(dateString)!

  // Create a Date object from the extracted values
  const date = new Date(`${year}-${month}-${day}`)

  // Validate the Date object
  const isValidDate = !isNaN(date.getTime()) && date.getDate() == Number(day)

  return isValidDate
}

export const toDateOnly = (date: DateOnly | dayjs.Dayjs | string): DateOnly => {
  console.log({ date })
  if (isString(date)) {
    return date as DateOnly
  }

  return dayjs(date).format(DATE_ONLY_FORMAT) as DateOnly
}

export const toDayJSFromAll = (date: DateOnly | dayjs.Dayjs | '' | null | string): dayjs.Dayjs | null => {
  if (!date) return null

  if (isString(date) && isDateOnly(date)) {
    return dayJSFromDateOnly(date as DateOnly)
  }

  return dayjs(date)
}
