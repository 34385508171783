import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material'
import React from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import { AnimalAdoptionDtoStatus } from '../../../interactors/gen/backendClient'
import { theme } from '../../theme'

interface FormParams {
  adoptionStatus: AnimalAdoptionDtoStatus
  exitDate: string | ''
  adoptionDate: string | ''
  vpaDate: string | ''
  newName: string | ''
}

interface Props {
  isOpen: boolean
  onClose: () => void
  handleSubmit: UseFormHandleSubmit<FormParams>
  onSubmit: (data: FormParams, confirmation?: boolean) => void
  animalName: string
}

export const GetAnimalAdoptedModal: React.FC<Props> = ({ isOpen, onClose, handleSubmit, onSubmit, animalName }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog
      maxWidth={isMobile ? 'xs' : 'sm'}
      open={isOpen}
      onClose={onClose}
      fullWidth
      scroll="paper"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      <DialogTitle sx={{ pb: isMobile ? 0 : 'auto' }}>Êtes vous sûr de vouloir continuer ?</DialogTitle>

      <Box
        sx={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          boxSizing: 'content-box',
        }}
      >
        <DialogContent
          sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)', fontSize: isMobile ? '14px' : '16px' }}
        >
          <b>
            Vous venez d&#39;ajouter une date d&#39;adoption physique.
            <br />
            Si vous continuez {animalName} sera considéré comme étant adopté et son statut sera mis à jour.
            <br />
            De plus, cette procédure sera marquée comme terminée.
          </b>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="outlined"
            type="button"
            sx={{ width: isMobile ? '50%' : 'auto', textTransform: isMobile ? 'none' : 'normal' }}
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            type="button"
            sx={{ width: isMobile ? '50%' : 'auto', textTransform: isMobile ? 'none' : 'normal' }}
            onClick={handleSubmit((data) => onSubmit(data, true))}
          >
            Continuer
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
