import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import React, { useEffect, useRef } from 'react'
import L, { LatLngExpression } from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import 'leaflet/dist/leaflet.css'
import MarkerIconYellow from '../utils/MarkerIconYellow.svg'
import MarkerIconBlue from '../utils/MarkerIconBlue.svg'
import MarkerIconPink from '../utils/MarkerIconPink.svg'
import MarkerIconLightBlue from '../utils/MarkerIconLightBlue.svg'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { PATHS } from '../PATHS'
import { AccountDtoRoles } from '../../interactors/gen/backendClient'

interface Props {
  memberMarkers: MapCoordinates[]
  adopterMarkers: MapCoordinates[]
  hostFamilyMarkers: MapCoordinates[]
  veterinaryMarkers: MapCoordinates[]
  selectedMarker: MapCoordinates | null
}

export type MapCoordinates = {
  position: LatLngExpression
  title: string
  id: string
  address?: string
  postalCode?: string
  city?: string
  country?: string
  role?: string
}

const adopterIcon = new L.Icon({
  iconUrl: MarkerIconYellow,
  iconSize: new L.Point(36, 36),
})
const memberIcon = new L.Icon({
  iconUrl: MarkerIconBlue,
  iconSize: new L.Point(36, 36),
})
const veterinaryIcon = new L.Icon({
  iconUrl: MarkerIconPink,
  iconSize: new L.Point(36, 36),
})
const hostFamilyIcon = new L.Icon({
  iconUrl: MarkerIconLightBlue,
  iconSize: new L.Point(36, 36),
})

const MapComponent: React.FC<Props> = ({
  memberMarkers,
  hostFamilyMarkers,
  veterinaryMarkers,
  adopterMarkers,
  selectedMarker,
}) => {
  const map = useMap()
  const markerRef = useRef<L.Marker>(null)

  useEffect(() => {
    if (selectedMarker) {
      map.flyTo(selectedMarker.position, 16, { duration: 0.5 }) // Reduce duration for faster animation
      if (markerRef && markerRef.current != null) {
        setTimeout(() => {
          markerRef.current?.openPopup()
        }, 500)
      }
    }
  }, [selectedMarker, map])

  return (
    <MarkerClusterGroup>
      {selectedMarker && (
        <Marker
          key={`selected-${selectedMarker.id}`}
          position={selectedMarker.position}
          title={selectedMarker.title}
          icon={
            selectedMarker.id.startsWith('B')
              ? adopterIcon
              : selectedMarker.role === AccountDtoRoles.Member
              ? memberIcon
              : selectedMarker.role === AccountDtoRoles.HostFamily
              ? hostFamilyIcon
              : veterinaryIcon
          }
          ref={markerRef}
        >
          <Popup>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="h6" sx={{ fontSize: '18px' }}>
                {selectedMarker.title.split('-').map((part, index, array) =>
                  index === 0 ? (
                    <span key={`${index}-${selectedMarker.id}-selected`} style={{ fontWeight: 'bold' }}>
                      {part}
                    </span>
                  ) : (
                    <>
                      {array.length > 1 && '-'}
                      <span key={`${index}-${selectedMarker.id}-selected`} style={{ fontStyle: 'italic' }}>
                        {part}
                      </span>
                    </>
                  )
                )}
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '5px' }}>
                <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                  {selectedMarker.address}
                </Typography>
                <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                  {selectedMarker.postalCode}, {selectedMarker.city}
                </Typography>
              </Box>

              <Link
                to={`${
                  selectedMarker.id.startsWith('B') ? PATHS.suiviAdoptant.absolute : PATHS.suiviBenevole.absolute
                }/${selectedMarker.id}`}
              >
                <Typography variant="body1">Voir la fiche</Typography>
              </Link>
            </Box>
          </Popup>
        </Marker>
      )}
      {memberMarkers &&
        memberMarkers
          .filter((marker) => marker.id !== selectedMarker?.id)
          .map((element) => (
            <Marker
              key={`${element.title}-${element.id}`}
              position={element.position}
              title={element.title}
              icon={memberIcon}
            >
              <Popup>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="h6" sx={{ fontSize: '18px' }}>
                    {element.title.split('-').map((part, index, array) =>
                      index === 0 ? (
                        <span key={`${index}-${element.id}-${element.role}`} style={{ fontWeight: 'bold' }}>
                          {part}
                        </span>
                      ) : (
                        <>
                          {array.length > 1 && '-'}
                          <span key={`${index}-${element.id}-${element.role}`} style={{ fontStyle: 'italic' }}>
                            {part}
                          </span>
                        </>
                      )
                    )}
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '5px' }}>
                    <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                      {element.address}
                    </Typography>
                    <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                      {element.postalCode}, {element.city}
                    </Typography>
                  </Box>

                  <Link to={`${PATHS.suiviBenevole.absolute}/${element.id}`}>
                    <Typography variant="body1">Voir la fiche</Typography>
                  </Link>
                </Box>
              </Popup>
            </Marker>
          ))}
      {hostFamilyMarkers &&
        hostFamilyMarkers
          .filter((marker) => marker.id !== selectedMarker?.id)
          .map((element) => (
            <Marker
              key={`${element.title}-${element.id}`}
              position={element.position}
              title={element.title}
              icon={hostFamilyIcon}
            >
              <Popup>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="h6" sx={{ fontSize: '18px' }}>
                    {element.title.split('-').map((part, index, array) =>
                      index === 0 ? (
                        <span key={`${index}-${element.id}-${element.role}`} style={{ fontWeight: 'bold' }}>
                          {part}
                        </span>
                      ) : (
                        <>
                          {array.length > 1 && '-'}
                          <span key={`${index}-${element.id}-${element.role}`} style={{ fontStyle: 'italic' }}>
                            {part}
                          </span>
                        </>
                      )
                    )}
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '5px' }}>
                    <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                      {element.address}
                    </Typography>
                    <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                      {element.postalCode}, {element.city}
                    </Typography>
                  </Box>

                  <Link to={`${PATHS.suiviBenevole.absolute}/${element.id}`}>
                    <Typography variant="body1">Voir la fiche</Typography>
                  </Link>
                </Box>
              </Popup>
            </Marker>
          ))}
      {veterinaryMarkers &&
        veterinaryMarkers
          .filter((marker) => marker.id !== selectedMarker?.id)
          .map((element) => (
            <Marker
              key={`${element.title}-${element.id}`}
              position={element.position}
              title={element.title}
              icon={veterinaryIcon}
            >
              <Popup>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="h6" sx={{ fontSize: '18px' }}>
                    {element.title.split('-').map((part, index, array) =>
                      index === 0 ? (
                        <span key={`${index}-${element.id}-${element.role}`} style={{ fontWeight: 'bold' }}>
                          {part}
                        </span>
                      ) : (
                        <>
                          {array.length > 1 && '-'}
                          <span key={`${index}-${element.id}-${element.role}`} style={{ fontStyle: 'italic' }}>
                            {part}
                          </span>
                        </>
                      )
                    )}
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '5px' }}>
                    <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                      {element.address}
                    </Typography>
                    <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                      {element.postalCode}, {element.city}
                    </Typography>
                  </Box>

                  <Link to={`${PATHS.suiviBenevole.absolute}/${element.id}`}>
                    <Typography variant="body1">Voir la fiche</Typography>
                  </Link>
                </Box>
              </Popup>
            </Marker>
          ))}
      {adopterMarkers &&
        adopterMarkers
          .filter((marker) => marker.id !== selectedMarker?.id)
          .map((element) => (
            <Marker
              key={`${element.title}-${element.id}`}
              position={element.position}
              title={element.title}
              icon={adopterIcon}
            >
              <Popup>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="h6" sx={{ fontSize: '18px' }}>
                    {element.title.split('-').map((part, index, array) =>
                      index === 0 ? (
                        <span key={`${index}-${element.id}`} style={{ fontWeight: 'bold' }}>
                          {part}
                        </span>
                      ) : (
                        <>
                          {array.length > 1 && '-'}
                          <span key={`${index}-${element.id}`} style={{ fontStyle: 'italic' }}>
                            {part}
                          </span>
                        </>
                      )
                    )}
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '5px' }}>
                    <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                      {element.address}
                    </Typography>
                    <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                      {element.postalCode}, {element.city}
                    </Typography>
                  </Box>

                  <Link to={`${PATHS.suiviAdoptant.absolute}/${element.id}`}>
                    <Typography variant="body1">Voir la fiche</Typography>
                  </Link>
                </Box>
              </Popup>
            </Marker>
          ))}
    </MarkerClusterGroup>
  )
}

export const MapOfFrance: React.FC<Props> = ({
  memberMarkers,
  hostFamilyMarkers,
  veterinaryMarkers,
  adopterMarkers,
  selectedMarker,
}) => {
  // Position for map centered on France
  const position: LatLngExpression = [46.71109, 1.7191036]
  return (
    <MapContainer style={{ height: '100%' }} center={position} zoom={6} scrollWheelZoom={true} maxZoom={18}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapComponent
        memberMarkers={memberMarkers}
        hostFamilyMarkers={hostFamilyMarkers}
        veterinaryMarkers={veterinaryMarkers}
        adopterMarkers={adopterMarkers}
        selectedMarker={selectedMarker}
      />
    </MapContainer>
  )
}
