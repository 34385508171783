import { AnimalDto } from '../interactors/gen/backendClient'

export const animalButtonShouldBeDisabled = (animal: AnimalDto): boolean => {
  if (
    animal.adoption.status === 'cannot-be-adopted' &&
    (animal.adoption.cannotBeAdoptedStatus === 'dead' ||
      animal.adoption.cannotBeAdoptedStatus === 'lost' ||
      animal.adoption.cannotBeAdoptedStatus === 'released' ||
      animal.adoption.cannotBeAdoptedStatus === 'transfer-to-another-association')
  ) {
    return true
  }
  return false
}
