import React, { useEffect } from 'react'
import { Box, TextField, Typography, FormControl, Button, InputAdornment } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { spacingItem } from '../../theme'
import {
  CreateDonationTrackingBodyDto,
  CreateDonationTrackingBodyDtoCgi,
  CreateDonationTrackingBodyDtoFormOfDonation,
  CreateDonationTrackingBodyDtoMethodOfPayment,
  CreateDonationTrackingBodyDtoTypeOfDonation,
  CreateDonorBodyDto,
  Donor,
  DonorType,
} from '../../../interactors/gen/backendClient'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../../common/ControlledDateField'
import {
  getReadableDonationTrackingCGI,
  getReadableDonationTrackingFormOfDonation,
  getReadableDonationTrackingMethodOfPayment,
  getReadableDonationTrackingTypeOfDonation,
} from '../../../domain/Donations/DonationTrackingDisplay'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { getDonatorFullName, getReadableDonatorType } from '../../../domain/Donations/DonatorDisplay'
import { useMutation } from 'react-query'
import { DonatersClient } from '../../../interactors/clients/DonatersClient'
import { useFetchOrRefreshDonatersOnMount } from '../../../store/useFetchOrRefreshDonatersOnMount'
import { useDonatersStore } from '../../../store/DonatersStore'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { DonationTrackingsClient } from '../../../interactors/clients/DonationTrackingClient'

export const AddDonatorScreen: React.FC = () => {
  const [selectedDonatorId, setSelectedDonatorId] = React.useState<string | undefined>('')
  const donatersStore = useDonatersStore()

  useFetchOrRefreshDonatersOnMount()

  useEffect(() => {
    donatersStore.refreshDonaters()
    setValue('donator', selectedDonatorId)
  }, [selectedDonatorId])

  const allDonaters = donatersStore.donaters

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      donator: selectedDonatorId,
    },
    mode: 'onChange',
  })

  const donator = watch('donator')
  const selectedDonator = allDonaters.find((aDonator) => aDonator.id === donator)

  return (
    <Box sx={{ width: '900px', maxWidth: '90%', marginX: 'auto', marginY: '5rem' }}>
      <Typography variant="h4" fontSize={28}>
        Le donateur:
      </Typography>

      <ControlledAutoCompleteWithCustomValue
        sx={{ ...spacingItem, mb: 6 }}
        key={selectedDonatorId}
        control={control}
        defaultValue=""
        error={errors.donator}
        fieldName="donator"
        label="Sélectionner un donateur"
        options={[
          { label: 'Ajouter un donateur', value: 'addDonator' },
          ...allDonaters
            .map((option) => ({
              label: getDonatorFullName(option) || '',
              value: option.id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ]}
        requiredRule={undefined}
        highlight
      />

      {donator === 'addDonator' && <AddDonatorForm setSelectedDonatorId={setSelectedDonatorId} />}
      {donator && donator !== 'addDonator' && <AddDonationForm donatorId={donator} selectedDonator={selectedDonator} />}
    </Box>
  )
}

interface AddDonatorFormParams {
  type: DonorType | undefined
  SIRETNumber: string
  name: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: string
  postalCode: string
  city: string
  country: string
}

type AddDonatorFormProps = {
  setSelectedDonatorId: (donatorId: string) => void
}

export const AddDonatorForm: React.FC<AddDonatorFormProps> = ({ setSelectedDonatorId }) => {
  const addDonatorMutation = useMutation(
    (donator: AddDonatorFormParams) => {
      const donor: Omit<CreateDonorBodyDto, 'customerId'> = {
        type: donator.type!,
        human:
          donator.type === DonorType.Individuals
            ? {
                firstName: donator.firstName,
                lastName: donator.lastName,
                email: donator.email,
                phoneNumber: donator.phoneNumber,
                address: donator.address,
                postalCode: donator.postalCode,
                city: donator.city,
                country: donator.country,
              }
            : undefined,
        organization:
          donator.type !== DonorType.Individuals
            ? {
                SIRETNumber: donator.SIRETNumber,
                name: donator.name,
                email: donator.email,
                phoneNumber: donator.phoneNumber,
                address: donator.address,
                postalCode: donator.postalCode,
                city: donator.city,
                country: donator.country,
              }
            : undefined,
      }
      return DonatersClient.createDonater(donor)
    },
    {
      onSuccess: (data) => {
        setSelectedDonatorId(data.id)
      },
    }
  )

  const onSubmit = (data: AddDonatorFormParams) => {
    addDonatorMutation.mutate(data)
  }

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AddDonatorFormParams>({
    defaultValues: {
      type: undefined,
      SIRETNumber: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      country: 'France',
    },
    mode: 'onChange',
  })

  const typeOfDonator = watch('type')

  return (
    <>
      <Typography variant="h4" fontSize={28}>
        🤴 Ajouter un Donateur
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.type}
          fieldName="type"
          label="Type de donateur"
          options={Object.values(DonorType).map((value) => ({
            label: getReadableDonatorType(value),
            value: value,
          }))}
          requiredRule={'Le type de donateur est requis'}
        />

        {typeOfDonator && typeOfDonator !== DonorType.Individuals && (
          <>
            <TextField
              id="name-input"
              label="Nom de l'organisation"
              type="text"
              fullWidth
              {...register('name', { required: 'Le nom est requis' })}
              sx={spacingItem}
              error={!!errors.name}
              helperText={errors.name?.message}
              required
            />
            <TextField
              id="first-name-input"
              label="Numéro de siret"
              type="text"
              fullWidth
              {...register('SIRETNumber', { required: 'Le numéro de siret est requis' })}
              sx={spacingItem}
              error={!!errors.SIRETNumber}
              helperText={errors.SIRETNumber?.message}
              required
            />
          </>
        )}

        {typeOfDonator === DonorType.Individuals && (
          <>
            <TextField
              id="first-name-input"
              label="Prénom"
              type="text"
              fullWidth
              {...register('firstName', { required: 'Le prénom est requis' })}
              sx={spacingItem}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              required
            />

            <TextField
              id="last-name-input"
              label="Nom de Famille"
              type="text"
              fullWidth
              {...register('lastName', { required: 'Le nom est requis' })}
              sx={spacingItem}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              required
            />
          </>
        )}

        <TextField
          id="email"
          label="Email"
          type="email"
          fullWidth
          {...register('email', {
            required: "L'email est requis.",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "L'addresse n'est pas valide.",
            },
          })}
          sx={spacingItem}
          error={!!errors.email}
          helperText={errors.email?.message}
          required
        />

        <FormControl fullWidth variant="outlined">
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              validate: (value: string | undefined) => {
                console.log(value)
                return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                  ? true
                  : 'Tel is invalid'
              },
            }}
            render={({ field, fieldState }) => (
              <MuiTelInput
                {...field}
                label="Numero de Téléphone"
                defaultCountry="FR"
                helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                error={!!fieldState.error}
                sx={spacingItem}
              />
            )}
          />
        </FormControl>

        <TextField
          id="address"
          label="Adresse"
          type="text"
          fullWidth
          {...register('address')}
          sx={spacingItem}
          error={!!errors.address}
          helperText={errors.address?.message}
          required
        />

        <TextField
          id="postalCode"
          label="Code Postal"
          type="text"
          fullWidth
          {...register('postalCode')}
          sx={spacingItem}
          error={!!errors.postalCode}
          helperText={errors.postalCode?.message}
          required
        />

        <TextField
          id="city"
          label="Ville"
          type="text"
          fullWidth
          {...register('city')}
          sx={spacingItem}
          error={!!errors.city}
          helperText={errors.city?.message}
          required
        />

        <TextField
          id="country"
          label="Pays"
          type="text"
          fullWidth
          {...register('country')}
          sx={spacingItem}
          error={!!errors.country}
          helperText={errors.country?.message}
          required
        />

        {/*         {serverError && (
          <Box sx={spacingItem}>
            <Typography color="error">{serverError}</Typography>
          </Box>
        )} */}

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', ...spacingItem }}>
          <LoadingButton
            variant="contained"
            sx={{ width: '100%' }}
            type="submit"
            loading={addDonatorMutation.isLoading}
          >
            Ajouter
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}

interface AddDonationFormParams {
  donaterId: string
  dateCollected: string
  amount: string
  formOfDonation: CreateDonationTrackingBodyDtoFormOfDonation
  typeOfDonation: CreateDonationTrackingBodyDtoTypeOfDonation
  methodOfPayment: CreateDonationTrackingBodyDtoMethodOfPayment
  description?: string
  cgi: CreateDonationTrackingBodyDtoCgi
}

type AddDonationFormProps = {
  donatorId: string
  selectedDonator?: Donor
}

export const AddDonationForm: React.FC<AddDonationFormProps> = ({ donatorId, selectedDonator }) => {
  const navigate = useNavigate()
  const addDonationMutation = useMutation(
    (donation: AddDonationFormParams) => {
      const newDonation: CreateDonationTrackingBodyDto = {
        donaterId: donation.donaterId || donatorId,
        dateCollected: donation.dateCollected as any,
        amount: parseFloat(donation.amount),
        formOfDonation: donation.formOfDonation,
        typeOfDonation: donation.typeOfDonation,
        methodOfPayment: donation.methodOfPayment,
        description: donation.description,
        cgi: donation.cgi,
      }
      console.log(newDonation)
      return DonationTrackingsClient.createDonation(newDonation)
    },
    {
      onSuccess: () => {
        navigate('/association', { replace: true })
      },
    }
  )

  const onSubmit = (data: AddDonationFormParams) => {
    addDonationMutation.mutate(data)
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddDonationFormParams>({
    defaultValues: {
      donaterId: donatorId,
      dateCollected: '',
      amount: '0',
      formOfDonation: undefined,
      typeOfDonation: undefined,
      methodOfPayment: undefined,
      description: '',
      cgi: selectedDonator
        ? selectedDonator.type === DonorType.Individuals
          ? CreateDonationTrackingBodyDtoCgi._200
          : CreateDonationTrackingBodyDtoCgi._238
        : undefined,
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (selectedDonator && selectedDonator.type === DonorType.Individuals) {
      setValue('cgi', CreateDonationTrackingBodyDtoCgi._200)
    } else {
      setValue('cgi', CreateDonationTrackingBodyDtoCgi._238)
    }
  }, [selectedDonator])

  return (
    <>
      <Typography variant="h4" fontSize={28}>
        💸 Ajouter un Don
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledDateField
          sx={spacingItem}
          control={control}
          validate={(value) => isValidPastDateCoherence(value) || !value}
          error={errors.dateCollected}
          fieldName={'dateCollected'}
          label="Date de perception"
          requiredRule={'La date de perception est requise'}
        />

        <TextField
          sx={spacingItem}
          id="amount-number-input"
          label="Montant"
          type="number"
          inputProps={{
            step: '0.01',
            inputMode: 'decimal',
            pattern: '[0-9]*',
          }}
          fullWidth
          {...register('amount')}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          required
        />

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.formOfDonation}
          fieldName="formOfDonation"
          label="Forme du don"
          options={Object.values(CreateDonationTrackingBodyDtoFormOfDonation).map((value) => ({
            label: getReadableDonationTrackingFormOfDonation(value),
            value: value,
          }))}
          requiredRule={'La forme du don est requise'}
        />

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.typeOfDonation}
          fieldName="typeOfDonation"
          label="Nature du don"
          options={Object.values(CreateDonationTrackingBodyDtoTypeOfDonation).map((value) => ({
            label: getReadableDonationTrackingTypeOfDonation(value),
            value: value,
          }))}
          requiredRule={'La nature du don est requise'}
        />

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.methodOfPayment}
          fieldName="methodOfPayment"
          label="Méthode de paiement"
          options={Object.values(CreateDonationTrackingBodyDtoMethodOfPayment).map((value) => ({
            label: getReadableDonationTrackingMethodOfPayment(value),
            value: value,
          }))}
          requiredRule={'La méthode de paiement est requise'}
        />

        <TextField
          sx={spacingItem}
          id="outlined-textarea"
          label="Description"
          placeholder="Description du don"
          multiline
          fullWidth
          {...register('description')}
          error={!!errors.description}
          helperText={errors.description?.message}
        />

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.cgi}
          fieldName="cgi"
          label="Le bénéficiaire certifie sur l'honneur que les dons et versements qu'il reçoit ouvrent droit à la réduction d'impôt prévue à l'article (3)"
          options={Object.values(CreateDonationTrackingBodyDtoCgi).map((value) => ({
            label: getReadableDonationTrackingCGI(value),
            value: value,
          }))}
          requiredRule={'Le champs CGI est requis'}
        />

        {/*         {serverError && (
          <Box sx={spacingItem}>
            <Typography color="error">{serverError}</Typography>
          </Box>
        )} */}

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', ...spacingItem }}>
          <Button variant="contained" sx={{ width: '100%' }} type="submit">
            Ajouter
          </Button>
        </Box>
      </form>
    </>
  )
}
