import { TextField, Box, InputAdornment, Button } from '@mui/material'
import React from 'react'
import { spacingItem } from '../../theme'
import { MemberCardSaveButton } from '../../Members/MemberCardSaveButton'
import {
  CreateDonationTrackingBodyDtoCgi,
  CreateDonationTrackingBodyDtoFormOfDonation,
  CreateDonationTrackingBodyDtoMethodOfPayment,
  CreateDonationTrackingBodyDtoTypeOfDonation,
  DonationTrackingDto,
} from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { useMutation } from 'react-query'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { DonationTrackingsClient } from '../../../interactors/clients/DonationTrackingClient'
import {
  getReadableDonationTrackingFormOfDonation,
  getReadableDonationTrackingTypeOfDonation,
  getReadableDonationTrackingMethodOfPayment,
  getReadableDonationTrackingCGI,
} from '../../../domain/Donations/DonationTrackingDisplay'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../../common/ControlledDateField'

interface AssociationDonationFormProps {
  selectedDonation: DonationTrackingDto
  handleFiscalReport: (e: React.MouseEvent) => void
}

interface FormParams {
  donaterId: string
  dateCollected: string
  amount: string
  formOfDonation: CreateDonationTrackingBodyDtoFormOfDonation
  typeOfDonation: CreateDonationTrackingBodyDtoTypeOfDonation
  methodOfPayment: CreateDonationTrackingBodyDtoMethodOfPayment
  description?: string
  cgi: CreateDonationTrackingBodyDtoCgi
}

export const AssociationDonationForm: React.FC<AssociationDonationFormProps> = ({
  selectedDonation,
  handleFiscalReport,
}) => {
  const globalSnackBarStore = useGlobalSnackbarStore()

  //Mutation pour modifier un compte
  const editDonatorMutation = useMutation(
    (donation: FormParams) => {
      const newDonation: DonationTrackingDto = {
        ...selectedDonation,
        donaterId: donation.donaterId || selectedDonation.donaterId,
        dateCollected: donation.dateCollected,
        amount: parseFloat(donation.amount),
        formOfDonation: donation.formOfDonation,
        typeOfDonation: donation.typeOfDonation,
        methodOfPayment: donation.methodOfPayment,
        description: donation.description,
        cgi: donation.cgi,
      }
      return DonationTrackingsClient.editDonation(newDonation)
    },
    {
      onSuccess: () => {
        globalSnackBarStore.triggerSuccessMessage('Le donateur a bien été modifié')
      },
    }
  )

  const onSubmit = (data: FormParams) => {
    editDonatorMutation.mutate(data)
  }

  const getDefaultValues = (donation: DonationTrackingDto): FormParams => ({
    donaterId: donation?.donaterId,
    dateCollected: donation?.dateCollected,
    amount: donation?.amount.toString(),
    formOfDonation: donation?.formOfDonation,
    typeOfDonation: donation?.typeOfDonation,
    methodOfPayment: donation?.methodOfPayment,
    description: donation?.description,
    cgi: donation?.cgi,
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(selectedDonation, getDefaultValues)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledDateField
        sx={spacingItem}
        control={control}
        validate={(value) => isValidPastDateCoherence(value) || !value}
        error={errors.dateCollected}
        fieldName={'dateCollected'}
        label="Date de perception"
        requiredRule={'La date de perception est requise'}
      />

      <TextField
        sx={spacingItem}
        id="amount-number-input"
        label="Montant"
        type="number"
        inputProps={{
          step: '0.01',
          inputMode: 'decimal',
          pattern: '[0-9]*',
        }}
        fullWidth
        {...register('amount')}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
        required
      />

      <ControlledSelectField
        sx={spacingItem}
        control={control}
        error={errors.formOfDonation}
        fieldName="formOfDonation"
        label="Forme du don"
        options={Object.values(CreateDonationTrackingBodyDtoFormOfDonation).map((value) => ({
          label: getReadableDonationTrackingFormOfDonation(value),
          value: value,
        }))}
        requiredRule={'La forme du don est requise'}
      />

      <ControlledSelectField
        sx={spacingItem}
        control={control}
        error={errors.typeOfDonation}
        fieldName="typeOfDonation"
        label="Nature du don"
        options={Object.values(CreateDonationTrackingBodyDtoTypeOfDonation).map((value) => ({
          label: getReadableDonationTrackingTypeOfDonation(value),
          value: value,
        }))}
        requiredRule={'La nature du don est requise'}
      />

      <ControlledSelectField
        sx={spacingItem}
        control={control}
        error={errors.methodOfPayment}
        fieldName="methodOfPayment"
        label="Méthode de paiement"
        options={Object.values(CreateDonationTrackingBodyDtoMethodOfPayment).map((value) => ({
          label: getReadableDonationTrackingMethodOfPayment(value),
          value: value,
        }))}
        requiredRule={'La méthode de paiement est requise'}
      />

      <TextField
        sx={spacingItem}
        id="outlined-textarea"
        label="Description"
        placeholder="Description du don"
        multiline
        fullWidth
        {...register('description')}
        error={!!errors.description}
        helperText={errors.description?.message}
      />

      <ControlledSelectField
        sx={spacingItem}
        control={control}
        error={errors.cgi}
        fieldName="cgi"
        label="Le bénéficiaire certifie sur l'honneur que les dons et versements qu'il reçoit ouvrent droit à la réduction d'impôt prévue à l'article (3)"
        options={Object.values(CreateDonationTrackingBodyDtoCgi).map((value) => ({
          label: getReadableDonationTrackingCGI(value),
          value: value,
        }))}
        requiredRule={'La champs CGI est requis'}
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', ...spacingItem }}>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={(e) => handleFiscalReport(e)}
          sx={{ textTransform: 'none' }}
        >
          Editer un reçu fiscal
        </Button>
        <MemberCardSaveButton isOwnAccount={false} />
      </Box>
    </form>
  )
}
