import { Box, Card, CardContent, Collapse, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { ExitDocumentCard } from './ExitDocumentCard'
import { AdoptionAttemptDto } from '../../../interactors/gen/backendClient'
import { ArrowDownward } from '@mui/icons-material'
import { theme } from '../../theme'
import dayjs from 'dayjs'

interface Props {
  adoptionAttempt: AdoptionAttemptDto
  adopterId: string
  isReader: boolean
  defaultExpanded?: boolean
}

export const ExitDocumentsCard: React.FC<Props> = ({
  adoptionAttempt,
  adopterId,
  isReader,
  defaultExpanded = false,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleToggleExpand = () => {
    setExpanded(!expanded)
  }

  const documents = adoptionAttempt?.documents?.sort((a, b) => dayjs(b.uploadedAt).diff(dayjs(a.uploadedAt)))

  return (
    <Card
      sx={{
        m: 0,
        width: '100%',
      }}
    >
      <CardContent
        sx={{
          m: 0,
          p: isMobile ? 'auto' : '12px !important',
          '&:last-child': { pb: isMobile ? 0 : 'auto' },
        }}
      >
        <Box
          onClick={handleToggleExpand}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Typography variant="h4" component="p" sx={{ fontSize: isMobile ? 18 : 20, fontWeight: 700 }}>
            {isMobile ? 'Documents obligatoires' : 'Mise à disposition des documents obligatoires'}
          </Typography>
          <IconButton>
            <ArrowDownward
              style={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            />
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto">
          <Grid container spacing={isMobile ? 1 : 2}>
            <Grid item xs={12} md={6} lg={3}>
              <ExitDocumentCard
                title="Attestation de cession"
                type={'sell-certificate'}
                adopterId={adopterId}
                adoptionAttemptId={adoptionAttempt.id}
                originalFileKey={documents?.find((doc) => doc.type === 'sell-certificate')?.documentKey || ''}
                disabled={isReader}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ExitDocumentCard
                title="Certificat vétérinaire"
                type={'veterinary-certificate'}
                adopterId={adopterId}
                adoptionAttemptId={adoptionAttempt.id}
                originalFileKey={documents?.find((doc) => doc.type === 'veterinary-certificate')?.documentKey || ''}
                disabled={isReader}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ExitDocumentCard
                title="Document d'information"
                type={'information-document'}
                adopterId={adopterId}
                adoptionAttemptId={adoptionAttempt.id}
                originalFileKey={documents?.find((doc) => doc.type === 'information-document')?.documentKey || ''}
                disabled={isReader}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ExitDocumentCard
                title="Carnet de santé"
                type={'health-book'}
                adopterId={adopterId}
                adoptionAttemptId={adoptionAttempt.id}
                originalFileKey={documents?.find((doc) => doc.type === 'health-book')?.documentKey || ''}
                disabled={isReader}
              />
            </Grid>
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  )
}
