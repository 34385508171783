import { Box, Typography } from '@mui/material'
import React from 'react'
import { AccountDtoPermission, AdopterDto, AdopterDtoProfileHasBeenAccepted } from '../../interactors/gen/backendClient'
import { blue } from '../theme'
import { AdoptionForm } from './Profil/AdoptionForm'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useMutation } from 'react-query'
import { backendClient } from '../../interactors/clients/client'
import { LoadingButton } from '@mui/lab'
import { AdoptionFollowUp } from './Profil/AdoptionFollowUp'
import { DesiredAnimalBanner } from './Profil/DesiredAnimalBanner'
import { OptOutAdoptionFormModal } from './OptOutAdoptionFormModal'
import { useAccountsStore } from '../../store/AccountsStore'

interface Props {
  adopter: AdopterDto
  onSubmit: (data: Partial<AdopterDto>) => Promise<void>
}

export const AdopterProfil: React.FC<Props> = ({ adopter, onSubmit }) => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const sendFormMutation = useMutation(
    async () => {
      const config = {
        method: 'POST',
        url: 'adopters-platform/auth/create-password/request',
        data: {
          adopterId: adopter.id,
          customerId: adopter.customerId,
        },
      }
      const response = await backendClient(config)
      return response
    },
    {
      onSuccess: () => {
        globalSnackBarStore.triggerSuccessMessage(`Le formulaire a été envoyé par email à ${adopter.firstName}!`)
        adopter.profileHasBeenAccepted = AdopterDtoProfileHasBeenAccepted.FormSent
      },
    }
  )

  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        p: 2,
        pt: 2,
        pb: 10,
      }}
    >
      {adopter.profileHasBeenAccepted !== 'accepted' && <DesiredAnimalBanner adopter={adopter} onSubmit={onSubmit} />}
      {!adopter.adoptionForm && adopter.profileHasBeenAccepted !== 'accepted' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mb: 4 }}>
          <Typography variant="body2" sx={{ color: blue, fontSize: 20, fontWeight: 700 }}>
            {adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormSent
              ? `Le formulaire a été envoyé, vous serez notifié quand ${adopter.firstName} l'aura complété`
              : `Envoyer le formulaire de pré-adoption à ${adopter?.firstName} pour avoir plus d'informations`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <OptOutAdoptionFormModal onSubmit={onSubmit} />
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ width: '250px' }}
              onClick={() => sendFormMutation.mutate()}
              loading={sendFormMutation.isLoading}
              disabled={adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormSent || isReader}
            >
              {adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormSent
                ? 'Formulaire envoyé'
                : 'Envoyer le formulaire'}
            </LoadingButton>
          </Box>
        </Box>
      )}
      <AdoptionForm adopter={adopter} onSubmit={onSubmit} />
      <AdoptionFollowUp key={adopter.profileHasBeenAccepted} adopter={adopter} onSubmit={onSubmit} />
    </Box>
  )
}
