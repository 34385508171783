import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Collapse, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import {
  getReadableAdopterAdoptionFormMainHomeType,
  getReadableAdopterAdoptionFormWorkflow,
  getReadableAdopterAdoptionFormHomeCounter,
  getReadableAdopterAdoptionFormYesNo,
  getReadableAdopterAdoptionFormPlanForPetDuringRelocation,
  getReadableAdopterAdoptionFormCounter,
  getReadableAdopterAdoptionFormHomeComposition,
  getReadableAdopterAdoptionFormSex,
  getReadableAdopterAdoptionFormSterilizedOrNeutered,
  getReadableAdopterAdoptionFormHadAnimalBefore,
  getReadableAdopterAdoptionFormPetIntendedForSelfOrGift,
} from '../../../domain/Adopters/AccountDisplay'
import {
  AccountDtoPermission,
  AdopterDto,
  AdopterDtoAlreadyHasAnimalOrChild,
  AdopterDtoProfileHasBeenAccepted,
} from '../../../interactors/gen/backendClient'
import { spacingItem } from '../../theme'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { LoadingButton } from '@mui/lab'
import { useAccountsStore } from '../../../store/AccountsStore'
import { EditFormCustomerCommentModal } from '../EditFormCustomerCommentModal'

interface Props {
  adopter: AdopterDto
  onSubmit: (data: Partial<AdopterDto>) => Promise<void>
}

export const AdoptionForm: React.FC<Props> = ({ adopter, onSubmit }) => {
  const [isExpanded, setExpanded] = useState(
    adopter.adoptionForm &&
      !(
        adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormAccepted ||
        adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted
      )
      ? true
      : false
  )

  const handleClickOnExpand = () => {
    setExpanded(!isExpanded)
  }

  const handleDeclineForm = () => {
    onSubmit({ profileHasBeenAccepted: AdopterDtoProfileHasBeenAccepted.FormRefused })
    if (adopter && adopter.email) {
      const email = adopter.email
      const subject = encodeURIComponent("Votre formulaire d'adoption a été refusé")
      const body = encodeURIComponent(
        "Cher adoptant,\n\nNous sommes désolés de vous informer que votre formulaire d'adoption a été refusé pour les raisons suivantes:\n\n[Vos raisons ici]\n\nCordialement,\nL'équipe d'adoption"
      )

      // Open the user's mail client
      window.open(`mailto:${email}?subject=${subject}&body=${body}`, '_blank')
    } else {
      console.error('Adopter email is missing.')
    }
  }

  const handleAcceptForm = () => {
    onSubmit({ profileHasBeenAccepted: AdopterDtoProfileHasBeenAccepted.FormAccepted })
    setExpanded(false)
  }

  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          opacity: adopter.adoptionForm ? 1 : 0.5,
          cursor: adopter.adoptionForm ? 'default' : 'not-allowed',
          py: 1,
        }}
      >
        <Typography variant="h4" fontSize={{ xs: 24, sm: 32 }}>
          Étape 1: Formulaire de pré-adoption
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">
            {adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormRefused && '🚫 Formulaire refusé'}
            {adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused && '🚫 Profil refusé'}
            {(adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormAccepted ||
              adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted) &&
              '✅ Étape validée'}
          </Typography>
          <Button type="button" onClick={handleClickOnExpand}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Box>
      </Box>

      <Collapse in={isExpanded}>
        <Box
          sx={{
            ...spacingItem,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: 'grey.100',
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            {(adopter.adoptionForm?.customerComments ||
              adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormAccepted ||
              adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted ||
              adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused ||
              adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormRefused) && (
              <Grid item xs={12} md={12}>
                <Card>
                  <CardHeader
                    title={`${adopter.adoptionForm?.species ? 'Note' : 'Les infos'} sur ${adopter.firstName}`}
                  />
                  <CardContent>
                    <Typography variant="body2" sx={{ p: 2 }}>
                      {adopter.adoptionForm?.customerComments}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <EditFormCustomerCommentModal
                      onSubmit={onSubmit}
                      initialComment={adopter.adoptionForm?.customerComments}
                    />
                  </CardActions>
                </Card>
              </Grid>
            )}
            {adopter.adoptionForm?.desiredPetCharacteristics && (
              <Grid item xs={12} md={12}>
                <Card>
                  <CardHeader title={`Le message de ${adopter.firstName}`} />
                  <CardContent>
                    <Typography variant="body2" sx={{ p: 2 }}>
                      {adopter.adoptionForm?.desiredPetCharacteristics}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {adopter.adoptionForm?.species && (
              <>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardHeader title="Foyer et sensibilisation" />
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="body2" sx={{ fontSize: 16, textDecoration: 'underline' }}>
                          Composition du foyer
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', gap: 2 }}>
                          {Object.entries(adopter.adoptionForm?.alreadyHasAnimalOrChild || {})
                            .filter(([key]) => key !== 'dog' && key !== 'cat' && key !== 'other')
                            .map(([key, value]) => (
                              <Box
                                key={key}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography variant="body2" sx={{ fontSize: 16 }}>
                                  {getReadableAdopterAdoptionFormHomeComposition(
                                    key as keyof AdopterDtoAlreadyHasAnimalOrChild
                                  )}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: 16, fontWeight: 700 }}>
                                  {getReadableAdopterAdoptionFormCounter(value)}
                                </Typography>
                              </Box>
                            ))}
                        </Box>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Tous les membres de votre foyer sont-ils d'accord pour adopter un animal: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.allHouseMembersAgree
                              ? getReadableAdopterAdoptionFormYesNo(adopter.adoptionForm?.allHouseMembersAgree)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Y a-t-il des personnes allergiques dans votre foyer: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.allergiesInHousehold
                              ? getReadableAdopterAdoptionFormYesNo(adopter.adoptionForm?.allergiesInHousehold)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Êtes-vous conscient que l'arrivée d'un animal est coûteuse et peut vous amener à réaménager votre habitat: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.consciousOfAnimalNeeds
                              ? getReadableAdopterAdoptionFormYesNo(adopter.adoptionForm?.consciousOfAnimalNeeds)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Êtes-vous prêt à vous rendre chez le vétérinaire, à assumer les frais de santé et garantir l'hygiène et le bien-être de votre animal: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.consciousOfAnimalCost
                              ? getReadableAdopterAdoptionFormYesNo(adopter.adoptionForm?.consciousOfAnimalCost)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Avez-vous conscience que l'adoption doit être un acte murement réfléchi: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.consciousOfAnimalAdoption
                              ? getReadableAdopterAdoptionFormYesNo(adopter.adoptionForm?.consciousOfAnimalAdoption)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardHeader title="Mode de vie et Habitation" />
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography variant="body2" sx={{ fontSize: 16 }}>
                            {`Type d'habitation: `}
                            <span style={{ fontWeight: 700 }}>
                              {adopter.adoptionForm?.mainHomeType
                                ? getReadableAdopterAdoptionFormMainHomeType(adopter.adoptionForm?.mainHomeType)
                                : 'Non renseigné'}
                            </span>
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: 16 }}>
                            {`- Avec balcon: `}
                            <span style={{ fontWeight: 700 }}>
                              {adopter.adoptionForm?.hasExteriorAccess?.balcony
                                ? getReadableAdopterAdoptionFormHomeCounter(
                                    adopter.adoptionForm?.hasExteriorAccess?.balcony
                                  )
                                : 'Non renseigné'}
                            </span>
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: 16 }}>
                            {`- Avec jardin: `}
                            <span style={{ fontWeight: 700 }}>
                              {adopter.adoptionForm?.hasExteriorAccess?.garden
                                ? getReadableAdopterAdoptionFormHomeCounter(
                                    adopter.adoptionForm?.hasExteriorAccess?.garden
                                  )
                                : 'Non renseigné'}
                            </span>
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: 16 }}>
                            {`- Avec cours: `}
                            <span style={{ fontWeight: 700 }}>
                              {adopter.adoptionForm?.hasExteriorAccess?.courtyard
                                ? getReadableAdopterAdoptionFormHomeCounter(
                                    adopter.adoptionForm?.hasExteriorAccess?.courtyard
                                  )
                                : 'Non renseigné'}
                            </span>
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: 16 }}>
                            {`- Avec terrasse: `}
                            <span style={{ fontWeight: 700 }}>
                              {adopter.adoptionForm?.hasExteriorAccess?.terrace
                                ? getReadableAdopterAdoptionFormHomeCounter(
                                    adopter.adoptionForm?.hasExteriorAccess?.terrace
                                  )
                                : 'Non renseigné'}
                            </span>
                          </Typography>
                        </Box>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Mode de travail: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.workflow
                              ? getReadableAdopterAdoptionFormWorkflow(adopter.adoptionForm?.workflow)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Savez vous à qui vous pouvez confier votre animal en cas d'absence: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.caretakerDuringAbsence
                              ? getReadableAdopterAdoptionFormYesNo(adopter.adoptionForm?.caretakerDuringAbsence)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`En cas de déménagement: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.planForPetDuringRelocation
                              ? getReadableAdopterAdoptionFormPlanForPetDuringRelocation(
                                  adopter.adoptionForm?.planForPetDuringRelocation
                                )
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardHeader title="Préférences d'adoption" />
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Vous cherchez à adopter: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.species && adopter.adoptionForm?.species.length > 0
                              ? adopter.adoptionForm?.species.map((specie) => getReadableSpecies(specie)).join('/')
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Sexe: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.sex
                              ? getReadableAdopterAdoptionFormSex(adopter.adoptionForm?.sex)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Stérilisation: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.sterilizedOrNeutered
                              ? getReadableAdopterAdoptionFormSterilizedOrNeutered(
                                  adopter.adoptionForm?.sterilizedOrNeutered
                                )
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Avez-vous déjà eu un animal par le passé: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.hadAnimalBefore
                              ? getReadableAdopterAdoptionFormHadAnimalBefore(adopter.adoptionForm?.hadAnimalBefore)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`L'adoption est-elle destinée à être offerte en cadeau à une autre personne: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.petIntendedForSelfOrGift
                              ? getReadableAdopterAdoptionFormPetIntendedForSelfOrGift(
                                  adopter.adoptionForm?.petIntendedForSelfOrGift
                                )
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 16, textDecoration: 'underline' }}>
                          Présence d&#39;animaux
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', gap: 2 }}>
                          {Object.entries(adopter.adoptionForm?.alreadyHasAnimalOrChild || {})
                            .filter(([key]) => key === 'dog' || key === 'cat' || key === 'other')
                            .map(([key, value]) => (
                              <Box
                                key={key}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography variant="body2" sx={{ fontSize: 16 }}>
                                  {getReadableAdopterAdoptionFormHomeComposition(
                                    key as keyof AdopterDtoAlreadyHasAnimalOrChild
                                  )}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: 16, fontWeight: 700 }}>
                                  {getReadableAdopterAdoptionFormCounter(value)}
                                </Typography>
                              </Box>
                            ))}
                        </Box>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {`Si oui, vos animaux ont-ils l'habitude des autres animaux: `}
                          <span style={{ fontWeight: 700 }}>
                            {adopter.adoptionForm?.ownAnimalUsedToAnimals
                              ? getReadableAdopterAdoptionFormYesNo(adopter.adoptionForm?.ownAnimalUsedToAnimals)
                              : 'Non renseigné'}
                          </span>
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
          {adopter.profileHasBeenAccepted !== AdopterDtoProfileHasBeenAccepted.Refused &&
            adopter.profileHasBeenAccepted !== AdopterDtoProfileHasBeenAccepted.Accepted &&
            adopter.profileHasBeenAccepted !== AdopterDtoProfileHasBeenAccepted.FormAccepted &&
            adopter.profileHasBeenAccepted !== AdopterDtoProfileHasBeenAccepted.FormRefused &&
            !isReader && (
              <Box
                sx={{
                  ...spacingItem,
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="error"
                  sx={{ width: '450px' }}
                  onClick={handleDeclineForm}
                  disabled={isReader}
                >
                  Refuser la demande d&#39;adoption et informer l&#39;adoptant
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  sx={{ width: '350px' }}
                  onClick={handleAcceptForm}
                  disabled={isReader}
                >
                  Valider et planifier une prise de contact
                </LoadingButton>
              </Box>
            )}
          {adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormRefused && (
            <Box
              sx={{
                ...spacingItem,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                sx={{ width: '350px' }}
                onClick={handleAcceptForm}
                disabled={isReader}
              >
                Modifier la décision et accepter le formulaire
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Collapse>
    </>
  )
}
