import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AccountDtoPermission, AdopterDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { PATHS } from '../PATHS'
import { theme } from '../theme'

interface Props {
  adopter: AdopterDto
}

export const AddAdoptionPopUp: React.FC<Props> = ({ adopter }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()

  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        sx={{
          textTransform: 'none',
          fontSize: isMobile ? '14px' : '16px',
          width: isMobile ? '100%' : 'auto',
        }}
        onClick={openDialog}
        disabled={isReader}
      >
        {!isMobile && <Add sx={{ mr: 1 }} />}
        Ajouter une adoption
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: isMobile ? 5 : 15 },
        }}
      >
        <DialogContent>
          <Box>
            <Typography variant="h4" component="h2" marginBottom={2} fontSize={isMobile ? 18 : 20}>
              Souhaitez-vous ajouter une adoption passée ou lancer une nouvelle procédure ?
            </Typography>
            <Typography variant="body2" component="p" marginBottom={isMobile ? 0 : 2} fontSize={15} fontStyle="italic">
              {`L'intérêt d'ajouter une adoption passée est de pouvoir conserver l'historique des adoptions de l'adoptant sur Petso.`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              width: '100%',
              gap: isMobile ? '10px' : '20px',
            }}
          >
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              sx={{ textTransform: 'none', fontSize: isMobile ? '14px' : '16px', width: isMobile ? '100%' : 'auto' }}
              type="button"
              onClick={() => navigate(`${PATHS.assignerAnimal.absolute}?adopterId=${adopter.id}&type=pastAdoption`)}
            >
              Ajouter une adoption passée
            </LoadingButton>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              sx={{ textTransform: 'none', fontSize: isMobile ? '14px' : '16px', width: isMobile ? '100%' : 'auto' }}
              type="button"
              onClick={() => {
                navigate(`${PATHS.suiviAdoptant.absolute}/${adopter.id}?tab=profil&newAdoption=true`)
                closeDialog()
              }}
            >
              Démarrer une adoption
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}
