import { Box, Card, CardContent, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { getReadableAnimalAdoptionOfferType } from '../../../domain/Animal/AnimalDisplay'
import { AdoptionAttemptDto, AnimalDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { PaymentInfoModal } from './PaymentInfoModal'
import { theme } from '../../theme'

interface Props {
  adoptionAttempt: AdoptionAttemptDto
  animal: AnimalDto
  isPreStep?: boolean
  isReader: boolean
}

export const PaymentInfoCard: React.FC<Props> = ({ adoptionAttempt, animal, isPreStep, isReader }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const accountsStore = useAccountsStore()
  const [customer, setCustomer] = useState(accountsStore.connectedCustomer)
  return (
    <Card sx={{ m: 0, p: 0, boxShadow: isMobile ? 'none' : 'auto' }}>
      <CardContent
        sx={{ m: 0, p: { xs: 0, sm: '12px !important' }, '&:last-child': { pb: { xs: '16px', sm: '24px' } } }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {customer?.paymentInfo ? (
            <ul style={{ padding: isMobile ? '0 10px' : 0, margin: 0, listStylePosition: 'inside' }}>
              <b style={{ fontSize: isMobile ? '16px' : '19px', paddingLeft: 0 }}>
                Modalités de paiement communiquées à l&apos;adoptant
              </b>

              {customer?.paymentInfo?.IBANNumber && (
                <li style={{ fontSize: isMobile ? '14px' : '16px' }}>Virement bancaire</li>
              )}
              {customer?.paymentInfo?.checkOrder && <li style={{ fontSize: isMobile ? '14px' : '16px' }}>Chèque</li>}
              {customer?.paymentInfo?.onlinePaymentURL && (
                <li style={{ fontSize: isMobile ? '14px' : '16px' }}>Paiement en ligne</li>
              )}
            </ul>
          ) : (
            <Typography sx={{ fontSize: { xs: '16px', sm: '19px' } }}>Aucun moyen de paiement enregistré</Typography>
          )}
          {!isPreStep && animal.adoption.offerType && (
            <Typography variant="body1" sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
              {`L'adoption est prévue comme`} <b>{getReadableAnimalAdoptionOfferType(animal.adoption.offerType)}</b>
              {` à hauteur de`} <b>{animal.adoption?.priceInEuros || 0}€</b>
            </Typography>
          )}
          {customer && !isReader && (
            <PaymentInfoModal
              customer={customer}
              setCustomer={setCustomer}
              noEdit={adoptionAttempt.verifiedPaymentInfo}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

/* function atLeastOnePaymentMethod(info?: CustomerPaymentInfoDto): boolean {
  if (info?.IBANNumber || info?.checkOrder || info?.onlinePaymentURL) {
    return true
  }
  return false
} */
