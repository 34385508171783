import { Autocomplete, BaseTextFieldProps, Chip, SxProps, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { blueSky } from '../theme'

interface Option {
  title: string
  options: string[]
}

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Option[]
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  freeSolo?: boolean
  color?: string
  sx?: SxProps
}

export function ControlledMultipleFreeSoloAutoCompleteWithCategories<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  options,
  size,
  disabled,
  freeSolo,
  color = blueSky,
  sx,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={[] as any} // TODO: check this
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          sx={sx}
          onChange={(_, data) => onChange(data)}
          freeSolo={freeSolo}
          id={fieldName}
          options={options.flatMap((option) => option.options)} // Flatten options
          getOptionLabel={(option) => option}
          value={value}
          size={size}
          disabled={disabled}
          groupBy={(option) => {
            // Find the category for this option
            const category = options.find((category) => category.options.includes(option))
            return category?.title || ''
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...restTagProps } = getTagProps({ index })
              return (
                // Destructure getTagProps to remove the key
                <Chip
                  key={option}
                  label={option}
                  {...restTagProps}
                  // Customize the chip style here
                  style={{ backgroundColor: color, color: 'white' }}
                />
              )
            })
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} placeholder={label} error={!!error} />
          )}
        />
      )}
    />
  )
}
