import { Box, Card, CardContent, Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import {
  getReadableAccountPermission,
  getReadableAccountRole,
  getReadableAccountVeterinaryType,
} from '../../domain/Account/AccountDisplay'
import { AccountDto, AccountDtoRoles } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { isColorDark } from '../../utils/isColorDark'
import { blueSky } from '../theme'
import { EditMemberGeneralInfoPrompt } from './EditMemberGeneralInfoPrompt'

interface Props {
  account: AccountDto | null
  onSubmit: (data: Partial<AccountDto>) => Promise<void>
  isOwnAccount: boolean
  isLoading: boolean
}

export const MemberGeneralInfo: React.FC<Props> = ({ account, onSubmit, isOwnAccount, isLoading }) => {
  const accountsStore = useAccountsStore()
  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-around',
              width: '100%',
              gap: 4,
            }}
          >
            <Stack spacing={0.8}>
              <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight={700}>
                Coordonnées
              </Typography>
              {account?.email && (
                <Typography variant="body2" color="text.primary">
                  {account?.email}
                </Typography>
              )}
              {account?.phoneNumber && (
                <Typography variant="body2" color="text.primary">
                  {account?.phoneNumber}
                </Typography>
              )}
              <Typography variant="body2" color="text.primary">
                {account?.restrainedData
                  ? "Ces informations ne sont pas visibles compte tenu des préférences de l'utilisateur"
                  : `${account?.address ?? ''} ${account?.postalCode ?? ''} ${account?.city ?? ''}`}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight={700}>
                Rôle(s)
              </Typography>
              {account?.roles
                .filter((role) => role !== AccountDtoRoles.Veterinary)
                .map((role) => (
                  <Chip
                    key={role}
                    label={getReadableAccountRole(role)}
                    sx={{
                      maxWidth: '180px',
                      backgroundColor: blueSky,
                      color: isColorDark(blueSky) ? 'black' : 'white',
                    }}
                  />
                ))}
              {account?.roles.includes(AccountDtoRoles.Veterinary) && (
                <Chip
                  key={account.veterinary?.type}
                  label={
                    account.veterinary?.type
                      ? getReadableAccountVeterinaryType(account.veterinary?.type)
                      : 'Vétérinaire indépendant'
                  }
                  sx={{
                    maxWidth: '180px',
                    backgroundColor: blueSky,
                    color: isColorDark(blueSky) ? 'black' : 'white',
                  }}
                />
              )}
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight={700}>
                Poste(s)
              </Typography>
              {account?.titles &&
                account?.titles.length > 0 &&
                account?.titles.map((poste) => (
                  <Chip
                    key={poste}
                    label={poste}
                    sx={{
                      maxWidth: '180px',
                      backgroundColor: blueSky,
                      color: isColorDark(blueSky) ? 'black' : 'white',
                    }}
                  />
                ))}
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight={700}>
                Permission
              </Typography>
              <Chip
                key={account?.permission}
                label={account?.permission ? getReadableAccountPermission(account?.permission) : '..'}
                sx={{ maxWidth: '180px', backgroundColor: blueSky, color: isColorDark(blueSky) ? 'black' : 'white' }}
              />
            </Stack>
          </Box>
          {(accountsStore.canEdit() || isOwnAccount) && (
            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditMemberGeneralInfoPrompt
                account={account}
                onSubmit={onSubmit}
                isOwnAccount={isOwnAccount}
                isLoading={isLoading}
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
