import { Add, Edit, Search as SearchIcon } from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Fab,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { getReadableCustomerTemplateType } from '../../../domain/Customer/CustomerDisplay'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import { CustomerDto, CustomerTemplateDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { getCustomerSignatureSrc } from '../../../utils/S3-links'
import { CircularProgressPanel } from '../../common/CircularProgressPanel'
import { colors, spacingItem } from '../../theme'
import { TemplateItem } from './TemplateItem'
import { DefaultTemplateItem } from './DefaultTemplateItem'

interface TemplatesListProps {
  association: CustomerDto
}

export const TemplatesList: React.FC<TemplatesListProps> = ({ association }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [templatesList, setTemplatesList] = useState<CustomerTemplateDto[]>(association?.templates || [])
  const navigate = useNavigate()

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const filteredTemplates = templatesList
    ? templatesList.filter(
        (template) =>
          searchQuery === '' ||
          template.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          getReadableCustomerTemplateType(template.type)?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : []

  const fetchCustomerMutation = useMutation(async () => await CustomersClient.getCurrentCustomer(), {
    onSuccess: (data) => {
      setTemplatesList(data.templates || [])
    },
  })

  useEffect(() => {
    fetchCustomerMutation.mutate()
  }, [])

  const handleSignatureClick = () => {
    navigate('/association/signature')
  }

  const accountsStore = useAccountsStore()
  const members = accountsStore.members
  if (!association) return <CircularProgressPanel />
  const member = members.find((m) => m.id === association.signature?.madeBy)

  return (
    <>
      {/* Signature Section */}
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="Signature" />
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {association && association.signature ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  p: 2,
                  border: '1px solid #ccc',
                  borderRadius: 2,
                  backgroundColor: '#f5f5f5',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <img
                    src={getCustomerSignatureSrc(association.signature.fileKey)}
                    alt="Signature"
                    style={{ maxHeight: '100px', maxWidth: '150px' }}
                  />
                  <Box>
                    <Typography variant="h6">Signature actuelle</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                      <Typography variant="caption" color="text.secondary">
                        Mis en ligne le {new Date(association.signature.signedAt).toLocaleDateString('fr-FR')}
                      </Typography>
                      {member && (
                        <Typography variant="caption" color="text.secondary">
                          Par {fullName(member)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <IconButton
                  onClick={handleSignatureClick}
                  sx={{
                    '&:hover svg': {
                      color: 'black',
                    },
                  }}
                >
                  <Edit
                    sx={{
                      fontSize: 40,
                      color: 'grey',
                      transition: 'color 0.2s',
                    }}
                  />
                </IconButton>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  p: 2,
                  border: '1px solid #ccc',
                  borderRadius: 2,
                  backgroundColor: '#f5f5f5',
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
                onClick={handleSignatureClick}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Add fontSize="large" />
                  <Typography variant="h6">Ajouter une signature</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      {/* Templates Section */}
      <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{ ...spacingItem, backgroundColor: colors.white }}>
        <TextField
          id="name-input"
          placeholder="Rechercher un modèle"
          type="text"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          sx={{
            borderRadius: 0,
            [`& fieldset`]: {
              borderRadius: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Card>
        <CardHeader title="Modèles de documents" />
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, width: '100%' }}>
            <DefaultTemplateItem />
            {filteredTemplates.length > 0 &&
              filteredTemplates.map((template) => (
                <TemplateItem key={template.id} template={template} setTemplatesList={setTemplatesList} />
              ))}
          </Box>

          <Box display="flex" justifyContent="flex-end" sx={spacingItem}>
            <Fab color="primary" variant="extended" onClick={() => navigate('/association/template')}>
              <Add sx={{ mr: 1 }} />
              Ajouter un modèle
            </Fab>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
