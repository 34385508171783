import dayjs from 'dayjs'
import { toDateOnly } from '../../utils/date/DateOnly'
import { downloadPdf } from '../../utils/downloadCSV'
import { CreateDonationTrackingBodyDto, DonationTrackingDto } from '../gen/backendClient'
import { backendClient } from './client'
import { AxiosRequestConfig } from 'axios'

export class DonationTrackingsClient {
  public static async createDonation(params: CreateDonationTrackingBodyDto): Promise<DonationTrackingDto> {
    const config: AxiosRequestConfig<CreateDonationTrackingBodyDto> = {
      method: 'POST',
      url: '/donationtrackings',
      data: params,
    }

    const response = await backendClient<CreateDonationTrackingBodyDto>(config)

    return response.data as unknown as DonationTrackingDto
  }

  public static async getAllDonations(): Promise<Array<DonationTrackingDto>> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/donationtrackings',
    }

    const response = await backendClient<Array<DonationTrackingDto>>(config)

    return response.data
  }

  public static async editDonation(params: DonationTrackingDto): Promise<DonationTrackingDto> {
    const config: AxiosRequestConfig<DonationTrackingDto> = {
      method: 'PUT',
      url: '/donationtrackings',
      data: {
        ...params,
      },
    }

    const response = await backendClient<DonationTrackingDto>(config)

    return response.data
  }

  public static async downloadFiscalReportPdf(donorId: string, donationTrackingId: string): Promise<void> {
    const url = `/donationtrackings/export/pdf?donorId=${donorId}&donationTrackingId=${donationTrackingId}`

    console.log(url)
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      responseType: 'blob',
    }

    const response = await backendClient<Blob>(config)

    await downloadPdf(response.data, `reçu-fiscal-${toDateOnly(dayjs())}`)
  }

  public static async downloadMultipleFiscalReportPdf(
    donorId: string,
    startDate: string,
    endDate: string
  ): Promise<void> {
    const url = `/donationtrackings/export/multiple-pdf?donorId=${donorId}&startDate=${dayjs(
      startDate
    ).toISOString()}&endDate=${dayjs(endDate).toISOString()}`

    console.log(url)
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      responseType: 'blob',
    }

    const response = await backendClient<Blob>(config)

    await downloadPdf(response.data, `reçu-fiscaux-${toDateOnly(dayjs())}`)
  }
}
