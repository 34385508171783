import { Autocomplete, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { debounce } from 'lodash'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { getEmojiSpecies, getReadableAnimalAdoptionStatus } from '../../domain/Animal/AnimalDisplay'
import { AnimalAdoptionDtoStatus, AnimalDtoSpecies } from '../../interactors/gen/backendClient'
import { spacingItem } from '../theme'

type Option = {
  label: string
  value: string
  species: AnimalDtoSpecies
  adoptionStatus: AnimalAdoptionDtoStatus
}

type Props<T extends FieldValues> = {
  control: Control<T>
  fieldName: Path<T>
  label?: string
}

export function ControlledAnimalsAutocomplete<T extends FieldValues>({
  control,
  fieldName,
  label,
}: PropsWithChildren<Props<T>>) {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedAdoptionStatus, setSelectedAdoptionStatus] = useState<AnimalAdoptionDtoStatus | null>(null)

  const fetchData = async (search: string) => {
    if (!search) {
      return
    }

    setLoading(true)
    try {
      const animals = await AnimalsClient.getAllAnimal(1, 50, undefined, search)
      const newOptions = animals.map((animal) => ({
        label: animal.name,
        value: animal.id,
        species: animal.species,
        adoptionStatus: animal.adoptionStatus,
      }))
      setOptions(newOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  // Use useCallback to create a stable reference of debounced function
  const debouncedFetchData = useCallback(
    debounce((search: string) => fetchData(search), 1000),
    [] // dependencies array is empty, meaning the function is created only once
  )

  const handleInputChange = (_: React.SyntheticEvent<Element, Event>, newInputValue: string, reason: string) => {
    setInputValue(newInputValue)
    if (reason === 'input') {
      debouncedFetchData(newInputValue)
    }
  }

  const handleOptionChange = (newValue: Option | null) => {
    setSelectedAdoptionStatus(newValue?.adoptionStatus || null)
  }

  console.log(selectedAdoptionStatus)

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            value={options.find((option) => option.value === value)}
            onChange={(_, newValue) => {
              onChange(newValue?.value ?? null)
              handleOptionChange(newValue)
            }}
            sx={{ ...spacingItem }}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            loading={loading}
            options={options}
            noOptionsText={
              inputValue.length > 0 ? 'Aucun animal correspondant à votre recherche.' : "Saisissez le nom de l'animal"
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label || 'Rechercher un animal'}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      {getEmojiSpecies(option.species as AnimalDtoSpecies)}
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      <Typography variant="body1">{option.label}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {getReadableAnimalAdoptionStatus(option.adoptionStatus as AnimalAdoptionDtoStatus)}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }}
          />
        )}
      />
      {/* {(selectedAdoptionStatus === 'has-been-adopted' ||
        selectedAdoptionStatus === 'in-the-process-of-being-adoptable') && (
        <Typography sx={{ mt: 2 }}>
          Attention cet animal semble déjà avoir été adopté / être en cours d&#39;adoption.
        </Typography>
      )} */}
    </>
  )
}
