import React from 'react'
import { AccountDtoAvailability, AccountDtoAvailabilityStatus } from '../../../interactors/gen/backendClient'
import { Dialog, DialogTitle, Box, DialogContent, DialogActions, Button, Grid, Typography } from '@mui/material'
import { DialogOpenedStore } from '../../../utils/hooks/useDialogOpened'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../../common/ControlledDateField'
import { spacingItem } from '../../theme'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { getColorForAvailabilityStatus, getReadableAvailabilityStatus } from '../../../domain/Account/AccountDisplay'
import { MemberStatusSelectField } from '../MemberStatusSelectField'

interface AvailabilityFormParams {
  startDate: string
  endDate: string
  status: AccountDtoAvailabilityStatus
}

interface Props {
  availabilities: AccountDtoAvailability[]
  setAvailabilities: (availabilities: AccountDtoAvailability[]) => void
  dialogOpenedState: DialogOpenedStore
  selectedStartDate: Date | null
}

export const NewAvailabilityPrompt: React.FC<Props> = ({
  availabilities,
  setAvailabilities,
  dialogOpenedState,
  selectedStartDate,
}) => {
  const submitProcess = (data: AvailabilityFormParams) => {
    const newAvailability = {
      ...data,
      status: data.status as AccountDtoAvailabilityStatus,
    }
    reset()
    setAvailabilities([...availabilities, newAvailability])
    return dialogOpenedState.closeDialog()
  }

  const getDefaultValues = (startDate: Date | null): AvailabilityFormParams => ({
    startDate: startDate?.toISOString() || '',
    endDate: startDate?.toISOString() || '',
    status: 'emergency-only',
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useFormExtended(selectedStartDate, getDefaultValues)

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Ajouter une indisponibilité</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ControlledDateField
                    sx={spacingItem}
                    control={control}
                    validate={(value) => isValidPastDateCoherence(value) || !value}
                    error={errors.startDate}
                    fieldName={'startDate'}
                    label="Date de début"
                    requiredRule={'La date de début est requise'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledDateField
                    sx={spacingItem}
                    control={control}
                    validate={(value) => isValidPastDateCoherence(value) || !value}
                    error={errors.endDate}
                    fieldName={'endDate'}
                    label="Date de fin"
                    requiredRule={'La date de fin est requise'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography fontSize={18}>Pendant cette période, je serai:</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MemberStatusSelectField
                    label="Disponibilité"
                    control={control}
                    error={errors.status}
                    fieldName="status"
                    options={Object.values(AccountDtoAvailabilityStatus)
                      .filter((status) => status !== AccountDtoAvailabilityStatus.Active)
                      .map((status: AccountDtoAvailabilityStatus) => ({
                        label: getReadableAvailabilityStatus(status),
                        value: status,
                        color: getColorForAvailabilityStatus(status),
                      }))}
                    requiredRule="Ce champs est requis"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={dialogOpenedState.closeDialog}>Annuler</Button>
              <Button type="submit" variant="contained" color="primary">
                Valider
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
