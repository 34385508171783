import { AlertColor } from '@mui/material'
import create from 'zustand'

interface GlobalSnackbarStore {
  isSnackbarOpen: boolean
  openSnackbar: () => void
  closeSnackbar: () => void
  triggerSuccessMessage: (message: string) => void
  triggerErrorMessage: (message: string) => void
  triggerInfoMessage: (message: string) => void
  setMessage: (message: string) => void
  message: string
  severity: AlertColor
}

export const useGlobalSnackbarStore = create<GlobalSnackbarStore>()((set, get) => ({
  isSnackbarOpen: false,
  message: '',
  severity: 'success',
  openSnackbar: () => {
    set({ isSnackbarOpen: true })
  },
  closeSnackbar: () => {
    set({ isSnackbarOpen: false })
  },
  setMessage: (message: string) => {
    set({ message })
  },
  triggerSuccessMessage: (message: string) => {
    set({ severity: 'success' })
    get().setMessage(message)
    get().openSnackbar()
  },
  triggerInfoMessage: (message: string) => {
    set({ severity: 'info' })
    get().setMessage(message)
    get().openSnackbar()
  },
  triggerErrorMessage: (message: string) => {
    set({ severity: 'error' })
    get().setMessage(message)
    get().openSnackbar()
  },
}))
