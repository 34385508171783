import React, { FC, useEffect } from 'react'

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Drawer,
  Fab,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'

import { Add, AssignmentTurnedIn, Healing, Pets, Search as SearchIcon, Transgender, Tune } from '@mui/icons-material'

import {
  AdopterDto,
  AdopterDtoPreferenceSex,
  AdopterDtoPreferenceSterilizedOrNeutered,
  AdopterDtoProfileHasBeenAccepted,
  AdoptionAttemptDto,
  AdoptionAttemptDtoStatus,
  AnimalDtoSpecies,
} from '../../interactors/gen/backendClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'

import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { accountCanEdit } from '../../domain/Account/accountCanEdit'
import {
  getColorForAdopterProfileHasBeenAccepted,
  getReadableAdopterFieldLabel,
  getReadableAdopterPreferenceSex,
  getReadableAdopterPreferenceSterilized,
  getReadableAdopterProfileHasBeenAccepted,
} from '../../domain/Adopters/AccountDisplay'
import { searchAdopters } from '../../domain/Adopters/searchAdopters'
import { sortAdopters } from '../../domain/Adopters/sortAdopters'
import { getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import { useAccountsStore } from '../../store/AccountsStore'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { useFetchOrRefreshAdoptersOnMount } from '../../store/useFetchOrRefreshAdoptersOnMount'
import { getAnimalProfilePhotoSrc } from '../../utils/S3-links'
import { getAdoptionAttemptStatus } from '../../utils/getAdoptionAttemptStatus'
import { isColorDark } from '../../utils/isColorDark'
import { setOpacity } from '../../utils/setOpacity'
import { sortAlphabetically } from '../../utils/sort'
import { PATHS } from '../PATHS'
import { AQuestion } from '../common/AQuestion'
import { FilterList } from '../common/FilterList'
import { FilterListItem } from '../common/FilterListItem'
import { spacingItem } from '../theme'
import { AdopterListCard } from './AdopterListCard'

export const AdoptersListScreen: FC = () => {
  const [adopters, setAdopters] = React.useState<AdopterDto[]>([])
  const [selectedTab, setSelectedTab] = React.useState<'all' | 'new' | 'to-do' | 'waiting' | 'to-match'>('all')
  const [selectedSpecies, setSelectedSpecies] = React.useState<AnimalDtoSpecies[]>([])
  const [selectedSexs, setSelectedSexs] = React.useState<AdopterDtoPreferenceSex[]>([])
  const [selectedSterilisations, setSelectedSterilisations] = React.useState<
    AdopterDtoPreferenceSterilizedOrNeutered[]
  >([])
  const [selectedProfileHasBeenAccepted, setSelectedProfileHasBeenAccepted] = React.useState<
    AdopterDtoProfileHasBeenAccepted[]
  >([])
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const navigate = useNavigate()

  useFetchOrRefreshAdoptersOnMount()

  const adoptersStore = useAdoptersStore()
  const accountsStore = useAccountsStore()

  const attemptStatusToDo: AdoptionAttemptDtoStatus[] = [
    AdoptionAttemptDtoStatus.AwaitingCertificate,
    AdoptionAttemptDtoStatus.AwaitingContract,
    AdoptionAttemptDtoStatus.AwaitingCertificateValidation,
    AdoptionAttemptDtoStatus.AwaitingContractValidation,
    AdoptionAttemptDtoStatus.AwaitingPaymentValidation,
    AdoptionAttemptDtoStatus.PendingUser,
  ]

  const attemptStatusWaiting: AdoptionAttemptDtoStatus[] = [
    AdoptionAttemptDtoStatus.AwaitingSignedCertificate,
    AdoptionAttemptDtoStatus.AwaitingSignedContract,
    AdoptionAttemptDtoStatus.AwaitingPayment,
  ]

  useEffect(() => {
    const isNotDeleted = (adopter: AdopterDto) => !adopter.isDeleted
    const hasOngoingAdoption = (adopter: AdopterDto, statusList: AdoptionAttemptDtoStatus[]) => {
      return adopter.adoptionAttempts?.some((attempt: AdoptionAttemptDto) => statusList.includes(attempt.status))
    }

    switch (selectedTab) {
      case 'all':
        setAdopters(adoptersStore.adopters.filter(isNotDeleted))
        break
      case 'new':
        setAdopters(
          adoptersStore.adopters.filter(
            (adopter) =>
              isNotDeleted(adopter) && adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Waiting
          )
        )
        break
      case 'to-do':
        setAdopters(
          adoptersStore.adopters.filter(
            (adopter) =>
              isNotDeleted(adopter) &&
              (adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Waiting ||
                adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormAccepted ||
                hasOngoingAdoption(adopter, attemptStatusToDo))
          )
        )
        break
      case 'waiting':
        setAdopters(
          adoptersStore.adopters.filter(
            (adopter) =>
              isNotDeleted(adopter) &&
              (adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormSent ||
                hasOngoingAdoption(adopter, attemptStatusWaiting))
          )
        )
        break
      case 'to-match':
        setAdopters(
          adoptersStore.adopters.filter(
            (adopter) =>
              isNotDeleted(adopter) &&
              adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted &&
              (adopter.adoptionAttempts?.length === 0 || adopter.adoptionAttempts === undefined)
          )
        )
        break
      default:
        throw new Error(`selectedTab ${selectedTab} not supported`)
    }
  }, [adoptersStore.adopters, selectedTab])

  const handleTabChange = (_: React.SyntheticEvent, newValue: 'all' | 'new' | 'to-do' | 'waiting' | 'to-match') => {
    setSelectedTab(newValue)
  }

  const [searchQuery, setSearchQuery] = React.useState('')

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchQuery(e.target.value)
  }

  const account = accountsStore.connectedAccount

  const species = accountsStore.connectedCustomer?.preferences.species

  const numberOfWaitingProfiles = adoptersStore.adopters.filter(
    (adopter) => adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Waiting
  ).length

  const profileHasBeenAcceptedCustomOrder = [
    AdopterDtoProfileHasBeenAccepted.FormToSent,
    AdopterDtoProfileHasBeenAccepted.Waiting,
    AdopterDtoProfileHasBeenAccepted.FormAccepted,
    AdopterDtoProfileHasBeenAccepted.Accepted,
    AdopterDtoProfileHasBeenAccepted.FormSent,
    AdopterDtoProfileHasBeenAccepted.FormRefused,
    AdopterDtoProfileHasBeenAccepted.Refused,
    AdopterDtoProfileHasBeenAccepted.Blacklisted,
  ]

  const FiltersBar = () => {
    return (
      <>
        <FilterList label="Statut de l'adoptant" icon={<AssignmentTurnedIn />}>
          {profileHasBeenAcceptedCustomOrder.map((profil) => {
            const isSelected = selectedProfileHasBeenAccepted.includes(profil)

            return (
              <FilterListItem
                key={profil}
                label={`${getReadableAdopterProfileHasBeenAccepted(profil)} ${
                  profil === AdopterDtoProfileHasBeenAccepted.Waiting ? ': ' + numberOfWaitingProfiles : ''
                }`}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the specie is already selected, remove it from the selectedSpecies array
                    setSelectedProfileHasBeenAccepted(selectedProfileHasBeenAccepted.filter((p) => p !== profil))
                  } else {
                    // If the specie is not selected, add it to the selectedSpecies array
                    setSelectedProfileHasBeenAccepted([...selectedProfileHasBeenAccepted, profil])
                  }
                }}
              />
            )
          })}
        </FilterList>

        <Typography variant="h6" gutterBottom sx={spacingItem}>
          Préférences des adoptants
        </Typography>

        {species && species.length > 1 && (
          <FilterList label="Espèces" icon={<Pets />}>
            {species?.map((specie) => {
              const isSelected = selectedSpecies.includes(specie)

              return (
                <FilterListItem
                  key={specie}
                  label={getReadableSpecies(specie)}
                  isSelected={isSelected}
                  toggleSelected={() => {
                    if (isSelected) {
                      // If the specie is already selected, remove it from the selectedSpecies array
                      setSelectedSpecies(selectedSpecies.filter((s) => s !== specie))
                    } else {
                      // If the specie is not selected, add it to the selectedSpecies array
                      setSelectedSpecies([...selectedSpecies, specie])
                    }
                  }}
                />
              )
            })}
          </FilterList>
        )}

        <FilterList label="Sexe" icon={<Transgender />}>
          {Object.values(AdopterDtoPreferenceSex).map((sex) => {
            const isSelected = selectedSexs.includes(sex)

            return (
              <FilterListItem
                key={sex}
                label={getReadableAdopterPreferenceSex(sex)}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the sex is already selected, remove it from the selectedSexs array
                    setSelectedSexs(selectedSexs.filter((s) => s !== sex))
                  } else {
                    // If the sex is not selected, add it to the selectedSexs array
                    setSelectedSexs([...selectedSexs, sex])
                  }
                }}
              />
            )
          })}
        </FilterList>

        <FilterList label="Stérilisation" icon={<Healing />}>
          {Object.values(AdopterDtoPreferenceSterilizedOrNeutered).map((sterilisation) => {
            const isSelected = selectedSterilisations.includes(sterilisation)

            return (
              <FilterListItem
                key={sterilisation}
                label={getReadableAdopterPreferenceSterilized(sterilisation)}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the sterilisation is already selected, remove it from the selectedSterilisations array
                    setSelectedSterilisations(selectedSterilisations.filter((s) => s !== sterilisation))
                  } else {
                    // If the sterilisation is not selected, add it to the selectedSexs array
                    setSelectedSterilisations([...selectedSterilisations, sterilisation])
                  }
                }}
              />
            )
          })}
        </FilterList>
        <AQuestion />
      </>
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: 1,
          paddingBottom: 7.5,
          backgroundColor: 'grey.100',
          minHeight: `calc(100% - 20px)`,
          boxSizing: 'border-box',
          marginBottom: 2,
          maxHeight: `calc(100% - 20px)`,
        }}
      >
        {adoptersStore.isLoading ? (
          <CircularProgressPanel />
        ) : (
          <>
            {/* Filters for desktop version */}
            <Card
              sx={{
                ...spacingItem,
                width: 230,
                display: { xs: 'none', sm: 'block' },
                overflowY: 'auto',
              }}
            >
              <CardContent>
                <FiltersBar />
              </CardContent>
            </Card>
            {/* Filters for mobile version */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
              }}
            >
              <Box sx={{ padding: 2 }}>
                <FiltersBar />
              </Box>
            </Drawer>
          </>
        )}

        <Card
          sx={{
            ...spacingItem,
            marginLeft: { xs: 0, sm: 2 },
            width: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              marginBottom: '1rem',
              borderBottom: '1px solid lightgray',
            }}
          >
            <Tab
              label="Tous"
              value="all"
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="À faire"
              value="to-do"
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="En attente"
              value="waiting"
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="Profils à matcher"
              value="to-match"
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          </Tabs>
          <Box display="flex" flexDirection="row" flexWrap="nowrap">
            <TextField
              id="name-input"
              placeholder="Rechercher dans les adoptants"
              type="text"
              fullWidth
              value={searchQuery}
              onChange={onChangeSearch}
              sx={{
                borderRadius: 0,
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' }, mx: 'auto' }}
            >
              <Tune />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',
              overflow: 'auto',
              paddingBottom: 17, // HACK: To avoid the last row to be hidden
            }}
          >
            {adoptersStore.isLoading ? (
              <CircularProgressPanel />
            ) : (
              <SearchResults
                members={adopters}
                searchQuery={searchQuery}
                filters={{ selectedSpecies, selectedSexs, selectedSterilisations, selectedProfileHasBeenAccepted }}
              />
            )}
          </Box>
        </Card>
      </Box>

      {account && accountCanEdit(account) && (
        <Box sx={{ position: 'absolute', right: 2, bottom: 0, margin: 2 }}>
          <Fab color="primary" variant="extended" onClick={() => navigate(PATHS.ajouterAdoptant.absolute)}>
            <Add sx={{ mr: 1 }} />
            Ajouter un Adoptant
          </Fab>
        </Box>
      )}
    </>
  )
}

type SortDirection = 'asc' | 'desc'
type SortColumn = 'firstName' | 'lastName' | 'email' | 'profileHasBeenAccepted' | 'phoneNumber'

type SearchResultsProps = {
  members: Array<AdopterDto>
  searchQuery: string
  filters?: {
    selectedSpecies: AnimalDtoSpecies[]
    selectedSexs: AdopterDtoPreferenceSex[]
    selectedSterilisations: AdopterDtoPreferenceSterilizedOrNeutered[]
    selectedProfileHasBeenAccepted: AdopterDtoProfileHasBeenAccepted[]
  }
}

const SearchResults: React.FC<SearchResultsProps> = ({ members, searchQuery, filters }) => {
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('profileHasBeenAccepted')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')
  const [expandCardIndex, setExpandCardIndex] = React.useState<number | null>(null)
  const isMobile = useMediaQuery('(max-width:600px)')
  const handleSort = (column: SortColumn, columnDefaultDirection: SortDirection) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortDirection(columnDefaultDirection)
    }

    setSortColumn(column)
  }
  const searchResult = searchAdopters(members, searchQuery)
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedSpecies.length) {
        return true
      }
      return member.preferences.species
        ? member.preferences.species.some((specie) => filters.selectedSpecies.includes(specie))
        : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedSexs.length) {
        return true
      }
      return member.preferences.sex ? filters.selectedSexs.includes(member.preferences.sex) : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedSterilisations.length) {
        return true
      }
      return member.preferences.sterilizedOrNeutered
        ? filters.selectedSterilisations.includes(member.preferences.sterilizedOrNeutered)
        : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedProfileHasBeenAccepted.length) {
        return true
      }
      return member.profileHasBeenAccepted
        ? filters.selectedProfileHasBeenAccepted.includes(member.profileHasBeenAccepted)
        : false
    })
    .sort((a, b) => {
      switch (sortColumn) {
        case 'firstName':
          return sortAlphabetically(a, b, sortDirection, 'firstName')
        case 'lastName':
          return sortAlphabetically(a, b, sortDirection, 'lastName')
        case 'email':
          return sortAlphabetically(a, b, sortDirection, 'email')
        case 'profileHasBeenAccepted':
          return sortAdopters(a, b)
        case 'phoneNumber':
          return sortAlphabetically(a, b, sortDirection, 'phoneNumber', 'firstName')
        default:
          throw new Error(`sortColumn ${sortColumn} not supported`)
      }
    })

  return !isMobile ? (
    // Desktop version
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'firstName'}
              direction={sortDirection}
              onClick={() => {
                handleSort('firstName', 'asc')
              }}
            >
              {getReadableAdopterFieldLabel('firstName')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'lastName'}
              direction={sortDirection}
              onClick={() => {
                handleSort('lastName', 'asc')
              }}
            >
              {getReadableAdopterFieldLabel('lastName')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'profileHasBeenAccepted'}
              direction={sortDirection}
              onClick={() => {
                handleSort('profileHasBeenAccepted', 'asc')
              }}
            >
              Statut de l&#39;adoptant
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'email'}
              direction={sortDirection}
              onClick={() => {
                handleSort('email', 'asc')
              }}
            >
              {getReadableAdopterFieldLabel('email')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'phoneNumber'}
              direction={sortDirection}
              onClick={() => {
                handleSort('phoneNumber', 'asc')
              }}
            >
              Adoption(s) en cours
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ height: '100%' }}>
        {searchResult.map((member) => (
          <AdopterListItem key={member.id} member={member} />
        ))}
      </TableBody>
    </Table>
  ) : (
    // Mobile version
    <Stack spacing={1.5} marginTop="10px" marginBottom="80px" marginX="15px">
      {searchResult.map((member, index) => (
        <AdopterListCard
          key={member.id}
          member={member}
          index={index}
          expandCardIndex={expandCardIndex}
          setExpandCardIndex={setExpandCardIndex}
        />
      ))}
    </Stack>
  )
}

type ItemProps = {
  member: AdopterDto
}

const AdopterListItem: React.FC<ItemProps> = ({ member }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${member.id}`)
  }

  return (
    <TableRow onClick={handleClick} sx={{ cursor: 'pointer', height: '100%' }}>
      <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
        {member.firstName}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.lastName}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.profileHasBeenAccepted && (
          <Chip
            label={getReadableAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted)}
            sx={{
              backgroundColor: setOpacity(getColorForAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted), 0.8),
              color: isColorDark(getColorForAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted))
                ? '#fff'
                : '#000',
            }}
          />
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.email}
      </TableCell>

      <TableCell component="th" scope="row">
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', maxWidth: '60%' }}>
          {member.adoptionAttempts?.map((attempt) => {
            return (
              <AdoptionAttemptChip
                key={attempt.id}
                member={member}
                attempt={attempt}
                avatarSx={animalAvatarSx}
                textSx={adoptionChipTextSx}
              />
            )
          })}
        </Box>
      </TableCell>
    </TableRow>
  )
}

type ChipProps = {
  member: AdopterDto
  attempt: AdoptionAttemptDto
  avatarSx: SxProps
  textSx: SxProps
}

// AdoptionAttemptsChip props

const animalAvatarSx = {
  height: '35px !important',
  width: '35px !important',
  ml: '-1px !important',
  mt: '-1px !important',
}
const adoptionChipTextSx = {
  fontSize: 12,
  height: 35,
  pl: 0,
  gap: 0,
}

export const AdoptionAttemptChip: React.FC<ChipProps> = ({ member, attempt, avatarSx, textSx }) => {
  const navigate = useNavigate()
  return (
    <Chip
      clickable
      onClick={(e) => {
        e.stopPropagation()
        navigate(
          `${PATHS.suiviAdoptant.absolute}/${member.id}?tab=${getTabForAdoptionAttemptStatus(
            attempt
          )}-adoption&attemptId=${attempt.id}`
        )
      }}
      avatar={
        <Avatar alt={`Photo de l'animal`} src={getAnimalProfilePhotoSrc(`${attempt.animalId}.jpg`)} sx={avatarSx} />
      }
      label={getAdoptionAttemptStatus(attempt)}
      variant="outlined"
      sx={textSx}
    />
  )
}

export function getTabForAdoptionAttemptStatus(attempt: AdoptionAttemptDto): 'ongoing' | 'past' | 'suspended' {
  if (attempt.status === AdoptionAttemptDtoStatus.Done && attempt.step > 5) {
    return 'past'
  }
  if (
    attempt.status === AdoptionAttemptDtoStatus.SuspendedByCustomer ||
    attempt.status === AdoptionAttemptDtoStatus.SuspendedByUser
  ) {
    return 'suspended'
  }
  return 'ongoing'
}
