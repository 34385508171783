import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import { StepsProps } from './StepOne'
import { blue } from '../../theme'

export const StepTwo: React.FC<StepsProps> = ({ register }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
      <Box>
        <Typography variant="body2" sx={{ color: blue, fontSize: 20, fontWeight: 700 }}>
          Pour remplacer le formulaire, vous pouvez ajouter un commentaire avec toutes les informations que vous avez
          sur l&#39;adoptant
        </Typography>
        <TextField multiline rows={4} fullWidth id="customer-comment-input" {...register('customerComment')} />
      </Box>
    </Box>
  )
}
