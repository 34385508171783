import { Box, Button, Collapse, Grid, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { spacingItem, theme } from '../../../theme'
import {
  getColorForAnimalAdoptionStatus,
  getColorForCannotBeAdoptedStatus,
  getColorForHostedFamilyFollowUpType,
  getReadableAnimalAdoptionStatus,
  getReadableCannotBeAdoptedStatus,
  getReadableHostedFamilyFollowUpType,
} from '../../../../domain/Animal/AnimalDisplay'
import { DisplayChipLikeTextField } from '../../../common/DisplayChipLikeTextField'
import { MainHostedFamilyFollowUp } from './HostedFamillyFollowUpsTab'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import { HostedFamilyFollowUpDtoType } from '../../../../domain/Animal/HostedFamilyFollowUpDtoType'
import { EditHostedFamilyFollowUpModal } from './EditHostedFamilyFollowUpModal'
import {
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoStatus,
  AnimalDto,
  ShiftHostedFamilyFollowUpDto,
} from '../../../../interactors/gen/backendClient'
import { animalButtonShouldBeDisabled } from '../../../../utils/animalButtonShouldBeDisabled'
import { DeleteFollowUpModal } from './DeleteFollowUpModal'
import dayjs from 'dayjs'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'

interface PropsItem {
  followUp: MainHostedFamilyFollowUp
  allFollowUps: Array<MainHostedFamilyFollowUp>
  setAllFollowUps: (allFollowUps: Array<MainHostedFamilyFollowUp>) => void
  animal: AnimalDto
  mostRecent: boolean
}

export const HostedFamilyFollowUp: FC<PropsItem> = ({
  followUp,
  allFollowUps,
  setAllFollowUps,
  animal,
  mostRecent,
}) => {
  const accountsStore = useAccountsStore()
  const canEditAnimal = useCanEditAnimal()
  const [isExpanded, setExpanded] = useState(false)

  const handleClickOnExpand = () => {
    setExpanded(!isExpanded)
  }
  const handleClickOnDelete = () => {
    setAllFollowUps(allFollowUps.filter((aFollowUp) => followUp !== aFollowUp))
  }

  const sxField = { ...spacingItem, cursor: 'disabled', input: { cursor: 'not-allowed' } }

  const getAccountLabelFromId = (id: string): string => {
    if (!id) {
      return ''
    }

    const account = accountsStore.withRemovedMembers.find((account) => account.id === id)
    if (!account) {
      return `${id} (Introuvable)`
    }
    let label = account ? fullName(account) : ''

    if (account.id === accountsStore.connectedAccount?.id) {
      label = `Moi (${label})`
    }

    if (account.isDeleted) {
      label = `Compte supprimé (${label})`
    }

    return label
  }

  const sortedFollowUpsWithDestination = (allFollowUps as Array<ShiftHostedFamilyFollowUpDto>)
    .filter((AfollowUp) => AfollowUp.destination != null)
    .sort((a, b) => {
      const dateA = dayjs(a.date, 'DD/MM/YYYY')
      const dateB = dayjs(b.date, 'DD/MM/YYYY')

      return dateA.isBefore(dateB) ? 1 : dateA.isAfter(dateB) ? -1 : 0
    })

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={3} xs={12}>
          <DisplayChipLikeTextField
            label="Type de déplacement"
            value={getReadableHostedFamilyFollowUpType(followUp.type)}
            color={getColorForHostedFamilyFollowUpType(followUp.type)}
          />
        </Grid>

        <Grid item sm={1.5} xs={12}>
          <TextField
            label="Date"
            type="text"
            fullWidth
            value={followUp.date}
            sx={sxField}
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>

        {(followUp.type === HostedFamilyFollowUpDtoType.InitialPlacement ||
          followUp.type === HostedFamilyFollowUpDtoType.Shift ||
          followUp.type === HostedFamilyFollowUpDtoType.Exit) && (
          <>
            <Grid item sm={2.75} xs={12}>
              <TextField
                label="Accompagnateur"
                type="text"
                fullWidth
                value={getAccountLabelFromId(followUp.memberInChargeAccountId)}
                sx={sxField}
                multiline
                size="small"
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item sm={2.75} xs={12}>
              {followUp.type === HostedFamilyFollowUpDtoType.Exit ? (
                Object.values(AnimalAdoptionDtoCannotBeAdoptedStatus as Record<string, string>).includes(
                  followUp.destination
                ) ? (
                  <DisplayChipLikeTextField
                    label="Destination"
                    value={getReadableCannotBeAdoptedStatus(
                      followUp.destination as AnimalAdoptionDtoCannotBeAdoptedStatus
                    )}
                    color={getColorForCannotBeAdoptedStatus(
                      followUp.destination as AnimalAdoptionDtoCannotBeAdoptedStatus
                    )}
                  />
                ) : (
                  <DisplayChipLikeTextField
                    label="Destination"
                    value={getReadableAnimalAdoptionStatus(followUp.destination as AnimalAdoptionDtoStatus)}
                    color={getColorForAnimalAdoptionStatus(followUp.destination as AnimalAdoptionDtoStatus)}
                  />
                )
              ) : (
                <TextField
                  label="Destination"
                  type="text"
                  fullWidth
                  value={
                    followUp.destination
                      ? followUp.destination.startsWith('A-')
                        ? getAccountLabelFromId(followUp.destination)
                        : followUp.destination
                      : ''
                  }
                  sx={sxField}
                  multiline
                  size="small"
                  inputProps={{ readOnly: true }}
                />
              )}
            </Grid>
          </>
        )}

        {followUp.type === HostedFamilyFollowUpDtoType.ChangeOfHostedFamily && (
          <>
            <Grid item sm={2.75} xs={12}>
              <TextField
                label="Ancienne famille d'accueil"
                type="text"
                fullWidth
                value={getAccountLabelFromId(followUp.beforeAccountId)}
                sx={sxField}
                multiline
                size="small"
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item sm={2.75} xs={12}>
              <TextField
                label="Nouvelle famille d'accueil"
                type="text"
                fullWidth
                value={getAccountLabelFromId(followUp.afterAccountId)}
                sx={sxField}
                multiline
                size="small"
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </>
        )}

        <Grid item sm={2} xs={12}>
          {canEditAnimal && (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%', pt: 2 }}>
              <Button onClick={handleClickOnExpand}>{isExpanded ? <ExpandLess /> : <ExpandMore />}</Button>
              <EditHostedFamilyFollowUpModal
                followUp={followUp}
                allFollowUps={allFollowUps}
                setAllFollowUps={setAllFollowUps}
                animal={animal}
                disabled={
                  animalButtonShouldBeDisabled(animal) || !mostRecent || !accountsStore.isPremium() || !canEditAnimal
                }
              />
              <DeleteFollowUpModal
                handleClickOnDelete={handleClickOnDelete}
                disabled={
                  animalButtonShouldBeDisabled(animal) || !mostRecent || !accountsStore.isPremium() || !canEditAnimal
                }
                animalName={animal.name}
                lastLocation={sortedFollowUpsWithDestination[1]?.destination || 'Inconnue'}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Collapse in={isExpanded}>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <TextField
              label="Details"
              type="text"
              fullWidth
              value={followUp.description}
              sx={sxField}
              multiline
              size="small"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'row', sm: 'column' }}
              justifyContent={{ xs: 'space-between', sm: 'center' }}
              alignItems="left"
              padding={1}
              paddingTop={1.8}
              gap={0.5}
              sx={{ color: theme.palette.grey[500] }}
            >
              {followUp.created && (
                <Typography variant="body2" fontSize={14} sx={{ p: 0, m: 0 }}>
                  Créé le {new Date(followUp.created.at).toLocaleDateString()} par{' '}
                  {getAccountLabelFromId(followUp.created.by)}
                </Typography>
              )}
              {followUp.modified && (
                <Typography variant="body2" fontSize={14} sx={{ p: 0, m: 0 }}>
                  Modifié le {new Date(followUp.created.at).toLocaleDateString()} par{' '}
                  {getAccountLabelFromId(followUp.created.by)}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', marginY: 2 }}>
        <hr />
      </Box>
    </>
  )
}
