import { InfoOutlined, Search, Tune, Visibility } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Drawer,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  autocompleteClasses,
} from '@mui/material'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { fullName } from '../../domain/Account/AccountDisplay'
import { AccountDtoRoles } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { useFetchOrRefreshAdoptersOnMount } from '../../store/useFetchOrRefreshAdoptersOnMount'
import { useFetchOrRefreshMembersOnMount } from '../../store/useFetchOrRefreshMembersOnMount'
import { AQuestion } from '../common/AQuestion'
import { FilterList } from '../common/FilterList'
import { FilterListItem } from '../common/FilterListItem'
import { spacingItem, theme } from '../theme'
import { MapCoordinates, MapOfFrance } from './MapOfFrance'

export const MapScreen: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [memberMarkers, setMemberMarkers] = useState<MapCoordinates[]>([])
  const [hostFamilyMarkers, setHostFamilyMarkers] = useState<MapCoordinates[]>([])
  const [veterinaryMarkers, setVeterinaryMarkers] = useState<MapCoordinates[]>([])
  const [adopterMarkers, setAdopterMarkers] = useState<MapCoordinates[]>([])

  const [markAdopters, setMarkAdopters] = useState<boolean>(true)
  const [markMembers, setMarkMembers] = useState<boolean>(true)
  const [markHostFamily, setMarkHostFamily] = useState<boolean>(true)
  const [markVeterinary, setMarkVeterinary] = useState<boolean>(true)

  const [selectedMarker, setSelectedMarker] = useState<MapCoordinates | null>(
    queryParams.get('selectedMarker') != null ? JSON.parse(queryParams.get('selectedMarker')!) : null
  )

  useFetchOrRefreshAdoptersOnMount()
  useFetchOrRefreshMembersOnMount()

  const accountsStore = useAccountsStore()
  const adoptersStore = useAdoptersStore()

  const positionToExclude = [46.227638, 2.213749] // France's coordinates

  useEffect(() => {
    // Create new arrays for each marker type
    const newMemberMarkers = accountsStore.members
      .filter(
        (member) =>
          member.roles.includes(AccountDtoRoles.Member) &&
          member.coordinates &&
          member.isDeleted !== true &&
          member.coordinates.lat !== positionToExclude[0] &&
          member.coordinates.lng !== positionToExclude[1]
      )
      .map((account) => ({
        position: [account.coordinates?.lat, account.coordinates?.lng],
        title: `${fullName(account)} - Membre`,
        id: account.id,
        role: AccountDtoRoles.Member,
        address: account.address,
        postalCode: account.postalCode,
        city: account.city,
        country: account.country,
      }))

    const newHostFamilyMarkers = accountsStore.members
      .filter(
        (member) =>
          member.roles.includes(AccountDtoRoles.HostFamily) &&
          member.coordinates &&
          member.isDeleted !== true &&
          member.coordinates.lat !== positionToExclude[0] &&
          member.coordinates.lng !== positionToExclude[1]
      )
      .map((account) => ({
        position: [account.coordinates?.lat, account.coordinates?.lng],
        title: `${fullName(account)} - Famille d'accueil`,
        id: account.id,
        role: AccountDtoRoles.HostFamily,
        address: account.address,
        postalCode: account.postalCode,
        city: account.city,
        country: account.country,
      }))

    const newVeterinaryMarkers = accountsStore.members
      .filter(
        (member) =>
          member.roles.includes(AccountDtoRoles.Veterinary) &&
          member.coordinates &&
          member.isDeleted !== true &&
          member.coordinates.lat !== positionToExclude[0] &&
          member.coordinates.lng !== positionToExclude[1]
      )
      .map((account) => ({
        position: [account.coordinates?.lat, account.coordinates?.lng],
        title: `${fullName(account)} - Vétérinaire`,
        id: account.id,
        role: AccountDtoRoles.Veterinary,
        address: account.address,
        postalCode: account.postalCode,
        city: account.city,
        country: account.country,
      }))

    const newAdopterMarkers = adoptersStore.adopters
      .filter(
        (adopter) =>
          accountsStore.isAdmin() &&
          adopter.coordinates &&
          adopter.coordinates.lat !== positionToExclude[0] &&
          adopter.coordinates.lng !== positionToExclude[1]
      )
      .map((adopter) => ({
        position: [adopter.coordinates?.lat, adopter.coordinates?.lng],
        title: `${fullName(adopter)} - Adoptant`,
        id: adopter.id,
        address: adopter.address,
        postalCode: adopter.postalCode,
        city: adopter.city,
        country: adopter.country,
      }))

    // Set the new markers state
    setMemberMarkers(newMemberMarkers as MapCoordinates[])
    setHostFamilyMarkers(newHostFamilyMarkers as MapCoordinates[])
    setVeterinaryMarkers(newVeterinaryMarkers as MapCoordinates[])
    setAdopterMarkers(newAdopterMarkers as MapCoordinates[])
  }, [markAdopters, markMembers, markHostFamily, markVeterinary, accountsStore.members, adoptersStore.adopters])

  const handleSelectMarker = (_event: SyntheticEvent<Element, Event>, value: MapCoordinates | null) => {
    setQueryParams({ selectedMarker: value ? JSON.stringify(value) : null })
    setSelectedMarker(value)
  }

  const setQueryParams = (params: { [key: string]: string | null }) => {
    const newParams = new URLSearchParams(window.location.search)
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value)
      } else {
        newParams.delete(key)
      }
    })
    window.history.replaceState({}, '', `${window.location.pathname}?${newParams.toString()}`)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const FiltersBar = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'space-between', height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6">
              {`Total:
              ${
                [
                  ...(markMembers ? memberMarkers : []),
                  ...(markHostFamily ? hostFamilyMarkers : []),
                  ...(markVeterinary ? veterinaryMarkers : []),
                  ...(markAdopters ? adopterMarkers : []),
                ].length
              }/${
                [
                  ...accountsStore.members.filter((member) => member.isDeleted !== true),
                  ...(accountsStore.isAdmin() ? adoptersStore.adopters : []),
                ].length
              }`}
            </Typography>
            <Tooltip
              title={`Cette carte tient compte des préférences des utilisateurs et ne fait apparaitre que ceux qui le souhaitent (onglet "visibilité"). De plus ceux ayant une adresse incomplète n'apparaîtront pas non plus.`}
            >
              <InfoOutlined sx={{ color: theme.palette.primary.main }} />
            </Tooltip>
          </Box>
          <FilterList label="Afficher" icon={<Visibility />}>
            <FilterListItem
              label="Membres"
              isSelected={markMembers}
              toggleSelected={() => setMarkMembers(!markMembers)}
              color="#1E90FF"
              icon={Visibility}
            />
            <FilterListItem
              label="Familles d'accueil"
              isSelected={markHostFamily}
              toggleSelected={() => setMarkHostFamily(!markHostFamily)}
              color="#82CFFF"
              icon={Visibility}
            />
            <FilterListItem
              label="Vétérinaires"
              isSelected={markVeterinary}
              toggleSelected={() => setMarkVeterinary(!markVeterinary)}
              color="#F7C8DF"
              icon={Visibility}
            />
            {accountsStore.isAdmin() && (
              <FilterListItem
                label="Adoptants"
                isSelected={markAdopters}
                toggleSelected={() => setMarkAdopters(!markAdopters)}
                color="#FCB402"
                icon={Visibility}
              />
            )}
          </FilterList>
        </Box>
        <AQuestion />
      </Box>
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        padding: 1,
        paddingBottom: 7.5,
        backgroundColor: 'grey.100',
        minHeight: `calc(100% - 20px)`,
        boxSizing: 'border-box',
        marginBottom: 2,
        maxHeight: `calc(100% - 20px)`,
      }}
    >
      <>
        {/* Filters for desktop version */}
        <Card
          sx={{
            ...spacingItem,
            width: 230,
            display: { xs: 'none', sm: 'block' },
            overflowY: 'auto',
          }}
        >
          <CardContent sx={{ height: '100%' }}>
            <FiltersBar />
          </CardContent>
        </Card>

        {/* Filters for mobile version */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          <Box sx={{ padding: 2 }}>
            <FiltersBar />
          </Box>
        </Drawer>

        <Card
          sx={{
            ...spacingItem,
            marginLeft: { xs: 0, sm: 2 },
            width: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
            position: 'relative',
          }}
        >
          <Box display="flex" flexDirection="row" flexWrap="nowrap">
            <Autocomplete
              id="search-autocomplete"
              defaultValue={selectedMarker}
              options={[...memberMarkers, ...hostFamilyMarkers, ...veterinaryMarkers, ...adopterMarkers]
                .filter(Boolean)
                .sort((a, b) => (a?.title || '').localeCompare(b?.title || ''))}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option) => (
                <li {...props} key={`${option.id} - ${option.title}`}>
                  {option.title}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} placeholder="Rechercher un marqueur" />}
              popupIcon={<Search />}
              onChange={handleSelectMarker}
              sx={{
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  transform: 'none',
                },
              }}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' }, mx: 'auto' }}
            >
              <Tune />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: '100%',
              maxHeight: '100%',
              height: '100%',
              boxSizing: 'border-box',
              overflow: 'auto',
              paddingBottom: 7, // HACK: To avoid the last row to be hidden
            }}
          >
            <MapOfFrance
              memberMarkers={markMembers ? memberMarkers : []}
              hostFamilyMarkers={markHostFamily ? hostFamilyMarkers : []}
              veterinaryMarkers={markVeterinary ? veterinaryMarkers : []}
              adopterMarkers={markAdopters ? adopterMarkers : []}
              selectedMarker={selectedMarker}
            />
          </Box>
        </Card>
      </>
    </Box>
  )
}
