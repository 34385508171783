import { Button, Card, CardActions, CardContent, CardHeader, SxProps, Theme, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { getReadableAnimalFieldLabel } from '../../../../domain/Animal/AnimalDisplay'

import { AnimalDto } from '../../../../interactors/gen/backendClient'

import { blueSky, spacingItem } from '../../../theme'
import { EditorPrompt } from '../EditorPrompt'
import ReactQuill from 'react-quill'
import { TrimManager } from '../../../../utils/TrimManager'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { useMutation } from 'react-query'
import { AnimalsClient } from '../../../../interactors/clients/AnimalsClient'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { useAnimalStore } from '../../../../store/AnimalStore'
import { LoadingButton } from '@mui/lab'
import { CopyToClipboardButton } from '../../../common/CopyToClipboardButton'
import dayjs from 'dayjs'
import { ControlledMultipleFreeSoloAutoCompleteWithCategories } from '../../../common/ControlledMultipleFreeSoloAutocompleteWithCategories'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { AnimalCardSaveButton } from '../AnimalCardSaveButton'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { ControlledChipInput } from '../../../common/ControlledChipInput'

interface Props {
  animal: AnimalDto
  sx?: SxProps<Theme>
}

type FormParams = {
  characteristics: Array<string>
  identifyingMarks: Array<string>
}

export const AnimalAboutCards: FC<Props> = ({ animal }) => {
  const editAnimalMutation = useEditAnimalMutation({})
  const globalSnackBarStore = useGlobalSnackbarStore()
  const animalStore = useAnimalStore()
  const canEditAnimal = useCanEditAnimal()

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    characteristics: animal?.characteristics || [],
    identifyingMarks: animal?.identifyingMarks || [],
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useFormExtended(animal, getDefaultValues)

  const onSubmit = async (data: FormParams) => {
    await editAnimalMutation.mutate({
      ...animal,
      characteristics: data.characteristics,
      identifyingMarks: data.identifyingMarks,
    })
  }

  const onEditDescription = async (newDescription: string) => {
    await editAnimalMutation.mutate({ ...animal, description: newDescription })
  }

  const onEditLifeBeforeAssociation = async (newLifeBeforeAssociation: string) => {
    await editAnimalMutation.mutate({ ...animal, lifeBeforeAssociation: newLifeBeforeAssociation })
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const autoGeneratedAnnounce = animal.announce?.autoGeneratedAnnounce

  const generateAnnounceMutation = useMutation(
    async () => {
      const startOfTheMutation = new Date()
      setIsLoading(true)

      await AnimalsClient.generateAnnounce(animal.id)

      let index = 0
      const intervalId = setInterval(() => {
        if (index === 3) {
          globalSnackBarStore.triggerSuccessMessage(`Ceci peut prendre un peu de temps. Merci de patienter.`)
        }

        if (index === 10) {
          globalSnackBarStore.triggerSuccessMessage(`La patience est une vertu.`)
        }

        if (index === 18) {
          globalSnackBarStore.triggerSuccessMessage(`Encore un tout petit peu de patience.`)
        }

        AnimalsClient.getAnimal(animal.id).then((animalUpdated) => {
          index++

          console.log(animalUpdated?.announce?.autoGeneratedAt)
          console.log(dayjs(animalUpdated?.announce?.autoGeneratedAt).isAfter(startOfTheMutation))

          if (
            animalUpdated?.announce?.autoGeneratedAt &&
            dayjs(animalUpdated?.announce?.autoGeneratedAt).isAfter(startOfTheMutation)
          ) {
            setIsLoading(false)
            animalStore.refreshSelectedAnimal()

            globalSnackBarStore.triggerSuccessMessage(`L'annonce a été générée!`)

            clearInterval(intervalId) // Stop the interval
          } else if (index === 200) {
            // Stop the interval if the maximum attempts are reached
            clearInterval(intervalId)
            setIsLoading(false)
            globalSnackBarStore.triggerErrorMessage(`Maximum attempts reached.`)
          }
        })
      }, 1000)
    },
    {
      onError: () => {
        globalSnackBarStore.triggerErrorMessage(`Erreur.`)
      },
    }
  )

  const options = [
    {
      title: 'Habitat et mode de vie',
      options: [
        'Convient à la vie en appartement',
        'Ne convient pas à la vie en appartement',
        'Convient à la vie en maison',
        'Ne convient pas à la vie en maison',
        'Hypoallergénique',
      ],
    },
    {
      title: 'Vie sociale',
      options: [
        'Entente avec les enfants',
        'Ne s’entend pas avec les enfants',
        'Entente avec les chiens',
        'Ne s’entend pas avec les chiens',
        'Entente avec les chats',
        'Ne s’entend pas avec les chats',
        'Entente avec les autres animaux',
        'Ne s’entend pas avec les autres animaux',
      ],
    },
    {
      title: 'Traits de caractère',
      options: ['Joueur', 'Calin', 'Craintif', 'Peureux', 'Agressif', 'Timide', 'Bruyant', 'Propre'],
    },
  ]

  return (
    <>
      <Card>
        <CardHeader
          title={getReadableAnimalFieldLabel('lifeBeforeAssociation')}
          action={
            canEditAnimal && (
              <EditorPrompt
                initialValue={animal.lifeBeforeAssociation || ''}
                onSubmit={onEditLifeBeforeAssociation}
                title={getReadableAnimalFieldLabel('lifeBeforeAssociation')}
              />
            )
          }
        />
        <CardContent sx={{ pt: 0 }}>
          <ReactQuill value={TrimManager.trimIfNeeded(animal.lifeBeforeAssociation)} readOnly={true} theme={'bubble'} />
          {TrimManager.needTrim(animal.lifeBeforeAssociation) && (
            <EditorPrompt
              title={getReadableAnimalFieldLabel('lifeBeforeAssociation')}
              initialValue={animal.lifeBeforeAssociation || ''}
              onSubmit={onEditDescription}
              renderButton={(onClick: () => void): JSX.Element => <Button onClick={onClick}>Voir Plus</Button>}
            />
          )}
        </CardContent>
      </Card>

      <Card sx={spacingItem}>
        <CardHeader
          title={`Présentation de ${animal.name}`}
          action={
            canEditAnimal && (
              <EditorPrompt
                title={`Présentation de ${animal.name}`}
                initialValue={animal.description || ''}
                onSubmit={onEditDescription}
              />
            )
          }
          sx={{ pb: 0 }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent sx={{ pt: 0 }}>
            <Typography variant="h5" fontStyle="italic" color={blueSky} fontSize={13}>
              Si adoptable, cette présentation apparaitra dans l’annonce de {animal.name} sur Petso.fr
            </Typography>
            <ReactQuill value={TrimManager.trimIfNeeded(animal.description)} readOnly={true} theme={'bubble'} />
            {TrimManager.needTrim(animal.description) && (
              <EditorPrompt
                title={getReadableAnimalFieldLabel('description')}
                initialValue={animal.description || ''}
                onSubmit={onEditDescription}
                renderButton={(onClick: () => void): JSX.Element => <Button onClick={onClick}>Voir Plus</Button>}
              />
            )}
            <ControlledMultipleFreeSoloAutoCompleteWithCategories
              options={options}
              freeSolo={true}
              control={control}
              error={undefined}
              fieldName="characteristics"
              label="Caractéristiques"
              requiredRule=""
              sx={spacingItem}
            />
            <ControlledChipInput
              control={control}
              error={undefined}
              fieldName="identifyingMarks"
              label="Signes distinctifs"
              requiredRule=""
              helperText={`Entrez un signe distinctif puis appuyez sur "entrée" pour l'ajouter à la liste`}
              sx={spacingItem}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <AnimalCardSaveButton isLoading={editAnimalMutation.isLoading} disabled={!isDirty} />
          </CardActions>
        </form>
      </Card>

      <Card sx={spacingItem}>
        <CardHeader
          title="Annonce pour adoption"
          action={
            canEditAnimal && (
              <>
                {autoGeneratedAnnounce && <CopyToClipboardButton text={autoGeneratedAnnounce} />}
                <LoadingButton
                  variant="contained"
                  onClick={() => generateAnnounceMutation.mutate()}
                  loading={isLoading}
                >
                  {autoGeneratedAnnounce ? 'Générer à nouveau' : 'Générer'}
                </LoadingButton>
              </>
            )
          }
        />
        {autoGeneratedAnnounce && (
          <CardContent sx={{ paddingTop: 0 }}>
            <Typography variant="h5" fontStyle="italic" color={blueSky} fontSize={15}>
              Nous vous avons généré une annonce pour vous, utilisez la comme base pour créer votre annonce.
            </Typography>

            <ReactQuill value={autoGeneratedAnnounce} readOnly={true} theme={'bubble'} />
          </CardContent>
        )}
      </Card>
    </>
  )
}
