import React from 'react'
import { Modal, Box, Typography, Button, useMediaQuery } from '@mui/material'
import useInactivityPopup from '../../utils/hooks/useInactivityPopup' // Adjust the import path accordingly
import { theme } from '../theme'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const InactivityPopup: React.FC = () => {
  const { open, handleStay, handleLeave } = useInactivityPopup()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Modal open={open} aria-labelledby="inactivity-popup-title" aria-describedby="inactivity-popup-description">
      <Box sx={{ ...style, p: isMobile ? 2 : 4 }} width={isMobile ? '100%' : 'auto'}>
        <Typography id="inactivity-popup-title" variant="h6" component="h2">
          Vous êtes toujours là ?
        </Typography>
        <Typography id="inactivity-popup-description" sx={{ mt: 2 }}>
          Vous avez été inactif pendant un certain temps.
        </Typography>
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            gap: 2,
          }}
        >
          <Button variant="contained" color="primary" sx={{ textTransform: 'none' }} onClick={handleStay}>
            Rester sur la page
          </Button>
          <Button variant="contained" color="secondary" sx={{ textTransform: 'none' }} onClick={handleLeave}>
            Revenir à la liste des animaux
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default InactivityPopup
