import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material'
import { FC, useEffect } from 'react'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import { getColorForContactFollowUpType, getReadableContactFollowUpType } from '../../../../domain/Animal/AnimalDisplay'
import {
  AccountDtoRoles,
  AnimalDto,
  ContactHostedFamilyFollowUpDto,
  ContactHostedFamilyFollowUpDtoType,
} from '../../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { toDateOnly } from '../../../../utils/date/DateOnly'
import { isValidPastDateCoherence } from '../../../../utils/date/isValidPastDateCoherence'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { ColoredChipSelectField } from '../../../common/ColoredChipSelectField'
import { ControlledAutoCompleteWithCustomValue } from '../../../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateField } from '../../../common/ControlledDateField'
import { spacingItem } from '../../../theme'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../PATHS'
import dayjs from 'dayjs'
import { animalButtonShouldBeDisabled } from '../../../../utils/animalButtonShouldBeDisabled'

type FormParams = {
  type: ContactHostedFamilyFollowUpDtoType
  date: string
  hostFamilyAccountId: string
  interlocutorAccountId: string
  description?: string
}

interface Props {
  followUp: ContactHostedFamilyFollowUpDto
  contactFollowUps: Array<ContactHostedFamilyFollowUpDto>
  setContactFollowUps: (contactFollowUps: Array<ContactHostedFamilyFollowUpDto>) => void
  animal: AnimalDto
  disabled?: boolean
}

export const EditContactFollowUpModal: FC<Props> = ({
  followUp,
  contactFollowUps,
  setContactFollowUps,
  animal,
  disabled = false,
}) => {
  const queryParams = new URLSearchParams(location.search)
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const editAnimalMutation = useEditAnimalMutation({})

  const allHostFamilies = accountsStore.members.filter((account) => account.roles.includes(AccountDtoRoles.HostFamily))

  const allMembers = accountsStore.members.filter((account) => !account.roles.includes(AccountDtoRoles.Veterinary))

  const getDefaultValues = (followUp: ContactHostedFamilyFollowUpDto): FormParams => ({
    type: followUp.type || '',
    date: followUp.date || '',
    description: followUp.description || '',
    hostFamilyAccountId: queryParams.get('hostFamilyInCharge') || followUp.hostFamilyAccountId || '',
    interlocutorAccountId: queryParams.get('memberInCharge') || followUp.interlocutorAccountId || '',
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useFormExtended(followUp, getDefaultValues)

  const onSubmit = (data: FormParams) => {
    const newFollowUp = {
      ...data,
      date: toDateOnly(data.date),
      created: followUp.created,
      modified: {
        by: accountsStore.connectedAccount?.id || '',
        at: dayjs().toISOString(),
      },
    }
    setContactFollowUps(
      contactFollowUps.map((AFollowUp) =>
        AFollowUp === followUp ? (newFollowUp as ContactHostedFamilyFollowUpDto) : AFollowUp
      )
    )
    closeDialog()
    reset()
  }

  useEffect(() => {
    queryParams.get('card') === JSON.stringify(followUp) && openDialog()
  }, [queryParams.get('card')])

  const hostFamilyAccountId = watch('hostFamilyAccountId')
  useEffect(() => {
    if (hostFamilyAccountId === 'addAccount') {
      reset({ hostFamilyAccountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=familles&role=${
          AccountDtoRoles.HostFamily
        }&card=${JSON.stringify(followUp)}`
      )
    }
  }, [hostFamilyAccountId])

  const interlocutorAccountId = watch('interlocutorAccountId')
  useEffect(() => {
    if (interlocutorAccountId === 'addAccount') {
      reset({ interlocutorAccountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=familles&role=${
          AccountDtoRoles.Member
        }&card=${JSON.stringify(followUp)}`
      )
    }
  }, [interlocutorAccountId])

  return (
    <>
      <IconButton color="primary" onClick={openDialog} disabled={animalButtonShouldBeDisabled(animal) || disabled}>
        <Tooltip title="Editer le suivi">
          <Edit />
        </Tooltip>
      </IconButton>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        >
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Modifier un suivi
              </Typography>
              <ColoredChipSelectField
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={errors.type}
                fieldName="type"
                size="small"
                label="Type de suivi"
                options={Object.values(ContactHostedFamilyFollowUpDtoType).map(
                  (status: ContactHostedFamilyFollowUpDtoType) => ({
                    label: getReadableContactFollowUpType(status),
                    value: status,
                    color: getColorForContactFollowUpType(status),
                  })
                )}
                requiredRule={'Le type de suivi est requis'}
              />

              <ControlledDateField
                control={control}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                error={errors.date}
                fieldName={'date'}
                label="Date"
                requiredRule={'La date est requise'}
                size="small"
                sx={spacingItem}
              />

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'hostFamilyAccountId'}
                size="small"
                options={[
                  { value: 'addAccount', label: "Ajouter une famille d'accueil" },
                  ...allHostFamilies
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Famille d'accueil"
                error={errors.hostFamilyAccountId}
                requiredRule={"La famille d'accueil est requise"}
                defaultValue={''}
                sx={spacingItem}
                highlight
              />

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'interlocutorAccountId'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un membre' },
                  ...allMembers
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Interlocuteur"
                error={errors.interlocutorAccountId}
                requiredRule={"L'interlocuteur est requis"}
                defaultValue={''}
                sx={spacingItem}
                highlight
              />

              <TextField
                id="description-input"
                label="Détails"
                type="text"
                fullWidth
                {...register('description')}
                sx={spacingItem}
                size="small"
                multiline
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              loading={editAnimalMutation.isLoading}
            >
              Modifier
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
