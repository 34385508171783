import { UploadFile } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { setOpacity } from '../../../utils/setOpacity'
import { blue, colors, spacingItem } from '../../theme'
import { StepsProps } from './StepOne'

export interface StepPropsWithFile extends StepsProps {
  setFile: React.Dispatch<React.SetStateAction<File | null>>
}

export const StepFive: React.FC<StepPropsWithFile> = ({ setFile }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0])
    },
    [setFile]
  )
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
  })
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="body2" sx={{ color: blue, fontSize: 20, fontWeight: 700 }}>
        Si vous le souhaitez, vous pouvez ajouter le certificat remis à l&#39;adoptant ici (optionnel)
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={3}
        {...getRootProps({
          sx: {
            ...spacingItem,
            border: 3,
            borderRadius: 5,
            borderStyle: 'dashed',
            borderColor: isDragActive ? 'green' : blue,
            backgroundColor: isDragActive
              ? 'lightgreen'
              : acceptedFiles[0]
              ? setOpacity(colors.geyser, 0.4)
              : 'transparent',
            transition: 'all 0.3s',
          },
        })}
      >
        <input {...getInputProps()} />
        <UploadFile sx={{ color: blue, fontSize: 64, mb: 1 }} />
        <Typography variant="h4" component="p" fontSize={20} textAlign="center">
          Glissez-déposer ou
          <br />
          Insérer un document
        </Typography>
        <Box
          marginTop={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            maxWidth: '100%',
          }}
        >
          <Typography variant="h4" component="p" fontSize={18} textAlign="center" sx={{ whiteSpace: 'nowrap' }}>
            Votre document:
          </Typography>
          <Typography
            variant="h4"
            component="p"
            fontSize={18}
            textAlign="center"
            color={colors.black}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flexGrow: 1,
            }}
          >
            {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
          </Typography>
        </Box>
        <Button variant="contained" sx={{ width: '40%', mt: 1 }}>
          Parcourir
        </Button>
      </Box>
    </Box>
  )
}
