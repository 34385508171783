import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, Box, Typography, Button, DialogActions, Grid, Tooltip } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { ControlledDateField } from '../common/ControlledDateField'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import { blue, spacingItem } from '../theme'
import { ControlledAutoCompleteWithCustomValue } from '../common/ControlledAutocompleteWithCustomValue'
import { fullName } from '../../domain/Account/AccountDisplay'
import { useMutation } from 'react-query'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { useAccountsStore } from '../../store/AccountsStore'
import dayjs from 'dayjs'
import { AccountDtoPermission } from '../../interactors/gen/backendClient'

type FormValues = {
  type: string
  dateToCheck: string
  startDate: string
  endDate: string
  adopterId: string
}

export const AnimalExportPopUp: React.FC = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read

  const exportPdfMutation = useMutation(
    (variables: {
      accountId: string | undefined
      dateToCheck: string | undefined
      startDate: string | undefined
      endDate: string | undefined
    }) =>
      AnimalsClient.downloadAnimalsPdf(
        variables.accountId,
        variables.dateToCheck,
        variables.startDate,
        variables.endDate
      ),
    {
      onSuccess: async () => {
        globalSnackbarStore.triggerSuccessMessage(`PDF téléchargé !`)
      },
      onError: async (error) => {
        console.error(error)
        globalSnackbarStore.triggerErrorMessage(`Une erreur est survenue !`)
      },
    }
  )

  const exportExcelMutation = useMutation(AnimalsClient.downloadAnimalsExcel, {
    onSuccess: async () => {
      globalSnackbarStore.triggerSuccessMessage(`Excel téléchargé !`)
    },
    onError: async (error) => {
      console.error(error)
      globalSnackbarStore.triggerErrorMessage(`Une erreur est survenue !`)
    },
  })

  const exportICADExcelMutation = useMutation(AnimalsClient.downloadAnimalsICADExcel, {
    onSuccess: async () => {
      globalSnackbarStore.triggerSuccessMessage(`Excel téléchargé !`)
    },
    onError: async (error) => {
      console.error(error)
      globalSnackbarStore.triggerErrorMessage(`Une erreur est survenue !`)
    },
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      type: '',
      dateToCheck: 'noFilter',
      startDate: '',
      endDate: dayjs().toISOString(),
      adopterId: 'all',
    },
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (data.type === 'pdf') {
      const accountId = data.adopterId === 'all' ? undefined : data.adopterId
      const dateToCheck = data.dateToCheck === 'noFilter' ? undefined : data.dateToCheck
      const startDate = data.dateToCheck === 'noFilter' ? undefined : data.startDate
      const endDate = data.dateToCheck === 'noFilter' ? undefined : data.endDate
      return exportPdfMutation.mutate({ accountId, dateToCheck, startDate, endDate })
    }
    if (data.type === 'excel') {
      return exportExcelMutation.mutate()
    }
    if (data.type === 'icad') {
      return exportICADExcelMutation.mutate()
    }
  }

  const allMembers = accountsStore.members
    ? accountsStore.members.map((member) => {
        const label = fullName(member)

        return { value: member.id, label, key: member.id }
      })
    : []

  const type = watch('type')
  const dateToCheck = watch('dateToCheck')

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={openDialog}
        sx={{ marginTop: 4, paddingX: 3 }}
        disabled={isReader}
      >
        Exporter
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={2}>
                Exporter un document
              </Typography>

              <ControlledSelectField
                control={control}
                error={errors.type}
                fieldName="type"
                label="Type de fichier"
                options={[
                  { label: 'Registre Entrées - Sorties (pdf)', value: 'pdf' },
                  { label: 'Fiche Animal (Excel)', value: 'excel' },
                  { label: 'Cessions I-CAD à réaliser (Excel)', value: 'icad' },
                ]}
                size="small"
                requiredRule={'true'}
              />

              {type === 'pdf' && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, ...spacingItem }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <ControlledSelectField
                          control={control}
                          error={errors.dateToCheck}
                          fieldName="dateToCheck"
                          label="Sur la période"
                          options={[
                            { label: "Date d'entrée", value: 'entry' },
                            { label: 'Date de sortie', value: 'exit' },
                            { label: 'Pas de filtre', value: 'noFilter' },
                          ]}
                          size="small"
                          requiredRule={'true'}
                        />{' '}
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <ControlledDateField
                          control={control}
                          error={errors.startDate}
                          validate={(value) => isValidPastDateCoherence(value) || !value}
                          fieldName="startDate"
                          label="Du"
                          size="small"
                          requiredRule={dateToCheck === 'noFilter' ? undefined : 'La date de début est requise'}
                          disabled={dateToCheck === 'noFilter'}
                        />{' '}
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <ControlledDateField
                          control={control}
                          error={errors.endDate}
                          validate={(value) => isValidPastDateCoherence(value) || !value}
                          fieldName="endDate"
                          label="Au"
                          size="small"
                          requiredRule={dateToCheck === 'noFilter' ? undefined : 'La date de fin est requise'}
                          disabled={dateToCheck === 'noFilter'}
                        />{' '}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      ...spacingItem,
                    }}
                  >
                    <Typography color={blue} sx={{ whiteSpace: 'nowrap' }}>
                      Exporter un document qui concerne
                    </Typography>
                    <ControlledAutoCompleteWithCustomValue
                      control={control}
                      error={errors.endDate}
                      fieldName="adopterId"
                      options={[
                        { label: 'Toutes les FA', value: 'all' },
                        ...allMembers.sort((a, b) => a.label.localeCompare(b.label)),
                      ]}
                      size="small"
                      defaultValue=""
                      requiredRule={'Ce champs est requis'}
                    />
                  </Box>
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <Tooltip
              title={
                type === 'icad' && !accountsStore.isPremium()
                  ? "Ce type d'export est réservé aux possesseurs de l'offre Utile"
                  : ''
              }
            >
              <span>
                <LoadingButton
                  data-testid="apply-changes"
                  variant="contained"
                  type="submit"
                  disabled={type === 'icad' && !accountsStore.isPremium()}
                  loading={exportPdfMutation.isLoading}
                >
                  Exporter
                </LoadingButton>
              </span>
            </Tooltip>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
