import { Box, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../theme'

export const AQuestion: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& a:active': { color: colors.cello },
        marginTop: 4,
      }}
    >
      <Typography fontSize={15}>Une question ?</Typography>
      <a target="_blank" href="mailto:contact@petso.fr" rel="noreferrer" style={{ color: colors.cello, fontSize: 15 }}>
        contact@petso.fr
      </a>
    </Box>
  )
}
