import dayjs from 'dayjs'

export const isDateOlderThanJPlusOne = (dateToCheck: dayjs.Dayjs | null): boolean => {
  if (!dateToCheck) {
    return false
  }

  const tomorrow = dayjs().add(1, 'day').startOf('day')

  // Check if dateToCheck is not after tomorrow
  return !dateToCheck.isAfter(tomorrow)
}
