import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material'
import React from 'react'
import { theme } from '../../theme'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (confirmation?: boolean) => Promise<void>
}

export const RestartProcedureModal: React.FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      fullWidth
      scroll="paper"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
    >
      <DialogTitle>Êtes-vous sûr de vouloir continuer ?</DialogTitle>

      <Box
        sx={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          boxSizing: 'content-box',
        }}
      >
        <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
          <b>
            Il y a déjà une procédure en cours pour cet animal.
            <br />
            Si vous continuez, la procédure en cours sera suspendue et celle-ci sera relancée.
          </b>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="error"
            type="button"
            sx={{ fontSize: isMobile ? '14px' : '16px', textTransform: 'none' }}
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            type="button"
            sx={{ fontSize: isMobile ? '14px' : '16px', textTransform: 'none' }}
            onClick={() => onSubmit(true)}
          >
            Continuer quand même
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
