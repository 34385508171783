import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'
import { AccountCommentsDto } from '../../../interactors/gen/backendClient'
import { MemberCommentItem } from './MemberCommentItem'
import { AddCommentModal } from './AddCommentModal'
import { AccountsClient } from '../../../interactors/clients/AccountsClient'
import { useMutation } from 'react-query'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'

interface Props {
  accountId: string
  isOwnAccount: boolean
  baseComments?: AccountCommentsDto[]
}

export const MemberCommentsCard: React.FC<Props> = ({ baseComments, accountId, isOwnAccount }) => {
  const [comments, setComments] = React.useState<AccountCommentsDto[] | undefined>(baseComments)

  const globalSnackBarStore = useGlobalSnackbarStore()

  React.useEffect(() => {
    setComments(baseComments)
  }, [baseComments])

  const deleteMutation = useMutation((commentId: string) => AccountsClient.deleteComment(accountId, commentId), {
    onSuccess: (comments) => {
      setComments(comments)
      globalSnackBarStore.triggerSuccessMessage('Le commentaire a bien été supprimé')
    },
  })

  const editCommentMutation = useMutation(
    ({ commentId, comment }: { commentId: string; comment: string }) =>
      AccountsClient.editComment(accountId, commentId, comment),
    {
      onSuccess: (comments) => {
        setComments(comments)
        globalSnackBarStore.triggerSuccessMessage('Le commentaire a bien été modifié')
      },
    }
  )

  const addCommentMutation = useMutation((comment: string) => AccountsClient.addComment(accountId, comment), {
    onSuccess: (comments) => {
      setComments(comments)
      globalSnackBarStore.triggerSuccessMessage('Le commentaire a bien été ajouté')
    },
  })

  const onDelete = async (commentId: string) => {
    await deleteMutation.mutateAsync(commentId)
  }

  const onEdit = async (commentId: string, comment: string) => {
    await editCommentMutation.mutateAsync({ commentId, comment })
  }

  const addComment = async (comment: string) => {
    await addCommentMutation.mutateAsync(comment)
  }
  return (
    <Card>
      <CardHeader title="Commentaires" />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {comments ? (
          comments.map((comment) => (
            <MemberCommentItem key={comment.id} comment={comment} onDelete={onDelete} onEdit={onEdit} />
          ))
        ) : (
          <Typography variant="body1">Aucun commentaire pour le moment</Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <AddCommentModal addComment={addComment} isOwnAccount={isOwnAccount} />
        </Box>
      </CardContent>
    </Card>
  )
}
