import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useInactivityPopup = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const delay = 300000 // 5 minutes in milliseconds
  // const delay = 10000 // test: 10 seconds

  useEffect(() => {
    let timer: NodeJS.Timeout
    let opened = false

    const resetTimer = () => {
      clearTimeout(timer)
      console.log('restarted timer')
      timer = setTimeout(() => {
        setOpen(true)
        opened = true
      }, delay)
    }

    const handleInteraction = () => {
      if (!opened) resetTimer()
    }

    document.addEventListener('mousemove', handleInteraction)
    document.addEventListener('keypress', handleInteraction)
    document.addEventListener('touchstart', handleInteraction)

    if (!opened) resetTimer() // Initialize the timer

    return () => {
      clearTimeout(timer)
      document.removeEventListener('mousemove', handleInteraction)
      document.removeEventListener('keypress', handleInteraction)
      document.removeEventListener('touchstart', handleInteraction)
    }
  }, [delay])

  const handleStay = () => {
    window.location.reload()
  }

  const handleLeave = () => {
    navigate('/animaux')
  }

  return {
    open,
    handleStay,
    handleLeave,
    setOpen,
  }
}

export default useInactivityPopup
