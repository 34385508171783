import { useEffect, useMemo } from 'react'
import { useAnimalStore } from './AnimalStore'
import { AnimalFilters } from '../domain/Animal/AnimalFiltersType'
import { SortColumn, SortDirection } from '../views/Animals/AnimalsTable'

// Now taking page, limit and searchQuery as optional parameters
// If parameters are not provided, it will fetch or refresh all animals (page 1, limit 9999, no search query)
export const useFetchOrRefreshAnimalsOnMount = (
  page?: number,
  limit?: number,
  filters?: AnimalFilters,
  searchQuery?: string,
  sortColumn?: SortColumn,
  sortDirection?: SortDirection
) => {
  const animalStore = useAnimalStore()

  const memoizedFilters = useMemo(() => filters, [JSON.stringify(filters)])

  useEffect(() => {
    const fetchData = async () => {
      await animalStore.fetchOrRefreshAnimals(page, limit, memoizedFilters, searchQuery, sortColumn, sortDirection)

      console.log('Animals fetched or refreshed')
    }

    fetchData()
  }, [page, memoizedFilters, searchQuery, sortColumn, sortDirection])
}
