import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Typography, Button, Collapse } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  AccountDtoPermission,
  AdopterDto,
  AdopterDtoProfileHasBeenAccepted,
  AdopterFollowUpDto,
} from '../../../interactors/gen/backendClient'
import { spacingItem } from '../../theme'
import { FollowUpItem } from './FollowUpItem'
import { NewFollowUpModal } from './NewFollowUpModal'
import { useFetchOrRefreshMembersOnMount } from '../../../store/useFetchOrRefreshMembersOnMount'
import dayjs from 'dayjs'
import { useAccountsStore } from '../../../store/AccountsStore'

interface Props {
  adopter: AdopterDto
  onSubmit: (data: Partial<AdopterDto>) => Promise<void>
}

export const AdoptionFollowUp: React.FC<Props> = ({ adopter, onSubmit }) => {
  const [isExpanded, setExpanded] = useState(
    adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormAccepted ||
      adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted ||
      adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused
      ? true
      : false
  )
  const [followUps, setFollowUps] = useState<AdopterFollowUpDto[]>(adopter.followUp || [])

  useFetchOrRefreshMembersOnMount()

  const initialFollowUpsRef = useRef(adopter.followUp || [])
  useEffect(() => {
    const savefollowUps = async () => {
      // only save if followUps have been modified
      if (JSON.stringify(followUps) === JSON.stringify(initialFollowUpsRef.current)) {
        return
      }

      // Sort followUps by date in descending order to get the most recent ones first
      const sortedFollowUps = [...followUps].sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf())

      let mostRecentVerdict: 'accepted' | 'refused' | null = null
      for (const followUp of sortedFollowUps) {
        if (followUp.verdict === 'accepted' || followUp.verdict === 'refused') {
          mostRecentVerdict = followUp.verdict
          break
        }
      }

      const update: Partial<AdopterDto> = {
        followUp: sortedFollowUps,
        profileHasBeenAccepted: mostRecentVerdict || adopter.profileHasBeenAccepted,
      }

      console.log('update', update)

      await onSubmit(update)
    }
    savefollowUps()
  }, [followUps])

  const handleClickOnExpand = () => {
    setExpanded(!isExpanded)
  }

  const skipFollowUp = () => {
    onSubmit({
      profileHasBeenAccepted: 'accepted',
    })
  }

  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read

  return (
    <>
      <Box
        sx={{
          ...spacingItem,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          opacity:
            adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormAccepted ||
            adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted ||
            adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused
              ? 1
              : 0.5,
          cursor:
            adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormAccepted ||
            adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted ||
            adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused
              ? 'default'
              : 'not-allowed',
          py: 1,
        }}
      >
        <Typography variant="h4" fontSize={{ xs: 24, sm: 32 }}>
          Étape 2: Prise de contact
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">
            {adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused && '🚫 Profil refusé'}
            {adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted && '✅ Étape validée'}
          </Typography>
          <Button
            type="button"
            onClick={handleClickOnExpand}
            disabled={
              !(
                adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormAccepted ||
                adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted ||
                adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused
              )
            }
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Box>
      </Box>
      <Collapse in={isExpanded}>
        {followUps.length === 0 && adopter.profileHasBeenAccepted === 'accepted' && (
          <Typography variant="body2" sx={{ ...spacingItem, color: 'grey.500' }}>
            Vous n’avez renseigné aucune information lors de la validation de la prise de contact
          </Typography>
        )}
        {followUps.map((followUp, index) => (
          <FollowUpItem
            key={followUp.date + index}
            followUp={followUp}
            followUps={followUps}
            setFollowUps={setFollowUps}
            adopter={adopter}
          />
        ))}

        <Box display="flex" justifyContent="space-between" sx={spacingItem}>
          {adopter.profileHasBeenAccepted !== 'accepted' && (
            <Button variant="outlined" color="error" onClick={() => skipFollowUp()} disabled={isReader}>
              Accepter le profil sans prise de contact
            </Button>
          )}
          <NewFollowUpModal
            adopter={adopter}
            followUps={followUps}
            setFollowUps={setFollowUps}
            alreadyAccepted={adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted}
          />
        </Box>
      </Collapse>
    </>
  )
}
