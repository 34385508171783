import { AccountDtoPreferences } from '../interactors/gen/backendClient'

const isEquivalent = (a: any, b: any): boolean => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && a.every((val, index) => val === b[index])
  }
  return a === b
}

export const doesPreferenceExist = (
  newPref: AccountDtoPreferences,
  existingPreferences: Array<AccountDtoPreferences>
) => {
  return existingPreferences.some((pref) => {
    return Object.keys(newPref).every((key) => {
      const keyTyped = key as keyof AccountDtoPreferences
      return isEquivalent(newPref[keyTyped], pref[keyTyped])
    })
  })
}
