import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PATHS } from './PATHS'

import logo from './logo.svg'

import { Icon } from '@mui/material'
import { FC } from 'react'
import { AuthService } from '../interactors/services/AuthService'
import { useAccountsStore } from '../store/AccountsStore'
import { fullName } from '../domain/Account/AccountDisplay'
import { ArrowBack } from '@mui/icons-material'
import { CustomerDtoTypes } from '../interactors/gen/backendClient'
import { getBestCustomerTypeLabel } from '../domain/Customer/CustomerDisplay'
import { getCustomerProfilePhotoSrc } from '../utils/S3-links'
import { VideoPopIn } from './utils/VideoPopIn'

interface Props {
  customerTypes: CustomerDtoTypes[] | undefined
}

export const ResponsiveAppBar: FC<Props> = ({ customerTypes }) => {
  const pages: Array<{ label: string; path: string }> = [
    { label: 'Animaux', path: PATHS.animals.absolute },
    { label: 'Membres', path: PATHS.suiviBenevole.absolute },
    { label: 'Vétérinaires', path: PATHS.suiviVeterinaire.absolute },
    { label: 'Adoptants', path: PATHS.suiviAdoptant.absolute },
    { label: 'La carte', path: PATHS.carte.absolute },
  ]

  const accountsStore = useAccountsStore()
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const customerId = accountsStore.connectedAccount?.customerId
  const [customerDisplay, setCustomerDisplay] = React.useState<string>(customerId || '')

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    AuthService.logout()
    handleCloseUserMenu()
    navigate(PATHS.login.relative)
    setTimeout(() => {
      navigate(PATHS.login.relative)
    }, 2000)
  }

  const srcLogo = `https://logo-customer-images.s3.eu-west-3.amazonaws.com/${customerId}.jpg`

  React.useEffect(() => {
    if (accountsStore.connectedCustomer?.name) {
      setCustomerDisplay(accountsStore.connectedCustomer?.name)
    }
  }, [accountsStore.connectedCustomer?.name])

  const baseRoutesForBackButton = [
    PATHS.animals.absolute,
    PATHS.suiviBenevole.absolute,
    PATHS.suiviAdoptant.absolute,
    PATHS.suiviVeterinaire.absolute,
    // Add more base routes here
  ]

  const customer = accountsStore.connectedCustomer

  const profileImageKey = customer?.profileImageKey

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Add the back button only if the current route is not the base route */}
          {baseRoutesForBackButton.some((route) => location.pathname.includes(route)) &&
            (location.pathname.match(/\//g) || []).length > 1 && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="go back"
                onClick={() => navigate(-1)} // navigate to the previous page in history
                sx={{ marginRight: 2 }}
              >
                <ArrowBack />
              </IconButton>
            )}
          <Icon sx={{ width: '60px', height: '60px' }}>
            <img src={logo} />
          </Icon>{' '}
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', py: 2 }}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  // fontFamily: 'monospace',
                  fontWeight: 400,
                  letterSpacing: '.3rem',
                  color: 'white',
                  textDecoration: 'none',
                  fontSize: '1rem',
                  fontFamily: 'Insaniburger',
                }}
              >
                PETSO
              </Typography>
              {customer?.subscription?.status === 'active' && (
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 22,
                    fontSize: 12,
                    color: 'white',
                    fontFamily: 'Insaniburger',
                  }}
                >
                  {customer.subscription.plan === 'premium'
                    ? 'Premium'
                    : customer.subscription.plan === 'essential'
                    ? 'Essentiel +'
                    : ''}
                </Typography>
              )}
            </Box>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.path} onClick={() => navigate(page.path)}>
                  <Typography textAlign="center" sx={{ textTransform: 'none' }}>
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.path}
                onClick={() => navigate(page.path)}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: '1rem',
                }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          <Button
            onClick={() => navigate(PATHS.association.absolute)}
            sx={{
              my: 2,
              mr: 2,
              color: 'white',
              display: 'block',
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '1rem',
            }}
          >
            {getBestCustomerTypeLabel(customerTypes, { my: true })}
          </Button>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Espace Perso">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={customerId}
                  src={(profileImageKey && getCustomerProfilePhotoSrc(profileImageKey)) || srcLogo}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="customer" onClick={() => navigate(`${PATHS.association.absolute}`)}>
                <Typography textAlign="center">{customerDisplay}</Typography>
              </MenuItem>
              {/* <MenuItem key="account" sx={{ cursor: 'default' }}> */}
              <MenuItem
                key="account"
                onClick={() => navigate(`${PATHS.suiviBenevole.absolute}/${accountsStore?.connectedAccount?.id}`)}
              >
                <Typography textAlign="center">Mon Profil ({fullName(accountsStore.connectedAccount!)})</Typography>
                {/* <Typography textAlign="center">{fullName(accountsStore.connectedAccount!)}</Typography> */}
              </MenuItem>
              <VideoPopIn menuItem />
              <MenuItem key="logout" onClick={handleLogout}>
                <Typography textAlign="center">Se Déconnecter</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
