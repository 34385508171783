import { Avatar, Box, Card, CardContent, CardHeader, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { DesiredAnimalPopUp } from './DesiredAnimalPopUp'
import { AdopterDto } from '../../../interactors/gen/backendClient'
import { useAnimalStore } from '../../../store/AnimalStore'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { getAnimalProfilePhotoSrc } from '../../../utils/S3-links'
import { calculateAge } from '../../../utils/date/calculateAge'
import { Link } from 'react-router-dom'
import { blueSky } from '../../theme'

interface Props {
  adopter: AdopterDto
  onSubmit: (data: Partial<AdopterDto>) => Promise<void>
}

export const DesiredAnimalBanner: React.FC<Props> = ({ adopter, onSubmit }) => {
  const animalStore = useAnimalStore()

  useEffect(() => {
    const loadAnimal = async () => {
      if (adopter.desiredAnimal?.desiredAnimalId) {
        await animalStore.changeSelectedAnimal(adopter.desiredAnimal.desiredAnimalId)
      }
    }

    loadAnimal()
  }, [adopter.desiredAnimal?.desiredAnimalId])

  const animal = animalStore.selectedAnimal

  const profileImageKey = animal?.images?.profileImageKey

  const animalProperties = [
    animal?.species && getReadableSpecies(animal?.species),
    animal?.birthday?.date && calculateAge(animal?.birthday?.date),
    animal?.health?.sterilizedOrNeutered ? 'Stérilisé' : 'Non stérilisé',
  ]
  return (
    <Card sx={{ mb: 6 }}>
      <CardHeader title={`Le souhait d'adoption de ${adopter.firstName}`} />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {adopter.desiredAnimal?.hasDesiredAnimal && animal && (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
              <Avatar
                alt={animal.name}
                src={profileImageKey && getAnimalProfilePhotoSrc(profileImageKey)}
                sx={{ width: 64, height: 64 }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <Typography fontSize={20} color="text.secondary" fontWeight={700}>
                  {animal?.name}
                </Typography>
                <Typography fontSize={15} color="text.secondary">{`${
                  animalProperties && animalProperties.filter(Boolean).join(', ')
                }`}</Typography>
              </Box>
            </Box>
          )}
          {!adopter.desiredAnimal?.hasDesiredAnimal && (
            <Typography>
              Vous n&#39;avez pas encore indiqué l&#39;animal que {adopter.firstName} souhaite adopter.
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            {adopter.desiredAnimal?.desiredAnimalId && (
              <Link to={`/animaux/${animal?.id}`} style={{ fontWeight: 700, color: blueSky }}>
                Consulter le profil
              </Link>
            )}
            {adopter && <DesiredAnimalPopUp adopter={adopter} onSubmit={onSubmit} />}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
