// eslint-disable-next-line max-classes-per-file
import { AuthClient } from '../clients/AuthClient'
import { backendClient } from '../clients/client'
import { AccountDto } from '../gen/backendClient'

export class LocalStorageToken {
  private static TOKEN_KEY = 'token'

  public static getCurrentToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY)
  }

  public static setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token)
  }

  public static removeStaticToken(): void {
    localStorage.removeItem(this.TOKEN_KEY)
  }
}

export class AuthService {
  public static account?: AccountDto = undefined

  public static async loginWithPassword(email: string, password: string): Promise<string> {
    const accessToken = await AuthClient.getAccessToken(email, password)

    LocalStorageToken.setToken(accessToken)
    this.attachAuthorizationToAllRequest(accessToken)
    await this.loadAccount()

    return accessToken
  }

  public static async adminLogin(customerId: string, password: string): Promise<string> {
    const accessToken = await AuthClient.getAdminAccessToken(customerId, password)

    LocalStorageToken.setToken(accessToken)
    this.attachAuthorizationToAllRequest(accessToken)
    await this.loadAccount()

    return accessToken
  }

  public static async tryLogInWithLocalToken(): Promise<AccountDto | null> {
    console.log('tryLogInWithLocalToken')
    const token = LocalStorageToken.getCurrentToken()
    if (!token) {
      return null
    }

    this.attachAuthorizationToAllRequest(token)
    return await this.loadAccount()
  }

  // public static logout(): void {
  //   localStorage.removeItem('user')
  // }

  public static isLogged(): boolean {
    return Boolean(this.account)
  }

  // public static isLoggedAsAdmin(): boolean {
  //   return !!this.account && this.account.roles.includes('forto-admin')
  // }

  public static async loadAccount(): Promise<AccountDto> {
    const responseDto = await AuthClient.getCurrentAccount()
    this.account = responseDto

    return responseDto
  }

  public static async logout(): Promise<void> {
    LocalStorageToken.removeStaticToken()
    this.attachAuthorizationToAllRequest('')
    this.account = undefined
    window.location.reload()
  }

  public static async requestPasswordReset(email: string) {
    await AuthClient.requestPasswordReset(email)
  }

  private static attachAuthorizationToAllRequest(token: string): void {
    backendClient.interceptors.request.use((config) => {
      if (!config) {
        throw new Error('Config undefined')
      }

      if (!config.url?.includes('auth')) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-param-reassign
        config.headers!.Authorization = `Bearer ${token}`
      }

      return config
    })
  }
}
