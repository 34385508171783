import { AnimalDtoSpecies } from '../../interactors/gen/backendClient'

export const getAnimalRegistrationNumberType = (species: AnimalDtoSpecies): string | undefined => {
  if (species === AnimalDtoSpecies.Cat) {
    return 'LOOF'
  }
  if (species === AnimalDtoSpecies.Dog) {
    return 'LOF'
  }
  return undefined
}
