import { Card, CardHeader, CardContent, Typography, Box } from '@mui/material'
import React from 'react'
import { AccountDto } from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { MemberCardSaveButton } from '../MemberCardSaveButton'
import { useAccountsStore } from '../../../store/AccountsStore'

type Props = {
  account: AccountDto
  isOwnAccount: boolean
  onSubmit: (data: Partial<AccountDto>) => Promise<void>
}

interface VisibilityForm {
  toEditors: boolean
  toReaders: boolean
  toAdmins: boolean
  onTheMap: boolean
  pauseProfile?: boolean
}

export const MemberVisibilityTab: React.FC<Props> = ({ account, isOwnAccount, onSubmit }) => {
  const accountsStore = useAccountsStore()
  const getDefaultValues = (account: AccountDto): VisibilityForm => {
    return {
      toEditors: account.visibility?.toEditors != null ? account.visibility?.toEditors : true,
      toReaders: account.visibility?.toReaders != null ? account.visibility?.toReaders : true,
      toAdmins: true,
      onTheMap: account.visibility?.onTheMap != null ? account.visibility?.onTheMap : true,
      pauseProfile: account.visibility?.pauseProfile != null ? account.visibility?.pauseProfile : false,
    }
  }
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormExtended(account, getDefaultValues)

  const submitProcess = (data: VisibilityForm) => {
    const newAccount: AccountDto = {
      ...account,
      visibility: {
        toEditors: data.toEditors,
        toReaders: data.toReaders,
        toAdmins: true,
        onTheMap: data.onTheMap,
        pauseProfile: data.pauseProfile,
      },
    }
    onSubmit(newAccount)
  }

  const formShouldBeDisabled = () => {
    if (accountsStore.isAdmin() && account.permission === 'silent') {
      return false
    } else if (isOwnAccount) {
      return false
    }
    return true
  }
  return (
    <Card>
      <CardHeader title="Visibilité" />
      <CardContent>
        <Typography sx={{ mb: 2 }}>Je souhaite que mes informations soient visibles :</Typography>
        <form onSubmit={handleSubmit(submitProcess)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', justifyContent: 'center' }}
            >
              <Typography>aux Admins</Typography>
              <ControlledToggleButton<VisibilityForm>
                control={control}
                fieldName="toAdmins"
                firstOptionLabel="Oui"
                secondOptionLabel="Non"
                firstOptionValue={true}
                secondOptionValue={false}
                disabled
                error={errors.toAdmins}
                requiredRule="Vous devez choisir une option"
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', justifyContent: 'center' }}
            >
              <Typography>aux Editeurs</Typography>
              <ControlledToggleButton<VisibilityForm>
                control={control}
                fieldName="toEditors"
                firstOptionLabel="Oui"
                secondOptionLabel="Non"
                firstOptionValue={true}
                secondOptionValue={false}
                disabled={formShouldBeDisabled()}
                error={errors.toEditors}
                requiredRule="Vous devez choisir une option"
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', justifyContent: 'center' }}
            >
              <Typography>aux Lecteurs</Typography>
              <ControlledToggleButton<VisibilityForm>
                control={control}
                fieldName="toReaders"
                firstOptionLabel="Oui"
                secondOptionLabel="Non"
                firstOptionValue={true}
                secondOptionValue={false}
                disabled={formShouldBeDisabled()}
                error={errors.toReaders}
                requiredRule="Vous devez choisir une option"
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', justifyContent: 'center' }}
            >
              <Typography>Sur la carte</Typography>
              <ControlledToggleButton<VisibilityForm>
                control={control}
                fieldName="onTheMap"
                firstOptionLabel="Oui"
                secondOptionLabel="Non"
                firstOptionValue={true}
                secondOptionValue={false}
                disabled={formShouldBeDisabled()}
                error={errors.onTheMap}
                requiredRule="Vous devez choisir une option"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'center',
              mt: 3,
              width: '100%',
            }}
          >
            <Typography>Mettre ce profil en pause:</Typography>
            <ControlledToggleButton<VisibilityForm>
              control={control}
              fieldName="pauseProfile"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={true}
              secondOptionValue={false}
              disabled={formShouldBeDisabled()}
              error={errors.pauseProfile}
              requiredRule={undefined}
              sx={{ width: 'fit-content' }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
            <MemberCardSaveButton isOwnAccount={isOwnAccount} disabled={!isDirty} />
          </Box>
        </form>
      </CardContent>
    </Card>
  )
}
