import { Edit } from '@mui/icons-material'
import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Tooltip,
  Grid,
  TextField,
} from '@mui/material'
import React from 'react'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import {
  AccountDto,
  AccountDtoHomeBalcony,
  AccountDtoHomeGarden,
  AccountDtoHomeHasCar,
  AccountDtoHomeHomeType,
} from '../../interactors/gen/backendClient'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { MemberPromptSaveButton } from './MemberPromptSaveButton'
import {
  getReadableAccountHomeBalcony,
  getReadableAccountHomeGarden,
  getReadableAccountHomeType,
  getReadableAccountPreferencesWillingToSocializeWildAnimals,
} from '../../domain/Account/AccountDisplay'
import { ControlledSelectField } from '../common/ControlledSelectField'

interface Props {
  account: AccountDto | null
  onSubmit: (data: Partial<AccountDto>) => Promise<void>
  isOwnAccount: boolean
  isLoading: boolean
}

export interface HomeFormParams {
  homeType?: AccountDtoHomeHomeType
  balcony?: AccountDtoHomeBalcony
  garden?: AccountDtoHomeGarden
  quarantineRoomNumber?: number
  adultNumber?: number
  childrenNumber?: number
  maxCapacity?: number
  hasCar?: AccountDtoHomeHasCar
  homeSurface?: number
  hasIsolationRoom?: AccountDtoHomeHasCar
}

const numberOptions = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
]

export const EditMemberHomePrompt: React.FC<Props> = ({ account, onSubmit, isOwnAccount, isLoading }) => {
  const dialogOpenedState = useDialogOpened()

  const submitProcess = (data: HomeFormParams) => {
    const newAccount = { ...account, home: { ...account?.home, ...data } }
    return onSubmit(newAccount)
  }

  const getDefaultValues = (account: AccountDto | null): HomeFormParams => ({
    homeType: account?.home?.homeType,
    balcony: account?.home?.balcony,
    garden: account?.home?.garden,
    quarantineRoomNumber: account?.home?.quarantineRoomNumber,
    adultNumber: account?.home?.adultNumber,
    childrenNumber: account?.home?.childrenNumber,
    maxCapacity: account?.home?.maxCapacity,
    hasCar: account?.home?.hasCar,
    homeSurface: account?.home?.homeSurface,
    hasIsolationRoom: account?.home?.hasIsolationRoom,
  })

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useFormExtended(account, getDefaultValues)

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
        <Tooltip title="Editer le foyer">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Editer le foyer</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="homeType"
                    label="Type d'habitation"
                    error={errors.homeType}
                    options={Object.values(AccountDtoHomeHomeType).map((status: AccountDtoHomeHomeType) => ({
                      label: getReadableAccountHomeType(status),
                      value: status,
                    }))}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="balcony"
                    label="Avec balcon(s)"
                    error={errors.balcony}
                    options={Object.values(AccountDtoHomeBalcony).map((status: AccountDtoHomeBalcony) => ({
                      label: getReadableAccountHomeBalcony(status),
                      value: status,
                    }))}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="garden"
                    label="Avec jardin(s)"
                    error={errors.garden}
                    options={Object.values(AccountDtoHomeGarden).map((status: AccountDtoHomeGarden) => ({
                      label: getReadableAccountHomeGarden(status),
                      value: status,
                    }))}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="quarantineRoomNumber"
                    label="Nombre de salle(s) de quarantaine"
                    error={errors.quarantineRoomNumber}
                    options={numberOptions}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="hasIsolationRoom"
                    label="Possibilité d'isolement"
                    error={errors.hasIsolationRoom}
                    options={Object.values(AccountDtoHomeHasCar).map((status: AccountDtoHomeHasCar) => ({
                      label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                      value: status,
                    }))}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register('homeSurface')}
                    label="Surface de l'habitation (m²)"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="hasCar"
                    label="Véhiculé"
                    error={errors.hasCar}
                    options={Object.values(AccountDtoHomeHasCar).map((status: AccountDtoHomeHasCar) => ({
                      label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                      value: status,
                    }))}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="adultNumber"
                    label="Nombre d'adulte(s)"
                    error={errors.adultNumber}
                    options={numberOptions}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledSelectField
                    control={control}
                    fieldName="childrenNumber"
                    label="Nombre d'enfant(s)"
                    error={errors.childrenNumber}
                    options={numberOptions}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="maxCapacity"
                    label="Capacité d'accueil"
                    error={errors.maxCapacity}
                    options={[
                      { label: '1 animal', value: 1 },
                      { label: '2 animaux', value: 2 },
                      { label: '3 animaux', value: 3 },
                      { label: '4 animaux', value: 4 },
                      { label: '5 animaux', value: 5 },
                      { label: '6 animaux', value: 6 },
                      { label: '7 animaux', value: 7 },
                      { label: '8 animaux', value: 8 },
                      { label: '9 animaux', value: 9 },
                      { label: '10 animaux', value: 10 },
                      { label: '11 animaux', value: 11 },
                      { label: '12 animaux', value: 12 },
                      { label: '13 animaux', value: 13 },
                      { label: '14 animaux', value: 14 },
                      { label: '15 animaux', value: 15 },
                      { label: '16 animaux', value: 16 },
                      { label: '17 animaux', value: 17 },
                      { label: '18 animaux', value: 18 },
                      { label: '19 animaux', value: 19 },
                      { label: '20 animaux', value: 20 },
                    ]}
                    requiredRule=""
                    size="small"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <MemberPromptSaveButton
                isOwnAccount={isOwnAccount}
                isLoading={isLoading}
                dialogOpenedState={dialogOpenedState}
              />
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
