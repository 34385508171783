import { Autocomplete, BaseTextFieldProps, Chip, SxProps, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { blueSky } from '../theme'

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: string[]
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  freeSolo?: boolean
  color?: string
  sx?: SxProps
  helperText?: string
  placeholder?: string
}

export function ControlledMultipleFreeSoloAutoComplete<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  options,
  size,
  disabled,
  freeSolo,
  color = blueSky,
  sx,
  helperText,
  placeholder,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={[] as any} // TODO: check this
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          sx={{ ...sx, width: '100%' }}
          multiple
          onChange={(_, data) => onChange(data)}
          freeSolo={freeSolo}
          id={fieldName}
          options={options}
          getOptionLabel={(option) => option}
          value={value}
          size={size}
          disabled={disabled}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...restTagProps } = getTagProps({ index })
              return (
                // Destructure getTagProps to remove the key
                <Chip
                  key={option}
                  label={option}
                  {...restTagProps}
                  // Customize the chip style here
                  style={{ backgroundColor: color, color: 'white' }}
                />
              )
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={placeholder ?? label}
              error={!!error}
              helperText={helperText ?? ''}
            />
          )}
        />
      )}
    />
  )
}
