import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import { AdoptionFormParams } from './AnimalAdoptionTab'

interface Props {
  isOpen: boolean
  onClose: () => void
  handleSubmit: UseFormHandleSubmit<AdoptionFormParams>
  onSubmit: (data: AdoptionFormParams, confirmation?: boolean) => void
  animalName: string
}

export const GetAnimalAdoptedModal: React.FC<Props> = ({ isOpen, onClose, handleSubmit, onSubmit, animalName }) => {
  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      fullWidth
      scroll="paper"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
    >
      <DialogTitle>Êtes vous sûr de vouloir continuer ?</DialogTitle>

      <Box
        sx={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          boxSizing: 'content-box',
        }}
      >
        <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
          <b>
            Vous venez d&#39;ajouter une date d&#39;adoption physique.
            <br />
            Si vous continuez {animalName} sera considéré comme étant adopté et son statut sera mis à jour.
          </b>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="outlined" type="button" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="button" onClick={handleSubmit((data) => onSubmit(data, true))}>
            Continuer
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
