import dayjs from 'dayjs'

export const calculateAge = (birthdate: string): string => {
  // Parse the birthdate string into a Day.js object
  const birthDateDayjs = dayjs(birthdate, 'DD/MM/YYYY')

  // Get today's date
  const today = dayjs()

  // Calculate the difference in years
  const ageInYears = today.diff(birthDateDayjs, 'year')

  if (ageInYears >= 1) {
    return ageInYears === 1 ? `${ageInYears} an` : `${ageInYears} ans`
  } else {
    // Calculate the difference in months if less than a year old
    const ageInMonths = today.diff(birthDateDayjs, 'month')
    return `${ageInMonths} mois`
  }
}
