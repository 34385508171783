import React from 'react'
import { DonationTrackingDto } from '../../../interactors/gen/backendClient'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { spacingItem } from '../../theme'
import dayjs from 'dayjs'
import {
  getColorForDonationTrackingTypeOfDonation,
  getReadableDonationTrackingFormOfDonation,
  getReadableDonationTrackingMethodOfPayment,
  getReadableDonationTrackingTypeOfDonation,
} from '../../../domain/Donations/DonationTrackingDisplay'
import { isColorDark } from '../../../utils/isColorDark'
import { sortAlphabetically } from '../../../utils/sort'
import { useDonatersStore } from '../../../store/DonatersStore'
import { getDonatorFullName } from '../../../domain/Donations/DonatorDisplay'

interface AssociationDonationTrackingProps {
  donations: DonationTrackingDto[]
  setSelectedDonation: (selectedDonation: DonationTrackingDto) => void
  setDonationForFiscalReport: (donation: DonationTrackingDto) => void
  setIsExportPDFFiscalReportModalOpen: (isOpen: boolean) => void
}

export type SortDirection = 'asc' | 'desc'
export type SortColumn =
  | 'donaterName'
  | 'dateCollected'
  | 'amount'
  | 'formOfDonation'
  | 'typeOfDonation'
  | 'methodOfPayment'

export const AssociationDonationTracking: React.FC<AssociationDonationTrackingProps> = ({
  donations,
  setSelectedDonation,
  setDonationForFiscalReport,
  setIsExportPDFFiscalReportModalOpen,
}) => {
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('dateCollected')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')

  const handleSort = (column: SortColumn, columnDefaultDirection: SortDirection) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortDirection(columnDefaultDirection)
    }

    setSortColumn(column)
  }

  const sortedDonations = donations.sort((a, b) => {
    switch (sortColumn) {
      case 'dateCollected':
        if (dayjs(a.dateCollected).isAfter(dayjs(b.dateCollected))) {
          return sortDirection === 'asc' ? -1 : 1
        }

        return sortDirection === 'asc' ? 1 : -1
      case 'donaterName':
        return sortAlphabetically(a, b, sortDirection, 'donaterId')
      case 'amount':
        return sortAlphabetically(a, b, sortDirection, 'amount', 'donaterId')
      case 'formOfDonation':
        return sortAlphabetically(a, b, sortDirection, 'formOfDonation', 'donaterId')
      case 'typeOfDonation':
        return sortAlphabetically(a, b, sortDirection, 'typeOfDonation', 'donaterId')
      case 'methodOfPayment':
        return sortAlphabetically(a, b, sortDirection, 'methodOfPayment', 'donaterId')
      default:
        throw new Error(`sortColumn ${sortColumn} not supported`)
    }
  })
  return (
    <>
      <Card sx={spacingItem}>
        <CardHeader title="Suivi des dons" />
        <CardContent sx={{ overflowX: 'auto' }}>
          <Table stickyHeader={true} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortColumn === 'dateCollected'}
                    direction={sortDirection}
                    onClick={() => {
                      handleSort('dateCollected', 'asc')
                    }}
                  >
                    Date de perception
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortColumn === 'donaterName'}
                    direction={sortDirection}
                    onClick={() => {
                      handleSort('donaterName', 'asc')
                    }}
                  >
                    Donateur
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortColumn === 'amount'}
                    direction={sortDirection}
                    onClick={() => {
                      handleSort('amount', 'desc')
                    }}
                  >
                    Montant du don
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortColumn === 'formOfDonation'}
                    direction={sortDirection}
                    onClick={() => {
                      handleSort('formOfDonation', 'asc')
                    }}
                  >
                    Forme du don
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortColumn === 'typeOfDonation'}
                    direction={sortDirection}
                    onClick={() => {
                      handleSort('typeOfDonation', 'asc')
                    }}
                  >
                    Nature du don
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortColumn === 'methodOfPayment'}
                    direction={sortDirection}
                    onClick={() => {
                      handleSort('methodOfPayment', 'asc')
                    }}
                  >
                    Méthode de paiement
                  </TableSortLabel>
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Reçu fiscal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedDonations.map((donation, i) => {
                return (
                  <AssociationDonationTrackingItem
                    key={donation.dateCollected + i}
                    donation={donation}
                    setSelectedDonation={setSelectedDonation}
                    setDonationForFiscalReport={setDonationForFiscalReport}
                    setIsExportPDFFiscalReportModalOpen={setIsExportPDFFiscalReportModalOpen}
                  />
                )
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}

interface AssociationDonationTrackingItemProps {
  donation: DonationTrackingDto
  setSelectedDonation: (selectedDonation: DonationTrackingDto) => void
  setDonationForFiscalReport: (donation: DonationTrackingDto) => void
  setIsExportPDFFiscalReportModalOpen: (isOpen: boolean) => void
}

export const AssociationDonationTrackingItem: React.FC<AssociationDonationTrackingItemProps> = ({
  donation,
  setSelectedDonation,
  setDonationForFiscalReport,
  setIsExportPDFFiscalReportModalOpen,
}) => {
  const donatersStore = useDonatersStore()

  const donator = donatersStore.donaters.find((donator) => donator.id === donation.donaterId)

  const handleFiscalReport = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setDonationForFiscalReport(donation)
    setIsExportPDFFiscalReportModalOpen(true)
  }

  const handleClick = () => {
    setSelectedDonation(donation)
  }
  return (
    <TableRow
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
        {dayjs(donation.dateCollected).format('DD/MM/YYYY')}
      </TableCell>

      <TableCell component="th" scope="row">
        {donator ? getDonatorFullName(donator) : ''}
      </TableCell>

      <TableCell component="th" scope="row">
        {donation.amount}€
      </TableCell>

      <TableCell component="th" scope="row">
        {donation.formOfDonation ? getReadableDonationTrackingFormOfDonation(donation.formOfDonation) : ''}
      </TableCell>

      <TableCell component="th" scope="row">
        {donation.typeOfDonation ? (
          <Chip
            label={getReadableDonationTrackingTypeOfDonation(donation.typeOfDonation)}
            sx={{
              backgroundColor: getColorForDonationTrackingTypeOfDonation(donation.typeOfDonation),
              color: isColorDark(getColorForDonationTrackingTypeOfDonation(donation.typeOfDonation)) ? '#fff' : 'black',
            }}
          />
        ) : (
          ''
        )}
      </TableCell>

      <TableCell component="th" scope="row">
        {donation.methodOfPayment ? getReadableDonationTrackingMethodOfPayment(donation.methodOfPayment) : ''}
      </TableCell>

      <TableCell component="th" scope="row">
        {donation.description}
      </TableCell>

      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button onClick={(e) => handleFiscalReport(e)} sx={{ fontSize: 14, whiteSpace: 'nowrap' }}>
            Reçu fiscal
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  )
}
