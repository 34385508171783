import { useEffect, useState } from 'react'
import { useAccountsStore } from './AccountsStore'
import { useAnimalStore } from './AnimalStore'

export const useCanEditAnimal = () => {
  const animalStore = useAnimalStore()
  const accountsStore = useAccountsStore()

  const connectedAccount = accountsStore.connectedAccount
  const selectedAnimal = animalStore.selectedAnimal

  const [canEditAnimal, setCanEditAnimal] = useState(false)

  useEffect(() => {
    if (connectedAccount?.permission === 'administrator') {
      setCanEditAnimal(true)
    }

    if (connectedAccount?.permission === 'editor') {
      if (
        [
          selectedAnimal?.responsibilities?.hostFamilyInChargeAccountId,
          selectedAnimal?.responsibilities?.memberInChargeAccountId,
          selectedAnimal?.responsibilities?.veterinaryChargeAccountId,
        ].includes(connectedAccount.id)
      ) {
        console.log('can edit animal')
        setCanEditAnimal(true)
      } else {
        console.log('can not edit animal')
        setCanEditAnimal(false)
      }
    }
  }, [connectedAccount?.permission, animalStore.selectedAnimal])

  return canEditAnimal
}
