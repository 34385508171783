import { Search as SearchIcon } from '@mui/icons-material'
import { Card, CardHeader, CardContent, Box, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import { colors, spacingItem } from '../../../theme'
import { DocumentItem } from './DocumentItem'
import { AddDocumentModal } from './AddDocumentModal'
import { AnimalDocumentDto } from '../../../../interactors/gen/backendClient'
import { getReadableAnimalDocumentType } from '../../../../domain/Animal/AnimalDisplay'
import { useFetchOrRefreshMembersOnMount } from '../../../../store/useFetchOrRefreshMembersOnMount'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'

interface DocumentsListProps {
  documents: AnimalDocumentDto[]
  animalId: string
}

export const DocumentsList: React.FC<DocumentsListProps> = ({ documents, animalId }) => {
  useFetchOrRefreshMembersOnMount()

  const canEditAnimal = useCanEditAnimal()

  const [searchQuery, setSearchQuery] = useState('')
  const [documentsList, setDocumentsList] = useState<AnimalDocumentDto[]>(documents)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const filteredDocuments = documentsList
    ? documentsList.filter(
        (document) =>
          searchQuery === '' ||
          document.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          document.reference?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          getReadableAnimalDocumentType(document.type)?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : []

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{ ...spacingItem, backgroundColor: colors.white }}>
        <TextField
          id="name-input"
          placeholder="Rechercher un document"
          type="text"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          sx={{
            borderRadius: 0,
            [`& fieldset`]: {
              borderRadius: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Card>
        <CardHeader title="Documents" />
        <CardContent>
          {filteredDocuments.length > 0 &&
            filteredDocuments.map((document) => (
              <DocumentItem
                key={document.id}
                document={document}
                animalId={animalId}
                setDocumentsList={setDocumentsList}
              />
            ))}
          <Box display="flex" justifyContent="flex-end" sx={spacingItem}>
            <AddDocumentModal animalId={animalId} disabled={!canEditAnimal} />
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
