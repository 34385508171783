import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Box } from '@mui/material'
import { FC } from 'react'
import { Delete } from '@mui/icons-material'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { fullName } from '../../../../domain/Account/AccountDisplay'

interface Props {
  handleClickOnDelete: () => void
  animalName: string | null
  lastLocation: string | null
  disabled?: boolean
}

export const DeleteFollowUpModal: FC<Props> = ({ handleClickOnDelete, animalName, lastLocation, disabled = false }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()

  const accountsStore = useAccountsStore()

  return (
    <>
      <Button sx={{ color: 'error.main' }} onClick={() => openDialog()} disabled={disabled}>
        <Delete />
      </Button>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>
          <Box
            marginBottom={1}
          >{`Vous êtes sur le point de supprimer la dernière ligne de déplacement en date. En cas de validation, ${
            animalName ?? ''
          } sera automatiquement rattaché au plus récent lieu de résidence enregistré ${
            lastLocation && lastLocation?.startsWith('A-')
              ? accountsStore.members.find((member) => member.id === lastLocation)
                ? fullName(accountsStore.members.find((member) => member.id === lastLocation)!)
                : 'Erreur'
              : lastLocation
          }`}</Box>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel" onClick={closeDialog}>
            Annuler
          </Button>
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            onClick={() => {
              handleClickOnDelete()
              closeDialog()
            }}
            sx={{ backgroundColor: 'error.main' }}
          >
            Supprimer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
