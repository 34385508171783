import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AdopterDto, AdopterDtoProfileHasBeenAccepted } from '../../interactors/gen/backendClient'

import { setOpacity } from '../../utils/setOpacity'
import { getColorForAdopterProfileHasBeenAccepted } from '../../domain/Adopters/AccountDisplay'
import { isColorDark } from '../../utils/isColorDark'

import { AdoptionAttemptChip } from './AdoptersListScreen'

import { Avatar, Box, Button, Card, CardContent, Collapse, Grid, Typography } from '@mui/material'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'

type CardProps = {
  member: AdopterDto
  index: number
  expandCardIndex: number | null
  setExpandCardIndex: React.Dispatch<React.SetStateAction<null | number>>
}

export const AdopterListCard: FC<CardProps> = ({ member, index, expandCardIndex, setExpandCardIndex }) => {
  const attempts = member.adoptionAttempts

  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`${member.id}`)
  }
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const handleExpandClick = () => {
    setExpandCardIndex(index)
    setExpanded(!expanded)
  }

  useEffect(() => {
    setExpanded(index === expandCardIndex)
  }, [expandCardIndex])

  // AdoptionAttemptsChip props

  const animalAvatarSx = {
    height: '30px !important',
    width: '30px !important',
    ml: '-1px !important',
    mt: '-1px !important',
  }
  const adoptionChipTextSx = {
    fontSize: 12,
    height: 30,
    pl: 0,
    gap: 0,
  }

  return (
    <Card
      variant="outlined"
      onClick={handleClick}
      sx={{
        borderRadius: '25px',
        border: '2px solid #D2D2D2',
        boxShadow: 'none',
      }}
    >
      <CardContent
        sx={{
          paddingX: '20px',
          paddingTop: '20px',
          '&:last-child': { paddingBottom: 0 },
        }}
      >
        {/* Avatar, first name + last name + mail */}
        <Box display="flex" gap="15px" alignItems="center" marginBottom="20px">
          {/* Avatar */}
          <Box>
            {member.profileHasBeenAccepted && (
              <Avatar
                alt={"Statut de l'adoptant"}
                sx={{
                  height: '40px',
                  width: '40px',
                  backgroundColor: setOpacity(
                    getColorForAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted),
                    0.8
                  ),
                  color: isColorDark(getColorForAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted))
                    ? '#fff'
                    : '#000',
                }}
              >
                <>{getIconForAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted)}</>
              </Avatar>
            )}
          </Box>
          {/* First name + last name + email */}
          <Box>
            {/* First name + last name */}
            <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
              {member.firstName} {member.lastName}
            </Typography>
            {/* Email */}
            <Typography sx={{ fontSize: '16px' }}>{member.email}</Typography>
          </Box>
        </Box>

        {/* If adoption(s): 1 or 2 first chips */}
        {Array.isArray(attempts) && attempts.length > 0 && (
          <Grid container spacing={1} marginBottom="8px">
            {/* 1 adoption : 1 chip */}
            {attempts.length === 1 && (
              <Grid item>
                <AdoptionAttemptChip
                  member={member}
                  attempt={attempts[0]}
                  avatarSx={animalAvatarSx}
                  textSx={adoptionChipTextSx}
                />
              </Grid>
            )}
            {/* 2 or more adoptions: 2 first chips  */}
            {attempts.length >= 2 && (
              <Grid item container spacing={1}>
                <Grid item xs={6}>
                  <AdoptionAttemptChip
                    member={member}
                    attempt={attempts[0]}
                    avatarSx={animalAvatarSx}
                    textSx={adoptionChipTextSx}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdoptionAttemptChip
                    member={member}
                    attempt={attempts[1]}
                    avatarSx={animalAvatarSx}
                    textSx={adoptionChipTextSx}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        {/* More than 2 adoptions: collapse + expand button */}
        {Array.isArray(attempts) && attempts.length > 2 && (
          <Box paddingBottom="25px">
            <Collapse in={expanded}>
              <Grid container spacing={1} marginBottom="8px">
                {attempts.slice(2).map((attempt, index) => {
                  return (
                    <Grid
                      item
                      key={attempt.id}
                      xs={attempts.length % 2 === 1 && index === attempts.length - 3 ? 12 : 6}
                    >
                      <AdoptionAttemptChip
                        member={member}
                        attempt={attempt}
                        avatarSx={animalAvatarSx}
                        textSx={adoptionChipTextSx}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Collapse>
            <Button
              variant="contained"
              color="secondary"
              disableElevation={true}
              sx={{
                textTransform: 'none',
                height: '25px',
                paddingX: '20px',
                paddingBottom: '8px',
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleExpandClick()
              }}
              aria-label="show more"
            >
              {!expanded ? 'Voir plus' : 'Voir moins'}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

const getIconForAdopterProfileHasBeenAccepted = (
  profileHasBeenAccepted: AdopterDtoProfileHasBeenAccepted
): JSX.Element | undefined => {
  const iconSx = {
    fontSize: '30px',
    color: 'white',
  }
  switch (profileHasBeenAccepted) {
    case AdopterDtoProfileHasBeenAccepted.Accepted:
      return <CheckCircleOutlineIcon sx={iconSx} />
    case AdopterDtoProfileHasBeenAccepted.FormAccepted:
      return <HourglassEmptyIcon sx={iconSx} />
    case AdopterDtoProfileHasBeenAccepted.Refused:
      return <CancelOutlinedIcon sx={iconSx} />
    default:
      return undefined
  }
}
