import { Check, Close } from '@mui/icons-material'
import { Avatar, Box, Button, DialogContent, IconButton, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../theme'

interface Props {
  submitStep: () => void
  hasAnimal: boolean
  close: () => void
}

export const SummaryStep: React.FC<Props> = ({ submitStep, hasAnimal, close }) => {
  return (
    <>
      <DialogContent>
        <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={close}>
          <Close />
        </IconButton>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}
        >
          <Avatar sx={{ height: '80px', width: '80px', backgroundColor: colors.lightGreen }}>
            <Check />
          </Avatar>
          <Typography variant="h6">
            {hasAnimal ? `Une procédure d'adoption a été créée` : `L'adoptant a bien été créé`}
          </Typography>
          <Button variant="contained" color="primary" onClick={submitStep}>
            Aller voir
          </Button>
        </Box>
      </DialogContent>
    </>
  )
}
