import {
  Card,
  CardContent,
  Box,
  CardHeader,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  AccountDto,
  AccountDtoPreferences,
  AccountDtoPreferencesNeedsBottleFeeding,
  AccountDtoPreferencesSeniority,
  AccountDtoPreferencesSex,
  AccountDtoPreferencesSterilizedOrNeutered,
  AccountDtoPreferencesVaccinated,
  AccountDtoPreferencesWillingToSocializeWildAnimals,
  AnimalDtoSpecies,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { spacingItem } from '../theme'
import { EditMemberAdoptionPreferencesPrompt } from './EditMemberAdoptionPreferencesPrompt'
import { getEmojiSpecies, getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import {
  collectNoPreferences,
  translateAccountPreferencesNeedsBottleFeeding,
  translateAccountPreferencesSeniority,
  translateAccountPreferencesSex,
  translateAccountPreferencesSterilizedOrNeutered,
  translateAccountPreferencesVaccinated,
} from '../../domain/Account/AccountAdoptionPreferencesDisplay'
import { AddCircleOutline, ArrowForward, Delete, Edit } from '@mui/icons-material'
import {
  getReadableAccountPreferencesSeniority,
  getReadableAccountPreferencesSex,
  getReadableAccountPreferencesSterilizedOrNeutered,
  getReadableAccountPreferencesVaccinated,
  getReadableAccountPreferencesWillingToSocializeWildAnimals,
  getReadableAccountPreferencesNeedsBottleFeeding,
} from '../../domain/Account/AccountDisplay'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { ControlledAutoCompleteWithCustomValue } from '../common/ControlledAutocompleteWithCustomValue'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { useForm } from 'react-hook-form'
import { omitEmptyString } from '../../utils/omitEmptyString'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { ControlledMultipleAutoComplete } from '../common/ControlledMultipleAutoComplete'
import { setOpacity } from '../../utils/setOpacity'
import { doesPreferenceExist } from '../../utils/doesPreferenceExist'

interface Props {
  account: AccountDto
  onSubmit: (data: Partial<AccountDto>) => Promise<void>
  isOwnAccount: boolean
  isLoading: boolean
}

export const MemberAdoptionPreferences: React.FC<Props> = ({ account, onSubmit, isOwnAccount, isLoading }) => {
  const [otherPreferences, setOtherPreferences] = useState<AccountDtoPreferences[]>(
    account.preferences?.otherPreferences || []
  )
  const initialOperationsRef = useRef(account?.preferences?.otherPreferences)
  const initialMount = useRef(true)
  const accountsStore = useAccountsStore()

  useEffect(() => {
    const saveOtherPreferences = async () => {
      // only save if vaccinations have been modified
      if (JSON.stringify(otherPreferences) !== JSON.stringify(initialOperationsRef.current)) {
        const update = omitEmptyString({
          ...account,
          preferences: {
            ...account?.preferences,
            otherPreferences: otherPreferences,
          },
        })

        await onSubmit(update)
      }
    }

    saveOtherPreferences()
  }, [otherPreferences])

  useEffect(() => {
    if (initialMount.current && account.preferences?.otherPreferences) {
      setOtherPreferences(account.preferences.otherPreferences)
    }
    initialMount.current = false
  }, [account])

  const noPreferenceFields = collectNoPreferences(account?.preferences?.primaryPreferences)
  const preferenceString =
    noPreferenceFields.length > 1 ? `Pas de préférences sur: ${noPreferenceFields.join(', ')}` : ``

  const preferenceChoices = [
    account?.preferences?.primaryPreferences?.sex &&
      translateAccountPreferencesSex(account?.preferences?.primaryPreferences.sex, noPreferenceFields),
    account?.preferences?.primaryPreferences?.seniority &&
    account?.preferences?.primaryPreferences.seniority.filter((seniority) => seniority !== 'no-preference').length > 0
      ? 'Âge: ' +
        account?.preferences?.primaryPreferences.seniority
          .map((seniority) => translateAccountPreferencesSeniority(seniority, noPreferenceFields))
          .join(', ')
      : '',
    account?.preferences?.primaryPreferences?.needsBottleFeeding &&
      translateAccountPreferencesNeedsBottleFeeding(
        account?.preferences?.primaryPreferences.needsBottleFeeding,
        noPreferenceFields
      ),
    account?.preferences?.primaryPreferences?.vaccinated &&
      translateAccountPreferencesVaccinated(account?.preferences?.primaryPreferences.vaccinated, noPreferenceFields),
    account?.preferences?.primaryPreferences?.sterilizedOrNeutered &&
      translateAccountPreferencesSterilizedOrNeutered(
        account?.preferences?.primaryPreferences?.sterilizedOrNeutered,
        noPreferenceFields
      ),
  ].filter((choice) => choice !== '') // filter out empty choices

  const preferencesDisplay = preferenceChoices.join('; ')

  return (
    <Card sx={{ ...spacingItem, height: 'calc(100% - 16px)', boxSizing: 'border-box' }}>
      <CardHeader title="Préférences de prise en charge" />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
            <Typography variant="h4" sx={{ fontSize: 18, mb: 2, textDecoration: 'underline' }}>
              Préférence principale:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
              {account?.preferences?.primaryPreferences ? (
                <>
                  <Typography fontSize={32}>
                    {account?.preferences?.primaryPreferences?.specie
                      ? getEmojiSpecies(account?.preferences?.primaryPreferences?.specie)
                      : '..'}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography fontSize={20} fontWeight={700}>
                      {account?.preferences?.primaryPreferences?.specie
                        ? getReadableSpecies(account?.preferences?.primaryPreferences?.specie)
                        : '..'}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography fontSize={16}>
                        {preferencesDisplay}
                        {preferencesDisplay ? ';' : ''}
                        {noPreferenceFields.length > 1 && ` ${preferenceString}`}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.1, pr: 5 }}
                >
                  Votre préférence principale semble vide, veuillez l&#39;éditer en cliquant sur le crayon{'   '}
                  <ArrowForward />
                </Typography>
              )}
            </Box>
          </Box>
          {(accountsStore.canEdit() || isOwnAccount) && (
            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditMemberAdoptionPreferencesPrompt
                account={account}
                onSubmit={onSubmit}
                isOwnAccount={isOwnAccount}
                isLoading={isLoading}
              />
            </Box>
          )}
        </Box>
        {account.preferences.primaryPreferences && (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
            <Typography variant="h4" sx={{ fontSize: 18, mt: 4, mb: 1, textDecoration: 'underline' }}>
              Autres préférences:
            </Typography>
            {otherPreferences?.map((preference, i) => (
              <AdoptionPreference
                key={preference.specie + i.toString()}
                preference={preference}
                setOtherPreferences={setOtherPreferences}
                otherPreferences={otherPreferences}
                primaryPreference={account.preferences.primaryPreferences!}
                isOwnAccount={isOwnAccount}
              />
            ))}
            <NewAdoptionPreferencePrompt
              primaryPreference={account.preferences.primaryPreferences}
              setOtherPreferences={setOtherPreferences}
              otherPreferences={otherPreferences}
              isOwnAccount={isOwnAccount}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

interface AdoptionPreferenceProps {
  preference: AccountDtoPreferences
  setOtherPreferences: (otherPreferences: AccountDtoPreferences[]) => void
  otherPreferences: AccountDtoPreferences[]
  primaryPreference: AccountDtoPreferences
  isOwnAccount: boolean
}

const AdoptionPreference: React.FC<AdoptionPreferenceProps> = ({
  preference,
  setOtherPreferences,
  otherPreferences,
  primaryPreference,
  isOwnAccount,
}) => {
  const accountsStore = useAccountsStore()

  const noPreferenceFields = collectNoPreferences(preference)
  const preferenceString =
    noPreferenceFields.length > 1 ? `Pas de préférences sur: ${noPreferenceFields.join(', ')}` : ``

  const preferenceChoices = [
    preference.sex && translateAccountPreferencesSex(preference.sex, noPreferenceFields),
    preference.seniority && preference.seniority.filter((seniority) => seniority !== 'no-preference').length > 0
      ? 'Âge: ' +
        preference.seniority
          .map((seniority) => translateAccountPreferencesSeniority(seniority, noPreferenceFields))
          .join(', ')
      : '',
    preference.needsBottleFeeding &&
      translateAccountPreferencesNeedsBottleFeeding(preference.needsBottleFeeding, noPreferenceFields),
    preference.vaccinated && translateAccountPreferencesVaccinated(preference.vaccinated, noPreferenceFields),
    preference.sterilizedOrNeutered &&
      translateAccountPreferencesSterilizedOrNeutered(preference.sterilizedOrNeutered, noPreferenceFields),
  ].filter((choice) => choice !== '') // filter out empty choices

  const preferencesDisplay = preferenceChoices.join('; ')
  return (
    <Box sx={{ display: 'flex', width: '100%', p: 2, mt: 2, boxShadow: '0px 2px 15px 1px #F0F0F0' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        <Typography fontSize={32}>{preference?.specie ? getEmojiSpecies(preference?.specie) : '..'}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography fontSize={20} fontWeight={700}>
            {preference?.specie ? getReadableSpecies(preference?.specie) : '..'}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography fontSize={16}>
              {preferencesDisplay}
              {noPreferenceFields.length > 1 && `; ${preferenceString}`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 2, md: 0 } }}>
          <EditAdoptionPreferencePrompt
            preference={preference}
            setOtherPreferences={setOtherPreferences}
            otherPreferences={otherPreferences}
            primaryPreference={primaryPreference}
            isOwnAccount={isOwnAccount}
          />
          <IconButton
            sx={{ color: 'error.main' }}
            onClick={() => {
              setOtherPreferences(otherPreferences.filter((APreference) => APreference !== preference))
            }}
            disabled={!accountsStore.isAdmin() || !isOwnAccount}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

interface NewAdoptionPreferenceProps {
  primaryPreference: AccountDtoPreferences
  setOtherPreferences: (otherPreferences: AccountDtoPreferences[]) => void
  otherPreferences: AccountDtoPreferences[]
  isOwnAccount: boolean
}

export interface PreferencesFormParams {
  specie?: AnimalDtoSpecies
  seniority?: AccountDtoPreferencesSeniority[]
  sex?: AccountDtoPreferencesSex
  sterilizedOrNeutered?: AccountDtoPreferencesSterilizedOrNeutered
  vaccinated?: AccountDtoPreferencesVaccinated
  withMedicalAntecedent?: AccountDtoPreferencesWillingToSocializeWildAnimals // Yes or No
  willingToSocializeWildAnimals?: AccountDtoPreferencesWillingToSocializeWildAnimals
  needsBottleFeeding?: AccountDtoPreferencesNeedsBottleFeeding
}

export const NewAdoptionPreferencePrompt: React.FC<NewAdoptionPreferenceProps> = ({
  primaryPreference,
  setOtherPreferences,
  otherPreferences,
  isOwnAccount,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()

  const submitProcess = (data: PreferencesFormParams) => {
    const newPreference = {
      ...data,
    }
    if (doesPreferenceExist(newPreference, [...otherPreferences, primaryPreference])) {
      console.error('Preference with the same parameters already exists!')
      setErrorMessage('Vous avez déjà une préférence avec ces paramètres !')
      return
    }
    setErrorMessage(undefined)

    setValue('specie', undefined)
    setValue('seniority', primaryPreference?.seniority || [])
    setValue('sex', primaryPreference?.sex || undefined)
    setValue('sterilizedOrNeutered', primaryPreference?.sterilizedOrNeutered || undefined)
    setValue('vaccinated', primaryPreference?.vaccinated || undefined)
    setValue('withMedicalAntecedent', primaryPreference?.withMedicalAntecedent || undefined)
    setValue('willingToSocializeWildAnimals', primaryPreference?.willingToSocializeWildAnimals || undefined)
    setValue('needsBottleFeeding', primaryPreference?.needsBottleFeeding || undefined)
    setOtherPreferences([...otherPreferences, newPreference])
    return dialogOpenedState.closeDialog()
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PreferencesFormParams>({
    defaultValues: {
      specie: undefined,
      seniority: primaryPreference?.seniority || [],
      sex: primaryPreference?.sex || undefined,
      sterilizedOrNeutered: primaryPreference?.sterilizedOrNeutered || undefined,
      vaccinated: primaryPreference?.vaccinated || undefined,
      withMedicalAntecedent: primaryPreference?.withMedicalAntecedent || undefined,
      willingToSocializeWildAnimals: primaryPreference?.willingToSocializeWildAnimals || undefined,
      needsBottleFeeding: primaryPreference?.needsBottleFeeding || undefined,
    },
    mode: 'onChange',
  })

  const species = accountsStore.connectedCustomer?.preferences.species

  return (
    <>
      <Box
        onClick={() => (accountsStore.isAdmin() || isOwnAccount ? dialogOpenedState.openDialog() : null)}
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '100%',
          p: 2,
          py: 4,
          mt: 2,
          boxShadow: '0px 2px 15px 1px #F0F0F0',
          cursor: 'pointer',
          color: 'primary.main',
          gap: 2,
          transition: 'all 0.1s ease-in-out',
          ':hover': {
            textDecoration: accountsStore.isAdmin() || isOwnAccount ? 'underline' : 'none',
            cursor: accountsStore.isAdmin() || isOwnAccount ? 'pointer' : 'not-allowed',
          },
        }}
      >
        <AddCircleOutline sx={{ fontSize: 32 }} />
        <Typography fontSize={20} fontWeight={700}>
          Ajouter une préférence
        </Typography>
      </Box>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Ajouter une nouvelle préférence</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    defaultValue=""
                    fieldName="specie"
                    label="Espèce"
                    chip
                    error={errors.specie}
                    options={
                      species
                        ? Object.values(species).map((status: AnimalDtoSpecies) => ({
                            label: getReadableSpecies(status, { withEmoji: false }),
                            value: status,
                          }))
                        : []
                    }
                    requiredRule="L'espèce est requise"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledMultipleAutoComplete
                    control={control}
                    fieldName="seniority"
                    label="Âge"
                    error={errors.specie}
                    options={Object.values(AccountDtoPreferencesSeniority).map(
                      (status: AccountDtoPreferencesSeniority) => ({
                        label: getReadableAccountPreferencesSeniority(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sex"
                    label="Sexe"
                    error={errors.sex}
                    options={Object.values(AccountDtoPreferencesSex).map((status: AccountDtoPreferencesSex) => ({
                      label: getReadableAccountPreferencesSex(status),
                      value: status,
                    }))}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sterilizedOrNeutered"
                    label="Stérilisé/Castré"
                    error={errors.sterilizedOrNeutered}
                    options={Object.values(AccountDtoPreferencesSterilizedOrNeutered).map(
                      (status: AccountDtoPreferencesSterilizedOrNeutered) => ({
                        label: getReadableAccountPreferencesSterilizedOrNeutered(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="vaccinated"
                    label="Vaccination"
                    error={errors.vaccinated}
                    options={Object.values(AccountDtoPreferencesVaccinated).map(
                      (status: AccountDtoPreferencesVaccinated) => ({
                        label: getReadableAccountPreferencesVaccinated(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="withMedicalAntecedent"
                    label="Possibilité de prendre en charge des animaux malades"
                    error={errors.withMedicalAntecedent}
                    options={Object.values(AccountDtoPreferencesWillingToSocializeWildAnimals).map(
                      (status: AccountDtoPreferencesWillingToSocializeWildAnimals) => ({
                        label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="willingToSocializeWildAnimals"
                    label="Possibilité d'accueil d'animaux sauvages, avec besoin de sociabilisation"
                    error={errors.willingToSocializeWildAnimals}
                    options={Object.values(AccountDtoPreferencesWillingToSocializeWildAnimals).map(
                      (status: AccountDtoPreferencesWillingToSocializeWildAnimals) => ({
                        label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="needsBottleFeeding"
                    label="Besoin de biberonnage"
                    error={errors.needsBottleFeeding}
                    options={Object.values(AccountDtoPreferencesNeedsBottleFeeding)
                      .filter((s) => s !== AccountDtoPreferencesNeedsBottleFeeding.NoPreference)
                      .map((status: AccountDtoPreferencesNeedsBottleFeeding) => ({
                        label: getReadableAccountPreferencesNeedsBottleFeeding(status),
                        value: status,
                      }))}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
              </Grid>
              {errorMessage && (
                <Box
                  sx={{
                    padding: 2,
                    color: (theme) => theme.palette.common.black,
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- TODO: Fix template expression
                    border: (theme) => `1px solid ${theme.palette['error'].main}`,
                    borderRadius: (theme) => theme.spacing(0.5),
                    margin: (theme) => theme.spacing(1, 0),
                    fontSize: (theme) => theme.typography.body2.fontSize,
                    background: (theme) => setOpacity(theme.palette['error'].main, 0.2),
                    fontWeight: 700,
                  }}
                >
                  {errorMessage}
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button onClick={dialogOpenedState.closeDialog}>Annuler</Button>
              <Button type="submit" variant="contained" color="primary">
                Valider
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}

export interface PreferencesFormParams {
  specie?: AnimalDtoSpecies
  seniority?: AccountDtoPreferencesSeniority[]
  sex?: AccountDtoPreferencesSex
  sterilizedOrNeutered?: AccountDtoPreferencesSterilizedOrNeutered
  vaccinated?: AccountDtoPreferencesVaccinated
  withMedicalAntecedent?: AccountDtoPreferencesWillingToSocializeWildAnimals
  willingToSocializeWildAnimals?: AccountDtoPreferencesWillingToSocializeWildAnimals
  needsBottleFeeding?: AccountDtoPreferencesNeedsBottleFeeding
}

export const EditAdoptionPreferencePrompt: React.FC<AdoptionPreferenceProps> = ({
  preference,
  setOtherPreferences,
  otherPreferences,
  primaryPreference,
  isOwnAccount,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()

  const submitProcess = (data: PreferencesFormParams) => {
    const newPreference = {
      ...data,
    }

    if (doesPreferenceExist(newPreference, [...otherPreferences, primaryPreference])) {
      console.error('Preference with the same parameters already exists!')
      setErrorMessage('Vous avez déjà une préférence avec ces paramètres !')
      return
    }
    setErrorMessage(undefined)

    setOtherPreferences(
      otherPreferences.map((APreference) => (APreference === preference ? newPreference : APreference))
    )

    return dialogOpenedState.closeDialog()
  }

  const getDefaultValues = (preference: AccountDtoPreferences | null): PreferencesFormParams => ({
    specie: preference?.specie || undefined,
    seniority: preference?.seniority || undefined,
    sex: preference?.sex || undefined,
    sterilizedOrNeutered: preference?.sterilizedOrNeutered || undefined,
    vaccinated: preference?.vaccinated || undefined,
    withMedicalAntecedent: preference?.withMedicalAntecedent || undefined,
    willingToSocializeWildAnimals: preference?.willingToSocializeWildAnimals || undefined,
    needsBottleFeeding: preference?.needsBottleFeeding || undefined,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(preference, getDefaultValues)

  const species = accountsStore.connectedCustomer?.preferences.species

  return (
    <>
      <IconButton
        sx={{ color: 'primary.main' }}
        onClick={dialogOpenedState.openDialog}
        disabled={!isOwnAccount || !accountsStore.isAdmin()}
      >
        <Edit />
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Editer la préférence de prise en charge</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    defaultValue=""
                    fieldName="specie"
                    label="Espèce"
                    chip
                    error={errors.specie}
                    options={
                      species
                        ? Object.values(species).map((status: AnimalDtoSpecies) => ({
                            label: getReadableSpecies(status, { withEmoji: false }),
                            value: status,
                          }))
                        : []
                    }
                    requiredRule="L'espèce est requise"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledMultipleAutoComplete
                    control={control}
                    fieldName="seniority"
                    label="Âge"
                    error={errors.specie}
                    options={Object.values(AccountDtoPreferencesSeniority).map(
                      (status: AccountDtoPreferencesSeniority) => ({
                        label: getReadableAccountPreferencesSeniority(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sex"
                    label="Sexe"
                    error={errors.sex}
                    options={Object.values(AccountDtoPreferencesSex).map((status: AccountDtoPreferencesSex) => ({
                      label: getReadableAccountPreferencesSex(status),
                      value: status,
                    }))}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sterilizedOrNeutered"
                    label="Stérilisé/Castré"
                    error={errors.sterilizedOrNeutered}
                    options={Object.values(AccountDtoPreferencesSterilizedOrNeutered).map(
                      (status: AccountDtoPreferencesSterilizedOrNeutered) => ({
                        label: getReadableAccountPreferencesSterilizedOrNeutered(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="vaccinated"
                    label="Vaccination"
                    error={errors.vaccinated}
                    options={Object.values(AccountDtoPreferencesVaccinated).map(
                      (status: AccountDtoPreferencesVaccinated) => ({
                        label: getReadableAccountPreferencesVaccinated(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="withMedicalAntecedent"
                    label="Possibilité de prendre en charge des animaux malades"
                    error={errors.withMedicalAntecedent}
                    options={Object.values(AccountDtoPreferencesWillingToSocializeWildAnimals).map(
                      (status: AccountDtoPreferencesWillingToSocializeWildAnimals) => ({
                        label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="willingToSocializeWildAnimals"
                    label="Possibilité d'accueil d'animaux sauvages, avec besoin de sociabilisation"
                    error={errors.willingToSocializeWildAnimals}
                    options={Object.values(AccountDtoPreferencesWillingToSocializeWildAnimals).map(
                      (status: AccountDtoPreferencesWillingToSocializeWildAnimals) => ({
                        label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="needsBottleFeeding"
                    label="Besoin de biberonnage"
                    error={errors.needsBottleFeeding}
                    options={Object.values(AccountDtoPreferencesNeedsBottleFeeding)
                      .filter((s) => s !== AccountDtoPreferencesNeedsBottleFeeding.NoPreference)
                      .map((status: AccountDtoPreferencesNeedsBottleFeeding) => ({
                        label: getReadableAccountPreferencesNeedsBottleFeeding(status),
                        value: status,
                      }))}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
              </Grid>
              {errorMessage && (
                <Box
                  sx={{
                    padding: 2,
                    color: (theme) => theme.palette.common.black,
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- TODO: Fix template expression
                    border: (theme) => `1px solid ${theme.palette['error'].main}`,
                    borderRadius: (theme) => theme.spacing(0.5),
                    margin: (theme) => theme.spacing(1, 0),
                    fontSize: (theme) => theme.typography.body2.fontSize,
                    background: (theme) => setOpacity(theme.palette['error'].main, 0.2),
                    fontWeight: 700,
                  }}
                >
                  {errorMessage}
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button onClick={dialogOpenedState.closeDialog}>Annuler</Button>
              <Button type="submit" variant="contained" color="primary">
                Valider
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
