import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { FileCopyOutlined as FileCopyOutlinedIcon } from '@mui/icons-material'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'

interface Props {
  text: string
}

export const CopyToClipboardButton: FC<Props> = ({ text }) => {
  const snackbarStore = useGlobalSnackbarStore()

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text)
    snackbarStore.triggerSuccessMessage('Copié dans le presse-papier.')
  }

  return (
    <Tooltip title="Copier dans le presse-papier">
      <IconButton color="primary" onClick={copyToClipboard}>
        <FileCopyOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}
