import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import { AdoptionFormParams } from './AnimalAdoptionTab'

interface Props {
  isOpen: boolean
  onClose: () => void
  handleSubmit: UseFormHandleSubmit<AdoptionFormParams>
  onSubmit: (data: AdoptionFormParams, confirmation?: boolean) => void
  alreadyExistingAttempt: boolean
  isSameAdopter: boolean
  oldAdopterName?: string
}

export const ForceAdopterAcceptedModal: React.FC<Props> = ({
  isOpen,
  onClose,
  handleSubmit,
  onSubmit,
  alreadyExistingAttempt,
  isSameAdopter,
  oldAdopterName,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      fullWidth
      scroll="paper"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
    >
      <DialogTitle>Êtes vous sûr de vouloir continuer ?</DialogTitle>

      <Box
        sx={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          boxSizing: 'content-box',
        }}
      >
        <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
          {alreadyExistingAttempt ? (
            isSameAdopter ? (
              <span>
                Vous êtes sur le point de finaliser une procédure d’adoption.
                <br />
                Si vous continuez, la procédure auprès de {oldAdopterName} sera considérée comme terminée.
                <br />
                De plus le statut de l’animal sera mis à jour comme adopté.
              </span>
            ) : (
              <span>
                <b>
                  Vous êtes sur le point de suspendre une procédure d’adoption.
                  <br />
                  Si vous continuez, la procédure auprès de {oldAdopterName} sera suspendue.
                </b>
                <br />
                De plus si le profil du nouvel adoptant n&39;est pas validé, son statut sera automatiquement mis à jour.
              </span>
            )
          ) : (
            <span>
              Le profil de l&#39;adoptant n&#39;est pas validé. Si vous souhaitez continuer, son statut sera
              automatiquement mis à jour.
            </span>
          )}
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="outlined" type="button" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="button" onClick={handleSubmit((data) => onSubmit(data, true))}>
            Continuer
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
