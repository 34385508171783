import { AddCircleOutlineOutlined, ArrowDownward, Cancel, CloudDownload } from '@mui/icons-material'
import { Box, Card, CardContent, CircularProgress, Collapse, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useMutation } from 'react-query'
import { backendClient } from '../../../../interactors/clients/client'
import { AnimalDto } from '../../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { getAnimalProfilePhotoSrc } from '../../../../utils/S3-links'
import { setOpacity } from '../../../../utils/setOpacity'
import { blue, colors, spacingItem, theme } from '../../../theme'
import { useAnimalStore } from '../../../../store/AnimalStore'

interface Props {
  animal: AnimalDto
}

export const AnimalOtherImagesCard: React.FC<Props> = ({ animal }) => {
  // Only accept images files type
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: { 'image/*': [] },
    multiple: false,
  })
  const globalSnackbar = useGlobalSnackbarStore()
  const animalStore = useAnimalStore()
  const canEditAnimal = useCanEditAnimal()

  const [pictures, setPictures] = React.useState<string[]>(animal.images?.otherImagesKeys || [])

  const [expanded, setExpanded] = useState(false)

  // Toggle expand/collapse
  const handleToggleExpand = () => {
    setExpanded(!expanded)
  }

  const addImageMutation = useMutation(
    async (file: File) => {
      const formData = new FormData()
      formData.append('file', file)
      return await backendClient.post(`animals/${animal.id}/images/other`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    {
      onSuccess: (response) => {
        globalSnackbar.triggerSuccessMessage(`Votre photo a bien été ajoutée !`)
        const parts = response.data.split('/')
        setPictures((pictures) => [...pictures, parts[parts.length - 1]])
        animalStore.changeSelectedAnimal(animal.id)
      },
    }
  )

  const deleteImageMutation = useMutation(
    async (imageKey: string) => {
      return await backendClient.delete(`animals/${animal.id}/images/other/${imageKey}`)
    },
    {
      onSuccess: (_data, variables) => {
        globalSnackbar.triggerSuccessMessage(`Votre photo a bien été supprimée !`)
        setPictures((pictures) => pictures.filter((picture) => picture !== variables))
      },
    }
  )

  useEffect(() => {
    // Check if there is a file and it's jpg or png
    if (acceptedFiles[0] && (acceptedFiles[0].type === 'image/jpeg' || acceptedFiles[0].type === 'image/png')) {
      addImageMutation.mutate(acceptedFiles[0])
    }
  }, [acceptedFiles])

  return (
    <Card>
      <CardContent sx={{ pt: 0, py: '10px !important' }}>
        <Box
          onClick={handleToggleExpand}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Typography variant="h5" color={colors.blue} fontWeight={700}>
            Autres photos
          </Typography>

          <IconButton>
            <ArrowDownward
              style={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            />
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto">
          {addImageMutation.isLoading ? (
            <CircularProgress sx={{ pt: 2 }} />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                flexWrap: { xs: 'nowrap', md: 'wrap' },
                gap: 2,
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
                pt: 2,
              }}
            >
              {canEditAnimal && (
                <Box sx={{ width: { xs: '100%', md: '23%' } }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    padding={3}
                    {...getRootProps({
                      sx: {
                        ...spacingItem,
                        border: 3,
                        borderRadius: 5,
                        borderStyle: 'dashed',
                        borderColor: isDragActive ? 'green' : blue,
                        backgroundColor: isDragActive
                          ? 'lightgreen'
                          : acceptedFiles[0]
                          ? setOpacity(colors.geyser, 0.4)
                          : 'transparent',
                        transition: 'all 0.3s',
                        cursor: 'pointer',
                      },
                    })}
                  >
                    <input {...getInputProps()} />
                    <AddCircleOutlineOutlined sx={{ color: blue, fontSize: 64, mb: 1 }} />
                    <Typography variant="h4" component="p" fontSize={20} textAlign="center">
                      Ajouter une photo
                    </Typography>
                    <Box
                      marginTop={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1,
                        maxWidth: '100%',
                      }}
                    ></Box>
                  </Box>
                </Box>
              )}

              {pictures.map((imageKey) => (
                <Box
                  sx={{
                    width: { xs: '100%', md: '23%' },
                    position: 'relative',
                    padding: 2,
                    boxShadow: '0px 5px 10px -1px #979797',
                  }}
                  key={imageKey}
                >
                  <img
                    height={220}
                    width="100%"
                    src={getAnimalProfilePhotoSrc(imageKey)}
                    alt={imageKey}
                    style={{ objectFit: 'cover' }}
                  />
                  <IconButton
                    sx={{ position: 'absolute', top: -5, right: -5, color: colors.blueSky, p: 0 }}
                    onClick={() => deleteImageMutation.mutate(imageKey)}
                    disabled={!canEditAnimal}
                  >
                    <Cancel sx={{ fontSize: 32 }} />
                  </IconButton>
                  <IconButton
                    sx={{ position: 'absolute', bottom: 0, right: 2, color: 'primary.main', p: 0 }}
                    aria-label="download"
                    component="a"
                    href={getAnimalProfilePhotoSrc(imageKey)}
                    download
                  >
                    <CloudDownload />
                  </IconButton>
                </Box>
              ))}
              {[1, 2, 3].map(() => (
                <Box
                  key={Math.random()}
                  sx={{
                    width: { xs: '0%', md: '23%' },
                    height: 0,
                  }}
                ></Box>
              ))}
            </Box>
          )}
        </Collapse>
      </CardContent>
    </Card>
  )
}
