import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { blue, colors, spacingItem } from '../../theme'
import { DisplayFunctionalities } from './DisplayFunctionalities'
import { OfferTitle } from './OfferTitle'
import { ChoosePaymentPlanModal } from './ChoosePaymentPlanModal'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { LoadingButton } from '@mui/lab'
import { useMutation } from 'react-query'
import { backendClient } from '../../../interactors/clients/client'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { useAccountsStore } from '../../../store/AccountsStore'
import { isAdmin } from '../../../domain/Account/isAdmin'

const animalFunctionalities = [
  { text: 'Suivi général', status: true },
  { text: 'Suivi de santé', status: true },
  { text: 'Suivi de l’adoption', status: true },
  { text: 'Documents', status: true },
  { text: 'Suivi des déplacement', status: false },
  { text: 'Rappels par email basique', status: true },
]

const memberFunctionalities = [
  { text: 'Général', status: true },
  { text: 'Préférences', status: true },
  { text: 'Disponibilité', status: true },
  { text: 'Documents', status: false },
]

const adoptionFunctionalities = [
  { text: 'Général', status: true },
  { text: 'Envoi du formulaire de pré-adoption', status: true },
  { text: 'Lancement de la procédure d’adoption', status: true },
]

const supportFunctionalities = [
  { text: 'Tableau de bord basique', status: true },
  { text: 'Suivi des dons', status: false },
  { text: 'Lieux et emplacements', status: false },
]

const fileExportFunctionalities = [
  { text: 'Export du registre Entrée-Sortie (.pdf)', status: true },
  { text: 'Export Fichier animal (.xlxs)', status: true },
  { text: 'Export fiche animal unique (.pdf)', status: false },
  { text: 'Export fichier pour cession I-CAD .(xlxs)', status: false },
]

interface RenewSubscriptionData {
  stripeCustomerId: string
}

interface PortalSessionData {
  customerId: string
}

interface Props {
  customer: CustomerDto | null
}

export const EssentialOffer: React.FC<Props> = ({ customer }) => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()
  const currentUserIsAdmin = accountsStore.connectedAccount ? isAdmin(accountsStore.connectedAccount) : false
  const renewMutation = useMutation(
    (data: RenewSubscriptionData) => backendClient.post('/stripe/reactivate-subscription', data),
    {
      onSuccess: () => {
        globalSnackbarStore.triggerSuccessMessage('Votre abonnement a bien été renouvelé')
      },
      onError: (error) => {
        // Handle error.
        console.error('Error Creating Checkout Session:', error)
      },
    }
  )

  const dashboardMutation = useMutation(
    (data: PortalSessionData) => backendClient.post('/stripe/create-portal-session-customer-id', data),
    {
      onSuccess: (data) => {
        window.location.href = data.data
      },
      onError: (error) => {
        // Handle error.
        console.error('Error Creating Portal Session:', error)
      },
    }
  )

  const handleClick = async () => {
    if (hasCanceledSubscription && customer?.stripeCustomerId) {
      try {
        const { data: session } = await renewMutation.mutateAsync({ stripeCustomerId: customer.stripeCustomerId })
        console.log('Subscription renewed:', session)
      } catch (error) {
        // Handle error
        console.error('Error Renewing Subscription:', error)
      }
    } else if (isSubscribed) {
      const customerId = customer?.stripeCustomerId || ''

      try {
        const { data: session } = await dashboardMutation.mutateAsync({ customerId })
        console.log('Portal Session Created:', session)
      } catch (error) {
        // Handle error
        console.error('Error Creating Portal Session:', error)
      }
    }
  }

  const hasEssentialPlus = customer?.subscription?.plan === 'essential'

  const isSubscribed = customer?.subscription?.status === 'active'

  const hasCanceledSubscription = customer?.subscription?.status === 'canceled'

  return (
    <Card sx={{ px: 2 }}>
      <CardContent>
        <OfferTitle
          title="L'essentiel +"
          price="15 euros / mois ht"
          priceSubtitle="Nombres d'utilisateurs illimités"
          essentialOffer
        />
        <Typography
          variant="h5"
          fontStyle="italic"
          color={isSubscribed ? colors.bermudaGray : 'black'}
          fontFamily={isSubscribed ? 'Insaniburger' : 'inherit'}
          fontSize={16}
          sx={{ ...spacingItem, textAlign: 'center', height: { xs: '100%', md: '40px' } }}
        >
          {hasEssentialPlus
            ? hasCanceledSubscription
              ? `Vous avez annulé votre abonnement, il prendra fin le ${
                  customer.subscription?.expiresAt
                    ? new Date(customer.subscription?.expiresAt).toLocaleDateString('fr-FR')
                    : ''
                }`
              : 'Vous possédez cette offre'
            : 'Aller plus loin et gagner un temps précieux dans la gestion quotidienne de votre association/refuge'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: 2,
            gap: 1,
          }}
        >
          {hasEssentialPlus ? (
            <Tooltip title={!currentUserIsAdmin ? "Seuls les administrateurs peuvent gérer l'abonnement" : ''}>
              <span>
                <LoadingButton
                  onClick={() => handleClick()}
                  variant="contained"
                  loading={renewMutation.isLoading || dashboardMutation.isLoading}
                  sx={{
                    ...spacingItem,
                    fontWeight: 400,
                    fontSize: 24,
                    color: 'white',
                    width: '350px',
                    fontFamily: 'Insaniburger',
                  }}
                  disabled={!currentUserIsAdmin}
                >
                  {hasCanceledSubscription ? 'Renouveler mon offre' : 'Gérer mon abonnement'}
                </LoadingButton>
              </span>
            </Tooltip>
          ) : (
            <ChoosePaymentPlanModal
              offerName="essentiel +"
              prices={['15', '150']}
              priceIds={['price_1O4JLQKwIFnHkxNPsRR2QmjR', 'price_1O4JMFKwIFnHkxNPuoJnMf8S']}
              disabled={!currentUserIsAdmin}
            />
          )}
          <Typography sx={{ color: blue }}>150 euros/an soit 15% de remise</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
          <DisplayFunctionalities title="Suivi animal" functionalities={animalFunctionalities} />
          <DisplayFunctionalities title="Suivi des membres" functionalities={memberFunctionalities} />
          <DisplayFunctionalities title="Suivi des adoptions" functionalities={adoptionFunctionalities} />
          <DisplayFunctionalities title="Fonctionnalités supports" functionalities={supportFunctionalities} />
          <DisplayFunctionalities title="Exports de fichiers" functionalities={fileExportFunctionalities} />
        </Box>
      </CardContent>
    </Card>
  )
}
