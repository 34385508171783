import React, { createContext, useState } from 'react'

interface ScrollContextType {
  scrollPositions: Record<string, number>
  setScrollPosition: (id: string, position: number) => void
}

export const ScrollContext = createContext<ScrollContextType>({
  scrollPositions: {},
  setScrollPosition: (id: string, position: number) => {
    // This is a placeholder function and should be overridden by the provider.
    console.warn('setScrollPosition called without a proper implementation.', id, position)
  },
})

interface ScrollProviderProps {
  children: React.ReactNode
}

export const ScrollProvider: React.FC<ScrollProviderProps> = ({ children }) => {
  const [scrollPositions, setScrollPositions] = useState<Record<string, number>>({})

  const setScrollPosition = (id: string, position: number) => {
    setScrollPositions((prevPositions) => ({
      ...prevPositions,
      [id]: position,
    }))
  }

  return <ScrollContext.Provider value={{ scrollPositions, setScrollPosition }}>{children}</ScrollContext.Provider>
}
