import { CreateDonorBodyDto, Donor } from '../gen/backendClient'
import { backendClient } from './client'
import { AxiosRequestConfig } from 'axios'

export class DonatersClient {
  public static async createDonater(params: Omit<CreateDonorBodyDto, 'customerId'>): Promise<Donor> {
    const config: AxiosRequestConfig<Omit<CreateDonorBodyDto, 'customerId'>> = {
      method: 'POST',
      url: '/donors',
      data: params,
    }

    const response = await backendClient<CreateDonorBodyDto>(config)

    return response.data as Donor
  }

  public static async getAllDonaters(): Promise<Array<Donor>> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/donors',
    }

    const response = await backendClient<Array<Donor>>(config)

    return response.data
  }

  public static async editDonater(params: Omit<CreateDonorBodyDto, 'customerId'>): Promise<Donor> {
    const config: AxiosRequestConfig<Omit<CreateDonorBodyDto, 'customerId'>> = {
      method: 'PUT',
      url: '/donors',
      data: {
        ...params,
      },
    }

    const response = await backendClient<Donor>(config)

    return response.data
  }
}
