export const isColorDark = (color: string) => {
  // Remove '#' if present
  color = color.replace('#', '')

  // Expand 3 character hex code to 6 character format
  if (color.length === 3) {
    color = color
      .split('')
      .map((c) => c + c)
      .join('')
  }

  // Check if the length is still not 6 characters, then return false
  if (color.length !== 6) {
    return false
  }

  // Use regular expression to match hex format
  const matches = color.match(/\w\w/g)
  if (!matches || matches.length !== 3) {
    return false
  }

  // Convert hex values to decimal and calculate luminance
  const [r, g, b] = matches.map((c) => parseInt(c, 16))
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b

  // Determine if the color is dark
  return luminance < 128
}
