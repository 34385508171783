import { Check, Close } from '@mui/icons-material'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import React from 'react'
import { theme } from '../../theme'

interface Props {
  title: string
  functionalities: { text: string; status: boolean; gray?: boolean }[]
}

export const DisplayFunctionalities: React.FC<Props> = ({ title, functionalities }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" color={blue} fontWeight={700} fontSize={20} sx={{ fontFamily: 'Insaniburger' }}>
        {title}
      </Typography>
      <List>
        {functionalities.map((functionality) => (
          <ListItem key={functionality.text} sx={{ p: 0, pl: 1 }}>
            <ListItemIcon>
              {functionality.status ? (
                <Check sx={{ color: functionality.gray ? 'grey' : theme.palette.primary.main }} />
              ) : (
                <Close sx={{ color: blue }} />
              )}
            </ListItemIcon>
            <ListItemText primary={functionality.text} sx={{ color: functionality.gray ? 'grey' : 'black' }} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
