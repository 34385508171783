import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { blue, colors, spacingItem } from '../../../theme'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import {
  AnimalDocumentDtoType,
  UploadAnimalDocumentDto,
  UploadAnimalDocumentDtoAttributes,
} from '../../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { useDropzone } from 'react-dropzone'
import { backendClient } from '../../../../interactors/clients/client'
import { Add, UploadFile } from '@mui/icons-material'
import { ControlledAutoCompleteWithCustomValue } from '../../../common/ControlledAutocompleteWithCustomValue'
import { getColorForAnimalDocumentType, getReadableAnimalDocumentType } from '../../../../domain/Animal/AnimalDisplay'
import { setOpacity } from '../../../../utils/setOpacity'
import { useAnimalStore } from '../../../../store/AnimalStore'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'

type ExtendedUploadAnimalDocumentDto = UploadAnimalDocumentDto & {
  requiredForRegisterEntry?: boolean
  requiredForRegisterExit?: boolean
}

interface Props {
  animalId: string
  disabled?: boolean
}

export const AddDocumentModal: React.FC<Props> = ({ animalId, disabled }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone()
  const animalStore = useAnimalStore()
  const canEditAnimal = useCanEditAnimal()

  const mutation = useMutation(
    async ({ data, file }: { data: ExtendedUploadAnimalDocumentDto; file: File }) => {
      const formData = new FormData()
      formData.append('file', file)
      if (data.type !== undefined) {
        formData.append('type', data.type)
      }

      if (data.name !== undefined) {
        formData.append('name', data.name)
      }

      if (data.reference !== undefined) {
        formData.append('reference', data.reference)
      }

      data.attributes.forEach((value) => {
        formData.append('attributes[]', value)
      })
      return await backendClient.post(`animals/${animalId}/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    {
      onSuccess: () => {
        closeDialog()
        globalSnackbar.triggerSuccessMessage(`Votre document a bien été ajouté !`)
        animalStore.changeSelectedAnimal(animalId)
      },
    }
  )

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<ExtendedUploadAnimalDocumentDto>({
    defaultValues: {
      type: undefined,
      name: '',
      reference: '',
      requiredForRegisterEntry: false,
      requiredForRegisterExit: false,
    },
    mode: 'onChange',
  })

  const onSubmit = (data: ExtendedUploadAnimalDocumentDto) => {
    const attributes = []
    if (data.requiredForRegisterEntry) {
      attributes.push('required-for-the-register-entry')
    }
    if (data.requiredForRegisterExit) {
      attributes.push('required-for-the-register-exit')
    }
    delete data.requiredForRegisterEntry
    delete data.requiredForRegisterExit

    const uploadData: UploadAnimalDocumentDto = {
      ...data,
      attributes: attributes as UploadAnimalDocumentDtoAttributes[],
    }

    if (acceptedFiles[0]) {
      mutation.mutate({ data: uploadData, file: acceptedFiles[0] })
    } else {
      globalSnackbar.triggerErrorMessage(`Vous devez ajouter un fichier !`)
    }
  }

  const type = watch('type')

  if (!canEditAnimal) {
    return null
  }

  return (
    <>
      <Fab color="primary" variant="extended" onClick={openDialog} disabled={disabled}>
        <Add sx={{ mr: 1 }} />
        Ajouter un document
      </Fab>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Ajouter un document
              </Typography>

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'type'}
                options={Object.values(AnimalDocumentDtoType).map((type: AnimalDocumentDtoType) => ({
                  label: getReadableAnimalDocumentType(type),
                  value: type,
                  color: getColorForAnimalDocumentType(type),
                }))}
                label="Type de document"
                error={errors.type}
                requiredRule={'true'}
                defaultValue={''}
                size="small"
                chip={true}
              />

              {type === 'others' && (
                <TextField
                  label="Nom du document"
                  type="text"
                  fullWidth
                  size="small"
                  required
                  {...register('name', { required: 'Le nom est requis' })}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  sx={spacingItem}
                />
              )}
              <TextField
                label="Libellé"
                {...register('reference')}
                error={!!errors.reference}
                helperText={errors.reference?.message}
                type="text"
                fullWidth
                size="small"
                sx={spacingItem}
              />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox {...register('requiredForRegisterEntry')} />}
                  label="Justificatif nécessaire au registre d'entrée"
                  sx={spacingItem}
                />
                <FormControlLabel
                  control={<Checkbox {...register('requiredForRegisterExit')} />}
                  label="Justificatif nécessaire au registre de sortie"
                />
              </FormGroup>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding={3}
                {...getRootProps({
                  sx: {
                    ...spacingItem,
                    border: 3,
                    borderRadius: 5,
                    borderStyle: 'dashed',
                    borderColor: isDragActive ? 'green' : blue,
                    backgroundColor: isDragActive
                      ? 'lightgreen'
                      : acceptedFiles[0]
                      ? setOpacity(colors.geyser, 0.4)
                      : 'transparent',
                    transition: 'all 0.3s',
                  },
                })}
              >
                <input {...getInputProps()} />
                <UploadFile sx={{ color: blue, fontSize: 64, mb: 1 }} />
                <Typography variant="h4" component="p" fontSize={20} textAlign="center">
                  Glissez-déposer ou
                  <br />
                  Insérer un document
                </Typography>
                <Box
                  marginTop={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    maxWidth: '100%',
                  }}
                >
                  <Typography variant="h4" component="p" fontSize={18} textAlign="center" sx={{ whiteSpace: 'nowrap' }}>
                    Votre document:
                  </Typography>
                  <Typography
                    variant="h4"
                    component="p"
                    fontSize={18}
                    textAlign="center"
                    color={colors.black}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                    }}
                  >
                    {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                  </Typography>
                </Box>
                <Button variant="contained" sx={{ width: '40%', mt: 1 }}>
                  Parcourir
                </Button>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton data-testid="apply-changes" variant="contained" type="submit" loading={mutation.isLoading}>
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
