import { DescriptionOutlined } from '@mui/icons-material'
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { blueSky } from '../../theme'

export const DefaultTemplateItem: React.FC = () => {
  const navigate = useNavigate()

  const handleDefaultTemplateClick = () => {
    // Navigate to the template creation page with the default template as the base
    navigate('/association/template', { state: { isDefaultTemplate: true } })
  }

  return (
    <Card
      sx={{
        maxWidth: 300,
        width: '100%',
        borderRadius: 2,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s',
        position: 'relative',
        overflow: 'visible',
        backgroundColor: '#f5f5f5', // Different background to distinguish it
        border: '1px solid #ccc', // Different border color
      }}
    >
      <CardActionArea
        onClick={handleDefaultTemplateClick}
        sx={{
          px: 2,
          pb: 0,
          position: 'relative',
          '&:hover .description-icon': {
            opacity: 0.5,
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: 150 }}>
          <DescriptionOutlined sx={{ fontSize: 50, color: blueSky }} />
        </Box>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" component="div" noWrap>
            Modèle de contrat Petso
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Utilisez ce modèle comme base pour créer votre propre contrat
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Toujours disponible
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
