import { styled } from '@mui/material/styles'
import { ReactElement } from 'react'
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemProps,
  ListItemText,
  ListItemSecondaryAction,
  SvgIcon,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/CancelOutlined'

// const arePropsEqual = (prevProps: FilterListItemProps, nextProps: FilterListItemProps) =>
//   prevProps.label === nextProps.label && shallowEqual(prevProps.value, nextProps.value)

/**
 * Button to enable/disable a list filter.
 *
 * Expects 2 props:
 *
 * - label: The text (or React element) to be displayed for this item.
 *   If it's a string, the component will translate it.
 * - value: An object to be merged into the filter value when enabling the filter
 *   (e.g. { is_published: true, published_at_gte: '2020-07-08' })
 *
 * @example
 *
 * import * as React from 'react';
 * import { Card, CardContent } from '@mui/material';
 * import MailIcon from '@mui/icons-material/MailOutline';
 * import { FilterList, FilterListItem } from 'react-admin';
 *
 * const FilterSidebar = () => (
 *     <Card>
 *         <CardContent>
 *             <FilterList
 *                 label="Subscribed to newsletter"
 *                 icon={<MailIcon />}
 *             >
 *                 <FilterListItem
 *                     label="Yes"
 *                     value={{ has_newsletter: true }}
 *                  />
 *                 <FilterListItem
 *                     label="No"
 *                     value={{ has_newsletter: false }}
 *                  />
 *             </FilterList>
 *         </CardContent>
 *     </Card>
 * );
 *
 * @example // The value prop can contain multiple keys
 *
 * import * as React from 'react';
 * import {
 *     endOfYesterday,
 *     startOfWeek,
 *     subWeeks,
 *     startOfMonth,
 *     subMonths,
 * } from 'date-fns';
 * import { Card, CardContent } from '@mui/material';
 * import AccessTimeIcon from '@mui/icons-material/AccessTime';
 * import { FilterList, FilterListItem } from 'react-admin';
 *
 * const FilterSidebar = () => (
 *     <Card>
 *         <CardContent>
 *             <FilterList
 *                 label="Last visited"
 *                 icon={<AccessTimeIcon />}
 *             >
 *                 <FilterListItem
 *                     label="Today"
 *                     value={{
 *                         last_seen_gte: endOfYesterday().toISOString(),
 *                         last_seen_lte: undefined,
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="This week"
 *                     value={{
 *                         last_seen_gte: startOfWeek(
 *                             new Date()
 *                         ).toISOString(),
 *                         last_seen_lte: undefined,
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="Last week"
 *                     value={{
 *                         last_seen_gte: subWeeks(
 *                             startOfWeek(new Date()),
 *                             1
 *                         ).toISOString(),
 *                         last_seen_lte: startOfWeek(
 *                             new Date()
 *                         ).toISOString(),
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="This month"
 *                     value={{
 *                         last_seen_gte: startOfMonth(
 *                             new Date()
 *                         ).toISOString(),
 *                         last_seen_lte: undefined,
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="Last month"
 *                     value={{
 *                         last_seen_gte: subMonths(
 *                             startOfMonth(new Date()),
 *                             1
 *                         ).toISOString(),
 *                         last_seen_lte: startOfMonth(
 *                             new Date()
 *                         ).toISOString(),
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="Earlier"
 *                     value={{
 *                         last_seen_gte: undefined,
 *                         last_seen_lte: subMonths(
 *                             startOfMonth(new Date()),
 *                             1
 *                         ).toISOString(),
 *                     }}
 *                 />
 *             </FilterList>
 *         </CardContent>
 *     </Card>
 * );
 */

export const FilterListItem = (props: FilterListItemProps) => {
  const { label, isSelected, toggleSelected, color, icon: CustomIcon, ...rest } = props

  const toggleFilter = () => toggleSelected()

  return (
    <StyledListItem onClick={toggleFilter} selected={isSelected} disablePadding {...rest}>
      {color && <div style={{ width: 15, height: 15, backgroundColor: color, borderRadius: '50%' }}></div>}
      <ListItemButton
        disableGutters
        className={FilterListItemClasses.listItemButton}
        sx={{ maxWidth: 'calc(100% - 15px)' }}
      >
        <ListItemText
          primary={label}
          sx={{ color: 'grey.800' }}
          className={FilterListItemClasses.listItemText}
          data-selected={isSelected ? 'true' : 'false'}
        />
        {isSelected && (
          <ListItemSecondaryAction
            onClick={(event) => {
              event.stopPropagation()
              toggleFilter()
            }}
          >
            <IconButton size="small">{CustomIcon ? <CustomIcon /> : <CancelIcon />}</IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItemButton>
    </StyledListItem>
  )
}

const PREFIX = 'RaFilterListItem'

export const FilterListItemClasses = {
  listItemButton: `${PREFIX}-listItemButton`,
  listItemText: `${PREFIX}-listItemText`,
}

const StyledListItem = styled(ListItem, {
  name: PREFIX,
  overridesResolver: (_, styles) => styles.root,
})(() => ({
  [`& .${FilterListItemClasses.listItemButton}`]: {
    paddingRight: '2em',
    paddingLeft: '2em',
  },
  [`& .${FilterListItemClasses.listItemText}`]: {
    margin: 0,
  },
}))

export interface FilterListItemProps extends Omit<ListItemProps, 'value'> {
  label: string | ReactElement
  isSelected: boolean
  toggleSelected: () => void
  color?: string
  icon?: typeof SvgIcon // Type for MUI Icons
}
