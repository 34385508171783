import { Box, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateAndTimeField } from '../../common/ControlledDateAndTimeField'
import { StepsProps } from './StepOne'
import { AccountDtoRoles } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { blue } from '../../theme'
import { useFetchOrRefreshMembersOnMount } from '../../../store/useFetchOrRefreshMembersOnMount'

export const StepFour: React.FC<StepsProps> = ({ control, register }) => {
  useFetchOrRefreshMembersOnMount()
  const accountsStore = useAccountsStore()
  const allMembers = accountsStore.members.filter((account) => account.roles.includes(AccountDtoRoles.Member))
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="body2" sx={{ color: blue, fontSize: 20, fontWeight: 700 }}>
        Remplissez les informations de la rencontre avec l&#39;animal/adoptant
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item sm={6} xs={12}>
          <ControlledDateAndTimeField
            control={control}
            fieldName="date"
            label="Date et heure"
            size="small"
            validate={(value) => isValidPastDateCoherence(value) || value === null}
            requiredRule="La date et l'heure sont requis"
            error={undefined}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <ControlledAutoCompleteWithCustomValue
            control={control}
            fieldName="memberInCharge"
            label="Membre en charge"
            size="small"
            options={allMembers
              .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
              .map((option) => ({ label: fullName(option), value: option.id }))}
            requiredRule="Le membre en charge est requis"
            error={undefined}
            defaultValue=""
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            id="description-input"
            label="Commentaires"
            type="text"
            fullWidth
            {...register('details')}
            size="small"
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  )
}
