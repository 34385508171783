import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Box } from '@mui/material'
import { FC } from 'react'
import { useMutation } from 'react-query'
import { backendClient } from '../../../interactors/clients/client'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { Delete } from '@mui/icons-material'
import { CustomerDocumentDto } from '../../../interactors/gen/backendClient'

interface Props {
  documentId: string
  setDocumentsList?: React.Dispatch<React.SetStateAction<CustomerDocumentDto[]>>
  disabled?: boolean
}

export const DeleteDocumentModal: FC<Props> = ({ documentId, setDocumentsList, disabled = false }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()

  // Mutation to delete the document
  const mutation = useMutation(async () => await backendClient.delete(`customers/documents/${documentId}`), {
    onSuccess: () => {
      globalSnackbar.triggerSuccessMessage(`Le document a bien été supprimé !`)
      setDocumentsList?.((prev) => prev.filter((document) => document.id !== documentId))
    },
  })

  return (
    <>
      <Button sx={{ color: 'error.main' }} onClick={() => openDialog()} disabled={disabled}>
        <Delete />
      </Button>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>
          <Box marginBottom={1}>Voulez vous vraiment supprimer ce document ?</Box>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel" onClick={closeDialog}>
            Annuler
          </Button>
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            onClick={() => mutation.mutate()}
            sx={{ backgroundColor: 'error.main' }}
            loading={mutation.isLoading}
          >
            Supprimer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
