import { Box, Button, Card, CardContent, CardHeader, Grid, SxProps, TextField, Theme, Typography } from '@mui/material'
import { FC, useEffect } from 'react'

import dayjs from 'dayjs'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import { getReadableAnimalDogCategory, getReadableAnimalHealthPathology } from '../../../../domain/Animal/AnimalDisplay'
import {
  CatPathologies,
  DogPathologies,
  FerretPathologies,
  GlobalPathologies,
} from '../../../../domain/Animal/AnimalPathologies'
import {
  AccountDto,
  AccountDtoRoles,
  AnimalDto,
  AnimalDtoSpecies,
  AnimalHealthDtoDogCategory,
  AnimalHealthDtoFIV,
  AnimalHealthDtoFeLV,
  AnimalHealthDtoHasEuropeanPassport,
  AnimalHealthDtoPathologies,
  AnimalHealthTestResultDtoType,
  CreateAnimalBodyDtoVaccinationStatus,
} from '../../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { TrimManager } from '../../../../utils/TrimManager'
import { getColorForBooleanOrUnknown, getReadableBooleanOrUnknown } from '../../../../utils/getReadableBooleanOrUnknown'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { isInEnum } from '../../../../utils/isInEnum'
import { omitEmptyString } from '../../../../utils/omitEmptyString'
import { PATHS } from '../../../PATHS'
import { ColoredChipSelectField } from '../../../common/ColoredChipSelectField'
import { ControlledAutoCompleteWithCustomValue } from '../../../common/ControlledAutocompleteWithCustomValue'
import { ControlledMultipleAutoComplete } from '../../../common/ControlledMultipleAutoComplete'
import { ControlledSelectField } from '../../../common/ControlledSelectField'
import { ReadOnlyItem } from '../../../common/ReadOnlyItem'
import { AnimalCardSaveButton } from '../AnimalCardSaveButton'
import { EditorPrompt } from '../EditorPrompt'
import { nanoid } from 'nanoid'

interface Props {
  animal: AnimalDto
  sx?: SxProps<Theme>
}

type FormParams = {
  vaccinationStatus: CreateAnimalBodyDtoVaccinationStatus | ''
  vaccinationType: string
  firstTCInjectionAt: string // DateString
  secondTCInjectionAt: string // DateString
  thirdTCInjectionAt: string // DateString
  veterinaryChargeAccountId: string | null
  FeLV: AnimalHealthDtoFeLV | ''
  FIV: AnimalHealthDtoFIV | ''
  hasEuropeanPassport?: AnimalHealthDtoHasEuropeanPassport
  dogCategory?: AnimalHealthDtoDogCategory
  pathologies: AnimalHealthDtoPathologies[]
  alimentation?: string
}

export const AnimalHealthCard: FC<Props> = ({ animal, sx }) => {
  const queryParams = new URLSearchParams(location.search)
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()
  const canEditAnimal = useCanEditAnimal()

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    vaccinationStatus: animal.vaccination.vaccinationStatus || '',
    vaccinationType: animal.vaccination.vaccinationType || '',
    firstTCInjectionAt: animal.vaccination.firstInjection?.date || '',
    secondTCInjectionAt: animal.vaccination.secondInjection?.date || '',
    thirdTCInjectionAt: animal.vaccination.thirdInjection?.date || '',
    veterinaryChargeAccountId: animal.responsibilities?.veterinaryChargeAccountId || '',
    FeLV: animal.health?.FeLV || '',
    FIV: animal.health?.FIV || '',
    hasEuropeanPassport: animal.health?.hasEuropeanPassport || undefined,
    dogCategory: animal.health?.dogCategory || undefined,
    pathologies: animal.health?.pathologies || [],
    alimentation: animal.health?.alimentation || '',
  })

  const { control, register, handleSubmit, watch, setValue, reset, formState } = useFormExtended(
    animal,
    getDefaultValues
  )

  const options = accountsStore.members.filter((account) => account.roles.includes(AccountDtoRoles.Veterinary))

  const initialVeterinaryOption = options.find(
    (member) => member.id === animal.responsibilities?.veterinaryChargeAccountId
  )

  useEffect(() => {
    setValue('veterinaryChargeAccountId', initialVeterinaryOption?.id || null)
  }, [initialVeterinaryOption?.id])

  const watchVeterinary = watch('veterinaryChargeAccountId')

  const editAnimalMutation = useEditAnimalMutation({})

  const onSubmit = async (data: Partial<FormParams>) => {
    const newPathologies = data.pathologies?.filter((pathology) => !animal.health?.pathologies?.includes(pathology))
    const deletedPathologies = animal.health?.pathologies?.filter((pathology) => !data.pathologies?.includes(pathology))
    const oldOperations = animal.health?.operations || []
    if (deletedPathologies && deletedPathologies.length > 0) {
      deletedPathologies
        .filter((pathology) => isInEnum(AnimalHealthTestResultDtoType, pathology))
        .forEach((pathology) => {
          oldOperations
            .sort((a, b) => dayjs(b.date, 'DD/MM/YYYY').diff(dayjs(a.date, 'DD/MM/YYYY')))
            .find((operation) => operation.type.includes(pathology as AnimalHealthTestResultDtoType))
            ?.testResults?.forEach((testResult) => {
              if (testResult.type === pathology) {
                testResult.result = 'unknown'
              }
            })
        })
    }
    const newOperations = oldOperations
    if (newPathologies && newPathologies.length > 0) {
      newPathologies
        .filter((pathology) => pathology !== AnimalHealthTestResultDtoType.Coryza)
        .filter((pathology) => isInEnum(AnimalHealthTestResultDtoType, pathology))
        .forEach((pathology) => {
          newOperations.push({
            id: nanoid(),
            date: '',
            type: [pathology as AnimalHealthTestResultDtoType],
            testResults: [
              {
                type: pathology as AnimalHealthTestResultDtoType,
                result: 'positive',
              },
            ],
          })
        })
    }

    const update = omitEmptyString({
      ...animal,
      health: {
        ...animal.health,
        FIV: data.FIV as AnimalHealthDtoFIV,
        FeLV: data.FeLV as AnimalHealthDtoFeLV,
        hasEuropeanPassport: data.hasEuropeanPassport,
        dogCategory: animal.species === AnimalDtoSpecies.Dog ? data.dogCategory : undefined,
        pathologies: data.pathologies,
        operations: newOperations,
        alimentation: data.alimentation,
      },
      responsibilities: {
        ...animal.responsibilities,
        veterinaryChargeAccountId:
          data.veterinaryChargeAccountId || animal.responsibilities?.veterinaryChargeAccountId || undefined,
      },
    })

    await editAnimalMutation.mutateAsync(update)
  }

  const onEditHealthHistory = async (newHealthHistory: string) => {
    const update = { ...animal, health: { ...animal.health, healthHistory: newHealthHistory } }
    await editAnimalMutation.mutate(update)
  }

  const getOptionLabel = (option: AccountDto): string => {
    let optionLabel = fullName(option)
    if (option.id === accountsStore.connectedAccount?.id) {
      optionLabel = `Moi (${optionLabel})`
    }

    return optionLabel
  }

  useEffect(() => {
    if (queryParams.get('tab') === 'sante' && queryParams.get('card') === 'animalHealthCard') {
      const newVeterinary = queryParams.get('veterinary')

      if (newVeterinary !== undefined && newVeterinary !== null) {
        setValue('veterinaryChargeAccountId', newVeterinary || '')
        handleSubmit(onSubmit)()
      }
    }
  }, [])

  useEffect(() => {
    if (watchVeterinary === 'addAccount') {
      reset({ veterinaryChargeAccountId: null })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=sante&role=${AccountDtoRoles.Veterinary}&card=animalHealthCard`
      )
    }
  }, [watchVeterinary])

  const allPathologies = Object.values(AnimalHealthDtoPathologies)

  function filterPathologiesForSpecies(animalSpecies: AnimalDtoSpecies) {
    let filterOutPathologies: Partial<AnimalHealthDtoPathologies[]> = []
    switch (animalSpecies) {
      case AnimalDtoSpecies.Dog:
        filterOutPathologies = CatPathologies.concat(FerretPathologies).filter(
          (pathology) => !DogPathologies.includes(pathology)
        )
        break
      case AnimalDtoSpecies.Cat:
        filterOutPathologies = DogPathologies.concat(FerretPathologies).filter(
          (pathology) => !CatPathologies.includes(pathology)
        )
        break
      default:
        filterOutPathologies = DogPathologies.concat(CatPathologies)
          .concat(FerretPathologies)
          .filter((pathology) => !GlobalPathologies.includes(pathology))
        break
    }

    // Include all operation types except those specifically filtered out for the species
    return allPathologies.filter((type) => !filterOutPathologies.includes(type))
  }

  const speciesPathologies = filterPathologiesForSpecies(animal.species)

  return (
    <>
      <Card sx={{ ...sx, height: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <CardHeader title="🏥 Santé" />
          <CardContent sx={{ height: '100%' }}>
            <Grid container spacing={{ md: 1, xs: 2 }}>
              <Grid item xs={12} sm={3}>
                <ControlledAutoCompleteWithCustomValue
                  control={control}
                  fieldName={'veterinaryChargeAccountId'}
                  defaultValue=""
                  size="small"
                  options={[
                    { value: 'addAccount', label: 'Ajouter un vétérinaire' },
                    ...(accountsStore.connectedAccount &&
                    accountsStore.connectedAccount?.roles.includes(AccountDtoRoles.Veterinary)
                      ? [
                          {
                            value: accountsStore.connectedAccount?.id,
                            label: getOptionLabel(accountsStore.connectedAccount),
                          },
                        ]
                      : []),
                    ...options
                      .filter((option) => option.id !== accountsStore.connectedAccount?.id)
                      .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                      .map((option) => ({
                        label: getOptionLabel(option),
                        value: option.id,
                      })),
                  ]}
                  label="Vétérinaire en charge"
                  error={undefined}
                  requiredRule={undefined}
                  highlight
                />
              </Grid>

              <Grid item xs={12} sm={3.5}>
                <Box
                  sx={{
                    borderLeft: '2px grey solid',
                    borderLeftColor: 'grey.200',
                    paddingLeft: 2,
                    boxSizing: 'border-box',
                  }}
                >
                  <ReadOnlyItem
                    label="Email"
                    value={options.find((member) => member.id === watchVeterinary)?.email || ''}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={5.5}>
                {animal.species === AnimalDtoSpecies.Dog && (
                  <ControlledSelectField
                    control={control}
                    fieldName="dogCategory"
                    label="Chien de catégorie"
                    options={Object.values(AnimalHealthDtoDogCategory).map((value: AnimalHealthDtoDogCategory) => ({
                      label: getReadableAnimalDogCategory(value),
                      value: value,
                    }))}
                    size="small"
                    error={undefined}
                    requiredRule=""
                  />
                )}
                {/* {animal.species === AnimalDtoSpecies.Cat && (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mx: { md: 2, xs: 0 }, gap: 4 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                        borderLeft: '2px grey solid',
                        borderLeftColor: 'grey.200',
                        paddingLeft: 1,
                        boxSizing: 'border-box',
                      }}
                    >
                      <Typography
                        variant={'body2'}
                        color="textSecondary"
                        sx={{
                          lineHeight: '0.875rem',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        Test FeLV
                      </Typography>
                      <Chip
                        label={animal.health?.FeLV ? getReadableAnimalHealthTestStatus(animal.health?.FeLV) : 'Inconnu'}
                        sx={{
                          backgroundColor: setOpacity(
                            animal.health?.FeLV ? getColorForAnimalHealthTestStatus(animal.health?.FeLV) : colors.amber,
                            0.8
                          ),
                          color: 'white',
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                        borderLeft: '2px grey solid',
                        borderLeftColor: 'grey.200',
                        paddingLeft: 1,
                        boxSizing: 'border-box',
                      }}
                    >
                      <Typography
                        variant={'body2'}
                        color="textSecondary"
                        sx={{
                          lineHeight: '0.875rem',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        Test FIV
                      </Typography>
                      <Chip
                        label={animal.health?.FIV ? getReadableAnimalHealthTestStatus(animal.health?.FIV) : 'Inconnu'}
                        sx={{
                          backgroundColor: setOpacity(
                            animal.health?.FIV ? getColorForAnimalHealthTestStatus(animal.health?.FIV) : colors.amber,
                            0.8
                          ),
                          color: 'white',
                        }}
                      />
                    </Box>
                  </Box>
                )} */}
              </Grid>

              <Grid item xs={12} sm={5}>
                <ColoredChipSelectField
                  control={control}
                  fieldName="hasEuropeanPassport"
                  label="Détention d'un passeport européen"
                  options={Object.values(AnimalHealthDtoHasEuropeanPassport).map(
                    (value: AnimalHealthDtoHasEuropeanPassport) => ({
                      label: getReadableBooleanOrUnknown(value),
                      color: getColorForBooleanOrUnknown(value),
                      value: value,
                    })
                  )}
                  size="small"
                  error={undefined}
                  requiredRule=""
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <ControlledMultipleAutoComplete
                control={control}
                fieldName="pathologies"
                label="Pathologies"
                options={speciesPathologies
                  .sort((a, b) =>
                    getReadableAnimalHealthPathology(a).localeCompare(getReadableAnimalHealthPathology(b))
                  )
                  .map((value: AnimalHealthDtoPathologies) => ({
                    label: getReadableAnimalHealthPathology(value),
                    value: value,
                  }))}
                size="small"
                error={undefined}
                requiredRule={undefined}
                sx={{ my: 2 }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                {...register('alimentation')}
                label="Alimentation"
                multiline
                fullWidth
                size="small"
                sx={{ mb: 2 }}
              />
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: { xs: 2, sm: 0 } }}>
              <AnimalCardSaveButton isLoading={editAnimalMutation.isLoading} disabled={!formState.isDirty} />
            </Box>

            <Box
              sx={{
                marginTop: 6,
                marginBottom: 4,
              }}
            >
              <Card sx={{ backgroundColor: 'rgba(255, 248, 226, 0.3)' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6">Suivi et historique de santé</Typography>
                    {canEditAnimal && (
                      <EditorPrompt
                        initialValue={animal.health.healthHistory || ''}
                        onSubmit={onEditHealthHistory}
                        title="🏥 Suivi Santé"
                      />
                    )}
                  </Box>
                  <Box>
                    <ReactQuill
                      value={TrimManager.trimIfNeeded(animal.health.healthHistory)}
                      readOnly={true}
                      theme={'bubble'}
                    />
                    {TrimManager.needTrim(animal.health.healthHistory) && (
                      <EditorPrompt
                        title="Suivi et historique de santé"
                        initialValue={animal.health.healthHistory || ''}
                        onSubmit={onEditHealthHistory}
                        renderButton={(onClick: () => void): JSX.Element => (
                          <Button onClick={onClick}>Voir Plus</Button>
                        )}
                      />
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </CardContent>
        </form>
      </Card>
    </>
  )
}
