import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { darken, styled } from '@mui/material/styles'
import { DateCalendar, LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import isBetween from 'dayjs/plugin/isBetween' // import the plugin
import * as React from 'react'
import { AccountDtoAvailability } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { DialogOpenedStore } from '../../../utils/hooks/useDialogOpened'
import { setOpacity } from '../../../utils/setOpacity'
import { colors } from '../../theme'

dayjs.extend(isBetween) // use the plugin
dayjs.locale('fr')

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  dayIsBetween: boolean
  isFirstDay: boolean
  isLastDay: boolean
}

type DayProps = PickersDayProps<Dayjs> & {
  periods: AccountDtoAvailability[]
  newDialogOpenedState: DialogOpenedStore
  editDialogOpenedState: DialogOpenedStore
  setSelectedStartDate: (date: Date | null) => void
  setSelectedAvailability: (availability: AccountDtoAvailability | null) => void
  isOwnAccount: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay' && prop !== 'status',
})<CustomPickerDayProps & { status?: string }>(({ theme, dayIsBetween, isFirstDay, isLastDay, status }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: status === 'emergency-only' ? colors.amber : theme.palette.error.main,
    color: status === 'emergency-only' ? 'black' : theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: status === 'emergency-only' ? darken(colors.amber, 0.2) : theme.palette.error.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
  ...(!dayIsBetween &&
    !isFirstDay &&
    !isLastDay && {
      backgroundColor: setOpacity(colors.lightGreen, 0.8),
      '&:hover, &:focus': {
        backgroundColor: colors.lightGreen,
      },
    }),
})) as React.ComponentType<CustomPickerDayProps & { status?: string }>

const Day: React.FC<DayProps> = (props) => {
  const {
    day,
    periods,
    newDialogOpenedState,
    editDialogOpenedState,
    setSelectedStartDate,
    setSelectedAvailability,
    isOwnAccount,
    ...other
  } = props
  const accountsStore = useAccountsStore()
  const isAdmin = accountsStore.isAdmin()

  let dayIsBetween = false
  let isFirstDay = false
  let isLastDay = false

  let status = undefined

  for (const period of periods) {
    const startDay = dayjs(period.startDate)
    const endDay = dayjs(period.endDate)

    if (day.isSame(startDay, 'day') || day.isSame(endDay, 'day') || day.isBetween(startDay, endDay, null, '()')) {
      dayIsBetween = true
    }

    if (day.isSame(startDay, 'day')) {
      isFirstDay = true
    }

    if (day.isSame(endDay, 'day')) {
      isLastDay = true
    }

    if (dayIsBetween) {
      status = period.status
      break
    }
  }

  const handleDayClick = (day: Dayjs, periods: AccountDtoAvailability[]) => {
    let isDayWithinPeriod = false
    let matchedPeriod = null

    for (const period of periods) {
      const startDay = dayjs(period.startDate)
      const endDay = dayjs(period.endDate)

      if (day.isBetween(startDay, endDay, null, '[]')) {
        isDayWithinPeriod = true
        matchedPeriod = period
        break // We found a matching period, so we can exit the loop early
      }
    }

    if (isOwnAccount || isAdmin) {
      if (isDayWithinPeriod) {
        setSelectedAvailability(matchedPeriod)
        return editDialogOpenedState.openDialog()
      } else {
        setSelectedStartDate(day.toDate())
        return newDialogOpenedState.openDialog()
      }
    }
  }

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween || isFirstDay || isLastDay}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
      status={status}
      onClick={() => handleDayClick(day, periods)}
    />
  )
}

type MemberAvailabilityCalendarProps = {
  periods: AccountDtoAvailability[]
  newDialogOpenedState: DialogOpenedStore
  editDialogOpenedState: DialogOpenedStore
  setSelectedStartDate: (date: Date | null) => void
  setSelectedAvailability: (availability: AccountDtoAvailability | null) => void
  isOwnAccount: boolean
}

export const MemberAvailabilityCalendar: React.FC<MemberAvailabilityCalendarProps> = ({
  periods,
  newDialogOpenedState,
  setSelectedStartDate,
  setSelectedAvailability,
  editDialogOpenedState,
  isOwnAccount,
}) => {
  const [value, setValue] = React.useState<Dayjs | null>(null)
  const [currentStartMonth, setStartMonth] = React.useState(dayjs())

  const shiftMonths = (n: number) => {
    setStartMonth(currentStartMonth.add(n, 'month'))
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <Box
        sx={{
          '.MuiPickersArrowSwitcher-root': {
            display: 'none',
          },
        }}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <IconButton onClick={() => shiftMonths(-1)} sx={{ pr: { xs: 0, sm: '8px' } }}>
          <ArrowBackIos />
        </IconButton>
        {[0, 1, 2].map((n) => {
          const targetMonth = currentStartMonth.clone().add(n, 'month')
          return (
            <DateCalendar
              key={`${targetMonth.format('YYYY-MM')}-${n}`} // using a unique key based on the target month
              readOnly
              defaultCalendarMonth={targetMonth}
              onChange={(newValue) => setValue(newValue)}
              value={value}
              slots={{
                day: (props) => (
                  <Day
                    {...props}
                    periods={periods}
                    newDialogOpenedState={newDialogOpenedState}
                    setSelectedStartDate={setSelectedStartDate}
                    setSelectedAvailability={setSelectedAvailability}
                    editDialogOpenedState={editDialogOpenedState}
                    isOwnAccount={isOwnAccount}
                  />
                ),
              }}
              sx={{
                display: { xs: n === 0 ? 'block' : 'none', sm: n === 2 ? 'none' : 'block', md: 'block' },
                m: 0,
              }}
            />
          )
        })}
        <IconButton onClick={() => shiftMonths(1)} sx={{ pl: { xs: 0, sm: '8px' } }}>
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </LocalizationProvider>
  )
}
