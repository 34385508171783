import React, { useEffect } from 'react'
import {
  AnimalDto,
  AnimalDtoSpecies,
  AnimalVaccinationDtoVaccines,
  CreateAnimalBodyDtoVaccinationStatus,
} from '../../../../interactors/gen/backendClient'
import { Box, Grid, Typography } from '@mui/material'
import {
  getReadableAnimalVaccineStatus,
  getColorForAnimalVaccineStatus,
  getReadableVaccines,
} from '../../../../domain/Animal/AnimalDisplay'
import { ColoredChipSelectField } from '../../../common/ColoredChipSelectField'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { omitEmptyString } from '../../../../utils/omitEmptyString'
import { ControlledMultipleAutoComplete } from '../../../common/ControlledMultipleAutoComplete'
import { isVaccineForCat, isVaccineForDog } from '../../../../domain/Animal/VaccinationType'
import { ReadOnlyItem } from '../../../common/ReadOnlyItem'
import { EditAnimalHealthFrequenciesModal } from './EditAnimalHealthFrequenciesModal'

interface Props {
  animal: AnimalDto
  canEditAnimal: boolean
}

type FormParams = {
  vaccinationStatus?: CreateAnimalBodyDtoVaccinationStatus
  vaccines?: AnimalVaccinationDtoVaccines[]
}

export const AnimalHealthVaccines: React.FC<Props> = ({ animal, canEditAnimal }) => {
  const editAnimalMutation = useEditAnimalMutation({})

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    vaccinationStatus: animal.vaccination.vaccinationStatus || undefined,
    vaccines: animal.vaccination.vaccines || [],
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormExtended(animal, getDefaultValues)

  useEffect(() => {
    const onSubmit = async (data: FormParams) => {
      const update = omitEmptyString({
        ...animal,
        vaccination: {
          ...animal.vaccination,
          vaccinationStatus: data.vaccinationStatus || undefined,
          vaccines: data.vaccines || undefined,
        },
      })

      await editAnimalMutation.mutate(update)
    }
    if (isDirty) {
      handleSubmit(onSubmit)()
    }
  }, [isDirty])
  return (
    <form>
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={12} md={2}>
          <ColoredChipSelectField
            control={control}
            error={errors.vaccinationStatus}
            fieldName="vaccinationStatus"
            size="small"
            label="Statut primovaccination"
            options={Object.values(CreateAnimalBodyDtoVaccinationStatus).map(
              (status: CreateAnimalBodyDtoVaccinationStatus) => ({
                label: getReadableAnimalVaccineStatus(status),
                value: status,
                color: getColorForAnimalVaccineStatus(status),
              })
            )}
            requiredRule="Le statut primovaccination est requis"
          />
        </Grid>

        <Grid item xs={12} md={5}>
          {(animal.species === AnimalDtoSpecies.Cat || animal.species === AnimalDtoSpecies.Dog) && (
            <ControlledMultipleAutoComplete
              freeSolo={false}
              control={control}
              error={undefined}
              fieldName="vaccines"
              label="Type (Choississez un ou plusieurs vaccins)"
              options={
                animal.species === AnimalDtoSpecies.Cat
                  ? Object.values(AnimalVaccinationDtoVaccines)
                      .filter((vaccine) => isVaccineForCat(vaccine))
                      .map((vaccine: AnimalVaccinationDtoVaccines) => ({
                        label: getReadableVaccines(vaccine),
                        value: vaccine,
                      }))
                  : animal.species === AnimalDtoSpecies.Dog
                  ? Object.values(AnimalVaccinationDtoVaccines)
                      .filter((vaccine) => isVaccineForDog(vaccine))
                      .map((vaccine: AnimalVaccinationDtoVaccines) => ({
                        label: getReadableVaccines(vaccine),
                        value: vaccine,
                      }))
                  : []
              }
              requiredRule=""
              size="small"
            />
          )}
        </Grid>

        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { sm: 'row', xs: 'column' },
              alignItems: 'center',
              gap: { sm: 2, xs: 1 },
            }}
          >
            <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
              Fréquence des rappels:
            </Typography>
            <ReadOnlyItem
              label="Post-primo"
              value={
                animal.vaccination.annualReminderFrequencyInYear === -1
                  ? 'Non'
                  : `${animal.vaccination.annualReminderFrequencyInYear || 1} an(s)`
              }
            />
            <ReadOnlyItem
              label="Vermifuge"
              value={
                animal.health.dewormingFrequencyInMonths === -1
                  ? 'Non'
                  : animal.health.dewormingFrequencyInMonths === 0.5
                  ? '15 jours'
                  : `${animal.health.dewormingFrequencyInMonths || 1} mois`
              }
            />
            <ReadOnlyItem
              label="Antipuce"
              value={
                animal.health.fleaControlFrequencyInMonths === -1
                  ? 'Non'
                  : animal.health.fleaControlFrequencyInMonths === 0.5
                  ? '15 jours'
                  : `${animal.health.fleaControlFrequencyInMonths || 1} mois`
              }
            />
            {canEditAnimal && (
              <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
                <EditAnimalHealthFrequenciesModal animal={animal} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
