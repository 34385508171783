import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import { AccountDto } from '../../interactors/gen/backendClient'
import { useNavigate } from 'react-router-dom'
import { getAccountProfilePhotoSrc } from '../../utils/S3-links'
import {
  fullAddress,
  getColorForAvailabilityStatus,
  getReadableAccountPermission,
  getReadableAccountVeterinaryType,
} from '../../domain/Account/AccountDisplay'
import { setOpacity } from '../../utils/setOpacity'
import { isUserAvailableToday } from '../../utils/isUserAvailableToday'

interface CardProps {
  member: AccountDto
  bgColor?: string
}

const VeterinariesListCard = ({ member, bgColor }: CardProps) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`${member.id}`)
  }

  const profileImageKey = member.images?.profileImageKey

  return (
    <Card
      sx={{ borderRadius: '25px', border: '2px solid #D2D2D2', backgroundColor: bgColor, boxShadow: 'none' }}
      onClick={handleClick}
    >
      <CardContent
        sx={{
          paddingTop: 0,
          '&:last-child': { paddingBottom: 0 },
          paddingLeft: '25px',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        {/* Box 1: avatar + [[name, availability avatar], type, permission] */}
        <Box display="flex" gap={2} paddingTop={2}>
          <Avatar
            alt={member.firstName}
            src={profileImageKey && getAccountProfilePhotoSrc(profileImageKey)}
            sx={{
              height: '80px',
              width: '80px',
            }}
          />
          <Box>
            <Box display="flex" alignItems="center" gap="10px" marginBottom="5px">
              <Typography sx={{ fontWeight: 700 }}>
                {member.veterinary?.type === 'clinic' ? member.firstName : `${member.firstName} ${member.lastName}`}
              </Typography>
              <Avatar
                sx={{
                  background: setOpacity(getColorForAvailabilityStatus(isUserAvailableToday(member)), 0.7),
                  width: '22px',
                  height: '22px',
                  fontWeight: 'bold',
                }}
              >
                {' '}
              </Avatar>
            </Box>
            <Typography>
              {member.veterinary?.type
                ? getReadableAccountVeterinaryType(member.veterinary.type)
                : 'Vétérinaire indépendant'}
            </Typography>
            <Typography fontWeight={700} color="secondary">
              {getReadableAccountPermission(member.permission)}
            </Typography>
          </Box>
        </Box>

        {/* Box 2: contact details: [mail, phone number] + address */}
        <Box
          sx={{
            paddingBottom: '20px',
          }}
        >
          <Typography fontStyle="italic" fontSize="15px" sx={{ textDecoration: 'underline' }} color="secondary">
            {member.email}
          </Typography>
          {/* {member.phoneNumber && (
            <Typography fontStyle="italic" fontSize="15px">
              {member.phoneNumber}
            </Typography>
          )} */}
          <Typography fontStyle="italic" fontSize="15px">
            {fullAddress(member)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default VeterinariesListCard
