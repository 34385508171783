import { Visibility, VisibilityOff, Lock, Person } from '@mui/icons-material'
import { Typography, TextField, Grid, Box, IconButton, CardContent, Card } from '@mui/material'
import { StatusCodes } from 'http-status-codes'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthService } from '../../interactors/services/AuthService'

import Background from './login-bg.png'
import placeholder from './login-bg-compressed.png'
import { useProgressiveImage } from '../../utils/hooks/useProgressiveImage'
import { useAccountsStore } from '../../store/AccountsStore'
import { LoadingButton } from '@mui/lab'
import { blue } from '../theme'

export const AdminLoginPage: React.FC = () => {
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()

  const [isLoading, setIsLoading] = useState(false)
  const [customerId, setCustomerId] = React.useState('')
  const [password, setPassword] = useState('')
  const [serverError, setServerError] = useState('')
  const [isPasswordField, setIsPasswordField] = useState(true)

  useEffect(() => {
    if (AuthService.isLogged()) {
      navigate('/')
    }
  })

  const handleLogin = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setIsLoading(true)
    setServerError('')

    await AuthService.adminLogin(customerId.trim(), password.trim())
      .then(() => {
        if (AuthService.account) {
          accountsStore.changeConnectedAccount(AuthService.account)
        }

        navigate('/')
      })
      .catch((error) => {
        console.error({ error })
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          setServerError('Mot de passe incorrect.')
        } else {
          setServerError('Something wrong happened')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const loadedBackground = useProgressiveImage(Background)

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        boxShadow: 'rgb(15 15 33 / 20%) 0px 0px 0px 1000px inset',
        '@media orientation: landscape': {
          overflow: 'auto',
        },
      }}
    >
      <Box sx={{ height: { md: 0, sm: 100, xs: 200 } }}></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          minHeight: { sm: '450px', xs: '450px' },
          width: { md: '50%', xs: '100%' },
        }}
      >
        <Box flexGrow="1" margin="auto" padding="0px 2%" boxSizing="border-box" paddingLeft={{ lg: 10 }}>
          <div>
            <Box
              sx={{
                margin: 'auto',
                '@media orientation: portrait': {
                  maxWidth: { md: '600px', sm: '80vh' },
                },
                '@media orientation: landscape': {
                  maxWidth: { md: '600px', sm: '80vw' },
                },
                maxWidth: { md: '600px' },
                color: 'white',
                marginTop: { md: 20, xs: 5 },
                width: { md: '100%', sm: '600px' },
              }}
            >
              <Typography
                variant="h1"
                sx={{ color: blue, fontFamily: 'Insaniburger', fontSize: { xs: '80px', sm: '100px' } }}
              >
                Admin
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Card elevation={20} sx={{ width: { md: '100%', xs: '600px' }, maxWidth: { md: '600px', xs: '95vw' } }}>
                <CardContent>
                  <form onSubmit={handleLogin}>
                    <div>
                      <Box marginTop="0.5rem">
                        <TextField
                          fullWidth
                          label="Customer ID"
                          value={customerId}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomerId(event.target.value)}
                          InputProps={{
                            startAdornment: (
                              <div style={{ marginRight: '8px' }}>
                                <Person />
                              </div>
                            ),
                          }}
                        />
                      </Box>
                    </div>

                    <div>
                      <Box marginTop="1.5rem">
                        <TextField
                          fullWidth
                          type={isPasswordField ? 'password' : 'text'}
                          label="Mot de passe"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <div style={{ marginRight: '8px' }}>
                                <Lock />
                              </div>
                            ),
                            endAdornment: (
                              <IconButton
                                color="default"
                                size="small"
                                edge="end"
                                onClick={() => setIsPasswordField(!isPasswordField)}
                              >
                                {isPasswordField ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            ),
                          }}
                        />
                      </Box>
                    </div>

                    <div>
                      {serverError && (
                        <Grid item>
                          <Typography color="error">{serverError}</Typography>
                        </Grid>
                      )}
                      <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                        <Box position="relative" sx={{ display: 'flex', flexDirection: 'column' }}>
                          <LoadingButton variant="contained" id="login-button" type="submit" loading={isLoading}>
                            Se connecter
                          </LoadingButton>
                        </Box>
                      </Box>
                    </div>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { md: '100vh', xs: '100%' },
          width: { md: '50%', xs: '100%' },
          backgroundImage: `url(${loadedBackground || placeholder})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: { md: 'left', xs: 'top' },
        }}
      ></Box>
    </Box>
  )
}
