import {
  CreateDonationTrackingBodyDtoCgi,
  CreateDonationTrackingBodyDtoFormOfDonation,
  CreateDonationTrackingBodyDtoMethodOfPayment,
  CreateDonationTrackingBodyDtoTypeOfDonation,
} from '../../interactors/gen/backendClient'
import { colors } from '../../views/theme'

export const getReadableDonationTrackingFormOfDonation = (
  field: CreateDonationTrackingBodyDtoFormOfDonation
): string => {
  const map: Record<CreateDonationTrackingBodyDtoFormOfDonation, string> = {
    [CreateDonationTrackingBodyDtoFormOfDonation.DeclarationOfManualDonation]: 'Déclaration de don manuel',
    [CreateDonationTrackingBodyDtoFormOfDonation.Deed]: 'Acte authentique',
    [CreateDonationTrackingBodyDtoFormOfDonation.PrivateDeed]: 'Acte sous seing privé',
    [CreateDonationTrackingBodyDtoFormOfDonation.Other]: 'Autre',
  }

  return map[field]
}

export const getReadableDonationTrackingTypeOfDonation = (
  field: CreateDonationTrackingBodyDtoTypeOfDonation
): string => {
  const map: Record<CreateDonationTrackingBodyDtoTypeOfDonation, string> = {
    [CreateDonationTrackingBodyDtoTypeOfDonation.Cash]: 'Numéraire',
    [CreateDonationTrackingBodyDtoTypeOfDonation.Equipment]: 'Matériel',
    [CreateDonationTrackingBodyDtoTypeOfDonation.Food]: 'Alimentaire',
    [CreateDonationTrackingBodyDtoTypeOfDonation.Other]: 'Autre',
  }

  return map[field]
}

export const getColorForDonationTrackingTypeOfDonation = (
  field: CreateDonationTrackingBodyDtoTypeOfDonation
): string => {
  const map: Record<CreateDonationTrackingBodyDtoTypeOfDonation, string> = {
    [CreateDonationTrackingBodyDtoTypeOfDonation.Cash]: colors.anakiwa,
    [CreateDonationTrackingBodyDtoTypeOfDonation.Equipment]: colors.amber,
    [CreateDonationTrackingBodyDtoTypeOfDonation.Food]: colors.bermudaGray,
    [CreateDonationTrackingBodyDtoTypeOfDonation.Other]: colors.azalea,
  }

  return map[field]
}

export const getReadableDonationTrackingMethodOfPayment = (
  field: CreateDonationTrackingBodyDtoMethodOfPayment
): string => {
  const map: Record<CreateDonationTrackingBodyDtoMethodOfPayment, string> = {
    [CreateDonationTrackingBodyDtoMethodOfPayment.BankCard]: 'Carte bancaire',
    [CreateDonationTrackingBodyDtoMethodOfPayment.Cash]: "Remise d'espèces",
    [CreateDonationTrackingBodyDtoMethodOfPayment.Cheque]: 'Chèque',
    [CreateDonationTrackingBodyDtoMethodOfPayment.DirectDebit]: 'Prélevement bancaire',
    [CreateDonationTrackingBodyDtoMethodOfPayment.WireTransfer]: 'Virement bancaire',
  }

  return map[field]
}

export const getReadableDonationTrackingCGI = (field: CreateDonationTrackingBodyDtoCgi): string => {
  const map: Record<CreateDonationTrackingBodyDtoCgi, string> = {
    [CreateDonationTrackingBodyDtoCgi._200]: '200 du CGI',
    [CreateDonationTrackingBodyDtoCgi._238]: '238 Bis du CGI',
    [CreateDonationTrackingBodyDtoCgi._885]: '885-0 V Bis A du CGI',
    [CreateDonationTrackingBodyDtoCgi.Other]: 'Aucun des trois',
  }

  return map[field]
}
