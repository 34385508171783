import React, { useState } from 'react'
import { Box, Modal, Button, Typography, MenuItem } from '@mui/material'

interface Props {
  linkStyle?: boolean
  menuItem?: boolean
}

export const VideoPopIn: React.FC<Props> = ({ linkStyle = false, menuItem = false }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    const isMobileDevice = /Mobi|Tablet|iPad|iPhone|Android/i.test(navigator.userAgent)
    const youtubeAppUrl = `youtube://www.youtube.com/watch?v=L3LapukO4WE`

    if (isMobileDevice) {
      window.location.href = youtubeAppUrl
    } else {
      setIsOpen(true)
    }
  }

  const handleClose = () => setIsOpen(false)

  return (
    <>
      {linkStyle ? (
        <Typography
          sx={{ fontWeight: 500, fontSize: 26, textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => handleOpen()}
        >
          Avant toute chose, n&#39;oubliez pas que vous disposez d&#39;un tuto vidéo accessible par tous !
        </Typography>
      ) : menuItem ? (
        <MenuItem key="tuto" onClick={handleOpen}>
          Le tuto vidéo
        </MenuItem>
      ) : (
        <Button
          onClick={handleOpen}
          sx={{
            my: 2,
            color: 'white',
            display: 'block',
            textTransform: 'none',
            fontWeight: 600,
            fontSize: '1rem',
          }}
        >
          Le tuto vidéo
        </Button>
      )}

      <Modal
        open={isOpen}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(3px)',
        }}
      >
        <Box
          sx={{
            width: '85%',
            maxHeight: '100vh',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            aspectRatio: '16 / 9',
            borderRadius: '12px',
            boxShadow: '24',
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/i-d966m04mQ?si=AluLYJB8oRHLBdzK"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              borderRadius: '12px',
            }}
          ></iframe>
        </Box>
      </Modal>
    </>
  )
}
