import React from 'react'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { DocumentsList } from './DocumentsList'

interface Props {
  association: CustomerDto
}

export const AssociationDocumentsTab: React.FC<Props> = ({ association }) => {
  return <DocumentsList documents={association?.documents} />
}
