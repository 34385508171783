import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, MenuItem } from '@mui/material'
import { FC } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { AnimalDto } from '../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { PATHS } from '../../PATHS'

interface Props {
  animal: AnimalDto
}

export const DeleteAnimalPrompt: FC<Props> = ({ animal }) => {
  const navigate = useNavigate()
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()

  const deleteAnimalMutation = useMutation(async () => await AnimalsClient.deleteAnimal(animal.id), {
    onSuccess: () => {
      closeDialog()
      navigate(PATHS.animals.absolute)
      globalSnackbar.triggerSuccessMessage(`${animal.name} a été supprimé !`)
    },
  })

  return (
    <>
      <MenuItem key="delete" onClick={openDialog}>
        {"Supprimer l'animal"}
      </MenuItem>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>⚠️ Êtes vous sur de vouloir supprimer cet animal?</DialogContent>
        <DialogActions>
          <Button data-testid="cancel" onClick={closeDialog}>
            Revenir
          </Button>
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            sx={{ backgroundColor: 'error.main' }}
            onClick={() => deleteAnimalMutation.mutate()}
            loading={deleteAnimalMutation.isLoading}
          >
            Valider
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
