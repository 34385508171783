import { Card, CardContent, CardHeader, Box, Typography } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { last } from 'lodash'
import {
  AnimalDto,
  ChangeOfHostedFamilyHostedFamilyFollowUpDto,
  ShiftHostedFamilyFollowUpDto,
} from '../../../../interactors/gen/backendClient'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { omitEmptyString } from '../../../../utils/omitEmptyString'

import { HostedFamilyFollowUp } from './HostedFamilyFollowUpItem'
import { HostedFamilyFollowUpDtoType } from '../../../../domain/Animal/HostedFamilyFollowUpDtoType'
import { NewHostedFamilyFollowUpModal } from './NewHostedFamilyFollowUpModal'
import dayjs from 'dayjs'
import { spacingItem } from '../../../theme'
import { ContactFollowUpItem } from './ContactFollowUpItem'
import { NewContactFollowUpModal } from './NewContactFollowUpModal'
import { useFetchOrRefreshMembersOnMount } from '../../../../store/useFetchOrRefreshMembersOnMount'
import { useFetchOrRefreshAdoptersOnMount } from '../../../../store/useFetchOrRefreshAdoptersOnMount'
import { animalButtonShouldBeDisabled } from '../../../../utils/animalButtonShouldBeDisabled'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { PATHS } from '../../../PATHS'
import { Link } from 'react-router-dom'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'

interface Props {
  animal: AnimalDto
}

export type MainHostedFamilyFollowUp = ChangeOfHostedFamilyHostedFamilyFollowUpDto | ShiftHostedFamilyFollowUpDto

export const HostedFamillyFollowUpsTab: FC<Props> = ({ animal }) => {
  const accountsStore = useAccountsStore()
  const [allFollowUps, setAllFollowUps] = useState<MainHostedFamilyFollowUp[]>(animal.hostedFamilyFollowUps.main || [])
  const [contactFollowUps, setContactFollowUps] = useState(animal.hostedFamilyFollowUps.contact || [])

  useFetchOrRefreshMembersOnMount()
  useFetchOrRefreshAdoptersOnMount()

  const editAnimalMutation = useEditAnimalMutation({})

  const initialFollowUpsRef = useRef(animal.hostedFamilyFollowUps.main || [])
  useEffect(() => {
    const saveFollowUps = async () => {
      // only save if followUps have been modified
      if (JSON.stringify(allFollowUps) === JSON.stringify(initialFollowUpsRef.current)) {
        return
      }
      const sortedFollowUps = allFollowUps.sort((a, b) => {
        const dateA = dayjs(a.date, 'DD/MM/YYYY')
        const dateB = dayjs(b.date, 'DD/MM/YYYY')

        // Compare by date
        if (dateA.isBefore(dateB)) return -1
        if (dateA.isAfter(dateB)) return 1

        // If dates are the same, sort based on their created.date
        const createdDateA = dayjs(a.created.at)
        const createdDateB = dayjs(b.created.at)

        if (createdDateA.isBefore(createdDateB)) return -1
        if (createdDateA.isAfter(createdDateB)) return 1

        return 0
      })

      const lastChangeLocation: MainHostedFamilyFollowUp | undefined = last(sortedFollowUps)

      let hostFamilyInChargeAccountId: string | undefined
      let cageNumber: string | undefined
      if (lastChangeLocation) {
        if (
          lastChangeLocation.type === HostedFamilyFollowUpDtoType.ChangeOfHostedFamily ||
          lastChangeLocation.destination?.startsWith('A-')
        ) {
          if (lastChangeLocation.type === HostedFamilyFollowUpDtoType.ChangeOfHostedFamily) {
            hostFamilyInChargeAccountId =
              (lastChangeLocation as ChangeOfHostedFamilyHostedFamilyFollowUpDto).afterAccountId ||
              animal.responsibilities?.hostFamilyInChargeAccountId
          } else {
            hostFamilyInChargeAccountId =
              (lastChangeLocation as ShiftHostedFamilyFollowUpDto).destination ||
              animal.responsibilities?.hostFamilyInChargeAccountId
          }
        } else if (lastChangeLocation.destination?.startsWith('Box N°')) {
          cageNumber = lastChangeLocation.destination.split('Box N°')[1].trim()
        }
      }

      const newFollowUps = allFollowUps

      const update: AnimalDto = omitEmptyString({
        ...animal,
        responsibilities: {
          ...animal.responsibilities,
          hostFamilyInChargeAccountId,
          cageNumber,
        },
        hostedFamilyFollowUps: {
          main: newFollowUps as MainHostedFamilyFollowUp[],
          contact: animal.hostedFamilyFollowUps.contact,
        },
      })

      console.log('update', update)

      await editAnimalMutation.mutate(update)
    }
    saveFollowUps()
  }, [allFollowUps])

  const initialContactFollowUpsRef = useRef(animal.hostedFamilyFollowUps.contact || [])
  useEffect(() => {
    const saveContactFollowUps = async () => {
      // only save if contactFollowUps have been modified
      if (JSON.stringify(contactFollowUps) === JSON.stringify(initialContactFollowUpsRef.current)) {
        return
      }

      const newFollowUps = contactFollowUps

      const update: AnimalDto = omitEmptyString({
        ...animal,
        hostedFamilyFollowUps: {
          main: animal.hostedFamilyFollowUps.main || [],
          contact: newFollowUps,
        },
      })

      console.log('update', update)

      await editAnimalMutation.mutate(update)
    }
    saveContactFollowUps()
  }, [contactFollowUps])

  //Get readable reason for error message
  const getReason = (reason: string): string => {
    switch (reason) {
      case 'has-been-adopted':
        return 'adopté'
      case 'lost':
        return `enregistré comme perdu`
      case 'dead':
        return `enregistré comme décédé`
      case 'released':
        return `relâché`
      case 'transfer-to-another-association':
        return `transféré à une autre association`
      default:
        return ''
    }
  }

  const canEditAnimal = useCanEditAnimal()

  return (
    <>
      <Card sx={spacingItem}>
        <CardHeader title="Suivi des déplacements" />
        <CardContent>
          {animalButtonShouldBeDisabled(animal) && (
            <Typography variant="body1" sx={spacingItem}>
              {`${animal.name} est ${getReason(
                animal.adoption.status === 'has-been-adopted'
                  ? animal.adoption.status
                  : animal.adoption.cannotBeAdoptedStatus || ''
              )}, il est donc archivé. Vous ne pouvez pas modifier.`}
            </Typography>
          )}
          <Box sx={{ position: 'relative' }}>
            {allFollowUps.sort((a, b) => {
              if (a.type === 'initial-placement') return 1
              if (b.type === 'initial-placement') return -1

              const dateA = dayjs(a.date, 'DD/MM/YYYY')
              const dateB = dayjs(b.date, 'DD/MM/YYYY')

              // Compare by date
              if (dateA.isBefore(dateB)) return 1
              if (dateA.isAfter(dateB)) return -1

              // If dates are the same, sort based on their created.date
              const createdDateA = dayjs(a.created?.at)
              const createdDateB = dayjs(b.created?.at)

              if (createdDateA.isBefore(createdDateB)) return 1
              if (createdDateA.isAfter(createdDateB)) return -1

              return 0
            })[0] && (
              <HostedFamilyFollowUp
                key={allFollowUps[0].date}
                followUp={allFollowUps[0]}
                allFollowUps={allFollowUps}
                setAllFollowUps={setAllFollowUps}
                animal={animal}
                mostRecent={allFollowUps[0].type !== 'initial-placement'}
              />
            )}

            <Box
              sx={{
                filter: !accountsStore.isPremium() && allFollowUps.length > 1 ? 'blur(5px)' : 'none',
                pointerEvents: !accountsStore.isPremium() && allFollowUps.length > 1 ? 'none' : 'auto',
                marginTop: 2,
              }}
            >
              {allFollowUps
                .sort((a, b) => {
                  if (a.type === 'initial-placement') return 1
                  if (b.type === 'initial-placement') return -1

                  const dateA = dayjs(a.date, 'DD/MM/YYYY')
                  const dateB = dayjs(b.date, 'DD/MM/YYYY')

                  // Compare by date
                  if (dateA.isBefore(dateB)) return 1
                  if (dateA.isAfter(dateB)) return -1

                  // If dates are the same, sort based on their created.date
                  const createdDateA = dayjs(a.created?.at)
                  const createdDateB = dayjs(b.created?.at)

                  if (createdDateA.isBefore(createdDateB)) return 1
                  if (createdDateA.isAfter(createdDateB)) return -1

                  return 0
                })
                .slice(1, accountsStore.isPremium() ? allFollowUps.length : 2)
                .map((followUp, index) => (
                  <HostedFamilyFollowUp
                    key={followUp.date + index + 1}
                    followUp={followUp}
                    allFollowUps={allFollowUps}
                    setAllFollowUps={setAllFollowUps}
                    animal={animal}
                    mostRecent={false}
                  />
                ))}
            </Box>

            {!accountsStore.isPremium() && allFollowUps.length > 1 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  left: 0,
                  zIndex: 2,
                  py: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 700, backgroundColor: 'rgba(255, 255, 255, 0.7)', p: 0.5, borderRadius: 1 }}
                >
                  Pour bénéficier intégralement de cette fonctionnalité,{' '}
                  <Link style={{ color: 'black' }} to={`${PATHS.association.absolute}?tab=functionalities`}>
                    découvrez l&#39;offre utile dans mes abonnements
                  </Link>
                </Typography>
              </Box>
            )}
            {!accountsStore.isPremium() && allFollowUps.length < 2 && (
              <Typography sx={{ fontWeight: 700 }}>
                Pour bénéficier intégralement de cette fonctionnalité,{' '}
                <Link style={{ color: 'black' }} to={`${PATHS.association.absolute}?tab=functionalities`}>
                  découvrez l&#39;offre utile dans mes abonnements
                </Link>
              </Typography>
            )}
          </Box>

          <Box display="flex" justifyContent="flex-end" sx={spacingItem}>
            <NewHostedFamilyFollowUpModal
              animal={animal}
              allFollowUps={allFollowUps}
              setAllFollowUps={setAllFollowUps}
              disabled={!accountsStore.isPremium() || !canEditAnimal}
            />
          </Box>
        </CardContent>
        <CardHeader title="Suivi des FA" />
        <CardContent>
          {contactFollowUps.map((followUp, index) => (
            <ContactFollowUpItem
              key={followUp.date + index}
              followUp={followUp}
              contactFollowUps={contactFollowUps}
              setContactFollowUps={setContactFollowUps}
              animal={animal}
              disabled={!canEditAnimal}
            />
          ))}
          <Box display="flex" justifyContent="flex-end" sx={spacingItem}>
            <NewContactFollowUpModal
              animal={animal}
              contactFollowUps={contactFollowUps}
              setContactFollowUps={setContactFollowUps}
              disabled={!canEditAnimal}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
