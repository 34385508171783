import React, { useEffect } from 'react'
import { CollapsableCard } from '../../common/CollapsableCard'
import { Grid, TextField, InputAdornment, useMediaQuery } from '@mui/material'
import { getReadableAnimalAdoptionOfferType } from '../../../domain/Animal/AnimalDisplay'
import { AdoptionAttemptDto, AnimalAdoptionDtoOfferType, AnimalDto } from '../../../interactors/gen/backendClient'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { AnimalCardSaveButton } from '../../Animals/Details/AnimalCardSaveButton'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { ColoredChipSelectField } from '../../common/ColoredChipSelectField'
import { colors, theme } from '../../theme'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { nanoid } from 'nanoid'
import { UseMutationResult } from 'react-query'
import { AxiosError } from 'axios'
import { PaymentInfoCard } from './PaymentInfoCard'

interface FormParams {
  offerType?: AnimalAdoptionDtoOfferType
  priceInEuros?: number
  paymentMethod: string
  paymentDone: string
}

interface Props {
  animal: AnimalDto
  adoptionAttempt: AdoptionAttemptDto
  isReader: boolean
  updateAttemptMutation: UseMutationResult<
    AdoptionAttemptDto,
    Error | AxiosError<unknown, unknown>,
    AdoptionAttemptDto,
    unknown
  >
}

export const ContributionCard: React.FC<Props> = ({ animal, adoptionAttempt, updateAttemptMutation, isReader }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const isUpMdWidth = useMediaQuery(theme.breakpoints.up('md'))
  const isDownMdWidth = useMediaQuery(theme.breakpoints.down('md'))

  const getDefaultValues = (attempt: AdoptionAttemptDto): FormParams => ({
    offerType: animal.adoption.offerType || undefined,
    priceInEuros: animal.adoption.priceInEuros || undefined,
    paymentMethod: attempt?.paymentMethod || '',
    paymentDone: attempt?.paymentDone ? 'true' : 'false',
  })

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useFormExtended(adoptionAttempt, getDefaultValues)

  const onSubmit = async (data: FormParams) => {
    setIsLoading(true)
    if (data.offerType !== animal.adoption.offerType || data.priceInEuros !== animal.adoption.priceInEuros) {
      animal.adoption.offerType = data.offerType
      animal.adoption.priceInEuros = data.priceInEuros
      await AnimalsClient.editAnimal(animal)
    }
    if (
      data.paymentMethod !== adoptionAttempt.paymentMethod ||
      data.paymentDone !== adoptionAttempt.paymentDone.toString()
    ) {
      if (data.paymentDone === 'true' && adoptionAttempt.paymentDone === false) {
        adoptionAttempt.events.push({ id: nanoid(), type: 'payment-reception', date: new Date().toISOString() })
      }
      if (data.paymentDone === 'false' && adoptionAttempt.paymentDone === true) {
        adoptionAttempt.events.push({ id: nanoid(), type: 'payment-cancelled', date: new Date().toISOString() })
      }
      adoptionAttempt.paymentDone = data.paymentDone === 'true'
      adoptionAttempt.paymentMethod = data.paymentMethod

      await updateAttemptMutation.mutateAsync(adoptionAttempt)
    }
    setIsLoading(false)
  }

  const isDonation = watch('offerType') === AnimalAdoptionDtoOfferType.Donation
  useEffect(() => {
    if (isDonation) {
      setValue('priceInEuros', 0, { shouldValidate: true })
    }
  }, [isDonation, setValue])

  return (
    <CollapsableCard defaultExpanded title="Contribution">
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          marginTop: 16,
          width: '100%',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <ControlledSelectField
              control={control}
              error={errors.offerType}
              fieldName="offerType"
              label="Nature de l'offre"
              options={Object.values(AnimalAdoptionDtoOfferType).map((field: AnimalAdoptionDtoOfferType) => ({
                label: getReadableAnimalAdoptionOfferType(field),
                value: field,
              }))}
              requiredRule=""
              size="small"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <TextField
              id="price-input"
              label="Montant"
              type="number"
              inputProps={{
                step: '0.01',
                inputMode: 'decimal',
                pattern: '[0-9]*',
              }}
              disabled={isDonation}
              fullWidth
              {...register('priceInEuros')}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
          {isUpMdWidth && (
            <Grid item md={3}>
              <PaymentInfoCard adoptionAttempt={adoptionAttempt} animal={animal} isReader={isReader} />
            </Grid>
          )}
          <Grid item xs={6} md={3}>
            <ColoredChipSelectField
              control={control}
              error={errors.paymentDone}
              fieldName="paymentDone"
              label="Status"
              options={[
                { label: 'Validé', value: 'true', color: colors.seaGreen },
                { label: 'En attente', value: 'false', color: colors.amber },
              ]}
              requiredRule=""
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AnimalCardSaveButton disabled={!isDirty} isLoading={isLoading} />
          </Grid>
          {isDownMdWidth && (
            <Grid item xs={12}>
              <PaymentInfoCard adoptionAttempt={adoptionAttempt} animal={animal} isReader={isReader} />
            </Grid>
          )}
        </Grid>
      </form>
    </CollapsableCard>
  )
}
