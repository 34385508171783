import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { AssociationAvatarAndName } from './AssociationAvatarAndName'
import { CustomerDto } from '../../interactors/gen/backendClient'
import { AssociationTabs } from './AssociationTabs'
import { useFetchOrRefreshMembersOnMount } from '../../store/useFetchOrRefreshMembersOnMount'
import { CustomersClient } from '../../interactors/clients/CustomerClient'

export const AssociationDetailsScreen = () => {
  useFetchOrRefreshMembersOnMount()

  const [association, setAssociation] = React.useState<CustomerDto | null>(null)

  useEffect(() => {
    const loadAssociation = async () => {
      const selectedAssociation = await CustomersClient.getCurrentCustomer()
      setAssociation(selectedAssociation || null)
    }

    loadAssociation()
  }, [])

  return (
    <Box sx={{ padding: 2, backgroundColor: 'grey.100', minHeight: '100%' }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: '5rem' }}>
        <AssociationAvatarAndName association={association!} />

        <AssociationTabs association={association!} setAssociation={setAssociation} />
      </Box>
    </Box>
  )
}
