import { Box, Button, Dialog, DialogActions, DialogContent, Fab, TextField, Typography } from '@mui/material'
import React from 'react'
import { blue, colors, spacingItem } from '../../theme'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import {
  AccountDocumentDto,
  AccountDocumentDtoType,
  UploadAccountDocumentDto,
} from '../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { useDropzone } from 'react-dropzone'
import { backendClient } from '../../../interactors/clients/client'
import { Add, UploadFile } from '@mui/icons-material'
import { setOpacity } from '../../../utils/setOpacity'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { getReadableAccountDocumentType, getColorForAccountDocumentType } from '../../../domain/Account/AccountDisplay'
import { ControlledDateField } from '../../common/ControlledDateField'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'

interface Props {
  accountId: string
  setDocumentsList: React.Dispatch<React.SetStateAction<AccountDocumentDto[]>>
  disabled?: boolean
}

export const AddDocumentModal: React.FC<Props> = ({ accountId, setDocumentsList, disabled = false }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone()

  const mutation = useMutation(
    async ({ data, file }: { data: UploadAccountDocumentDto; file: File }) => {
      const formData = new FormData()
      formData.append('file', file)
      if (data.type !== undefined) {
        formData.append('type', data.type)
      }

      if (data.name !== undefined) {
        formData.append('name', data.name)
      }

      if (data.reference !== undefined) {
        formData.append('reference', data.reference)
      }

      if (data.obtainedDate !== undefined) {
        formData.append('obtainedDate', data.obtainedDate)
      }

      return await backendClient.post(`accounts/${accountId}/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    {
      onSuccess: (data) => {
        closeDialog()
        reset({ type: undefined, name: '', reference: '', obtainedDate: '' })
        acceptedFiles.splice(0, 1)
        globalSnackbar.triggerSuccessMessage(`Votre document a bien été ajouté !`)
        setDocumentsList((prev) => [...prev, data.data])
      },
    }
  )

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm<UploadAccountDocumentDto>({
    defaultValues: {
      type: undefined,
      name: '',
      reference: '',
      obtainedDate: '',
    },
    mode: 'onChange',
  })

  const onSubmit = (data: UploadAccountDocumentDto) => {
    const uploadData: UploadAccountDocumentDto = {
      ...data,
    }

    if (acceptedFiles[0]) {
      mutation.mutate({ data: uploadData, file: acceptedFiles[0] })
    } else {
      globalSnackbar.triggerErrorMessage(`Vous devez ajouter un fichier !`)
    }
  }

  const type = watch('type')

  return (
    <>
      <Fab color="primary" variant="extended" onClick={openDialog} disabled={disabled}>
        <Add sx={{ mr: 1 }} />
        Ajouter un document
      </Fab>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Ajouter un document
              </Typography>

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'type'}
                options={Object.values(AccountDocumentDtoType).map((type: AccountDocumentDtoType) => ({
                  label: getReadableAccountDocumentType(type),
                  value: type,
                  color: getColorForAccountDocumentType(type),
                }))}
                label="Type de document"
                error={errors.type}
                requiredRule={'true'}
                defaultValue={''}
                size="small"
                chip={true}
              />

              {type === 'others' && (
                <TextField
                  label="Nom du document"
                  type="text"
                  fullWidth
                  size="small"
                  required
                  {...register('name', { required: 'Le nom est requis' })}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  sx={spacingItem}
                />
              )}
              {type === AccountDocumentDtoType.Asv || type === AccountDocumentDtoType.Acaced ? (
                <ControlledDateField
                  control={control}
                  validate={(value) => isValidPastDateCoherence(value) || !value}
                  fieldName={'obtainedDate'}
                  label="Date d'obtention/d'actualisation"
                  error={errors.obtainedDate}
                  requiredRule={"La date d'obtention est requise"}
                  size="small"
                  sx={spacingItem}
                />
              ) : (
                <TextField
                  label="Libellé"
                  {...register('reference')}
                  error={!!errors.reference}
                  helperText={errors.reference?.message}
                  type="text"
                  fullWidth
                  size="small"
                  sx={spacingItem}
                />
              )}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding={3}
                {...getRootProps({
                  sx: {
                    ...spacingItem,
                    border: 3,
                    borderRadius: 5,
                    borderStyle: 'dashed',
                    borderColor: isDragActive ? 'green' : blue,
                    backgroundColor: isDragActive
                      ? 'lightgreen'
                      : acceptedFiles[0]
                      ? setOpacity(colors.geyser, 0.4)
                      : 'transparent',
                    transition: 'all 0.3s',
                  },
                })}
              >
                <input {...getInputProps()} />
                <UploadFile sx={{ color: blue, fontSize: 64, mb: 1 }} />
                <Typography variant="h4" component="p" fontSize={20} textAlign="center">
                  Glissez-déposer ou
                  <br />
                  Insérer un document
                </Typography>
                <Box
                  marginTop={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    maxWidth: '100%',
                  }}
                >
                  <Typography variant="h4" component="p" fontSize={18} textAlign="center" sx={{ whiteSpace: 'nowrap' }}>
                    Votre document:
                  </Typography>
                  <Typography
                    variant="h4"
                    component="p"
                    fontSize={18}
                    textAlign="center"
                    color={colors.black}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                    }}
                  >
                    {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                  </Typography>
                </Box>
                <Button variant="contained" sx={{ width: '40%', mt: 1 }}>
                  Parcourir
                </Button>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton data-testid="apply-changes" variant="contained" type="submit" loading={mutation.isLoading}>
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
