import React, { useState } from 'react'
import {
  AccountDtoPermission,
  AdopterDto,
  AdopterDtoProfileHasBeenAccepted,
  AdopterFollowUpDto,
} from '../../../interactors/gen/backendClient'
import { ExpandLess, ExpandMore, Delete } from '@mui/icons-material'
import { Grid, TextField, Box, Button, Collapse } from '@mui/material'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { useAccountsStore } from '../../../store/AccountsStore'
import { DisplayChipLikeTextField } from '../../common/DisplayChipLikeTextField'
import { blueSky, spacingItem } from '../../theme'
import {
  getColorForAdopterFollowUpType,
  getColorForAdopterFollowUpVerdict,
  getReadableAdopterFollowUpType,
  getReadableAdopterFollowUpVerdict,
} from '../../../domain/Adopters/AccountDisplay'
import dayjs from 'dayjs'
import { EditFollowUpModal } from './EditFollowUpModal'

interface PropsItem {
  followUp: AdopterFollowUpDto
  followUps: Array<AdopterFollowUpDto>
  setFollowUps: (followUps: Array<AdopterFollowUpDto>) => void
  adopter: AdopterDto
}

export const FollowUpItem: React.FC<PropsItem> = ({ followUp, followUps, setFollowUps, adopter }) => {
  const accountsStore = useAccountsStore()
  const [isExpanded, setExpanded] = useState(false)

  const handleClickOnExpand = () => {
    setExpanded(!isExpanded)
  }
  const handleClickOnDelete = () => {
    setFollowUps(followUps.filter((aFollowUp) => followUp !== aFollowUp))
  }

  const sxField = { ...spacingItem, cursor: 'disabled', input: { cursor: 'not-allowed' } }

  const getAccountLabelFromId = (id: string): string => {
    if (!id) {
      return ''
    }

    const account = accountsStore.withRemovedMembers.find((account) => account.id === id)
    if (!account) {
      return `${id} (Introuvable)`
    }
    let label = account ? fullName(account) : ''

    if (account.id === accountsStore.connectedAccount?.id) {
      label = `Moi (${label})`
    }

    if (account.isDeleted) {
      label = `Compte supprimé (${label})`
    }

    return label
  }

  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={3} xs={12}>
          <DisplayChipLikeTextField
            label="Type de suivi"
            value={getReadableAdopterFollowUpType(followUp.type)}
            color={getColorForAdopterFollowUpType(followUp.type)}
          />
        </Grid>

        <Grid item sm={1.5} xs={12}>
          <TextField
            label="Date"
            type="text"
            fullWidth
            value={followUp.date ? dayjs(followUp.date).format('DD/MM/YYYY') : '..'}
            sx={sxField}
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item sm={2.75} xs={12}>
          <TextField
            label="Interlocuteur"
            type="text"
            fullWidth
            value={getAccountLabelFromId(followUp.interlocutorAccountId)}
            sx={sxField}
            multiline
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item sm={2.75} xs={12}>
          <DisplayChipLikeTextField
            label="Décision"
            value={followUp.verdict ? getReadableAdopterFollowUpVerdict(followUp.verdict) : 'Décision déjà prise'}
            color={followUp.verdict ? getColorForAdopterFollowUpVerdict(followUp.verdict) : blueSky}
          />
        </Grid>

        <Grid item sm={2} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%', pt: 2 }}>
            <Button onClick={handleClickOnExpand}>{isExpanded ? <ExpandLess /> : <ExpandMore />}</Button>
            <EditFollowUpModal
              followUp={followUp}
              followUps={followUps}
              setFollowUps={setFollowUps}
              adopter={adopter}
              disabled={isReader}
            />
            <Button
              onClick={handleClickOnDelete}
              sx={{ color: 'error.main' }}
              disabled={
                adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused ||
                adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormRefused ||
                adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted ||
                isReader
              }
            >
              <Delete />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={isExpanded}>
        <Grid container spacing={1}>
          <Grid item sm={3} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              label="Details"
              type="text"
              fullWidth
              value={followUp.description}
              sx={sxField}
              multiline
              size="small"
              inputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', marginY: 2 }}>
        <hr />
      </Box>
    </>
  )
}
