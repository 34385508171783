import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Fab, Dialog, DialogContent, Box, Typography, TextField, DialogActions, Button } from '@mui/material'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import {
  getReadableHostedFamilyFollowUpType,
  getColorForHostedFamilyFollowUpType,
} from '../../../../domain/Animal/AnimalDisplay'
import { AccountDtoRoles, AnimalDto } from '../../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { toDateOnly } from '../../../../utils/date/DateOnly'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { ColoredChipSelectField } from '../../../common/ColoredChipSelectField'
import { ControlledAutoCompleteWithCustomValue } from '../../../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateField } from '../../../common/ControlledDateField'
import { spacingItem } from '../../../theme'
import { HostedFamilyFollowUpDtoType } from '../../../../domain/Animal/HostedFamilyFollowUpDtoType'
import { MainHostedFamilyFollowUp } from './HostedFamillyFollowUpsTab'
import { omitEmptyString } from '../../../../utils/omitEmptyString'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../PATHS'
import dayjs from 'dayjs'
import { ControlledAutoCompleteWithTabs } from '../../../common/ControlledAutoCompleteWithTabs'
import { animalButtonShouldBeDisabled } from '../../../../utils/animalButtonShouldBeDisabled'
import { isDateOlderThanJPlusOne } from '../../../../utils/date/isDateOlderThanJPlusOne'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'

type FormParams = {
  type: HostedFamilyFollowUpDtoType
  date: string
  description?: string
  beforeAccountId?: string
  afterAccountId?: string
  memberInChargeAccountId?: string
  destination?: string
}

interface Props {
  animal: AnimalDto
  allFollowUps: Array<MainHostedFamilyFollowUp>
  setAllFollowUps: (allFollowUps: Array<MainHostedFamilyFollowUp>) => void
  disabled: boolean
}

export const NewHostedFamilyFollowUpModal: FC<Props> = ({ animal, allFollowUps, setAllFollowUps, disabled }) => {
  const queryParams = new URLSearchParams(location.search)
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const editAnimalMutation = useEditAnimalMutation({})
  const canEditAnimal = useCanEditAnimal()

  const customer = accountsStore.connectedCustomer

  const allHostFamilies = accountsStore.members.filter((account) => account.roles.includes(AccountDtoRoles.HostFamily))

  const allMembers = accountsStore.members.filter((account) => !account.roles.includes(AccountDtoRoles.Veterinary))

  const initialPlacementDate = allFollowUps.find(
    (followUp) => followUp.type === HostedFamilyFollowUpDtoType.InitialPlacement
  )?.date

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormParams>({
    defaultValues: {
      type: undefined,
      date: '',
      description: '',
      beforeAccountId: animal.responsibilities?.hostFamilyInChargeAccountId,
      afterAccountId: queryParams.get('field') === 'afterAccountId' ? queryParams.get('hostFamilyInCharge') || '' : '',
      memberInChargeAccountId:
        queryParams.get('field') === 'memberInChargeAccountId'
          ? queryParams.get('hostFamilyInCharge') || queryParams.get('memberInCharge') || ''
          : '',
      destination:
        queryParams.get('field') === 'destination'
          ? queryParams.get('hostFamilyInCharge') === null
            ? 'Box N°' + queryParams.get('cageNumber') ?? undefined
            : queryParams.get('hostFamilyInCharge') ?? undefined
          : '',
    },
    mode: 'onChange',
  })

  const onSubmit = (data: FormParams) => {
    const isTypeChangeOfHostFamily = data.type === HostedFamilyFollowUpDtoType.ChangeOfHostedFamily
    const newFollowUp = {
      ...data,
      beforeAccountId: isTypeChangeOfHostFamily ? data.beforeAccountId : '',
      afterAccountId: isTypeChangeOfHostFamily ? data.afterAccountId : '',
      memberInChargeAccountId: !isTypeChangeOfHostFamily ? data.memberInChargeAccountId : '',
      destination: !isTypeChangeOfHostFamily ? data.destination : '',
      date: toDateOnly(data.date),
      created: {
        by: accountsStore.connectedAccount?.id || '',
        at: dayjs().toISOString(),
      },
    }
    const newFollowUpWithoutEmptyString = omitEmptyString(newFollowUp)
    setAllFollowUps([...allFollowUps, newFollowUpWithoutEmptyString as MainHostedFamilyFollowUp])
    closeDialog()
    reset()
  }

  useEffect(() => {
    queryParams.get('card') === 'newHostedFamilyFollowUp' && openDialog()
  }, [queryParams.get('card')])

  const afterAccountId = watch('afterAccountId')
  useEffect(() => {
    if (afterAccountId === 'addAccount') {
      reset({ afterAccountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=familles&role=${AccountDtoRoles.HostFamily}&card=newHostedFamilyFollowUp&field=afterAccountId`
      )
    }
  }, [afterAccountId])

  const memberInCharge = watch('memberInChargeAccountId')
  useEffect(() => {
    if (memberInCharge === 'addAccount') {
      reset({ memberInChargeAccountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=familles&role=${AccountDtoRoles.Member}&card=newHostedFamilyFollowUp&field=memberInChargeAccountId`
      )
    }
  }, [memberInCharge])

  const destination = watch('destination')
  useEffect(() => {
    if (destination === 'addAccount') {
      reset({ destination: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=familles&role=${AccountDtoRoles.HostFamily}&card=newHostedFamilyFollowUp&field=destination`
      )
    } else if (destination === 'addCage') {
      reset({ destination: undefined })
      navigate(
        `${PATHS.association.absolute}?tab=cages&from=animaux/${animal.id}?tab=familles&card=newHostedFamilyFollowUp&field=destination`
      )
    }
  }, [destination])

  const watchType = watch('type')

  let primaryDestinationOptions = [
    { value: 'addAccount', label: 'Ajouter une famille en charge' },
    ...allHostFamilies.map((option) => ({ label: fullName(option), value: option.id })),
  ]
  if (
    animal.responsibilities?.hostFamilyInChargeAccountId != null &&
    watchType !== HostedFamilyFollowUpDtoType.InitialPlacement
  ) {
    primaryDestinationOptions = []
  }

  let typeOptions = [
    ...Object.values(HostedFamilyFollowUpDtoType)
      .filter((type) => type !== HostedFamilyFollowUpDtoType.Exit)
      .map((status: HostedFamilyFollowUpDtoType) => ({
        label: getReadableHostedFamilyFollowUpType(status),
        value: status,
        color: getColorForHostedFamilyFollowUpType(status),
      })),
  ]
  if (animal.responsibilities?.hostFamilyInChargeAccountId != null) {
    typeOptions = typeOptions.filter(
      (type) =>
        type.value === HostedFamilyFollowUpDtoType.ChangeOfHostedFamily ||
        type.value === HostedFamilyFollowUpDtoType.Shift
    )
  }
  if (animal.responsibilities?.cageNumber != null) {
    typeOptions = typeOptions.filter((type) => type.value === HostedFamilyFollowUpDtoType.Shift)
  }

  if (!canEditAnimal) {
    return null
  }

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        onClick={openDialog}
        disabled={animalButtonShouldBeDisabled(animal) || disabled}
      >
        <Add sx={{ mr: 1 }} />
        Ajouter un déplacement
      </Fab>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        >
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Ajouter un déplacement
              </Typography>
              <ColoredChipSelectField
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={errors.type}
                fieldName="type"
                size="small"
                label="Type de déplacement"
                options={typeOptions}
                requiredRule={'Le type de déplacement est requis'}
              />

              <ControlledDateField
                control={control}
                validate={(value) => isDateOlderThanJPlusOne(value) || value === null}
                minDate={dayjs(initialPlacementDate, 'DD/MM/YYYY')}
                maxDate={dayjs().add(1, 'day')}
                error={errors.date}
                fieldName={'date'}
                label="Date"
                requiredRule={'La date est requise'}
                size="small"
                sx={spacingItem}
              />

              {watchType === HostedFamilyFollowUpDtoType.ChangeOfHostedFamily && (
                <>
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    fieldName={'beforeAccountId'}
                    size="small"
                    options={allHostFamilies
                      .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                      .map((option) => ({ label: fullName(option), value: option.id }))}
                    label="Ancienne famille en charge"
                    error={errors.beforeAccountId}
                    requiredRule={"L'ancienne famille en charge est requise"}
                    defaultValue={animal.responsibilities?.hostFamilyInChargeAccountId || ''}
                    sx={spacingItem}
                    disabled
                  />

                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    fieldName={'afterAccountId'}
                    size="small"
                    options={[
                      { value: 'addAccount', label: 'Ajouter une famille en charge' },
                      ...allHostFamilies
                        .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                        .map((option) => ({ label: fullName(option), value: option.id })),
                    ]}
                    label="Nouvelle famille en charge"
                    error={errors.afterAccountId}
                    requiredRule={'La nouvelle famille en charge est requise'}
                    defaultValue={''}
                    sx={spacingItem}
                    highlight
                  />
                </>
              )}

              {(watchType === HostedFamilyFollowUpDtoType.InitialPlacement ||
                watchType === HostedFamilyFollowUpDtoType.Shift) && (
                <>
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    fieldName={'memberInChargeAccountId'}
                    size="small"
                    options={[
                      { value: 'addAccount', label: 'Ajouter un membre' },
                      ...allMembers
                        .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                        .map((option) => ({ label: fullName(option), value: option.id })),
                    ]}
                    label="Accompagnateur"
                    error={errors.memberInChargeAccountId}
                    requiredRule={"L'accompagnateur est requis"}
                    defaultValue={''}
                    sx={spacingItem}
                    highlight
                  />

                  <ControlledAutoCompleteWithTabs
                    control={control}
                    fieldName={'destination'}
                    defaultValue={''}
                    size="small"
                    primaryTabLabel="Famille en charge"
                    primaryOptions={primaryDestinationOptions}
                    secondaryTabLabel="Boxes"
                    secondaryOptions={[
                      { value: 'addCage', label: 'Ajouter une cage' },
                      ...(customer?.cages || [])
                        .sort((a, b) => Number(a.cageNumber) - Number(b.cageNumber))
                        .map((option) => ({
                          label: `Box N°${option.cageNumber}`,
                          value: `Box N°${option.cageNumber}`,
                        })),
                    ]}
                    label="Destination"
                    error={undefined}
                    requiredRule={'La destination est requise'}
                    highlight
                    sx={spacingItem}
                    noOptionsTexts={["Pour ce cas, sélectionnez le motif changement de Famille d'accueil", 'No option']}
                  />
                </>
              )}

              <TextField
                id="description-input"
                label="Détails"
                type="text"
                fullWidth
                {...register('description')}
                sx={spacingItem}
                size="small"
                multiline
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              loading={editAnimalMutation.isLoading}
            >
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
