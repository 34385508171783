export interface TemplateVariable {
  label: string
  value: string
}

export const templateVariablesList: TemplateVariable[] = [
  { label: "Prénom de l'adoptant", value: '{{adopter_first_name}}' },
  { label: "Nom de l'adoptant", value: '{{adopter_last_name}}' },
  { label: "Email de l'adoptant", value: '{{adopter_email}}' },
  { label: "Téléphone de l'adoptant", value: '{{adopter_phone}}' },
  { label: "Adresse de l'adoptant", value: '{{adopter_address}}' },
  { label: "Code postal de l'adoptant", value: '{{adopter_postal_code}}' },
  { label: "Ville de l'adoptant", value: '{{adopter_city}}' },
  { label: 'Date du jour', value: '{{current_date}}' },
  { label: "Nom de l'animal", value: '{{animal_name}}' },
  { label: "Sexe de l'animal", value: '{{animal_gender}}' },
  { label: "Date de naissance de l'animal", value: '{{animal_birth_date}}' },
  { label: "Numéro d'identification de l'animal", value: '{{animal_identification_number}}' },
  { label: "Race de l'animal", value: '{{animal_breed}}' },
  { label: "Montant de l'adoption", value: '{{adoption_fee}}' },
  { label: "Type d'adoption", value: '{{adoption_type}}' },
  { label: "Date d'adoption prévisionnelle", value: '{{adoption_date}}' },
  { label: "Nouveau nom de l'animal", value: '{{animal_new_name}}' },
  { label: 'Signes distinctifs', value: '{{identifying_marks}}' },
  { label: 'Statut vaccinal', value: '{{vaccine_status}}' },
  { label: 'Date de stérilisation', value: '{{sterilization_date}}' },
  { label: 'Date rappel vaccin', value: '{{vaccine_recall_date}}' },
  { label: 'Date rappel vermifuge', value: '{{deworming_recall_date}}' },
  { label: 'Date rappel antiparasitaire', value: '{{flea_control_recall_date}}' },
]

export const translateVariablePlaceholder = (placeholder: string): string => {
  const variable = templateVariablesList.find((variable) => variable.value === placeholder)
  return variable ? variable.label : placeholder
}
