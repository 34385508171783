import { Add, Delete, Edit, ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  SxProps,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { colors, theme } from '../../../theme'
import { FC, useEffect, useState } from 'react'
import {
  getColorForAnimalHealthTestStatus,
  getColorForOperationType,
  getReadableAnimalHealthTestStatus,
  getReadableOperationType,
} from '../../../../domain/Animal/AnimalDisplay'
import {
  AccountDtoRoles,
  AnimalDto,
  AnimalDtoSpecies,
  AnimalHealthDtoFeLV,
  AnimalHealthDtoPathologies,
  AnimalHealthOperationDto,
  AnimalHealthOperationDtoType,
  AnimalHealthTestResultDto,
  AnimalHealthTestResultDtoType,
  AnimalVaccinationDtoVaccinationStatus,
} from '../../../../interactors/gen/backendClient'
import { spacingItem } from '../../../theme'
import { DisplayChipLikeTextField } from '../../../common/DisplayChipLikeTextField'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import { LoadingButton } from '@mui/lab'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { ColoredChipSelectField } from '../../../common/ColoredChipSelectField'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { isValidPastDateCoherence } from '../../../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../../../common/ControlledDateField'
import { toDateOnly } from '../../../../utils/date/DateOnly'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import dayjs from 'dayjs'
import { ControlledAutoCompleteWithCustomValue } from '../../../common/ControlledAutocompleteWithCustomValue'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../PATHS'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { ControlledMultipleAutoCompleteWithCategories } from '../../../common/ControlledMultipleAutocompleteWithCategories'
import { setOpacity } from '../../../../utils/setOpacity'
import { isColorDark } from '../../../../utils/isColorDark'
import { AnimalsClient } from '../../../../interactors/clients/AnimalsClient'
import { isAdmin } from '../../../../domain/Account/isAdmin'
import {
  CatTestPathologies,
  DogTestPathologies,
  FerretTestPathologies,
  GlobalTestPathologies,
} from '../../../../domain/Animal/AnimalPathologies'
import { isInEnum } from '../../../../utils/isInEnum'
import { nanoid } from 'nanoid'

interface Props {
  animal: AnimalDto
  operations: Array<AnimalHealthOperationDto>
  setOperations: (operations: Array<AnimalHealthOperationDto>) => void
  sx?: SxProps<Theme>
}

export const AnimalHealthOperations: FC<Props> = ({ operations, setOperations, sx, animal }) => {
  return (
    <>
      <Box sx={sx}>
        {operations
          .sort((a, b) => {
            const dateA = dayjs(a.date, 'DD/MM/YYYY')
            const dateB = dayjs(b.date, 'DD/MM/YYYY')
            return dateA.isAfter(dateB) ? -1 : 1
          })
          .map((operation, index) => (
            <AnimalHealthOperation
              key={index}
              animal={animal}
              operation={operation}
              index={index}
              operations={operations}
              setOperations={setOperations}
            />
          ))}
      </Box>
    </>
  )
}

const sxField = { ...spacingItem, cursor: 'disabled', input: { cursor: 'not-allowed' } }

interface PropsItem {
  animal: AnimalDto
  operation: AnimalHealthOperationDto
  index: number
  operations: Array<AnimalHealthOperationDto>
  setOperations: (operations: Array<AnimalHealthOperationDto>) => void
}

export const AnimalHealthOperation: FC<PropsItem> = ({ operation, operations, setOperations, index, animal }) => {
  const [isExpanded, setExpanded] = useState(false)

  const handleClickOnExpand = () => {
    setExpanded(!isExpanded)
  }
  const handleClickOnDelete = () => {
    setOperations(operations.filter((_, anIndex) => index !== anIndex))
  }

  const typesWithDisabledButton: AnimalHealthOperationDtoType[] = [
    AnimalHealthOperationDtoType.AnnualReminder,
    AnimalHealthOperationDtoType.Deworming,
    AnimalHealthOperationDtoType.FleaControl,
  ]
  const deleteButtonDisabled =
    dayjs(operation.date, 'DD/MM/YYYY').isAfter(dayjs()) &&
    operation.type.some((type) => typesWithDisabledButton.includes(type))

  const handleClickOnValidate = () => {
    const newOperation = { ...operation, validated: true }
    setOperations(operations.map((op) => (op.id === operation.id ? newOperation : op)))
  }

  const isValidated = operation.validated === true || !operation.needsValidation

  const showValidateButton =
    operation.needsValidation === true &&
    operation.validated !== true &&
    (dayjs(operation.date, 'DD/MM/YYYY').isBefore(dayjs(), 'day') ||
      dayjs(operation.date, 'DD/MM/YYYY').isSame(dayjs(), 'day'))

  const canEditAnimal = useCanEditAnimal()

  const accountsStore = useAccountsStore()

  const allVeterinaries = accountsStore.members.filter((account) => account.roles.includes(AccountDtoRoles.Veterinary))

  const veterinary = allVeterinaries.find((account) => account.id === operation.veterinary)

  const memberInCharge = accountsStore.members.find((account) => account.id === operation.memberInCharge)

  return (
    <>
      <Grid container spacing={{ md: 2, xs: 1 }}>
        <Grid item md={0.5} xs={1}>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            {isValidated ? '✔️' : dayjs(operation.date, 'DD/MM/YYYY').isAfter(dayjs(), 'day') ? '⌛' : '❌'}
          </Box>
        </Grid>
        <Grid item md={2.5} xs={5}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              gap: 1,
              ...sxField,
              mt: 1,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: 1,
                border: '1px solid',
                borderRadius: 1,
                borderColor: '#c4c4c4',
                p: '4px',
                '&:hover': {
                  borderColor: '#000000',
                },
                width: '100%',
              }}
            >
              {operation.type && operation.type.length > 0
                ? operation.type.map((type) => (
                    <Chip
                      key={type}
                      label={getReadableOperationType(type)}
                      style={{
                        backgroundColor: setOpacity(getColorForOperationType(type), 0.8),
                        color: isColorDark(getColorForOperationType(type)) ? 'white' : 'black',
                      }}
                    />
                  ))
                : null}
            </Box>
          </Box>
        </Grid>

        <Grid item md={2} xs={6}>
          <TextField
            label="Date"
            type="text"
            fullWidth
            value={operation.date}
            sx={sxField}
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item md={2.5} xs={6}>
          <TextField
            label="Membre en charge"
            type="text"
            fullWidth
            value={memberInCharge ? fullName(memberInCharge) : ''}
            sx={sxField}
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item md={2.5} xs={6}>
          {countOptionalFields(operation) === 1 ? (
            <RenderOneOptionalField operation={operation} />
          ) : showValidateButton ? (
            <Box
              sx={{
                ...sxField,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button variant="contained" onClick={() => handleClickOnValidate()}>
                Valider le rdv
              </Button>
            </Box>
          ) : (
            <TextField
              label="Vétérinaire"
              type="text"
              fullWidth
              value={veterinary ? fullName(veterinary) : ''}
              sx={sxField}
              size="small"
              inputProps={{ readOnly: true }}
            />
          )}
        </Grid>

        <Grid item md={2} sm={12} xs={12}>
          <Box
            display="flex"
            alignItems="center"
            sx={{ height: '100%', width: '100%', justifyContent: 'center', paddingTop: 2 }}
          >
            <Button onClick={handleClickOnExpand}>{isExpanded ? <ExpandLess /> : <ExpandMore />}</Button>
            <AnimalHealthEditOperationModal
              index={index}
              operations={operations}
              setOperations={setOperations}
              animal={animal}
              operation={operation}
              disabled={!canEditAnimal}
            />
            <Button
              onClick={handleClickOnDelete}
              sx={{ color: 'error.main' }}
              disabled={!canEditAnimal || deleteButtonDisabled}
            >
              <Delete />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={isExpanded}>
        <Grid container spacing={{ md: 2, xs: 1 }}>
          <Grid item md={1} sm={0} display={{ xs: 'none', md: 'block' }}></Grid>
          {countOptionalFields(operation) === 1 && (
            <Grid item md={2} sm={2.5} xs={8}>
              <TextField
                label="Membre en charge"
                type="text"
                fullWidth
                value={memberInCharge ? fullName(memberInCharge) : ''}
                sx={sxField}
                size="small"
                inputProps={{ readOnly: true }}
              />
            </Grid>
          )}
          <Grid item md={1.5} sm={1.5} xs={countOptionalFields(operation) === 1 ? 4 : 12}>
            <TextField
              label="Coût €"
              type="text"
              fullWidth
              value={operation.price}
              sx={sxField}
              size="small"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid
            item
            md={countOptionalFields(operation) === 1 ? 5.5 : 7.5}
            sm={countOptionalFields(operation) === 1 ? 8 : 10.5}
            xs={12}
          >
            <TextField
              label="Details"
              type="text"
              fullWidth
              value={operation.additionalInfo}
              sx={sxField}
              size="small"
              multiline
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item md={2} sm={0} display={{ xs: 'none', md: 'block' }}></Grid>

          {countOptionalFields(operation) > 1 && (
            <>
              <Grid item md={1} sm={0} display={{ xs: 'none', md: 'block' }}></Grid>
              {operation.identificationNumber && (
                <Grid item md={2.25} sm={6} xs={6}>
                  <TextField
                    label="Numéro d'identification"
                    type="text"
                    fullWidth
                    value={operation.identificationNumber}
                    sx={{ ...sxField, mt: 0 }}
                    size="small"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              )}
              {operation.tattooNumber && (
                <Grid item md={2.25} sm={6} xs={6}>
                  <TextField
                    label="Numéro de tatouage"
                    type="text"
                    fullWidth
                    value={operation.tattooNumber}
                    sx={{ ...sxField, mt: 0 }}
                    size="small"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              )}
              {operation.testResults &&
                operation.testResults.length > 1 &&
                operation.testResults.map((testResult) => (
                  <Grid
                    key={`${testResult.type}-${operation.date}`}
                    item
                    md={2.25}
                    sm={6}
                    xs={6}
                    sx={{ mt: { md: -2, sm: -1, xs: -1 } }}
                  >
                    <DisplayChipLikeTextField
                      label={`Résultat ${getReadableOperationType(testResult.type)}`}
                      value={getReadableAnimalHealthTestStatus(testResult.result)}
                      color={getColorForAnimalHealthTestStatus(testResult.result)}
                    />
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </Collapse>
      <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', marginY: 2 }}>
        <hr />
      </Box>
    </>
  )
}

type FormParams = {
  type: Array<AnimalHealthOperationDtoType>
  veterinary: string
  date: string // DateString
  price: string
  additionalInfo: string // DateString
  memberInCharge: string
  testResults: AnimalHealthTestResultDto[]
  identificationNumber: string
  tattooNumber: string
  orderGenerated?: boolean
}

interface PropsNewOperation {
  animal: AnimalDto
  operations: Array<AnimalHealthOperationDto>
  setOperations: (operations: Array<AnimalHealthOperationDto>) => void
  disabled?: boolean
}

export const AnimalHealthNewOperationModal: FC<PropsNewOperation> = ({
  animal,
  operations,
  setOperations,
  disabled = false,
}) => {
  const queryParams = new URLSearchParams(location.search)
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const editAnimalMutation = useEditAnimalMutation({})
  const canEditAnimail = useCanEditAnimal()
  const [needsAnotherVaccine, setNeedsAnotherVaccine] = useState(true)

  useEffect(() => {
    if (queryParams.get('card') === 'animalHealthOperationsCard') {
      openDialog()
    }
  }, [])

  const allVetetinaries = accountsStore.members.filter((account) => account.roles.includes(AccountDtoRoles.Veterinary))

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormParams>({
    defaultValues: {
      type: [],
      veterinary: queryParams.get('veterinary') || animal.responsibilities?.veterinaryChargeAccountId,
      date: '',
      price: '',
      additionalInfo: '',
      memberInCharge:
        queryParams.get('memberInCharge') ||
        animal.responsibilities?.hostFamilyInChargeAccountId ||
        animal.responsibilities?.memberInChargeAccountId,
      testResults: [],
      identificationNumber: '',
      tattooNumber: '',
      orderGenerated: false,
    },
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'testResults', // unique name for your Field Array
  })

  const onSubmit = async (data: FormParams) => {
    const newOperation = {
      id: nanoid(),
      ...data,
      date: toDateOnly(data.date),
      needsValidation: dayjs(data.date).isAfter(dayjs()),
    }
    if (
      !needsAnotherVaccine ||
      data.type.includes(AnimalHealthOperationDtoType.ThirdInjection) ||
      data.type.includes(AnimalHealthOperationDtoType.AnnualReminder)
    ) {
      animal.vaccination.vaccinationStatus = AnimalVaccinationDtoVaccinationStatus.Completed
    }
    if (newOperation.type.includes(AnimalHealthOperationDtoType.FirstInjection) && needsAnotherVaccine) {
      animal.vaccination.vaccinationStatus = AnimalVaccinationDtoVaccinationStatus.InProgress
    }
    if (data.orderGenerated) {
      await AnimalsClient.downloadVeterinaryOrderPdf(newOperation as AnimalHealthOperationDto, animal.id)
    }
    setOperations([...operations, newOperation as AnimalHealthOperationDto])
    closeDialog()
    setValue('type', [])
    setValue('veterinary', animal.responsibilities?.veterinaryChargeAccountId || '')
    setValue(
      'memberInCharge',
      animal.responsibilities?.hostFamilyInChargeAccountId || animal.responsibilities?.memberInChargeAccountId || ''
    )
    setValue('date', '')
    setValue('price', '')
    setValue('additionalInfo', '')
    setValue('testResults', [])
    setValue('identificationNumber', '')
    setValue('tattooNumber', '')
    setValue('orderGenerated', false)
  }

  const filterOperationTypesBySpeciesVaccinationHistoryAndStatus = (
    animal: AnimalDto
  ): AnimalHealthOperationDtoType[] => {
    const allTypes = Object.values(AnimalHealthOperationDtoType)

    // Filter operation types based on species
    function filterOperationTypesForSpecies(animalSpecies: AnimalDtoSpecies) {
      let filterOutPathologies: Partial<AnimalHealthOperationDtoType[]> = []
      switch (animalSpecies) {
        case AnimalDtoSpecies.Dog:
          filterOutPathologies = CatTestPathologies.concat(FerretTestPathologies).filter(
            (pathology) => !DogTestPathologies.includes(pathology)
          )
          break
        case AnimalDtoSpecies.Cat:
          filterOutPathologies = DogTestPathologies.concat(FerretTestPathologies).filter(
            (pathology) => !CatTestPathologies.includes(pathology)
          )
          break
        case AnimalDtoSpecies.Ferret:
          filterOutPathologies = DogTestPathologies.concat(CatTestPathologies).filter(
            (pathology) => !FerretTestPathologies.includes(pathology)
          )
          break
        default:
          filterOutPathologies = DogTestPathologies.concat(CatTestPathologies)
            .concat(FerretTestPathologies)
            .filter((pathology) => !GlobalTestPathologies.includes(pathology))
          break
      }

      // Include all operation types except those specifically filtered out for the species
      return allTypes.filter((type) => !filterOutPathologies.includes(type))
    }

    let operationTypes = filterOperationTypesForSpecies(animal.species)

    // Determine which vaccination types are already done
    const completedVaccinations = animal.health.operations
      .flatMap((operation) => operation.type)
      .filter((type) =>
        (
          [
            AnimalHealthOperationDtoType.FirstInjection,
            AnimalHealthOperationDtoType.SecondInjection,
            AnimalHealthOperationDtoType.ThirdInjection,
          ] as AnimalHealthOperationDtoType[]
        ).includes(type)
      )

    // Exclude completed vaccinations from operation types
    operationTypes = operationTypes.filter((type) => !completedVaccinations.includes(type))

    // Filter Different injection based on vaccination status
    const { vaccinationStatus } = animal.vaccination
    if (vaccinationStatus) {
      if (
        (
          [
            AnimalVaccinationDtoVaccinationStatus.Completed,
            AnimalVaccinationDtoVaccinationStatus.NotDoing,
          ] as AnimalVaccinationDtoVaccinationStatus[]
        ).includes(vaccinationStatus)
      ) {
        operationTypes = operationTypes.filter(
          (type) =>
            type !== AnimalHealthOperationDtoType.SecondInjection &&
            type !== AnimalHealthOperationDtoType.ThirdInjection &&
            type !== AnimalHealthOperationDtoType.FirstInjection
        )
      }
    }

    return operationTypes
  }

  const specificTypes = filterOperationTypesBySpeciesVaccinationHistoryAndStatus(animal)

  const typeOfOperation = watch('type')

  useEffect(() => {
    const handleTypeChange = (selectedTypes: AnimalHealthOperationDtoType[]) => {
      // Identify new types that need to be added
      const newTypesToAdd = selectedTypes.filter(
        (type) =>
          isInEnum(AnimalHealthTestResultDtoType, type) && // Ensure type is valid
          !fields.some((field) => field.type === type) // Check if this type is not already added
      )

      // Clear existing testResults that are not in selectedTypes anymore
      fields.forEach((field, index) => {
        if (!selectedTypes.includes(field.type as AnimalHealthOperationDtoType)) {
          remove(index)
        }
      })

      // Append new testResults fields for new types
      newTypesToAdd.forEach((type) => {
        if (isInEnum(AnimalHealthTestResultDtoType, type)) {
          append({ type: type as AnimalHealthTestResultDtoType, result: 'unknown' }) // Append a new object for each new, unique test
        }
      })
    }

    handleTypeChange(typeOfOperation)
  }, [typeOfOperation, fields, append, remove])

  const memberInCharge = watch('memberInCharge')
  useEffect(() => {
    if (memberInCharge === 'addAccount') {
      reset({ memberInCharge: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=sante&role=${AccountDtoRoles.Member}&card=animalHealthOperationsCard`
      )
    }
  }, [memberInCharge])

  const veterinary = watch('veterinary')
  useEffect(() => {
    if (veterinary === 'addAccount') {
      reset({ veterinary: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=sante&role=${AccountDtoRoles.Veterinary}&card=animalHealthOperationsCard`
      )
    }
  }, [veterinary])

  if (!canEditAnimail) {
    return null
  }

  const optionsWithCategories = [
    {
      title: 'Primovaccination et rappel',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.FirstInjection,
              AnimalHealthOperationDtoType.SecondInjection,
              AnimalHealthOperationDtoType.ThirdInjection,
              AnimalHealthOperationDtoType.AnnualReminder,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Identification',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.IdentificationChip,
              /* AnimalHealthOperationDtoType.EarMarking, */
              AnimalHealthOperationDtoType.Tatoo,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Opérations',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.SterilizedOrNeutered,
              AnimalHealthOperationDtoType.Implant,
              AnimalHealthOperationDtoType.Surgery,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Soins courants',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.FleaControl,
              AnimalHealthOperationDtoType.Deworming,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Autres visites vétérinaires',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.Others,
              AnimalHealthOperationDtoType.Identification,
              AnimalHealthOperationDtoType.BloodTest,
              AnimalHealthOperationDtoType.Hospitalization,
              AnimalHealthOperationDtoType.MedicalAppointment,
              AnimalHealthOperationDtoType.Treatment,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Tests/Diagnostiques',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthDtoPathologies.CanineDistemper,
              AnimalHealthDtoPathologies.RubarthHepatitis,
              AnimalHealthDtoPathologies.Leptospirosis,
              AnimalHealthDtoPathologies.Rabies,
              AnimalHealthDtoPathologies.Parvovirus,
              AnimalHealthDtoPathologies.KennelCough,
              AnimalHealthDtoPathologies.Herpesvirus,
              AnimalHealthDtoPathologies.Babesiosis,
              AnimalHealthDtoPathologies.LymeDisease,
              AnimalHealthDtoPathologies.Diabetic,
              AnimalHealthDtoPathologies.Typhus,
              AnimalHealthDtoPathologies.Chlamydiosis,
              AnimalHealthDtoPathologies.Coryza,
              AnimalHealthOperationDtoType.Fiv,
              AnimalHealthOperationDtoType.FeLv,
              AnimalHealthDtoPathologies.Fip,
              AnimalHealthOperationDtoType.GoodHealthCertificate,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ]

  const orderGenerated = watch('orderGenerated')

  return (
    <>
      <Fab color="primary" variant="extended" onClick={openDialog} disabled={disabled}>
        <Add sx={{ mr: 1 }} />
        Ajouter un nouvel acte
      </Fab>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Ajouter un nouvel acte
              </Typography>
              <ControlledMultipleAutoCompleteWithCategories
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={undefined}
                fieldName="type"
                size="small"
                label="Motif"
                options={optionsWithCategories}
                requiredRule={'Le motif est requis'}
              />
              <ControlledDateField
                control={control}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                error={errors.date}
                fieldName={'date'}
                label="Date"
                requiredRule={undefined}
                size="small"
                sx={spacingItem}
              />

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'veterinary'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un vétérinaire' },
                  ...allVetetinaries
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Vétérinaire"
                error={errors.veterinary}
                requiredRule={orderGenerated ? 'Le vétérinaire est requis' : undefined}
                defaultValue={animal.responsibilities?.veterinaryChargeAccountId || ''}
                sx={spacingItem}
                highlight
              />

              {fields.map((field, index) => (
                <ColoredChipSelectField
                  key={field.id}
                  sx={spacingItem}
                  control={control}
                  fieldName={`testResults.${index}.result`}
                  label={`Résultat ${getReadableOperationType(field.type)}`}
                  options={Object.values(AnimalHealthDtoFeLV).map((status: AnimalHealthDtoFeLV) => ({
                    label: getReadableAnimalHealthTestStatus(status),
                    value: status,
                    color: getColorForAnimalHealthTestStatus(status),
                  }))}
                  error={undefined}
                  requiredRule={undefined}
                  size="small"
                />
              ))}

              {typeOfOperation.some((type) => type === AnimalHealthOperationDtoType.IdentificationChip) && (
                <TextField
                  id="identificationNumber"
                  label="Numéro d'identification"
                  type="text"
                  fullWidth
                  {...register('identificationNumber')}
                  sx={spacingItem}
                  size="small"
                />
              )}

              {typeOfOperation.some((type) => type === AnimalHealthOperationDtoType.Tatoo) && (
                <TextField
                  id="tattooNumber"
                  label="Numéro de tatouage"
                  type="text"
                  fullWidth
                  {...register('tattooNumber')}
                  sx={spacingItem}
                  size="small"
                />
              )}

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'memberInCharge'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un bénévole' },
                  ...accountsStore.members
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Membre en charge"
                error={errors.memberInCharge}
                requiredRule={orderGenerated ? 'Le membre en charge est requis' : undefined}
                defaultValue={animal.responsibilities?.memberInChargeAccountId || ''}
                sx={spacingItem}
                highlight
              />

              <TextField
                id="price"
                label="Coût €"
                type="text"
                fullWidth
                {...register('price')}
                sx={spacingItem}
                size="small"
              />

              <TextField
                id="additional-info-input"
                label="Détails"
                type="text"
                fullWidth
                {...register('additionalInfo')}
                sx={spacingItem}
                size="small"
                multiline
              />

              {typeOfOperation.some(
                (type) =>
                  type === AnimalHealthOperationDtoType.FirstInjection ||
                  type === AnimalHealthOperationDtoType.SecondInjection
              ) && (
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...spacingItem, gap: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography fontSize={16} fontWeight={700}>
                      Un autre injection sera-t-elle nécessaire ?<span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Tooltip title="Dans le cadre de la primo-vaccination, une autre injection sera-t-elle requise après celle-ci ?">
                      <InfoOutlined sx={{ color: theme.palette.primary.main }} />
                    </Tooltip>
                  </Box>
                  <ToggleButtonGroup
                    exclusive
                    color="info"
                    value={needsAnotherVaccine}
                    onChange={(_, newValue) => {
                      // Set the value manually
                      setNeedsAnotherVaccine(newValue)
                    }}
                    fullWidth
                    aria-label="user want to assign past adoption"
                  >
                    <ToggleButton value={true} aria-label="Oui">
                      Oui
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="Non">
                      Non
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}

              {accountsStore.connectedAccount && isAdmin(accountsStore.connectedAccount) ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...spacingItem, gap: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography fontSize={16} fontWeight={700}>
                      Générer automatiquement un bon vétérinaire ?<span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Tooltip title="Nous pouvons vous générer automatiquement un bon de commande à destination du vétérinaire">
                      <InfoOutlined sx={{ color: theme.palette.primary.main }} />
                    </Tooltip>
                  </Box>
                  <Controller
                    control={control}
                    name="orderGenerated"
                    render={({ field }) => (
                      <ToggleButtonGroup
                        exclusive
                        color="info"
                        {...field}
                        onChange={(_, newValue) => {
                          field.onChange(newValue)
                        }}
                        fullWidth
                        aria-label="user want to generate veterinary order form"
                      >
                        <ToggleButton value={true} aria-label="Oui">
                          Oui
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="Non">
                          Non
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                </Box>
              ) : (
                <Typography fontSize={16} fontWeight={700} sx={{ ...spacingItem }}>
                  Si nécessaire, demander à un administrateur de générer un bon vétérinaire pour ce rendez-vous
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              loading={editAnimalMutation.isLoading}
            >
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

interface PropsEditOperation {
  animal: AnimalDto
  operations: Array<AnimalHealthOperationDto>
  operation: AnimalHealthOperationDto
  setOperations: (operations: Array<AnimalHealthOperationDto>) => void
  index: number
  disabled?: boolean
}

export const AnimalHealthEditOperationModal: FC<PropsEditOperation> = ({
  animal,
  operation,
  operations,
  setOperations,
  index,
  disabled = false,
}) => {
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const accountsStore = useAccountsStore()
  const editAnimalMutation = useEditAnimalMutation({})

  const allVetetinaries = accountsStore.members.filter((account) => account.roles.includes(AccountDtoRoles.Veterinary))

  useEffect(() => {
    if (queryParams.get('card') === JSON.stringify(operation)) {
      openDialog()
    }
  }, [])

  const getDefaultValues = (operation: AnimalHealthOperationDto): FormParams => ({
    type: operation.type,
    veterinary: queryParams.get('veterinary') || operation.veterinary || '',
    date: operation.date,
    price: operation.price || '',
    additionalInfo: operation.additionalInfo || '',
    testResults: operation.testResults || [],
    memberInCharge: queryParams.get('memberInCharge') || operation.memberInCharge || '',
    identificationNumber: operation.identificationNumber || '',
    tattooNumber: operation.tattooNumber || '',
    orderGenerated: operation.orderGenerated || false,
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useFormExtended(operation, getDefaultValues)

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'testResults', // unique name for your Field Array
  })

  const onSubmit = async (data: FormParams) => {
    const newOperation = {
      ...data,
      id: operation.id,
      date: toDateOnly(data.date),
      needsValidation: !operation.validated
        ? dayjs(data.date, 'DD/MM/YYYY').isAfter(dayjs()) || dayjs(data.date, 'DD/MM/YYYY').isSame(dayjs(), 'day')
        : false,
    }

    if ((!operation.orderGenerated && data.orderGenerated) || (operation.orderGenerated && !data.orderGenerated)) {
      await AnimalsClient.downloadVeterinaryOrderPdf(newOperation as AnimalHealthOperationDto, animal.id)
    }

    // create a copy of operations
    const updatedOperations = [...operations]

    // replace the operation at the given index
    updatedOperations[index] = newOperation as AnimalHealthOperationDto

    // update the state
    setOperations(updatedOperations)

    closeDialog()
  }

  const allTypes = Object.values(AnimalHealthOperationDtoType)

  // Filter operation types based on species
  function filterOperationTypesForSpecies(animalSpecies: AnimalDtoSpecies) {
    let filterOutPathologies: Partial<AnimalHealthOperationDtoType[]> = []
    switch (animalSpecies) {
      case AnimalDtoSpecies.Dog:
        filterOutPathologies = CatTestPathologies.concat(FerretTestPathologies).filter(
          (pathology) => !DogTestPathologies.includes(pathology)
        )
        break
      case AnimalDtoSpecies.Cat:
        filterOutPathologies = DogTestPathologies.concat(FerretTestPathologies).filter(
          (pathology) => !CatTestPathologies.includes(pathology)
        )
        break
      case AnimalDtoSpecies.Ferret:
        filterOutPathologies = DogTestPathologies.concat(CatTestPathologies).filter(
          (pathology) => !FerretTestPathologies.includes(pathology)
        )
        break
      default:
        filterOutPathologies = DogTestPathologies.concat(CatTestPathologies)
          .concat(FerretTestPathologies)
          .filter((pathology) => !GlobalTestPathologies.includes(pathology))
        break
    }

    // Include all operation types except those specifically filtered out for the species
    return allTypes.filter((type) => !filterOutPathologies.includes(type))
  }

  const specificTypes: AnimalHealthOperationDtoType[] = filterOperationTypesForSpecies(animal.species)

  const typeOfOperation = watch('type')

  useEffect(() => {
    const handleTypeChange = (selectedTypes: AnimalHealthOperationDtoType[]) => {
      // Identify new types that need to be added
      const newTypesToAdd = selectedTypes.filter(
        (type) =>
          isInEnum(AnimalHealthTestResultDtoType, type) && // Ensure type is valid
          !fields.some((field) => field.type === type) // Check if this type is not already added
      )

      // Clear existing testResults that are not in selectedTypes anymore
      fields.forEach((field, index) => {
        if (!selectedTypes.includes(field.type as AnimalHealthOperationDtoType)) {
          remove(index)
        }
      })

      // Append new testResults fields for new types
      newTypesToAdd.forEach((type) => {
        if (isInEnum(AnimalHealthTestResultDtoType, type)) {
          append({ type: type as AnimalHealthTestResultDtoType, result: 'unknown' }) // Append a new object for each new, unique test
        }
      })
    }

    handleTypeChange(typeOfOperation)
  }, [typeOfOperation, fields, append, remove])

  const memberInCharge = watch('memberInCharge')
  useEffect(() => {
    if (memberInCharge === 'addAccount') {
      reset({ memberInCharge: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=sante&role=${
          AccountDtoRoles.Member
        }&card=${JSON.stringify(operation)}`
      )
    }
  }, [memberInCharge])

  const veterinary = watch('veterinary')
  useEffect(() => {
    if (veterinary === 'addAccount') {
      reset({ veterinary: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=sante&role=${
          AccountDtoRoles.Veterinary
        }&card=${JSON.stringify(operation)}`
      )
    }
  }, [veterinary])

  const optionsWithCategories = [
    {
      title: 'Primovaccination et rappel',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.FirstInjection,
              AnimalHealthOperationDtoType.SecondInjection,
              AnimalHealthOperationDtoType.ThirdInjection,
              AnimalHealthOperationDtoType.AnnualReminder,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Identification',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.IdentificationChip,
              /* AnimalHealthOperationDtoType.EarMarking, */
              AnimalHealthOperationDtoType.Tatoo,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Opérations',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.SterilizedOrNeutered,
              AnimalHealthOperationDtoType.Implant,
              AnimalHealthOperationDtoType.Surgery,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Soins courants',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.FleaControl,
              AnimalHealthOperationDtoType.Deworming,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Autres visites vétérinaires',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.Others,
              AnimalHealthOperationDtoType.Identification,
              AnimalHealthOperationDtoType.BloodTest,
              AnimalHealthOperationDtoType.Hospitalization,
              AnimalHealthOperationDtoType.MedicalAppointment,
              AnimalHealthOperationDtoType.Treatment,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      title: 'Tests/Diagnostiques',
      options: specificTypes
        .filter((type) =>
          (
            [
              AnimalHealthDtoPathologies.CanineDistemper,
              AnimalHealthDtoPathologies.RubarthHepatitis,
              AnimalHealthDtoPathologies.Leptospirosis,
              AnimalHealthDtoPathologies.Rabies,
              AnimalHealthDtoPathologies.Parvovirus,
              AnimalHealthDtoPathologies.KennelCough,
              AnimalHealthDtoPathologies.Herpesvirus,
              AnimalHealthDtoPathologies.Babesiosis,
              AnimalHealthDtoPathologies.LymeDisease,
              AnimalHealthDtoPathologies.Diabetic,
              AnimalHealthDtoPathologies.Typhus,
              AnimalHealthDtoPathologies.Chlamydiosis,
              AnimalHealthDtoPathologies.Coryza,
              AnimalHealthOperationDtoType.Fiv,
              AnimalHealthOperationDtoType.FeLv,
              AnimalHealthDtoPathologies.Fip,
              AnimalHealthOperationDtoType.GoodHealthCertificate,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ]

  const orderGenerated = watch('orderGenerated')

  return (
    <>
      <Button onClick={openDialog} sx={{ color: colors.black }} disabled={disabled}>
        <Edit />
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Modifier un acte
              </Typography>
              <ControlledMultipleAutoCompleteWithCategories
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={undefined}
                fieldName="type"
                size="small"
                label="Motif"
                options={optionsWithCategories}
                requiredRule={'Le motif est requis'}
              />

              <ControlledDateField
                control={control}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                error={errors.date}
                fieldName={'date'}
                label="Date"
                requiredRule={undefined}
                size="small"
                sx={spacingItem}
              />

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'veterinary'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un vétérinaire' },
                  ...allVetetinaries
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Vétérinaire"
                error={errors.veterinary}
                requiredRule={orderGenerated ? 'Le vétérinaire est requis' : undefined}
                defaultValue={animal.responsibilities?.veterinaryChargeAccountId || ''}
                sx={spacingItem}
                highlight
              />

              {fields.map((field, index) => (
                <ColoredChipSelectField
                  key={field.id}
                  sx={spacingItem}
                  control={control}
                  fieldName={`testResults.${index}.result`}
                  label={`Résultat ${getReadableOperationType(field.type)}`}
                  options={Object.values(AnimalHealthDtoFeLV).map((status: AnimalHealthDtoFeLV) => ({
                    label: getReadableAnimalHealthTestStatus(status),
                    value: status,
                    color: getColorForAnimalHealthTestStatus(status),
                  }))}
                  error={undefined}
                  requiredRule={undefined}
                  size="small"
                />
              ))}

              {typeOfOperation.some((type) => type === AnimalHealthOperationDtoType.IdentificationChip) && (
                <TextField
                  id="identificationNumber"
                  label="Numéro d'identification"
                  type="text"
                  fullWidth
                  {...register('identificationNumber')}
                  sx={spacingItem}
                  size="small"
                />
              )}

              {typeOfOperation.some((type) => type === AnimalHealthOperationDtoType.Tatoo) && (
                <TextField
                  id="tattooNumber"
                  label="Numéro de tatouage"
                  type="text"
                  fullWidth
                  {...register('tattooNumber')}
                  sx={spacingItem}
                  size="small"
                />
              )}

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'memberInCharge'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un bénévole' },
                  ...accountsStore.members
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Membre en charge"
                error={errors.memberInCharge}
                requiredRule={orderGenerated ? 'Le membre en charge est requis' : undefined}
                defaultValue={animal.responsibilities?.memberInChargeAccountId || ''}
                sx={spacingItem}
                highlight
              />

              <TextField
                id="price"
                label="Coût €"
                type="text"
                fullWidth
                {...register('price')}
                sx={spacingItem}
                size="small"
                multiline
              />

              <TextField
                id="additional-info-input"
                label="Détails"
                type="text"
                fullWidth
                {...register('additionalInfo')}
                sx={spacingItem}
                size="small"
                multiline
              />

              {!operation.orderGenerated ? (
                accountsStore.connectedAccount && isAdmin(accountsStore.connectedAccount) ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...spacingItem, gap: 0.5 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography fontSize={16} fontWeight={700}>
                        Générer automatiquement un bon vétérinaire ?<span style={{ color: 'red' }}> *</span>
                      </Typography>
                      <Tooltip title="Nous pouvons vous générer automatiquement un bon de commande à destination du vétérinaire">
                        <InfoOutlined sx={{ color: theme.palette.primary.main }} />
                      </Tooltip>
                    </Box>
                    <Controller
                      control={control}
                      name="orderGenerated"
                      render={({ field }) => (
                        <ToggleButtonGroup
                          exclusive
                          color="info"
                          {...field}
                          onChange={(_, newValue) => {
                            field.onChange(newValue)
                          }}
                          fullWidth
                          aria-label="user want to generate veterinary order form"
                        >
                          <ToggleButton value={true} aria-label="Oui">
                            Oui
                          </ToggleButton>
                          <ToggleButton value={false} aria-label="Non">
                            Non
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    />
                  </Box>
                ) : (
                  <Typography fontSize={16} fontWeight={700} sx={{ ...spacingItem }}>
                    Si nécessaire, demander à un administrateur de générer un bon vétérinaire pour ce rendez-vous
                  </Typography>
                )
              ) : (
                <>
                  <Typography fontSize={16} fontWeight={700} sx={{ ...spacingItem }}>
                    Un bon vétérinaire a déjà été généré pour cet acte. Il est disponible dans les documents de{' '}
                    {animal.name}
                  </Typography>
                  {accountsStore.connectedAccount && isAdmin(accountsStore.connectedAccount) && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...spacingItem, gap: 0.5 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography fontSize={14} fontWeight={600}>
                          Re-générer un nouveau bon vétérinaire ?<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <Tooltip title="Nous pouvons vous générer automatiquement un bon de commande à destination du vétérinaire">
                          <InfoOutlined sx={{ color: theme.palette.primary.main }} />
                        </Tooltip>
                      </Box>
                      <Controller
                        control={control}
                        name="orderGenerated"
                        render={({ field }) => (
                          <ToggleButtonGroup
                            exclusive
                            color="info"
                            {...field}
                            onChange={(_, newValue) => {
                              field.onChange(newValue)
                            }}
                            fullWidth
                            aria-label="user want to generate veterinary order form"
                          >
                            <ToggleButton value={false} aria-label="Oui">
                              Oui
                            </ToggleButton>
                            <ToggleButton value={true} aria-label="Non">
                              Non
                            </ToggleButton>
                          </ToggleButtonGroup>
                        )}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              loading={editAnimalMutation.isLoading}
            >
              Modifier
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

function countOptionalFields(operation: AnimalHealthOperationDto): number {
  let count = 0
  if (operation.testResults && operation.testResults.length > 0) count += operation.testResults.length
  if (operation.identificationNumber) count++
  if (operation.tattooNumber) count++
  return count
}

interface PropsOptionalField {
  operation: AnimalHealthOperationDto
}

function RenderOneOptionalField({ operation }: PropsOptionalField) {
  if (countOptionalFields(operation) > 1) return null
  if (operation.testResults && operation.testResults.length > 0) {
    return (
      <DisplayChipLikeTextField
        label={`Résultat ${getReadableOperationType(operation.testResults[0].type)}`}
        value={getReadableAnimalHealthTestStatus(operation.testResults[0].result)}
        color={getColorForAnimalHealthTestStatus(operation.testResults[0].result)}
      />
    )
  }
  if (operation.identificationNumber) {
    return (
      <TextField
        label="Numéro d'identification"
        type="text"
        fullWidth
        value={operation.identificationNumber}
        sx={sxField}
        size="small"
        inputProps={{ readOnly: true }}
      />
    )
  }
  if (operation.tattooNumber) {
    return (
      <TextField
        label="Numéro de tatouage"
        type="text"
        fullWidth
        value={operation.tattooNumber}
        sx={sxField}
        size="small"
        inputProps={{ readOnly: true }}
      />
    )
  }
  return null
}
