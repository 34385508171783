import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { AccountDto } from '../../../interactors/gen/backendClient'
import { DocumentsList } from './DocumentsList'
import { blue } from '../../theme'

interface Props {
  account: AccountDto
  isOwnAccount: boolean
}

export const MemberDocumentsTab: React.FC<Props> = ({ account, isOwnAccount }) => {
  return account.restrainedData ? (
    <Card>
      <CardContent>
        <Typography sx={{ color: blue, fontSize: 18 }}>
          Documents non accessibles compte tenu des préférences de l&#39;utilisateur
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <DocumentsList documents={account.documents} accountId={account.id} isOwnAccount={isOwnAccount} />
  )
}
