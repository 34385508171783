import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  FormGroup,
} from '@mui/material'
import React, { useEffect } from 'react'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { AnimalDtoSpecies, CustomerCageWithAnimalsDetailsDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { DialogOpenedStore } from '../../../utils/hooks/useDialogOpened'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { ControlledMultipleAutoComplete } from '../../common/ControlledMultipleAutoComplete'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { spacingItem } from '../../theme'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'

type FormParams = {
  cageNumber: string
  name?: string
  suitableForSpecies: AnimalDtoSpecies[]
  capacity: number
  memberInChargeId?: string
  address: string
  city: string
  postalCode: string
}

interface Props {
  setCagesList: React.Dispatch<React.SetStateAction<CustomerCageWithAnimalsDetailsDto[]>>
  cagesList: CustomerCageWithAnimalsDetailsDto[]
  dialogOpenedState: DialogOpenedStore
  cage: CustomerCageWithAnimalsDetailsDto
}

export const AssociationEditCageModal: React.FC<Props> = ({ setCagesList, cagesList, dialogOpenedState, cage }) => {
  const accountsStore = useAccountsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  const [isAddressSameAsMemberInCharge, setIsAddressSameAsMemberInCharge] = React.useState(false)
  const [isAddressSameAsAssociation, setIsAddressSameAsAssociation] = React.useState(false)

  const getDefaultValues = (cage: CustomerCageWithAnimalsDetailsDto): FormParams => ({
    cageNumber: cage.cageNumber,
    name: cage.name || '',
    suitableForSpecies: cage.suitableForSpecies,
    capacity: cage.capacity,
    memberInChargeId: cage.memberInChargeId,
    address: cage.address || '',
    city: cage.city || '',
    postalCode: cage.postalCode || '',
  })

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormExtended(cage, getDefaultValues)

  const onSubmit = (data: FormParams) => {
    const isDuplicateCageNumber = cagesList
      .filter((aCage) => aCage !== cage)
      .some((cage) => cage.cageNumber === data.cageNumber)

    if (isDuplicateCageNumber) {
      globalSnackbarStore.triggerErrorMessage('Une cage existe déjà avec ce numéro')
    } else {
      const uploadData: CustomerCageWithAnimalsDetailsDto = {
        ...data,
        animals: cage.animals,
      }
      setCagesList((prev) => [...prev.filter((aCage) => aCage !== cage), uploadData])
      dialogOpenedState.closeDialog()
      reset()
    }
  }

  const options = accountsStore.members

  const watchMemberInChargeId = watch('memberInChargeId')
  useEffect(() => {
    const memberInCharge = accountsStore.members.find((member) => member.id === watchMemberInChargeId)
    if (memberInCharge && isAddressSameAsMemberInCharge) {
      setValue('address', memberInCharge.address ? memberInCharge.address : '')
      setValue('city', memberInCharge.city ? memberInCharge.city : '')
      setValue('postalCode', memberInCharge.postalCode ? memberInCharge.postalCode : '')
    }
  }, [watchMemberInChargeId, isAddressSameAsMemberInCharge])

  useEffect(() => {
    if (isAddressSameAsAssociation) {
      const customer = accountsStore.connectedCustomer
      if (customer) {
        setValue('address', customer.address ? customer.address : '')
        setValue('city', customer.city ? customer.city : '')
        setValue('postalCode', customer.postalCode ? customer.postalCode : '')
      }
    }
  }, [isAddressSameAsAssociation])

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setCagesList(cagesList.filter((aCage) => aCage !== cage))
    return dialogOpenedState.closeDialog()
  }

  return (
    <Dialog
      open={dialogOpenedState.isDialogOpen}
      onClose={dialogOpenedState.closeDialog}
      PaperProps={{
        style: { borderRadius: 15, padding: 15 },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box>
            <Typography variant="h4" component="h2" marginBottom={4}>
              Modifier une cage
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', gap: 2 }}>
              <Box sx={{ width: { xs: '100%', sm: '25%' } }}>
                <TextField
                  label="N° de cage'"
                  type="text"
                  fullWidth
                  size="small"
                  {...register('cageNumber')}
                  error={!!errors.cageNumber}
                  helperText={errors.cageNumber?.message}
                />
              </Box>

              <Box sx={{ width: '100%' }}>
                <TextField
                  label="Intitulé"
                  type="text"
                  fullWidth
                  size="small"
                  {...register('name')}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Box>
            </Box>

            <ControlledMultipleAutoComplete
              control={control}
              fieldName="suitableForSpecies"
              label="Adapté pour"
              error={errors.name}
              options={Object.values(AnimalDtoSpecies).map((status: AnimalDtoSpecies) => ({
                label: getReadableSpecies(status, { withEmoji: false }),
                value: status,
              }))}
              size="small"
              requiredRule={'true'}
              sx={spacingItem}
            />

            <ControlledSelectField
              control={control}
              fieldName="capacity"
              label="Capacité d'accueil"
              error={errors.capacity}
              options={[
                { label: '1 animal', value: 1 },
                { label: '2 animaux', value: 2 },
                { label: '3 animaux', value: 3 },
                { label: '4 animaux', value: 4 },
                { label: '5 animaux', value: 5 },
                { label: '6 animaux', value: 6 },
                { label: '7 animaux', value: 7 },
                { label: '8 animaux', value: 8 },
                { label: '9 animaux', value: 9 },
                { label: '10 animaux', value: 10 },
                { label: '11 animaux', value: 11 },
                { label: '12 animaux', value: 12 },
                { label: '13 animaux', value: 13 },
                { label: '14 animaux', value: 14 },
                { label: '15 animaux', value: 15 },
                { label: '16 animaux', value: 16 },
                { label: '17 animaux', value: 17 },
                { label: '18 animaux', value: 18 },
                { label: '19 animaux', value: 19 },
                { label: '20 animaux', value: 20 },
              ]}
              requiredRule="Le champs capacité d'accueil est requis"
              size="small"
              sx={spacingItem}
            />

            <ControlledAutoCompleteWithCustomValue
              control={control}
              fieldName={'memberInChargeId'}
              options={options
                .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                .map((option) => ({ label: fullName(option), value: option.id }))}
              label="Membre en charge"
              error={errors.memberInChargeId}
              requiredRule={'true'}
              defaultValue={''}
              size="small"
              sx={spacingItem}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAddressSameAsMemberInCharge}
                    onChange={() => {
                      setIsAddressSameAsMemberInCharge((prev) => !prev)
                      if (isAddressSameAsAssociation) {
                        setIsAddressSameAsAssociation(false)
                      }
                    }}
                  />
                }
                label="Adresse similaire à celle du membre en charge"
                sx={spacingItem}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAddressSameAsAssociation}
                    onChange={() => {
                      setIsAddressSameAsAssociation((prev) => !prev)
                      if (isAddressSameAsMemberInCharge) {
                        setIsAddressSameAsMemberInCharge(false)
                      }
                    }}
                  />
                }
                label="Adresse similaire à celle de l'association"
              />
            </FormGroup>

            <TextField
              label="Adresse"
              type="text"
              fullWidth
              size="small"
              {...register('address')}
              error={!!errors.address}
              helperText={errors.address?.message}
              sx={spacingItem}
              disabled={isAddressSameAsMemberInCharge || isAddressSameAsAssociation}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Code postal"
              type="text"
              fullWidth
              size="small"
              {...register('postalCode')}
              error={!!errors.postalCode}
              helperText={errors.postalCode?.message}
              sx={spacingItem}
              disabled={isAddressSameAsMemberInCharge || isAddressSameAsAssociation}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Ville"
              type="text"
              fullWidth
              size="small"
              {...register('city')}
              error={!!errors.city}
              helperText={errors.city?.message}
              sx={spacingItem}
              disabled={isAddressSameAsMemberInCharge || isAddressSameAsAssociation}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button type="button" color="error" onClick={(e) => handleDelete(e)} disabled={!accountsStore.isAdmin()}>
            Supprimer cette cage
          </Button>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button data-testid="cancel" onClick={() => dialogOpenedState.closeDialog()}>
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              disabled={!accountsStore.isAdmin()}
            >
              Valider
            </LoadingButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}
