import { AnimalVaccinationDtoVaccines } from '../../interactors/gen/backendClient'

export enum CatVaccinationType {
  TC = 'tc',
  TCL = 'tcl',
  TCClamoxydos = 'tc-clamoxydos',
  TCLClamoxydos = 'tcl-clamoxydos',
}

export enum DogVaccinationType {
  CHPPi = 'chppi',
  CHPPiL = 'chppil',
  CHPPiLRage = 'chppil-rahe',
}

export const getReadableVaccinationType = (vaccinationType: CatVaccinationType | DogVaccinationType): string => {
  const map = {
    [CatVaccinationType.TC]: 'TC',
    [CatVaccinationType.TCL]: 'TCL',
    [CatVaccinationType.TCClamoxydos]: 'TC + Clamoxydos',
    [CatVaccinationType.TCLClamoxydos]: 'TCL + Clamoxydos',
    [DogVaccinationType.CHPPi]: 'CHPPi',
    [DogVaccinationType.CHPPiL]: 'CHPPiL',
    [DogVaccinationType.CHPPiLRage]: 'CHPPiL + Rage',
  }

  return map[vaccinationType]
}

export enum NewCatVaccinationType {
  TC = 'TC',
  Leucose = 'leucose',
  Clamoxydose = 'clamoxydose',
  Herpesvirose = 'herpesvirose',
  Rage = 'rage',
}

export enum NewDogVaccinationType {
  CHPPi = 'CHPPi',
  Leptospirose = 'leptospirose',
  Rage = 'rage',
  KennelCough = 'kennel-cough',
}

export const isVaccineForDog = (vaccine: AnimalVaccinationDtoVaccines): boolean => {
  return Object.values(NewDogVaccinationType).includes(vaccine as NewDogVaccinationType)
}

export const isVaccineForCat = (vaccine: AnimalVaccinationDtoVaccines): boolean => {
  return Object.values(NewCatVaccinationType).includes(vaccine as NewCatVaccinationType)
}
