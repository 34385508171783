import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material'
import React from 'react'
import { theme } from '../../theme'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
  animalName: string
  isLoading: boolean
}

export const SuspendProcedureModal: React.FC<Props> = ({ isOpen, onClose, onSubmit, animalName, isLoading }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      fullWidth
      scroll="paper"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
    >
      <DialogTitle sx={{ pb: isMobile ? 0 : 'auto' }}>Êtes-vous sûr de vouloir continuer ?</DialogTitle>

      <Box
        sx={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          boxSizing: 'content-box',
        }}
      >
        <DialogContent
          sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)', fontSize: isMobile ? '14px' : '16px' }}
        >
          <b>
            Si vous continuez, toute la progression de la procédure sera perdue.
            <br />
            De plus, {animalName} repassera en statut &quot;Adoptable&quot;.
          </b>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
          <Button
            variant="outlined"
            color="error"
            type="button"
            sx={{
              textTransform: 'none',
              px: isMobile ? 'auto' : '50px',
              fontSize: isMobile ? '14px' : '16px',
            }}
            onClick={onClose}
            disabled={isLoading}
          >
            Annuler
          </Button>
          <LoadingButton
            variant="contained"
            type="button"
            sx={{
              textTransform: 'none',
              fontSize: isMobile ? '14px' : '16px',
            }}
            loading={isLoading}
            onClick={() => onSubmit()}
          >
            Continuer quand même
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
