import { AdopterDto } from '../../interactors/gen/backendClient'
import { formatStringForSearch } from '../shared/formatStringForSearch'

export const searchAdopters = (members: Array<AdopterDto>, searchQuery: string): Array<AdopterDto> => {
  return members.filter(
    (account) =>
      formatStringForSearch(`${account.firstName} ${account.lastName}`).startsWith(
        formatStringForSearch(searchQuery)
      ) ||
      formatStringForSearch(`${account.lastName} ${account.firstName}`).startsWith(
        formatStringForSearch(searchQuery)
      ) ||
      formatStringForSearch(`${account.email}`).startsWith(formatStringForSearch(searchQuery))
  )
}
