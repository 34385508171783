import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, MenuItem } from '@mui/material'
import { FC } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../domain/Account/AccountDisplay'
import { AccountsClient } from '../../interactors/clients/AccountsClient'
import { AccountDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { PATHS } from '../PATHS'
import { useAccountsStore } from '../../store/AccountsStore'

interface Props {
  account: AccountDto
}

export const DeleteMemberPrompt: FC<Props> = ({ account }) => {
  const navigate = useNavigate()
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()

  // Mutations
  const mutation = useMutation(async () => await AccountsClient.deleteAccount(account.id), {
    onSuccess: () => {
      closeDialog()
      navigate(PATHS.suiviBenevole.absolute)
      globalSnackbar.triggerSuccessMessage(`Le compte de ${fullName(account)} a été supprimé !`)
    },
  })

  if (!accountsStore.isAdmin()) {
    return null
  }

  return (
    <>
      <MenuItem key="delete" onClick={openDialog}>
        {'Supprimer ce compte'}
      </MenuItem>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>⚠️ Êtes vous sur de vouloir supprimer ce compte?</DialogContent>
        <DialogActions>
          <Button data-testid="cancel" onClick={closeDialog}>
            Revenir
          </Button>
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            sx={{ backgroundColor: 'error.main' }}
            onClick={() => mutation.mutate()}
            loading={mutation.isLoading}
          >
            Valider
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
