import React, { FC, useEffect, useRef } from 'react'

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Drawer,
  Fab,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from '@mui/material'

import {
  AccessTime,
  Add,
  DirectionsCar,
  EmojiFoodBeverage,
  FamilyRestroom,
  Healing,
  HourglassEmpty,
  LockOpen,
  Pets,
  RoomPreferences,
  Search as SearchIcon,
  SupervisedUserCircle,
  Tune,
  Verified,
} from '@mui/icons-material'
import {
  AccountDto,
  AccountDtoAvailabilityStatus,
  AccountDtoHomeHasIsolationRoom,
  AccountDtoPermission,
  AccountDtoPreferencesNeedsBottleFeeding,
  AccountDtoPreferencesSeniority,
  AccountDtoPreferencesWillingToSocializeWildAnimals,
  AccountDtoRoles,
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoStatus,
  AnimalDtoSpecies,
  AnimalSearchItemDto,
} from '../../interactors/gen/backendClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'

import { useNavigate } from 'react-router-dom'
import {
  getColorForAvailabilityStatus,
  getReadableAccountFieldLabel,
  getReadableAccountPermission,
  getReadableAccountPreferencesSeniority,
  getReadableAccountRole,
  getReadableAvailabilityStatus,
  getSmallReadableAccountRole,
} from '../../domain/Account/AccountDisplay'
import { searchMembers } from '../../domain/Account/searchMembers'
import { getEmojiAnimalSex, getEmojiSpecies, getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import { BooleanOrAll } from '../../domain/enums/Boolean.enum'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useFetchOrRefreshMembersOnMount } from '../../store/useFetchOrRefreshMembersOnMount'
import { getAccountProfilePhotoSrc, getAnimalProfilePhotoSrc } from '../../utils/S3-links'
import { useComponentScrollRestoration } from '../../utils/hooks/useScrollRestoration'
import { isUserAvailableToday } from '../../utils/isUserAvailableToday'
import { setOpacity } from '../../utils/setOpacity'
import { sortAlphabetically } from '../../utils/sort'
import { PATHS } from '../PATHS'
import { AQuestion } from '../common/AQuestion'
import { FilterList } from '../common/FilterList'
import { FilterListItem } from '../common/FilterListItem'
import { blueSky, colors, spacingItem, theme } from '../theme'
import { MemberExportSheet } from './MemberExportSheet'
import MemberListCard from './MemberListCard'

export const ContactListScreen: FC = () => {
  const queryParams = new URLSearchParams(location.search)
  const getArrayQueryParamOrDefault = (paramValue: string | null | undefined, defaultValue: string[]) => {
    const values = paramValue?.split('|')
    if (values && values.length === 1 && values[0] === '') {
      return defaultValue
    }
    return values ?? defaultValue
  }
  const [selectedStatus, setSelectedStatus] = React.useState<AccountDtoAvailabilityStatus | null>(
    queryParams.get('selectedStatus') as AccountDtoAvailabilityStatus | null
  )
  const [hasChildren, setHasChildren] = React.useState<BooleanOrAll>(
    (queryParams.get('hasChildren') as BooleanOrAll) ?? BooleanOrAll.All
  )
  const [hasIsolationRoom, setHasIsolationRoom] = React.useState<BooleanOrAll>(
    (queryParams.get('hasIsolationRoom') as BooleanOrAll) ?? BooleanOrAll.All
  )
  const [hasCar, setHasCar] = React.useState<BooleanOrAll>(
    (queryParams.get('hasCar') as BooleanOrAll) ?? BooleanOrAll.All
  )
  const [agreeToBottleFeed, setAgreeToBottleFeed] = React.useState<BooleanOrAll>(
    (queryParams.get('agreeToBottleFeed') as BooleanOrAll) ?? BooleanOrAll.All
  )
  const [agreeToHandleSickAnimals, setAgreeToHandleSickAnimals] = React.useState<BooleanOrAll>(
    (queryParams.get('agreeToHandleSickAnimals') as BooleanOrAll) ?? BooleanOrAll.All
  )
  const [hasAnimalsOutsideAssociation, setHasAnimalsOutsideAssociation] = React.useState<BooleanOrAll>(
    (queryParams.get('hasAnimalsOutsideAssociation') as BooleanOrAll) ?? BooleanOrAll.All
  )
  const [selectedSpecies, setSelectedSpecies] = React.useState<AnimalDtoSpecies[]>(
    getArrayQueryParamOrDefault(queryParams.get('selectedSpecies'), []) as AnimalDtoSpecies[]
  )
  const [selectedSeniorities, setSelectedSeniorities] = React.useState<AccountDtoPreferencesSeniority[]>(
    getArrayQueryParamOrDefault(queryParams.get('selectedSeniorities'), []) as AccountDtoPreferencesSeniority[]
  )
  const [selectedPermissions, setSelectedPermissions] = React.useState<AccountDtoPermission[]>(
    getArrayQueryParamOrDefault(queryParams.get('selectedPermissions'), []) as AccountDtoPermission[]
  )
  const [selectedRole, setSelectedRole] = React.useState<AccountDtoRoles | null>(
    queryParams.get('selectedRole') as AccountDtoRoles | null
  )
  const [isArchived, setIsArchived] = React.useState<boolean>(queryParams.get('isArchived') === 'true')
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const scrollRef = useRef<HTMLElement | null>(null)
  useComponentScrollRestoration(scrollRef, 'member-list')

  const handleTabChange = (_: unknown, newValue: AccountDtoRoles | 'archived') => {
    if (newValue === 'archived') {
      setSelectedRole(null)
      return setIsArchived(true)
    }
    setSelectedRole(newValue as AccountDtoRoles)
    setIsArchived(false)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const navigate = useNavigate()

  useFetchOrRefreshMembersOnMount()

  const accountsStore = useAccountsStore()

  const [searchQuery, setSearchQuery] = React.useState('')

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchQuery(e.target.value)
  }

  const species = accountsStore.connectedCustomer?.preferences.species

  useEffect(() => {
    const searchParams = new URLSearchParams()
    searchParams.set('search', searchQuery)
    searchParams.set('selectedStatus', selectedStatus ?? '')
    searchParams.set('hasChildren', hasChildren)
    searchParams.set('hasIsolationRoom', hasIsolationRoom)
    searchParams.set('hasCar', hasCar)
    searchParams.set('agreeToBottleFeed', agreeToBottleFeed)
    searchParams.set('agreeToHandleSickAnimals', agreeToHandleSickAnimals)
    searchParams.set('hasAnimalsOutsideAssociation', hasAnimalsOutsideAssociation)
    searchParams.set('selectedSpecies', selectedSpecies.join('|'))
    searchParams.set('selectedSeniorities', selectedSeniorities.join('|'))
    searchParams.set('selectedPermissions', selectedPermissions.join('|'))
    searchParams.set('selectedRole', selectedRole ?? '')
    searchParams.set('isArchived', isArchived.toString())
    // Add more parameters as needed
    navigate(`?${searchParams.toString()}`, { replace: true })
  }, [
    searchQuery,
    selectedStatus,
    hasChildren,
    hasIsolationRoom,
    hasCar,
    agreeToBottleFeed,
    agreeToHandleSickAnimals,
    hasAnimalsOutsideAssociation,
    selectedSpecies,
    selectedSeniorities,
    selectedPermissions,
    selectedRole,
    isArchived,
  ])

  const FiltersBar = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          Rechercher un membre
        </Typography>

        <FilterList label="Disponibilité" icon={<AccessTime />}>
          {Object.values(AccountDtoAvailabilityStatus).map((status) => {
            return (
              <FilterListItem
                key={status}
                label={getReadableAvailabilityStatus(status)}
                isSelected={selectedStatus === status}
                toggleSelected={() => {
                  if (selectedStatus === status) {
                    // If the status is already selected, remove it from the selectedStatus array
                    setSelectedStatus(null)
                  } else {
                    setSelectedStatus(status)
                  }
                }}
              />
            )
          })}
        </FilterList>

        <FilterList label="Permission" icon={<LockOpen />}>
          {Object.values(AccountDtoPermission).map((permission) => {
            const isSelected = selectedPermissions.includes(permission)

            return (
              <FilterListItem
                key={permission}
                label={getReadableAccountPermission(permission)}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the permission is already selected, remove it from the selectedPermissions array
                    setSelectedPermissions(selectedPermissions.filter((s) => s !== permission))
                  } else {
                    // If the permission is not selected, add it to the selectedSexs array
                    setSelectedPermissions([...selectedPermissions, permission])
                  }
                }}
              />
            )
          })}
        </FilterList>

        <FilterList label="Rôle" icon={<SupervisedUserCircle />}>
          {Object.values(AccountDtoRoles).map((role) => {
            const isSelected = selectedRole === role

            return (
              <FilterListItem
                key={role}
                label={getReadableAccountRole(role)}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the role is already selected, remove it from the selectedRoles array
                    setSelectedRole(null)
                  } else {
                    // If the role is not selected, add it to the selectedSexs array
                    setSelectedRole(role)
                  }
                }}
              />
            )
          })}
        </FilterList>

        <Typography variant="h6" gutterBottom sx={spacingItem}>
          Préférences des membres
        </Typography>

        {species && species.length > 1 && (
          <FilterList label="Espèces" icon={<Pets />}>
            {species?.map((specie) => {
              const isSelected = selectedSpecies.includes(specie)

              return (
                <FilterListItem
                  key={specie}
                  label={getReadableSpecies(specie)}
                  isSelected={isSelected}
                  toggleSelected={() => {
                    if (isSelected) {
                      // If the specie is already selected, remove it from the selectedSpecies array
                      setSelectedSpecies(selectedSpecies.filter((s) => s !== specie))
                    } else {
                      // If the specie is not selected, add it to the selectedSpecies array
                      setSelectedSpecies([...selectedSpecies, specie])
                    }
                  }}
                />
              )
            })}
          </FilterList>
        )}

        <FilterList label="Enfants" icon={<FamilyRestroom />}>
          <FilterListItem
            label="Oui"
            isSelected={hasChildren === BooleanOrAll.True}
            toggleSelected={() => {
              if (hasChildren === BooleanOrAll.True) {
                setHasChildren(BooleanOrAll.All)
              } else {
                setHasChildren(BooleanOrAll.True)
              }
            }}
          />
          <FilterListItem
            label="Non"
            isSelected={hasChildren === BooleanOrAll.False}
            toggleSelected={() => {
              if (hasChildren === BooleanOrAll.False) {
                setHasChildren(BooleanOrAll.All)
              } else {
                setHasChildren(BooleanOrAll.False)
              }
            }}
          />
        </FilterList>

        <FilterList label="Isolement" icon={<RoomPreferences />}>
          <FilterListItem
            label="Oui"
            isSelected={hasIsolationRoom === BooleanOrAll.True}
            toggleSelected={() => {
              if (hasIsolationRoom === BooleanOrAll.True) {
                setHasIsolationRoom(BooleanOrAll.All)
              } else {
                setHasIsolationRoom(BooleanOrAll.True)
              }
            }}
          />
          <FilterListItem
            label="Non"
            isSelected={hasIsolationRoom === BooleanOrAll.False}
            toggleSelected={() => {
              if (hasIsolationRoom === BooleanOrAll.False) {
                setHasIsolationRoom(BooleanOrAll.All)
              } else {
                setHasIsolationRoom(BooleanOrAll.False)
              }
            }}
          />
        </FilterList>

        <FilterList label="Biberonnage" icon={<EmojiFoodBeverage />}>
          <FilterListItem
            label="Oui"
            isSelected={agreeToBottleFeed === BooleanOrAll.True}
            toggleSelected={() => {
              if (agreeToBottleFeed === BooleanOrAll.True) {
                setAgreeToBottleFeed(BooleanOrAll.All)
              } else {
                setAgreeToBottleFeed(BooleanOrAll.True)
              }
            }}
          />
          <FilterListItem
            label="Non"
            isSelected={agreeToBottleFeed === BooleanOrAll.False}
            toggleSelected={() => {
              if (agreeToBottleFeed === BooleanOrAll.False) {
                setAgreeToBottleFeed(BooleanOrAll.All)
              } else {
                setAgreeToBottleFeed(BooleanOrAll.False)
              }
            }}
          />
        </FilterList>

        <FilterList label="Véhiculé" icon={<DirectionsCar />}>
          <FilterListItem
            label="Oui"
            isSelected={hasCar === BooleanOrAll.True}
            toggleSelected={() => {
              if (hasCar === BooleanOrAll.True) {
                setHasCar(BooleanOrAll.All)
              } else {
                setHasCar(BooleanOrAll.True)
              }
            }}
          />
          <FilterListItem
            label="Non"
            isSelected={hasCar === BooleanOrAll.False}
            toggleSelected={() => {
              if (hasCar === BooleanOrAll.False) {
                setHasCar(BooleanOrAll.All)
              } else {
                setHasCar(BooleanOrAll.False)
              }
            }}
          />
        </FilterList>

        <FilterList label="Animaux hors asso" icon={<Pets />}>
          <FilterListItem
            label="Oui"
            isSelected={hasAnimalsOutsideAssociation === BooleanOrAll.True}
            toggleSelected={() => {
              if (hasAnimalsOutsideAssociation === BooleanOrAll.True) {
                setHasAnimalsOutsideAssociation(BooleanOrAll.All)
              } else {
                setHasAnimalsOutsideAssociation(BooleanOrAll.True)
              }
            }}
          />
          <FilterListItem
            label="Non"
            isSelected={hasAnimalsOutsideAssociation === BooleanOrAll.False}
            toggleSelected={() => {
              if (hasAnimalsOutsideAssociation === BooleanOrAll.False) {
                setHasAnimalsOutsideAssociation(BooleanOrAll.All)
              } else {
                setHasAnimalsOutsideAssociation(BooleanOrAll.False)
              }
            }}
          />
        </FilterList>

        <FilterList label="Accepte animaux malades" icon={<Healing />}>
          <FilterListItem
            label="Oui"
            isSelected={agreeToHandleSickAnimals === BooleanOrAll.True}
            toggleSelected={() => {
              if (agreeToHandleSickAnimals === BooleanOrAll.True) {
                setAgreeToHandleSickAnimals(BooleanOrAll.All)
              } else {
                setAgreeToHandleSickAnimals(BooleanOrAll.True)
              }
            }}
          />
          <FilterListItem
            label="Non"
            isSelected={agreeToHandleSickAnimals === BooleanOrAll.False}
            toggleSelected={() => {
              if (agreeToHandleSickAnimals === BooleanOrAll.False) {
                setAgreeToHandleSickAnimals(BooleanOrAll.All)
              } else {
                setAgreeToHandleSickAnimals(BooleanOrAll.False)
              }
            }}
          />
        </FilterList>

        <FilterList label="Âge recherché" icon={<HourglassEmpty />}>
          {Object.values(AccountDtoPreferencesSeniority).map((seniority) => {
            const isSelected = selectedSeniorities.includes(seniority)

            return (
              <FilterListItem
                key={seniority}
                label={getReadableAccountPreferencesSeniority(seniority)}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the seniority is already selected, remove it from the selectedSenioritys array
                    setSelectedSeniorities(selectedSeniorities.filter((s) => s !== seniority))
                  } else {
                    // If the seniority is not selected, add it to the selectedSenioritys array
                    setSelectedSeniorities([...selectedSeniorities, seniority])
                  }
                }}
              />
            )
          })}
        </FilterList>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <MemberExportSheet />
        </Box>
        <AQuestion />
      </>
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: 1,
          paddingBottom: 7.5,
          backgroundColor: 'grey.100',
          minHeight: `calc(100% - 20px)`,
          boxSizing: 'border-box',
          marginBottom: 2,
          maxHeight: `calc(100% - 20px)`,
        }}
      >
        {accountsStore.isLoading ? (
          <CircularProgressPanel />
        ) : (
          <>
            {/* Filters for desktop version */}
            <Card
              sx={{
                ...spacingItem,
                width: 230,
                display: { xs: 'none', sm: 'block' },
                overflowY: 'auto',
              }}
            >
              <CardContent>
                <FiltersBar />
              </CardContent>
            </Card>
            {/* Filters for mobile version */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
              }}
            >
              <Box sx={{ padding: 2 }}>
                <FiltersBar />
              </Box>
            </Drawer>
          </>
        )}
        <Card
          sx={{
            ...spacingItem,
            marginLeft: { xs: 0, sm: 2 },
            width: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Tabs
            value={selectedRole ? selectedRole : isArchived ? 'archived' : null}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              marginBottom: '1rem',
              borderBottom: '1px solid lightgray',
            }}
          >
            <Tab
              label="Tous"
              value={null}
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="Membres"
              value={AccountDtoRoles.Member}
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="Familles d'accueil"
              value={AccountDtoRoles.HostFamily}
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="Archivés"
              value={'archived'}
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            {/* <Tab
              label="Vétérinaires"
              value={AccountDtoRoles.Veterinary}
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            /> */}
          </Tabs>

          <Box display="flex" flexDirection="row" flexWrap="nowrap">
            <TextField
              id="name-input"
              placeholder="Rechercher dans les membres"
              type="text"
              fullWidth
              value={searchQuery}
              onChange={onChangeSearch}
              sx={{
                borderRadius: 0,
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' }, mx: 'auto' }}
            >
              <Tune />
            </IconButton>
          </Box>

          <Box
            sx={{
              width: '100%',
              maxHeight: '100%',
              boxSizing: 'border-box',
              overflow: 'auto',
              paddingBottom: 20, // HACK: To avoid the last row to be hidden
              transform: 'translateZ(0)',
            }}
            ref={scrollRef}
          >
            {accountsStore.isLoading ? (
              <CircularProgressPanel />
            ) : (
              <SearchResults
                members={accountsStore.members.filter(
                  (member) => !(member.roles.includes(AccountDtoRoles.Veterinary) && member.roles.length === 1)
                )}
                searchQuery={searchQuery}
                filters={{
                  selectedStatus,
                  hasChildren,
                  selectedSpecies,
                  selectedSeniorities,
                  selectedPermissions,
                  selectedRole,
                  hasIsolationRoom,
                  hasCar,
                  agreeToBottleFeed,
                  hasAnimalsOutsideAssociation,
                  agreeToHandleSickAnimals,
                  isArchived,
                }}
              />
            )}
          </Box>

          {accountsStore.isAdmin() && (
            <Box sx={{ position: 'absolute', right: 2, bottom: 0, margin: 2 }}>
              <Fab color="primary" variant="extended" onClick={() => navigate(PATHS.ajouterBenevole.absolute)}>
                <Add sx={{ mr: 1 }} />
                Ajouter un Bénévole
              </Fab>
            </Box>
          )}
        </Card>
      </Box>
    </>
  )
}

type SortDirection = 'asc' | 'desc'
type SortColumn = 'firstName' | 'lastName' | 'status' | 'email' | 'address' | 'roles' | 'permission'

type SearchResultsProps = {
  members: Array<AccountDto>
  searchQuery: string
  filters?: {
    selectedStatus: AccountDtoAvailabilityStatus | null
    hasChildren: BooleanOrAll
    hasIsolationRoom: BooleanOrAll
    agreeToBottleFeed: BooleanOrAll
    hasCar: BooleanOrAll
    hasAnimalsOutsideAssociation: BooleanOrAll
    agreeToHandleSickAnimals: BooleanOrAll
    selectedSpecies: AnimalDtoSpecies[]
    selectedSeniorities: AccountDtoPreferencesSeniority[]
    selectedPermissions?: AccountDtoPermission[]
    selectedRole?: AccountDtoRoles | null
    isArchived: boolean
  }
}

const SearchResults: React.FC<SearchResultsProps> = ({ members, searchQuery, filters }) => {
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('status')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')
  const [expandCardIndex, setExpandCardIndex] = React.useState<number | null>(null)
  const accountsStore = useAccountsStore()
  const isMobile = useMediaQuery('(max-width:600px)')

  const handleSort = (column: SortColumn, columnDefaultDirection: SortDirection) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortDirection(columnDefaultDirection)
    }

    setSortColumn(column)
  }
  const searchResult = searchMembers(members, searchQuery)
    .map((member) => {
      // Calculate filtered preferences once and reuse them across filters
      const filteredPreferences =
        !filters?.selectedSpecies || filters?.selectedSpecies.length === 0
          ? [
              ...(member.preferences.primaryPreferences ? [member.preferences.primaryPreferences] : []),
              ...(member.preferences.otherPreferences && member.preferences.otherPreferences.length > 0
                ? member.preferences.otherPreferences
                : []),
            ]
          : [
              ...(filters?.selectedSpecies.includes(member.preferences.primaryPreferences?.specie as AnimalDtoSpecies)
                ? [member.preferences.primaryPreferences]
                : []),
              ...(member.preferences.otherPreferences?.filter((pref) =>
                filters?.selectedSpecies.includes(pref.specie as AnimalDtoSpecies)
              ) || []),
            ]

      // Compute seniorities from the filtered preferences
      const seniorities = [...(filteredPreferences?.map((pref) => pref?.seniority) || [])]
        .filter(Boolean)
        .flat() // Removes any undefined values and flattens the array
        .filter(Boolean) as AccountDtoPreferencesSeniority[]

      return { member, filteredPreferences, seniorities }
    })
    .filter(({ member }) => {
      if (!filters) return true
      switch (filters.hasChildren) {
        case BooleanOrAll.All:
          return true
        case BooleanOrAll.True:
          return member.home?.childrenNumber ? member.home?.childrenNumber > 0 : false
        case BooleanOrAll.False:
          return member.home?.childrenNumber ? member.home?.childrenNumber === 0 : true
      }
    })
    .filter(({ filteredPreferences }) => {
      // Filter based on selected species
      if (!filters || !filters.selectedSpecies || filters.selectedSpecies.length === 0) {
        return true
      }
      return filteredPreferences.length > 0
    })
    .filter(({ seniorities }) => {
      // Filter based on selected seniorities
      if (!filters || !filters.selectedSeniorities || filters.selectedSeniorities.length === 0) {
        return true
      }
      return seniorities.filter(Boolean).some((seniority) => filters.selectedSeniorities.includes(seniority))
    })
    .map(({ member }) => member)
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedPermissions?.length) {
        return true
      }
      return member.permission ? filters.selectedPermissions.includes(member.permission) : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedRole) {
        return true
      }
      return member.roles
        ? member.roles.some((roles) => roles.includes(filters.selectedRole as AccountDtoRoles))
        : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedStatus) {
        return true
      }
      return filters.selectedStatus === isUserAvailableToday(member)
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.hasIsolationRoom) return true
      if (filters.hasIsolationRoom === BooleanOrAll.All) return true

      return filters.hasIsolationRoom === 'true'
        ? member.home?.hasIsolationRoom === AccountDtoHomeHasIsolationRoom.Yes
        : member.home?.hasIsolationRoom === AccountDtoHomeHasIsolationRoom.No
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.hasCar) return true
      if (filters.hasCar === BooleanOrAll.All) return true

      return filters.hasCar === 'true'
        ? member.home?.hasCar === AccountDtoHomeHasIsolationRoom.Yes
        : member.home?.hasCar === AccountDtoHomeHasIsolationRoom.No
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.hasAnimalsOutsideAssociation) return true
      if (filters.hasAnimalsOutsideAssociation === BooleanOrAll.All) return true

      return filters.hasAnimalsOutsideAssociation === 'true'
        ? member.home?.pets && member.home.pets.length > 0
        : !member.home?.pets || member.home.pets.length === 0
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.agreeToBottleFeed) return true
      if (filters.agreeToBottleFeed === BooleanOrAll.All) return true

      return filters.agreeToBottleFeed === 'true'
        ? member.preferences?.primaryPreferences?.needsBottleFeeding === AccountDtoPreferencesNeedsBottleFeeding.True
        : member.preferences?.primaryPreferences?.needsBottleFeeding === AccountDtoPreferencesNeedsBottleFeeding.False
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.agreeToHandleSickAnimals) return true
      if (filters.agreeToHandleSickAnimals === BooleanOrAll.All) return true

      return filters.agreeToHandleSickAnimals === 'true'
        ? member.preferences?.primaryPreferences?.withMedicalAntecedent === AccountDtoHomeHasIsolationRoom.Yes
        : member.preferences?.primaryPreferences?.withMedicalAntecedent === AccountDtoHomeHasIsolationRoom.No
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.isArchived) return !member.visibility?.pauseProfile || member.visibility?.pauseProfile == null
      return filters.isArchived
        ? member.visibility?.pauseProfile === true
        : !member.visibility?.pauseProfile || member.visibility?.pauseProfile == null
    })
    .sort((a, b) => {
      switch (sortColumn) {
        case 'firstName':
          return sortAlphabetically(a, b, sortDirection, 'firstName')
        case 'lastName':
          return sortAlphabetically(a, b, sortDirection, 'lastName')
        case 'status':
          return sortAlphabetically(a, b, sortDirection, 'status')
        case 'email':
          return sortAlphabetically(a, b, sortDirection, 'email')
        case 'address':
          return sortAlphabetically(a, b, sortDirection, 'address')
        case 'roles':
          return sortAlphabetically(a, b, sortDirection, 'roles')
        case 'permission':
          return sortAlphabetically(a, b, sortDirection, 'permission')
        default:
          throw new Error(`sortColumn ${sortColumn} not supported`)
      }
    })

  const connectedMember = accountsStore.connectedAccount

  return !isMobile ? (
    // Desktop device
    <Table
      stickyHeader
      aria-label="simple table"
      sx={{
        padding: '10px',
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'firstName'}
              direction={sortDirection}
              onClick={() => {
                handleSort('firstName', 'asc')
              }}
            >
              {getReadableAccountFieldLabel('firstName')}
            </TableSortLabel>
          </TableCell>
          {/*           <TableCell>
            <TableSortLabel
              active={sortColumn === 'lastName'}
              direction={sortDirection}
              onClick={() => {
                handleSort('lastName', 'asc')
              }}
            >
              {getReadableAccountFieldLabel('lastName')}
            </TableSortLabel>
          </TableCell> */}
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'status'}
              direction={sortDirection}
              onClick={() => {
                handleSort('status', 'asc')
              }}
            >
              Disponibilité
            </TableSortLabel>
          </TableCell>
          <TableCell>Possibilité isolement</TableCell>
          <TableCell>Possibilité soin</TableCell>
          <TableCell>Animaux hors asso</TableCell>
          <TableCell>Surface domicile</TableCell>
          {/* <TableCell>Capacité hébergement</TableCell> */}
          <TableCell>Animaux à charge</TableCell>
          {/* <TableCell>
            <TableSortLabel
              active={sortColumn === 'roles'}
              direction={sortDirection}
              onClick={() => {
                handleSort('roles', 'asc')
              }}
            >
              {getReadableAccountFieldLabel('roles')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'permission'}
              direction={sortDirection}
              onClick={() => {
                handleSort('permission', 'asc')
              }}
            >
              {getReadableAccountFieldLabel('permission')}
            </TableSortLabel>
          </TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {connectedMember && searchResult.some((member) => member.id === connectedMember.id) && (
          <MemberListItem member={connectedMember} bgColor={setOpacity(colors.bermudaGray, 0.35)} />
        )}

        {searchResult
          .filter((member) => member.id != connectedMember?.id)
          .map((member) => (
            <MemberListItem key={member.id} member={member} />
          ))}
      </TableBody>
    </Table>
  ) : (
    // Mobile device
    <Stack spacing={1.5} marginTop="10px" marginBottom="80px" marginX="15px">
      {connectedMember && searchResult.some((member) => member.id === connectedMember.id) && (
        <MemberListCard
          member={connectedMember}
          bgColor={setOpacity(colors.bermudaGray, 0.35)}
          index={0}
          expandCardIndex={expandCardIndex}
          setExpandCardIndex={setExpandCardIndex}
        />
      )}

      {searchResult
        .filter((member) => member.id != connectedMember?.id)
        .map((member, index) => (
          <MemberListCard
            key={member.id}
            member={member}
            index={index + 1}
            expandCardIndex={expandCardIndex}
            setExpandCardIndex={setExpandCardIndex}
          />
        ))}
    </Stack>
  )
}

type ItemProps = {
  member: AccountDto
  bgColor?: string
}

const MemberListItem: React.FC<ItemProps> = ({ member, bgColor }) => {
  const navigate = useNavigate()
  const [animals, setAnimals] = React.useState<AnimalSearchItemDto[] | null>(null)
  const [howManyAnimals, setHowManyAnimals] = React.useState<number>(0)

  const handleClick = () => {
    navigate(`${member.id}`)
  }

  const fetchAnimals = async () => {
    if (animals) return
    const fetchedAnimals = await AnimalsClient.getAllAnimalForHostFamilyAccountId(member.id)
    const filteredAnimals = fetchedAnimals.filter(
      (animal) =>
        animal.responsibilities?.hostFamilyInChargeAccountId === member.id &&
        animal.adoptionStatus !== AnimalAdoptionDtoStatus.HasBeenAdopted &&
        !(
          animal.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted &&
          (animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Dead ||
            animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Released ||
            animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation ||
            animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Lost)
        )
    )
    setAnimals(filteredAnimals)
    setHowManyAnimals(filteredAnimals.length)
  }

  const profileImageKey = member.images?.profileImageKey

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 240,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))

  return (
    // <TableRow>
    <TableRow onClick={handleClick} sx={{ cursor: 'pointer', backgroundColor: bgColor }}>
      <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
        <Avatar alt={member.firstName} src={profileImageKey && getAccountProfilePhotoSrc(profileImageKey)} />
      </TableCell>
      <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {member.firstName} {member.lastName}
          {member.validated && <Verified sx={{ color: theme.palette.info.main, fontSize: 20 }} />}
        </Box>
        <span style={{ fontWeight: 400, fontStyle: 'italic' }}>
          {member.roles.map((role) => getSmallReadableAccountRole(role)).join(', ')}
        </span>
        <br />
        <span style={{ fontWeight: 400, color: blueSky }}>{getReadableAccountPermission(member.permission)}</span>
      </TableCell>
      <TableCell component="th" scope="row">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Avatar
            sx={{
              background: setOpacity(getColorForAvailabilityStatus(isUserAvailableToday(member)), 0.7),
              width: '22px',
              height: '22px',
              fontWeight: 'bold',
            }}
          >
            {' '}
          </Avatar>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row">
        {member.home?.hasIsolationRoom === AccountDtoHomeHasIsolationRoom.Yes ? 'Oui' : 'Non'}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.preferences.primaryPreferences?.withMedicalAntecedent ===
        AccountDtoPreferencesWillingToSocializeWildAnimals.Yes
          ? 'Oui'
          : 'Non'}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.home?.pets?.length && member.home.pets.length > 0 ? 'Oui' : 'Non'}
      </TableCell>
      <TableCell component="th" scope="row">
        {`${member.home?.homeSurface ?? 'NC'} ${member.home?.homeSurface ? 'm²' : ''}`}
      </TableCell>
      <TableCell component="th" scope="row" onClick={(e) => e.stopPropagation()}>
        <HtmlTooltip
          onOpen={async () => fetchAnimals()}
          title={
            animals ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography sx={{ fontWeight: 700 }}>
                  Capacité d&#39;accueil: {howManyAnimals}/{member.home?.maxCapacity || 'NC'}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {animals
                    .filter((a) => a.adoptionStatus !== 'has-been-adopted')
                    .map((animal) => (
                      <AnimalProfileChip
                        key={animal.id}
                        animal={animal}
                        chipSx={AnimalChipSxProps}
                        avatarSx={AnimalAvatarSxProps}
                      />
                    ))}
                  {animals.length === 0 && <Typography>Aucun en FA</Typography>}
                </Box>
              </Box>
            ) : (
              <CircularProgress />
            )
          }
        >
          <Typography>Voir les animaux en FA</Typography>
        </HtmlTooltip>
      </TableCell>
      {/* <TableCell component="th" scope="row">
        {member.roles.map((role) => getReadableAccountRole(role)).join(', ')}
      </TableCell>
      <TableCell component="th" scope="row">
        {getReadableAccountPermission(member.permission)}
      </TableCell> */}
    </TableRow>
  )
}

type ChipProps = {
  animal: AnimalSearchItemDto
  chipSx: SxProps
  avatarSx: SxProps
}
const AnimalChipSxProps = {
  fontSize: 16,
  height: 45,
  backgroundColor: 'white',
}
const AnimalAvatarSxProps = {
  height: '35px !important',
  width: '35px !important',
}

export const AnimalProfileChip: React.FC<ChipProps> = ({ animal, chipSx, avatarSx }) => {
  const navigate = useNavigate()
  return (
    <Chip
      key={animal.id}
      clickable
      onClick={() => navigate(`${PATHS.animals.absolute}/${animal.id}`)}
      label={`${animal.name} ${getEmojiSpecies(animal.species)} ${animal.sex ? getEmojiAnimalSex(animal.sex) : ''} `}
      avatar={
        <Avatar
          alt={`Photo de ${animal.name}`}
          src={animal.images?.profileImageKey && getAnimalProfilePhotoSrc(animal.images.profileImageKey)}
          sx={avatarSx}
        />
      }
      variant="outlined"
      sx={chipSx}
    />
  )
}
