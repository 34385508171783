import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, Box, Typography, DialogActions, TextField, useMediaQuery } from '@mui/material'
import { blue } from '@mui/material/colors'
import React from 'react'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { AdoptionAttemptDto } from '../../../interactors/gen/backendClient'
import { useMutation } from 'react-query'
import { theme } from '../../theme'

type Props = {
  adopterId: string
  adoptionAttempt: AdoptionAttemptDto
  type: 'certificate' | 'contract'
  setAdoptionAttempt: React.Dispatch<React.SetStateAction<AdoptionAttemptDto | undefined>>
  disabled?: boolean
}

export const RefuseFileModal: React.FC<Props> = ({
  adopterId,
  adoptionAttempt,
  type,
  setAdoptionAttempt,
  disabled = false,
}) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()
  const [refusedReason, setRefusedReason] = React.useState<string>()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const mutation = useMutation(
    async (data: AdoptionAttemptDto) => {
      return await AdoptersClient.updateAdoptionAttempt(adopterId, data)
    },
    {
      onSuccess: (data) => {
        setAdoptionAttempt(data)
        closeDialog()
        globalSnackbar.triggerSuccessMessage(`Votre document a bien été ajouté !`)
      },
    }
  )

  const onSubmit = () => {
    if (refusedReason) {
      mutation.mutate({
        ...adoptionAttempt,
        [type]: {
          ...adoptionAttempt[type],
          refusedReason,
          signed: undefined,
          signedDate: undefined,
        },
        status: getNewStatusForType(type),
      })
    } else {
      globalSnackbar.triggerErrorMessage(`Vous devez ajouter une raison !`)
    }
  }
  return (
    <>
      <Button
        variant="contained"
        color="error"
        fullWidth={true}
        sx={{
          textTransform: 'none',
          fontSize: { xs: '14px', sm: '16px' },
          padding: { xs: 0.5, sm: 'auto' },
        }}
        onClick={openDialog}
        disabled={disabled}
      >
        {`Refuser le document`}
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          closeDialog()
        }}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{ fontSize: { xs: '18px', sm: '20px' }, color: blue, mb: { xs: 3, sm: 4 } }}
            >
              {`Voulez-vous vraiment refuser le document ?`}
            </Typography>
            <TextField label="Raison du refus" multiline rows={4} onChange={(e) => setRefusedReason(e.target.value)} />
          </Box>
        </DialogContent>
        <DialogActions>
          <>
            <Button
              data-testid="cancel"
              onClick={() => {
                closeDialog()
              }}
              variant={isMobile ? 'outlined' : 'text'}
              sx={{
                textTransform: 'none',
                fontSize: { xs: '14px', sm: '16px' },
                width: { xs: '40%', sm: 'auto' },
              }}
            >
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              sx={{
                textTransform: 'none',
                fontSize: { xs: '14px', sm: '16px' },
                width: { xs: '60%', sm: 'auto' },
                px: { xs: '5px', sm: 'auto' },
              }}
              loading={mutation.isLoading}
              onClick={onSubmit}
            >
              Refuser le document
            </LoadingButton>{' '}
          </>
        </DialogActions>
      </Dialog>
    </>
  )
}

function getNewStatusForType(type: 'certificate' | 'contract') {
  switch (type) {
    case 'certificate':
      return 'awaiting-signed-certificate'
    case 'contract':
      return 'awaiting-signed-contract'
  }
}
