import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import { CustomerDto, CustomerPaymentInfoDto } from '../../../interactors/gen/backendClient'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { MemberCardSaveButton } from '../../Members/MemberCardSaveButton'
import { spacingItem, theme } from '../../theme'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import { readableAxiosError } from '../../../utils/axios'
import { useAccountsStore } from '../../../store/AccountsStore'

interface Props {
  customer: CustomerDto
  setCustomer: (customer: CustomerDto) => void
  noEdit?: boolean
}

interface FormParams {
  IBANNumber: CustomerPaymentInfoDto['IBANNumber']
  IBANFirstName: CustomerPaymentInfoDto['IBANFirstName']
  IBANLastName: CustomerPaymentInfoDto['IBANLastName']
  checkOrder: CustomerPaymentInfoDto['checkOrder']
  onlinePaymentURL: CustomerPaymentInfoDto['onlinePaymentURL']
}

export const PaymentInfoModal: React.FC<Props> = ({ customer, setCustomer, noEdit = false }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dialogOpenedState = useDialogOpened()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()

  //Mutation to edit the customer
  const editCustomerMutation = useMutation(
    async (data: CustomerDto) => {
      const response = await CustomersClient.editCustomer(data)
      return response
    },
    {
      onSuccess: (customer) => {
        globalSnackBarStore.triggerSuccessMessage(`Le profil de ${customer.name} a été modifié.`)
        const fetchCustomer = async () => {
          const customer = await CustomersClient.getCurrentCustomer()
          setCustomer(customer)
          accountsStore.changeConnectedCustomer(customer)
        }

        fetchCustomer()
      },
      onError: (error: Error | AxiosError) => {
        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const processSubmit = (data: FormParams) => {
    editCustomerMutation.mutate({ ...customer, paymentInfo: { ...(data as CustomerPaymentInfoDto) } })
    return dialogOpenedState.closeDialog()
  }

  const getDefaultValues = (customer: CustomerDto): FormParams => ({
    IBANNumber: customer?.paymentInfo?.IBANNumber,
    IBANFirstName: customer?.paymentInfo?.IBANFirstName,
    IBANLastName: customer?.paymentInfo?.IBANLastName,
    checkOrder: customer?.paymentInfo?.checkOrder,
    onlinePaymentURL: customer?.paymentInfo?.onlinePaymentURL,
  })

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useFormExtended(customer, getDefaultValues)
  return (
    <>
      {/* MOBILE */}
      {isMobile ? (
        <Button
          variant="outlined"
          color="primary"
          sx={{ textTransform: 'none', width: '100%', mt: 1 }}
          onClick={dialogOpenedState.openDialog}
        >
          {noEdit ? 'Consulter' : 'Editer'}
        </Button>
      ) : (
        // DESKTOP
        <Button variant="outlined" color="primary" onClick={dialogOpenedState.openDialog}>
          {noEdit ? 'Consulter' : 'Editer'}
        </Button>
      )}
      {/* MOBILE + DESKTOP */}
      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>{`${noEdit ? 'Consulter' : 'Editer'} les informations de paiement`}</DialogTitle>

        <form
          onSubmit={handleSubmit(processSubmit)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)', pt: isMobile ? 0 : 'auto' }}>
              {!noEdit && (
                <Typography variant="h4" sx={{ fontSize: isMobile ? 14 : 18, mb: 4, mt: 1, color: 'black' }}>
                  Attention, les infos que vous modifiez ici sont globales à l&#39;asso et seront utilisées pour tous
                  les adoptants.
                </Typography>
              )}
              <Typography
                variant="h4"
                sx={{
                  fontSize: isMobile ? 14 : 18,
                  mb: isMobile ? 2 : 3,
                  mt: 1,
                  ml: isMobile ? 'auto' : -1,
                  color: 'black',
                }}
              >
                Virement bancaire
              </Typography>
              <Box display="flex" width="100%" alignItems="center">
                <TextField
                  label="IBAN"
                  type="text"
                  fullWidth
                  {...register('IBANNumber')}
                  error={!!errors.IBANNumber}
                  helperText={errors.IBANNumber?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Prénom du titulaire du compte"
                  type="text"
                  fullWidth
                  {...register('IBANFirstName')}
                  error={!!errors.IBANFirstName}
                  helperText={errors.IBANFirstName?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Nom du titulaire du compte"
                  type="text"
                  fullWidth
                  {...register('IBANLastName')}
                  error={!!errors.IBANLastName}
                  helperText={errors.IBANLastName?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontSize: isMobile ? 14 : 18,
                  mb: isMobile ? 2 : 3,
                  mt: isMobile ? 2 : 1,
                  ml: isMobile ? 'auto' : -1,
                  color: 'black',
                }}
              >
                Chèque
              </Typography>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Ordre du chèque"
                  type="text"
                  fullWidth
                  {...register('checkOrder')}
                  error={!!errors.checkOrder}
                  helperText={errors.checkOrder?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontSize: isMobile ? 14 : 18,
                  mb: isMobile ? 2 : 3,
                  mt: isMobile ? 2 : 1,
                  ml: isMobile ? 'auto' : -1,
                  color: 'black',
                }}
              >
                Paiement en ligne
              </Typography>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Lien de paiement en ligne"
                  type="text"
                  fullWidth
                  {...register('onlinePaymentURL')}
                  error={!!errors.onlinePaymentURL}
                  helperText={errors.onlinePaymentURL?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </DialogContent>

            <DialogActions>
              {noEdit ? (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    width: isMobile ? '100%' : 'auto',
                  }}
                  onClick={dialogOpenedState.closeDialog}
                >
                  Revenir
                </Button>
              ) : (
                <MemberCardSaveButton isOwnAccount={false} />
              )}
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
