import { Box, Card, CardContent, Grid } from '@mui/material'

import { FC } from 'react'
import {
  getReadableAnimalBreedTitle,
  getReadableAnimalFieldLabel,
  getReadableAnimalHairLength,
  getReadableIdentificationNumber,
  getReadableSex,
  getReadableSpecies,
  getReadableTattooLocation,
} from '../../../domain/Animal/AnimalDisplay'
import { AnimalDto } from '../../../interactors/gen/backendClient'
import { getReadableBooleanOrUnknown } from '../../../utils/getReadableBooleanOrUnknown'

import { ReadOnlyItem } from '../../common/ReadOnlyItem'
import { spacingItem } from '../../theme'
import { EditGeneralInfoAnimalPrompt } from './EditGeneralInfoAnimalPrompt'
import { useCanEditAnimal } from '../../../store/useCanEditAnimal'

interface Props {
  animal: AnimalDto
}

export const AnimalGeneralCard: FC<Props> = ({ animal }) => {
  const canEditAnimal = useCanEditAnimal()

  const defaultItemProps = { xs: 6, sm: 3, item: true, marginBottom: 2 }
  const smallItemProps = defaultItemProps

  return (
    <>
      <Card sx={spacingItem}>
        <CardContent>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid {...smallItemProps}>
                  <ReadOnlyItem
                    label={getReadableAnimalFieldLabel('species') + ' / ' + getReadableAnimalFieldLabel('sex')}
                    value={`${getReadableSpecies(animal!.species, { withEmoji: true })} / ${
                      animal.sex
                        ? getReadableSex(animal.sex, {
                            withEmoji: true,
                          })
                        : ''
                    }`}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={animal.sex === 'male' ? 'Castré' : 'Stérilisée'}
                    value={
                      animal.health.sterilizedOrNeutered
                        ? getReadableBooleanOrUnknown(animal.health.sterilizedOrNeutered)
                        : getReadableBooleanOrUnknown('false')
                    }
                  />
                </Grid>

                <Grid {...smallItemProps}>
                  <ReadOnlyItem
                    label={getReadableAnimalFieldLabel('identificationNumber')}
                    value={
                      animal.identificationNumber
                        ? getReadableIdentificationNumber(animal.identificationNumber?.toString())
                        : '..'
                    }
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={getReadableAnimalFieldLabel('tattoo')}
                    value={`${animal?.tattoo?.number ? animal?.tattoo?.number : '..'} - ${
                      animal?.tattoo?.location ? getReadableTattooLocation(animal?.tattoo?.location) : '..'
                    } `}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label="Date de naissance"
                    value={
                      animal.birthday?.date
                        ? `${animal.birthday?.date} ${animal.birthday?.isApproximate ? '(approx...)' : ''}`
                        : '..'
                    }
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={
                      animal.breedData?.breedTitle
                        ? getReadableAnimalBreedTitle(animal.breedData?.breedTitle)
                        : 'Type de race'
                    }
                    value={animal.breed || '..'}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={getReadableAnimalFieldLabel('hair')}
                    value={animal.hairLength ? getReadableAnimalHairLength(animal.hairLength) : animal.hair || '..'}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem label={getReadableAnimalFieldLabel('color')} value={animal.color || '..'} />
                </Grid>
              </Grid>
            </Box>

            {canEditAnimal && (
              <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
                <EditGeneralInfoAnimalPrompt animal={animal} />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
