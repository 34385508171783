import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Avatar,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { spacingItem } from '../../theme'
import { AddCircleOutline, ArrowForward, Delete, Edit, Launch, Save } from '@mui/icons-material'
import { useAccountsStore } from '../../../store/AccountsStore'
import {
  AccountDto,
  AccountDtoRoles,
  CustomerBureauMemberDto,
  CustomerBureauMemberDtoTitles,
  CustomerDto,
} from '../../../interactors/gen/backendClient'
import { fullName } from '../../../domain/Account/AccountDisplay'
import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { useMutation } from 'react-query'
import { AccountsClient } from '../../../interactors/clients/AccountsClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../../utils/axios'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { EditPetsoContactPrompt } from './EditPetsoContactPrompt'
import { getAccountProfilePhotoSrc } from '../../../utils/S3-links'
import { Controller, useForm } from 'react-hook-form'
import { getReadableBureauMemberTitle } from '../../../domain/Customer/CustomerDisplay'
import { ControlledMultipleAutoComplete } from '../../common/ControlledMultipleAutoComplete'
import { LoadingButton } from '@mui/lab'
import { Link, useNavigate } from 'react-router-dom'
import { EditSanitaryVeterinarianPrompt } from './EditSanitaryVeterinarianPrompt'
import { omitEmptyString } from '../../../utils/omitEmptyString'
import { PATHS } from '../../PATHS'
import { EditAdoptionDocumentsValidator } from './EditAdoptionDocumentsValidator'

interface Props {
  association: CustomerDto
  onSubmit: (data: Partial<CustomerDto>) => void
}

const uniqueTitles = Object.values(CustomerBureauMemberDtoTitles).filter((title) => title !== 'vice-president')

const manageableTitles = Object.values(CustomerBureauMemberDtoTitles).map((title) =>
  getReadableBureauMemberTitle(title)
)

export const AssociationBureauMembers: React.FC<Props> = ({ association, onSubmit }) => {
  const [bureauMembers, setBureauMembers] = useState<CustomerBureauMemberDto[]>(association?.bureauMembers || [])
  const accountsStore = useAccountsStore()
  const petsoContact = accountsStore.members?.find((member) => member.id === association?.masterAccountId)
  const adoptionDocumentsValidator = accountsStore.members?.find(
    (member) => member.id === association?.adoptionDocumentsValidatorAccountId
  )
  const sanitaryVeterinarian = accountsStore.members?.find(
    (member) => member.id === association?.sanitaryVeterinarianAccountId
  )

  const petsoContactProfileImageKey = petsoContact?.images?.profileImageKey
  const adoptionDocumentsValidatorProfileImageKey = adoptionDocumentsValidator?.images?.profileImageKey
  const sanitaryVeterinarianProfileImageKey = sanitaryVeterinarian?.images?.profileImageKey

  const initialOperationsRef = useRef(association?.bureauMembers)
  const initialMount = useRef(true)

  useEffect(() => {
    const saveBureauMembers = async () => {
      // only save if vaccinations have been modified
      if (JSON.stringify(bureauMembers) !== JSON.stringify(initialOperationsRef.current)) {
        const update = omitEmptyString({
          ...association,
          bureauMembers: bureauMembers,
        })

        await onSubmit(update)
      }
    }

    saveBureauMembers()
  }, [bureauMembers])

  useEffect(() => {
    if (initialMount.current) {
      if (association?.bureauMembers) {
        setBureauMembers(association.bureauMembers)
      } else {
        const timeoutId = setTimeout(() => {
          setBureauMembers(association.bureauMembers ?? [])
        }, 1000)

        // Cleanup function to clear the timeout
        return () => clearTimeout(timeoutId)
      }

      initialMount.current = false
    }
  }, [association])

  return (
    <Card sx={{ ...spacingItem, height: 'calc(100% - 16px)', boxSizing: 'border-box' }}>
      <CardHeader title="Les représentants" />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
            <Typography variant="h4" sx={{ fontSize: 18, mb: 2, textDecoration: 'underline' }}>
              Point de contact avec Petso:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2 }}>
              {association?.masterAccountId ? (
                <>
                  <Avatar
                    alt={petsoContact?.firstName}
                    src={petsoContactProfileImageKey && getAccountProfilePhotoSrc(petsoContactProfileImageKey)}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography fontSize={20} fontWeight={700}>
                      <Link
                        to={`/suivi-benevole/${petsoContact?.id}`}
                        style={{ color: 'black', textDecoration: 'none' }}
                      >
                        {petsoContact ? fullName(petsoContact) : '..'}
                      </Link>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography fontSize={16}>
                        Point de contact Petso
                        <br />
                        {petsoContact?.email ? petsoContact?.email : '..'}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.1, pr: 5 }}
                >
                  Le point de contact Petso n&#39;a pas encore été défini, veuillez le faire en cliquant sur le crayon
                  {'   '}
                  <ArrowForward />
                </Typography>
              )}
            </Box>
          </Box>
          {accountsStore.isAdmin() && (
            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditPetsoContactPrompt association={association} onSubmit={onSubmit} />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
            <Typography variant="h4" sx={{ fontSize: 18, mb: 2, textDecoration: 'underline' }}>
              Signataire des documents d&#39;adoption:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2 }}>
              {association?.adoptionDocumentsValidatorAccountId ? (
                <>
                  <Avatar
                    alt={adoptionDocumentsValidator?.firstName}
                    src={
                      adoptionDocumentsValidatorProfileImageKey &&
                      getAccountProfilePhotoSrc(adoptionDocumentsValidatorProfileImageKey)
                    }
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography fontSize={20} fontWeight={700}>
                      <Link
                        to={`/suivi-benevole/${adoptionDocumentsValidator?.id}`}
                        style={{ color: 'black', textDecoration: 'none' }}
                      >
                        {adoptionDocumentsValidator ? fullName(adoptionDocumentsValidator) : '..'}
                      </Link>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography fontSize={16}>
                        Point de contact Petso
                        <br />
                        {adoptionDocumentsValidator?.email ? adoptionDocumentsValidator?.email : '..'}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.1, pr: 5 }}
                >
                  Le signataire des documents d&#39;adoption n&#39;a pas encore été défini, veuillez le faire en
                  cliquant sur le crayon
                  {'   '}
                  <ArrowForward />
                </Typography>
              )}
            </Box>
          </Box>
          {accountsStore.isAdmin() && (
            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditAdoptionDocumentsValidator association={association} onSubmit={onSubmit} />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
          <Typography variant="h4" sx={{ fontSize: 18, mt: 4, mb: 1, textDecoration: 'underline' }}>
            Membres du bureau:
          </Typography>
          {bureauMembers?.map((bureauMember, i) => (
            <BureauMemberItem
              key={bureauMember.accountId + i.toString()}
              bureauMember={bureauMember}
              setBureauMembers={setBureauMembers}
              bureauMembers={bureauMembers}
            />
          ))}
          <NewBureauMemberPrompt setBureauMembers={setBureauMembers} bureauMembers={bureauMembers} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: { xs: 0, sm: 2, md: 6 } }}>
            <Typography variant="h4" sx={{ fontSize: 18, mb: 2, textDecoration: 'underline' }}>
              Vétérinaire sanitaire:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2 }}>
              {association?.sanitaryVeterinarianAccountId ? (
                <>
                  <Avatar
                    alt={sanitaryVeterinarian?.firstName}
                    src={
                      sanitaryVeterinarianProfileImageKey &&
                      getAccountProfilePhotoSrc(sanitaryVeterinarianProfileImageKey)
                    }
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography fontSize={20} fontWeight={700}>
                      <Link
                        to={`/suivi-benevole/${sanitaryVeterinarian?.id}`}
                        style={{ color: 'black', textDecoration: 'none' }}
                      >
                        {sanitaryVeterinarian ? fullName(sanitaryVeterinarian) : '..'}
                      </Link>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography fontSize={16}>
                        Vétérinaire sanitaire
                        <br />
                        {sanitaryVeterinarian?.email ? sanitaryVeterinarian?.email : '..'}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.1, pr: 5 }}
                >
                  Le vétérinaire sanitaire n&#39;a pas encore été défini, veuillez le faire en cliquant sur le crayon
                  {'   '}
                  <ArrowForward />
                </Typography>
              )}
            </Box>
          </Box>
          {accountsStore.isAdmin() && (
            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditSanitaryVeterinarianPrompt association={association} onSubmit={onSubmit} />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

interface BureauMemberItemProps {
  bureauMember: CustomerBureauMemberDto
  bureauMembers: CustomerBureauMemberDto[]
  setBureauMembers: React.Dispatch<React.SetStateAction<CustomerBureauMemberDto[]>>
}

const BureauMemberItem: React.FC<BureauMemberItemProps> = ({ bureauMember, setBureauMembers, bureauMembers }) => {
  const accountsStore = useAccountsStore()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()
  const bureauMemberAccount = accountsStore.members?.find((member) => member.id === bureauMember?.accountId)
  const profileImageKey = bureauMemberAccount?.images?.profileImageKey

  const editAccountMutation = useMutation(
    async (data: AccountDto) => {
      const response = await AccountsClient.editAccount(data)
      return response
    },
    {
      onSuccess: () => {
        console.log('Utilisateur modifié avec succès')
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            globalSnackBarStore.triggerErrorMessage('Un utilisateur existe déjà avec cette adresse mail.')
            return
          }
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  if (!bureauMemberAccount) return null
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        p: 2,
        mt: 2,
        boxShadow: '0px 2px 15px 1px #F0F0F0',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <Avatar
            alt={bureauMemberAccount?.firstName}
            src={profileImageKey && getAccountProfilePhotoSrc(profileImageKey)}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontSize={20} fontWeight={700}>
              {bureauMemberAccount ? fullName(bureauMemberAccount) : '..'}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography fontSize={16}>
                {bureauMember?.titles
                  ? bureauMember?.titles?.map((bureauMember) => getReadableBureauMemberTitle(bureauMember)).join(', ')
                  : '..'}
                <br />
                {bureauMemberAccount?.email ? bureauMemberAccount?.email : '..'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 2, md: 0 } }}>
          <IconButton color="primary" onClick={() => navigate(`/suivi-benevole/${bureauMemberAccount.id}`)}>
            <Launch />
          </IconButton>
          <EditBureauMemberPrompt
            bureauMember={bureauMember}
            bureauMembers={bureauMembers}
            setBureauMembers={setBureauMembers}
            bureauMemberAccount={bureauMemberAccount}
            disabled={!accountsStore.isAdmin()}
          />
          <IconButton
            sx={{ color: 'error.main' }}
            onClick={() => {
              const otherTitles = bureauMemberAccount.titles?.filter((title) => !manageableTitles.includes(title)) || []
              const editedAccount = {
                ...bureauMemberAccount,
                titles: otherTitles,
                hasACACED: undefined,
              }
              editAccountMutation.mutateAsync(editedAccount)
              setBureauMembers(bureauMembers.filter((AbureauMember) => AbureauMember !== bureauMember))
            }}
            disabled={!accountsStore.isAdmin()}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

interface NewBureauMemberProps {
  bureauMembers: CustomerBureauMemberDto[]
  setBureauMembers: React.Dispatch<React.SetStateAction<CustomerBureauMemberDto[]>>
}

export const NewBureauMemberPrompt: React.FC<NewBureauMemberProps> = ({ setBureauMembers, bureauMembers }) => {
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()
  const globalSnackBarStore = useGlobalSnackbarStore()

  const [hasACACED, setHasACACED] = React.useState<boolean>(false)

  const editAccountMutation = useMutation(
    async (data: AccountDto) => {
      const response = await AccountsClient.editAccount(data)
      return response
    },
    {
      onSuccess: () => {
        console.log('Utilisateur modifié avec succès')
        accountsStore.fetchMembers()
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            globalSnackBarStore.triggerErrorMessage('Un utilisateur existe déjà avec cette addresse mail.')
            return
          }
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const submitProcess = async (data: CustomerBureauMemberDto) => {
    let isDataValid = true
    for (const title of uniqueTitles) {
      if (data.titles.includes(title)) {
        const isValid = checkUniqueTitle(title, '', bureauMembers)
        if (!isValid) {
          const errorMessage = `Il ne peut y avoir qu'un seul ${getReadableBureauMemberTitle(title)}.`
          globalSnackBarStore.triggerErrorMessage(errorMessage)
          isDataValid = false
          break // Once invalid data is found, exit the loop immediately.
        }
      }
    }

    if (!isDataValid) return

    const BureauMemberAccount = accountsStore.members?.find((member) => member.id === data?.accountId)
    if (BureauMemberAccount) {
      const mappedTitles = data.titles.map((title) => getReadableBureauMemberTitle(title))
      const otherTitles = BureauMemberAccount.titles?.filter((title) => !manageableTitles.includes(title)) || []
      const editedAccount = {
        ...BureauMemberAccount,
        titles: [...otherTitles, ...mappedTitles],
        hasACACED: hasACACED,
      }
      await editAccountMutation.mutateAsync(editedAccount)
    }

    const newBureauMember = {
      ...data,
    }

    reset()
    setBureauMembers([...bureauMembers, newBureauMember])
    return dialogOpenedState.closeDialog()
  }

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<CustomerBureauMemberDto>({
    defaultValues: {
      titles: [],
      accountId: queryParams.get('memberInCharge') || '',
      isSignatory: false,
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (queryParams.get('card') === 'newBureauMember') {
      dialogOpenedState.openDialog()
    }
  }, [])

  const accountId = watch('accountId')
  useEffect(() => {
    if (accountId === 'addAccount') {
      reset({ accountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=association?tab=my-association&role=${AccountDtoRoles.Member}&card=newBureauMember`
      )
    }
  }, [accountId])

  return (
    <>
      <Box
        onClick={() =>
          accountsStore.isAdmin()
            ? dialogOpenedState.openDialog()
            : globalSnackBarStore.triggerErrorMessage("Vous n'avez pas les droits pour ajouter un membre du bureau.")
        }
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '100%',
          p: 2,
          py: 4,
          mt: 2,
          boxShadow: '0px 2px 15px 1px #F0F0F0',
          cursor: 'pointer',
          color: 'primary.main',
          gap: 2,
          transition: 'all 0.1s ease-in-out',
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      >
        <AddCircleOutline sx={{ fontSize: 32 }} />
        <Typography fontSize={20} fontWeight={700}>
          Ajouter un membre
        </Typography>
      </Box>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Ajouter un membre du bureau</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <ControlledMultipleAutoComplete
                freeSolo={false}
                control={control}
                error={undefined}
                fieldName="titles"
                label="Poste(s)"
                options={Object.values(CustomerBureauMemberDtoTitles).map((option) => ({
                  label: getReadableBureauMemberTitle(option),
                  value: option,
                }))}
                requiredRule="Le poste est obligatoire"
                sx={spacingItem}
              />
              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'accountId'}
                options={[
                  { value: 'addAccount', label: 'Ajouter un bénévole' },
                  ...accountsStore.members
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Membre du bureau"
                error={errors.accountId}
                requiredRule="Le membre du bureau est obligatoire"
                defaultValue={''}
                highlight
                sx={spacingItem}
              />
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%', mr: '5%', ...spacingItem }}>
                  <Typography fontSize={16} fontWeight={700}>
                    Ce membre possède-t-il l&#39;ACACED ?<span style={{ color: 'red' }}> *</span>
                  </Typography>
                  <ToggleButtonGroup
                    exclusive
                    value={hasACACED}
                    onChange={(_, newValue) => setHasACACED(newValue)}
                    aria-label="text alignment"
                    fullWidth
                  >
                    <ToggleButton value={true} aria-label="Oui">
                      Oui
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="Non">
                      Non
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%', ml: '5%', ...spacingItem }}>
                  <Typography fontSize={16} fontWeight={700}>
                    Ce membre est-il signataire de l&#39;association ?<span style={{ color: 'red' }}> *</span>
                  </Typography>
                  <Controller
                    name="isSignatory"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <ToggleButtonGroup
                        exclusive
                        value={value}
                        onChange={(_, newValue) => onChange(newValue)}
                        aria-label="text alignment"
                        fullWidth
                      >
                        <ToggleButton value={true} aria-label="Oui">
                          Oui
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="Non">
                          Non
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                </Box>
              </Box>
            </DialogContent>

            <DialogActions>
              <LoadingButton
                color="primary"
                variant="outlined"
                type="submit"
                disabled={!accountsStore.isAdmin()}
                sx={{ textTransform: 'none' }}
              >
                <Save sx={{ marginRight: 1 }} />
                Enregistrer
              </LoadingButton>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}

interface EditBureauMemberProps {
  bureauMember: CustomerBureauMemberDto
  bureauMembers: CustomerBureauMemberDto[]
  setBureauMembers: React.Dispatch<React.SetStateAction<CustomerBureauMemberDto[]>>
  bureauMemberAccount: AccountDto
  disabled?: boolean
}

const EditBureauMemberPrompt: React.FC<EditBureauMemberProps> = ({
  setBureauMembers,
  bureauMembers,
  bureauMember,
  bureauMemberAccount,
  disabled = false,
}) => {
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()
  const globalSnackBarStore = useGlobalSnackbarStore()

  const [hasACACED, setHasACACED] = React.useState<boolean>(bureauMemberAccount?.hasACACED || false)

  const editAccountMutation = useMutation(
    async (data: AccountDto) => {
      const response = await AccountsClient.editAccount(data)
      return response
    },
    {
      onSuccess: () => {
        console.log('Utilisateur modifié avec succès')
        accountsStore.fetchMembers()
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            globalSnackBarStore.triggerErrorMessage('Un utilisateur existe déjà avec cette addresse mail.')
            return
          }
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const submitProcess = async (data: CustomerBureauMemberDto) => {
    let isDataValid = true
    for (const title of uniqueTitles) {
      if (data.titles.includes(title)) {
        const isValid = checkUniqueTitle(title, bureauMember.accountId, bureauMembers)
        if (!isValid) {
          const errorMessage = `Il ne peut y avoir qu'un seul ${getReadableBureauMemberTitle(title)}.`
          globalSnackBarStore.triggerErrorMessage(errorMessage)
          isDataValid = false
          break // Once invalid data is found, exit the loop immediately.
        }
      }
    }

    if (!isDataValid) return

    if (bureauMemberAccount) {
      const mappedTitles = data.titles.map((title) => getReadableBureauMemberTitle(title))
      const otherTitles = bureauMemberAccount.titles?.filter((title) => !manageableTitles.includes(title)) || []
      const editedAccount = {
        ...bureauMemberAccount,
        titles: [...otherTitles, ...mappedTitles],
        hasACACED: hasACACED,
      }
      await editAccountMutation.mutateAsync(editedAccount)
    }

    const newBureauMember = {
      ...data,
    }

    setBureauMembers(
      bureauMembers.map((AbureauMember) => (AbureauMember === bureauMember ? newBureauMember : AbureauMember))
    )
    return dialogOpenedState.closeDialog()
  }

  const getDefaultValues = (bureauMember: CustomerBureauMemberDto): CustomerBureauMemberDto => ({
    titles: bureauMember?.titles,
    accountId:
      queryParams.get('card') === JSON.stringify(bureauMember)
        ? queryParams.get('memberInCharge') || ''
        : bureauMember?.accountId || '',
    isSignatory: bureauMember?.isSignatory || false,
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useFormExtended(bureauMember, getDefaultValues)

  useEffect(() => {
    if (queryParams.get('card') === JSON.stringify(bureauMember)) {
      dialogOpenedState.openDialog()
    }
  }, [])

  const accountId = watch('accountId')
  useEffect(() => {
    if (accountId === 'addAccount') {
      reset({ accountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=association?tab=my-association&role=${
          AccountDtoRoles.Member
        }&card=${JSON.stringify(bureauMember)}`
      )
    }
  }, [accountId])

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog} disabled={disabled}>
        <Tooltip title="Editer le membre du bureau">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Editer le membre du bureau</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <ControlledMultipleAutoComplete
                freeSolo={false}
                control={control}
                error={undefined}
                fieldName="titles"
                label="Poste(s)"
                options={Object.values(CustomerBureauMemberDtoTitles).map((option) => ({
                  label: getReadableBureauMemberTitle(option),
                  value: option,
                }))}
                requiredRule="Le poste est requis"
                sx={spacingItem}
              />
              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'accountId'}
                options={[
                  { value: 'addAccount', label: 'Ajouter un bénévole' },
                  ...accountsStore.members
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Membre du bureau"
                error={errors.accountId}
                requiredRule="Le membre du bureau est requis"
                defaultValue={bureauMember.accountId || ''}
                highlight
                sx={spacingItem}
              />
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%', mr: '5%', ...spacingItem }}>
                  <Typography fontSize={16} fontWeight={700}>
                    Ce membre possède-t-il l&#39;ACACED ?<span style={{ color: 'red' }}> *</span>
                  </Typography>
                  <ToggleButtonGroup
                    exclusive
                    value={hasACACED}
                    onChange={(_, newValue) => setHasACACED(newValue)}
                    aria-label="text alignment"
                    fullWidth
                  >
                    <ToggleButton value={true} aria-label="Oui">
                      Oui
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="Non">
                      Non
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%', ml: '5%', ...spacingItem }}>
                  <Typography fontSize={16} fontWeight={700}>
                    Ce membre est-il signataire de l&#39;association ?<span style={{ color: 'red' }}> *</span>
                  </Typography>
                  <Controller
                    name="isSignatory"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <ToggleButtonGroup
                        exclusive
                        value={value}
                        onChange={(_, newValue) => onChange(newValue)}
                        aria-label="text alignment"
                        fullWidth
                      >
                        <ToggleButton value={true} aria-label="Oui">
                          Oui
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="Non">
                          Non
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                </Box>
              </Box>
            </DialogContent>

            <DialogActions>
              <LoadingButton
                color="primary"
                variant="outlined"
                type="submit"
                disabled={!accountsStore.isAdmin()}
                sx={{ textTransform: 'none' }}
              >
                <Save sx={{ marginRight: 1 }} />
                Enregistrer
              </LoadingButton>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}

function checkUniqueTitle(
  title: CustomerBureauMemberDtoTitles,
  currentId: string,
  bureauMembers: CustomerBureauMemberDto[]
): boolean {
  const memberWithGivenTitle = bureauMembers.find((bureauMember) => bureauMember.titles.includes(title))
  if (memberWithGivenTitle && memberWithGivenTitle.accountId !== currentId) {
    return false
  }
  return true
}
