import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { ControlledAnimalsAutocomplete } from '../../common/ControlledAnimalsAutocomplete'
import { blue } from '../../theme'
import { StepsFormParams } from '../AddAdopterDialog'
import { StepsProps } from './StepOne'
import { AdopterDto, AnimalAdoptionDtoStatus, AnimalDto } from '../../../interactors/gen/backendClient'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { useAdoptersStore } from '../../../store/AdoptersStore'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { getReadableAnimalAdoptionStatus } from '../../../domain/Animal/AnimalDisplay'

export const StepThree: React.FC<StepsProps> = ({ control, watch }) => {
  const [animal, setAnimal] = React.useState<AnimalDto | undefined>(undefined)
  const [animalAdopter, setAnimalAdopter] = React.useState<AdopterDto | undefined>(undefined)

  const adoptersStore = useAdoptersStore()

  const animalId = watch('animalId')

  useEffect(() => {
    const fetchAnimal = async () => {
      const response = await AnimalsClient.getAnimal(animalId)
      setAnimal(response)
      if (
        response.adoption.adopterId &&
        adoptersStore.adopters
          .find((adopter) => adopter.id === response.adoption.adopterId)
          ?.adoptionAttempts?.some((attempt) => attempt.animalId === response.id)
      ) {
        setAnimalAdopter(adoptersStore.adopters.find((adopter) => adopter.id === response.adoption.adopterId))
      } else {
        setAnimalAdopter(undefined)
      }
    }
    if (animalId) {
      fetchAnimal()
    }
  }, [animalId])
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
      <Box>
        <Typography variant="body2" sx={{ color: blue, fontSize: 20, fontWeight: 700 }}>
          Choisissez l&#39;animal à associer à l&#39;adoptant
        </Typography>
        <ControlledAnimalsAutocomplete<StepsFormParams> control={control} fieldName="animalId" />
        {animal && animalAdopter && (
          <Typography variant="body2" component="p" marginTop={2} fontSize={15} fontWeight={700}>
            {`Attention ! ${fullName(animalAdopter)} a déjà une procédure d'adoption pour cet animal.`}
            <br />
            {`Si vous démarrez une nouvelle procédure, la procédure de ${fullName(animalAdopter)} sera suspendue.`}
          </Typography>
        )}
        {animal && animal.adoption.status !== AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable && (
          <Typography variant="body2" component="p" marginTop={2} fontSize={15} fontWeight={700}>
            {`Attention ! ${animal.name} est enregistré en statut "${getReadableAnimalAdoptionStatus(
              animal.adoption.status
            )}".`}
            <br />
            {`Si vous continuez, son statut sera modifié en "En cours d'adoption".`}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
