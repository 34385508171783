import { TableRow, TableCell, Chip, Box, Avatar } from '@mui/material'
import React from 'react'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { CustomerCageWithAnimalsDetailsDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { getEmojiAnimalSex, getEmojiSpecies, getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { colors } from '../../theme'
import { AssociationEditCageModal } from './AssociationEditCageModal'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { getAnimalProfilePhotoSrc } from '../../../utils/S3-links'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../PATHS'

interface Props {
  cage: CustomerCageWithAnimalsDetailsDto
  setCagesList: React.Dispatch<React.SetStateAction<CustomerCageWithAnimalsDetailsDto[]>>
  cagesList: CustomerCageWithAnimalsDetailsDto[]
}

export const AssociationCageItem: React.FC<Props> = ({ cage, setCagesList, cagesList }) => {
  const accountsStore = useAccountsStore()
  const memberInCharge = accountsStore.members.find((member) => member.id === cage.memberInChargeId)
  const dialogOpenedState = useDialogOpened()
  const navigate = useNavigate()

  const handleClick = () => {
    dialogOpenedState.openDialog()
  }
  return (
    <>
      <TableRow
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        <TableCell component="th" scope="row">
          Box N°{cage.cageNumber}
        </TableCell>
        <TableCell component="th" scope="row">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cage.suitableForSpecies.map((species) => (
              <Chip
                key={species}
                label={getReadableSpecies(species)}
                sx={{
                  marginRight: 1,
                  backgroundColor: colors.blueSky,
                  color: 'white',
                }}
              />
            ))}
          </Box>
        </TableCell>

        <TableCell component="th" scope="row">
          {`${cage.capacity} ${cage.capacity > 1 ? 'animaux' : 'animal'}`}
        </TableCell>

        <TableCell component="th" scope="row">
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
            {cage.animals?.length && cage.animals.length > 0
              ? cage.animals?.map((animal) => (
                  <Chip
                    key={animal.id}
                    clickable
                    onClick={() => navigate(`${PATHS.animals.absolute}/${animal.id}`)}
                    avatar={
                      <Avatar
                        alt={`Photo de ${animal.name}`}
                        src={animal.profileImageKey && getAnimalProfilePhotoSrc(animal.profileImageKey)}
                        sx={{ height: '35px !important', width: '35px !important' }}
                      />
                    }
                    label={`${animal.name} ${getEmojiSpecies(animal.species)} ${
                      animal.sex ? getEmojiAnimalSex(animal.sex) : ''
                    } `}
                    variant="outlined"
                    sx={{
                      fontSize: 16,
                      height: 45,
                    }}
                  />
                ))
              : '..'}
          </Box>
        </TableCell>

        <TableCell component="th" scope="row">
          <Chip
            label={`${cage.animals?.length || 0}/${cage.capacity || 0}`}
            color={
              (cage.animals?.length || 0) === (cage.capacity || 0)
                ? 'warning' // Orange for full
                : (cage.animals?.length || 0) > (cage.capacity || 0)
                ? 'error' // Red for overloaded
                : 'success' // Green for everything else
            }
          />
        </TableCell>

        <TableCell component="th" scope="row">
          {memberInCharge ? fullName(memberInCharge) : '..'}
        </TableCell>
      </TableRow>
      <AssociationEditCageModal
        cage={cage}
        setCagesList={setCagesList}
        cagesList={cagesList}
        dialogOpenedState={dialogOpenedState}
      />
    </>
  )
}
