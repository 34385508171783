import { createTheme } from '@mui/material'

export const spacingItem = { marginTop: 2 }
export const spacingItemEquivalentForMask = { marginTop: 1 }

export const blue = 'rgba(32, 56, 100, 1)'
export const red = '#BC8076'
export const blueSky = '#698DAC'
export const lightGrey = '#F0F0F0'
export const yellowSemiLight = '#FFC300'
export const titleGrey = '#7C7C7C'

export const colors = {
  cello: '#203864',
  black: '#000000',
  white: '#FFFFFF',
  azalea: '#F7C8DF',
  mintTulip: '#D2E9F7',
  anakiwa: '#82CFFF',
  silver: '#CCCCCC',
  amber: '#FFC300',
  seaGreen: '#2F7D32',
  lightGreen: '#A8D5BA',
  athensGray: '#EEEFF3',
  punch: '#D93025',
  peach: '#FFE7AD',
  bermudaGray: '#698DAC',
  geyser: '#D6DEE1',
  yellowSemiLight,
  lightGrey,
  blueSky,
  red,
  blue,
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#FCB402',
      contrastText: '#fff', //button text white instead of black
      light: '#FFF8E2',
    },
    secondary: {
      main: '#698DAC',
      contrastText: '#fff',
    },
    warning: { main: '#F9A825' },
  },
  typography: {
    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;',
    h4: { color: blue },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '0.9rem',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        // Name of the slot
        title: {
          // Some CSS
          fontWeight: 'bold',
          color: blue,
        },
      },
    },
  },
})
