import React, { useState } from 'react'
import { AccountCommentsDto } from '../../../interactors/gen/backendClient'
import { Paper, Typography, IconButton, Tooltip, Box, TextField, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import dayjs from 'dayjs'
import { theme } from '../../theme'
import { useAccountsStore } from '../../../store/AccountsStore'
import { fullName } from '../../../domain/Account/AccountDisplay'

interface Props {
  comment: AccountCommentsDto
  onDelete: (commentId: string) => void
  onEdit: (commentId: string, comment: string) => void
}

export const MemberCommentItem: React.FC<Props> = ({ comment, onDelete, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedComment, setEditedComment] = useState(comment.comment)
  const accountsStore = useAccountsStore()

  const createdBy = accountsStore.members.find((member) => member.id === comment.createdBy)
  const isOwnComment = accountsStore.connectedAccount?.id === comment.createdBy

  const handleEdit = () => {
    onEdit(comment.id, editedComment)
    setIsEditing(false)
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: 2,
        marginBottom: 1,
        position: 'relative',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography variant="caption" color="textSecondary" sx={{ display: 'block', marginBottom: 1 }}>
        {`Ecrit par ${createdBy ? fullName(createdBy) : comment.createdBy} le ${dayjs(comment.createdAt).format(
          'DD/MM/YYYY'
        )}`}
      </Typography>
      {isEditing ? (
        <>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            value={editedComment}
            onChange={(e) => setEditedComment(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button variant="outlined" color="inherit" onClick={() => setIsEditing(false)}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleEdit}>
              Soumettre
            </Button>
          </Box>
        </>
      ) : (
        <Typography variant="body1">{comment.comment}</Typography>
      )}

      {isOwnComment && (
        <Box sx={{ position: 'absolute', top: 8, right: 8, display: 'flex', gap: 1 }}>
          {!isEditing && (
            <Tooltip title="Modifier mon commentaire" placement="top">
              <IconButton
                onClick={() => setIsEditing(true)}
                size="small"
                sx={{
                  '&:hover': { backgroundColor: 'transparent', color: theme.palette.primary.main },
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={isOwnComment ? `Supprimer mon commentaire` : `Vous ne pouvez pas supprimer ce commentaire`}
            placement="top"
          >
            <span>
              <IconButton
                onClick={() => onDelete(comment.id)}
                size="small"
                disabled={!isOwnComment}
                sx={{
                  '&:hover': { backgroundColor: 'transparent', color: theme.palette.error.main },
                }}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
    </Paper>
  )
}
