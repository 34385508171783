import create from 'zustand'
import { AdoptersClient } from '../interactors/clients/AdoptersClient'
import { AdopterDto, AdoptionAttemptDto } from '../interactors/gen/backendClient'

interface AdoptersStore {
  adopters: AdopterDto[]
  adoptionAttempts: Array<AdoptionAttemptDto | undefined>
  fetchAdopters: () => Promise<void>
  fetchOrRefreshAdopters: () => Promise<void>
  initializeAdoptersIfNeeded: () => Promise<void>
  refreshAdopters: () => Promise<void>
  isLoading: boolean
  areAdoptersInitialized: boolean
}

export const useAdoptersStore = create<AdoptersStore>()((set, get) => ({
  isLoading: false,
  adopters: [],
  adoptionAttempts: [],
  fetchAdopters: async () => {
    set({ isLoading: true })

    const adoptersFetched = await AdoptersClient.getAllAdopters()
    set({ adopters: adoptersFetched })
    set({ adoptionAttempts: adoptersFetched.map((adopter) => adopter.adoptionAttempts).flat() })
    set({ isLoading: false })
    set({ areAdoptersInitialized: true })
  },
  refreshAdopters: async () => {
    const refreshedAdopters = await AdoptersClient.getAllAdopters()
    set({ adopters: refreshedAdopters })
    set({ adoptionAttempts: refreshedAdopters.map((adopter) => adopter.adoptionAttempts).flat() })
  },
  fetchOrRefreshAdopters: async () => {
    if (get().areAdoptersInitialized) {
      await get().refreshAdopters()
    } else {
      await get().fetchAdopters()
    }
  },
  initializeAdoptersIfNeeded: async () => {
    if (!get().areAdoptersInitialized) {
      console.log('Loading adopters')
      await get().fetchAdopters()
      console.log('Adopters loaded')
    }
  },
  areAdoptersInitialized: false,
}))
