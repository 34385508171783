import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { toDateOnly } from '../../utils/date/DateOnly'
import { downloadXLSX } from '../../utils/downloadCSV'
import { encryptPassword } from '../../utils/encryptPassword'
import { AccountCommentsDto, AccountDto, CreateAccountBodyDto } from '../gen/backendClient'
import { backendClient } from './client'

export class AccountsClient {
  public static async createAccount(params: CreateAccountBodyDto): Promise<AccountDto> {
    const encryptedPassword = encryptPassword(params.password)

    const config: AxiosRequestConfig<CreateAccountBodyDto> = {
      method: 'POST',
      url: '/accounts',
      data: {
        ...params,
        password: encryptedPassword,
      },
    }

    const response = await backendClient<AccountDto>(config)

    return response.data
  }

  public static async editAccount(params: AccountDto & { password?: string }): Promise<AccountDto> {
    if (!params.password) {
      delete params.password
    } else {
      params.password = encryptPassword(params.password)
    }

    const config: AxiosRequestConfig<AccountDto> = {
      method: 'PUT',
      url: '/accounts',
      data: {
        ...params,
      },
    }

    const response = await backendClient<AccountDto>(config)

    return response.data
  }

  public static async deleteAccount(accountId: string): Promise<void> {
    const config: AxiosRequestConfig<{ accountId: string }> = {
      method: 'DELETE',
      url: '/accounts',
      data: {
        accountId,
      },
    }

    await backendClient<void>(config)
  }

  public static async uploadProfileImage(accountId: string, image: File): Promise<void> {
    const formData = new FormData()

    formData.append('file', image)

    const config: AxiosRequestConfig<FormData> = {
      method: 'POST',
      url: `/accounts/${accountId}/upload-profile-photos`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'image/',
      },
    }

    await backendClient<void>(config)
  }

  public static async downloadMembersExcel(associationName: string): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/accounts/export/xlsx`,
      responseType: 'blob',
    }

    const response = await backendClient<Blob>(config)

    await downloadXLSX(response.data, `Listing des membres Petso - ${associationName} - ${toDateOnly(dayjs())}`)
  }

  public static async addComment(accountId: string, comment: string): Promise<AccountCommentsDto[]> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/accounts/${accountId}/comments`,
      data: {
        comment,
      },
    }

    const response = await backendClient<AccountCommentsDto[]>(config)

    return response.data
  }

  public static async editComment(
    accountId: string,
    commentId: string,
    comment: string
  ): Promise<AccountCommentsDto[]> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/accounts/${accountId}/comments/${commentId}`,
      data: {
        comment,
      },
    }

    const response = await backendClient<AccountCommentsDto[]>(config)

    return response.data
  }

  public static async deleteComment(accountId: string, commentId: string): Promise<AccountCommentsDto[]> {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/accounts/${accountId}/comments/${commentId}`,
    }

    const response = await backendClient<AccountCommentsDto[]>(config)

    return response.data
  }
}
