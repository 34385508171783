import { Box, Card, CardContent, Grid, Tooltip, Typography } from '@mui/material'
import { blue, spacingItem, theme } from '../../theme'
import { EssentialOffer } from './EssentialOffer'
import { UsefulOffer } from './UsefulOffer'
import { useEffect, useState } from 'react'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { useMutation } from 'react-query'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import { AccountDtoPermission, CustomerDto } from '../../../interactors/gen/backendClient'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useAccountsStore } from '../../../store/AccountsStore'

export const FunctionalitiesTab = () => {
  const accountsStore = useAccountsStore()
  const membersCount = accountsStore.members.filter(
    (member) => member.permission !== AccountDtoPermission.Silent
  ).length
  const globalSnackarStore = useGlobalSnackbarStore()
  const queryParams = new URLSearchParams(window.location.search)
  const [customer, setCustomer] = useState<CustomerDto | null>(null)

  const isCustomerSubscribed = customer?.subscription?.status === 'active'

  const fetchCustomerMutation = useMutation(async () => await CustomersClient.getCurrentCustomer(), {
    onSuccess: (data) => {
      setCustomer(data || null)
    },
  })

  useEffect(() => {
    fetchCustomerMutation.mutate()
  }, [])

  useEffect(() => {
    if (queryParams.get('success')) {
      globalSnackarStore.triggerSuccessMessage('Félicitations ! Vous êtes maintenant abonné à Petso')
    } else if (queryParams.get('canceled')) {
      globalSnackarStore.triggerErrorMessage('Votre paiement a échoué. Veuillez rééssayer.')
    }
  }, [])
  return (
    <>
      <Box sx={{ padding: 2, backgroundColor: 'grey.100', minHeight: '100%' }}>
        <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 2 }}>
          <Typography variant="h4" color={blue} fontWeight={700} fontSize={26} sx={{ fontFamily: 'Insaniburger' }}>
            Abonnement
          </Typography>
          <Box sx={{ maxWidth: '1050px', marginX: 'auto' }}>
            <Card sx={spacingItem}>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography variant="h5" fontStyle="italic" color={'black'} fontSize={17}>
                    <span style={{ fontWeight: 700 }}>
                      Nous souhaitons rendre accessible un outil de suivi animal au plus grand nombre:
                    </span>
                    <br />
                    Vous bénéficiez gratuitement de l&#39;offre Essentiel + (excepté le tableau de bord) dans la limite
                    de 10 membres inscrits sur votre espace.
                    <br />
                    Choisissez sinon l&#39;offre qui vous convient le mieux: A partir de 12,5 euros/mois
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}>
                      {isCustomerSubscribed ? (
                        <Typography sx={{ color: 'green', fontWeight: 700 }}>{membersCount}</Typography>
                      ) : (
                        <Typography
                          sx={{ color: membersCount > 10 ? theme.palette.error.main : blue, fontWeight: 700 }}
                        >
                          {membersCount}/10
                        </Typography>
                      )}
                      <Typography sx={{ color: blue }}>utilisateurs</Typography>
                    </Box>
                    <Tooltip title="Les utilisateurs sont les membres ayant un accès à l’espace (admins, editeurs, lecteurs)">
                      <InfoOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 28 }} />
                    </Tooltip>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <Grid container spacing={2} sx={{ marginTop: 2, justifyContent: 'space-between' }}>
              <Grid item xs={12} sm={5.6}>
                <EssentialOffer customer={customer} />
              </Grid>
              <Grid item xs={12} sm={5.6}>
                <UsefulOffer customer={customer} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}
