import React from 'react'
import { AccountDto } from '../../../interactors/gen/backendClient'
import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material'
import { spacingItem } from '../../theme'
import { ControlledMultipleFreeSoloAutoComplete } from '../../common/ControlledMultipleFreeSoloAutoComplete'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { MemberCardSaveButton } from '../MemberCardSaveButton'

interface Props {
  account: AccountDto
  onSubmit: (account: Partial<AccountDto>) => void
  isOwnAccount: boolean
}

type FormParams = {
  specialty: string[]
  details: string
}

const options = ['Chirurgie', 'Dermatologie', 'Dentisterie', 'Ophtalmologie', 'Référé']

export const SpecialitiesCard: React.FC<Props> = ({ account, onSubmit, isOwnAccount }) => {
  const processSubmit = (data: FormParams) => {
    const dataToSend: Partial<AccountDto> = {
      ...account,
      veterinary: {
        type: account.veterinary?.type ?? 'independent',
        specialty: data.specialty ?? account.veterinary?.specialty,
        details: data.details ?? account.veterinary?.details,
      },
    }
    onSubmit(dataToSend)
  }

  const getDefaultValues = (account: AccountDto) => {
    return {
      specialty: account.veterinary?.specialty || [],
      details: account.veterinary?.details || '',
    }
  }

  const { control, handleSubmit, register } = useFormExtended(account, getDefaultValues)

  return (
    <Card>
      <form onSubmit={handleSubmit(processSubmit)}>
        <CardHeader title="Spécialités vétérinaires" />
        <CardContent>
          <ControlledMultipleFreeSoloAutoComplete
            freeSolo={true}
            control={control}
            error={undefined}
            fieldName="specialty"
            label="Spécialité(s)"
            placeholder="Sélectionnez ou écrivez une spécialité et validez en appuyant sur entrée"
            options={options}
            requiredRule=""
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', ...spacingItem }}>
            <MemberCardSaveButton isOwnAccount={isOwnAccount} />
          </Box>
        </CardContent>
        {account.veterinary?.type === 'clinic' && (
          <>
            <CardHeader title="Composition de la clinique" />
            <CardContent>
              <TextField
                fullWidth
                label="Vétérinaire (coordonnées, effectif…)"
                multiline
                rows={4}
                variant="outlined"
                {...register('details')}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', ...spacingItem }}>
                <MemberCardSaveButton isOwnAccount={isOwnAccount} />
              </Box>
            </CardContent>
          </>
        )}
      </form>
    </Card>
  )
}
