import React, { useState } from 'react'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, Box, DialogActions, Typography, TextField } from '@mui/material'
import { AccountDtoPermission, AdopterDto } from '../../interactors/gen/backendClient'
import { blue } from '../theme'
import { useAccountsStore } from '../../store/AccountsStore'

interface Props {
  onSubmit: (data: Partial<AdopterDto>) => Promise<void>
}

export const OptOutAdoptionFormModal: React.FC<Props> = ({ onSubmit }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const [customerComments, setCustomerComments] = useState<string>('')

  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read

  const onSubmitPastAdoption = async () => {
    await onSubmit({
      adoptionForm: {
        customerComments: customerComments,
      },
      profileHasBeenAccepted: 'form-accepted',
    })
    closeDialog()
  }

  return (
    <>
      <Button variant="outlined" color="error" onClick={() => openDialog()} disabled={isReader}>
        Passer à l&#39;étape suivante sans utiliser le formulaire
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <DialogContent>
          <Box>
            <Typography variant="body2" sx={{ color: blue, fontSize: 20, fontWeight: 700 }}>
              Vous pouvez ajouter un commentaire avec toutes les informations que vous avez sur l&#39;adoptant
            </Typography>
            <TextField
              multiline
              rows={4}
              fullWidth
              value={customerComments}
              onChange={(e) => setCustomerComments(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => closeDialog()}>
            Annuler
          </Button>

          <LoadingButton data-testid="apply-changes" variant="contained" onClick={() => onSubmitPastAdoption()}>
            {'Valider sans formulaire'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
