import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Box, Tooltip } from '@mui/material'
import { FC } from 'react'
import { useMutation } from 'react-query'
import { backendClient } from '../../../../interactors/clients/client'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { Delete } from '@mui/icons-material'
import { AnimalDocumentDto } from '../../../../interactors/gen/backendClient'

interface Props {
  documentId: string
  animalId: string
  setDocumentsList?: React.Dispatch<React.SetStateAction<AnimalDocumentDto[]>>
  disabled?: boolean
}

export const DeleteDocumentModal: FC<Props> = ({ documentId, animalId, setDocumentsList, disabled }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()

  // Mutation to delete the document
  const mutation = useMutation(async () => await backendClient.delete(`animals/${animalId}/documents/${documentId}`), {
    onSuccess: () => {
      globalSnackbar.triggerSuccessMessage(`Le document a bien été supprimé !`)
      setDocumentsList?.((prev) => prev.filter((document) => document.id !== documentId))
    },
  })

  return (
    <>
      <Tooltip
        title={disabled ? 'Vous ne pouvez pas supprimer les bons vétérinaire.' : 'Supprimer le document'}
        placement="top"
      >
        <span>
          <Button sx={{ color: 'error.main' }} disabled={disabled} onClick={() => openDialog()}>
            <Delete />
          </Button>
        </span>
      </Tooltip>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>
          <Box marginBottom={1}>Voulez vous vraiment supprimer ce document ?</Box>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel" onClick={closeDialog}>
            Annuler
          </Button>
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            onClick={() => mutation.mutate()}
            sx={{ backgroundColor: 'error.main' }}
            loading={mutation.isLoading}
          >
            Supprimer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
