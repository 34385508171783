import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import {
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoStatus,
  AnimalSearchItemDto,
} from '../../interactors/gen/backendClient'
import { readableAxiosError } from '../../utils/axios'
import { AxiosError } from 'axios'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { AnimalsTable, SortColumn, SortDirection } from '../Animals/AnimalsTable'
import { spacingItem } from '../theme'

interface MemberOwnAnimalsCardProps {
  accountId: string
  capacity: number | undefined
}

export const MemberOwnAnimalsCard: React.FC<MemberOwnAnimalsCardProps> = ({ accountId, capacity }) => {
  const [animals, setAnimals] = useState<AnimalSearchItemDto[]>([])
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('adoptionStatus')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')
  const [howManyAnimals, setHowManyAnimals] = useState<number>(0)
  const globalSnackBarStore = useGlobalSnackbarStore()

  //Mutation qui récupère les animaux d'un compte
  const { mutate: getAllAnimals } = useMutation(
    async (accountId: string) => {
      const response = await AnimalsClient.getAllAnimalForAccountId(accountId)
      return response
    },
    {
      onSuccess: (data) => {
        setAnimals(data)
        setHowManyAnimals(
          data.filter(
            (animal) =>
              animal.responsibilities?.hostFamilyInChargeAccountId === accountId &&
              animal.adoptionStatus !== AnimalAdoptionDtoStatus.HasBeenAdopted &&
              !(
                animal.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted &&
                (animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Dead ||
                  animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Released ||
                  animal.cannotBeAdoptedStatus ===
                    AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation ||
                  animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Lost)
              )
          ).length
        )
      },
      onError: (error: Error | AxiosError) => {
        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  useEffect(() => {
    getAllAnimals(accountId)
  }, [accountId])

  return (
    <Card sx={spacingItem}>
      <CardHeader
        title="Mes animaux"
        action={
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography sx={{ fontWeight: 700 }}>
              {capacity
                ? `Jauge de capacité: ${howManyAnimals}/${capacity}`
                : "Compléter la capacité d'accueil pour consulter la jauge"}
            </Typography>
          </Box>
        }
        sx={{
          '&& .MuiCardHeader-action': {
            display: 'flex',
            alignItems: 'center',
            maxHeight: '100%',
            height: '30px',
            pr: 1,
          },
        }}
      />
      <CardContent sx={{ overflowX: 'scroll' }}>
        <AnimalsTable
          animals={animals}
          setSortColumn={setSortColumn}
          setSortDirection={setSortDirection}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          myAnimalsSection={true}
        />
      </CardContent>
    </Card>
  )
}
