import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, Box, Typography, Grid, DialogActions, Button } from '@mui/material'
import React from 'react'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../../common/ControlledDateField'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { spacingItem } from '../../theme'
import { useMutation } from 'react-query'
import { DonationTrackingsClient } from '../../../interactors/clients/DonationTrackingClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { SubmitHandler, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { DonationTrackingDto } from '../../../interactors/gen/backendClient'
import { useDonatersStore } from '../../../store/DonatersStore'
import { getDonatorFullName } from '../../../domain/Donations/DonatorDisplay'

type FormValues = {
  type: string
  startDate: string
  endDate: string
}

interface Props {
  isDialogOpen: boolean
  closeDialog: () => void
  donation: DonationTrackingDto
}

export const ExportPDFFiscalReportModal: React.FC<Props> = ({ isDialogOpen, closeDialog, donation }) => {
  const donatersStore = useDonatersStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  const selectedDonator = donatersStore.donaters.find((donator) => donator.id === donation.donaterId)

  const exportSinglePdfMutation = useMutation(
    (variables: { donatorId: string; donationId: string }) =>
      DonationTrackingsClient.downloadFiscalReportPdf(variables.donatorId, variables.donationId),
    {
      onSuccess: async () => {
        globalSnackbarStore.triggerSuccessMessage(`PDF téléchargé !`)
      },
      onError: async (error) => {
        console.error(error)
        globalSnackbarStore.triggerErrorMessage(`Une erreur est survenue !`)
      },
    }
  )

  const exportMultiplePdfMutation = useMutation(
    (variables: { donatorId: string; startDate: string; endDate: string }) =>
      DonationTrackingsClient.downloadMultipleFiscalReportPdf(
        variables.donatorId,
        variables.startDate,
        variables.endDate
      ),
    {
      onSuccess: async () => {
        globalSnackbarStore.triggerSuccessMessage(`PDF téléchargé !`)
      },
      onError: async (error) => {
        console.error(error)
        globalSnackbarStore.triggerErrorMessage(`Une erreur est survenue !`)
      },
    }
  )

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (data.type === 'multiple-pdf') {
      const startDate = data.startDate
      const endDate = data.endDate
      return exportMultiplePdfMutation.mutate({ donatorId: donation.donaterId, startDate, endDate })
    }
    if (data.type === 'year-pdf') {
      const startDate = dayjs().startOf('year').toISOString()
      const endDate = dayjs().endOf('year').toISOString()
      return exportMultiplePdfMutation.mutate({ donatorId: donation.donaterId, startDate, endDate })
    }
    return exportSinglePdfMutation.mutate({ donatorId: donation.donaterId, donationId: donation.id })
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      type: 'single-pdf',
      startDate: '',
      endDate: dayjs().toISOString(),
    },
    mode: 'onChange',
  })

  const type = watch('type')

  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      PaperProps={{
        style: { borderRadius: 15, padding: 15 },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box>
            <Typography variant="h4" component="h2" fontSize={22} marginBottom={2}>
              Exporter un reçu fiscal pour {selectedDonator ? getDonatorFullName(selectedDonator) : '..'}
            </Typography>

            <ControlledSelectField
              control={control}
              error={errors.type}
              fieldName="type"
              label="Pour un ou plusieurs dons"
              options={[
                { label: `Reçu fiscal du don sélectionné (${donation.amount}€)`, value: 'single-pdf' },
                { label: 'Reçu fiscal de plusieurs dons', value: 'multiple-pdf' },
                { label: "Reçu fiscal de tous les dons de l'année en cours", value: 'year-pdf' },
              ]}
              size="small"
              requiredRule={"Veuillez choisir un type d'export"}
            />

            {type === 'multiple-pdf' && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, ...spacingItem }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <ControlledDateField
                        control={control}
                        error={errors.startDate}
                        validate={(value) => isValidPastDateCoherence(value) || !value}
                        fieldName="startDate"
                        label="Du"
                        size="small"
                        requiredRule={'La date de début est requise'}
                      />{' '}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <ControlledDateField
                        control={control}
                        error={errors.endDate}
                        validate={(value) => isValidPastDateCoherence(value) || !value}
                        fieldName="endDate"
                        label="Au"
                        size="small"
                        requiredRule={'La date de fin est requise'}
                      />{' '}
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel" onClick={closeDialog}>
            Annuler
          </Button>
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            type="submit"
            loading={exportSinglePdfMutation.isLoading}
          >
            Exporter
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
