import React, { PropsWithChildren, useRef, useState } from 'react'
import {
  Autocomplete,
  BaseTextFieldProps,
  Chip,
  SxProps,
  TextField,
  Tabs,
  Tab,
  Paper,
  ClickAwayListener,
} from '@mui/material'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { AddCircleOutline, Cancel as CancelIcon } from '@mui/icons-material'
import { isColorDark } from '../../utils/isColorDark'
import { blueSky } from '../theme'

interface Option {
  label: string
  value: string | number
  color?: string
}

interface Props<T extends FieldValues> {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  primaryOptions: Option[]
  secondaryOptions: Option[]
  primaryTabLabel: string
  secondaryTabLabel: string
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  defaultValue: string
  chip?: boolean
  sx?: SxProps
  highlight?: boolean
  removeCustomValue?: boolean
  noOptionsTexts?: string[]
}

export function ControlledAutoCompleteWithTabs<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  primaryOptions,
  secondaryOptions,
  primaryTabLabel,
  secondaryTabLabel,
  size,
  disabled,
  requiredRule,
  defaultValue,
  chip = false,
  sx,
  highlight,
  removeCustomValue = false,
  noOptionsTexts = ['No options available', 'No options available'],
}: PropsWithChildren<Props<T>>) {
  const initialTabValue = secondaryOptions.some((option) => option.value === defaultValue) ? 1 : 0
  const [tabValue, setTabValue] = useState(initialTabValue)
  const [menuOpen, setMenuOpen] = useState(false)
  const autocompleteRef = useRef<HTMLDivElement>(null)
  const tabRef = useRef<HTMLDivElement>(null)

  const activeOptions = tabValue === 0 ? primaryOptions : secondaryOptions

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleTabClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation() // stop event propagation when tab is clicked
    setMenuOpen(true) // ensure menu stays open
  }

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation()
    setMenuOpen(false)
  }

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue as any}
      render={({ field: { onChange, value } }) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div ref={autocompleteRef}>
            <Autocomplete
              open={menuOpen}
              onOpen={() => setMenuOpen(true)}
              sx={{ ...sx, width: '100%' }}
              clearIcon={null}
              onChange={(_, data) => {
                onChange(data ? data.value : null)
                setMenuOpen(false) // Close the menu when a value is selected
              }}
              id={fieldName}
              options={activeOptions}
              getOptionLabel={(option) => (chip ? '' : option.label)}
              value={activeOptions.find((option) => option.value === value) || null}
              noOptionsText={noOptionsTexts[tabValue]}
              size={size}
              disabled={disabled}
              PaperComponent={({ children }) => (
                <Paper>
                  <div ref={tabRef}>
                    <Tabs value={tabValue} onChange={handleChange} onClick={handleTabClick}>
                      <Tab label={primaryTabLabel} />
                      <Tab label={secondaryTabLabel} />
                    </Tabs>
                  </div>
                  {children}
                </Paper>
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.value}
                  style={{
                    backgroundColor:
                      highlight && (option.value as string).startsWith('add') && !removeCustomValue
                        ? 'lightyellow'
                        : 'inherit',
                  }}
                >
                  {highlight && (option.value as string).startsWith('add') && !removeCustomValue ? (
                    <AddCircleOutline />
                  ) : (
                    ''
                  )}
                  {' '}
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={label}
                  placeholder={'Écrivez pour rechercher...'}
                  required={requiredRule ? value === null || value === undefined : false}
                  error={!!error}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment:
                      chip && value ? (
                        <Chip
                          key={value}
                          label={activeOptions.find((option) => option.value === value)?.label || ''}
                          onDelete={() => onChange(null)}
                          deleteIcon={<CancelIcon />}
                          sx={{
                            backgroundColor: activeOptions.find((option) => option.value === value)?.color || blueSky,
                            color: isColorDark(activeOptions.find((option) => option.value === value)?.color || blueSky)
                              ? 'white'
                              : 'black',
                            marginY: '-4.5px !important',
                            marginTop: '-1.5px',
                            height: '28px',
                            '& .MuiChip-label': {
                              lineHeight: '24px',
                            },
                          }}
                        />
                      ) : undefined,
                  }}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      )}
    />
  )
}
