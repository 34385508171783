import { cloneDeep } from 'lodash'

export const omitEmptyString = <T extends Record<string, unknown>>(value: T): T => {
  const deepClone = cloneDeep(value)

  Object.keys(deepClone).forEach((key) => {
    if (deepClone[key] === '') {
      delete deepClone[key]
    }
  })

  return deepClone
}
