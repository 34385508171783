import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { FormControl, FormHelperText, ToggleButtonGroup, ToggleButton, SxProps, Theme } from '@mui/material'

type Props<T extends FieldValues> = {
  sx?: SxProps<Theme>
  control: Control<T>
  error: FieldError | undefined
  fieldName: Path<T>
  firstOptionLabel: string
  secondOptionLabel: string
  thirdOptionLabel?: string
  firstOptionValue: string | boolean | number
  secondOptionValue: string | boolean | number
  thirdOptionValue?: string | boolean | number
  requiredRule: string | undefined
  disabled?: boolean
  fullWidth?: boolean
}

export function ControlledToggleButton<T extends FieldValues>({
  sx,
  control,
  error,
  fieldName,
  firstOptionLabel,
  secondOptionLabel,
  thirdOptionLabel,
  firstOptionValue,
  secondOptionValue,
  thirdOptionValue,
  requiredRule,
  disabled,
  fullWidth = false,
}: Props<T>) {
  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{ width: '100%', ...sx }}
      required={Boolean(requiredRule)}
      component="fieldset"
    >
      <Controller
        name={fieldName}
        control={control}
        defaultValue={undefined}
        rules={{
          validate: (value) => {
            if (requiredRule) {
              return value !== undefined || requiredRule // Custom validation logic
            }
            return true // If not required, always return true
          },
        }}
        render={({ field: { onChange, value } }) => (
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={(_, newValue) => onChange(newValue)}
            size="medium"
            disabled={disabled}
            fullWidth={fullWidth}
            sx={{ '& .MuiToggleButton-root': { height: '48px' } }} // Set height of buttons
          >
            <ToggleButton value={firstOptionValue} key="firstOption">
              {firstOptionLabel}
            </ToggleButton>
            <ToggleButton value={secondOptionValue} key="secondOption">
              {secondOptionLabel}
            </ToggleButton>
            {thirdOptionLabel && thirdOptionValue && (
              <ToggleButton value={thirdOptionValue} key="thirdOption">
                {thirdOptionLabel}
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        )}
      />
      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  )
}
