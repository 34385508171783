import { Box } from '@mui/material'
import React from 'react'
import { AdopterDto, AdoptionAttemptDtoStatus } from '../../../interactors/gen/backendClient'
import { OnGoingAdoptionItem } from '../AdopterOnGoingAdoptions/OnGoingAdoptionItem'
import { OnGoingAdoptionScreen } from '../AdopterOnGoingAdoptions/OnGoingAdoptionScreen'

interface Props {
  adopter: AdopterDto
}

export const AdopterSuspendedAdoptionTab: React.FC<Props> = ({ adopter }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const attempts = adopter.adoptionAttempts?.filter(
    (adoptionAttempt) =>
      adoptionAttempt.status === AdoptionAttemptDtoStatus.SuspendedByCustomer ||
      adoptionAttempt.status === AdoptionAttemptDtoStatus.SuspendedByUser
  )
  return queryParams.get('attemptId') != null ? (
    <OnGoingAdoptionScreen adopter={adopter} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        p: 4,
        px: 6,
      }}
    >
      {attempts && attempts.length > 0 ? (
        attempts.map((adoptionAttempt) => (
          <OnGoingAdoptionItem key={adoptionAttempt.id} adoptionAttempt={adoptionAttempt} />
        ))
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <span>Aucune adoption suspendue</span>
        </Box>
      )}
    </Box>
  )
}
