import { AnimalHealthDtoSterilizedOrNeutered } from '../interactors/gen/backendClient'
import { colors } from '../views/theme'

export const getReadableBooleanOrUnknown = (field: AnimalHealthDtoSterilizedOrNeutered): string => {
  const map = {
    [AnimalHealthDtoSterilizedOrNeutered.True]: 'Oui',
    [AnimalHealthDtoSterilizedOrNeutered.False]: 'Non',
    [AnimalHealthDtoSterilizedOrNeutered.Unknown]: 'Ne sais pas',
  }

  return map[field]
}

export const getColorForBooleanOrUnknown = (field: AnimalHealthDtoSterilizedOrNeutered): string => {
  const map = {
    [AnimalHealthDtoSterilizedOrNeutered.True]: colors.seaGreen,
    [AnimalHealthDtoSterilizedOrNeutered.False]: colors.punch,
    [AnimalHealthDtoSterilizedOrNeutered.Unknown]: colors.amber,
  }

  return map[field]
}
