import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Pagination,
  TextField,
} from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { BooleanOrAll } from '../../../domain/enums/Boolean.enum'
import { useFetchOrRefreshAnimalsOnMount } from '../../../store/useFetchOrRefreshAnimalsOnMount'
import { AnimalsTable, SortColumn, SortDirection } from '../../Animals/AnimalsTable'
import { CircularProgressPanel } from '../../common/CircularProgressPanel'
import { useAnimalStore } from '../../../store/AnimalStore'
import { Close, DoNotDisturb, Search } from '@mui/icons-material'

interface Props {
  submitStep: (animalId?: string) => void
  close: () => void
  adopterName: string
}

export const MatchAnimalStep: React.FC<Props> = ({ submitStep, close, adopterName }) => {
  const [selectedAnimalId, setSelectedAnimalId] = React.useState<string | null>(null)
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('adoptionStatus')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')
  const [page, setPage] = React.useState(1)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState('')
  const maxAnimalPerPage = 50

  const animalStore = useAnimalStore()

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchQuery(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query !== debouncedSearchQuery) {
        setDebouncedSearchQuery(query)
        setPage(1) // Reset page as well when searchQuery changes
      }
    }, 500),
    [debouncedSearchQuery]
  )

  useEffect(() => {
    debouncedSearch(searchQuery)

    // Clean up function
    return () => {
      debouncedSearch.cancel()
    }
  }, [searchQuery, debouncedSearch])

  useFetchOrRefreshAnimalsOnMount(
    page,
    maxAnimalPerPage,
    {
      myAnimalsOnly: false,
      hasIdentificationNumberFilter: BooleanOrAll.All,
      sterilizedOrNeuteredFilter: [],
      adoptionStatusFilter: [],
      stepFilter: [],
      cannotBeAdoptedStatusFilter: [],
      hasExitDateFilter: null,
      speciesFilter: [],
      sexFilter: null,
    },
    debouncedSearchQuery,
    sortColumn,
    sortDirection
  )

  const onSubmit = () => {
    console.log(selectedAnimalId)

    submitStep(selectedAnimalId || undefined)
  }
  return (
    <>
      <DialogTitle sx={{ fontSize: 24, fontWeight: 700 }}>Qui {adopterName} souhaite adopter ?</DialogTitle>
      <DialogContent>
        <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={close}>
          <Close />
        </IconButton>
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
          <TextField
            id="name-input"
            placeholder="Rechercher un animal (nom, nº d'immatriculation, membres...)"
            type="text"
            fullWidth
            value={searchQuery}
            onChange={onChangeSearch}
            sx={{
              borderRadius: 0,
              [`& fieldset`]: {
                borderRadius: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {animalStore.isLoading ? (
          <CircularProgressPanel />
        ) : (
          <AnimalsTable
            animals={animalStore.animals}
            setSortColumn={setSortColumn}
            setSortDirection={setSortDirection}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            disableFrontSorting={true}
            assignAnimalId={setSelectedAnimalId}
            selectedAnimalId={selectedAnimalId || ''}
          />
        )}
        <Pagination
          count={Math.ceil(animalStore.animalsCount / maxAnimalPerPage)}
          page={page}
          onChange={(_, page) => {
            setPage(page)
          }}
          sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, paddingBottom: 10 }}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="outlined" onClick={() => submitStep()} startIcon={<DoNotDisturb />}>
          Aucun souhait précis exprimé pour le moment !
        </Button>
        <Button variant="contained" onClick={onSubmit}>{`Suivant`}</Button>
      </DialogActions>
    </>
  )
}
