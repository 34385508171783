import { Edit } from '@mui/icons-material'
import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Tooltip,
  Grid,
  Typography,
} from '@mui/material'
import React from 'react'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import {
  AccountDto,
  AccountDtoPreferencesNeedsBottleFeeding,
  AccountDtoPreferencesSeniority,
  AccountDtoPreferencesSex,
  AccountDtoPreferencesSterilizedOrNeutered,
  AccountDtoPreferencesVaccinated,
  AccountDtoPreferencesWillingToSocializeWildAnimals,
  AnimalDtoSpecies,
} from '../../interactors/gen/backendClient'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { useAccountsStore } from '../../store/AccountsStore'
import { MemberPromptSaveButton } from './MemberPromptSaveButton'
import { ControlledAutoCompleteWithCustomValue } from '../common/ControlledAutocompleteWithCustomValue'
import { getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import {
  getReadableAccountPreferencesNeedsBottleFeeding,
  getReadableAccountPreferencesSeniority,
  getReadableAccountPreferencesSex,
  getReadableAccountPreferencesSterilizedOrNeutered,
  getReadableAccountPreferencesVaccinated,
  getReadableAccountPreferencesWillingToSocializeWildAnimals,
} from '../../domain/Account/AccountDisplay'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { ControlledMultipleAutoComplete } from '../common/ControlledMultipleAutoComplete'

interface Props {
  account: AccountDto | null
  onSubmit: (data: Partial<AccountDto>) => Promise<void>
  isOwnAccount: boolean
  isLoading: boolean
}

export interface PreferencesFormParams {
  specie?: AnimalDtoSpecies
  seniority?: AccountDtoPreferencesSeniority[]
  sex?: AccountDtoPreferencesSex
  sterilizedOrNeutered?: AccountDtoPreferencesSterilizedOrNeutered
  vaccinated?: AccountDtoPreferencesVaccinated
  withMedicalAntecedent?: AccountDtoPreferencesWillingToSocializeWildAnimals
  willingToSocializeWildAnimals?: AccountDtoPreferencesWillingToSocializeWildAnimals
  needsBottleFeeding?: AccountDtoPreferencesNeedsBottleFeeding
}

export const EditMemberAdoptionPreferencesPrompt: React.FC<Props> = ({
  account,
  onSubmit,
  isOwnAccount,
  isLoading,
}) => {
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()

  const submitProcess = (data: PreferencesFormParams) => {
    const newAccount = { ...account, preferences: { ...account?.preferences, primaryPreferences: data } }
    return onSubmit(newAccount)
  }

  const getDefaultValues = (account: AccountDto | null): PreferencesFormParams => ({
    specie: account?.preferences?.primaryPreferences?.specie || undefined,
    seniority: account?.preferences?.primaryPreferences?.seniority || undefined,
    sex: account?.preferences?.primaryPreferences?.sex || undefined,
    sterilizedOrNeutered: account?.preferences?.primaryPreferences?.sterilizedOrNeutered || undefined,
    vaccinated: account?.preferences?.primaryPreferences?.vaccinated || undefined,
    withMedicalAntecedent: account?.preferences?.primaryPreferences?.withMedicalAntecedent || undefined,
    willingToSocializeWildAnimals: account?.preferences?.primaryPreferences?.willingToSocializeWildAnimals || undefined,
    needsBottleFeeding: account?.preferences?.primaryPreferences?.needsBottleFeeding || undefined,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(account, getDefaultValues)

  const species = accountsStore.connectedCustomer?.preferences.species

  return (
    <>
      <IconButton
        color="primary"
        onClick={dialogOpenedState.openDialog}
        disabled={!accountsStore.isAdmin() && !isOwnAccount}
      >
        <Tooltip title="Editer les préférences de prise en charge">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Editer les préférences de prise en charge</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Typography variant="h4" sx={{ fontSize: 18, mb: 2 }}>
                Préférence principale
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    defaultValue=""
                    fieldName="specie"
                    label="Espèce"
                    chip
                    error={errors.specie}
                    options={
                      species
                        ? Object.values(species).map((status: AnimalDtoSpecies) => ({
                            label: getReadableSpecies(status, { withEmoji: false }),
                            value: status,
                          }))
                        : []
                    }
                    requiredRule={"L'espèce est requise"}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledMultipleAutoComplete
                    control={control}
                    fieldName="seniority"
                    label="Âge"
                    error={errors.specie}
                    options={Object.values(AccountDtoPreferencesSeniority).map(
                      (status: AccountDtoPreferencesSeniority) => ({
                        label: getReadableAccountPreferencesSeniority(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sex"
                    label="Sexe"
                    error={errors.sex}
                    options={Object.values(AccountDtoPreferencesSex).map((status: AccountDtoPreferencesSex) => ({
                      label: getReadableAccountPreferencesSex(status),
                      value: status,
                    }))}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sterilizedOrNeutered"
                    label="Stérilisé/Castré"
                    error={errors.sterilizedOrNeutered}
                    options={Object.values(AccountDtoPreferencesSterilizedOrNeutered).map(
                      (status: AccountDtoPreferencesSterilizedOrNeutered) => ({
                        label: getReadableAccountPreferencesSterilizedOrNeutered(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    control={control}
                    fieldName="vaccinated"
                    label="Vaccination"
                    error={errors.vaccinated}
                    options={Object.values(AccountDtoPreferencesVaccinated).map(
                      (status: AccountDtoPreferencesVaccinated) => ({
                        label: getReadableAccountPreferencesVaccinated(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="withMedicalAntecedent"
                    label="Possibilité de prendre en charge des animaux malades"
                    error={errors.withMedicalAntecedent}
                    options={Object.values(AccountDtoPreferencesWillingToSocializeWildAnimals).map(
                      (status: AccountDtoPreferencesWillingToSocializeWildAnimals) => ({
                        label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="willingToSocializeWildAnimals"
                    label="Possibilité d'accueil d'animaux sauvages, avec besoin de sociabilisation"
                    error={errors.willingToSocializeWildAnimals}
                    options={Object.values(AccountDtoPreferencesWillingToSocializeWildAnimals).map(
                      (status: AccountDtoPreferencesWillingToSocializeWildAnimals) => ({
                        label: getReadableAccountPreferencesWillingToSocializeWildAnimals(status),
                        value: status,
                      })
                    )}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ControlledSelectField
                    control={control}
                    fieldName="needsBottleFeeding"
                    label="Besoin de biberonnage"
                    error={errors.needsBottleFeeding}
                    options={Object.values(AccountDtoPreferencesNeedsBottleFeeding)
                      .filter((s) => s !== AccountDtoPreferencesNeedsBottleFeeding.NoPreference)
                      .map((status: AccountDtoPreferencesNeedsBottleFeeding) => ({
                        label: getReadableAccountPreferencesNeedsBottleFeeding(status),
                        value: status,
                      }))}
                    requiredRule={undefined}
                    size="small"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <MemberPromptSaveButton
                isOwnAccount={isOwnAccount}
                isLoading={isLoading}
                dialogOpenedState={dialogOpenedState}
              />
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
