import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { IconButton, Dialog, DialogTitle, Box, DialogContent, Typography, DialogActions, Tooltip } from '@mui/material'
import { omit } from 'lodash'
import { FC, useState } from 'react'
import { AnimalDto, AnimalHealthOperationDtoType } from '../../../../interactors/gen/backendClient'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { readableAxiosError } from '../../../../utils/axios'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { omitEmptyString } from '../../../../utils/omitEmptyString'
import { ControlledSelectField } from '../../../common/ControlledSelectField'
import {
  getMostRecentDewormingOperation,
  getMostRecentFleaControlOperation,
  getMostRecentVaccinationOperation,
  getSecondMostRecentDewormingOperation,
  getSecondMostRecentFleaControlOperation,
  getSecondMostRecentVaccinationOperation,
} from './AnimalHealthOperationsCard'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'

// We need to omit, if not it does not work
type FormParams = {
  annualReminderFrequencyInYear?: number
  dewormingFrequencyInMonths?: number
  fleaControlFrequencyInMonths?: number
}

interface Props {
  animal: AnimalDto
}

export const EditAnimalHealthFrequenciesModal: FC<Props> = ({ animal }) => {
  const dialogOpenedState = useDialogOpened()

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    annualReminderFrequencyInYear: animal.vaccination.annualReminderFrequencyInYear || 1,
    dewormingFrequencyInMonths: animal.health.dewormingFrequencyInMonths || 1,
    fleaControlFrequencyInMonths: animal.health.fleaControlFrequencyInMonths || 1,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(animal, getDefaultValues)

  const [serverError, setServerError] = useState('')

  const editAnimalMutation = useEditAnimalMutation({
    onSuccess: dialogOpenedState.closeDialog,
    onError: (error) => {
      setServerError(readableAxiosError(error).join(' '))
    },
  })

  const updateNextDewormingOperation = (dewormingFrequencyInMonths: number): void => {
    const mostRecentDewormingOperation = getMostRecentDewormingOperation(animal.health.operations)
    const secondMostRecentDewormingOperation = getSecondMostRecentDewormingOperation(animal.health.operations)

    if (!mostRecentDewormingOperation) return
    if (dewormingFrequencyInMonths === -1) {
      if (dayjs(mostRecentDewormingOperation.date, 'DD/MM/YYYY').isAfter(dayjs(), 'days')) {
        animal.health.operations = animal.health.operations.filter(
          (operation) => operation.id !== mostRecentDewormingOperation.id
        )
      }
      return
    }

    const delayToAdd = dewormingFrequencyInMonths === 0.5 ? 15 : dewormingFrequencyInMonths || 1
    const unityToAdd = dewormingFrequencyInMonths === 0.5 ? 'days' : 'months'

    if (dayjs(mostRecentDewormingOperation.date, 'DD/MM/YYYY').isBefore(dayjs())) {
      if (!mostRecentDewormingOperation.needsValidation || mostRecentDewormingOperation.validated) {
        const formattedDate = dayjs(mostRecentDewormingOperation.date, 'DD/MM/YYYY')
          .add(delayToAdd, unityToAdd)
          .format('DD/MM/YYYY')
        animal.health.operations.push({
          id: nanoid(),
          date: formattedDate,
          type: [AnimalHealthOperationDtoType.Deworming],
          needsValidation: true,
          additionalInfo:
            'Rendez-vous créé automatiquement à la prochaine date de rappel de vermifuge conseillée. Vous pouvez modifier cette date si besoin.',
        })
      }
    } else if (secondMostRecentDewormingOperation) {
      animal.health.operations.find((operation) => operation.id === mostRecentDewormingOperation.id)!.date = dayjs(
        secondMostRecentDewormingOperation.date,
        'DD/MM/YYYY'
      )
        .add(delayToAdd, unityToAdd)
        .format('DD/MM/YYYY')
    }
  }

  const updateNextFleaControlOperation = (fleaControlFrequencyInMonths: number): void => {
    const mostRecentFleaControlOperation = getMostRecentFleaControlOperation(animal.health.operations)
    const secondMostRecentDewormingOperation = getSecondMostRecentFleaControlOperation(animal.health.operations)

    if (!mostRecentFleaControlOperation) return
    if (fleaControlFrequencyInMonths === -1) {
      if (dayjs(mostRecentFleaControlOperation.date, 'DD/MM/YYYY').isAfter(dayjs(), 'days')) {
        animal.health.operations = animal.health.operations.filter(
          (operation) => operation.id !== mostRecentFleaControlOperation.id
        )
      }
      return
    }

    const delayToAdd = fleaControlFrequencyInMonths === 0.5 ? 15 : fleaControlFrequencyInMonths || 1
    const unityToAdd = fleaControlFrequencyInMonths === 0.5 ? 'days' : 'months'

    if (dayjs(mostRecentFleaControlOperation.date, 'DD/MM/YYYY').isBefore(dayjs())) {
      if (!mostRecentFleaControlOperation.needsValidation || mostRecentFleaControlOperation.validated) {
        const formattedDate = dayjs(mostRecentFleaControlOperation.date, 'DD/MM/YYYY')
          .add(delayToAdd, unityToAdd)
          .format('DD/MM/YYYY')
        animal.health.operations.push({
          id: nanoid(),
          date: formattedDate,
          type: [AnimalHealthOperationDtoType.FleaControl],
          needsValidation: true,
          additionalInfo:
            "Rendez-vous créé automatiquement à la prochaine date de rappel d'antipuce conseillée. Vous pouvez modifier cette date si besoin.",
        })
      }
    } else if (secondMostRecentDewormingOperation) {
      animal.health.operations.find((operation) => operation.id === mostRecentFleaControlOperation.id)!.date = dayjs(
        secondMostRecentDewormingOperation.date,
        'DD/MM/YYYY'
      )
        .add(delayToAdd, unityToAdd)
        .format('DD/MM/YYYY')
    }
  }

  const updateNextVaccinationOperation = (annualReminderFrequencyInYear: number): void => {
    const mostRecentVaccinationOperation = getMostRecentVaccinationOperation(animal.health.operations)
    const secondMostRecentVaccinationOperation = getSecondMostRecentVaccinationOperation(animal.health.operations)

    if (annualReminderFrequencyInYear === -1) {
      if (mostRecentVaccinationOperation) {
        if (dayjs(mostRecentVaccinationOperation.date, 'DD/MM/YYYY').isAfter(dayjs(), 'days')) {
          animal.health.operations = animal.health.operations.filter(
            (operation) => operation.id !== mostRecentVaccinationOperation.id
          )
        }
      }
      return
    }

    if (animal.vaccination.vaccinationStatus === 'completed' && mostRecentVaccinationOperation) {
      if (dayjs(mostRecentVaccinationOperation.date, 'DD/MM/YYYY').isBefore(dayjs())) {
        if (!mostRecentVaccinationOperation.needsValidation || mostRecentVaccinationOperation.validated) {
          const formattedDate = dayjs(mostRecentVaccinationOperation.date, 'DD/MM/YYYY')
            .add(annualReminderFrequencyInYear || 1, 'year')
            .format('DD/MM/YYYY')
          animal.health.operations.push({
            id: nanoid(),
            date: formattedDate,
            type: [AnimalHealthOperationDtoType.AnnualReminder],
            needsValidation: true,
            additionalInfo:
              'Rendez-vous créé automatiquement à la prochaine date de rappel annuel conseillée. Vous pouvez modifier cette date si besoin.',
          })
        }
      } else if (secondMostRecentVaccinationOperation) {
        animal.health.operations.find((operation) => operation.id === mostRecentVaccinationOperation.id)!.date = dayjs(
          secondMostRecentVaccinationOperation.date,
          'DD/MM/YYYY'
        )
          .add(annualReminderFrequencyInYear || 1, 'year')
          .format('DD/MM/YYYY')
      }
    }
  }

  const onSubmit = async (data: FormParams) => {
    console.log('On submit', data)

    if (
      data.dewormingFrequencyInMonths &&
      data.dewormingFrequencyInMonths !== animal.health.dewormingFrequencyInMonths
    ) {
      updateNextDewormingOperation(data.dewormingFrequencyInMonths)
    }

    if (
      data.fleaControlFrequencyInMonths &&
      data.fleaControlFrequencyInMonths !== animal.health.fleaControlFrequencyInMonths
    ) {
      updateNextFleaControlOperation(data.fleaControlFrequencyInMonths)
    }

    if (
      data.annualReminderFrequencyInYear &&
      data.annualReminderFrequencyInYear !== animal.vaccination.annualReminderFrequencyInYear
    ) {
      updateNextVaccinationOperation(data.annualReminderFrequencyInYear)
    }

    const newAnimal = {
      ...animal,
      ...omit(data, 'annualReminderFrequencyInYear', 'dewormingFrequencyInMonths', 'fleaControlFrequencyInMonths'),
      health: {
        ...animal.health,
        dewormingFrequencyInMonths: data.dewormingFrequencyInMonths,
        fleaControlFrequencyInMonths: data.fleaControlFrequencyInMonths,
        operations: animal.health.operations,
      },
      vaccination: {
        ...animal.vaccination,
        annualReminderFrequencyInYear: data.annualReminderFrequencyInYear,
      },
    }

    const newAnimalWithoutEmptyString = omitEmptyString(newAnimal)

    await editAnimalMutation.mutate(newAnimalWithoutEmptyString)
  }
  const spacingItem = { marginTop: 2 }

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
        <Tooltip title="Editer les fréquences de rappels">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>🐈 Editer les fréquences de rappels de {animal.name}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}>
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <ControlledSelectField
                sx={{ width: '100%', ...spacingItem }}
                control={control}
                error={errors.annualReminderFrequencyInYear}
                fieldName="annualReminderFrequencyInYear"
                size="small"
                label="Post-primo"
                options={[
                  {
                    label: 'Tous les ans',
                    value: 1,
                  },
                  {
                    label: 'Tous les 2 ans',
                    value: 2,
                  },
                  {
                    label: 'Pas de rappel(s)',
                    value: -1,
                  },
                ]}
                requiredRule={undefined}
              />
              <ControlledSelectField
                sx={{ width: '100%', ...spacingItem }}
                control={control}
                error={errors.dewormingFrequencyInMonths}
                fieldName="dewormingFrequencyInMonths"
                size="small"
                label="Vermifuge"
                options={[
                  {
                    label: 'Tous les 15 jours',
                    value: 0.5,
                  },
                  {
                    label: 'Tous les mois',
                    value: 1,
                  },
                  {
                    label: 'Tous les 2 mois',
                    value: 2,
                  },
                  {
                    label: 'Tous les 3 mois',
                    value: 3,
                  },
                  {
                    label: 'Pas de rappel(s)',
                    value: -1,
                  },
                ]}
                requiredRule={undefined}
              />
              <ControlledSelectField
                sx={{ width: '100%', ...spacingItem }}
                control={control}
                error={errors.fleaControlFrequencyInMonths}
                fieldName="fleaControlFrequencyInMonths"
                size="small"
                label="Antipuce"
                options={[
                  {
                    label: 'Tous les 15 jours',
                    value: 0.5,
                  },
                  {
                    label: 'Tous les mois',
                    value: 1,
                  },
                  {
                    label: 'Tous les 2 mois',
                    value: 2,
                  },
                  {
                    label: 'Tous les 3 mois',
                    value: 3,
                  },
                  {
                    label: 'Pas de rappel(s)',
                    value: -1,
                  },
                ]}
                requiredRule={undefined}
              />
              {serverError && (
                <Box sx={spacingItem}>
                  <Typography color="error">{serverError}</Typography>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <LoadingButton
                variant="contained"
                sx={{ width: '100%' }}
                type="submit"
                loading={editAnimalMutation.isLoading}
              >
                Mettre à jour
              </LoadingButton>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
