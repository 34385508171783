import { Box, FormControl, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'

import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { InfoOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../domain/Account/AccountDisplay'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { AdopterDto, AdopterDtoProfileHasBeenAccepted, CreateAdopterBodyDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../utils/axios'
import { omitEmptyString } from '../../utils/omitEmptyString'
import { PATHS } from '../PATHS'
import { theme } from '../theme'
import { FastLaunchProcedureDialog } from './FastLaunchProcedureDialog'

// We need to omit the select and add empty string, if not it does not work
type CreateAdopterFormParams = CreateAdopterBodyDto

export const AddAdopterScreen: FC = () => {
  const queryParams = new URLSearchParams(location.search)
  const [isLoading, setIsLoading] = useState(false)
  const globalSnackbarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateAdopterFormParams>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      country: 'France',
      sendForm: false,
    },
    mode: 'onChange',
  })

  const [userWantToAssignPastAdoption, setUserWantToAssignPastAdoption] = useState<boolean>(false)
  const [openSteps, setOpenSteps] = useState(false)
  const [adopter, setAdopter] = useState<AdopterDto | null>(null)

  const [serverError, setServerError] = useState('')

  const onSubmit = async (data: CreateAdopterFormParams) => {
    setIsLoading(true)
    console.log('On submit', data)

    const dataWithoutEmptyString = omitEmptyString(data as any)

    if (userWantToAssignPastAdoption) {
      dataWithoutEmptyString.profileHasBeenAccepted = AdopterDtoProfileHasBeenAccepted.Accepted
    }

    const from = queryParams.get('from')

    await AdoptersClient.createAdopter(dataWithoutEmptyString as CreateAdopterBodyDto)
      .then((account) => {
        console.log('✨ Adopter created!')

        globalSnackbarStore.triggerSuccessMessage(`Adoptant ${fullName(data)} a été créé.`)

        if (!from && !userWantToAssignPastAdoption) {
          setAdopter(account)
          setOpenSteps(true)
          reset()
          return
        }

        if (from && from.length > 0) {
          return navigate(
            `/${from}&adoptant=${account.id}&card=${queryParams.get('card')}&status=${queryParams.get('status')}`
          )
        }
        if (userWantToAssignPastAdoption) {
          return navigate(`${PATHS.assignerAnimal.absolute}?adopterId=${account.id}&type=pastAdoption`)
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            setIsLoading(false)
            setServerError('Un utilisateur existe déjà avec cette addresse mail.')
            return
          }
        }

        setServerError(readableAxiosError(error).join(' '))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const spacingItem = { marginTop: 2 }
  return (
    <>
      <Box sx={{ width: '900px', maxWidth: '90%', marginX: 'auto', marginY: '5rem' }}>
        <Typography variant="h4">😇 Ajouter un Adoptant</Typography>

        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <TextField
            id="first-name-input"
            label="Prénom"
            type="text"
            fullWidth
            {...register('firstName', { required: 'Le prénom est requis' })}
            sx={spacingItem}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            required
          />

          <TextField
            id="last-name-input"
            label="Nom de Famille"
            type="text"
            fullWidth
            {...register('lastName', { required: 'Le nom est requis' })}
            sx={spacingItem}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            required
          />

          <TextField
            id="email"
            label="Email"
            type="email"
            fullWidth
            {...register('email', {
              required: "L'email est requis.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "L'addresse n'est pas valide.",
              },
            })}
            sx={spacingItem}
            error={!!errors.email}
            helperText={errors.email?.message}
            required
          />

          <FormControl fullWidth variant="outlined" sx={spacingItem}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                validate: (value: string | undefined) => (!value || matchIsValidTel(value) ? true : 'Tel is invalid'),
              }}
              render={({ field, fieldState }) => (
                <MuiTelInput
                  {...field}
                  label="Numero de Téléphone"
                  defaultCountry="FR"
                  helperText={fieldState.invalid ? "Le numero de téléphone n'est pas valide" : ''}
                  error={fieldState.invalid}
                />
              )}
            />
          </FormControl>

          <TextField
            id="address"
            label="Adresse"
            type="text"
            fullWidth
            {...register('address')}
            sx={spacingItem}
            error={!!errors.address}
            helperText={errors.address?.message}
          />

          <TextField
            id="postalCode"
            label="Code Postal"
            type="text"
            fullWidth
            {...register('postalCode')}
            sx={spacingItem}
            error={!!errors.postalCode}
            helperText={errors.postalCode?.message}
          />

          <TextField
            id="city"
            label="Ville"
            type="text"
            fullWidth
            {...register('city')}
            sx={spacingItem}
            error={!!errors.city}
            helperText={errors.city?.message}
          />

          <TextField
            id="country"
            label="Pays"
            type="text"
            fullWidth
            {...register('country')}
            sx={spacingItem}
            error={!!errors.country}
            helperText={errors.country?.message}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...spacingItem, gap: 0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography fontSize={16} fontWeight={700}>
                Ce nouvel adoptant concerne :<span style={{ color: 'red' }}> *</span>
              </Typography>
              <Tooltip title="L'intérêt d'ajouter une adoption passée est de pouvoir conserver l'historique des adoptions de l'adoptant sur Petso.">
                <InfoOutlined sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
            </Box>
            <ToggleButtonGroup
              exclusive
              color="info"
              value={userWantToAssignPastAdoption}
              onChange={(_, newValue) => setUserWantToAssignPastAdoption(newValue)}
              aria-label="text alignment"
              fullWidth
            >
              <ToggleButton value={true} aria-label="Une adoption passée">
                Une adoption passée
              </ToggleButton>
              <ToggleButton value={false} aria-label="Une demande d'adoption">
                Une demande d&apos;adoption
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {serverError && (
            <Box sx={spacingItem}>
              <Typography color="error">{serverError}</Typography>
            </Box>
          )}

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', ...spacingItem }}>
            <LoadingButton variant="contained" sx={{ width: '100%' }} type="submit" loading={isLoading}>
              Ajouter
            </LoadingButton>
          </Box>
        </form>
      </Box>
      <FastLaunchProcedureDialog open={openSteps} adopter={adopter} setOpen={setOpenSteps} />
    </>
  )
}
