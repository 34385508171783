import { useEffect } from 'react'
import { useDonatersStore } from './DonatersStore'

export const useFetchOrRefreshDonatersOnMount = () => {
  const donatersStore = useDonatersStore()

  useEffect(() => {
    const loadDonaters = async () => {
      await donatersStore.fetchOrRefreshDonaters()

      console.log('Donaters fetched or refreshed')
    }

    loadDonaters()
  }, [])
}
