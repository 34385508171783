import { useMutation } from 'react-query'
import { AnimalsClient } from '../interactors/clients/AnimalsClient'
import { useAnimalStore } from './AnimalStore'
import { useGlobalSnackbarStore } from './GlobalSnackBarStore'

export const useEditAnimalMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: (error: unknown) => void
}) => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const animalStore = useAnimalStore()

  return useMutation(AnimalsClient.editAnimal, {
    onSuccess: async (data) => {
      globalSnackBarStore.triggerSuccessMessage(`${data.name} modifié !`)
      await animalStore.refreshSelectedAnimalInBackground()
      if (onSuccess) {
        onSuccess()
      }
    },
    onError: async (error) => {
      console.error(error)
      globalSnackBarStore.triggerErrorMessage(`Une erreur est survenue !`)
      await animalStore.refreshSelectedAnimalInBackground()
      if (onError) {
        onError(error)
      }
    },
  })
}
