import { Save } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Tooltip, useMediaQuery } from '@mui/material'
import { FC } from 'react'
import { GENERIC_DISPLAY } from '../../../utils/genericDisplay'
import { useCanEditAnimal } from '../../../store/useCanEditAnimal'
import { theme } from '../../theme'

interface Props {
  isLoading: boolean
  disabled?: boolean
}

export const AnimalCardSaveButton: FC<Props> = ({ isLoading, disabled }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const canEdit = useCanEditAnimal()

  return (
    <Tooltip title={canEdit ? '' : GENERIC_DISPLAY.doNotHavePermissionAsReader}>
      <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
        {' '}
        <LoadingButton
          color="primary"
          variant="outlined"
          type="submit"
          loading={isLoading}
          disabled={!canEdit || disabled}
          sx={{ textTransform: 'none', width: { xs: '100%', md: 'auto' } }}
        >
          {!isMobile && <Save sx={{ marginRight: 1 }} />}
          Mettre à jour
        </LoadingButton>
      </Box>
    </Tooltip>
  )
}
