import { FC } from 'react'
import { AnimalDto } from '../../../../interactors/gen/backendClient'
import { AnimalHealthCard } from './AnimalHealthCard'
import { AnimalHealthFormAndActivityCard } from './AnimalHealthFormAndActivityCard'
import { AnimalHealthOperationsCard } from './AnimalHealthOperationsCard'
import { Grid } from '@mui/material'

interface Props {
  animal: AnimalDto
}

export const AnimalHealthTab: FC<Props> = ({ animal }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <AnimalHealthCard animal={animal} />
        </Grid>
        <Grid item xs={12} md={3}>
          <AnimalHealthFormAndActivityCard animal={animal} />
        </Grid>
        <Grid item xs={12} md={12}>
          <AnimalHealthOperationsCard animal={animal} />
        </Grid>
      </Grid>
    </>
  )
}
