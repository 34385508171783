import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { spacingItem } from '../../theme'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { AnimalDtoSpecies, CustomerCageWithAnimalsDetailsDto } from '../../../interactors/gen/backendClient'
import { Add } from '@mui/icons-material'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { ControlledMultipleAutoComplete } from '../../common/ControlledMultipleAutoComplete'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { useNavigate } from 'react-router-dom'

type FormParams = {
  cageNumber: string
  name?: string
  suitableForSpecies: AnimalDtoSpecies[]
  capacity: number
  memberInChargeId?: string
  address: string
  city: string
  postalCode: string
}

interface Props {
  setCagesList: React.Dispatch<React.SetStateAction<CustomerCageWithAnimalsDetailsDto[]>>
  highestCageNumber: number
  cagesList: CustomerCageWithAnimalsDetailsDto[]
}

export const AssociationAddCageModal: React.FC<Props> = ({ setCagesList, highestCageNumber, cagesList }) => {
  const queryParams = new URLSearchParams(location.search)
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const accountsStore = useAccountsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()

  const [isAddressSameAsMemberInCharge, setIsAddressSameAsMemberInCharge] = React.useState(true)
  const [isAddressSameAsAssociation, setIsAddressSameAsAssociation] = React.useState(false)

  useEffect(() => {
    if (queryParams.get('from')) {
      openDialog()
    }
  }, [])

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormParams>({
    defaultValues: {
      cageNumber: (highestCageNumber + 1).toString(),
      name: '',
      suitableForSpecies: [],
      capacity: 0,
      memberInChargeId: '',
      address: '',
      city: '',
      postalCode: '',
    },
    mode: 'onChange',
  })

  const onSubmit = (data: FormParams) => {
    const isDuplicateCageNumber = cagesList.some((cage) => cage.cageNumber === data.cageNumber)

    if (isDuplicateCageNumber) {
      globalSnackbarStore.triggerErrorMessage('Une cage existe déjà avec ce numéro')
    } else {
      const uploadData: FormParams = {
        ...data,
      }
      setCagesList((prev) => [...prev, uploadData])
      closeDialog()
      reset()
      const from = queryParams.get('from')
      if (from && from.length > 0) {
        return navigate(
          `/${from}&cageNumber=${uploadData.cageNumber}&card=${queryParams.get(
            'card'
          )}&species=${data.suitableForSpecies.join(',')}
          &field=${queryParams.get('field')}`
        )
      }
    }
  }

  const options = accountsStore.members

  useEffect(() => {
    setValue('cageNumber', (highestCageNumber + 1).toString())
  }, [highestCageNumber])

  const watchMemberInChargeId = watch('memberInChargeId')
  useEffect(() => {
    const memberInCharge = accountsStore.members.find((member) => member.id === watchMemberInChargeId)
    if (memberInCharge && isAddressSameAsMemberInCharge) {
      setValue('address', memberInCharge.address ? memberInCharge.address : '')
      setValue('city', memberInCharge.city ? memberInCharge.city : '')
      setValue('postalCode', memberInCharge.postalCode ? memberInCharge.postalCode : '')
    }
  }, [watchMemberInChargeId, isAddressSameAsMemberInCharge])

  useEffect(() => {
    if (isAddressSameAsAssociation) {
      const customer = accountsStore.connectedCustomer
      if (customer) {
        setValue('address', customer.address ? customer.address : '')
        setValue('city', customer.city ? customer.city : '')
        setValue('postalCode', customer.postalCode ? customer.postalCode : '')
      }
    }
  }, [isAddressSameAsAssociation])

  return (
    <>
      <Tooltip
        title={
          !accountsStore.isPremium() ? "Seul les possesseurs de l'offre Utile ont accès à cette fonctionnalité" : ''
        }
      >
        <span>
          <Fab
            color="primary"
            variant="extended"
            onClick={openDialog}
            disabled={!accountsStore.isPremium() || !accountsStore.isAdmin()}
          >
            <Add sx={{ mr: 1 }} />
            Ajouter une cage
          </Fab>
        </span>
      </Tooltip>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Ajouter une cage
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', gap: 2 }}>
                <Box sx={{ width: { xs: '100%', sm: '25%' } }}>
                  <TextField
                    label="N° de cage'"
                    type="text"
                    fullWidth
                    size="small"
                    {...register('cageNumber')}
                    error={!!errors.cageNumber}
                    helperText={errors.cageNumber?.message}
                  />
                </Box>

                <Box sx={{ width: '100%' }}>
                  <TextField
                    label="Intitulé"
                    type="text"
                    fullWidth
                    size="small"
                    {...register('name')}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Box>
              </Box>

              <ControlledMultipleAutoComplete
                control={control}
                fieldName="suitableForSpecies"
                label="Adapté pour"
                error={errors.name}
                options={Object.values(AnimalDtoSpecies).map((status: AnimalDtoSpecies) => ({
                  label: getReadableSpecies(status, { withEmoji: false }),
                  value: status,
                }))}
                size="small"
                requiredRule={'true'}
                sx={spacingItem}
              />

              <ControlledSelectField
                control={control}
                fieldName="capacity"
                label="Capacité d'accueil"
                error={errors.capacity}
                options={[
                  { label: '1 animal', value: 1 },
                  { label: '2 animaux', value: 2 },
                  { label: '3 animaux', value: 3 },
                  { label: '4 animaux', value: 4 },
                  { label: '5 animaux', value: 5 },
                  { label: '6 animaux', value: 6 },
                  { label: '7 animaux', value: 7 },
                  { label: '8 animaux', value: 8 },
                  { label: '9 animaux', value: 9 },
                  { label: '10 animaux', value: 10 },
                  { label: '11 animaux', value: 11 },
                  { label: '12 animaux', value: 12 },
                  { label: '13 animaux', value: 13 },
                  { label: '14 animaux', value: 14 },
                  { label: '15 animaux', value: 15 },
                  { label: '16 animaux', value: 16 },
                  { label: '17 animaux', value: 17 },
                  { label: '18 animaux', value: 18 },
                  { label: '19 animaux', value: 19 },
                  { label: '20 animaux', value: 20 },
                ]}
                requiredRule="Le champs capacité d'accueil est requis"
                size="small"
                sx={spacingItem}
              />

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'memberInChargeId'}
                options={options
                  .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                  .map((option) => ({ label: fullName(option), value: option.id }))}
                label="Membre en charge"
                error={errors.memberInChargeId}
                requiredRule={'true'}
                defaultValue={''}
                size="small"
                sx={spacingItem}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAddressSameAsMemberInCharge}
                      onChange={() => {
                        setIsAddressSameAsMemberInCharge((prev) => !prev)
                        if (isAddressSameAsAssociation) {
                          setIsAddressSameAsAssociation(false)
                        }
                      }}
                    />
                  }
                  label="Adresse similaire à celle du membre en charge"
                  sx={spacingItem}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAddressSameAsAssociation}
                      onChange={() => {
                        setIsAddressSameAsAssociation((prev) => !prev)
                        if (isAddressSameAsMemberInCharge) {
                          setIsAddressSameAsMemberInCharge(false)
                        }
                      }}
                    />
                  }
                  label="Adresse similaire à celle de l'association"
                />
              </FormGroup>

              <TextField
                label="Adresse"
                type="text"
                fullWidth
                size="small"
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address?.message}
                sx={spacingItem}
                disabled={isAddressSameAsMemberInCharge || isAddressSameAsAssociation}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Code postal"
                type="text"
                fullWidth
                size="small"
                {...register('postalCode')}
                error={!!errors.postalCode}
                helperText={errors.postalCode?.message}
                sx={spacingItem}
                disabled={isAddressSameAsMemberInCharge || isAddressSameAsAssociation}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Ville"
                type="text"
                fullWidth
                size="small"
                {...register('city')}
                error={!!errors.city}
                helperText={errors.city?.message}
                sx={spacingItem}
                disabled={isAddressSameAsMemberInCharge || isAddressSameAsAssociation}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton data-testid="apply-changes" variant="contained" type="submit">
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
