import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { blue, theme } from '../../theme'
import YellowCloud from './images/yellow-cloud.png'
import BlueCloud from './images/blue-cloud.png'

interface Props {
  title: string
  price: string
  priceSubtitle: ReactNode
  essentialOffer: boolean
}

export const OfferTitle: React.FC<Props> = ({ title, price, priceSubtitle, essentialOffer }) => {
  return (
    <Box
      sx={{
        position: 'relative', // Set position to relative
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'space-between',
        gap: 4,
        backgroundImage: `url(${essentialOffer ? YellowCloud : BlueCloud})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        p: 3,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="h4"
          color={theme.palette.primary.main}
          fontWeight={700}
          fontSize={26}
          sx={{ fontFamily: 'Insaniburger', whiteSpace: 'nowrap' }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h4" color={blue} fontWeight={700} fontSize={22} sx={{ fontFamily: 'Insaniburger' }}>
          {price}
        </Typography>
        <Typography variant="h4" color={blue} fontWeight={400} fontSize={16}>
          {priceSubtitle}
        </Typography>
      </Box>
    </Box>
  )
}
