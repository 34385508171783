import { AccountDto } from '../gen/backendClient'
import { backendClient } from './client'
import { AxiosRequestConfig } from 'axios'

/**
 * We differenitate members and account as members are other members of association
 */
export class MembersClient {
  public static async getAllMembers(): Promise<Array<AccountDto>> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/members',
    }

    const response = await backendClient<Array<AccountDto>>(config)

    return response.data
  }
}
