import React from 'react'
import { AssociationDescription } from './AssociationDescription'
import { Grid } from '@mui/material'
import {
  CustomerDto,
  CustomerPreferencesDto,
  OrganizationTypeDto,
  OrganizationTypeDtoType,
} from '../../../interactors/gen/backendClient'
import { useMutation } from 'react-query'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import axios, { AxiosError } from 'axios'
import { readableAxiosError } from '../../../utils/axios'
import { StatusCodes } from 'http-status-codes'
import { AssociationTheAssociationCard } from './AssociationTheAssociationCard'
import { AssociationBureauMembers } from './AssociationBureauMembers'
import { useAccountsStore } from '../../../store/AccountsStore'

interface AssociationProps {
  association: CustomerDto
  setAssociation: (association: CustomerDto) => void
}

export const AssociationMyAssociationTab: React.FC<AssociationProps> = ({ association, setAssociation }) => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()

  //Mutation to edit the adopter
  const editAssociationMutation = useMutation(
    async (data: CustomerDto) => {
      const response = await CustomersClient.editCustomer(data)
      return response
    },
    {
      onSuccess: (association) => {
        globalSnackBarStore.triggerSuccessMessage(`Le profil de ${association.name} a été modifié.`)
        const fetchCustomer = async () => {
          const customer = await CustomersClient.getCurrentCustomer()
          setAssociation(customer)
          accountsStore.changeConnectedCustomer(customer)
        }

        fetchCustomer()
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            globalSnackBarStore.triggerErrorMessage('Un utilisateur existe déjà avec cette addresse mail.')
            return
          }
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  //Global submit function used by all the association form components (not the owner form)
  const onSubmit = async (
    data: Partial<CustomerDto> & Partial<CustomerPreferencesDto> & Partial<OrganizationTypeDto>
  ) => {
    const organizationType = {
      type: data.type || association.organizationType?.type,
      otherField:
        data.type === OrganizationTypeDtoType.OtherOrganizations
          ? data?.otherField || association.organizationType?.otherField
          : undefined,
    }
    const preferences = {
      species: data.species || association.preferences.species,
    }
    console.log('On submit', data)

    const { species, ...restData } = data

    const newData = {
      ...association,
      ...restData,
      organizationType: organizationType,
      preferences: preferences,
      phoneNumber: ['+33', ''].includes(data.phoneNumber?.trim() || '') ? undefined : data.phoneNumber,
    }
    console.log('newData', newData)
    editAssociationMutation.mutate(newData)
  }

  if (!association) {
    return null
  }

  return (
    <>
      <AssociationDescription association={association!} onSubmit={onSubmit} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <AssociationTheAssociationCard association={association} onSubmit={onSubmit} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AssociationBureauMembers association={association!} onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </>
  )
}
