import { Add } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, Fab, TextField } from '@mui/material'
import React from 'react'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { useAccountsStore } from '../../../store/AccountsStore'
import { AccountDtoPermission } from '../../../interactors/gen/backendClient'

interface Props {
  addComment: (comment: string) => void
  isOwnAccount: boolean
}

export const AddCommentModal: React.FC<Props> = ({ addComment, isOwnAccount }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const [comment, setComment] = React.useState<string>('')
  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read
  return (
    <>
      <Fab color="primary" variant="extended" onClick={openDialog} disabled={isReader && !isOwnAccount}>
        <Add sx={{ mr: 1 }} />
        Ajouter un commentaire
      </Fab>

      <Dialog
        open={isDialogOpen}
        onClose={() => {
          closeDialog()
          setComment('')
        }}
        PaperProps={{
          style: { borderRadius: 15, padding: 15, width: 500, maxWidth: '90%' },
        }}
      >
        <DialogContent>
          <TextField
            sx={{ width: '100%', height: 200 }}
            placeholder="Ajouter un commentaire"
            value={comment}
            multiline
            rows={6}
            label="Commentaire"
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeDialog()
              setComment('')
            }}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              addComment(comment)
              setComment('')
              closeDialog()
            }}
          >
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
