export function formatStringForSearch(originalString: string): string {
  return (
    originalString
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      // .replace(/[^a-zA-Z ]/g, '')
      .trim()
  )
}
