import { Add, UploadFile } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { blue, colors } from '../theme'
import { useDropzone } from 'react-dropzone'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useMutation } from 'react-query'
import { backendClient } from '../../interactors/clients/client'
import { setOpacity } from '../../utils/setOpacity'
import { LoadingButton } from '@mui/lab'
import { useAnimalStore } from '../../store/AnimalStore'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'

interface ServerError {
  reason: string
  // add other possible properties
}

export const AnimalSheetPopUp = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    maxFiles: 1,
  })
  const [selectedType, setSelectedType] = useState<'active' | 'old' | null>(null)
  const isDropZoneDisabled = !selectedType
  const [progress, setProgress] = useState(0)
  const [completedApiCall, setCompletedApiCall] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const animalStore = useAnimalStore()
  const sx = { marginTop: 2 }

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newType: 'active' | 'old' | null) => {
    event.stopPropagation()
    setSelectedType(newType)
  }

  const resetAllAndClose = () => {
    setCompletedApiCall(false)
    setShowSuccessMessage(false)
    setProgress(0)
    setSelectedType(null)
    closeDialog()
    animalStore.fetchAnimals()
  }

  const mutation = useMutation(
    async (file: File) => {
      const typeOfFile = selectedType === 'active' ? 'active' : 'old'
      const formData = new FormData()
      formData.append('file', file)
      return await backendClient.post(`animals/import?typeOfFile=${typeOfFile}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    {
      onSuccess: () => {
        if (selectedType === 'active') {
          animalStore.fetchAnimals()
          closeDialog()
          globalSnackbar.triggerSuccessMessage(`Vos animaux ont bien été importés !`)
        }
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          error.response && error.response.data && (error.response.data as ServerError).reason
            ? (error.response.data as ServerError).reason
            : "Une erreur est survenue lors de l'import de vos animaux. Veuillez réessayer plus tard."
        globalSnackbar.triggerErrorMessage(`${errorMessage}`)
      },
    }
  )

  const onSubmit = async () => {
    if (acceptedFiles[0]) {
      mutation.mutate(acceptedFiles[0])
      setCompletedApiCall(true) // Set this flag to start the progress bar
    } else {
      globalSnackbar.triggerErrorMessage(`Vous devez ajouter un fichier !`)
    }
  }

  useEffect(() => {
    if (completedApiCall) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer)
            setShowSuccessMessage(true)
            return 100
          }
          const diff = 14.29 // Set the increment value so that it fills in 10 seconds.
          return Math.min(oldProgress + diff, 100)
        })
      }, 1000) // Updates every second

      return () => {
        clearInterval(timer)
      }
    }
  }, [completedApiCall])

  return (
    <>
      <Fab key="animalSheet" color="primary" variant="extended" onClick={openDialog}>
        <Add sx={{ mr: 1 }} />
        {'Ajouter tous mes animaux'}
      </Fab>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>
          {!completedApiCall && (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
              <Typography variant="h5" textAlign="center" color={blue} sx={{ fontSize: '25px' }}>
                Importer vos animaux sur votre espace
              </Typography>
              <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
                <Typography variant="h5" sx={sx} color={blue} fontSize={19}>
                  1. Remplissez en un clic votre espace Petso grâce à votre fichier I-CAD:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9} sx={{ display: 'flex', alignItems: 'center' }}>
                    <a href="https://www.i-cad.fr/" target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                      Pour gagner en temps, importer vos fichiers directement accessible depuis votre espace I-CAD
                    </a>
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <a href="https://www.i-cad.fr/" target="_blank" rel="noreferrer">
                      <img src="/images/i-cad.png" alt="i-cad" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    </a>
                  </Grid>
                </Grid>

                <Typography variant="h5" sx={sx} color={blue} fontSize={19}>
                  2. Attention: choississez attentivement le type de documents que vous allez importer parmis ces 2
                  possibilités:
                </Typography>

                <ToggleButtonGroup
                  exclusive
                  value={selectedType}
                  onChange={handleToggleChange}
                  aria-label="text alignment"
                  fullWidth
                >
                  <ToggleButton value="active" aria-label="Animaux suivis">
                    Animaux suivis
                  </ToggleButton>
                  <ToggleButton value="old" aria-label="Anciens animaux">
                    Anciens animaux
                  </ToggleButton>
                </ToggleButtonGroup>

                <Typography variant="h5" color={blue} fontSize={19}>
                  3. Ajoutez le document rempli ci-dessous:
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding={3}
                {...getRootProps({
                  sx: {
                    border: 3,
                    borderRadius: 5,
                    borderStyle: 'dashed',
                    borderColor: blue,
                    backgroundColor: isDragActive
                      ? setOpacity(colors.cello, 0.4)
                      : acceptedFiles[0]
                      ? setOpacity(colors.geyser, 0.4)
                      : 'transparent',
                    transition: 'all 0.3s',
                    pointerEvents: isDropZoneDisabled ? 'none' : 'auto', // This will make it non-interactive
                    opacity: isDropZoneDisabled ? 0.5 : 1, // This will make it look disabled (optional)
                  },
                })}
              >
                <input {...getInputProps()} />
                <UploadFile sx={{ color: blue, fontSize: 64, mb: 1 }} />
                <Typography variant="h4" component="p" fontSize={20} textAlign="center">
                  Glissez-déposer ou
                  <br />
                  Insérer un document
                </Typography>
                <Box
                  marginTop={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    maxWidth: '100%',
                  }}
                >
                  <Typography variant="h4" component="p" fontSize={18} textAlign="center" sx={{ whiteSpace: 'nowrap' }}>
                    Votre document (.xlsx uniquement):
                  </Typography>
                  <Typography
                    variant="h4"
                    component="p"
                    fontSize={18}
                    textAlign="center"
                    color={colors.black}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                    }}
                  >
                    {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                  </Typography>
                </Box>
                <Button variant="contained" sx={{ width: '40%', mt: 1 }}>
                  Parcourir
                </Button>
              </Box>
              <Typography>
                Nous pourrons automatiquement inscrire vos animaux sur votre espace Petso ! Libre à vous de rajouter des
                détails par la suite
              </Typography>
            </Box>
          )}
          {/* Display the progressive loading bar only if API call has started */}
          {completedApiCall && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 2, gap: 1 }}>
              <Typography>Merci de patienter pendant que nous traitons vos données...</Typography>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}

          {/* Display success message once the loading is done */}
          {showSuccessMessage && (
            <>
              <Typography color="green" style={{ textAlign: 'center', marginTop: '10px' }}>
                Traitement terminé avec succès !
              </Typography>
              <Typography style={{ textAlign: 'center', marginTop: '10px' }}>
                Nous avons ajouté tous les animaux du fichier &quot;Mes anciens animaux&quot;. Par défault, chaque
                animal a été enregistré comme étant adopté.{' '}
                <span style={{ fontWeight: 700 }}>Si ce n&#39;est pas le cas de certains,</span> pensez dès maintenant à
                modifier l&#39;information sur sa fiche dans l&#39;onglet &quot;adoption&quot;
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {showSuccessMessage ? (
            <Button
              data-testid="terminate"
              onClick={() => {
                resetAllAndClose()
              }}
            >
              Terminer
            </Button>
          ) : (
            <>
              {!completedApiCall && (
                <Button data-testid="cancel" onClick={closeDialog}>
                  Revenir
                </Button>
              )}
              <LoadingButton
                data-testid="submit"
                variant="contained"
                onClick={onSubmit}
                disabled={!acceptedFiles[0] || isDropZoneDisabled}
                loading={mutation.isLoading || completedApiCall}
              >
                Importer
              </LoadingButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
