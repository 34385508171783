import { Box, Card, CardContent, CardHeader, Chip, Grid, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import {
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoStatus,
  AnimalDtoSpecies,
  AnimalTakeOverDtoReason,
  CannotBeAdoptedStatusCountDto,
  DashboardDataDto,
} from '../../../interactors/gen/backendClient'
import {
  getColorForAnimalAdoptionStatus,
  getReadableAnimalAdoptionStatus,
  getReadableCannotBeAdoptedStatus,
  getReadableSpecies,
  getReadableTakeOverReason,
} from '../../../domain/Animal/AnimalDisplay'
import { PreConfiguredPieChart } from '../../common/PreConfiguredPieChart'
import { AssociationDashboardEvolution } from './AssociationDashboardEvolution'
import { backendClient } from '../../../interactors/clients/client'
import { isColorDark } from '../../../utils/isColorDark'
import dayjs from 'dayjs'
import { DefineDatesToFilter } from '../DonationTracking/DefineDatesToFilter'

export const AssociationDashboard: React.FC = () => {
  const [data, setData] = useState<DashboardDataDto>()
  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs | null>(null)
  const [dateTo, setDateTo] = useState<dayjs.Dayjs | null>(null)

  function debounce<F extends (...args: any[]) => any>(func: F, waitFor: number) {
    let timeout: ReturnType<typeof setTimeout> | null = null

    return function (...args: Parameters<F>) {
      const later = () => {
        clearTimeout(timeout!)
        timeout = null
        func(...args)
      }

      clearTimeout(timeout!)
      timeout = setTimeout(later, waitFor)
    } as F
  }

  useEffect(() => {
    const fetchDashboardData = debounce(async () => {
      const params = {
        startDate: dateFrom?.toISOString(),
        endDate: dateTo?.toISOString(),
      }

      await backendClient.get('/animals/dashboard-data', { params }).then((response) => {
        setData(response.data)
      })
    }, 500) // delay of 500ms

    fetchDashboardData()
  }, [dateFrom, dateTo])

  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
  ]

  const cannotBeAdoptedStatusNotHandled = data?.cannotBeAdoptedStatusCount.filter(
    (o) => o.status === 'lost' || o.status === 'dead' || o.status === 'transfer-to-another-association'
  )
  const totalCannotBeAdoptedNotHandledCount = cannotBeAdoptedStatusNotHandled?.reduce(
    (sum, item) => sum + item.count,
    0
  )

  const cannotBeAdoptedStatusHandled = data?.cannotBeAdoptedStatusCount.filter(
    (o) => o.status !== 'lost' && o.status !== 'dead' && o.status !== 'transfer-to-another-association'
  )
  const totalCannotBeAdoptedHandledCount = cannotBeAdoptedStatusHandled?.reduce((sum, item) => sum + item.count, 0)

  const dataForNewEntryBySpeciePieChart: { name: string; value: number }[] =
    data?.entriesBySpeciesCount?.map((entry) => ({
      name: getReadableSpecies(entry.speciesName as AnimalDtoSpecies),
      value: entry.count,
    })) || []

  const dataForTakeOverReasonPieChart: { name: string; value: number }[] =
    data?.takeOverReasonCount?.map((reason) => ({
      name: getReadableTakeOverReason(reason.reason as AnimalTakeOverDtoReason),
      value: reason.count,
    })) || []

  const dataForAdoptedBySpeciesPieChart: { name: string; value: number }[] =
    data?.adoptionCountBySpecies?.map((speciesData) => ({
      name: getReadableSpecies(speciesData.speciesName as AnimalDtoSpecies),
      value: speciesData.count,
    })) || []

  const dataForCityOfOriginPieChart: { name: string; value: number }[] =
    data?.citiesOfOriginCount?.map((cityData) => ({
      name: cityData.cityName,
      value: cityData.count,
    })) || []

  return (
    <Card>
      <CardHeader
        title="Tableau de bord"
        action={
          <DefineDatesToFilter dateFrom={dateFrom} setDateFrom={setDateFrom} dateTo={dateTo} setDateTo={setDateTo} />
        }
      />
      <CardContent>
        <Box display="flex" justifyContent="space-between" sx={{ px: 1 }}>
          <Box>
            <Typography variant="h5" marginBottom={2}>
              Suivi animal
            </Typography>
            <Typography variant="h6" marginBottom={2}>
              {`${data?.totalCount} animaux concernés sur la période`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              gap: { xs: 1, md: 6 },
            }}
          >
            <Chip color="info" label={`Stérilisés : ${data?.sterilizedOrNeuteredCount}`} />
            <Chip
              color="warning"
              label={`Primovaccination complète : ${data?.completedVaccinationCount}`}
              sx={{ width: 220 }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ mt: 2, px: 1, flexWrap: { xs: 'wrap', md: 'nowrap' }, gap: 1 }}
        >
          {data?.adoptionStatusesCount
            .filter((o) => o.status !== 'cannot-be-adopted')
            .map((adoptionStatus) => (
              <Chip
                key={adoptionStatus.status}
                label={`${getReadableAnimalAdoptionStatus(adoptionStatus.status as AnimalAdoptionDtoStatus)} : ${
                  adoptionStatus.count
                }`}
                sx={{
                  backgroundColor: getColorForAnimalAdoptionStatus(adoptionStatus.status as AnimalAdoptionDtoStatus),
                  color: isColorDark(getColorForAnimalAdoptionStatus(adoptionStatus.status as AnimalAdoptionDtoStatus))
                    ? 'white'
                    : 'black',
                  cursor: 'pointer',
                }}
              />
            ))}
          <Tooltip title={<ToolTipContentForNonAdoptableStatus data={cannotBeAdoptedStatusHandled} />}>
            <Chip
              label={`Non adoptable en gestion : ${totalCannotBeAdoptedHandledCount}`}
              sx={{
                backgroundColor: getColorForAnimalAdoptionStatus('cannot-be-adopted' as AnimalAdoptionDtoStatus),
                color: isColorDark(getColorForAnimalAdoptionStatus('cannot-be-adopted' as AnimalAdoptionDtoStatus))
                  ? 'white'
                  : 'black',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
          <Tooltip title={<ToolTipContentForNonAdoptableStatus data={cannotBeAdoptedStatusNotHandled} />}>
            <Chip
              label={`Non adoptable hors gestion : ${totalCannotBeAdoptedNotHandledCount}`}
              sx={{
                backgroundColor: getColorForAnimalAdoptionStatus('cannot-be-adopted' as AnimalAdoptionDtoStatus),
                color: isColorDark(getColorForAnimalAdoptionStatus('cannot-be-adopted' as AnimalAdoptionDtoStatus))
                  ? 'white'
                  : 'black',
                cursor: 'pointer',
                width: 220,
              }}
            />
          </Tooltip>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={6} sm={3}>
            <PreConfiguredPieChart data={dataForNewEntryBySpeciePieChart} colors={COLORS} />
            <Typography variant="h6" align="center" marginTop="-10px">
              Nouvelle entrée par animaux
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <PreConfiguredPieChart data={dataForTakeOverReasonPieChart} colors={COLORS} />
            <Typography variant="h6" align="center" marginTop="-10px">
              Motif des abandons
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <PreConfiguredPieChart data={dataForAdoptedBySpeciesPieChart} colors={COLORS} />
            <Typography variant="h6" align="center" marginTop="-10px">
              Adoption par animaux
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <PreConfiguredPieChart data={dataForCityOfOriginPieChart} colors={COLORS} />
            <Typography variant="h6" align="center" marginTop="-10px">
              Ville de prise en charge
            </Typography>
          </Grid>
        </Grid>
        {data?.animalsEnteredByDateCount && (
          <AssociationDashboardEvolution
            entryData={data.animalsEnteredByDateCount}
            exitData={data.animalsExitedByDateCount}
          />
        )}
      </CardContent>
    </Card>
  )
}

interface ToolTipContentProps {
  data: CannotBeAdoptedStatusCountDto[] | undefined
}

const ToolTipContentForNonAdoptableStatus: React.FC<ToolTipContentProps> = ({ data }) => (
  <>
    {data?.map((o) => (
      <div key={o.status}>
        {o.status
          ? getReadableCannotBeAdoptedStatus(o.status as AnimalAdoptionDtoCannotBeAdoptedStatus)
          : 'Motif à définir'}
        : {o.count}
      </div>
    ))}
  </>
)
