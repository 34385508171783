import { Box, Button, Card, CardContent, CardHeader, Modal, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  getReadableAnimalActivity,
  getReadableAnimalCorpulence,
  getReadableAnimalSize,
} from '../../../../domain/Animal/AnimalDisplay'
import {
  AnimalDto,
  AnimalFormAndActivityDto,
  AnimalFormAndActivityDtoActivity,
  AnimalFormAndActivityDtoCorpulence,
  AnimalFormAndActivityDtoSize,
  AnimalWeightDto,
} from '../../../../interactors/gen/backendClient'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { omitEmptyString } from '../../../../utils/omitEmptyString'
import { ControlledSelectField } from '../../../common/ControlledSelectField'
import { AnimalCardSaveButton } from '../AnimalCardSaveButton'
import { AnimalHealthWeight, NewAnimalHealthWeightModal } from './AnimalHealthWeight'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'

interface Props {
  animal: AnimalDto
}

type FormParams = {
  size: AnimalFormAndActivityDtoSize | ''
  corpulence: AnimalFormAndActivityDtoCorpulence | ''
  activity: AnimalFormAndActivityDtoActivity | ''
}

export const AnimalHealthFormAndActivityCard: React.FC<Props> = ({ animal }) => {
  const [weights, setWeights] = useState<AnimalWeightDto[]>(animal?.formAndActivity?.weight || [])

  const canEditAnimal = useCanEditAnimal()

  const editAnimalMutation = useEditAnimalMutation({})

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    ...animal.formAndActivity,
    size: animal.formAndActivity?.size || '',
    corpulence: animal.formAndActivity?.corpulence || '',
    activity: animal.formAndActivity?.activity || '',
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormExtended(animal, getDefaultValues)

  const onSubmit = async (data: FormParams) => {
    if (!isDirty) return
    const formAndActivity: AnimalFormAndActivityDto = {
      size: data.size || undefined,
      corpulence: data.corpulence || undefined,
      activity: data.activity || undefined,
    }

    const update = omitEmptyString({
      ...animal,
      formAndActivity,
    })

    await editAnimalMutation.mutate(update)
  }

  const initialWeightsRef = useRef(animal?.formAndActivity?.weight)

  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return // don't run the effect
    }

    const saveWeights = async () => {
      if (JSON.stringify(weights) !== JSON.stringify(initialWeightsRef.current)) {
        const formAndActivity: AnimalFormAndActivityDto = {
          ...animal.formAndActivity,
          weight: weights || undefined,
        }

        const update = omitEmptyString({
          ...animal,
          formAndActivity,
        })

        await editAnimalMutation.mutate(update)
      }
    }

    saveWeights()
  }, [weights])

  const sortedWeights = [...weights].sort((a, b) => {
    const aDate = a.dateOfWeightIn ? new Date(a.dateOfWeightIn) : new Date(0)
    const bDate = b.dateOfWeightIn ? new Date(b.dateOfWeightIn) : new Date(0)

    return bDate.getTime() - aDate.getTime()
  })

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <CardHeader title="💉 Forme et activité" />
          <CardContent sx={{ height: '100%', pb: '16px !important' }}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 2, height: '100%' }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <ControlledSelectField
                  control={control}
                  error={errors.size}
                  fieldName="size"
                  size="small"
                  label="Taille"
                  options={Object.values(AnimalFormAndActivityDtoSize).map((field) => ({
                    label: getReadableAnimalSize(field),
                    value: field,
                  }))}
                  requiredRule={undefined}
                />
                <ControlledSelectField
                  control={control}
                  error={errors.corpulence}
                  fieldName="corpulence"
                  size="small"
                  label="Corpulence"
                  options={Object.values(AnimalFormAndActivityDtoCorpulence).map((field) => ({
                    label: getReadableAnimalCorpulence(field),
                    value: field,
                  }))}
                  requiredRule={undefined}
                />
                <ControlledSelectField
                  control={control}
                  error={errors.activity}
                  fieldName="activity"
                  size="small"
                  label="Activité"
                  options={Object.values(AnimalFormAndActivityDtoActivity).map((field) => ({
                    label: getReadableAnimalActivity(field),
                    value: field,
                  }))}
                  requiredRule={undefined}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <AnimalCardSaveButton isLoading={editAnimalMutation.isLoading} disabled={!isDirty} />
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  Suivi du poids
                </Typography>

                {weights.length > 0 ? (
                  <>
                    <AnimalHealthWeight
                      weight={sortedWeights[0]}
                      weights={weights}
                      setWeights={setWeights}
                      disabled={!canEditAnimal}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                        <WeightHistoryPopUp weights={sortedWeights} setWeights={setWeights} disabled={!canEditAnimal} />
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                        <NewAnimalHealthWeightModal
                          weights={weights}
                          setWeights={setWeights}
                          disabled={!canEditAnimal}
                        />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <NewAnimalHealthWeightModal
                    weights={weights}
                    setWeights={setWeights}
                    firstWeightIn={true}
                    disabled={!canEditAnimal}
                  />
                )}
              </Box>
            </Box>
          </CardContent>
        </form>
      </Card>
    </>
  )
}

interface PopUpProps {
  weights: AnimalWeightDto[]
  setWeights: (weights: Array<AnimalWeightDto>) => void
  disabled?: boolean
}

const WeightHistoryPopUp: React.FC<PopUpProps> = ({ weights, setWeights, disabled = false }) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: { sm: 4, xs: 2 },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxHeight: '80%',
    maxWidth: { sm: '80%', xs: '95%' },
    overflowY: 'auto',
  }

  return (
    <>
      <Button variant="text" onClick={handleOpen} sx={{ textTransform: 'none', fontSize: 15 }}>
        Voir l&#39;historique
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'left',
              gap: 2,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
              <Typography variant="h6">Historique du poids</Typography>
            </Box>
            {weights.map((weight, index) => (
              <AnimalHealthWeight
                key={index}
                weight={weight}
                weights={weights}
                setWeights={setWeights}
                flexDirection="row"
                disabled={disabled}
              />
            ))}
          </Box>
        </Box>
      </Modal>
    </>
  )
}
