import { DescriptionOutlined } from '@mui/icons-material'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { CustomerDocumentDto } from '../../../interactors/gen/backendClient'
import { getCustomerDocumentsSrc } from '../../../utils/S3-links'
/* import { EditDocumentModal } from './EditDocumentModal' */
import { blueSky, theme } from '../../theme'
import { DeleteDocumentModal } from './DeleteDocumentModal'
import { useAccountsStore } from '../../../store/AccountsStore'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { DisplayChipLikeTextField } from '../../common/DisplayChipLikeTextField'
import {
  getReadableCustomerDocumentType,
  getColorForCustomerDocumentType,
} from '../../../domain/Customer/CustomerDisplay'

interface DocumentItemProps {
  document: CustomerDocumentDto
  setDocumentsList: React.Dispatch<React.SetStateAction<CustomerDocumentDto[]>>
}

export const DocumentItem: React.FC<DocumentItemProps> = ({ document, setDocumentsList }) => {
  const sxField = { cursor: 'disabled', input: { cursor: 'not-allowed' } }
  const accountsStore = useAccountsStore()
  const members = accountsStore.members
  const member = members.find((m) => m.id === document.uploadedBy)

  return (
    <Box mt={2} sx={{ border: { xs: 1, sm: 1, md: 0 }, borderRadius: { xs: 2, md: 0 }, padding: { xs: 2, md: 0 } }}>
      <Grid container spacing={2}>
        <Grid item xs={1.8} sm={0.8}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <DescriptionOutlined sx={{ width: 42, height: 42, color: blueSky }} />
          </Box>
        </Grid>
        <Grid item xs={10.2} sm={5.6} md={3}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <DisplayChipLikeTextField
              label="Nom du document"
              value={document.type === 'others' ? document.name! : getReadableCustomerDocumentType(document.type) || ''}
              fullWidth
              documentPage
              color={getColorForCustomerDocumentType(document.type) || blueSky}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5.6} md={3}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <TextField
              label="Libellé"
              type="text"
              fullWidth
              value={document.reference}
              sx={sxField}
              size="small"
              inputProps={{ readOnly: true }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={2.2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="center"
            height="100%"
            sx={{ color: theme.palette.grey[500] }}
          >
            <Box
              display="flex"
              flexDirection={{ xs: 'row', sm: 'column' }}
              justifyContent={{ xs: 'space-between', sm: 'center' }}
            >
              <Typography variant="body2" fontSize={14}>
                Mis en ligne le {new Date(document.uploadedAt).toLocaleDateString()}
              </Typography>
              {member && (
                <Typography variant="body2" fontSize={14}>
                  Par {fullName(member)}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={5.5} md={2.2}>
          <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center" height="100%">
            <Button variant="contained" href={getCustomerDocumentsSrc(document.documentKey)} target="_blank">
              Consulter
            </Button>
          </Box>
        </Grid>
        {/* Commented as backend is not ready yet */}
        {/* <Grid item xs={3} sm={1.5} md={0.8}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" maxWidth="100%">
            <EditDocumentModal document={document} />
          </Box>
        </Grid> */}
        <Grid item xs={6} sm={1.5} md={0.8}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <DeleteDocumentModal
              documentId={document.id}
              setDocumentsList={setDocumentsList}
              disabled={!accountsStore.isAdmin()}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
