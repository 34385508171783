import React from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

interface PreConfiguredPieChartProps {
  data: { name: string; value: number }[]
  colors: string[]
  displayLegend?: boolean
  displayLabel?: boolean
  CustomTooltip?: React.ComponentType<any>
}

interface RenderLegendProps {
  payload?: { value: string }[]
}

export const PreConfiguredPieChart: React.FC<PreConfiguredPieChartProps> = ({
  data,
  colors,
  displayLegend = false,
  displayLabel = false,
  CustomTooltip,
}) => {
  const renderLegend: React.FC<RenderLegendProps> = ({ payload }) => (
    <ul
      style={{
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        fontSize: '15px',
        whiteSpace: 'nowrap',
        overflow: 'ellipsis',
      }}
    >
      {payload?.map((entry, index: number) => (
        <li key={`item-${index}`} style={{ color: colors[index] }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '10px', height: '10px', backgroundColor: colors[index], marginRight: '5px' }} />
            {entry.value}
          </div>
        </li>
      ))}
    </ul>
  )

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={300} height={300}>
        <defs>
          {colors.map((color, index) => (
            <linearGradient key={color + index} id={`gradient${index}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8} />
              <stop offset="95%" stopColor={color} stopOpacity={0.5} />
            </linearGradient>
          ))}
        </defs>
        <Pie
          dataKey="value"
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={25}
          outerRadius={70}
          paddingAngle={5}
          labelLine={false}
          label={
            displayLabel &&
            ((entry) => {
              if (entry.percent < 0.1) {
                return null
              }
              return `${entry.name}: ${entry.value}`
            })
          }
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={`url(#gradient${index})`} />
          ))}
        </Pie>
        {displayLegend && (
          <Legend
            content={renderLegend}
            align="left"
            payload={data.map((item, index) => ({
              id: item.name,
              type: 'square',
              value: item.name,
              color: colors[index % colors.length],
            }))}
          />
        )}
        {CustomTooltip ? <Tooltip content={<CustomTooltip />} /> : <Tooltip />}
      </PieChart>
    </ResponsiveContainer>
  )
}
