import { AnimalAdoptionDtoCannotBeAdoptedStatus, AnimalAdoptionDtoStatus } from '../interactors/gen/backendClient'

export const sortAlphabetically = <T extends object>(
  a: T,
  b: T,
  sortDirection: 'asc' | 'desc',
  ...keys: (keyof T)[]
): -1 | 0 | 1 => {
  let result: -1 | 0 | 1 = 0
  let i = 0
  while (result === 0 && i < keys.length) {
    const key = keys[i]
    const stringA = String(a[key]).toLowerCase()
    const stringB = String(b[key]).toLowerCase()

    if (stringA !== stringB) {
      result = sortDirection === 'asc' ? (stringA > stringB ? 1 : -1) : stringA > stringB ? -1 : 1
    }
    i++
  }

  if (result === 0) return 1

  return result
}

export const sortProfileHasBeenAccepted = <T extends object>(
  a: T,
  b: T,
  sortDirection: 'asc' | 'desc',
  ...keys: (keyof T)[]
): -1 | 0 | 1 => {
  const order: { [key: string]: number } = {
    waiting: 1,
    'form-accepted': 2,
    'form-sent': 3,
    'form-refused': 4,
    'form-to-sent': 5,
    refused: 6,
    accepted: 7,
  }

  let result: -1 | 0 | 1 = 0
  let i = 0
  while (result === 0 && i < keys.length) {
    const key = keys[i]
    const stringA = String(a[key])
    const stringB = String(b[key])

    if (stringA !== stringB) {
      result =
        sortDirection === 'asc' ? (order[stringA] > order[stringB] ? 1 : -1) : order[stringA] > order[stringB] ? -1 : 1
    }
    i++
  }

  if (result === 0) return 1

  return result
}

export const sortByAdoptionStatus = <
  T extends {
    adoptionStatus: AnimalAdoptionDtoStatus
    cannotBeAdoptedStatus?: AnimalAdoptionDtoCannotBeAdoptedStatus
    name: string
  }
>(
  a: T,
  b: T,
  sortDirection: 'asc' | 'desc'
): -1 | 0 | 1 => {
  const adoptionStatusOrder: Record<AnimalAdoptionDtoStatus, number> = {
    [AnimalAdoptionDtoStatus.ToBeFilled]: 0,
    [AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable]: 1,
    [AnimalAdoptionDtoStatus.Adoptable]: 4,
    [AnimalAdoptionDtoStatus.CannotBeAdopted]: 5,
    [AnimalAdoptionDtoStatus.HasBeenAdopted]: 6,
  }

  const cannotBeAdoptedStatusOrder: Partial<Record<AnimalAdoptionDtoCannotBeAdoptedStatus, number>> = {
    [AnimalAdoptionDtoCannotBeAdoptedStatus.InCare]: 2,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.BeingSocialized]: 3,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.Lost]: 7,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation]: 8,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.Dead]: 9,
  }

  let orderA = adoptionStatusOrder[a.adoptionStatus] || 10
  let orderB = adoptionStatusOrder[b.adoptionStatus] || 10

  // If status is 'CannotBeAdopted', check the specific 'cannotBeAdoptedStatus'
  if (a.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted && a.cannotBeAdoptedStatus) {
    orderA = cannotBeAdoptedStatusOrder[a.cannotBeAdoptedStatus] || 5
  }

  if (b.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted && b.cannotBeAdoptedStatus) {
    orderB = cannotBeAdoptedStatusOrder[b.cannotBeAdoptedStatus] || 5
  }

  if (orderA < orderB) {
    return sortDirection === 'asc' ? -1 : 1
  }
  if (orderA > orderB) {
    return sortDirection === 'asc' ? 1 : -1
  }

  // if status order is the same, then compare by name
  if (a.name < b.name) {
    return sortDirection === 'asc' ? -1 : 1
  }
  if (a.name > b.name) {
    return sortDirection === 'asc' ? 1 : -1
  }

  return 0
}
