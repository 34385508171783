import create from 'zustand'
import { DonatersClient } from '../interactors/clients/DonatersClient'
import { Donor } from '../interactors/gen/backendClient'

interface DonatersStore {
  donaters: Donor[]
  fetchDonaters: () => Promise<void>
  fetchOrRefreshDonaters: () => Promise<void>
  initializeDonatersIfNeeded: () => Promise<void>
  refreshDonaters: () => Promise<void>
  isLoading: boolean
  areDonatersInitialized: boolean
}

export const useDonatersStore = create<DonatersStore>()((set, get) => ({
  isLoading: false,
  donaters: [],
  fetchDonaters: async () => {
    set({ isLoading: true })

    const adoptersFetched = await DonatersClient.getAllDonaters()
    set({ donaters: adoptersFetched })
    set({ isLoading: false })
    set({ areDonatersInitialized: true })
  },
  refreshDonaters: async () => {
    const refreshedDonaters = await DonatersClient.getAllDonaters()
    set({ donaters: refreshedDonaters })
  },
  fetchOrRefreshDonaters: async () => {
    if (get().areDonatersInitialized) {
      await get().refreshDonaters()
    } else {
      await get().fetchDonaters()
    }
  },
  initializeDonatersIfNeeded: async () => {
    if (!get().areDonatersInitialized) {
      console.log('Loading donaters')
      await get().fetchDonaters()
      console.log('Donaters loaded')
    }
  },
  areDonatersInitialized: false,
}))
