import { Add, Delete } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, InputAdornment, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useForm } from 'react-hook-form'
import { AnimalWeightDto } from '../../../../interactors/gen/backendClient'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { isValidPastDateCoherence } from '../../../../utils/date/isValidPastDateCoherence'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { ControlledDateField } from '../../../common/ControlledDateField'

interface Props {
  weight: AnimalWeightDto
  weights: AnimalWeightDto[]
  setWeights: (weights: Array<AnimalWeightDto>) => void
  flexDirection?: 'row' | 'column'
  disabled?: boolean
}

export const AnimalHealthWeight: React.FC<Props> = ({
  weight,
  weights,
  setWeights,
  flexDirection = 'column',
  disabled = false,
}) => {
  const sxField = { cursor: 'disabled', input: { cursor: 'not-allowed' } }

  const handleClickOnDelete = () => {
    setWeights(weights.filter((weightFromList) => weightFromList !== weight))
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <Box sx={{ display: 'flex', flexDirection, gap: 2 }}>
          <TextField
            label="Poids"
            type="text"
            fullWidth
            value={weight.weightInKgs || '..'}
            sx={sxField}
            size="small"
            InputProps={{ readOnly: true, endAdornment: <InputAdornment position="end">kg</InputAdornment> }}
          />
          <TextField
            label="Date"
            type="text"
            fullWidth
            value={weight.dateOfWeightIn ? dayjs(weight.dateOfWeightIn).format('DD/MM/YYYY') : '..'}
            sx={sxField}
            size="small"
            inputProps={{ readOnly: true }}
          />
        </Box>

        <Box display="flex" alignItems="center" sx={{ width: 'min-content', justifyContent: 'center' }}>
          <Button onClick={handleClickOnDelete} sx={{ color: 'error.main' }} disabled={disabled}>
            <Delete />
          </Button>
        </Box>
      </Box>
    </>
  )
}

interface NewWeightProps {
  weights: AnimalWeightDto[]
  setWeights: (weights: Array<AnimalWeightDto>) => void
  firstWeightIn?: boolean
  disabled?: boolean
}

type FormParams = {
  dateOfWeightIn: string
  weightInKgs: string
}

export const NewAnimalHealthWeightModal: React.FC<NewWeightProps> = ({
  weights,
  setWeights,
  firstWeightIn = false,
  disabled = false,
}) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const canEditAnimal = useCanEditAnimal()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormParams>({
    defaultValues: {
      dateOfWeightIn: '',
      weightInKgs: '',
    },
    mode: 'onChange',
  })

  const onSubmit = (data: FormParams) => {
    const newWeight = {
      ...data,
    }
    setWeights([...weights, newWeight as AnimalWeightDto])
    closeDialog()
    setValue('dateOfWeightIn', '')
    setValue('weightInKgs', '')
  }

  if (!canEditAnimal) {
    return null
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={openDialog}
        disableElevation
        fullWidth={firstWeightIn}
        disabled={disabled}
      >
        <Add sx={{ mr: firstWeightIn ? 1 : 0 }} />
        {firstWeightIn && 'Ajouter la première pesée'}
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                gap: 2,
                paddingX: 4,
              }}
            >
              <Typography variant="h4" component="h2" marginBottom={4}>
                Ajouter une nouvelle pesée
              </Typography>

              <ControlledDateField
                control={control}
                validate={(value) => isValidPastDateCoherence(value) || !value}
                error={errors.dateOfWeightIn}
                fieldName={'dateOfWeightIn'}
                label="Date de la pesée"
                requiredRule={undefined}
              />

              <TextField
                id="additional-info-input"
                label="Poids"
                type="number"
                inputProps={{
                  step: '0.001',
                  inputMode: 'decimal',
                  pattern: '[0-9]*',
                }}
                fullWidth
                {...register('weightInKgs')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <Button data-testid="apply-changes" variant="contained" type="submit">
              Valider
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
