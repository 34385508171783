import {
  FormControl,
  FormHelperText,
  SxProps,
  Theme,
  InputLabel,
  MenuItem,
  Select,
  BaseTextFieldProps,
  Chip,
  Box,
} from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { setOpacity } from '../../utils/setOpacity'
import { isColorDark } from '../../utils/isColorDark'

interface Option {
  label: string
  value: string | number
  color: string
}

type Props<T extends FieldValues> = {
  sx?: SxProps<Theme>
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Array<Option>
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
}

export function ColoredChipSelectField<T extends FieldValues>({
  sx,
  control,
  options,
  error,
  fieldName,
  label,
  requiredRule,
  size,
  disabled,
}: PropsWithChildren<Props<T>>) {
  const labelId = `${fieldName}_label`

  return (
    <FormControl fullWidth variant="outlined" sx={sx} required={Boolean(requiredRule)}>
      <InputLabel htmlFor={labelId} error={!!error} size={size === 'small' ? 'small' : 'normal'}>
        {label}
      </InputLabel>
      <Controller
        name={fieldName}
        control={control}
        rules={{ required: requiredRule }}
        defaultValue={'' as any} // TODO: check this
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            onChange={onChange}
            label={label}
            labelId={labelId}
            error={!!error}
            required={Boolean(requiredRule)}
            size={size}
            disabled={disabled}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {options.map(
                  (option) =>
                    // Find the corresponding option object based on the value
                    selected === option.value && (
                      // If an option is found, use its label; otherwise, use the value as a fallback
                      <Chip
                        key={option.value}
                        label={option.label}
                        // Used this dirty hack to fix the padding of the select to be the same as the other inputs
                        sx={{
                          marginY: '-4.5px !important',
                          marginTop: '-1.5px',
                          backgroundColor: setOpacity(option.color, 0.8),
                          color: isColorDark(option.color) ? '#fff' : '#000',
                          height: '28px',
                          '& .MuiChip-label': {
                            lineHeight: '24px',
                          },
                        }}
                      />
                    )
                )}
              </Box>
            )}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                <Chip
                  label={option.label}
                  sx={{
                    backgroundColor: setOpacity(option.color, 0.8),
                    color: isColorDark(option.color) ? '#fff' : '#000',
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        )}
      />

      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  )
}
