import { Card, CardHeader, CardContent } from '@mui/material'
import React, { useEffect } from 'react'
import { spacingItem } from '../../theme'
import { MemberAvailabilityCalendar } from './MemberAvailabilityCalendar'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { NewAvailabilityPrompt } from './NewAvailabilityPrompt'
import { AccountDtoAvailability } from '../../../interactors/gen/backendClient'
import { omitEmptyString } from '../../../utils/omitEmptyString'
import { EditAvailabilityPrompt } from './EditAvailabilityPrompt'

interface Props {
  initialAvailabilities: AccountDtoAvailability[]
  onSubmit: (data: { availability: AccountDtoAvailability[] }) => Promise<void>
  isOwnAccount: boolean
}

export const MemberAvailabilityTab: React.FC<Props> = ({ initialAvailabilities, onSubmit, isOwnAccount }) => {
  const [availabilities, setAvailabilities] = React.useState<AccountDtoAvailability[]>(initialAvailabilities)
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(null)
  const [selectedAvailability, setSelectedAvailability] = React.useState<AccountDtoAvailability | null>(null)
  const dialogOpenedStateForNewAvailability = useDialogOpened()
  const dialogOpenedStateForEditAvailability = useDialogOpened()

  useEffect(() => {
    const saveAvailabilities = async () => {
      // only save if vaccinations have been modified
      if (JSON.stringify(availabilities) !== JSON.stringify(initialAvailabilities)) {
        const update = omitEmptyString({
          availability: availabilities,
        })

        await onSubmit(update)
      }
    }

    saveAvailabilities()
  }, [availabilities, initialAvailabilities])

  return (
    <Card sx={spacingItem}>
      <CardHeader title="Disponibilité" />
      <CardContent sx={{ p: { xs: 0, sm: '16px' } }}>
        <MemberAvailabilityCalendar
          periods={availabilities}
          newDialogOpenedState={dialogOpenedStateForNewAvailability}
          editDialogOpenedState={dialogOpenedStateForEditAvailability}
          setSelectedStartDate={setSelectedStartDate}
          setSelectedAvailability={setSelectedAvailability}
          isOwnAccount={isOwnAccount}
        />
        <NewAvailabilityPrompt
          dialogOpenedState={dialogOpenedStateForNewAvailability}
          availabilities={availabilities}
          setAvailabilities={setAvailabilities}
          selectedStartDate={selectedStartDate}
        />
        <EditAvailabilityPrompt
          dialogOpenedState={dialogOpenedStateForEditAvailability}
          availabilities={availabilities.filter((availability) => availability !== selectedAvailability)}
          setAvailabilities={setAvailabilities}
          availability={selectedAvailability}
        />
      </CardContent>
    </Card>
  )
}
