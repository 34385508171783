import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import { AdoptionFormParams } from './AnimalAdoptionTab'

interface Props {
  isOpen: boolean
  onClose: () => void
  handleSubmit: UseFormHandleSubmit<AdoptionFormParams>
  onSubmit: (data: AdoptionFormParams, confirmation?: boolean) => void
  alreadyExistingAttempt: boolean
  oldAdopterName?: string
}

export const CreateAdoptionAttemptConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  handleSubmit,
  onSubmit,
  alreadyExistingAttempt,
  oldAdopterName,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      fullWidth
      scroll="paper"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
    >
      <DialogTitle>Êtes vous sûr de vouloir continuer ?</DialogTitle>

      <Box
        sx={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          boxSizing: 'content-box',
        }}
      >
        <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
          {alreadyExistingAttempt ? (
            <span>
              <b>
                Vous êtes sur le point de suspendre une procédure d’adoption.
                <br />
                Si vous continuer la procédure auprès de {oldAdopterName} sera suspendue.
              </b>
              <br />
              Le profil du nouvel adoptant sera alors validé et un email pour démarrer la procédure lui sera envoyé
            </span>
          ) : (
            <span>
              Si le profil de l&#39;adoptant n&#39;est pas validé et que vous souhaitez continuer, son statut sera
              automatiquement mis à jour.
              <br />
              Une nouvelle procédure d&#39;adoption sera créée et un email sera envoyé à l&#39;adoptant.
            </span>
          )}
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="outlined" type="button" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="button" onClick={handleSubmit((data) => onSubmit(data, true))}>
            Continuer
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
