import { Edit } from '@mui/icons-material'
import { IconButton, Dialog, DialogTitle, Box, DialogContent, DialogActions, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import { AccountDtoRoles, CustomerDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { MemberPromptSaveButton } from '../../Members/MemberPromptSaveButton'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { PATHS } from '../../PATHS'
import { useNavigate } from 'react-router-dom'

interface Props {
  association: CustomerDto
  onSubmit: (data: Partial<CustomerDto>) => void
}

export const EditPetsoContactPrompt: React.FC<Props> = ({ association, onSubmit }) => {
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()

  const getDefaultValues = (association: CustomerDto | null): Partial<CustomerDto> => ({
    masterAccountId: queryParams.get('memberInCharge') || association?.masterAccountId || '',
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useFormExtended(association, getDefaultValues)

  useEffect(() => {
    if (queryParams.get('card') === 'editPetsoContact') {
      dialogOpenedState.openDialog()
    }
  }, [])

  const masterAccountId = watch('masterAccountId')
  useEffect(() => {
    if (masterAccountId === 'addAccount') {
      reset({ masterAccountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=association?tab=my-association&role=${AccountDtoRoles.Member}&card=editPetsoContact`
      )
    }
  }, [masterAccountId])

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
        <Tooltip title="Editer le point de contact Petso">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Editer le point de contact Petso</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}>
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'masterAccountId'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un bénévole' },
                  ...accountsStore.members
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Point de contact avec Petso"
                error={errors.masterAccountId}
                requiredRule="Le point de contact avec Petso est requis"
                defaultValue={association?.masterAccountId || ''}
                highlight
              />
            </DialogContent>

            <DialogActions>
              <MemberPromptSaveButton isOwnAccount={false} isLoading={false} dialogOpenedState={dialogOpenedState} />
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
