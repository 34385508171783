import {
  AccountDtoPreferences,
  AccountDtoPreferencesNeedsBottleFeeding,
  AccountDtoPreferencesSeniority,
  AccountDtoPreferencesSex,
  AccountDtoPreferencesSterilizedOrNeutered,
  AccountDtoPreferencesVaccinated,
} from '../../interactors/gen/backendClient'

export const translateAccountPreferencesSex = (
  field: AccountDtoPreferencesSex,
  noPreferenceFields: string[]
): string => {
  const map: Record<AccountDtoPreferencesSex, string> = {
    [AccountDtoPreferencesSex.Female]: 'Femelle',
    [AccountDtoPreferencesSex.Male]: 'Mâle',
    [AccountDtoPreferencesSex.NoPreference]: noPreferenceFields.length > 1 ? '' : 'Sexe: pas de préférence',
  }

  return map[field]
}

export const translateAccountPreferencesSeniority = (
  field: AccountDtoPreferencesSeniority,
  noPreferenceFields: string[]
): string => {
  const map: Record<AccountDtoPreferencesSeniority, string> = {
    [AccountDtoPreferencesSeniority.LessThanOneMonth]: '< 1 mois',
    [AccountDtoPreferencesSeniority.OneToSixMonths]: '1 à 4 mois',
    [AccountDtoPreferencesSeniority.MoreThanSixMonths]: 'Adolescent',
    [AccountDtoPreferencesSeniority.Senior]: 'Sénior',
    [AccountDtoPreferencesSeniority.Adult]: 'Adulte',
    [AccountDtoPreferencesSeniority.NoPreference]: noPreferenceFields.length > 1 ? '' : 'Âge: pas de préférence',
    [AccountDtoPreferencesSeniority.PregnantFemale]: 'Femelle gestante',
    [AccountDtoPreferencesSeniority.MotherWithBabies]: 'Mère avec bébés',
  }

  return map[field]
}

export const translateAccountPreferencesNeedsBottleFeeding = (
  field: AccountDtoPreferencesNeedsBottleFeeding,
  noPreferenceFields: string[]
): string => {
  const map: Record<AccountDtoPreferencesNeedsBottleFeeding, string> = {
    [AccountDtoPreferencesNeedsBottleFeeding.True]: 'Avec biberonnage',
    [AccountDtoPreferencesNeedsBottleFeeding.False]: 'Sans biberonnage',
    [AccountDtoPreferencesNeedsBottleFeeding.NoPreference]:
      noPreferenceFields.length > 1 ? '' : 'Biberonnage : pas de préférence',
  }

  return map[field]
}

export const translateAccountPreferencesVaccinated = (
  field: AccountDtoPreferencesVaccinated,
  noPreferenceFields: string[]
): string => {
  const map: Record<AccountDtoPreferencesVaccinated, string> = {
    [AccountDtoPreferencesVaccinated.True]: 'Vacciné',
    [AccountDtoPreferencesVaccinated.False]: 'Non vacciné',
    [AccountDtoPreferencesVaccinated.NoPreference]: noPreferenceFields.length > 1 ? '' : 'Vaccin: pas de préférence',
  }

  return map[field]
}

export const translateAccountPreferencesSterilizedOrNeutered = (
  field: AccountDtoPreferencesSterilizedOrNeutered,
  noPreferenceFields: string[]
): string => {
  const map: Record<AccountDtoPreferencesSterilizedOrNeutered, string> = {
    [AccountDtoPreferencesSterilizedOrNeutered.True]: 'Stérilisé ou castré',
    [AccountDtoPreferencesSterilizedOrNeutered.False]: 'Non stérilisé ou Non castré',
    [AccountDtoPreferencesSterilizedOrNeutered.NoPreference]:
      noPreferenceFields.length > 1 ? '' : 'Stérilisation/Castration: pas de préférence',
  }

  return map[field]
}

export const collectNoPreferences = (preference: AccountDtoPreferences | null | undefined): string[] => {
  const noPreferenceFields = []

  if (preference?.sex === AccountDtoPreferencesSex.NoPreference) {
    noPreferenceFields.push('Sexe')
  }

  if (preference?.seniority?.includes(AccountDtoPreferencesSex.NoPreference)) {
    noPreferenceFields.push('Âge')
  }

  if (preference?.needsBottleFeeding === AccountDtoPreferencesNeedsBottleFeeding.NoPreference) {
    noPreferenceFields.push('Biberonnage')
  }

  if (preference?.vaccinated === AccountDtoPreferencesVaccinated.NoPreference) {
    noPreferenceFields.push('Vaccin')
  }

  if (preference?.sterilizedOrNeutered === AccountDtoPreferencesSterilizedOrNeutered.NoPreference) {
    noPreferenceFields.push('Stérilisation/Castration')
  }

  return noPreferenceFields
}
