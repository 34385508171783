import { Avatar, Box, Button, Card, CardContent, Collapse, Divider, Grid, Typography } from '@mui/material'
import {
  AccountDto,
  AccountDtoHomeHasIsolationRoom,
  AccountDtoPreferencesWillingToSocializeWildAnimals,
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoStatus,
  AnimalSearchItemDto,
} from '../../interactors/gen/backendClient'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { getAccountProfilePhotoSrc } from '../../utils/S3-links'
import { getReadableAccountPermission, getSmallReadableAccountRole } from '../../domain/Account/AccountDisplay'
import { AnimalProfileChip } from './MembersListScreen'
import { LoadingButton } from '@mui/lab'
import { Verified } from '@mui/icons-material'
import { theme } from '../theme'

type CardProps = {
  member: AccountDto
  bgColor?: string
  index: number
  expandCardIndex: number | null
  setExpandCardIndex: React.Dispatch<React.SetStateAction<number | null>>
}

const AnimalChipSxProps = {
  fontSize: 14,
  size: 'small',
  backgroundColor: 'white',
}
const AnimalAvatarSxProps = {
  size: 'small',
}

const MemberListCard: React.FC<CardProps> = ({ member, bgColor, index, expandCardIndex, setExpandCardIndex }) => {
  const navigate = useNavigate()
  const [animals, setAnimals] = React.useState<AnimalSearchItemDto[] | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [howManyAnimals, setHowManyAnimals] = React.useState<number>(0)
  const [firstLevelExpanded, setFirstLevelExpanded] = React.useState<boolean>(false)
  const [secondLevelExpanded, setSecondLevelExpanded] = React.useState<boolean>(false)

  const handleClick = () => {
    navigate(`${member.id}`)
  }
  const handleFirstExpandClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!animals) {
      await fetchAnimals()
    }
    setExpandCardIndex(index)
    setFirstLevelExpanded((prev) => !prev)
    if (firstLevelExpanded) {
      setSecondLevelExpanded(false)
    }
  }
  const handleSecondExpandClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setSecondLevelExpanded((prev) => !prev)
  }

  useEffect(() => {
    setFirstLevelExpanded(index === expandCardIndex)
    setSecondLevelExpanded(false)
  }, [expandCardIndex])

  const fetchAnimals = async () => {
    if (animals) return
    setIsLoading(true)
    const fetchedAnimals = await AnimalsClient.getAllAnimalForHostFamilyAccountId(member.id)
    const filteredAnimals = fetchedAnimals.filter(
      (animal) =>
        animal.responsibilities?.hostFamilyInChargeAccountId === member.id &&
        animal.adoptionStatus !== AnimalAdoptionDtoStatus.HasBeenAdopted &&
        !(
          animal.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted &&
          (animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Dead ||
            animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Released ||
            animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation ||
            animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Lost)
        )
    )
    setAnimals(filteredAnimals)
    setHowManyAnimals(filteredAnimals.length)
    setIsLoading(false)
  }

  const profileImageKey = member.images?.profileImageKey

  return (
    <Card
      variant="outlined"
      onClick={handleClick}
      sx={{
        backgroundColor: bgColor,
        borderRadius: '25px',
        border: '2px solid #D2D2D2',
        boxShadow: 'none',
      }}
    >
      <CardContent sx={{ paddingRight: '25px' }}>
        {/* Avatar, [last name, first name], [roles], permission   */}
        <Box
          display="flex"
          gap="15px"
          sx={{
            padding: '5px',
            marginBottom: '5px',
          }}
        >
          {/* Avatar */}
          <Avatar
            alt={member.firstName}
            src={profileImageKey && getAccountProfilePhotoSrc(profileImageKey)}
            sx={{
              height: '80px',
              width: '80px',
            }}
          />
          {/* [last name, first name], [roles], permission */}
          <Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {/* last name, first name */}
              <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
                {member.firstName} {member.lastName}
              </Typography>
              {member.validated && <Verified sx={{ color: theme.palette.info.main }} />}
            </Box>

            {/* roles */}
            <Typography sx={{ fontSize: '16px' }}>
              {member.roles.map((role) => getSmallReadableAccountRole(role)).join(', ')}
            </Typography>
            {/* permission */}
            <Typography color="secondary" sx={{ fontSize: '16px', fontWeight: 700 }}>
              {getReadableAccountPermission(member.permission)}
            </Typography>
          </Box>
        </Box>

        {/* Collapse (1st level) containing: capacity infos, 2 1st animals chips, ??? 2nd level collapse (other animals) ??? */}
        <Collapse in={firstLevelExpanded}>
          {/* Capacity infos */}
          <Typography>
            <span
              style={{
                fontSize: '16px',
                fontWeight: 700,
                textDecoration: 'underline',
              }}
            >
              A charge
            </span>{' '}
            -{' '}
            <span style={{ fontSize: '15px', fontStyle: 'italic' }}>
              capacité accueil: {howManyAnimals}/{member.home?.maxCapacity || 'NC'}
            </span>
          </Typography>
          {/* 2 1st animals chips */}
          {animals && animals.length > 0 && (
            <Grid
              container
              spacing={1}
              sx={{
                paddingY: '5px',
              }}
            >
              {animals.slice(0, 2).map((animal) => {
                return (
                  <Grid item key={animal.id} xs={animals.length === 1 ? 12 : 6}>
                    <AnimalProfileChip animal={animal} chipSx={AnimalChipSxProps} avatarSx={AnimalAvatarSxProps} />
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Collapse>

        {/* Collapse (2nd level): more than 2 animals chips */}
        <Collapse in={secondLevelExpanded}>
          {animals && animals.length > 2 && (
            <Grid
              container
              spacing={1}
              sx={{
                paddingBottom: '5px',
              }}
            >
              {animals.slice(2).map((animal) => {
                return (
                  <Grid item key={animal.id}>
                    <AnimalProfileChip animal={animal} chipSx={AnimalChipSxProps} avatarSx={AnimalAvatarSxProps} />
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Collapse>

        {/* Collapse buttons */}
        <Box display="flex" justifyContent="center" gap="10px" sx={{ paddingY: '5px' }}>
          {/* 2nd level button */}
          {animals && animals.length > 2 && firstLevelExpanded && (
            <Button
              variant="contained"
              color="secondary"
              disableElevation={true}
              sx={{
                textTransform: 'none',
                height: '25px',
                paddingX: '25px',
                paddingBottom: '8px',
              }}
              onClick={(e) => {
                handleSecondExpandClick(e)
              }}
            >
              {!secondLevelExpanded ? 'Voir plus' : 'Voir moins'}
            </Button>
          )}
          {/* 1st level button */}
          <LoadingButton
            onClick={handleFirstExpandClick}
            loading={isLoading}
            variant="contained"
            color="secondary"
            disableElevation={true}
            sx={{
              textTransform: 'none',
              height: '25px',
              paddingX: firstLevelExpanded ? '25px' : 'x',
              paddingBottom: '8px',
              width: !firstLevelExpanded || (firstLevelExpanded && animals && animals.length <= 2) ? '100%' : 'x',
            }}
            aria-label="show more"
          >
            {!firstLevelExpanded ? 'Voir tous les animaux à charge' : 'Masquer'}
          </LoadingButton>
        </Box>

        {/* Home surface, Hors asso */}
        <Box display="flex" flexWrap="nowrap" overflow="hidden">
          <Box display="flex" gap="5px">
            <Typography fontSize="15px" fontStyle="italic" noWrap>
              Surface
            </Typography>
            <Typography fontSize="15px" fontStyle="italic" sx={{ whiteSpace: 'nowrap' }}>
              : {member.home?.homeSurface ?? 'NC'} {member.home?.homeSurface ? 'm²' : ''}
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginX: '5px',
              backgroundColor: 'black',
            }}
          />
          <Box display="flex" gap="5px" overflow="hidden">
            <Typography noWrap fontSize="15px" fontStyle="italic">
              Animaux hors asso
            </Typography>
            <Typography fontSize="15px" fontStyle="italic" sx={{ whiteSpace: 'nowrap' }}>
              : <b>{member.home?.pets?.length && member.home.pets.length > 0 ? 'Oui' : 'Non'}</b>
            </Typography>
          </Box>
        </Box>

        {/* "Possibilités, [Isolation, medical possibilities] */}
        <Box marginTop={2}>
          <Typography fontSize="16px" fontWeight={700} sx={{ textDecoration: 'underline' }}>
            Possibilités
          </Typography>
          <Typography fontSize="15px" fontStyle="italic">
            {' '}
            Isolement :
            <span style={{ fontWeight: 700 }}>
              {' '}
              {member.home?.hasIsolationRoom === AccountDtoHomeHasIsolationRoom.Yes ? 'Oui' : 'Non'}
            </span>{' '}
            | Soin :{' '}
            <span style={{ fontWeight: 700 }}>
              {' '}
              {member.preferences.primaryPreferences?.withMedicalAntecedent ===
              AccountDtoPreferencesWillingToSocializeWildAnimals.Yes
                ? 'Oui'
                : 'Non'}
            </span>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default MemberListCard
