import { useState } from 'react'

export interface DialogOpenedStore {
  isDialogOpen: boolean
  openDialog: () => void
  closeDialog: () => void
}

export const useDialogOpened = (): DialogOpenedStore => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const openDialog = (): void => setIsDialogOpen(true)
  const closeDialog = (): void => setIsDialogOpen(false)

  return { isDialogOpen, openDialog, closeDialog }
}
