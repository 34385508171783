import React, { useEffect, useState } from 'react'
import { AccountDtoPermission, CustomerDto, DonationTrackingDto } from '../../../interactors/gen/backendClient'
import { AssociationDonationDashboard } from './AssociationDonationDashboard'
import { Box, Fab, Grid, Tooltip } from '@mui/material'
import { AssociationDonationTracking } from './AssociationDonationTracking'
import { DonationTrackingsClient } from '../../../interactors/clients/DonationTrackingClient'
import { useMutation } from 'react-query'
import { Add } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { PATHS } from '../../PATHS'
import dayjs from 'dayjs'
import { AssociationDonationFormTabs } from './AssociationDonatrionFormTabs'
import { ExportPDFFiscalReportModal } from './ExportPDFFiscalReportModal'
import { useAccountsStore } from '../../../store/AccountsStore'

interface AssociationDonationTrackingTabProps {
  association: CustomerDto
}

const donationsTemplate: DonationTrackingDto[] = [
  {
    id: 'DT-1000',
    donaterId: 'D-1005',
    dateCollected: '2023-07-30T22:00:00.000Z',
    amount: 42,
    formOfDonation: 'deed',
    typeOfDonation: 'cash',
    methodOfPayment: 'cash',
    description: 'De la bonne thunasse',
    cgi: '200',
    customerId: 'C-1002',
  },
  {
    id: 'DT-1001',
    donaterId: 'D-1000',
    dateCollected: '2023-07-31T22:00:00.000Z',
    amount: 250,
    formOfDonation: 'declaration-of-manual-donation',
    typeOfDonation: 'cash',
    methodOfPayment: 'wire-transfer',
    description: 'Oui',
    cgi: '200',
    customerId: 'C-1002',
  },
  {
    id: 'DT-1002',
    donaterId: 'D-1001',
    dateCollected: '2023-07-05T22:00:00.000Z',
    amount: 52,
    formOfDonation: 'private-deed',
    typeOfDonation: 'food',
    methodOfPayment: 'bank-card',
    description: 'Oui je donne des aliments par carte',
    cgi: '200',
    customerId: 'C-1002',
  },
  {
    id: 'DT-1003',
    donaterId: 'D-1006',
    dateCollected: '2023-07-19T22:00:00.000Z',
    amount: 17,
    formOfDonation: 'other',
    typeOfDonation: 'equipment',
    methodOfPayment: 'cheque',
    description: 'Canard',
    cgi: '238',
    customerId: 'C-1002',
  },
  {
    id: 'DT-1004',
    donaterId: 'D-1007',
    dateCollected: '2023-08-02T22:00:00.000Z',
    amount: 42,
    formOfDonation: 'private-deed',
    typeOfDonation: 'cash',
    methodOfPayment: 'bank-card',
    description: 'Merci',
    cgi: '238',
    customerId: 'C-1002',
  },
  {
    id: 'DT-1005',
    donaterId: 'D-1008',
    dateCollected: '2023-08-03T22:00:00.000Z',
    amount: 42,
    formOfDonation: 'declaration-of-manual-donation',
    typeOfDonation: 'equipment',
    methodOfPayment: 'cheque',
    description: 'oui',
    cgi: '238',
    customerId: 'C-1002',
  },
  {
    id: 'DT-1006',
    donaterId: 'D-1009',
    dateCollected: '2023-07-31T22:00:00.000Z',
    amount: 116,
    formOfDonation: 'declaration-of-manual-donation',
    typeOfDonation: 'other',
    methodOfPayment: 'wire-transfer',
    description: 'test',
    cgi: '238',
    customerId: 'C-1002',
  },
  {
    id: 'DT-1007',
    donaterId: 'D-1002',
    dateCollected: '2023-07-31T22:00:00.000Z',
    amount: 13,
    formOfDonation: 'deed',
    typeOfDonation: 'cash',
    methodOfPayment: 'wire-transfer',
    description: '',
    cgi: '238',
    customerId: 'C-1002',
  },
  {
    id: 'DT-1008',
    donaterId: 'D-1008',
    dateCollected: '2023-08-01T22:00:00.000Z',
    amount: 112,
    formOfDonation: 'deed',
    typeOfDonation: 'equipment',
    methodOfPayment: 'cash',
    description: '',
    cgi: '238',
    customerId: 'C-1002',
  },
]

export const AssociationDonationTrackingTab: React.FC<AssociationDonationTrackingTabProps> = ({ association }) => {
  const [donationForFiscalReport, setDonationForFiscalReport] = React.useState<DonationTrackingDto | null>(null)
  const [isExportPDFFiscalReportModalOpen, setIsExportPDFFiscalReportModalOpen] = React.useState(false)
  const [selectedDonation, setSelectedDonation] = React.useState<DonationTrackingDto | undefined>(undefined)
  const [donations, setDonations] = React.useState<DonationTrackingDto[]>([])
  const [isTemplate, setIsTemplate] = React.useState<boolean>(true)
  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs | null>(null)
  const [dateTo, setDateTo] = useState<dayjs.Dayjs | null>(null)
  const [filteredDonations, setFilteredDonations] = useState<DonationTrackingDto[]>(donations)
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read
  const loadDonationsMutation = useMutation(
    async () => {
      return await DonationTrackingsClient.getAllDonations()
    },
    {
      onSuccess: (data) => {
        setDonations(data)
      },
    }
  )

  useEffect(() => {
    if (accountsStore.isPremium()) {
      setIsTemplate(false)
      loadDonationsMutation.mutate()
    } else {
      setDonations(donationsTemplate)
      setIsTemplate(true)
    }
  }, [association])

  useEffect(() => {
    let updatedFilteredDonations = donations

    if (dateFrom !== null) {
      updatedFilteredDonations = updatedFilteredDonations.filter((donation) => {
        return dayjs(donation.dateCollected).isAfter(dateFrom) || dayjs(donation.dateCollected).isSame(dateFrom)
      })
    }

    if (dateTo !== null) {
      updatedFilteredDonations = updatedFilteredDonations.filter((donation) => {
        return dayjs(donation.dateCollected).isBefore(dateTo) || dayjs(donation.dateCollected).isSame(dateTo)
      })
    }

    setFilteredDonations(updatedFilteredDonations)
  }, [dateFrom, dateTo, donations])

  const closeDialog = () => {
    setIsExportPDFFiscalReportModalOpen(false)
  }

  return (
    <>
      <AssociationDonationDashboard
        association={association}
        donations={filteredDonations}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={selectedDonation === undefined ? 12 : 7}>
          {!isTemplate && (
            <AssociationDonationTracking
              donations={filteredDonations}
              setSelectedDonation={setSelectedDonation}
              setDonationForFiscalReport={setDonationForFiscalReport}
              setIsExportPDFFiscalReportModalOpen={setIsExportPDFFiscalReportModalOpen}
            />
          )}
        </Grid>
        {selectedDonation !== undefined && (
          <Grid item xs={12} md={5}>
            <AssociationDonationFormTabs
              selectedDonation={selectedDonation}
              setSelectedDonation={setSelectedDonation}
              setDonationForFiscalReport={setDonationForFiscalReport}
              setIsExportPDFFiscalReportModalOpen={setIsExportPDFFiscalReportModalOpen}
            />
          </Grid>
        )}
      </Grid>
      {donationForFiscalReport && isExportPDFFiscalReportModalOpen && (
        <ExportPDFFiscalReportModal
          donation={donationForFiscalReport}
          isDialogOpen={isExportPDFFiscalReportModalOpen}
          closeDialog={closeDialog}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          right: 2,
          bottom: 0,
          margin: 2,
        }}
      >
        <Tooltip
          title={
            isTemplate ? "Pour bénéficier de cette fonctionnalité, souscrivez à l'offre utile" : 'Ajouter une donation'
          }
        >
          <span>
            <Fab
              color="primary"
              variant="extended"
              disabled={isTemplate || isReader}
              onClick={() => navigate(PATHS.ajouterDonateur.relative)}
            >
              <Add sx={{ mr: 1 }} />
              Ajouter une donation
            </Fab>
          </span>
        </Tooltip>
      </Box>
    </>
  )
}
