import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Typography } from '@mui/material'
import { FC } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../domain/Account/AccountDisplay'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { PATHS } from '../PATHS'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { AdopterDto } from '../../interactors/gen/backendClient'

interface Props {
  adopter: AdopterDto
}

export const DeleteAdopterPrompt: FC<Props> = ({ adopter }) => {
  const navigate = useNavigate()
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()

  // Mutations
  const mutation = useMutation(async () => await AdoptersClient.deleteAdopter(adopter.id), {
    onSuccess: () => {
      closeDialog()
      navigate(PATHS.suiviAdoptant.absolute)
      globalSnackbar.triggerSuccessMessage(`Le compte de ${fullName(adopter)} a été supprimé !`)
    },
  })

  return (
    <>
      <MenuItem key="delete" onClick={openDialog}>
        {'Supprimer ce compte'}
      </MenuItem>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography>⚠️ Êtes vous sur de vouloir supprimer cet adoptant ?</Typography>
          <Typography>
            <b>
              {`Si vous continuez, tous les animaux associés à cet adoptant verront leur status d'adoption repasser à
            "adoptable"`}
            </b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel" onClick={closeDialog}>
            Revenir
          </Button>
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            sx={{ backgroundColor: 'error.main' }}
            onClick={() => mutation.mutate()}
            loading={mutation.isLoading}
          >
            Supprimer définitivement
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
