import { Edit } from '@mui/icons-material'
import { IconButton, Dialog, DialogTitle, Box, DialogContent, DialogActions, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import { AccountDto, AccountDtoRoles, CustomerDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { MemberPromptSaveButton } from '../../Members/MemberPromptSaveButton'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { PATHS } from '../../PATHS'
import { useNavigate } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { useMutation } from 'react-query'
import { AccountsClient } from '../../../interactors/clients/AccountsClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../../utils/axios'

interface Props {
  association: CustomerDto
  onSubmit: (data: Partial<CustomerDto>) => void
}

export const EditSanitaryVeterinarianPrompt: React.FC<Props> = ({ association, onSubmit }) => {
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()
  const globalSnackBarStore = useGlobalSnackbarStore()

  const editAccountMutation = useMutation(
    async (data: AccountDto) => {
      const response = await AccountsClient.editAccount(data)
      return response
    },
    {
      onSuccess: () => {
        console.log('Utilisateur modifié avec succès')
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            globalSnackBarStore.triggerErrorMessage('Un utilisateur existe déjà avec cette addresse mail.')
            return
          }
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const submitProcess = async (data: Partial<CustomerDto>) => {
    // Check if veterinary has changed and update the account accordingly
    if (data.sanitaryVeterinarianAccountId !== association.sanitaryVeterinarianAccountId) {
      const oldSanitaryVeterinarianAccount = accountsStore.members.find(
        (member) => member.id === association.sanitaryVeterinarianAccountId
      )
      if (oldSanitaryVeterinarianAccount) {
        const mappedTitle = 'Vétérinaire sanitaire'
        const otherTitles = oldSanitaryVeterinarianAccount.titles?.filter((title) => title !== mappedTitle) || []
        const editedAccount = {
          ...oldSanitaryVeterinarianAccount,
          titles: otherTitles,
        }
        await editAccountMutation.mutateAsync(editedAccount)
      }
    }

    // Update the new veterinary account
    const sanitaryVeterinarianAccount = accountsStore.members.find(
      (member) => member.id === data.sanitaryVeterinarianAccountId
    )
    if (sanitaryVeterinarianAccount) {
      const mappedTitle = 'Vétérinaire sanitaire'
      const otherTitles = sanitaryVeterinarianAccount.titles?.filter((title) => title !== mappedTitle) || []
      const editedAccount = {
        ...sanitaryVeterinarianAccount,
        titles: [...otherTitles, mappedTitle],
      }
      await editAccountMutation.mutateAsync(editedAccount)
    }

    onSubmit(data)
    return dialogOpenedState.closeDialog()
  }

  const veterinariesOptions = accountsStore.members
    .filter((member) => member.roles.includes(AccountDtoRoles.Veterinary))
    .map((option) => ({ label: fullName(option), value: option.id }))

  const getDefaultValues = (association: CustomerDto | null): Partial<CustomerDto> => ({
    sanitaryVeterinarianAccountId: queryParams.get('veterinary') || association?.sanitaryVeterinarianAccountId || '',
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useFormExtended(association, getDefaultValues)

  useEffect(() => {
    if (queryParams.get('card') === 'editSanitaryVeterinarian') {
      dialogOpenedState.openDialog()
    }
  }, [])

  const masterAccountId = watch('sanitaryVeterinarianAccountId')
  useEffect(() => {
    if (masterAccountId === 'addAccount') {
      reset({ masterAccountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=association?tab=my-association&role=${AccountDtoRoles.Veterinary}&card=editSanitaryVeterinarian`
      )
    }
  }, [masterAccountId])

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
        <Tooltip title="Editer le vétérinaire sanitaire">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Editer le vétérinaire sanitaire</DialogTitle>

        <form
          onSubmit={handleSubmit(submitProcess)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'sanitaryVeterinarianAccountId'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un bénévole' },
                  ...veterinariesOptions.sort((a, b) => a.label.localeCompare(b.label)),
                ]}
                label="Vétérinaire sanitaire"
                error={errors.sanitaryVeterinarianAccountId}
                requiredRule="Le vétérinaire sanitaire est requis"
                defaultValue={association?.sanitaryVeterinarianAccountId || ''}
                highlight
              />
            </DialogContent>

            <DialogActions>
              <MemberPromptSaveButton isOwnAccount={false} isLoading={false} dialogOpenedState={dialogOpenedState} />
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
