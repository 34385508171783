import { AnimalFilters } from '../domain/Animal/AnimalFiltersType'

export const formatFiltersToSend = (filters: AnimalFilters) => {
  let filtersToSend: Partial<AnimalFilters> | undefined = undefined

  if (filters?.myAnimalsOnly) {
    filtersToSend = { myAnimalsOnly: filters.myAnimalsOnly }
  }

  if (filters?.hasExitDateFilter) {
    filtersToSend = { ...filtersToSend, hasExitDateFilter: filters.hasExitDateFilter }
  }

  if (filters?.hasIdentificationNumberFilter && filters?.hasIdentificationNumberFilter !== 'all') {
    filtersToSend = { ...filtersToSend, hasIdentificationNumberFilter: filters?.hasIdentificationNumberFilter }
  }

  if (filters?.sterilizedOrNeuteredFilter && filters.sterilizedOrNeuteredFilter.length > 0) {
    filtersToSend = { ...filtersToSend, sterilizedOrNeuteredFilter: filters.sterilizedOrNeuteredFilter }
  }

  if (filters?.adoptionStatusFilter && filters.adoptionStatusFilter.length > 0) {
    filtersToSend = { ...filtersToSend, adoptionStatusFilter: filters.adoptionStatusFilter }
  }

  if (filters?.speciesFilter && filters.speciesFilter.length > 0) {
    filtersToSend = { ...filtersToSend, speciesFilter: filters.speciesFilter }
  }

  if (filters?.sexFilter) {
    filtersToSend = { ...filtersToSend, sexFilter: filters.sexFilter }
  }

  if (
    filters?.adoptionStatusFilter.includes('cannot-be-adopted') &&
    filters?.cannotBeAdoptedStatusFilter &&
    filters.cannotBeAdoptedStatusFilter.length > 0
  ) {
    filtersToSend = { ...filtersToSend, cannotBeAdoptedStatusFilter: filters.cannotBeAdoptedStatusFilter }
  }

  if (
    filters?.adoptionStatusFilter.includes('has-been-adopted') &&
    filters?.stepFilter &&
    filters.stepFilter.length > 0
  ) {
    filtersToSend = { ...filtersToSend, stepFilter: filters.stepFilter }
  }
  return filtersToSend
}
