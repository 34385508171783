import { Box, Card, CardContent, CardHeader, Grid, SxProps, Theme } from '@mui/material'

import { FC } from 'react'
import { getReadableAnimalBreedTitle } from '../../../../domain/Animal/AnimalDisplay'
import { getAnimalRegistrationNumberType } from '../../../../domain/Animal/getAnimalRegistrationType'
import { AnimalBreedDtoBreedTitle, AnimalDto } from '../../../../interactors/gen/backendClient'

import { ReadOnlyItem } from '../../../common/ReadOnlyItem'
import { EditAnimalBreedPrompt } from './EditAnimalBreedPrompt'

interface Props {
  animal: AnimalDto
  sx?: SxProps<Theme>
}

export const AnimalBreedCard: FC<Props> = ({ animal, sx }) => {
  const defaultItemProps = { xs: 6, sm: 6, item: true, marginBottom: 2 }

  const registrationType = getAnimalRegistrationNumberType(animal.species)

  return (
    <>
      <Card sx={sx}>
        <CardHeader title="🐾 Portée et généalogie" />
        <CardContent>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={
                      animal.breedData?.breedTitle ? getReadableAnimalBreedTitle(animal.breedData?.breedTitle) : 'Race'
                    }
                    value={
                      animal.breedData?.breedTitle === AnimalBreedDtoBreedTitle.MixedBreed
                        ? `${animal.breedData?.filiation?.fatherBreed || '..'} x ${
                            animal.breedData?.filiation?.motherBreed || '..'
                          }`
                        : animal.breedData?.breed || '..'
                    }
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label="Généalogie"
                    value={`${
                      animal.breedData?.isPedigreeRegistered
                        ? `${registrationType || 'Enregistré'} / ${animal.breedData?.registrationNumber || '..'}`
                        : `Non ${registrationType || ''}`
                    }`}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label="Nb d'animaux dans la portée"
                    value={animal.breedData?.numberOfAnimalsInLitter?.toString() || '..'}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem label="N° de la portée" value={animal.breedData?.litterNumber || '..'} />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditAnimalBreedPrompt animal={animal} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
