import { TextField, FormControl, Box, Button } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { spacingItem } from '../../theme'
import { MemberCardSaveButton } from '../../Members/MemberCardSaveButton'
import { useDonatersStore } from '../../../store/DonatersStore'
import { CreateDonorBodyDto, Donor, DonorType } from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { getReadableDonatorType } from '../../../domain/Donations/DonatorDisplay'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { useMutation } from 'react-query'
import { DonatersClient } from '../../../interactors/clients/DonatersClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'

interface AssociationDonatorFormProps {
  selectedDonaterId: string
  handleFiscalReport: (e: React.MouseEvent) => void
}

interface FormParams {
  type?: DonorType
  SIRETNumber: string
  name: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: string
  postalCode: string
  city: string
  country: string
}

export const AssociationDonatorForm: React.FC<AssociationDonatorFormProps> = ({
  selectedDonaterId,
  handleFiscalReport,
}) => {
  const donatersStore = useDonatersStore()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const donator = donatersStore.donaters.find((donator) => donator.id === selectedDonaterId)

  //Mutation pour modifier un compte
  const editDonatorMutation = useMutation(
    (newDonator: FormParams) => {
      const donor: Omit<CreateDonorBodyDto, 'customerId'> = {
        ...donator,
        type: newDonator.type!,
        human:
          newDonator.type === DonorType.Individuals
            ? {
                firstName: newDonator.firstName,
                lastName: newDonator.lastName,
                email: newDonator.email,
                phoneNumber: newDonator.phoneNumber,
                address: newDonator.address,
                postalCode: newDonator.postalCode,
                city: newDonator.city,
                country: newDonator.country,
              }
            : undefined,
        organization:
          newDonator.type !== DonorType.Individuals
            ? {
                SIRETNumber: newDonator.SIRETNumber,
                name: newDonator.name,
                email: newDonator.email,
                phoneNumber: newDonator.phoneNumber,
                address: newDonator.address,
                postalCode: newDonator.postalCode,
                city: newDonator.city,
                country: newDonator.country,
              }
            : undefined,
      }
      return DonatersClient.editDonater(donor)
    },
    {
      onSuccess: () => {
        globalSnackBarStore.triggerSuccessMessage('Le donateur a bien été modifié')
        donatersStore.fetchOrRefreshDonaters()
      },
    }
  )

  const onSubmit = (data: FormParams) => {
    editDonatorMutation.mutate(data)
  }

  const getDefaultValues = (donator?: Donor): FormParams => ({
    type: donator?.type,
    SIRETNumber: donator?.organization?.SIRETNumber || '',
    name: donator?.organization?.name || '',
    firstName: donator?.human?.firstName || '',
    lastName: donator?.human?.lastName || '',
    email: donator?.human?.email || donator?.organization?.email || '',
    phoneNumber: donator?.human?.phoneNumber || donator?.organization?.phoneNumber || '',
    address: donator?.human?.address || donator?.organization?.address || '',
    postalCode: donator?.human?.postalCode || donator?.organization?.postalCode || '',
    city: donator?.human?.city || donator?.organization?.city || '',
    country: donator?.human?.country || donator?.organization?.country || '',
  })

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(donator, getDefaultValues)

  const typeOfDonator = watch('type')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledSelectField
        sx={spacingItem}
        control={control}
        error={errors.type}
        fieldName="type"
        label="Type de donateur"
        options={Object.values(DonorType).map((value) => ({
          label: getReadableDonatorType(value),
          value: value,
        }))}
        requiredRule={'Le type de donateur est requis'}
      />

      {typeOfDonator && typeOfDonator !== DonorType.Individuals && (
        <>
          <TextField
            id="name-input"
            label="Nom de l'organisation"
            type="text"
            fullWidth
            {...register('name', { required: 'Le nom est requis' })}
            sx={spacingItem}
            error={!!errors.name}
            helperText={errors.name?.message}
            required
          />
          <TextField
            id="first-name-input"
            label="Numéro de siret"
            type="text"
            fullWidth
            {...register('SIRETNumber', { required: 'Le numéro de siret est requis' })}
            sx={spacingItem}
            error={!!errors.SIRETNumber}
            helperText={errors.SIRETNumber?.message}
            required
          />
        </>
      )}

      {typeOfDonator === DonorType.Individuals && (
        <>
          <TextField
            id="first-name-input"
            label="Prénom"
            type="text"
            fullWidth
            {...register('firstName', { required: 'Le prénom est requis' })}
            sx={spacingItem}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            required
          />

          <TextField
            id="last-name-input"
            label="Nom de Famille"
            type="text"
            fullWidth
            {...register('lastName', { required: 'Le nom est requis' })}
            sx={spacingItem}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            required
          />
        </>
      )}

      <TextField
        id="email"
        label="Email"
        type="email"
        fullWidth
        {...register('email', {
          required: "L'email est requis.",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "L'addresse n'est pas valide.",
          },
        })}
        sx={spacingItem}
        error={!!errors.email}
        helperText={errors.email?.message}
        required
      />

      <FormControl fullWidth variant="outlined">
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            validate: (value: string | undefined) => {
              console.log(value)
              return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                ? true
                : 'Tel is invalid'
            },
          }}
          render={({ field, fieldState }) => (
            <MuiTelInput
              {...field}
              label="Numero de Téléphone"
              defaultCountry="FR"
              helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
              error={!!fieldState.error}
              sx={spacingItem}
            />
          )}
        />
      </FormControl>

      <TextField
        id="address"
        label="Adresse"
        type="text"
        fullWidth
        {...register('address')}
        sx={spacingItem}
        error={!!errors.address}
        helperText={errors.address?.message}
        required
      />

      <TextField
        id="postalCode"
        label="Code Postal"
        type="text"
        fullWidth
        {...register('postalCode')}
        sx={spacingItem}
        error={!!errors.postalCode}
        helperText={errors.postalCode?.message}
        required
      />

      <TextField
        id="city"
        label="Ville"
        type="text"
        fullWidth
        {...register('city')}
        sx={spacingItem}
        error={!!errors.city}
        helperText={errors.city?.message}
        required
      />

      <TextField
        id="country"
        label="Pays"
        type="text"
        fullWidth
        {...register('country')}
        sx={spacingItem}
        error={!!errors.country}
        helperText={errors.country?.message}
        required
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', ...spacingItem }}>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={(e) => handleFiscalReport(e)}
          sx={{ textTransform: 'none' }}
        >
          Editer un reçu fiscal
        </Button>
        <MemberCardSaveButton isOwnAccount={false} />
      </Box>
    </form>
  )
}
