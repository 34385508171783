import { AnimalSearchItemDto } from '../../interactors/gen/backendClient'

export const isAnimalOfferPublished = (animal: AnimalSearchItemDto): boolean => {
  let specieCondition = true
  if (['cat', 'dog', 'ferret'].includes(animal.species)) {
    if (!(animal.identificationNumber != null || animal.tattoo?.number != null)) {
      specieCondition = false
    }
  }
  return (
    animal.adoptionStatus === 'adoptable' &&
    animal.images?.profileImageKey != null &&
    animal.birthdate != null &&
    specieCondition
  )
}
