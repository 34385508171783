import React, { FC } from 'react'

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Drawer,
  Fab,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { Add, FolderSpecial, LockOpen, Search as SearchIcon, Store, Tune } from '@mui/icons-material'
import {
  AccountDto,
  AccountDtoPermission,
  AccountDtoRoles,
  AccountVeterinaryDtoType,
} from '../../interactors/gen/backendClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'

import { useNavigate } from 'react-router-dom'
import {
  fullAddress,
  getColorForAvailabilityStatus,
  getReadableAccountFieldLabel,
  getReadableAccountPermission,
  getReadableAccountRole,
  getReadableAccountVeterinaryType,
} from '../../domain/Account/AccountDisplay'
import { searchMembers } from '../../domain/Account/searchMembers'
import { useAccountsStore } from '../../store/AccountsStore'
import { useFetchOrRefreshMembersOnMount } from '../../store/useFetchOrRefreshMembersOnMount'
import { getAccountProfilePhotoSrc } from '../../utils/S3-links'
import { isUserAvailableToday } from '../../utils/isUserAvailableToday'
import { setOpacity } from '../../utils/setOpacity'
import { sortAlphabetically } from '../../utils/sort'
import { PATHS } from '../PATHS'
import { AQuestion } from '../common/AQuestion'
import { FilterList } from '../common/FilterList'
import { FilterListItem } from '../common/FilterListItem'
import { colors, spacingItem } from '../theme'
import { MemberExportSheet } from './MemberExportSheet'
import VeterinariesListCard from './VeterinariesListCard'

export const VeterinariesListScreen: FC = () => {
  const [selectedPermissions, setSelectedPermissions] = React.useState<AccountDtoPermission[]>([])
  const [selectedSpecialities, setSelectedSpecialities] = React.useState<string[]>([])
  const [selectedType, setSelectedType] = React.useState<AccountVeterinaryDtoType | null>(null)
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const navigate = useNavigate()

  useFetchOrRefreshMembersOnMount()

  const accountsStore = useAccountsStore()

  const [searchQuery, setSearchQuery] = React.useState('')

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchQuery(e.target.value)
  }

  const allSpecialities = accountsStore.members
    .filter((member) => member.roles.includes(AccountDtoRoles.Veterinary))
    .map((member) => member.veterinary?.specialty)
    .flat()

  const FiltersBar = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          Filtrer les vétérinaires
        </Typography>

        <FilterList label="Type" icon={<Store />}>
          {Object.values(AccountVeterinaryDtoType).map((value) => {
            return (
              <FilterListItem
                key={value}
                label={getReadableAccountVeterinaryType(value)}
                isSelected={value === selectedType}
                toggleSelected={() => {
                  if (value === selectedType) {
                    setSelectedType(null)
                  } else {
                    setSelectedType(value)
                  }
                }}
              />
            )
          })}
        </FilterList>

        <FilterList label="Permission" icon={<LockOpen />}>
          {Object.values(AccountDtoPermission).map((permission) => {
            const isSelected = selectedPermissions.includes(permission)

            return (
              <FilterListItem
                key={permission}
                label={getReadableAccountPermission(permission)}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the permission is already selected, remove it from the selectedPermissions array
                    setSelectedPermissions(selectedPermissions.filter((s) => s !== permission))
                  } else {
                    // If the permission is not selected, add it to the selectedSexs array
                    setSelectedPermissions([...selectedPermissions, permission])
                  }
                }}
              />
            )
          })}
        </FilterList>

        <FilterList label="Spécialité" icon={<FolderSpecial />}>
          {allSpecialities
            .filter((specialty) => specialty !== undefined)
            .map((value) => {
              const isSelected = selectedSpecialities.includes(value as string)

              return (
                <FilterListItem
                  key={value}
                  label={value as string}
                  isSelected={isSelected}
                  toggleSelected={() => {
                    if (isSelected) {
                      // If the permission is already selected, remove it from the selectedPermissions array
                      setSelectedSpecialities(selectedSpecialities.filter((s) => s !== (value as string)))
                    } else {
                      // If the permission is not selected, add it to the selectedSexs array
                      setSelectedSpecialities([...selectedSpecialities, value as string])
                    }
                  }}
                />
              )
            })}
        </FilterList>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <MemberExportSheet />
        </Box>
        <AQuestion />
      </>
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: 1,
          paddingBottom: 7.5,
          backgroundColor: 'grey.100',
          minHeight: `calc(100% - 20px)`,
          boxSizing: 'border-box',
          marginBottom: 2,
          maxHeight: `calc(100% - 20px)`,
        }}
      >
        {accountsStore.isLoading ? (
          <CircularProgressPanel />
        ) : (
          <>
            {/* Filters for desktop version */}
            <Card
              sx={{
                ...spacingItem,
                width: 230,
                display: { xs: 'none', sm: 'block' },
                overflowY: 'auto',
              }}
            >
              <CardContent>
                <FiltersBar />
              </CardContent>
            </Card>
            {/* Filters for mobile version */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
              }}
            >
              <Box sx={{ padding: 2 }}>
                <FiltersBar />
              </Box>
            </Drawer>
          </>
        )}
        <Card
          sx={{
            ...spacingItem,
            marginLeft: { xs: 0, sm: 2 },
            width: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Box display="flex" flexDirection="row" flexWrap="nowrap">
            <TextField
              id="name-input"
              placeholder="Rechercher dans les vétérinaires"
              type="text"
              fullWidth
              value={searchQuery}
              onChange={onChangeSearch}
              sx={{
                borderRadius: 0,
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' }, mx: 'auto' }}
            >
              <Tune />
            </IconButton>
          </Box>

          <Box
            sx={{
              width: '100%',
              maxHeight: '100%',
              boxSizing: 'border-box',
              overflow: 'auto',
              paddingBottom: 7, // HACK: To avoid the last row to be hidden
            }}
          >
            {accountsStore.isLoading ? (
              <CircularProgressPanel />
            ) : (
              <SearchResults
                members={accountsStore.members.filter((member) => member.roles.includes(AccountDtoRoles.Veterinary))}
                searchQuery={searchQuery}
                filters={{
                  selectedPermissions,
                  selectedSpecialities,
                  selectedType,
                }}
              />
            )}
          </Box>

          {accountsStore.isAdmin() && (
            <Box sx={{ position: 'absolute', right: 2, bottom: 0, margin: 2 }}>
              <Fab color="primary" variant="extended" onClick={() => navigate(PATHS.ajouterVeterinaire.absolute)}>
                <Add sx={{ mr: 1 }} />
                Ajouter un Membre
              </Fab>
            </Box>
          )}
        </Card>
      </Box>
    </>
  )
}

type SortDirection = 'asc' | 'desc'
type SortColumn = 'firstName' | 'lastName' | 'status' | 'email' | 'address' | 'roles' | 'permission'

type SearchResultsProps = {
  members: Array<AccountDto>
  searchQuery: string
  filters?: {
    selectedPermissions?: AccountDtoPermission[]
    selectedSpecialities?: string[]
    selectedType?: AccountVeterinaryDtoType | null
  }
}

const SearchResults: React.FC<SearchResultsProps> = ({ members, searchQuery, filters }) => {
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('status')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')
  const isMobile = useMediaQuery('(max-width:600px)')

  const accountsStore = useAccountsStore()

  const handleSort = (column: SortColumn, columnDefaultDirection: SortDirection) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortDirection(columnDefaultDirection)
    }

    setSortColumn(column)
  }
  const searchResult = searchMembers(members, searchQuery)
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedPermissions?.length) {
        return true
      }
      return member.permission ? filters.selectedPermissions.includes(member.permission) : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedSpecialities?.length) {
        return true
      }
      return member.veterinary?.specialty
        ? member.veterinary.specialty
            .map((specialty) => (filters.selectedSpecialities?.includes(specialty) ? true : false))
            .includes(true)
        : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedType) {
        return true
      }
      // If the veterinary type isn't defined we consider it as an independent veterinary
      if (!member.veterinary?.type && filters.selectedType === 'independent') return true
      return member.veterinary?.type ? member.veterinary.type === filters.selectedType : false
    })
    .sort((a, b) => {
      switch (sortColumn) {
        case 'firstName':
          return sortAlphabetically(a, b, sortDirection, 'firstName')
        case 'lastName':
          return sortAlphabetically(a, b, sortDirection, 'lastName')
        case 'status':
          return sortAlphabetically(a, b, sortDirection, 'status')
        case 'email':
          return sortAlphabetically(a, b, sortDirection, 'email')
        case 'address':
          return sortAlphabetically(a, b, sortDirection, 'address')
        case 'roles':
          return sortAlphabetically(a, b, sortDirection, 'roles')
        case 'permission':
          return sortAlphabetically(a, b, sortDirection, 'permission')
        default:
          throw new Error(`sortColumn ${sortColumn} not supported`)
      }
    })

  const connectedMember = accountsStore.connectedAccount

  return !isMobile ? (
    // Desktop version
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'firstName'}
              direction={sortDirection}
              onClick={() => {
                handleSort('firstName', 'asc')
              }}
            >
              Nom
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'lastName'}
              direction={sortDirection}
              onClick={() => {
                handleSort('lastName', 'asc')
              }}
            >
              Type
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'status'}
              direction={sortDirection}
              onClick={() => {
                handleSort('status', 'asc')
              }}
            >
              Disponibilité
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'email'}
              direction={sortDirection}
              onClick={() => {
                handleSort('email', 'asc')
              }}
            >
              {getReadableAccountFieldLabel('email')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'address'}
              direction={sortDirection}
              onClick={() => {
                handleSort('address', 'asc')
              }}
            >
              Adresse
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'roles'}
              direction={sortDirection}
              onClick={() => {
                handleSort('roles', 'asc')
              }}
            >
              {getReadableAccountFieldLabel('roles')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'permission'}
              direction={sortDirection}
              onClick={() => {
                handleSort('permission', 'asc')
              }}
            >
              {getReadableAccountFieldLabel('permission')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {connectedMember && searchResult.some((member) => member.id === connectedMember.id) && (
          <MemberListItem member={connectedMember} bgColor={setOpacity(colors.bermudaGray, 0.35)} />
        )}

        {searchResult
          .filter((member) => member.id != connectedMember?.id)
          .map((member) => (
            <MemberListItem key={member.id} member={member} />
          ))}
      </TableBody>
    </Table>
  ) : (
    // Mobile version
    <Stack spacing={1.5} marginTop="10px" marginBottom="100px" marginX="15px">
      {connectedMember && searchResult.some((member) => member.id === connectedMember.id) && (
        <VeterinariesListCard member={connectedMember} bgColor={setOpacity(colors.bermudaGray, 0.35)} />
      )}

      {searchResult
        .filter((member) => member.id != connectedMember?.id)
        .map((member) => (
          <VeterinariesListCard key={member.id} member={member} />
        ))}
    </Stack>
  )
}

type ItemProps = {
  member: AccountDto
  bgColor?: string
}

const MemberListItem: React.FC<ItemProps> = ({ member, bgColor }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${member.id}`)
  }

  const profileImageKey = member.images?.profileImageKey

  return (
    // <TableRow>
    <TableRow onClick={handleClick} sx={{ cursor: 'pointer', backgroundColor: bgColor }}>
      <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
        <Avatar alt={member.firstName} src={profileImageKey && getAccountProfilePhotoSrc(profileImageKey)} />
      </TableCell>
      <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
        {member.veterinary?.type === 'clinic' ? member.firstName : `${member.firstName} ${member.lastName}`}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.veterinary?.type ? getReadableAccountVeterinaryType(member.veterinary.type) : 'Vétérinaire indépendant'}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Avatar
            sx={{
              background: setOpacity(getColorForAvailabilityStatus(isUserAvailableToday(member)), 0.7),
              width: '22px',
              height: '22px',
              fontWeight: 'bold',
            }}
          >
            {' '}
          </Avatar>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row">
        {member.email}
      </TableCell>
      <TableCell component="th" scope="row">
        {fullAddress(member)}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.roles.map((role) => getReadableAccountRole(role)).join(', ')}
      </TableCell>
      <TableCell component="th" scope="row">
        {getReadableAccountPermission(member.permission)}
      </TableCell>
    </TableRow>
  )
}
