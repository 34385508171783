import { CustomerCageWithAnimalsDetailsDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { fullName } from '../Account/AccountDisplay'
import { getReadableSpecies } from '../Animal/AnimalDisplay'
import { formatStringForSearch } from '../shared/formatStringForSearch'

export const searchCages = (
  cages: Array<CustomerCageWithAnimalsDetailsDto>,
  searchQuery: string
): Array<CustomerCageWithAnimalsDetailsDto> => {
  const accountsStore = useAccountsStore()
  return cages.filter((cage) => {
    const formattedSearchQuery = formatStringForSearch(searchQuery)

    const memberInCharge = accountsStore.members.find((member) => member.id === cage.memberInChargeId)

    const memberInChargeMatches = memberInCharge
      ? formatStringForSearch(fullName(memberInCharge)).startsWith(formattedSearchQuery)
      : false
    const nameMatches = cage.name ? formatStringForSearch(cage.name).startsWith(formattedSearchQuery) : false
    const suitableForSpeciesMatches = cage.suitableForSpecies.some((specie) =>
      formatStringForSearch(getReadableSpecies(specie)).startsWith(formattedSearchQuery)
    )
    const cageNumberMatches = formatStringForSearch(`${cage.cageNumber}`).startsWith(formattedSearchQuery)

    return nameMatches || suitableForSpeciesMatches || cageNumberMatches || memberInChargeMatches
  })
}
