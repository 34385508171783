import { DescriptionOutlined, Search as SearchIcon } from '@mui/icons-material'
import { Card, CardHeader, CardContent, Box, InputAdornment, TextField, Typography, Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { blueSky, colors, spacingItem } from '../../theme'
import { DocumentItem } from './DocumentItem'
import { AddDocumentModal } from './AddDocumentModal'
import { CustomerDocumentDto } from '../../../interactors/gen/backendClient'
import { getReadableCustomerDocumentType } from '../../../domain/Customer/CustomerDisplay'
import { useMutation } from 'react-query'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import { getCustomerDocumentsSrc } from '../../../utils/S3-links'
import { useAccountsStore } from '../../../store/AccountsStore'

interface DocumentsListProps {
  documents: CustomerDocumentDto[]
}

const petsoRessources = [
  'Certificat-Petso-Chien.pdf',
  'Certificat-Petso-Chat.pdf',
  'Certificat-Petso-Lapin.pdf',
  'Certificat-Petso-Furet.pdf',
]

export const DocumentsList: React.FC<DocumentsListProps> = ({ documents }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [documentsList, setDocumentsList] = useState<CustomerDocumentDto[]>(documents)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const filteredDocuments = documentsList
    ? documentsList.filter(
        (document) =>
          searchQuery === '' ||
          document.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          document.reference?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          getReadableCustomerDocumentType(document.type)?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : []

  const fetchCustomerMutation = useMutation(async () => await CustomersClient.getCurrentCustomer(), {
    onSuccess: (data) => {
      setDocumentsList(data.documents || [])
    },
  })

  useEffect(() => {
    fetchCustomerMutation.mutate()
  }, [])

  const accountsStore = useAccountsStore()

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{ ...spacingItem, backgroundColor: colors.white }}>
        <TextField
          id="name-input"
          placeholder="Rechercher un document"
          type="text"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          sx={{
            borderRadius: 0,
            [`& fieldset`]: {
              borderRadius: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Card>
        <CardHeader title="Documents" />
        <CardContent>
          <Typography variant="body1" component="p" sx={{ mt: -3, mb: 4 }}>
            Déposez ici les modèles génériques de certificat et de contrat utilisés par l’association, ils seront utile
            pour faciliter les démarches lors des procédures d’adoption
          </Typography>
          {filteredDocuments.length > 0 &&
            filteredDocuments.map((document) => (
              <DocumentItem key={document.id} document={document} setDocumentsList={setDocumentsList} />
            ))}
          <Box display="flex" justifyContent="flex-end" sx={spacingItem}>
            <AddDocumentModal fetchCustomerMutation={fetchCustomerMutation} disabled={!accountsStore.isAdmin()} />
          </Box>
        </CardContent>
        <CardHeader title="Ressources Petso" />
        <CardContent>
          {petsoRessources.map((document) => (
            <Box
              mt={2}
              key={document}
              sx={{ border: { xs: 1, sm: 1, md: 0 }, borderRadius: { xs: 2, md: 0 }, padding: { xs: 2, md: 0 } }}
            >
              <Grid container spacing={2}>
                <Grid item xs={1.8} sm={0.8}>
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <DescriptionOutlined sx={{ width: 42, height: 42, color: blueSky }} />
                  </Box>
                </Grid>
                <Grid item xs={10.2} sm={5.6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', height: '100%' }}>
                    <Typography>
                      Modèle de certificat généré par Petso pour{' '}
                      {document.substring(
                        document.lastIndexOf('-') + 1,
                        document.indexOf('.', document.lastIndexOf('-'))
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={5.5} md={2.2}>
                  <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center" height="100%">
                    <Button variant="contained" href={getCustomerDocumentsSrc(document)} target="_blank">
                      Consulter
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </CardContent>
      </Card>
    </>
  )
}
