import { Card, CardContent, CardHeader, TextField, Box } from '@mui/material'
import React from 'react'
import { MemberCardSaveButton } from '../../Members/MemberCardSaveButton'
import { CustomerDto, OrganizationTypeDtoType } from '../../../interactors/gen/backendClient'
import {
  getBestCustomerTypeLabel,
  getReadableCustomerTypeOfOrganization,
} from '../../../domain/Customer/CustomerDisplay'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { spacingItem } from '../../theme'

const sx = { marginTop: 2 }

interface AssociationDescriptionProps {
  association: CustomerDto
  onSubmit: (data: FormParams) => Promise<void>
}

interface FormParams {
  type?: OrganizationTypeDtoType
  otherField?: string
  description: string | ''
}

export const AssociationDescription: React.FC<AssociationDescriptionProps> = ({ association, onSubmit }) => {
  const getDefaultValues = (customer: CustomerDto): FormParams => ({
    type: customer?.organizationType?.type || undefined,
    otherField: customer?.organizationType?.otherField || '',
    description: customer?.description || '',
  })

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormExtended(association, getDefaultValues)

  const typeOfOrganization = watch('type')

  return (
    <Card sx={sx}>
      <CardHeader title={`Description ${getBestCustomerTypeLabel(association?.types)}`} />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledAutoCompleteWithCustomValue
            control={control}
            defaultValue=""
            error={errors.type}
            fieldName="type"
            label="Type d'organisme"
            options={Object.values(OrganizationTypeDtoType).map((value) => ({
              label: getReadableCustomerTypeOfOrganization(value),
              value: value,
            }))}
            requiredRule={undefined}
          />
          {typeOfOrganization === OrganizationTypeDtoType.OtherOrganizations && (
            <TextField
              id="otherField-input"
              label="Type d'organisme personnalisé"
              multiline
              fullWidth
              {...register('otherField', { required: "Le type d'organisme est requis" })}
              error={!!errors.description}
              helperText={errors.description?.message}
              InputLabelProps={{ shrink: true }}
              sx={spacingItem}
            />
          )}
          <TextField
            id="outlined-textarea"
            label="Décrivez votre organisation en quelques mots"
            placeholder="Description de votre organisation en quelques mots"
            multiline
            fullWidth
            {...register('description', { required: 'La description est requise' })}
            error={!!errors.description}
            helperText={errors.description?.message}
            InputLabelProps={{ shrink: true }}
            sx={spacingItem}
          />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', ...sx }}>
            <MemberCardSaveButton isOwnAccount={false} />
          </Box>
        </form>
      </CardContent>
    </Card>
  )
}
