import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../../domain/Account/AccountDisplay'
import {
  getColorForAdopterFollowUpType,
  getColorForAdopterFollowUpVerdict,
  getReadableAdopterFollowUpType,
  getReadableAdopterFollowUpVerdict,
} from '../../../domain/Adopters/AccountDisplay'
import {
  AccountDtoRoles,
  AdopterDto,
  AdopterFollowUpDto,
  AdopterFollowUpDtoType,
  AdopterFollowUpDtoVerdict,
} from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { PATHS } from '../../PATHS'
import { ColoredChipSelectField } from '../../common/ColoredChipSelectField'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateField } from '../../common/ControlledDateField'
import { spacingItem } from '../../theme'

type FormParams = {
  type: AdopterFollowUpDtoType
  date: string
  verdict: AdopterFollowUpDtoVerdict
  interlocutorAccountId: string
  description?: string
}

interface Props {
  followUp: AdopterFollowUpDto
  followUps: Array<AdopterFollowUpDto>
  setFollowUps: (contactFollowUps: Array<AdopterFollowUpDto>) => void
  adopter: AdopterDto
  disabled?: boolean
}

export const EditFollowUpModal: React.FC<Props> = ({ followUp, followUps, setFollowUps, adopter, disabled }) => {
  const queryParams = new URLSearchParams(location.search)
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()

  const allMembers = accountsStore.members.filter((account) => !account.roles.includes(AccountDtoRoles.Veterinary))

  const getDefaultValues = (followUp: AdopterFollowUpDto): FormParams => ({
    type: followUp.type,
    date: followUp.date,
    verdict: followUp.verdict,
    interlocutorAccountId: queryParams.get('memberInCharge') || followUp.interlocutorAccountId,
    description: followUp.description,
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useFormExtended(followUp, getDefaultValues)

  const onSubmit = (data: FormParams) => {
    const newFollowUp = {
      ...data,
      date: dayjs(data.date).toISOString(),
    }
    setFollowUps(
      followUps.map((AFollowUp) => (AFollowUp === followUp ? (newFollowUp as AdopterFollowUpDto) : AFollowUp))
    )
    closeDialog()
    reset()
  }

  useEffect(() => {
    queryParams.get('card') === JSON.stringify(followUp) && openDialog()
  }, [queryParams.get('card')])

  const interlocutorAccountId = watch('interlocutorAccountId')
  useEffect(() => {
    if (interlocutorAccountId === 'addAccount') {
      reset({ interlocutorAccountId: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=suivi-adoptant/${adopter.id}&role=${
          AccountDtoRoles.Member
        }&card=${JSON.stringify(followUp)}`
      )
    }
  }, [interlocutorAccountId])

  return (
    <>
      <IconButton color="primary" onClick={openDialog} disabled={disabled}>
        <Tooltip title="Editer la prise de contact">
          <Edit />
        </Tooltip>
      </IconButton>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4}>
                Modifier une prise de contact
              </Typography>
              <ColoredChipSelectField
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={errors.type}
                fieldName="type"
                size="small"
                label="Type de suivi"
                options={Object.values(AdopterFollowUpDtoType).map((status: AdopterFollowUpDtoType) => ({
                  label: getReadableAdopterFollowUpType(status),
                  value: status,
                  color: getColorForAdopterFollowUpType(status),
                }))}
                requiredRule={'Le type de suivi est requis'}
              />

              <ControlledDateField
                control={control}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                error={errors.date}
                fieldName={'date'}
                label="Date"
                requiredRule={'La date est requise'}
                size="small"
                sx={spacingItem}
              />

              <ControlledAutoCompleteWithCustomValue
                control={control}
                fieldName={'interlocutorAccountId'}
                size="small"
                options={[
                  { value: 'addAccount', label: 'Ajouter un membre' },
                  ...allMembers
                    .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                    .map((option) => ({ label: fullName(option), value: option.id })),
                ]}
                label="Interlocuteur"
                error={errors.interlocutorAccountId}
                requiredRule={"L'interlocuteur est requis"}
                defaultValue={''}
                sx={spacingItem}
                highlight
              />

              <ColoredChipSelectField
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={errors.verdict}
                fieldName="verdict"
                size="small"
                label="Décision"
                options={Object.values(AdopterFollowUpDtoVerdict).map((status: AdopterFollowUpDtoVerdict) => ({
                  label: getReadableAdopterFollowUpVerdict(status),
                  value: status,
                  color: getColorForAdopterFollowUpVerdict(status),
                }))}
                requiredRule={'La décision est requise'}
              />

              <TextField
                id="description-input"
                label="Détails"
                type="text"
                fullWidth
                {...register('description')}
                sx={spacingItem}
                size="small"
                multiline
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton data-testid="apply-changes" variant="contained" type="submit">
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
