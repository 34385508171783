import { CustomerCageWithAnimalsDetailsDto, CustomerDto } from '../gen/backendClient'
import { backendClient } from './client'

import { AxiosRequestConfig } from 'axios'

export class CustomersClient {
  public static async getCurrentCustomer(): Promise<CustomerDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/customers',
    }

    const response = await backendClient<CustomerDto>(config)

    return response.data
  }

  public static async editCustomer(params: CustomerDto): Promise<CustomerDto> {
    const config: AxiosRequestConfig<CustomerDto> = {
      method: 'PUT',
      url: '/customers',
      data: {
        ...params,
      },
    }

    const response = await backendClient<CustomerDto>(config)

    return response.data
  }

  public static async getCurrentCustomerCagesWithAnimalsNames(): Promise<CustomerCageWithAnimalsDetailsDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/customers/cages',
    }

    const response = await backendClient<CustomerCageWithAnimalsDetailsDto[]>(config)

    return response.data
  }

  public static async uploadProfileImage(image: File): Promise<void> {
    const formData = new FormData()

    formData.append('file', image)

    const config: AxiosRequestConfig<FormData> = {
      method: 'POST',
      url: `/customers/upload-profile-photos`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'image/',
      },
    }

    await backendClient<void>(config)
  }

  public static async setSignatureFile(signature: File): Promise<void> {
    const formData = new FormData()

    formData.append('file', signature)

    const config: AxiosRequestConfig<FormData> = {
      method: 'POST',
      url: `/customers/signature`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    await backendClient<void>(config)
  }
}
