import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, Box, Typography, Grid, Card, CardContent, Tooltip } from '@mui/material'
import React from 'react'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { blue, spacingItem } from '../../theme'
import { useMutation } from 'react-query'
import { backendClient } from '../../../interactors/clients/client'

interface CheckoutSessionData {
  priceId: string
}

interface Props {
  offerName: string
  prices: string[]
  priceIds: string[]
  disabled: boolean
}

export const ChoosePaymentPlanModal: React.FC<Props> = ({ offerName, prices, priceIds, disabled }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()

  const mutation = useMutation(
    (data: CheckoutSessionData) => backendClient.post('/stripe/create-checkout-session', data),
    {
      onSuccess: (data) => {
        window.location.href = data.data
      },
      onError: (error) => {
        // Handle error.
        console.error('Error Creating Checkout Session:', error)
      },
    }
  )

  const onSubmit = (data: string) => {
    mutation.mutate({ priceId: data })
  }

  return (
    <>
      <Tooltip title={disabled ? "Seuls les administrateurs peuvent gérer l'abonnement" : ''}>
        <span>
          <Button
            onClick={() => openDialog()}
            variant="contained"
            disabled={disabled}
            sx={{
              ...spacingItem,
              fontWeight: 400,
              fontSize: { xs: 20, md: 24 },
              color: 'white',
              width: { xs: '120%', md: '350px' },
              fontFamily: 'Insaniburger',
            }}
          >
            Choisir cette offre
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <DialogContent>
          <Box>
            <Typography variant="h4" component="h2" marginBottom={4} sx={{ fontFamily: 'Insaniburger' }}>
              {`S'abonner à l'offre ${offerName}`}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
              <Typography
                sx={{ ...spacingItem, fontWeight: 400, fontSize: 24, color: blue, fontFamily: 'Insaniburger' }}
              >{`Mode de facturation`}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <PaymentPlan
                    type="monthly"
                    price={prices[0]}
                    priceId={priceIds[0]}
                    onSubmit={onSubmit}
                    isLoading={mutation.isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PaymentPlan
                    type="yearly"
                    price={prices[1]}
                    priceId={priceIds[1]}
                    onSubmit={onSubmit}
                    isLoading={mutation.isLoading}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

type PaymentPlanType = 'monthly' | 'yearly'

interface PaymentPlanProps {
  type: PaymentPlanType
  price: string
  priceId: string
  onSubmit: (data: string) => void
  isLoading: boolean
}

const PaymentPlan: React.FC<PaymentPlanProps> = ({ type, price, priceId, onSubmit, isLoading }) => {
  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 24,
              color: blue,
              fontFamily: 'Insaniburger',
            }}
          >
            {type === 'monthly' ? `${price} euros/mois HT` : `${price} euros/an HT`}
          </Typography>
          <Typography>
            {type === 'monthly'
              ? `Je m'abonne sur une base mensuelle`
              : `Je m'abonne sur une base annuelle (15% de réduction)`}
          </Typography>
          <LoadingButton
            variant="contained"
            type="submit"
            onClick={() => onSubmit(priceId)}
            loading={isLoading}
            sx={{ width: '100%', fontFamily: 'Insaniburger', fontSize: 20 }}
          >
            {type === 'monthly' ? `Mensuel` : `Annuel`}
          </LoadingButton>
        </Box>
      </CardContent>
    </Card>
  )
}
