import { ContentPaste, PersonPin, Pets, Star, Transgender, Tune, Wc } from '@mui/icons-material'
import React, { FC, useCallback, useEffect, useRef } from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  Drawer,
  Fab,
  IconButton,
  InputAdornment,
  Pagination,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'

import { Add, Search as SearchIcon } from '@mui/icons-material'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { accountCanEdit } from '../../domain/Account/accountCanEdit'
import {
  getReadableAnimalAdoptionStatus,
  getReadableAnimalAdoptionStep,
  getReadableCannotBeAdoptedStatus,
  getReadableSex,
  getReadableSpecies,
} from '../../domain/Animal/AnimalDisplay'
import { AnimalFilters } from '../../domain/Animal/AnimalFiltersType'
import { BooleanOrAll, BooleanOrUnknown } from '../../domain/enums/Boolean.enum'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import {
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDtoSex,
  AnimalDtoSpecies,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useAnimalStore } from '../../store/AnimalStore'
import { useFetchOrRefreshAnimalsOnMount } from '../../store/useFetchOrRefreshAnimalsOnMount'
import { useFetchOrRefreshMembersOnMount } from '../../store/useFetchOrRefreshMembersOnMount'
import { getReadableBooleanOrUnknown } from '../../utils/getReadableBooleanOrUnknown'
import { useComponentScrollRestoration } from '../../utils/hooks/useScrollRestoration'
import { PATHS } from '../PATHS'
import { AQuestion } from '../common/AQuestion'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { FilterList } from '../common/FilterList'
import { FilterListItem } from '../common/FilterListItem'
import { blueSky, spacingItem } from '../theme'
import { VideoPopIn } from '../utils/VideoPopIn'
import { AnimalExportPopUp } from './AnimalExportPopUp'
import { AnimalSheetPopUp } from './AnimalSheetPopUp'
import { AnimalsTable, SortColumn, SortDirection } from './AnimalsTable'

export type AnimalAdoptionDtoStepWithEmpty = AnimalAdoptionDtoStep | 'empty'
export type AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty = AnimalAdoptionDtoCannotBeAdoptedStatus | 'empty'

export const AnimalListScreen: FC = () => {
  const scrollRef = useRef<HTMLElement | null>(null)
  useComponentScrollRestoration(scrollRef, 'animal-list')

  const queryParams = new URLSearchParams(location.search)
  const getArrayQueryParamOrDefault = (paramValue: string | null | undefined, defaultValue: string[]) => {
    const values = paramValue?.split('|')
    if (values && values.length === 1 && values[0] === '') {
      return defaultValue
    }
    return values ?? defaultValue
  }
  // Filters
  const [myAnimalsOnly, setMyAnimalsOnly] = React.useState(queryParams.get('myAnimalsOnly') === 'true')
  const [hasIdentificationNumberFilter, setHasIdentificationNumberFilter] = React.useState(
    (queryParams.get('hasIdentificationNumberFilter') as BooleanOrAll) ?? BooleanOrAll.All
  )
  const [sterilizedOrNeuteredFilter, setSterilizedOrNeuteredFilter] = React.useState<BooleanOrUnknown[]>(
    getArrayQueryParamOrDefault(queryParams.get('sterilizedOrNeuteredFilter'), []) as BooleanOrUnknown[]
  )
  const [adoptionStatusFilter, setAdoptionStatusFilter] = React.useState<AnimalAdoptionDtoStatus[]>(
    getArrayQueryParamOrDefault(queryParams.get('adoptionStatusFilter'), [
      AnimalAdoptionDtoStatus.Adoptable,
      AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable,
      AnimalAdoptionDtoStatus.CannotBeAdopted,
    ]) as AnimalAdoptionDtoStatus[]
  )
  const [speciesFilter, setSpeciesFilter] = React.useState<AnimalDtoSpecies[]>(
    getArrayQueryParamOrDefault(queryParams.get('speciesFilter'), []) as AnimalDtoSpecies[]
  )
  /* const [hasExitDateFilter, setHasExitDateFilter] = React.useState<boolean | null>(null) */
  const [sexFilter, setSexFilter] = React.useState<AnimalDtoSex | null>(
    queryParams.get('sexFilter') as AnimalDtoSex | null
  )
  const [stepFilter, setStepFilter] = React.useState<AnimalAdoptionDtoStepWithEmpty[]>(
    getArrayQueryParamOrDefault(queryParams.get('stepFilter'), [
      ...Object.values(AnimalAdoptionDtoStep),
      'empty',
    ]) as AnimalAdoptionDtoStepWithEmpty[]
  )
  const [cannotBeAdoptedStatusFilter, setCannotBeAdoptedStatusFilter] = React.useState<
    AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty[]
  >(
    getArrayQueryParamOrDefault(queryParams.get('cannotBeAdoptedStatusFilter'), [
      AnimalAdoptionDtoCannotBeAdoptedStatus.BeingSocialized,
      AnimalAdoptionDtoCannotBeAdoptedStatus.InCare,
      AnimalAdoptionDtoCannotBeAdoptedStatus.InGestation,
      AnimalAdoptionDtoCannotBeAdoptedStatus.InLactation,
      AnimalAdoptionDtoCannotBeAdoptedStatus.Released,
      AnimalAdoptionDtoCannotBeAdoptedStatus.LongTermWithHostedFamily,
      'empty',
    ]) as AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty[]
  )
  const createFilterObject = (): AnimalFilters => {
    return {
      myAnimalsOnly,
      hasIdentificationNumberFilter,
      sterilizedOrNeuteredFilter,
      adoptionStatusFilter,
      stepFilter,
      cannotBeAdoptedStatusFilter,
      hasExitDateFilter: null,
      speciesFilter,
      sexFilter,
    }
  }
  const baseFilterObject = {
    myAnimalsOnly: false,
    hasIdentificationNumberFilter: 'all',
    sterilizedOrNeuteredFilter: [],
    adoptionStatusFilter: [],
    stepFilter: [
      'animal-reserved',
      'adoption-to-be-validated',
      'adoption-validated-without-post-visit',
      'adoption-validated-after-post-visit',
      'post-visit-not-validated',
      'animal-returned',
      'empty',
    ],
    cannotBeAdoptedStatusFilter: [
      'released',
      'dead',
      'long-term-with-hosted-family',
      'in-care',
      'being-socialized',
      'lost',
      'transfer-to-another-association',
      'in-gestation',
      'in-lactation',
      'empty',
    ],
    hasExitDateFilter: null,
    speciesFilter: [],
    sexFilter: null,
  }

  const [selectedTab, setSelectedTab] = React.useState<string>(queryParams.get('selectedTab') ?? 'followed')

  const [sortColumn, setSortColumn] = React.useState<SortColumn>(
    (queryParams.get('sortColumn') ?? 'insights') as SortColumn
  )
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(
    (queryParams.get('sortDirection') ?? 'asc') as SortDirection
  )

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [page, setPage] = React.useState(parseInt(queryParams.get('page') || '1', 10))
  const [assoHasAnimals, setAssoHasAnimals] = React.useState<boolean>(true)
  const [searchQuery, setSearchQuery] = React.useState(queryParams.get('search') || '')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState(queryParams.get('search') || '')
  const maxAnimalPerPage = 50

  const animalStore = useAnimalStore()
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()

  const account = accountsStore.connectedAccount

  useEffect(() => {
    const searchParams = new URLSearchParams()
    searchParams.set('page', page.toString())
    searchParams.set('search', debouncedSearchQuery)
    searchParams.set('myAnimalsOnly', myAnimalsOnly.toString())
    searchParams.set('hasIdentificationNumberFilter', hasIdentificationNumberFilter)
    searchParams.set('sterilizedOrNeuteredFilter', sterilizedOrNeuteredFilter.join('|'))
    searchParams.set('adoptionStatusFilter', adoptionStatusFilter.join('|'))
    searchParams.set('stepFilter', stepFilter.join('|'))
    searchParams.set('cannotBeAdoptedStatusFilter', cannotBeAdoptedStatusFilter.join('|'))
    searchParams.set('speciesFilter', speciesFilter.join('|'))
    searchParams.set('sexFilter', sexFilter ?? '')
    searchParams.set('sortColumn', sortColumn)
    searchParams.set('sortDirection', sortDirection)
    searchParams.set('selectedTab', selectedTab)
    // Add more parameters as needed
    navigate(`?${searchParams.toString()}`, { replace: true })
  }, [
    page,
    debouncedSearchQuery,
    myAnimalsOnly,
    hasIdentificationNumberFilter,
    sterilizedOrNeuteredFilter,
    adoptionStatusFilter,
    stepFilter,
    cannotBeAdoptedStatusFilter,
    speciesFilter,
    sexFilter,
    sortColumn,
    sortDirection,
    selectedTab,
  ])

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false // Mark as rendered
      return // Skip this effect on first render
    }

    setPage(1) // Reset page to 1 whenever filter values change
  }, [
    myAnimalsOnly,
    hasIdentificationNumberFilter,
    sterilizedOrNeuteredFilter,
    adoptionStatusFilter,
    stepFilter,
    cannotBeAdoptedStatusFilter,
    speciesFilter,
    sexFilter,
  ])

  useEffect(() => {
    AnimalsClient.countAllAnimal().then((count) => {
      if (count > 0) {
        setAssoHasAnimals(true)
      } else {
        setAssoHasAnimals(false)
      }
    })
  }, [])

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchQuery(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query !== debouncedSearchQuery) {
        setDebouncedSearchQuery(query)
        setPage(1) // Reset page as well when searchQuery changes
      }
    }, 500),
    [debouncedSearchQuery]
  )

  useEffect(() => {
    debouncedSearch(searchQuery)

    // Clean up function
    return () => {
      debouncedSearch.cancel()
    }
  }, [searchQuery, debouncedSearch])

  useFetchOrRefreshMembersOnMount()
  useFetchOrRefreshAnimalsOnMount(
    page,
    maxAnimalPerPage,
    createFilterObject(),
    debouncedSearchQuery,
    sortColumn,
    sortDirection
  )

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleTabChange = (_: unknown, newValue: string) => {
    setSelectedTab(newValue)
    let adoptionFilterValues: AnimalAdoptionDtoStatus[] = []
    let cannotBeAdoptedFilterValues: AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty[] = []
    switch (newValue) {
      case 'all':
        adoptionFilterValues = []
        cannotBeAdoptedFilterValues = [...Object.values(AnimalAdoptionDtoCannotBeAdoptedStatus), 'empty']
        break
      case 'followed':
        adoptionFilterValues = [
          AnimalAdoptionDtoStatus.Adoptable,
          AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable,
          AnimalAdoptionDtoStatus.CannotBeAdopted,
        ]
        cannotBeAdoptedFilterValues = [
          AnimalAdoptionDtoCannotBeAdoptedStatus.BeingSocialized,
          AnimalAdoptionDtoCannotBeAdoptedStatus.InCare,
          AnimalAdoptionDtoCannotBeAdoptedStatus.InGestation,
          AnimalAdoptionDtoCannotBeAdoptedStatus.InLactation,
          AnimalAdoptionDtoCannotBeAdoptedStatus.Released,
          AnimalAdoptionDtoCannotBeAdoptedStatus.LongTermWithHostedFamily,
          'empty',
        ]
        break
      case 'archived':
        adoptionFilterValues = [
          AnimalAdoptionDtoStatus.HasBeenAdopted,
          AnimalAdoptionDtoStatus.CannotBeAdopted,
          AnimalAdoptionDtoStatus.ToBeFilled,
        ]
        cannotBeAdoptedFilterValues = [
          AnimalAdoptionDtoCannotBeAdoptedStatus.Dead,
          AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation,
          AnimalAdoptionDtoCannotBeAdoptedStatus.Lost,
        ]
        break
    }
    setAdoptionStatusFilter(adoptionFilterValues)
    setCannotBeAdoptedStatusFilter(cannotBeAdoptedFilterValues)
  }

  const FiltersBar = () => {
    return (
      <>
        <FilterList label="Résponsable" icon={<Star />}>
          <FilterListItem
            label="Mes animaux"
            isSelected={myAnimalsOnly}
            toggleSelected={() => setMyAnimalsOnly(!myAnimalsOnly)}
          />
        </FilterList>

        <FilterList label="Identification" icon={<ContentPaste />}>
          <FilterListItem
            label="Immatriculé"
            isSelected={hasIdentificationNumberFilter === BooleanOrAll.True}
            toggleSelected={() => {
              if (hasIdentificationNumberFilter === BooleanOrAll.True) {
                setHasIdentificationNumberFilter(BooleanOrAll.All)
              } else {
                setHasIdentificationNumberFilter(BooleanOrAll.True)
              }
            }}
          />
          <FilterListItem
            label="Manquante"
            isSelected={hasIdentificationNumberFilter === BooleanOrAll.False}
            toggleSelected={() => {
              if (hasIdentificationNumberFilter === BooleanOrAll.False) {
                setHasIdentificationNumberFilter(BooleanOrAll.All)
              } else {
                setHasIdentificationNumberFilter(BooleanOrAll.False)
              }
            }}
          />
        </FilterList>

        <FilterList label="Sterilisée/Castré" icon={<Transgender />}>
          {Object.values(BooleanOrUnknown).map((componentStatus) => {
            return (
              <FilterListItem
                key={componentStatus}
                label={getReadableBooleanOrUnknown(componentStatus)}
                isSelected={sterilizedOrNeuteredFilter.includes(componentStatus)}
                toggleSelected={() => {
                  if (sterilizedOrNeuteredFilter.includes(componentStatus)) {
                    const newFilter = sterilizedOrNeuteredFilter.filter((aStatus) => aStatus !== componentStatus)
                    setSterilizedOrNeuteredFilter(newFilter)
                  } else {
                    setSterilizedOrNeuteredFilter([...sterilizedOrNeuteredFilter, componentStatus])
                  }
                }}
              />
            )
          })}
        </FilterList>

        <FilterList label="Espèce" icon={<Pets />}>
          {accountsStore.connectedCustomer?.preferences.species.map((componentStatus) => {
            return (
              <FilterListItem
                key={componentStatus}
                label={getReadableSpecies(componentStatus)}
                isSelected={speciesFilter.includes(componentStatus)}
                toggleSelected={() => {
                  if (speciesFilter.includes(componentStatus)) {
                    const newFilter = speciesFilter.filter((aStatus) => aStatus !== componentStatus)
                    setSpeciesFilter(newFilter)
                  } else {
                    setSpeciesFilter([...speciesFilter, componentStatus])
                  }
                }}
              />
            )
          })}
        </FilterList>

        <FilterList label="Sexe" icon={<Wc />}>
          {Object.values(AnimalDtoSex).map((componentStatus) => (
            <FilterListItem
              key={componentStatus}
              label={getReadableSex(componentStatus)}
              isSelected={sexFilter === componentStatus}
              toggleSelected={() => {
                if (sexFilter === componentStatus) {
                  setSexFilter(null)
                } else {
                  setSexFilter(componentStatus)
                }
              }}
            />
          ))}
        </FilterList>

        <FilterList label="Statut d'adoption" icon={<PersonPin />}>
          {Object.values(AnimalAdoptionDtoStatus).map((componentStatus) => {
            return (
              <FilterListItem
                key={componentStatus}
                label={getReadableAnimalAdoptionStatus(componentStatus)}
                isSelected={adoptionStatusFilter.includes(componentStatus)}
                toggleSelected={() => {
                  if (adoptionStatusFilter.includes(componentStatus)) {
                    if (componentStatus === AnimalAdoptionDtoStatus.CannotBeAdopted) {
                      // Set all "cannot-be-adopted" statuses
                      setCannotBeAdoptedStatusFilter(Object.values(AnimalAdoptionDtoCannotBeAdoptedStatus))
                    }
                    if (componentStatus === AnimalAdoptionDtoStatus.HasBeenAdopted) {
                      // Set all "has-been-adopted" statuses
                      setStepFilter(Object.values(AnimalAdoptionDtoStep))
                    }
                    const newFilter = adoptionStatusFilter.filter((aStatus) => aStatus !== componentStatus)
                    setAdoptionStatusFilter(newFilter)
                  } else {
                    setAdoptionStatusFilter([...adoptionStatusFilter, componentStatus])
                  }
                }}
              />
            )
          })}
          {adoptionStatusFilter.includes('has-been-adopted') && (
            <FilterList label="État de l'adoption" icon={<PersonPin />}>
              {[...Object.values(AnimalAdoptionDtoStep), 'empty'].map((step) => (
                <FilterListItem
                  key={step}
                  label={step === 'empty' ? 'Vide' : getReadableAnimalAdoptionStep(step as AnimalAdoptionDtoStep)}
                  isSelected={stepFilter.includes(step as AnimalAdoptionDtoStepWithEmpty)}
                  toggleSelected={() => {
                    if (stepFilter.includes(step as AnimalAdoptionDtoStepWithEmpty)) {
                      setStepFilter(stepFilter.filter((aStep) => aStep !== step))
                    } else {
                      setStepFilter([...stepFilter, step as AnimalAdoptionDtoStepWithEmpty])
                    }
                  }}
                />
              ))}
            </FilterList>
          )}
          {adoptionStatusFilter.includes('cannot-be-adopted') && (
            <FilterList label="Raison non-adoption" icon={<PersonPin />}>
              {[...Object.values(AnimalAdoptionDtoCannotBeAdoptedStatus), 'empty'].map((status) => (
                <FilterListItem
                  key={status}
                  label={
                    status === 'empty'
                      ? 'Vide'
                      : getReadableCannotBeAdoptedStatus(status as AnimalAdoptionDtoCannotBeAdoptedStatus)
                  }
                  isSelected={cannotBeAdoptedStatusFilter.includes(
                    status as AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty
                  )}
                  toggleSelected={() => {
                    if (
                      cannotBeAdoptedStatusFilter.includes(status as AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty)
                    ) {
                      setCannotBeAdoptedStatusFilter(
                        cannotBeAdoptedStatusFilter.filter((aStatus) => aStatus !== status)
                      )
                    } else {
                      setCannotBeAdoptedStatusFilter([
                        ...cannotBeAdoptedStatusFilter,
                        status as AnimalAdoptionDtoCannotBeAdoptedStatusWithEmpty,
                      ])
                    }
                  }}
                />
              ))}
            </FilterList>
          )}
        </FilterList>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <AnimalExportPopUp />
        </Box>
        <AQuestion />
      </>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        padding: 1,
        paddingBottom: 7.5,
        backgroundColor: 'grey.100',
        minHeight: `calc(100% - 20px)`,
        boxSizing: 'border-box',
        marginBottom: 2,
        maxHeight: `calc(100% - 20px)`,
      }}
    >
      {animalStore.isLoading && !animalStore.areAnimalsInitialized ? (
        <CircularProgressPanel />
      ) : (
        <>
          {/* Filters for desktop version */}
          <Card
            sx={{
              ...spacingItem,
              width: 230,
              display: { xs: 'none', sm: 'block' },
              overflowY: 'auto',
            }}
          >
            <CardContent>
              <FiltersBar />
            </CardContent>
          </Card>

          {/* Filters for mobile version */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
            }}
          >
            <Box sx={{ padding: 2 }}>
              <FiltersBar />
            </Box>
          </Drawer>

          <Card
            sx={{
              ...spacingItem,
              marginLeft: { xs: 0, sm: 2 },
              width: '100%',
              maxHeight: '100%',
              boxSizing: 'border-box',
              position: 'relative',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                marginBottom: '1rem',
                borderBottom: '1px solid lightgray',
              }}
            >
              <Tab
                label="Tous"
                value="all"
                sx={{
                  textTransform: 'none',
                  borderRadius: '5px 5px 0 0',
                  border: '1px solid lightgray',
                  borderBottom: 'none',
                  marginRight: '5px',
                  marginBottom: '-1px',
                  '&.Mui-selected': {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              />
              <Tab
                label="Animaux suivis"
                value="followed"
                sx={{
                  textTransform: 'none',
                  borderRadius: '5px 5px 0 0',
                  border: '1px solid lightgray',
                  borderBottom: 'none',
                  marginRight: '5px',
                  marginBottom: '-1px',
                  '&.Mui-selected': {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              />
              <Tab
                label="Animaux archivés"
                value="archived"
                sx={{
                  textTransform: 'none',
                  borderRadius: '5px 5px 0 0',
                  border: '1px solid lightgray',
                  borderBottom: 'none',
                  marginRight: '5px',
                  marginBottom: '-1px',
                  '&.Mui-selected': {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              />
            </Tabs>
            <Typography sx={{ position: 'absolute', top: 10, right: 10, display: { xs: 'none', sm: 'block' } }}>{`${
              JSON.stringify(createFilterObject()) === JSON.stringify(baseFilterObject) ? 'Total' : 'Sélectionné(s)'
            }: ${animalStore.animalsCount}`}</Typography>
            {assoHasAnimals && (
              <Box display="flex" flexDirection="row" flexWrap="nowrap">
                <TextField
                  id="name-input"
                  placeholder="Rechercher un animal (nom, nº d'immatriculation, membres...)"
                  type="text"
                  fullWidth
                  value={searchQuery}
                  onChange={onChangeSearch}
                  sx={{
                    borderRadius: 0,
                    [`& fieldset`]: {
                      borderRadius: 0,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ display: { sm: 'none' }, mx: 'auto' }}
                >
                  <Tune />
                </IconButton>
              </Box>
            )}
            <Box
              sx={{
                width: '100%',
                maxHeight: '100%',
                boxSizing: 'border-box',
                overflow: 'auto',
                paddingBottom: 7, // HACK: To avoid the last row to be hidden
                transform: 'translateZ(0)',
              }}
              ref={scrollRef}
            >
              {assoHasAnimals ? (
                <>
                  <AnimalsTable
                    animals={animalStore.animals}
                    setSortColumn={setSortColumn}
                    setSortDirection={setSortDirection}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    disableFrontSorting={true}
                  />

                  <Pagination
                    count={Math.ceil(animalStore.animalsCount / maxAnimalPerPage)}
                    page={page}
                    onChange={(_, page) => {
                      setPage(page)
                    }}
                    sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, paddingBottom: 2, mb: 8 }}
                  />
                </>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    p: { xs: 4, md: 10 },
                    gap: 2,
                  }}
                >
                  <Typography variant="h2" sx={{ fontFamily: 'Insaniburger', color: blueSky }}>
                    Bienvenue sur Petso !
                  </Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: 26 }}>
                    Vous disposez à présent d&#39;un espace gratuit dédié à votre association.
                  </Typography>
                  <VideoPopIn linkStyle={true} />
                  <Typography sx={{ fontWeight: 500, fontSize: 24 }}>
                    Petso a été conçu en collaboration avec des associations et refuges pour faciliter la prise en
                    charge et le suivi de vos animaux. Commençons par le commencement, sur cette plateforme vous pouvez
                    assurer :
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 2,
                      justifyContent: 'left',
                      alignItems: 'center',
                      mt: 4,
                      mb: { xs: 10, md: 0 },
                    }}
                  >
                    <SuiviCard
                      title="Membres et vétos"
                      subtitle="Petso facilite la collaboration entre membres, familles d'accueil et vétérinaires"
                      description="Ajoutez vos membres, configurez des niveaux de permissions différents, enregistrez leurs informations et rattachez les aux animaux dont-ils sont en charge."
                      link={PATHS.ajouterBenevole.absolute}
                      buttonText="Ajouter un membre/véto"
                    />
                    <SuiviCard
                      title="Animaux"
                      subtitle="Ajoutez vos animaux et complétez leurs fiches pour faciliter leur suivi et leur bien-être"
                      description="Renseignez leurs antécédents de santé, centralisez leurs documents, bénéficiez de rappels de santé, éditez en un clic registres et bons vétérinaire."
                      link={PATHS.ajouterAnimal.absolute}
                      buttonText="Ajouter un animal"
                    />
                    <SuiviCard
                      title="Adoptants"
                      subtitle="Petso facilite la collaboration entre membre, famille d'accueil et vétérinaire"
                      description="Ajoutez vos membres, configurez des niveaux de permissions différents, enregistrez leurs informations et rattachez les aux animaux dont ils sont en charge."
                      link={PATHS.ajouterAdoptant.absolute}
                      buttonText="Ajouter un adoptant"
                    />
                  </Box>
                </Box>
              )}
            </Box>

            {account && accountCanEdit(account) && (
              <Box
                sx={{
                  position: 'fixed',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  right: 2,
                  bottom: 0,
                  margin: 2,
                }}
              >
                {accountsStore.isAdmin() && <AnimalSheetPopUp />}
                <Fab color="primary" variant="extended" onClick={() => navigate(PATHS.ajouterAnimal.absolute)}>
                  <Add sx={{ mr: 1 }} />
                  Ajouter un animal
                </Fab>
              </Box>
            )}
          </Card>
        </>
      )}
    </Box>
  )
}

interface SuiviCardProps {
  title: string
  subtitle: string
  description: string
  link: string
  buttonText: string
}

const SuiviCard: React.FC<SuiviCardProps> = ({ title, subtitle, description, link, buttonText }) => {
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        maxWidth: { xs: '100%', md: '32%' },
        width: '600px',
        mb: { xs: 10, md: 0 },
      }}
    >
      <Typography variant="h2" sx={{ fontFamily: 'Insaniburger', color: blueSky, fontSize: 36 }}>
        {title}
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: 20 }}>{subtitle}</Typography>
      <Typography sx={{ fontWeight: 500, fontSize: 20 }}>{description}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(link)}
          disabled={!accountsStore.isAdmin()}
          sx={{ width: '65%', height: 50, mt: 2, borderRadius: 3 }}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  )
}
