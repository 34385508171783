export const getAnimalProfilePhotoSrc = (profileImageKey: string) => {
  return `https://animals-photos.s3.amazonaws.com/${profileImageKey}`
}

export const getAccountProfilePhotoSrc = (profileImageKey: string) => {
  return `https://accounts-photos.s3.amazonaws.com/${profileImageKey}`
}

export const getCustomerProfilePhotoSrc = (profileImageKey: string) => {
  return `https://petso-customers-photos.s3.eu-west-3.amazonaws.com/${profileImageKey}`
}

export const getAnimalDocumentsPhotoSrc = (profileImageKey: string) => {
  if (!profileImageKey) {
    return ''
  }

  return `https://animals-documents.s3.amazonaws.com/${profileImageKey}`
}

export const getCustomerDocumentsSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://petso-customers-documents.s3.amazonaws.com/${documentKey}`
}

export const getCustomerTemplatesSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://petso-customers-templates.s3.eu-west-3.amazonaws.com/${documentKey}`
}

export const getCustomerSignatureSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://petso-customers-signatures.s3.eu-west-3.amazonaws.com/${documentKey}`
}

export const getAccountDocumentsSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://petso-accounts-documents.s3.amazonaws.com/${documentKey}`
}

export const getAdoptionAttemptDocumentsSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://petso-adoption-attempts-documents.s3.amazonaws.com/${documentKey}`
}
