import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Box, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { FC } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { PATHS } from '../PATHS'
import { AuthClient } from '../../interactors/clients/AuthClient'

interface Props {
  sx: { marginTop: number }
}

export const ModifyPasswordMemberPrompt: FC<Props> = ({ sx }) => {
  const navigate = useNavigate()
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{ newPassword: string; confirmPassword: string }>()

  const onSubmit = (data: { newPassword: string }) => {
    mutation.mutate(data)
  }

  const validatePasswordsMatch = (value: string) => {
    if (value !== watch('newPassword')) {
      return 'Les mots de passe ne correspondent pas'
    }
  }

  const validatePassword = (value: string) => {
    if (value.length < 8) {
      return 'Le mot de passe doit au moins contenir 8 caractères'
    }
  }

  // Mutation qui permet de modifier le mot de passe
  const mutation = useMutation(
    async (params: { newPassword: string }) => await AuthClient.updatePassword(params.newPassword),
    {
      onSuccess: () => {
        closeDialog()
        navigate(PATHS.suiviBenevole.absolute)
        globalSnackbar.triggerSuccessMessage(`Votre mot de passe a bien été modifié !`)
      },
    }
  )

  return (
    <>
      <MenuItem key="modifyPassword" onClick={openDialog}>
        {'Modifier le mot de passe'}
      </MenuItem>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box marginBottom={4}>Veuillez entrer un nouveau mot de passe:</Box>
            <Box display="flex" justifyContent="center" sx={sx}>
              <TextField
                id="new-password-input"
                label="Nouveau mot de passe"
                type="password"
                InputLabelProps={{ shrink: true }}
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
                sx={{ width: '100%' }}
                {...register('newPassword', { validate: validatePassword })}
              />
            </Box>
            <Box display="flex" justifyContent="center" sx={sx}>
              <TextField
                id="confirm-password-input"
                label="Confirmer le mot de passe"
                type="password"
                InputLabelProps={{ shrink: true }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                sx={{ width: '100%' }}
                {...register('confirmPassword', { validate: validatePasswordsMatch })}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel" onClick={closeDialog}>
              Annuler
            </Button>
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              type="submit"
              sx={{ backgroundColor: 'error.main' }}
              loading={mutation.isLoading}
            >
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
