import axios from 'axios'

const getBackendBaseUrl = (): string => {
  const currentFrontendUrl = window.location.href

  if (currentFrontendUrl.includes('.petso.fr')) {
    return 'https://petso-backend.herokuapp.com'
  }

  // return 'http://localhost:3007'

  return 'https://petso-backend.herokuapp.com'
}

export const backendClient = axios.create({
  baseURL: `${getBackendBaseUrl()}/v1`,
})
