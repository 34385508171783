import { Box, Typography, Avatar, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomerDocumentDtoType, CustomerDto, CustomerDtoTypes } from '../../interactors/gen/backendClient'
import { PATHS } from '../PATHS'
import { Link, useLocation } from 'react-router-dom'
import { setOpacity } from '../../utils/setOpacity'
import { useAccountsStore } from '../../store/AccountsStore'
import { getReadableCustomerDocumentType } from '../../domain/Customer/CustomerDisplay'
import { useMutation } from 'react-query'
import { CustomersClient } from '../../interactors/clients/CustomerClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { getCustomerProfilePhotoSrc } from '../../utils/S3-links'
import { PhotoCamera } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { spacingItem } from '../theme'

interface AssociationAvatarAndNameProps {
  association: CustomerDto
}

export const AssociationAvatarAndName: React.FC<AssociationAvatarAndNameProps> = ({ association }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [previewURL, setPreviewURL] = useState<string | null>(null)
  const [isUploadDone, setIsUploadDone] = useState<boolean>(false)
  const [tab, setTab] = useState<string | null>(null)
  const location = useLocation()
  const accountsStore = useAccountsStore()
  const globalSnackBarStore = useGlobalSnackbarStore()

  const handleImageSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null
    if (selectedFile) {
      setSelectedImage(selectedFile)
      setPreviewURL(URL.createObjectURL(selectedFile))
    }
    setIsUploadDone(false)
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const currentTab = queryParams.get('tab')
    setTab(currentTab)
  }, [location.search])
  const associationImageKey = `https://logo-customer-images.s3.eu-west-3.amazonaws.com/${association?.id}.jpg`

  //Mutation pour l'upload du nouvel avatar
  const uploadMutation = useMutation(async () => await CustomersClient.uploadProfileImage(selectedImage as File), {
    onSuccess: () => {
      globalSnackBarStore.triggerSuccessMessage(`La photo de profil de ${association.name} a été mise à jour.`)
      setIsUploadDone(true)
    },
    onError: () => {
      globalSnackBarStore.triggerErrorMessage(
        `La photo de profil de ${association.name} a echoué. Il est probable que l'image soit trop grosse.`
      )
      setIsUploadDone(true)
    },
  })

  const profileImageKey = association?.profileImageKey

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar
            alt={association ? association.name : undefined}
            src={previewURL || (profileImageKey && getCustomerProfilePhotoSrc(profileImageKey)) || associationImageKey}
            sx={{ width: 168, height: 168 }}
          />
          {/* Bouton permettant de changer la photo de profil */}
          {accountsStore.canEdit() && (
            <IconButton
              component="label"
              sx={{
                position: 'relative',
                bottom: 45,
                marginLeft: 14,
                backgroundColor: '#e0e0e0',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: '#bdbdbd',
                },
              }}
            >
              <PhotoCamera />
              <input hidden accept="image/*" multiple type="file" onChange={handleImageSelection} />
            </IconButton>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-around" sx={spacingItem}>
          {previewURL && !isUploadDone && (
            <LoadingButton
              component="label"
              variant="contained"
              onClick={async () => {
                await uploadMutation.mutate()
              }}
              sx={{ marginLeft: 2 }}
              loading={uploadMutation.isLoading}
            >
              Mettre à jour la photo
            </LoadingButton>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={2} marginLeft={2}>
          <Typography variant="h4">{association?.name}</Typography>
          {!accountsStore.isPremium() && (tab === 'cages' || tab === 'donation-tracking') && (
            <Box
              sx={{
                padding: 2,
                color: (theme) => theme.palette.common.black,
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- TODO: Fix template expression
                border: (theme) => `1px solid ${theme.palette.info.main}`,
                borderRadius: (theme) => theme.spacing(0.5),
                margin: (theme) => theme.spacing(1, 0),
                fontSize: (theme) => theme.typography.body2.fontSize,
                background: (theme) => setOpacity(theme.palette.info.main, 0.2),
                fontWeight: 700,
              }}
            >
              <Typography sx={{ fontWeight: 700, fontSize: (theme) => theme.typography.body2.fontSize }}>
                Pour bénéficier intégralement de cette fonctionnalité,{' '}
                <Link style={{ color: 'black' }} to={`${PATHS.association.absolute}?tab=functionalities`}>
                  découvrez l&#39;offre utile dans mes abonnements
                </Link>
                <br />
                Toutes les données affichées actuellement sont fictives.
              </Typography>
            </Box>
          )}
          {!checkIfCustomerIsFullfilled(association) && (tab === 'my-association' || tab === '' || tab === null) && (
            <Box
              sx={{
                padding: 2,
                color: (theme) => theme.palette.common.black,
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- TODO: Fix template expression
                border: (theme) => `1px solid ${theme.palette.info.main}`,
                borderRadius: (theme) => theme.spacing(0.5),
                margin: (theme) => theme.spacing(1, 0),
                fontSize: (theme) => theme.typography.body2.fontSize,
                background: (theme) => setOpacity(theme.palette.info.main, 0.2),
                fontWeight: 700,
              }}
            >
              <Typography sx={{ fontWeight: 700, fontSize: (theme) => theme.typography.body2.fontSize }}>
                Afin de valider votre compte:
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: (theme) => theme.typography.body2.fontSize }}>
                1. <span style={{ textDecoration: 'underline' }}>Compléter</span>: les informations relatives à votre
                organisme et la déclaration des membres du Bureau.
                <br />
                2. <span style={{ textDecoration: 'underline' }}>Joindre dans le sous-onglet «Documents»</span>: les
                documents officiels nécessaires au bon fonctionnement de toute APA.
              </Typography>
            </Box>
          )}
          {checkIfCustomerIsFullfilled(association) &&
            checkIfCustomerHasAllRequiredDocuments(association) !== false &&
            checkIfCustomerHasAllRequiredDocuments(association) !== true &&
            (tab === 'my-association' || tab === '' || tab === null || tab === 'documents') && (
              <Box
                sx={{
                  padding: 2,
                  color: (theme) => theme.palette.common.black,
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- TODO: Fix template expression
                  border: (theme) => `1px solid ${theme.palette.info.main}`,
                  borderRadius: (theme) => theme.spacing(0.5),
                  margin: (theme) => theme.spacing(1, 0),
                  fontSize: (theme) => theme.typography.body2.fontSize,
                  background: (theme) => setOpacity(theme.palette.info.main, 0.2),
                  fontWeight: 700,
                }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: (theme) => theme.typography.body2.fontSize }}>
                  Afin de valider votre compte:
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: (theme) => theme.typography.body2.fontSize }}>
                  1.{' '}
                  <span style={{ textDecoration: 'underline' }}>Merci de joindre dans le sous-onglet «Documents»</span>{' '}
                  les documents officiels manquants:
                  <br />
                  {(checkIfCustomerHasAllRequiredDocuments(association) as CustomerDocumentDtoType[])
                    .map((doc) => getReadableCustomerDocumentType(doc))
                    .join(', ')}
                </Typography>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  )
}

function checkIfCustomerIsFullfilled(customer: CustomerDto): boolean {
  if (
    customer &&
    ((customer.SIRETNumber && customer.SIRETNumber.toString().length > 0) ||
      (customer.RNANumber && customer.RNANumber.length > 0)) &&
    customer.address &&
    customer.address.length > 0 &&
    customer.city &&
    customer.city.length > 0
  ) {
    if (customer.bureauMembers && customer.bureauMembers.length > 0) {
      return true
    }
  }

  return false
}

function checkIfCustomerHasAllRequiredDocuments(customer: CustomerDto): boolean | CustomerDocumentDtoType[] {
  const missingDocuments: CustomerDocumentDtoType[] = []

  if (customer && customer.documents && customer.documents.length > 0) {
    let requiredDocuments: CustomerDocumentDtoType[] = []

    if (customer.types && customer.types.length === 1 && customer.types[0] === CustomerDtoTypes.Shelter) {
      requiredDocuments = [CustomerDocumentDtoType.ActivityDeclaration]
    } else {
      requiredDocuments = [
        CustomerDocumentDtoType.SituationNotice,
        CustomerDocumentDtoType.OfficialJournalPublication,
        CustomerDocumentDtoType.BureauMembersDeclaration,
        CustomerDocumentDtoType.LegalStatus,
      ]
    }

    const customerDocuments = customer.documents.map((document) => document.type)

    // Checking for missing documents
    for (const doc of requiredDocuments) {
      if (!customerDocuments.includes(doc)) {
        missingDocuments.push(doc)
      }
    }

    if (missingDocuments.length === 0) {
      return true
    }
  }
  return missingDocuments
}
