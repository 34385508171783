import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  isMembersFull: boolean
}

export const UserNumberAlertBanner: React.FC<Props> = ({ isMembersFull }) => {
  return (
    <Box sx={{ backgroundColor: '#FFFF0', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '1200px', textAlign: 'center', p: 1 }}>
        <Link to="/association?tab=functionalities" style={{ color: 'black', textDecoration: 'none' }}>
          {isMembersFull ? (
            <Typography>
              Vous avez atteint la limite d&#39;ajout de membre possibles avec votre abonnement,{' '}
              <span style={{ textDecoration: 'underline' }}>consultez nos autres solutions</span>. Bénéficiez quoi
              qu&#39;il en soit de votre espace !
            </Typography>
          ) : (
            <Typography>
              Vous arrivez bientôt à la limite d&#39;ajout de membre possibles avec votre plan, pour en ajouter plus
              <span style={{ textDecoration: 'underline' }}>consultez nos autres plan</span>. Vous pourrez quoi
              qu&#39;ils en soit continuer de profiter de votre espace !
            </Typography>
          )}
        </Link>
      </Box>
    </Box>
  )
}
