import { LoadingButton } from '@mui/lab'
import { Box, Container, Paper, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomersClient } from '../../interactors/clients/CustomerClient'

export const SignatureScreen: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [isDrawing, setIsDrawing] = useState(false)
  const [hasSignature, setHasSignature] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [lastPos, setLastPos] = useState<{ offsetX: number; offsetY: number } | null>(null)
  const navigate = useNavigate()

  // Handle mouse/touch down event
  const handlePointerDown = (event: React.MouseEvent | React.TouchEvent) => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        const { offsetX, offsetY } = getPointerPosition(event)
        setLastPos({ offsetX, offsetY })
        setIsDrawing(true)
        ctx.beginPath()
        ctx.moveTo(offsetX, offsetY)
      }
    }
  }

  // Handle mouse/touch move event
  const handlePointerMove = (event: React.MouseEvent | React.TouchEvent) => {
    if (isDrawing && canvasRef.current && lastPos) {
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        const { offsetX, offsetY } = getPointerPosition(event)

        // Use quadratic Bézier curves for smoother lines
        ctx.quadraticCurveTo(
          lastPos.offsetX,
          lastPos.offsetY,
          (lastPos.offsetX + offsetX) / 2,
          (lastPos.offsetY + offsetY) / 2
        )
        ctx.stroke()

        setLastPos({ offsetX, offsetY })
      }
    }
  }

  // Handle mouse/touch up event
  const handlePointerUp = () => {
    if (isDrawing) {
      setIsDrawing(false)
      setHasSignature(true)
      setLastPos(null)
    }
  }

  // Clear the canvas
  const clearCanvas = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
        setHasSignature(false)
      }
    }
  }

  // Save the signature as a PNG file
  const saveSignature = async () => {
    if (canvasRef.current) {
      setIsLoading(true)

      // Convert the data URL to a Blob
      const dataUrl = canvasRef.current.toDataURL('image/png')

      // Convert the data URL to a Blob object
      const response = await fetch(dataUrl)
      const blob = await response.blob()

      // Create a File object from the Blob
      const file = new File([blob], 'signature.png', { type: 'image/png' })

      // Upload the file using the CustomersClient
      await CustomersClient.setSignatureFile(file)

      navigate('/association?tab=templates')
      setIsLoading(false)
    }
  }

  // Function to get the correct mouse/touch position relative to the canvas
  const getPointerPosition = (event: React.MouseEvent | React.TouchEvent) => {
    const canvas = canvasRef.current
    if (!canvas) return { offsetX: 0, offsetY: 0 }

    const rect = canvas.getBoundingClientRect()
    const scaleX = canvas.width / rect.width
    const scaleY = canvas.height / rect.height

    let clientX, clientY

    if ('touches' in event) {
      clientX = event.touches[0].clientX
      clientY = event.touches[0].clientY
    } else {
      clientX = event.clientX
      clientY = event.clientY
    }

    return {
      offsetX: (clientX - rect.left) * scaleX,
      offsetY: (clientY - rect.top) * scaleY,
    }
  }

  return (
    <Container maxWidth="lg" sx={{ mb: 10 }}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '16px', md: '22px' } }}>
          Créer une signature pour l&#39;asso
        </Typography>
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            overflow: 'hidden',
            position: 'relative',
            marginBottom: 2,
          }}
        >
          <canvas
            ref={canvasRef}
            width={600}
            height={200}
            style={{ width: '100%', height: 'auto', backgroundColor: '#fff' }}
            onMouseDown={handlePointerDown}
            onMouseMove={handlePointerMove}
            onMouseUp={handlePointerUp}
            onTouchStart={handlePointerDown}
            onTouchMove={handlePointerMove}
            onTouchEnd={handlePointerUp}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={clearCanvas}
            disabled={!hasSignature}
            loading={isLoading}
          >
            Effacer
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={saveSignature}
            disabled={!hasSignature}
            loading={isLoading}
          >
            Enregistrer la signature
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  )
}
