import { AccountDto } from '../../interactors/gen/backendClient'
import { formatStringForSearch } from '../shared/formatStringForSearch'

export const searchMembers = (members: Array<AccountDto>, searchQuery: string): Array<AccountDto> => {
  return members.filter(
    (account) =>
      formatStringForSearch(`${account.firstName} ${account.lastName}`).startsWith(
        formatStringForSearch(searchQuery)
      ) ||
      formatStringForSearch(`${account.lastName} ${account.firstName}`).startsWith(
        formatStringForSearch(searchQuery)
      ) ||
      formatStringForSearch(`${account.email}`).startsWith(formatStringForSearch(searchQuery)) ||
      formatStringForSearch(`${account.city}`).startsWith(formatStringForSearch(searchQuery)) ||
      formatStringForSearch(`${account.address}`).startsWith(formatStringForSearch(searchQuery)) ||
      formatStringForSearch(`${account.postalCode}`).startsWith(formatStringForSearch(searchQuery))
  )
}
