import React from 'react'
import { CustomerDto, DonationTrackingDto } from '../../../interactors/gen/backendClient'
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { blue, blueSky, colors, theme } from '../../theme'
import { setOpacity } from '../../../utils/setOpacity'
import { PreConfiguredPieChart } from '../../common/PreConfiguredPieChart'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  TooltipProps,
  Legend,
} from 'recharts'
import { getReadableDonationTrackingTypeOfDonation } from '../../../domain/Donations/DonationTrackingDisplay'
import { useFetchOrRefreshDonatersOnMount } from '../../../store/useFetchOrRefreshDonatersOnMount'
import { useDonatersStore } from '../../../store/DonatersStore'
import { getReadableDonatorType } from '../../../domain/Donations/DonatorDisplay'
import dayjs from 'dayjs'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { DefineDatesToFilter } from './DefineDatesToFilter'

interface AssociationDonationDashboardProps {
  association: CustomerDto
  donations: DonationTrackingDto[]
  dateFrom: dayjs.Dayjs | null
  setDateFrom: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
  dateTo: dayjs.Dayjs | null
  setDateTo: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
}

const colorsForChart = [
  colors.anakiwa,
  colors.bermudaGray,
  colors.amber,
  '#A3A0FB',
  '#33D69F',
  '#FE6DA8',
  '#FF5722',
  '#009688',
  '#FF9800',
  '#673AB7',
]

export const AssociationDonationDashboard: React.FC<AssociationDonationDashboardProps> = ({
  association,
  donations,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) => {
  useFetchOrRefreshDonatersOnMount()
  const donatersStore = useDonatersStore()
  const totalDonationsAmount = donations.reduce((total, donation) => total + donation.amount, 0)

  const dataForDonationTypePieChart = donations.reduce((result, donation) => {
    const { typeOfDonation, amount } = donation

    // Apply the transformation to the typeOfDonation
    const readableTypeOfDonation = getReadableDonationTrackingTypeOfDonation(typeOfDonation)

    // Find the existing object for this readable type of donation
    const existingType = result.find((item) => item.name === readableTypeOfDonation)

    // If found, update the value
    if (existingType) {
      existingType.value += amount
    } else {
      // If not found, create a new object for this readable type of donation
      result.push({ name: readableTypeOfDonation, value: amount })
    }

    return result
  }, [] as { name: string; value: number }[])

  interface DonationType {
    name: string
    value: number
  }

  interface GroupedByTypeOfDonator {
    [type: string]: DonationType
  }

  const dataForTypeOfDonatorPieChart = donations.reduce<GroupedByTypeOfDonator>((result, donation) => {
    // Find the corresponding donator by donatorId
    const donator = donatersStore.donaters.find((d) => d.id === donation.donaterId)

    // If no corresponding donator found, just return the current result
    if (!donator) return result

    // Get the type of donator
    const type = donator.type

    // Initialize the type if not already in result
    if (!result[type]) {
      result[type] = { name: getReadableDonatorType(type), value: 0 }
    }

    // Add the donation amount to the total for this type
    result[type].value += donation.amount

    return result
  }, {})

  const mappedDonations = donations.map((donation) => ({
    date: dayjs(donation.dateCollected).format('YYYY-MM-DD'), // Format to a sortable date string
    amount: donation.amount,
  }))

  // Reduce the mapped array to group by date and sum the amounts
  const groupedByDate = mappedDonations.reduce((result, { date, amount }) => {
    if (!result[date]) {
      result[date] = { name: date, value: 0 }
    }
    result[date].value += amount
    return result
  }, {} as Record<string, { name: string; value: number }>)

  // Convert the grouped object to an array and sort by date
  const sortedArray = Object.values(groupedByDate).sort((a, b) => (a.name > b.name ? 1 : -1))

  // Initialize running total variable
  let runningTotal = 0

  // Format the dates to "DD/MM/YYYY" and compute the running total
  const dataForDonationOnTimeChart = sortedArray.map((item) => {
    runningTotal += item.value
    return {
      name: dayjs(item.name).format('DD/MM/YYYY'),
      value: runningTotal,
    }
  })

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: 1,
            paddingLeft: 4,
            paddingRight: 4,
            border: '1px solid lightgrey',
          }}
        >
          <p className="label">{`${label} : ${payload[0].value}€`}</p>
        </div>
      )
    }

    return null
  }

  const CustomTooltipForPieCharts = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: 1,
            paddingLeft: 4,
            paddingRight: 4,
            border: '1px solid lightgrey',
          }}
        >
          <p className="label">{`${payload[0].name} : ${payload[0].value}€`}</p>
        </div>
      )
    }

    return null
  }

  return (
    <>
      <Card>
        <CardHeader
          title="Le coup d'oeil !"
          action={
            <DefineDatesToFilter dateFrom={dateFrom} setDateFrom={setDateFrom} dateTo={dateTo} setDateTo={setDateTo} />
          }
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            '& .MuiCardHeader-action': {
              marginTop: { xs: theme.spacing(1), sm: 0 },
            },
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
                sx={{
                  borderRadius: 5,
                  backgroundColor: setOpacity(blueSky, 0.25),
                  border: 2,
                  borderColor: blue,
                  paddingY: 5,
                  paddingX: 2,
                }}
              >
                <Box>
                  <Typography variant="h3" color={blue} fontSize={35}>
                    {association && `${totalDonationsAmount} €`}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" color={blue} fontSize={22} lineHeight={1.3} textAlign="center">
                    de dons collectés sur la période
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {donations.length > 1 ? (
              <>
                <Grid item xs={12} md={5}>
                  <Box display="flex">
                    <Box
                      display="flex"
                      flexDirection="column-reverse"
                      justifyContent="center"
                      alignItems="center"
                      height={350}
                      width="50%"
                    >
                      <PreConfiguredPieChart
                        data={dataForDonationTypePieChart}
                        colors={colorsForChart}
                        displayLegend
                        CustomTooltip={CustomTooltipForPieCharts}
                      />
                      <Typography variant="h6" color={blue} fontSize={{ sm: 20, xs: 18 }}>
                        Type de don
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column-reverse"
                      justifyContent="center"
                      alignItems="center"
                      height={350}
                      width="50%"
                    >
                      <PreConfiguredPieChart
                        data={Object.values(dataForTypeOfDonatorPieChart)}
                        colors={colorsForChart}
                        displayLegend
                        CustomTooltip={CustomTooltipForPieCharts}
                      />
                      <Typography variant="h6" color={blue} fontSize={{ sm: 20, xs: 18 }}>
                        Type de donateur
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Box
                    display="flex"
                    flexDirection="column-reverse"
                    justifyContent="center"
                    alignItems="center"
                    height={350}
                  >
                    <ResponsiveContainer width="100%" height="85%">
                      <LineChart width={500} height={300} data={dataForDonationOnTimeChart}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" type="category" dy={8} tick={{ fontSize: 15 }} />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend wrapperStyle={{ position: 'relative', marginTop: -15 }} />
                        <Line
                          name="Montant des donations (en €)"
                          type="monotone"
                          dataKey="value"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>

                    <Typography variant="h6" color={blue} fontSize={{ sm: 20, xs: 18 }}>
                      Evolution des dons
                    </Typography>
                  </Box>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={10}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="h6" color={blue} fontSize={{ sm: 20, xs: 18 }}>
                    Aucune information disponible sur cette période
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
