import MoreHoriz from '@mui/icons-material/MoreHoriz'
import LoadingButton from '@mui/lab/LoadingButton'
import { Avatar, Box, Grid, IconButton, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { ClearRounded, DoneRounded, PhotoCamera } from '@mui/icons-material'
import { useMutation } from 'react-query'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { useAnimalStore } from '../../../store/AnimalStore'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { getAnimalProfilePhotoSrc } from '../../../utils/S3-links'
import { CircularProgressPanel } from '../../common/CircularProgressPanel'
import { AnimalAdoptionTab } from './Adoption/AnimalAdoptionTab'
import { AnimalGeneralCard } from './AnimalGeneralCard'
import { AllAnimalInsightsInfoBox } from './AnimalVaccinationImportantInfo'
import { DeleteAnimalPrompt } from './DeleteAnimalPrompt'
import { AnimalDocumentsAndPicturesTab } from './DocumentsAndPictures/AnimalDocumentsAndPicturesTab'
import { AnimalAboutCards } from './General/AnimalAboutCards'
import { AnimalBreedCard } from './General/AnimalBreedCard'
import { AnimalResponsibilitiesCard } from './General/AnimalResponsabilityCard'
import { AnimalTakeOverCard } from './General/AnimalTakeOverCard'
import { AnimalHealthTab } from './Health/AnimalHealthTab'
import { HostedFamillyFollowUpsTab } from './HostedFammilyFollowUpsTab/HostedFamillyFollowUpsTab'

import { useCanEditAnimal } from '../../../store/useCanEditAnimal'
import { colors } from '../../theme'
import { AnimalOtherImagesCard } from './General/AnimalOtherImagesCard'
import { useEditAnimalMutation } from '../../../store/useEditAnimalMutation'
import InactivityPopup from '../../utils/InactivityPopup'

type TabKey = 'general' | 'sante' | 'familles' | 'documents' | 'adoption'

export const AnimalDetailsScreen: FC = () => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()
  const location = useLocation()
  const canEditAnimal = useCanEditAnimal()
  const editAnimalMutation = useEditAnimalMutation({})

  const [currentTabIndex, setCurrentTabIndex] = useState<TabKey>('general')

  const handleChange = (_: React.SyntheticEvent, newTabKey: TabKey) => {
    setCurrentTabIndex(newTabKey)
    navigate(`/animaux/${animalId}?tab=${newTabKey}`)
  }

  const { animalId } = useParams() as { animalId: string }
  const animalStore = useAnimalStore()

  const [isUploadDone, setIsUploadDone] = useState<boolean>(false)

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  useEffect(() => {
    const loadAnimal = async () => {
      await animalStore.changeSelectedAnimal(animalId)
    }

    loadAnimal()
  }, [animalId])

  const animal = animalStore.selectedAnimal

  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [previewURL, setPreviewURL] = useState<string | null>(null)
  const nameInputRef = useRef<HTMLInputElement>(null) // Create a ref

  const [editMode, setEditMode] = useState(false)
  const [tempName, setTempName] = useState(animal?.name || '')

  useEffect(() => {
    if (editMode && nameInputRef.current) {
      nameInputRef.current.focus() // Focus the input
      nameInputRef.current.select() // Select the text
    }
  }, [editMode])

  const handleImageSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null
    if (selectedFile) {
      setSelectedImage(selectedFile)
      setPreviewURL(URL.createObjectURL(selectedFile))
    }
    setIsUploadDone(false)
  }

  const uploadMutation = useMutation(
    async () => await AnimalsClient.uploadProfileImage(animal!.id, selectedImage as File),
    {
      onSuccess: (data) => {
        globalSnackBarStore.triggerSuccessMessage(`La photo de profil de ${animal!.name} a été mise à jour.`)
        setIsUploadDone(true)
        if (animal) {
          animal.images.profileImageKey = data.split('/').pop() as string
        }
      },
      onError: () => {
        globalSnackBarStore.triggerErrorMessage(
          `La photo de profil de ${animal!.name} a echoué. Il est probable que l'image soit trop grosse.`
        )
        setIsUploadDone(true)
      },
    }
  )

  const spacingItem = { marginTop: 2 }

  function a11yProps(tabKey: TabKey) {
    return {
      id: `simple-tab-${tabKey}`,
      value: tabKey,
      'aria-controls': `simple-tabpanel-${tabKey}`,
      key: tabKey,
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tabParam = queryParams.get('tab')
    const newTabKey = tabParam as TabKey
    setCurrentTabIndex(newTabKey || 'general')
  }, [location.search])

  if (animalStore.isLoading || !animal) {
    return <CircularProgressPanel />
  }

  const profileImageKey = animal.images?.profileImageKey

  // Handle the save action
  const handleSave = () => {
    animal.name = tempName
    editAnimalMutation.mutate(animal)
    setEditMode(false)
  }

  // Handle cancel action
  const handleCancel = () => {
    setTempName(animal?.name || '')
    setEditMode(false)
  }

  return (
    <Box sx={{ padding: 2, backgroundColor: 'grey.100', minHeight: '100%' }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginTop: '1rem', marginBottom: '5rem' }}>
        <InactivityPopup />
        <Box display="flex" alignItems="center">
          <Box sx={{ position: 'relative' }}>
            <Avatar
              alt={animal.name}
              src={previewURL || (profileImageKey && getAnimalProfilePhotoSrc(profileImageKey))}
              sx={{ width: 168, height: 168 }}
            />

            {canEditAnimal && (
              <IconButton
                component="label"
                sx={{
                  position: 'relative',
                  bottom: 45,
                  marginLeft: 14,
                  backgroundColor: '#e0e0e0',
                  borderRadius: '50%',
                  '&:hover': {
                    backgroundColor: '#bdbdbd',
                  },
                }}
              >
                <PhotoCamera />
                <input hidden accept="image/*" multiple type="file" onChange={handleImageSelection} />
              </IconButton>
            )}
          </Box>
          {editMode ? (
            <Box display="flex" alignItems="center" sx={{ gap: 1, ml: 2 }}>
              <TextField
                value={tempName}
                onChange={(e) => setTempName(e.target.value)}
                variant="standard"
                inputRef={nameInputRef}
                InputProps={{
                  disableUnderline: true,
                }}
                autoFocus
                sx={{ mr: 1, input: { fontSize: '2.125rem', color: colors.blue, width: '100%' } }}
              />

              <IconButton onClick={handleCancel} color="error" size="small">
                <ClearRounded />
              </IconButton>
              <IconButton onClick={handleSave} color="primary" size="small">
                <DoneRounded />
              </IconButton>
            </Box>
          ) : (
            <Typography variant="h4" sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
              {animal?.adoption.newName ? `${animal?.adoption.newName} (ex ${animal?.name})` : animal?.name}
            </Typography>
          )}
          {/* <Typography variant="h4" sx={{ marginLeft: 2 }}>
            {animal?.name}
            {animal?.adoption.newName && <Typography>Nouveau nom: {animal?.adoption.newName}</Typography>}
          </Typography> */}
          <Box display="flex" alignItems="center" justifyContent="space-around" sx={spacingItem}>
            {previewURL && !isUploadDone && (
              <LoadingButton
                component="label"
                variant="contained"
                onClick={async () => {
                  await uploadMutation.mutate()
                }}
                sx={{ marginLeft: 2 }}
                loading={uploadMutation.isLoading}
              >
                Mettre à jour la photo
              </LoadingButton>
            )}
          </Box>

          {canEditAnimal && (
            <Box display="flex" alignItems="center" justifyContent="space-around" sx={{ marginTop: 1, marginLeft: 1 }}>
              <IconButton onClick={handleOpenUserMenu}>
                <MoreHoriz />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <DeleteAnimalPrompt animal={animal} />
                <MenuItem
                  key="edit name"
                  onClick={() => {
                    setTempName(animal.name)
                    setEditMode(true)
                    handleCloseUserMenu()
                  }}
                >
                  {'Modifier le nom'}
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>

        <AnimalGeneralCard animal={animal} />

        <AllAnimalInsightsInfoBox animal={animal} />

        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab label="Général" {...a11yProps('general')} />
            <Tab label="Santé" {...a11yProps('sante')} />
            <Tab label="Adoption" {...a11yProps('adoption')} />
            <Tab label="Famille d'accueil et déplacement" {...a11yProps('familles')} />
            <Tab label="Documents" {...a11yProps('documents')} />
          </Tabs>
        </Box>

        <TabPanel value={currentTabIndex} index={'general' as TabKey}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <AnimalOtherImagesCard animal={animal} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AnimalResponsibilitiesCard animal={animal} />
              <AnimalTakeOverCard sx={spacingItem} animal={animal} />
              <AnimalBreedCard sx={spacingItem} animal={animal} />
            </Grid>

            <Grid item xs={12} sm={5}>
              <AnimalAboutCards animal={animal} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'sante' as TabKey}>
          <AnimalHealthTab animal={animal} />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'adoption' as TabKey}>
          <AnimalAdoptionTab animal={animal} />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'familles' as TabKey}>
          <HostedFamillyFollowUpsTab animal={animal} />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'documents' as TabKey}>
          <AnimalDocumentsAndPicturesTab animal={animal} />
        </TabPanel>
      </Box>
    </Box>
  )
}

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
