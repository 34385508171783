import { useEffect, useContext, MutableRefObject } from 'react'
import { ScrollContext } from './ScrollContext' // Assume we store scroll positions here
import { throttle } from 'lodash'
import { useAnimalStore } from '../../store/AnimalStore'
import { useLocation } from 'react-router-dom'

export const useComponentScrollRestoration = (ref: MutableRefObject<HTMLElement | null>, id: string): void => {
  const { scrollPositions, setScrollPosition } = useContext(ScrollContext)
  const animalStore = useAnimalStore()
  const { search } = useLocation()

  const filtersReset = search === ''

  useEffect(() => {
    // Restore scroll position if navigating back, else reset to 0
    const savedScrollPosition = !filtersReset ? scrollPositions[id] || 0 : 0

    if (ref.current) {
      ref.current.scrollTop = savedScrollPosition
    }
  }, [id, ref]) // Notice we don't include setScrollPosition to avoid re-triggering

  useEffect(() => {
    // Update scroll position on scroll, but only after initial restoration
    const element = ref.current
    if (!element || (!animalStore.areAnimalsInitialized && id === 'animal-list')) return
    if (element) {
      const handleScroll = throttle(() => {
        setScrollPosition(id, element.scrollTop)
      }, 100) // Adjust the delay as needed

      if (element) {
        element.addEventListener('scroll', handleScroll)
      }

      return () => {
        if (element) {
          element.removeEventListener('scroll', handleScroll)
        }
      }
    }
  }, [id, ref, setScrollPosition, animalStore.areAnimalsInitialized])
}
