import { Autocomplete, BaseTextFieldProps, Chip, SxProps, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { blueSky } from '../theme'
import { isColorDark } from '../../utils/isColorDark'
import CancelIcon from '@mui/icons-material/Cancel'
import { AddCircleOutline } from '@mui/icons-material'

interface Option {
  label: string
  value: string | number
  color?: string
}

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Option[]
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  defaultValue: string
  chip?: boolean // Added chip prop here
  sx?: SxProps
  highlight?: boolean
  removeCustomValue?: boolean
}

export function ControlledAutoCompleteWithCustomValue<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  options,
  size,
  disabled,
  requiredRule,
  defaultValue,
  chip = false,
  sx,
  highlight,
  removeCustomValue = false,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue as any}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          sx={{ ...sx, width: '100%' }}
          clearIcon={null}
          onChange={(_, data) => onChange(data ? data.value : null)}
          id={fieldName}
          options={options}
          getOptionLabel={(option) => (chip ? '' : option.label)}
          value={options.find((option) => option.value === value) || { label: '', value: '' }}
          size={size}
          disabled={disabled}
          renderOption={(props, option, { index }) => (
            <li
              {...props}
              key={option.value}
              style={{
                backgroundColor:
                  highlight && index === 0 && (option.value as string).startsWith('add') && !removeCustomValue
                    ? 'lightyellow'
                    : 'inherit',
              }}
            >
              {highlight && index === 0 && (option.value as string).startsWith('add') && !removeCustomValue ? (
                <AddCircleOutline />
              ) : (
                ''
              )}
              {' '}
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={'Écrivez pour rechercher...'}
              required={requiredRule ? value === null || value === undefined || value === '' : false}
              error={!!error}
              // control value based on chip prop
              InputProps={{
                ...params.InputProps,
                startAdornment:
                  chip && value ? (
                    <Chip
                      key={value}
                      label={options.find((option) => option.value === value)?.label || ''}
                      onDelete={() => onChange(null)} // Handle the delete event here
                      deleteIcon={<CancelIcon />} // Add this line to show a delete icon
                      sx={{
                        backgroundColor: options.find((option) => option.value === value)?.color || blueSky,
                        color: isColorDark(options.find((option) => option.value === value)?.color || blueSky)
                          ? 'white'
                          : 'black',
                        marginY: '-4.5px !important',
                        marginTop: '-1.5px',
                        height: '28px',
                        '& .MuiChip-label': {
                          lineHeight: '24px',
                        },
                      }}
                    />
                  ) : undefined,
              }}
            />
          )}
        />
      )}
    />
  )
}
