import { Edit } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import { CustomerDto, CustomerPaymentInfoDto } from '../../../interactors/gen/backendClient'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { MemberCardSaveButton } from '../../Members/MemberCardSaveButton'
import { spacingItem } from '../../theme'

interface Props {
  association: CustomerDto
  onSubmit: (data: Partial<CustomerDto>) => Promise<void>
}

interface FormParams {
  IBANNumber: CustomerPaymentInfoDto['IBANNumber']
  IBANFirstName: CustomerPaymentInfoDto['IBANFirstName']
  IBANLastName: CustomerPaymentInfoDto['IBANLastName']
  checkOrder: CustomerPaymentInfoDto['checkOrder']
  onlinePaymentURL: CustomerPaymentInfoDto['onlinePaymentURL']
}

export const EditPaymentInformationPrompt: React.FC<Props> = ({ association, onSubmit }) => {
  const dialogOpenedState = useDialogOpened()

  const processSubmit = (data: FormParams) => {
    onSubmit({ ...association, paymentInfo: { ...(data as CustomerPaymentInfoDto) } })
    return dialogOpenedState.closeDialog()
  }

  const getDefaultValues = (customer: CustomerDto): FormParams => ({
    IBANNumber: customer?.paymentInfo?.IBANNumber,
    IBANFirstName: customer?.paymentInfo?.IBANFirstName,
    IBANLastName: customer?.paymentInfo?.IBANLastName,
    checkOrder: customer?.paymentInfo?.checkOrder,
    onlinePaymentURL: customer?.paymentInfo?.onlinePaymentURL,
  })

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useFormExtended(association, getDefaultValues)
  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
        <Tooltip title={`Editer les informations de paiement`}>
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>{`Editer les informations de paiement`}</DialogTitle>

        <form
          onSubmit={handleSubmit(processSubmit)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Typography variant="h4" sx={{ fontSize: 18, mb: 3, mt: 1, ml: -1, color: 'black' }}>
                Virement bancaire
              </Typography>
              <Box display="flex" width="100%" alignItems="center">
                <TextField
                  label="IBAN"
                  type="text"
                  fullWidth
                  {...register('IBANNumber')}
                  error={!!errors.IBANNumber}
                  helperText={errors.IBANNumber?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Prénom du titulaire du compte"
                  type="text"
                  fullWidth
                  {...register('IBANFirstName')}
                  error={!!errors.IBANFirstName}
                  helperText={errors.IBANFirstName?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Nom du titulaire du compte"
                  type="text"
                  fullWidth
                  {...register('IBANLastName')}
                  error={!!errors.IBANLastName}
                  helperText={errors.IBANLastName?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Typography variant="h4" sx={{ fontSize: 18, mb: 3, mt: 1, ml: -1, color: 'black' }}>
                Chèque
              </Typography>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Ordre du chèque"
                  type="text"
                  fullWidth
                  {...register('checkOrder')}
                  error={!!errors.checkOrder}
                  helperText={errors.checkOrder?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Typography variant="h4" sx={{ fontSize: 18, mb: 3, mt: 1, ml: -1, color: 'black' }}>
                Paiement en ligne
              </Typography>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Lien de paiement en ligne"
                  type="text"
                  fullWidth
                  {...register('onlinePaymentURL')}
                  error={!!errors.onlinePaymentURL}
                  helperText={errors.onlinePaymentURL?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <MemberCardSaveButton isOwnAccount={false} />
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
