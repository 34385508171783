import { Close } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, IconButton, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { spacingItem } from '../../theme'
import { AssociationDonatorForm } from './AssociationDonatorForm'
import { DonationTrackingDto } from '../../../interactors/gen/backendClient'
import { AssociationDonationForm } from './AssociationDonationForm'

interface Props {
  selectedDonation: DonationTrackingDto
  setSelectedDonation: (selectedDonation: DonationTrackingDto | undefined) => void
  setDonationForFiscalReport: (donation: DonationTrackingDto) => void
  setIsExportPDFFiscalReportModalOpen: (isOpen: boolean) => void
}

type TabKey = 0 | 1 | 2

export const AssociationDonationFormTabs: React.FC<Props> = ({
  selectedDonation,
  setSelectedDonation,
  setDonationForFiscalReport,
  setIsExportPDFFiscalReportModalOpen,
}) => {
  const handleFiscalReport = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setDonationForFiscalReport(selectedDonation)
    setIsExportPDFFiscalReportModalOpen(true)
  }

  const [currentTabIndex, setCurrentTabIndex] = useState<TabKey>(0)

  const handleChange = (_: React.SyntheticEvent, newTabKey: TabKey) => {
    setCurrentTabIndex(newTabKey)
  }

  function a11yProps(tabKey: TabKey) {
    return {
      id: `simple-tab-${tabKey}`,
      'aria-controls': `simple-tabpanel-${tabKey}`,
    }
  }

  return (
    <>
      <Card sx={spacingItem}>
        <CardHeader
          title="Détails de la donation"
          action={
            <IconButton onClick={() => setSelectedDonation(undefined)}>
              {' '}
              <Close />{' '}
            </IconButton>
          }
        />
        <CardContent>
          <Tabs value={currentTabIndex} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Le don" {...a11yProps(0)} />
            <Tab label="Le donateur" {...a11yProps(1)} />
          </Tabs>

          {selectedDonation && (
            <>
              {' '}
              <TabPanel value={currentTabIndex} index={0}>
                <AssociationDonationForm selectedDonation={selectedDonation} handleFiscalReport={handleFiscalReport} />
              </TabPanel>
              <TabPanel value={currentTabIndex} index={1}>
                <AssociationDonatorForm
                  selectedDonaterId={selectedDonation.donaterId}
                  handleFiscalReport={handleFiscalReport}
                />
              </TabPanel>{' '}
            </>
          )}
        </CardContent>
      </Card>
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
