import { Box, Chip, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { setOpacity } from '../../utils/setOpacity'
import { theme } from '../theme'

interface Props {
  label: string
  value: string
  color: string
  fullWidth?: boolean
  documentPage?: boolean
}

export const DisplayChipLikeTextField: React.FC<Props> = ({
  label,
  value,
  color,
  fullWidth = false,
  documentPage = false,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  function isColorDark(color: string) {
    const matches = color.substring(1).match(/\w\w/g)
    if (!matches) {
      return false
    }
    const [r, g, b] = matches.map((c) => parseInt(c, 16))
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b
    return luminance < 128
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      height="100%"
      width={fullWidth ? '100%' : 'auto'}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          position: 'relative',
          marginTop: documentPage ? '1px' : isMobile ? 1 : 2,
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            position: 'absolute',
            top: '-0.8em',
            left: '0.5em',
            background: 'white',
            paddingLeft: '0.2em',
            paddingRight: '0.2em',
          }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            padding: '5px',
            display: 'flex',
            alignItems: 'flex-end',
            paddingRight: '15px',
            '&:hover': {
              borderColor: 'black',
            },
            cursor: 'not-allowed',
          }}
        >
          <Chip
            label={value}
            sx={{
              zIndex: 1,
              backgroundColor: documentPage ? color : setOpacity(color, 0.8),
              color: isColorDark(color) ? '#fff' : '#000',
              marginLeft: '12px',
              height: '28px',
              '& .MuiChip-label': {
                lineHeight: '24px',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
