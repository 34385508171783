import { Save } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { FC } from 'react'
import { useAccountsStore } from '../../store/AccountsStore'
import { GENERIC_DISPLAY } from '../../utils/genericDisplay'
import { LoadingButton } from '@mui/lab'
import { DialogOpenedStore } from '../../utils/hooks/useDialogOpened'

interface MemberPromptSaveButtonProps {
  isOwnAccount: boolean
  disabled?: boolean
  isLoading: boolean
  dialogOpenedState: DialogOpenedStore
  noClose?: boolean
}

export const MemberPromptSaveButton: FC<MemberPromptSaveButtonProps> = ({
  isOwnAccount,
  disabled,
  isLoading,
  dialogOpenedState,
  noClose = false,
}) => {
  const accountsStore = useAccountsStore()

  const canEdit = accountsStore.isAdmin() || isOwnAccount

  return (
    <Tooltip title={canEdit ? '' : GENERIC_DISPLAY.doNotHavePermissionAsReader}>
      <span>
        {' '}
        <LoadingButton
          color="primary"
          variant="outlined"
          type="submit"
          loading={isLoading}
          disabled={!canEdit || disabled}
          sx={{ textTransform: 'none' }}
          onClick={() => (!noClose ? dialogOpenedState.closeDialog() : null)}
        >
          <Save sx={{ marginRight: 1 }} />
          Enregistrer
        </LoadingButton>
      </span>
    </Tooltip>
  )
}
