import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import {
  AdopterDto,
  AdoptionAttemptDtoStatus,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDto,
} from '../../../../interactors/gen/backendClient'
import dayjs from 'dayjs'
import { LoadingButton } from '@mui/lab'
import { AnimalsClient } from '../../../../interactors/clients/AnimalsClient'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { AdoptersClient } from '../../../../interactors/clients/AdoptersClient'
import { useNavigate } from 'react-router-dom'

interface Props {
  isOpen: boolean
  onClose: () => void
  animal: AnimalDto
  adopter: AdopterDto
  shouldRedirect?: boolean
}

export const VerdictAfterVPAModal: React.FC<Props> = ({ isOpen, onClose, animal, adopter, shouldRedirect = false }) => {
  const [validateLoading, setValidateLoading] = React.useState(false)
  const [cancelLoading, setCancelLoading] = React.useState(false)

  const globalSnackBarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()

  const onValidate = async () => {
    setValidateLoading(true)
    animal.adoption.step = AnimalAdoptionDtoStep.AdoptionValidatedAfterPostVisit
    await AnimalsClient.editAnimal(animal).then((data) => {
      animal = data
    })
    await endAdoptionAttempt()
    globalSnackBarStore.triggerSuccessMessage('La visite post-adoption a bien été validée.')
    setValidateLoading(false)
    onClose()
    if (shouldRedirect) {
      const adoptionAttempt = adopter.adoptionAttempts?.find(
        (adoptionAttempt) => adoptionAttempt.animalId === animal.id
      )
      navigate(`/suivi-adoptant/${adopter.id}?tab=past-adoption&attemptId=${adoptionAttempt?.id}`)
    } else {
      window.location.reload()
    }
  }

  const onCancel = async () => {
    setCancelLoading(true)
    await setAnimalToAdoptableStatus()
    await suspendAdoptionAttempt()
    globalSnackBarStore.triggerSuccessMessage("L'adoption a bien été annulée.")
    setCancelLoading(false)
    onClose()
    if (shouldRedirect) {
      const adoptionAttempt = adopter.adoptionAttempts?.find(
        (adoptionAttempt) => adoptionAttempt.animalId === animal.id
      )
      navigate(`/suivi-adoptant/${adopter.id}?tab=suspended-adoption&attemptId=${adoptionAttempt?.id}`)
    } else {
      window.location.reload()
    }
  }

  const setAnimalToAdoptableStatus = async () => {
    animal.adoption.status = AnimalAdoptionDtoStatus.Adoptable
    animal.adoption.step = undefined
    animal.adoption.adopterId = undefined
    animal.adoption.vpaDate = undefined
    animal.adoption.exitDate = undefined
    animal.adoption.adoptionDate = undefined
    await AnimalsClient.editAnimal(animal).then((data) => {
      animal = data
    })
  }

  const suspendAdoptionAttempt = async () => {
    const adoptionAttempt = adopter.adoptionAttempts?.find((adoptionAttempt) => adoptionAttempt.animalId === animal.id)
    if (adoptionAttempt) {
      adoptionAttempt.status = AdoptionAttemptDtoStatus.SuspendedByCustomer
      await AdoptersClient.editAccount(adopter).then((data) => {
        adopter = data
      })
    }
  }

  const endAdoptionAttempt = async () => {
    const adoptionAttempt = adopter.adoptionAttempts?.find((adoptionAttempt) => adoptionAttempt.animalId === animal.id)
    if (adoptionAttempt) {
      adoptionAttempt.step = 6
      adoptionAttempt.status = AdoptionAttemptDtoStatus.Done
      await AdoptersClient.editAccount(adopter).then((data) => {
        adopter = data
      })
    }
  }
  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      fullWidth
      scroll="paper"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
    >
      <DialogTitle>Verdict de la visite post-adoption</DialogTitle>

      <Box
        sx={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          boxSizing: 'content-box',
        }}
      >
        <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
          <b>
            Vous avez effectué la visite post-adoption le{' '}
            {animal.adoption?.vpaDate ? dayjs(animal.adoption?.vpaDate).format('DD/MM/YYYY') : 'Date inconnue'}.
            <br />
            Merci de confirmer votre décision.
          </b>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" type="button" onClick={onClose} disabled={cancelLoading || validateLoading}>
            Plus tard
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <LoadingButton
              variant="contained"
              color="error"
              type="button"
              onClick={onCancel}
              disabled={validateLoading}
              loading={cancelLoading}
            >
              Annuler l&#39;adoption
            </LoadingButton>
            <LoadingButton
              variant="contained"
              type="button"
              onClick={onValidate}
              disabled={cancelLoading}
              loading={validateLoading}
            >
              Valider la VPA
            </LoadingButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
