import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Edit } from '@mui/icons-material'

import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { LoadingButton } from '@mui/lab'
import { AxiosError } from 'axios'
import { readableAxiosError } from '../../../utils/axios'
import { spacingItem } from '../../theme'

import { GENERIC_DISPLAY } from '../../../utils/genericDisplay'
import { useCanEditAnimal } from '../../../store/useCanEditAnimal'

interface Props {
  title: string
  initialValue: string
  onSubmit: (newValue: string) => Promise<void>
  renderButton?: (onClick: () => void) => any
}

/**
 * For now, supposed to be used in the Animal Page, update support if you want to use outside
 */
export const EditorPrompt: FC<Props> = ({ title, initialValue, onSubmit, renderButton }) => {
  const canEditAnimal = useCanEditAnimal()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dialogOpenedState = useDialogOpened()
  const [convertedText, setConvertedText] = useState(initialValue)
  const [serverError, setServerError] = useState('')

  useEffect(() => {
    setConvertedText(initialValue)
  }, [initialValue])

  const onSave = async () => {
    setIsLoading(true)

    await onSubmit(convertedText)
      .then(async () => {
        setIsLoading(false)
        dialogOpenedState.closeDialog()
      })
      .catch((error: Error | AxiosError) => {
        setServerError(readableAxiosError(error).join(' '))
        setIsLoading(false)
      })
  }

  return (
    <>
      {renderButton ? (
        renderButton(dialogOpenedState.openDialog)
      ) : (
        <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
          <Tooltip title="Editer">
            <>
              <Edit />
            </>
          </Tooltip>
        </IconButton>
      )}

      <Dialog
        maxWidth="xl"
        sx={{ marginX: 'auto' }}
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div>
            <ReactQuill theme="snow" value={convertedText} onChange={setConvertedText} />
          </div>

          {serverError && (
            <Box sx={spacingItem}>
              <Typography color="error">{serverError}</Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Tooltip title={GENERIC_DISPLAY.doNotHavePermissionAsReader}>
            <Box component="span" sx={{ width: '100%' }}>
              <LoadingButton
                variant="contained"
                sx={{ width: '100%' }}
                loading={isLoading}
                onClick={onSave}
                disabled={!canEditAnimal}
              >
                Mettre à jour
              </LoadingButton>
            </Box>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  )
}
