import { Avatar, Box, Button, Card, CardContent, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { AnimalDto } from '../../../interactors/gen/backendClient'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { getAnimalProfilePhotoSrc } from '../../../utils/S3-links'
import { Link } from 'react-router-dom'
import {
  blueSky,
  // blueSky,
  theme,
} from '../../theme'

interface Props {
  animal: AnimalDto
  status: string
}

export const OnGoingAdoptionAnimalCard: React.FC<Props> = ({ animal, status }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const profileImageKey = animal?.images?.profileImageKey
  const animalProperties = [
    animal?.species && getReadableSpecies(animal?.species),
    /* animal?.birthday?.date && calculateAge(animal?.birthday?.date), */
    animal?.health.sterilizedOrNeutered ? 'Stérilisé' : 'Non stérilisé',
  ]
  return (
    <Card sx={{ borderRadius: 4, boxShadow: 3, my: { xs: 0, sm: 2 } }}>
      <CardContent sx={{ pb: '16px !important' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: { xs: 0, sm: 8 },
            alignItems: 'center',
          }}
        >
          {/* Avatar, name + properties */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
            {/* Avatar */}
            <Avatar
              alt={animal.name}
              src={profileImageKey && getAnimalProfilePhotoSrc(profileImageKey)}
              sx={{ width: { xs: 56, sm: 64 }, height: { xs: 56, sm: 64 } }}
            />
            {/* Name, properties, (mobile: profile link) */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h5"
                component="div"
                sx={{ fontSize: { xs: '14px', sm: '19px' }, fontWeight: { xs: 700, sm: 'normal' } }}
              >
                {animal.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {animalProperties.join(' • ')}
              </Typography>
              {/* MOBILE: profile link */}
              {isMobile && (
                <Link to={`/animaux/${animal.id}`} style={{ textDecoration: 'none' }}>
                  <Typography color="primary.main" style={{ fontSize: '14px' }}>
                    Voir le profil
                  </Typography>
                </Link>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              gap: {
                xs: 0.5,
                sm: 1,
                md: 2,
              },
            }}
          >
            {isMobile && <Typography fontSize="14px">Action à mener</Typography>}
            {/* Button: status ("envoyer le certificat" ...) */}
            <Box
              sx={{
                // backgroundColor: blueSky,
                bgcolor: 'white',
                color: blueSky,
                border: `1px solid ${blueSky}`,
                px: { xs: '15px', sm: '8px' },
                py: { xs: '5px', sm: '8px' },
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1,
                width: { sm: '250px' },
                maxWidth: { xs: '200px', sm: '250px' },
                fontSize: { xs: '14px', sm: '16px' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {status}
            </Box>
            {/* DESKTOP: profile link (button) */}
            {!isMobile && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  component={Link}
                  to={`/animaux/${animal.id}`}
                  variant="contained"
                  sx={{
                    backgroundColor: 'grey',
                    color: 'white',
                    '&:hover': { backgroundColor: 'darkgrey' },
                    textTransform: 'none',
                    fontSize: '16px',
                    width: '250px',
                  }}
                >
                  Voir le profil
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
