import create from 'zustand'
import { accountCanEdit } from '../domain/Account/accountCanEdit'
import { MembersClient } from '../interactors/clients/MembersClient'
import { AccountDto, CustomerDto } from '../interactors/gen/backendClient'

interface AccountsStore {
  members: AccountDto[]
  withRemovedMembers: AccountDto[]
  fetchMembers: () => Promise<void>
  fetchOrRefreshMembers: () => Promise<void>
  initializeMembersIfNeeded: () => Promise<void>
  refreshMembers: () => Promise<void>
  changeConnectedAccount: (account: AccountDto) => void
  connectedAccount: AccountDto | null
  isLoading: boolean
  areMembersInitialized: boolean
  canEdit: () => boolean
  isAdmin: () => boolean
  isPremium: () => boolean
  connectedCustomer: CustomerDto | null
  changeConnectedCustomer: (customer: CustomerDto) => void
}

export const useAccountsStore = create<AccountsStore>()((set, get) => ({
  isLoading: false,
  members: [],
  withRemovedMembers: [],
  fetchMembers: async () => {
    set({ isLoading: true })

    const membersFetched = await MembersClient.getAllMembers()
    set({ members: filterDeletedAccount(membersFetched), withRemovedMembers: membersFetched })
    set({ isLoading: false })
    set({ areMembersInitialized: true })
  },
  refreshMembers: async () => {
    const refreshedMembers = await MembersClient.getAllMembers()
    set({ members: filterDeletedAccount(refreshedMembers), withRemovedMembers: refreshedMembers })
  },
  fetchOrRefreshMembers: async () => {
    if (get().areMembersInitialized) {
      await get().refreshMembers()
    } else {
      await get().fetchMembers()
    }
  },
  initializeMembersIfNeeded: async () => {
    if (!get().areMembersInitialized) {
      console.log('Loading members')
      await get().fetchMembers()
      console.log('Members loaded')
    }
  },
  areMembersInitialized: false,
  connectedAccount: null,
  changeConnectedAccount: (account: AccountDto) => {
    set({ connectedAccount: account })
  },
  canEdit: () => {
    const account = get().connectedAccount
    return !!account && accountCanEdit(account)
  },
  isAdmin: () => {
    const account = get().connectedAccount
    return account?.permission === 'administrator'
  },
  isPremium: () => {
    const customer = get().connectedCustomer
    return customer?.subscription?.plan === 'premium' && customer?.subscription?.status === 'active'
  },
  connectedCustomer: null,
  changeConnectedCustomer: (customer: CustomerDto) => {
    set({ connectedCustomer: customer })
  },
}))

const filterDeletedAccount = (accounts: Array<AccountDto>) => {
  return accounts.filter((account) => !account.isDeleted)
}
