import React from 'react'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { frFR } from '@mui/x-date-pickers/locales'
import { Box, IconButton, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import dayjs from 'dayjs'

interface DefineDatesToFilterProps {
  dateFrom: dayjs.Dayjs | null
  setDateFrom: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
  dateTo: dayjs.Dayjs | null
  setDateTo: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
}

export const DefineDatesToFilter: React.FC<DefineDatesToFilterProps> = ({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) => {
  const clearFilters = () => {
    setDateFrom(null)
    setDateTo(null)
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', gap: 1 }}>
        <Typography>Définir une période:</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DatePicker
              localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
              value={dateFrom}
              onChange={(date) => {
                if (date && !date.isValid()) return // Check if date is valid
                setDateFrom(date)
              }}
              label="Date de début"
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              format="DD/MM/YYYY"
              sx={{ maxWidth: { xs: '140px', md: '180px' } }}
            />
            à
            <DatePicker
              localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
              value={dateTo}
              onChange={(date) => {
                if (date && !date.isValid()) return // Check if date is valid
                setDateTo(date)
              }}
              label="Date de fin"
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              format="DD/MM/YYYY"
              sx={{ maxWidth: { xs: '140px', md: '180px' } }}
            />
          </Box>
          {(dateFrom !== null || dateTo !== null) && (
            <IconButton onClick={clearFilters}>
              <ClearIcon />
            </IconButton>
          )}
        </LocalizationProvider>
      </Box>
    </>
  )
}
