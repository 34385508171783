import { useEffect } from 'react'
import { useAccountsStore } from './AccountsStore'

export const useFetchOrRefreshMembersOnMount = () => {
  const accountsStore = useAccountsStore()

  useEffect(() => {
    const loadMembers = async () => {
      await accountsStore.fetchOrRefreshMembers()

      console.log('Members fetched or refreshed')
    }

    loadMembers()
  }, [])
}
