import { Snackbar, Alert } from '@mui/material'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'

export const GlobalSnackBar = () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  return (
    <Snackbar
      open={globalSnackbarStore.isSnackbarOpen}
      autoHideDuration={6000}
      onClose={globalSnackbarStore.closeSnackbar}
    >
      <Alert onClose={globalSnackbarStore.closeSnackbar} severity={globalSnackbarStore.severity} sx={{ width: '100%' }}>
        {globalSnackbarStore.message}
      </Alert>
    </Snackbar>
  )
}
