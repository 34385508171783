import { useEffect } from 'react'
import { useAdoptersStore } from './AdoptersStore'

export const useFetchOrRefreshAdoptersOnMount = () => {
  const adoptersStore = useAdoptersStore()

  useEffect(() => {
    const loadAdopters = async () => {
      await adoptersStore.fetchOrRefreshAdopters()

      console.log('Adopters fetched or refreshed')
    }

    loadAdopters()
  }, [])
}
