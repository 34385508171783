import { ArrowDownward } from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import {
  AdopterDto,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDto,
} from '../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../../utils/axios'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { AnimalCardSaveButton } from '../../Animals/Details/AnimalCardSaveButton'
import { ControlledDateField } from '../../common/ControlledDateField'
import { theme } from '../../theme'
import { GetAnimalAdoptedModal } from './GetAnimalAdoptedModal'
import { VerdictAfterVPAModal } from '../../Animals/Details/Adoption/VerdictAfterVPAModal'

interface Props {
  animal: AnimalDto
  adopter: AdopterDto
  endProcedure: () => Promise<void>
  defaultExpanded?: boolean
}

interface FormParams {
  adoptionStatus: AnimalAdoptionDtoStatus
  exitDate: string | ''
  adoptionDate: string | ''
  vpaDate: string | ''
  newName: string | ''
}

export const ExitDatesCard: React.FC<Props> = ({ animal, adopter, endProcedure, defaultExpanded = false }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const globalSnackBarStore = useGlobalSnackbarStore()
  const [expanded, setExpanded] = useState(defaultExpanded)
  const [isOpen, setIsOpen] = useState(false)
  const [verdictVPAModalIsOpen, setVerdictVPAModalIsOpen] = useState(false)

  const onClose = () => setIsOpen(false)

  const onCloseVerdictVPAModal = () => setVerdictVPAModalIsOpen(false)

  useEffect(() => {
    const openVPAModal = () => {
      if (
        animal.adoption?.vpaDate &&
        animal.adoption.status === AnimalAdoptionDtoStatus.HasBeenAdopted &&
        dayjs().isAfter(dayjs(animal.adoption.vpaDate)) &&
        animal.adoption.step !== AnimalAdoptionDtoStep.AdoptionValidatedAfterPostVisit
      ) {
        setVerdictVPAModalIsOpen(true)
      }
    }
    openVPAModal()
  }, [animal.adoption?.vpaDate])

  const handleToggleExpand = () => {
    setExpanded(!expanded)
  }

  const editAnimalMutation = useMutation(
    async (updatedAnimal: AnimalDto) => {
      const response: AnimalDto = await AnimalsClient.editAnimal(updatedAnimal)
      return response
    },
    {
      onSuccess: () => {
        globalSnackBarStore.triggerSuccessMessage(`Vos modifications ont bien été enregistrées.`)
      },
      onError: (error: Error | AxiosError) => {
        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const getDefaultValues = (animal: AnimalDto): FormParams => {
    return {
      adoptionStatus: animal.adoption.status || '',
      exitDate: animal.adoption.exitDate || '',
      adoptionDate: animal.adoption.adoptionDate || '',
      vpaDate: animal.adoption.vpaDate || '',
      newName: animal.adoption.newName || '',
    }
  }

  const {
    control,
    formState: { errors, isDirty },
    register,
    handleSubmit,
    watch,
  } = useFormExtended(animal, getDefaultValues)

  const onSubmit = (data: FormParams, confirmation?: boolean) => {
    const updatedAnimal = {
      ...animal,
      adoption: {
        ...animal.adoption,
        status: data.adoptionStatus,
        exitDate: data.exitDate,
        adoptionDate: data.adoptionDate,
        vpaDate: data.vpaDate,
        newName: data.newName,
      },
    }
    if (data.adoptionDate && (animal.adoption.adoptionDate == null || animal.adoption.adoptionDate === '')) {
      if (confirmation) {
        updatedAnimal.adoption.status = AnimalAdoptionDtoStatus.HasBeenAdopted
        updatedAnimal.adoption.step = AnimalAdoptionDtoStep.AdoptionValidatedWithoutPostVisit
        animal.adoption.adopterId = adopter.id
        endProcedure()
      } else {
        return setIsOpen(true)
      }
    }

    editAnimalMutation.mutate(updatedAnimal)
  }

  const adoptionDate = watch('adoptionDate')
  const exitDatesDisabled = adoptionDate === '' || adoptionDate == null

  return (
    <>
      <Card sx={{ m: 0, p: 0, width: '100%' }}>
        <CardContent
          sx={{ m: 0, p: isMobile ? 'auto' : '12px !important', '&:last-child': { pb: isMobile ? '16px' : 'auto' } }}
        >
          <Box
            onClick={handleToggleExpand}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography variant="h4" component="p" sx={{ fontSize: isMobile ? 18 : 20, fontWeight: 700 }}>
              Infos de sortie
            </Typography>
            <IconButton>
              <ArrowDownward
                style={{
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
                }}
              />
            </IconButton>
          </Box>

          <Collapse in={expanded} timeout="auto">
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <Box
                sx={{
                  marginTop: 1,
                  pb: isMobile ? 1 : 0,
                }}
              >
                <Grid container spacing={1} sx={{ marginTop: 1 }}>
                  <Grid item xs={12} sm={3}>
                    <ControlledDateField
                      control={control}
                      validate={(value) => isValidPastDateCoherence(value) || value === null}
                      // error={errors.adoptionDate}
                      error={undefined}
                      fieldName={'adoptionDate'}
                      label="Adoption (physique)"
                      requiredRule={undefined}
                      sx={{ marginTop: 0 }}
                      size="small"
                      maxDate={dayjs()}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Tooltip title={exitDatesDisabled ? "Veuillez d'abord remplir la date d'adoption" : ''}>
                      <span>
                        <ControlledDateField
                          control={control}
                          validate={(value) => isValidPastDateCoherence(value) || value === null}
                          // error={errors.adoptionDate}
                          error={undefined}
                          fieldName={'vpaDate'}
                          label="Visite post-adoption"
                          requiredRule={undefined}
                          sx={{ marginTop: 0 }}
                          size="small"
                          minDate={dayjs(adoptionDate, 'DD/MM/YYYY')}
                          disabled={exitDatesDisabled}
                        />
                      </span>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Tooltip title={exitDatesDisabled ? "Veuillez d'abord remplir la date d'adoption" : ''}>
                      <span>
                        <ControlledDateField
                          control={control}
                          validate={(value) => isValidPastDateCoherence(value) || value === null}
                          error={errors.exitDate}
                          fieldName={'exitDate'}
                          label="Date de sortie (Cession I-CAD)"
                          requiredRule={undefined}
                          sx={{ marginTop: 0 }}
                          size="small"
                          minDate={dayjs(adoptionDate, 'DD/MM/YYYY')}
                          disabled={exitDatesDisabled}
                        />
                      </span>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="new-name-input"
                      label="Nouveau nom"
                      type="text"
                      fullWidth
                      {...register('newName')}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Box>
              {/* MOBILE */}
              {isMobile ? (
                <AnimalCardSaveButton isLoading={editAnimalMutation.isLoading} disabled={!isDirty} />
              ) : (
                // DESKTOP
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 2,
                  }}
                >
                  <AnimalCardSaveButton isLoading={editAnimalMutation.isLoading} disabled={!isDirty} />
                </Box>
              )}
            </form>
          </Collapse>
        </CardContent>
      </Card>
      <GetAnimalAdoptedModal
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        animalName={animal.name}
      />
      <VerdictAfterVPAModal
        isOpen={verdictVPAModalIsOpen}
        onClose={onCloseVerdictVPAModal}
        animal={animal}
        adopter={adopter}
        shouldRedirect
      />
    </>
  )
}
