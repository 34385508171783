import { Search as SearchIcon } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import { getReadableAccountDocumentType } from '../../../domain/Account/AccountDisplay'
import { AccountDocumentDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useFetchOrRefreshMembersOnMount } from '../../../store/useFetchOrRefreshMembersOnMount'
import { colors, spacingItem } from '../../theme'
import { AddDocumentModal } from './AddDocumentModal'
import { DocumentItem } from './DocumentItem'

interface DocumentsListProps {
  documents: AccountDocumentDto[]
  accountId: string
  isOwnAccount: boolean
}

export const DocumentsList: React.FC<DocumentsListProps> = ({ documents, accountId, isOwnAccount }) => {
  useFetchOrRefreshMembersOnMount()

  const accountsStore = useAccountsStore()
  const buttonsShouldBeDisabled = () => {
    if (accountsStore.isAdmin()) {
      return false
    } else if (isOwnAccount) {
      return false
    }
    return true
  }

  const [searchQuery, setSearchQuery] = useState('')
  const [documentsList, setDocumentsList] = useState<AccountDocumentDto[]>(documents)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const filteredDocuments = documentsList
    ? documentsList.filter(
        (document) =>
          searchQuery === '' ||
          document.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          document.reference?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          getReadableAccountDocumentType(document.type)?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : []

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{ ...spacingItem, backgroundColor: colors.white }}>
        <TextField
          id="name-input"
          placeholder="Rechercher un document"
          type="text"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          sx={{
            borderRadius: 0,
            [`& fieldset`]: {
              borderRadius: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Card>
        <CardHeader title="Documents" />
        <CardContent>
          {filteredDocuments.length > 0 &&
            filteredDocuments.map((document) => (
              <DocumentItem
                key={document.id}
                document={document}
                accountId={accountId}
                setDocumentsList={setDocumentsList}
                disabled={buttonsShouldBeDisabled()}
              />
            ))}
          <Box display="flex" justifyContent="flex-end" sx={spacingItem}>
            <AddDocumentModal
              accountId={accountId}
              setDocumentsList={setDocumentsList}
              disabled={buttonsShouldBeDisabled()}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
