import React from 'react'
import { DocumentsList } from './DocumentsList'
import { AnimalDto } from '../../../../interactors/gen/backendClient'

interface AnimalDocumentsAndPicturesTabProps {
  animal: AnimalDto
}

export const AnimalDocumentsAndPicturesTab: React.FC<AnimalDocumentsAndPicturesTabProps> = ({ animal }) => {
  return <DocumentsList documents={animal.documents} animalId={animal.id} />
}
