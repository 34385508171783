import dayjs from 'dayjs'
import { toDateOnly } from './DateOnly'

export const isValidPastDateCoherence = (dateToCheck: dayjs.Dayjs | null): boolean => {
  if (!dateToCheck) {
    return false
  }

  const dateAsDateOnly = toDateOnly(dateToCheck)

  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateAsDateOnly)) {
    return false
  }
  const parts = dateAsDateOnly.split('/')
  const now = new Date()
  const year = parseInt(parts[2], 10)
  const currentYear = now.getFullYear()
  const month = parts[1][0] === '0' ? parseInt(parts[1][1], 10) : parseInt(parts[1], 10)
  const day = parts[0][0] === '0' ? parseInt(parts[0][1], 10) : parseInt(parts[0], 10)

  if (year > currentYear + 1) {
    return false
  }
  if (currentYear - year < -1 || currentYear - year > 100) {
    return false
  }
  if (month < 1 || month > 12) {
    return false
  }
  if (day < 1 || day > 31) {
    return false
  }
  return true
}
